import React, { useCallback, useState } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { formatUTCDate, formatCurrency } from '../../../../utils/formatUtils';
import { nextSortState, sortByField } from '../../../../utils/sortUtils';
import { Payable } from '../../../Interfaces/Accounting/AccountsPayables.interfaces';
import {
  DataTablePFSEvent,
  DataTableRowClickEventParams,
  DataTableRowMouseEventParams,
  DataTableSortOrderType,
} from 'primereact/datatable';
import APDrilldown from '../APDrilldown/APDrilldown';
import { animateCellText } from '../../../../utils/htmlUtils';

export type APYardiOpenTableProps = {
  accountsPayables: Payable[];
  isArchive?: boolean;
};

const APYardiOpenTable = React.forwardRef<
  HTMLDivElement,
  APYardiOpenTableProps
>(({ accountsPayables, isArchive }, ref) => {
  const [sortState, setSortState] = React.useState<{
    field: string;
    order: DataTableSortOrderType;
  }>({ field: '', order: null });
  const [sortedTransactions, setSortedTransactions] = React.useState([
    ...accountsPayables,
  ]);
  const [showDrilldown, setShowDrilldown] = useState(false);
  const [selectedPayable, setSelectedPayable] = useState<Payable>();

  const calcHeight = (rows: number) => {
    const headerHeight = 49;
    const footerHeight = 54;
    const rowHeight = 31;
    return headerHeight + footerHeight + rows * rowHeight + 3;
  };

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  let payableAmountTotal = 0;
  let taxTotal = 0;
  let discTotal = 0;
  let gross = 0;
  accountsPayables.forEach((payable: Payable) => {
    if (payable.Reference) {
      payableAmountTotal += payable.PayableAmount ?? 0;
      taxTotal += payable.Tax ?? 0;
      discTotal += payable.DiscountOffered ?? 0;
      gross += payable.Gross;
    }
  });

  const onRowClick = (e: DataTableRowClickEventParams) => {
    if (e.data.Reference) {
      setSelectedPayable(e.data);
      setShowDrilldown(true);
    }
  };

  const rowClassName = (data: Payable) => {
    let className = data.Reference ? 'cursor-pointer' : '';
    if (!data.Reference) {
      className = `${className} font-bold surface-300`;
    }

    return className;
  };

  const handleSort = (event: DataTablePFSEvent) => {
    const { reset, field, order } = nextSortState(
      event.sortField,
      sortState.field
    );
    setSortState({ field, order });

    if (reset) {
      setSortedTransactions([...accountsPayables]);
      return;
    }

    const sortedData = [...accountsPayables].sort((a, b) => {
      switch (event.sortField) {
        case 'Net': {
          const aValue = a.NetAmount + (a.Retainage ?? 0);
          const bValue = b.NetAmount + (b.Retainage ?? 0);
          return (order ?? 0) * (aValue - bValue);
        }
        default: {
          const key = event.sortField as keyof Payable;
          const aValue = a[key];
          const bValue = b[key];
          return sortByField(aValue, bValue, order ?? 0);
        }
      }
    });

    setSortedTransactions(sortedData);
  };

  return (
    <div>
      {selectedPayable && (
        <APDrilldown
          visible={showDrilldown}
          setVisible={setShowDrilldown}
          payable={selectedPayable}
          extendedInfo={isArchive}
        />
      )}
      <Table
        id="open-table"
        ref={ref}
        data={sortedTransactions}
        className={`mx-3 dashboardOptionShadow tableFirstLineStyled dobleHeader`}
        calcHeight={useCallback(calcHeight, [])}
        onRowClick={useCallback(onRowClick, [])}
        rowClassName={useCallback(rowClassName, [])}
        onRowMouseEnter={useCallback(onRowHover, [])}
        sortField={sortState.field}
        sortOrder={sortState.order}
        onSort={useCallback(handleSort, [sortState.field, accountsPayables])}
        hideColumns={true}
      >
        <Column
          field="Vendor"
          header="Vendor"
          body={(ap) => <div className="scroll-text">{ap.vendor?.name}</div>}
          headerClassName={`tableHeader font-normal justify-content-center`}
          style={{ minWidth: '300px', width: '420px' }}
          className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="Reference"
          header="Reference Code"
          body={(ap) => <div className="scroll-text">{ap.TransCode}</div>}
          style={{ minWidth: '150px', width: '150px' }}
          headerClassName="tableHeader font-normal"
          className="justify-content-center text-standard blackText tableCell text-center word-break-all"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="InvoiceDate"
          header="Inv. Date"
          style={{ minWidth: '120px', width: '120px' }}
          body={(ap) => formatUTCDate(ap.InvoiceDate)}
          headerClassName="tableHeader font-normal"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="Description"
          header="Description"
          style={{ minWidth: '230px', width: '420px' }}
          body={(ap) => <div className="scroll-text">{ap.Description}</div>}
          headerClassName="tableHeader font-normal justify-content-center"
          className="text-standard blackText tableCell title overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName={`tableFooter`}
          sortable
        />
        <Column
          field="DueDate"
          header="Due Date"
          style={{ minWidth: '120px', width: '120px' }}
          body={(ap) => formatUTCDate(ap.DueDate)}
          headerClassName="tableHeader font-normal"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName={`tableFooter ${
            isArchive &&
            'block text-right border-top-2 border-transparent mt-4 limitBorder relative'
          }`}
          footer={isArchive && 'Total:'}
          sortable
        />

        <Column
          field="Net"
          header={'Net Amount'}
          style={{ minWidth: '170px', width: '200px' }}
          body={(ap) => formatCurrency(ap.NetAmount + (ap.Retainage ?? 0))}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="justify-content-end text-standard blackText tableCell"
          footer={formatCurrency(payableAmountTotal + taxTotal - discTotal)}
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          sortable
        />
      </Table>
    </div>
  );
});

const transactionsAreEqual = (
  prevTransactions: Readonly<APYardiOpenTableProps>,
  nextTransactions: Readonly<APYardiOpenTableProps>
) => {
  return (
    prevTransactions.accountsPayables === nextTransactions.accountsPayables
  );
};

export default React.memo(APYardiOpenTable, transactionsAreEqual);
