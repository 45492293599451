import { BASE_PDF_STYLES } from '../../../../utils/reportUtils';
import React from 'react';
import ReportButton from '../../../components/report/ReportButton';
import { excelCleanTable } from '../../../../utils/excelUtils';
import * as XLSX from 'xlsx';
import { useCompanyContext } from '../../../context/CompanyContext';
import {
  CostDetailArgs,
  CostType,
} from '../../../Interfaces/Accounting/JobsContracts.interface';
import { formatUTCDate } from '../../../../utils/formatUtils';

const pdfStyles = `
${BASE_PDF_STYLES}

body.pdfBody .drilldownHeader {
    margin-left: auto!important;
  }

`;

type JCDrilldownReportProps = {
  elementRef: React.MutableRefObject<HTMLElement | null>;
  fileName: string;
  isPortrait?: boolean;
  elementID: string;
  title: string;
  costDetail: CostDetailArgs;
  customer?: string;
  costType?: CostType;
  startDate?: Date;
  endDate?: Date;
};

const JCDrilldownReport = ({
  elementRef,
  fileName,
  isPortrait,
  elementID,
  title,
  costDetail,
  customer,
  costType,
  startDate,
  endDate,
}: JCDrilldownReportProps) => {
  const { selectedCompany } = useCompanyContext();

  const excelElementPreProcess = (element: HTMLElement) => {
    excelCleanTable(element);

    return element;
  };

  const excelBeforeDownload = (workBook: XLSX.WorkBook) => {
    const sheet = workBook.Sheets['Sheet1'];

    sheet['C2'] = { t: 's', v: selectedCompany!.name };
    sheet['C3'] = { t: 's', v: title };
    sheet['A4'] = { t: 's', v: 'Search criteria:' };

    let rowStart = 5;

    if ('contract' in costDetail) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Contract:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: `${costDetail.contract} - ${costDetail.contractName}`,
      };
      rowStart += 1;
    }

    if ('job' in costDetail) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Job:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: `${costDetail.job} - ${costDetail.jobName}`,
      };
      rowStart += 1;
    }

    if (customer) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Customer:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: customer,
      };
      rowStart += 1;
    }

    if (costDetail.phase) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Phase:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: `${costDetail.phase} - ${costDetail.phaseDescription}`,
      };
      rowStart += 1;
    }

    if (costType?.CostType) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Cost Type:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: `${costType.CostType} - ${costType?.Description}`,
      };
      rowStart += 1;
    }

    if (startDate) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Start Date:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: formatUTCDate(startDate),
      };
      rowStart += 1;
    }

    if (endDate) {
      sheet[`B${rowStart}`] = { t: 's', v: 'End Date:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: formatUTCDate(endDate),
      };
      rowStart += 1;
    }

    if (costDetail.dateType) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Date Type:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: costDetail.dateType,
      };
    }
  };

  return (
    <ReportButton
      elementToPrintRef={elementRef}
      fileName={fileName}
      pdfStyles={pdfStyles}
      noButton={true}
      isPdfPortrait={isPortrait}
      widthElementID={elementID}
      tableOriginCell="A11"
      excelPreprocess={excelElementPreProcess}
      excelBeforeDownload={excelBeforeDownload}
    />
  );
};

export default JCDrilldownReport;
