import React from 'react';
import { useNavigate } from 'react-router-dom';
import WrapperButton from '../buttons/WrapperButton';

type MenuOptionCardProps = {
  label: string;
  icon: string;
  disabled?: boolean;
  baseURL?: string;
};

const MenuOptionCard = ({
  icon,
  label,
  disabled,
  baseURL,
}: MenuOptionCardProps) => {
  const navigate = useNavigate();

  const onItemClick = (label: string, disabled: boolean | undefined) => {
    const url = baseURL ?? '';
    if (!disabled) {
      navigate(`${url}/${label.replace(' ', '-').toLowerCase()}`);
    }
  };

  return (
    <WrapperButton
      onClick={() => onItemClick(label, disabled)}
      disabled={disabled}
    >
      <div
        className={`dashboardOptionShadow bg-white w-20rem h-16rem flex flex-column justify-content-end`}
      >
        <img
          src={icon}
          alt={`${label} icon`}
          className={`w-3 mx-auto h-auto ${
            disabled ? 'svgInactive' : 'svgActive'
          }`}
        />
        <div className="font-bold mx-auto max-w-8rem h-5rem optionText text-17px text-center mt-5">
          {label}
        </div>
      </div>
    </WrapperButton>
  );
};

export default MenuOptionCard;
