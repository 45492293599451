import {
  AIAContractInfo,
  InvoiceFormItem,
} from '../../../Interfaces/Accounting/AccountsReceivables.interfaces';
import { Company } from '../../../Interfaces/User.interfaces';
import ARInvoiceHeader from './ARInvoiceHeader';
import ARInvoiceTable from './ARInvoiceTable';

type ARInvoicePage = {
  contract: AIAContractInfo;
  company: Company;
  items: InvoiceFormItem[];
  total: number;
  page: number;
  totalPages: number;
  invoice: string;
};

const ARInvoicePage = ({
  contract,
  company,
  items,
  total,
  page,
  totalPages,
  invoice,
}: ARInvoicePage) => {
  return (
    <div
      style={{ width: '1276px', height: '1648px' }}
      className="printBackground px-5 flex flex-column"
    >
      <ARInvoiceHeader
        invoice={invoice}
        contract={contract}
        company={company}
      />
      <ARInvoiceTable
        items={items}
        lastPage={page === totalPages}
        total={total}
      />
      <div className="font-bold text-18px text-right mt-auto mb-6 mr-6 pt-3 pr-2">
        Page {page} of {totalPages}
      </div>
    </div>
  );
};

export default ARInvoicePage;
