import { useQuery } from '@tanstack/react-query';
import { transformUTCtoLocale } from 'apps/tmr-frontend/src/utils/dateUtils';
import { formatUTCDate } from 'apps/tmr-frontend/src/utils/formatUtils';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useState } from 'react';
import ResumeCard from '../../../components/cards/ResumeCard';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import { ApiError } from '../../../Interfaces/Generic.interfaces';
import { fetchPRPeriodDates } from '../../../services/PayrollService';

type PayPeriodDatesProps = {
  storeDates: (periodStart: Date, periodEnd: Date) => void;
};

const PayPeriodDates = ({ storeDates }: PayPeriodDatesProps) => {
  const { selectedCompany } = useCompanyContext();
  const [errorMessage, setErrorMessage] = useState('');
  const { data, isLoading, isError } = useQuery({
    queryKey: ['getPRPeriodDates', selectedCompany],
    queryFn: ({ signal }) => fetchPRPeriodDates(selectedCompany!.id, signal),
    onSuccess: (data) => {
      storeDates(
        transformUTCtoLocale(data.periodStart),
        transformUTCtoLocale(data.periodEnd)
      );
    },
    onError: (error: ApiError) => {
      setErrorMessage(error?.response?.data?.message);
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  if (isLoading) {
    return (
      <div className="w-fit md:mr-auto ml-3">
        <ResumeCard>
          <div className="p-3 flex justify-content-center">
            <ProgressSpinner />
          </div>
        </ResumeCard>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="w-fit md:mr-auto ml-3">
        <ResumeCard>
          <div className="p-3 flex justify-content-center">
            <ErrorMessage
              content={
                errorMessage || 'Failed to obtain data! Please try again later.'
              }
            />
          </div>
        </ResumeCard>
      </div>
    );
  }

  return (
    <div className="w-fit md:mr-auto ml-3">
      <ResumeCard>
        <div className="p-3 flex justify-content-center">
          <div className="w-fit md:ml-auto">
            <div className="p-3 text-18px">
              <div className="flex justify-content-between sm:gap-8">
                <div>
                  <span className="font-bold mr-1">
                    Pay Period Timesheet Due:
                  </span>
                  {formatUTCDate(data.nextTimesheet)}
                </div>
              </div>
              <div className="flex justify-content-between sm:gap-8 mt-2">
                <div>
                  <span className="font-bold mr-1">Pay Period Start Date:</span>
                  {formatUTCDate(data.periodStart)}
                </div>
              </div>
              <div className="flex justify-content-between sm:gap-8 mt-2">
                <div>
                  <span className="font-bold mr-1">Pay Period End Date:</span>
                  {formatUTCDate(data.periodEnd)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ResumeCard>
    </div>
  );
};

export default PayPeriodDates;
