import { formatUTCDate } from '../../../../utils/formatUtils';
import React from 'react';
import { SCScheduleDates } from '../../../Interfaces/Accounting/Subcontracts.interfaces';

type SCScheduleProps = {
  scheduleDates: SCScheduleDates;
};

const SCSchedule = React.forwardRef<HTMLDivElement, SCScheduleProps>(
  ({ scheduleDates }, ref) => {
    const { nextCheck, worksheetDue } = scheduleDates;

    if (!nextCheck && !worksheetDue) {
      return (
        <div className="w-fit md:ml-auto">
          <div className="p-3 text-18px">
            This company has no subcontract payment schedule.
          </div>
        </div>
      );
    }

    return (
      <div className="w-fit md:ml-auto">
        <div className="p-3 text-18px">
          <div className="flex justify-content-between sm:gap-8">
            <div>Work Sheet Due</div>
            <div>{formatUTCDate(worksheetDue)}</div>
          </div>
          <div className="flex justify-content-between sm:gap-8 mt-4">
            <div>Check Date</div>
            <div>{formatUTCDate(nextCheck)}</div>
          </div>
        </div>
      </div>
    );
  }
);

export default SCSchedule;
