import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
} from 'chart.js';
import { BACKGROUND_COLORS, BORDER_COLORS } from '../../../../utils/graphConst';
import { SCPaymentSummary } from '../../../Interfaces/Accounting/Subcontracts.interfaces';

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale);

type SCResumeGraphProps = {
  payments: SCPaymentSummary[];
  groupType: string;
};

const SCResumeGraph = ({ payments, groupType }: SCResumeGraphProps) => {
  const colTypes = (type: string) => {
    switch (type) {
      case 'job':
        return payments.map((payment) => payment.JobName);
      case 'vendor':
        return payments.map((payment) => payment.VendorName);
      case 'pm':
        return payments.map((payment) => payment.Name);
      default:
        return [];
    }
  };

  const pieData = {
    labels: colTypes(groupType),
    datasets: [
      {
        data: payments.map((payment) => payment.PaidAmount),
        backgroundColor: BACKGROUND_COLORS,
        borderColor: BORDER_COLORS,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <Pie
      data={pieData}
      options={options}
      className="w-10rem h-10rem sm:w-18rem sm:h-18rem my-auto"
    />
  );
};

export default SCResumeGraph;
