import { useQuery } from '@tanstack/react-query';
import DropdownStyled from '../../components/inputs/StyledInputs/DropdownStyled';
import { useCompanyContext } from '../../context/CompanyContext';
import { fetchUserCompanies } from '../../services/UsersService';
import { auth } from '../../../firebase';
import { DropdownChangeParams } from 'primereact/dropdown';
import { useCookies } from 'react-cookie';
import { Company, CompanySource } from '../../Interfaces/User.interfaces';
import { useSearchParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';

const CompanySelector = () => {
  const [cookies, setCookie] = useCookies(['company']);
  const [searchParams, setSearchParams] = useSearchParams();
  const { selectedCompany, setSelectedCompany } = useCompanyContext();

  const changeCompanyCookie = (companyID: string) => {
    const expirationTime = new Date(Date.now() + 12 * 60 * 60 * 1000);
    setCookie('company', companyID, { expires: expirationTime, path: '/' });
  };

  const decryptCompany = (): string | null => {
    const companyParam = searchParams.get('company');
    const secretKey = process.env['NX_CRYPTO_PUBLIC_KEY'];

    let company = null;
    if (companyParam && secretKey) {
      try {
        const bytes = CryptoJS.AES.decrypt(companyParam, secretKey);
        company = bytes.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        console.error('Error decrypting company:', error);
      }
    }

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('company');

    setSearchParams(newSearchParams);
    return company;
  };

  const { data, isLoading, isError } = useQuery({
    queryKey: ['getUserCompanies'],
    queryFn: async () => {
      const tokenData = await auth.currentUser?.getIdTokenResult(true);
      const companies = tokenData?.claims['companyID'];
      return fetchUserCompanies(companies);
    },
    onSuccess: (data) => {
      data.sort((a: Company, b: Company) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      const companyID = decryptCompany();
      const companyData = data.find(
        (companyData: Company) => companyData.id === companyID
      );

      if (companyData) {
        changeCompanyCookie(companyData.id);
        setSelectedCompany(companyData);
      } else {
        const cookieCompany = data.find(
          (company: Company) => company.id === cookies.company
        );
        setSelectedCompany(cookieCompany || data[0]);
      }
    },
    refetchOnWindowFocus: false,
  });

  const handleSelecCompany = (id: string | null) => {
    const companyData = data.filter((company: Company) => company.id === id);
    setSelectedCompany(companyData[0]);
    changeCompanyCookie(companyData[0].id);
  };

  const getLabel = (company: Company): string => {
    if (company.source === CompanySource.ViewPoint)
      return company.alias ?? company.name;
    if (company.source === CompanySource.Yardi)
      return company.name + ' - ' + company.code;
    return company.name;
  };

  return (
    <DropdownStyled
      isTitle={true}
      filter={true}
      isLoading={isLoading}
      isDisabled={isError}
      options={data?.map((company: Company) => ({
        ...company,
        label: getLabel(company),
      }))}
      clearable={false}
      error={isError}
      placeholder="Select company"
      valueField="id"
      labelField="label"
      {...{
        value: selectedCompany ? selectedCompany.id : undefined,
        onChange: (e: DropdownChangeParams) => {
          handleSelecCompany(e.target.value);
        },
      }}
    />
  );
};

export default CompanySelector;
