import {
  formatCurrency,
  formatPercentage,
} from 'apps/tmr-frontend/src/utils/formatUtils';
import { Panel } from 'primereact/panel';
import { SLItem } from '../../../../Interfaces/Accounting/ACForms.interfaces';

type SLItemsSummaryProps = {
  slItems: SLItem[];
};

const SLItemsSummary = ({ slItems }: SLItemsSummaryProps) => {
  let total = 0;
  slItems.forEach((item) => {
    total += item.total ?? 0;
  });

  return (
    <Panel header="Schedule of Values" className="max-w-1200px mx-3 text-standard">
      <div className="flex flex-column row-gap-3">
        <div className="col-12 grid font-bold hidden lg:flex pb-0 text-center">
          <div className="col-1 pt-0 word-break-word">Line</div>
          <div className="col-2 pt-0 word-break-word">Phase Code</div>
          <div className="col-2 pt-0 word-break-word">Description</div>
          <div className="col-1 xl:col-2 pt-0">UoM</div>
          <div className="col-1 pt-0">Units</div>
          <div className="col-2 xl:col-1 pt-0">Price</div>
          <div className="col-2 pt-0">Total</div>
          <div className="col-1 pt-0">Retainage</div>
        </div>
        {slItems.map((item) => {
          return (
            <div className="col-12 grid" key={item.line}>
              <div className="col-12 sm:col-6 md:col-1 lg:col-1 lg:text-center">
                <span className="font-bold mr-1 lg:hidden">Line:</span>
                {item.line}
              </div>
              <div className="col-12 sm:col-6 md:col-4 lg:col-2 lg:text-center word-break-word">
                <span className="font-bold mr-1 lg:hidden">Phase Code:</span>
                {item.phase}
              </div>
              <div className="col-12 sm:col-6 md:col-4 lg:col-2 lg:text-center word-break-word">
                <span className="font-bold mr-1 lg:hidden sm:ml-2 md:ml-0">Description:</span>
                {item.description}
              </div>
              <div className="col-12 sm:col-6 md:col-3 lg:col-1 xl:col-2 lg:text-center">
                <span className="font-bold mr-1 lg:hidden">UoM:</span>
                {item.um}
              </div>
              <div className="col-12 sm:col-3 lg:col-1 lg:text-center">
                <div className="sm:ml-2 xl:ml-0">
                  <span className="font-bold mr-1 lg:hidden">Units:</span>
                  {item.units}
                </div>
              </div>
              <div className="col-12 sm:col-3 lg:col-2 xl:col-1 lg:text-right">
                <span className="font-bold mr-1 lg:hidden">Price:</span>
                {formatCurrency(item.price ?? 0)}
              </div>
              <div className="col-12 sm:col-3 lg:col-2 lg:text-right">
                <span className="font-bold mr-1 lg:hidden">Total:</span>
                {formatCurrency(item.total ?? 0)}
              </div>
              <div className="col-12 sm:col-3 lg:col-1 lg:text-right">
                <span className="font-bold mr-1 lg:hidden">Retainage:</span>
                {formatPercentage((item.retainage ?? 0) * 100)}
              </div>
            </div>
          );
        })}
      </div>
      <div className="px-2 font-bold sm:text-right mt-2 mb-1">
        Items Total: {formatCurrency(total)}
      </div>
    </Panel>
  );
};

export default SLItemsSummary;
