import React from 'react';

type FormErrorMessageProps = {
  errorMessage: string;
};

const FormErrorMessage = ({ errorMessage }: FormErrorMessageProps) => {
  return (
    <p role="alert" className="ml-2 text-standard p-error font-bold">
      {errorMessage}
    </p>
  );
};

export default FormErrorMessage;
