import React, { useCallback, useState } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { formatCurrency } from '../../../../utils/formatUtils';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import {
  JobCostRevenue,
  CostDetailArgs,
  CostType,
} from '../../../Interfaces/Accounting/JobsContracts.interface';
import JCCostDrilldownContainer from '../JCCostDrilldown/JCCostDrilldownContainer';
import JCBillReceiptsDrilldownContainer from '../JCBillReceiptsDrilldown/JCBillReceiptsDrilldownContainer';
import WrapperButton from '../../../components/buttons/WrapperButton';

type CostRevenueTableProps = {
  jobs: JobCostRevenue[];
  costTypes: CostType[];
};

const CostRevenueTable = React.forwardRef<
  HTMLDivElement,
  CostRevenueTableProps
>(({ jobs, costTypes }, ref) => {
  const [selectedCost, setSelectedCost] = useState<CostDetailArgs>();
  const [showDrilldown, setShowDrilldown] = useState(false);
  const [selectedContract, setSelectedContract] = useState<{
    contract: string;
    contractName: string;
  }>();
  const [showBillDrilldown, setShowBillDrilldown] = useState(false);

  const calcHeight = (rows: number) => {
    const headerHeight = 32;
    const footerHeight = 54;
    const rowHeight = 31;
    return headerHeight + footerHeight + rows * rowHeight + 3;
  };

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  const onCellClick = (job: string, jobName: string, costType: number) => {
    setSelectedCost({ job, jobName, costType });
    setShowDrilldown(true);
  };

  const hideDrilldown = () => {
    setSelectedCost(undefined);
    setShowDrilldown(false);
  };

  const onBilledCellClick = (contract: string, contractName: string) => {
    setShowBillDrilldown(true);
    setSelectedContract({ contract, contractName });
  };

  const hideBilledDrilldown = () => {
    setSelectedContract(undefined);
    setShowBillDrilldown(false);
  };

  const generateCostTypeCols = (costType: CostType) => {
    return (
      <Column
        field={costType.Description}
        header={costType.Description}
        body={(jc) => (
          <WrapperButton
            className="totalColor w-full text-right printColor"
            onClick={() => onCellClick(jc.Job, jc.JobName, costType.CostType)}
          >
            {formatCurrency(jc[costType.Description])}
          </WrapperButton>
        )}
        style={{ minWidth: '160px' }}
        headerClassName="tableHeader font-normal justify-content-center text-center"
        className="text-standard blackText tableCell"
        footerClassName="tableFooter block text-right border-top-2 mt-4"
        footer={(e) => {
          const records = e.props.value;
          let total = 0;
          records.forEach((record: JobCostRevenue) => {
            total += record[
              costType.Description as keyof JobCostRevenue
            ] as number;
          });

          return formatCurrency(total);
        }}
        sortable
      />
    );
  };

  let totalCost = 0;
  let billed = 0;
  jobs.forEach((contract) => {
    totalCost = totalCost + contract.TotalActualCost;
    billed = billed + contract.BilledAmount;
  });

  return (
    <div className="pdfDivFitContent">
      {selectedCost && (
        <JCCostDrilldownContainer
          visible={showDrilldown}
          hideDrilldown={hideDrilldown}
          costDetail={{ ...selectedCost }}
        />
      )}
      {selectedContract && (
        <JCBillReceiptsDrilldownContainer
          visible={showBillDrilldown}
          hideDrilldown={hideBilledDrilldown}
          {...selectedContract}
        />
      )}
      <Table
        id="jc-cost-revenue"
        ref={ref}
        data={jobs}
        className={`mx-3 dashboardOptionShadow z-5`}
        calcHeight={useCallback(calcHeight, [])}
        onRowMouseEnter={useCallback(onRowHover, [])}
      >
        <Column
          field="Job"
          header="Job #"
          style={{ minWidth: '110px' }}
          headerClassName="tableHeader font-normal"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="JobName"
          header="Description"
          body={(jc) => {
            return <div className="scroll-text">{jc.JobName}</div>;
          }}
          style={{ minWidth: '210px', maxWidth: '210px' }}
          headerClassName="tableHeader font-normal justify-content-center"
          className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName="overflow-x-visible tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
          footer="Total"
          sortable
        />
        {costTypes.map((costType) => {
          return generateCostTypeCols(costType);
        })}
        <Column
          field="TotalActualCost"
          header="Total Cost"
          body={(jc) => formatCurrency(jc.TotalActualCost)}
          style={{ minWidth: '160px' }}
          headerClassName="tableHeader font-normal text-center justify-content-center"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(totalCost)}
          sortable
        />
        <Column
          field="BilledAmount"
          header="Billed Amount"
          body={(jc) => (
            <WrapperButton
              className="totalColor w-full text-right printColor"
              onClick={() => onBilledCellClick(jc.Contract, jc.ContractName)}
            >
              {formatCurrency(jc.BilledAmount)}
            </WrapperButton>
          )}
          style={{ minWidth: '160px' }}
          headerClassName="tableHeader font-normal text-center"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(billed)}
          sortable
        />
      </Table>
    </div>
  );
});

const transactionsAreEqual = (
  prevTransactions: Readonly<CostRevenueTableProps>,
  nextTransactions: Readonly<CostRevenueTableProps>
) => {
  return prevTransactions.jobs === nextTransactions.jobs;
};

export default React.memo(CostRevenueTable, transactionsAreEqual);
