import { MODULES } from 'apps/tmr-frontend/src/utils/rolesConst';
import { formatUTCDate } from '../../../../utils/formatUtils';
import { APVendor } from '../../../Interfaces/Accounting/AccountsPayables.interfaces';
import { SCBillingDraft } from '../../../Interfaces/Accounting/Subcontracts.interfaces';
import { ITEMS_STATES } from '../SCFilters';

export const getSCReportTypeName = (id: string) => {
  const report = MODULES['subcontracts'].find((report) => report.value === id);

  return report?.label;
};

export const getSubcontractorName = (
  selectSubcontractor: string,
  subcontractorList: APVendor[]
) => {
  const subInfo = subcontractorList?.find(
    (sub) => sub.id === selectSubcontractor
  );

  return subInfo?.name;
};

export const getSubmittedDraft = (
  seledtedDraft: string,
  data: SCBillingDraft[]
) => {
  const draft = data?.find((draft) => draft.id === seledtedDraft);

  return `${formatUTCDate(draft?.updatedAt ?? '')} - ${draft?.userName}`;
};

export const getBillItemStatusLabel = (value: string) => {
  const itemState = ITEMS_STATES.find((state) => state.value === value);

  return itemState?.label;
};
