import React, { useCallback } from 'react';
import { formatCurrency, formatUTCDate } from '../../../../utils/formatUtils';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { CMTransaction } from '../../../Interfaces/Accounting/CashManagement.interfaces';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';

type CMTransactionsTableProps = {
  cmTransactions: CMTransaction[];
  balanceStart: number;
  startDescription: string;
};

const CMTransactionsTable = React.forwardRef<
  HTMLDivElement,
  CMTransactionsTableProps
>(({ cmTransactions, balanceStart, startDescription }, ref) => {
  const calcHeight = (rows: number) => {
    const headerHeight = 32;
    const footerHeight = 54;
    const rowHeight = 31;
    return headerHeight + footerHeight + rows * rowHeight + 3;
  };

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  const rowClass = (data: unknown) => {
    const transaction = data as CMTransaction;
    return {
      'balance-row': !transaction.CMRef,
    };
  };

  const isDebit = (transaction: CMTransaction) => {
    return transaction.Amount < 0;
  };

  const formatAmount = (transaction: CMTransaction, compareDebit: boolean) => {
    if (transaction.Amount === null || transaction.Amount === undefined) {
      return null;
    }

    let shouldFormat = true;

    if (compareDebit) {
      shouldFormat = isDebit(transaction);
    } else {
      shouldFormat = !isDebit(transaction);
    }

    return shouldFormat
      ? formatCurrency(transaction.Amount)
      : formatCurrency(0);
  };

  let balanceAC = balanceStart;
  cmTransactions.forEach((transaction: CMTransaction) => {
    if (transaction.Void !== 'Y') {
      transaction.transBalance = balanceAC + transaction.Amount;
      balanceAC = transaction.transBalance;
    } else {
      transaction.transBalance = 0;
    }
  });

  return (
    <Table
      id="cm-table"
      data={[
        {
          transBalance: balanceStart,
          Description: startDescription,
        },
        ...cmTransactions,
      ]}
      className="mx-3 dashboardOptionShadow tableFirstLineStyled"
      calcHeight={calcHeight}
      rowClass={rowClass}
      onRowMouseEnter={useCallback(onRowHover, [])}
      hideColumns={true}
    >
      <Column
        headerClassName={`tableHeader`}
        className="tableCell p-0 printHide"
        footerClassName="tableFooter "
      />
      <Column
        headerClassName={`tableHeader`}
        className="tableCell p-0 printHide"
        footerClassName="tableFooter"
      />
      <Column
        field="CMRef"
        header="Trans/ChkDep#"
        style={{ minWidth: '150px' }}
        headerClassName={`tableHeader font-normal`}
        className={`justify-content-center text-standard blackText tableCell`}
        footerClassName="tableFooter"
        sortable
      />
      <Column
        field="ActDate"
        header="Date"
        body={(transaction) =>
          transaction.ActDate ? formatUTCDate(transaction.ActDate) : null
        }
        style={{ minWidth: '120px' }}
        headerClassName="tableHeader font-normal"
        className="justify-content-center text-standard blackText tableCell"
        footerClassName="tableFooter"
        sortable
      />
      <Column
        field="Type"
        header="Type"
        style={{ minWidth: '80px' }}
        headerClassName="tableHeader font-normal"
        className="justify-content-center text-standard blackText tableCell"
        footerClassName="tableFooter"
        sortable
      />
      <Column
        field="Description"
        header="Description"
        body={(cm) => {
          return <div className="scroll-text">{cm.Description}</div>;
        }}
        style={{ minWidth: '400px', maxWidth: '400px' }}
        headerClassName="tableHeader font-normal justify-content-center"
        className=" text-standard blackText tableCell title overflow-x-hidden white-space-nowrap checkOverflow"
        footerClassName="tableFooter"
        sortable
      />
      <Column
        field="Amount"
        header="Debit(-)"
        body={(transaction) => formatAmount(transaction, true)}
        style={{ minWidth: '150px' }}
        headerClassName="tableHeader font-normal justify-content-center"
        className="justify-content-end text-standard blackText tableCell"
        footer="Total"
        footerClassName="tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
        sortable
      />
      <Column
        field="Amount"
        header="Credit(+)"
        body={(transaction) => formatAmount(transaction, false)}
        style={{ minWidth: '150px' }}
        headerClassName="tableHeader font-normal justify-content-center"
        className="justify-content-end text-standard blackText tableCell"
        footerClassName="tableFooter border-top-2 mt-4"
        sortable
      />
      <Column
        field="transBalance"
        header="Balance"
        body={(transaction) =>
          transaction.Void === 'Y'
            ? 'Void'
            : formatCurrency(transaction.transBalance)
        }
        style={{ minWidth: '150px' }}
        headerClassName="tableHeader font-normal justify-content-center"
        className="justify-content-end text-standard blackText tableCell result"
        footer={balanceAC.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })}
        footerClassName="tableFooter block text-right border-top-2 mt-4"
        sortable
      />
      <Column
        field="ClearDate"
        header="Cleared Date"
        body={(transaction) =>
          transaction.ClearDate ? formatUTCDate(transaction.ClearDate) : null
        }
        style={{ minWidth: '170px' }}
        headerClassName="tableHeader font-normal"
        className="justify-content-center text-standard blackText tableCell"
        footerClassName="tableFooter"
        sortable
      />
      <Column
        headerClassName={`tableHeader`}
        className="tableCell p-0 printHide"
        footerClassName="tableFooter"
      />
      <Column
        headerClassName={`tableHeader`}
        className="tableCell p-0 printHide"
        footerClassName="tableFooter"
      />
    </Table>
  );
});

const transactionsAreEqual = (
  prevTransactions: Readonly<CMTransactionsTableProps>,
  nextTransactions: Readonly<CMTransactionsTableProps>
) => {
  return (
    prevTransactions.cmTransactions === nextTransactions.cmTransactions &&
    prevTransactions.balanceStart === nextTransactions.balanceStart
  );
};

export default React.memo(CMTransactionsTable, transactionsAreEqual);
