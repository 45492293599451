import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ResumeCard from '../../components/cards/ResumeCard';
import ErrorMessage from '../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../context/CompanyContext';
import { ContractBillingResume } from '../../Interfaces/Accounting/ContractBillings.interface';
import { fetchAccountingDashboardContracts } from '../../services/DataService';
import CBResumeTable from '../contractBillings/CBResume/CBResumeTable';

const DashboardContractBillings = () => {
  const { selectedCompany } = useCompanyContext();
  const [contracts, setContracts] = useState<ContractBillingResume[]>([]);

  const { data, isError } = useQuery({
    queryKey: ['getAccountingDashboardContractsData', selectedCompany],
    queryFn: ({ signal }) =>
      fetchAccountingDashboardContracts(selectedCompany!, signal),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(() => {
    if (data?.contractBillings) {
      setContracts(data.contractBillings);
    }
  }, [data]);

  if (isError) {
    return (
      <ResumeCard>
        <div className="relative p-5 px-4 flex flex-column h-full">
          <Link
            to={`/accounting-dashboard/contract-billings`}
            className="w-fit"
          >
            <div className="text-24px font-bold cursor-pointer totalColor hover:underline w-fit">
              Contract Billings
            </div>
          </Link>
          <div className="my-8 py-3">
            <ErrorMessage
              content={'Failed to obtain data! Please try again later.'}
            />
          </div>
        </div>
      </ResumeCard>
    );
  }

  return (
    <ResumeCard>
      <div className="p-3 px-4 flex flex-column">
        <Link to={`/accounting-dashboard/contract-billings`} className="w-fit">
          <div className="text-24px font-bold cursor-pointer totalColor hover:underline">
            Contract Billings
          </div>
        </Link>
        <div className="pt-3 text-xl flex flex-wrap ">
          <CBResumeTable resume={contracts} />
        </div>
      </div>
    </ResumeCard>
  );
};

export default DashboardContractBillings;
