import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormErrorMessage from '../../components/messages/FormErrorMessage';

export type LoginFormData = {
  email: string;
  password: string;
};

const LoginForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <React.Fragment>
      <div>
        <label htmlFor="email" className="text-standard block mb-2">
          Email
        </label>
        <Controller
          defaultValue=""
          control={control}
          name="email"
          rules={{
            required: {
              value: true,
              message: 'Email is a required field',
            },
          }}
          render={({ field }) => (
            <span className="p-input-icon-left w-full">
              <i className="pi pi-user top-0 mt-2 pt-1 text-20px" />
              <InputText
                id="email"
                type="email"
                className={`w-full blackText text-standard ${
                  errors['email'] && 'p-invalid'
                }`}
                {...field}
              />
            </span>
          )}
        />
        {errors['email'] && (
          <FormErrorMessage errorMessage={errors['email'].message as string} />
        )}
      </div>
      <div className="my-5">
        <label htmlFor="password" className="text-standard block mb-2">
          Password
        </label>
        <Controller
          defaultValue=""
          control={control}
          name="password"
          rules={{
            required: {
              value: true,
              message: 'Password is a required field',
            },
          }}
          render={({ field }) => {
            const { ref, ...fieldProps } = field;
            return (
              <Password
                className="w-full"
                inputId="password"
                inputClassName={`w-full blackText text-standard ${
                  errors['password'] && 'p-invalid'
                }`}
                feedback={false}
                toggleMask
                inputRef={ref}
                {...fieldProps}
              />
            );
          }}
        />
        {errors['password'] && (
          <FormErrorMessage
            errorMessage={errors['password'].message as string}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default LoginForm;
