import { animateCellText } from '../../../../utils/htmlUtils';
import { useNavigate } from 'react-router-dom';
import { formatUTCDate } from '../../../../utils/formatUtils';
import OptionCard from '../../../components/cards/OptionCard';
import { FolderSummary } from '../../../Interfaces/Accounting/FinancialStatements.interface';
import FolderMenu from '../FolderMenuOptions/FolderMenu';
import { MouseEvent } from 'react';
import { useRolesAccessContext } from '../../../context/RolesAccessContext';
import WrapperButton from '../../../components/buttons/WrapperButton';

type FolderProps = {
  folder: FolderSummary;
  color?: string;
  fetchFolders?: () => void;
};

const Folder = ({ folder, color, fetchFolders }: FolderProps) => {
  const navigate = useNavigate();
  const { rolesAcess } = useRolesAccessContext();
  const canEdit = rolesAcess?.find(
    (acess) => acess.report === 'financial_statements' && acess.editable
  );

  const onHover = (e: MouseEvent) => {
    const cell = e.target as HTMLElement;
    const divContainer = cell.closest('.folderBox') as HTMLElement;

    animateCellText(divContainer);
  };

  return (
    <WrapperButton
      className="w-15rem h-13rem text-center relative cursor-pointer folderBox text-standard"
      onClick={(event) => {
        const target = event.target as HTMLElement;
        const folderBox = target.closest('.folderBox');

        if (folderBox) {
          navigate(folder.path);
        }
      }}
    >
      <div className="h-full" onMouseEnter={onHover} role="button" tabIndex={0}>
        <OptionCard>
          {canEdit && (
            <FolderMenu folder={folder} fetchFolders={fetchFolders} />
          )}
          <div className="h-8rem flex">
            <i
              className="pi pi-folder text-70px m-auto"
              style={{ color: color ?? 'black' }}
            />
          </div>
          <div className="capitalize overflow-x-hidden white-space-nowrap checkOverflow">
            <div className="scroll-text">{folder.name}</div>
          </div>
          {folder.lastUploadAt && (
            <div className="mt-2">
              Last upload: {formatUTCDate(folder.lastUploadAt)}
            </div>
          )}
        </OptionCard>
      </div>
    </WrapperButton>
  );
};

export default Folder;
