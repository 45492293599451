import { Menu } from 'primereact/menu';
import React, { useRef, useState } from 'react';
import WrapperButton from '../../../components/buttons/WrapperButton';
import { FolderSummary } from '../../../Interfaces/Accounting/FinancialStatements.interface';
import FolderRemoval from './FolderRemoval';
import FolderUpdate from './FolderUpdate';

type FolderMenuProps = {
  folder: FolderSummary;
  fetchFolders?: () => void;
};

const FolderMenu = ({ folder, fetchFolders }: FolderMenuProps) => {
  const menuRef = useRef<Menu>(null);
  const [visible, setVisible] = useState(false);
  const [removeVisible, setRemoveVisible] = useState(false);

  const items = [
    {
      label: 'Edit',
      command: () => {
        setVisible(true);
      },
      icon: 'pi pi-fw pi-pencil',
    },
    {
      label: 'Remove',
      icon: 'pi pi-fw pi-trash',
      command: () => {
        setRemoveVisible(true);
      },
    },
  ];

  const onHide = () => {
    setVisible(false);
  };

  const onRemovalHide = () => {
    setRemoveVisible(false);
  };

  return (
    <React.Fragment>
      <WrapperButton
        className="absolute right-0 mr-3"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          menuRef.current?.toggle(e);
        }}
      >
        <i className="pi pi-ellipsis-v p-1 border-circle hover:bg-gray-300" />
      </WrapperButton>
      <Menu ref={menuRef} model={items} popup />
      {visible && (
        <FolderUpdate
          visible={visible}
          onHide={onHide}
          folder={folder}
          fetchFolders={fetchFolders}
        />
      )}
      {removeVisible && (
        <FolderRemoval
          visible={removeVisible}
          onHide={onRemovalHide}
          folder={folder}
          fetchFolders={fetchFolders}
        />
      )}
    </React.Fragment>
  );
};

export default FolderMenu;
