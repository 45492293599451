import { Panel } from 'primereact/panel';
import { useFormContext } from 'react-hook-form';
import { ContractAttachments } from '../../../Interfaces/Accounting/ACForms.interfaces';

const ContractAttachmentsSummary = () => {
  const { getValues } = useFormContext<ContractAttachments>();
  const { attachments } = getValues();

  return (
    <Panel header="Attachments" className="max-w-600px mx-3 text-standard">
      <div className="grid prevent-break-children">
        {attachments && attachments?.length > 0
          ? attachments.map((file) => {
              return (
                <div key={file.name} className="col-12">
                  {file.name}
                </div>
              );
            })
          : 'No attachments were provided.'}
        <div className="col-12"></div>
      </div>
    </Panel>
  );
};

export default ContractAttachmentsSummary;
