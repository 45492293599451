import { useRef } from 'react';
import { BASE_PDF_STYLES } from '../../../../utils/reportUtils';
import SuccessFormMessage from '../../../components/messages/SuccessFormMessage';
import ReportButton from '../../../components/report/ReportButton';
import COSummary from './COSummary';

const COSuccess = () => {
  const summaryRef = useRef<HTMLDivElement | null>(null);

  return (
    <div>
      <SuccessFormMessage
        message="The Change Orders data has been sent to the AR Department!"
        redirectUrl="/accounting-dashboard/new"
        redirectMessage="Click here to return to the forms list"
      />
      <div
        id="co-success"
        className="w-fit mx-auto flex flex-column gap-3"
        ref={summaryRef}
      >
        <div className="text-30px font-bold mt-6 flex justify-content-between align-items-end">
          CO Summary:
          <div className="text-23px cursor-pointer printHide h-fit">
            <ReportButton
              elementToPrintRef={summaryRef}
              fileName="Change_Orders"
              pdfStyles={BASE_PDF_STYLES}
              noButton={true}
              hideExcel={true}
              isPdfPortrait={true}
              widthElementID="co-success"
              minWidth={800}
            />
          </div>
        </div>
        <COSummary />
      </div>
    </div>
  );
};

export default COSuccess;
