type FormInfoProps = {
  label: string;
  content: React.ReactNode;
};

const FormInfo = ({ label, content }: FormInfoProps) => {
  return (
    <div>
      <span className="font-bold mr-1">{label}:</span>
      {content}
    </div>
  );
};

export default FormInfo;
