import React from 'react';
import AppTopbar from '../../features/appTopBar/AppTopbar';
import UserIcon from '../../../assets/layout/images/icons/Icon awesome-user-alt.svg';
import BadgeIcon from '../../../assets/layout/images/icons/Icon-badge.svg';
import MenuOptionCard from '../../components/cards/MenuOptionCard';

const menu = [
  { label: 'Users', icon: UserIcon, disabled: false },
  { label: 'Roles', icon: BadgeIcon, disabled: false },
];

const AdminDashboard = () => {
  return (
    <div className="layout-menu-static">
      <AppTopbar active={false} mode={'desktop'} />
      <div className="max-w-933px mx-auto content grid dashboard">
        <div className="grid row-gap-5 mt-8 pt-5 w-full">
          {menu.map((x) => (
            <div
              className="col-12 md:col-6 lg:col-4 flex justify-content-center"
              key={x.label}
            >
              <MenuOptionCard
                label={x.label}
                disabled={x.disabled}
                icon={x.icon}
                baseURL={'/admin'}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
