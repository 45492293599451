import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { set, useWatch } from 'react-hook-form';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import { fetchCashManagementHistorcalData } from '../../../services/CashManagementService';
import { CashManagementFilterFields } from '../CashManagementFilters';
import CMTransactionBluwaiTable from './CMTransactionBluwai';

const CMTransactionBluwaiTableContainer = React.forwardRef<HTMLDivElement>(
  (props, ref) => {
    const { selectedCompany } = useCompanyContext();
    const filters = useWatch<CashManagementFilterFields>();

    const { data, isFetching, isError } = useQuery({
      queryKey: [
        'getCashManagementTransactionBluwai',
        filters.account,
        filters.dates,
        filters.info,
        selectedCompany,
      ],
      queryFn: ({ signal }) => {
        return fetchCashManagementHistorcalData(
          {
            source: selectedCompany!.source,
            companyID: selectedCompany!.id,
            accountId: filters.account,
            dates: filters.dates,
            info: filters.info || undefined,
          },
          signal
        );
      },
      refetchOnWindowFocus: false,
      enabled: !!(selectedCompany && filters.account),
    });

    if (isFetching || !(selectedCompany && filters.account))
      //CHECK with Luis if a company doesn't have any account yet like paddr the spinnig never disappears
      return (
        <div className="text-center mx-auto mt-3">
          <ProgressSpinner />
        </div>
      );

    if (isError)
      return (
        <ErrorMessage
          content={'Failed to obtain data! Please try again later.'}
        />
      );

    return (
      <CMTransactionBluwaiTable
        ref={ref}
        transactions={data.transactions ?? []}
        startBalance={data.startBalance}
      />
    );
  }
);

export default CMTransactionBluwaiTableContainer;
