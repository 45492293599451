import React from 'react';
import { useWatch } from 'react-hook-form';
import { useCompanyContext } from '../../../context/CompanyContext';
import { useQuery } from '@tanstack/react-query';
import { fecthCBContracts } from '../../../services/ContractBillingsService';
import {
  getContractStateLabel,
  getJCPhasesLabel,
  getJCPmNames,
  getJCReportTypeName,
  getJobStateLabel,
  getSubmittedDraft,
} from './JCReportUtils';
import { JobContractFilters } from '../JCFilters';
import { formatDate } from '../../../../utils/formatUtils';
import {
  getContractName,
  getContractNames,
  getJobDescription,
} from '../../../../utils/reportUtils';
import {
  fecthJobs,
  fetchJCJobPhases,
  fetchJCJobProjectManagers,
  fetchSubmittedProjectedCosts,
} from '../../../services/JobsContractsService';

const JCReportHeader = () => {
  const { selectedCompany } = useCompanyContext();
  const filters = useWatch<JobContractFilters>();

  const { data } = useQuery({
    queryKey: ['getContracts', selectedCompany],
    queryFn: ({ signal }) =>
      fecthCBContracts({ companyID: selectedCompany!.id }, signal),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const jobs = useQuery({
    queryKey: ['getJCJobs', selectedCompany, filters.jobState],
    queryFn: ({ signal }) =>
      fecthJobs(
        {
          companyID: selectedCompany!.id,
          status: filters.jobState === 'active' ? [1] : [2, 3],
        },
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const phases = useQuery({
    queryKey: ['getJobCostPhases', selectedCompany, filters.job],
    queryFn: ({ signal }) =>
      fetchJCJobPhases(
        {
          companyID: selectedCompany!.id,
          job: filters.job ?? '',
          hasCost: true,
        },
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const analysisContracts = useQuery({
    queryKey: ['getAnalysisContracts', selectedCompany, filters.contractState],
    queryFn: ({ signal }) =>
      fecthCBContracts({ companyID: selectedCompany!.id }, signal),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const pms = useQuery({
    queryKey: ['getJobPMs', selectedCompany],
    queryFn: ({ signal }) =>
      fetchJCJobProjectManagers(selectedCompany!.id, signal),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const submittedCosts = useQuery({
    queryKey: ['getSubmittedProjectedCosts', selectedCompany, filters.job],
    queryFn: ({ signal }) =>
      fetchSubmittedProjectedCosts(
        selectedCompany!.id,
        filters.job ?? '',
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  return (
    <div className="blackText printMedia printShow">
      <div className="text-30px text-center mt-5">{selectedCompany!.name}</div>
      <div className="text-28px text-center">Jobs & Contracts Report</div>
      <div className="text-17px ml-3 mt-2">
        <span className="font-bold text-20px">Search criteria:</span>
        <div className="ml-3">
          <div>
            <span className="font-bold mr-1">Report Type:</span>
            {getJCReportTypeName(filters.reportType ?? '')}
          </div>
          {filters.contractState && filters.reportType === 'contractAnalysis' && (
            <div>
              <span className="font-bold mr-1">Contract State:</span>
              {getContractStateLabel(filters.contractState)}
            </div>
          )}
          {filters.jobState && filters.reportType === 'costRevenue' && (
            <div>
              <span className="font-bold mr-1">Job State:</span>
              {getJobStateLabel(filters.jobState)}
            </div>
          )}
          {filters.pms &&
            pms.data &&
            filters.reportType === 'contractAnalysis' && (
              <div>
                <span className="font-bold mr-1">Project Managers:</span>
                {getJCPmNames(filters.pms, pms.data)}
              </div>
            )}
          {filters.contracts && filters.reportType === 'contractAnalysis' && (
            <React.Fragment>
              <div>
                <span className="font-bold mr-1">Contracts:</span>
                {filters.contracts.join(', ')}
              </div>
              <div>
                <span className="font-bold mr-1">Contract Names:</span>
                {getContractNames(filters.contracts, analysisContracts.data)}
              </div>
            </React.Fragment>
          )}
          {filters.contract && data && (
            <React.Fragment>
              <div>
                <span className="font-bold mr-1">Contract:</span>
                {filters.contract}
              </div>
              <div>
                <span className="font-bold mr-1">Job Name:</span>
                {getContractName(filters.contract, data || [])}
              </div>
            </React.Fragment>
          )}
          {filters.job && jobs.data && (
            <React.Fragment>
              <div>
                <span className="font-bold mr-1">Job:</span>
                {filters.job}
              </div>
              <div>
                <span className="font-bold mr-1">Job Name:</span>
                {getJobDescription(filters.job, jobs.data || [])}
              </div>
            </React.Fragment>
          )}
          {filters.submittedID && (
            <div>
              <span className="font-bold mr-1">Submitted Draft:</span>
              {getSubmittedDraft(filters.submittedID, submittedCosts.data)}
            </div>
          )}
          {filters.phases && filters.phases.length > 0 && (
            <div>
              <span className="font-bold mr-1">Phases:</span>
              {getJCPhasesLabel(filters.phases, phases.data || [])}
            </div>
          )}
          {filters.startDate && (
            <div>
              <span className="font-bold mr-1">Start Date:</span>
              {formatDate(filters.startDate)}
            </div>
          )}
          {filters.endDate && (
            <div>
              <span className="font-bold mr-1">End Date:</span>
              {formatDate(filters.endDate)}
            </div>
          )}
          {filters.info && (
            <div>
              <span className="font-bold">Search term:</span> {filters.info}
            </div>
          )}
        </div>
      </div>
      <div className="ml-3 text-17px mb-5">
        <span className="font-bold mr-1">Created at:</span>
        {new Date().toLocaleString()}
      </div>
    </div>
  );
};

export default JCReportHeader;
