import { useMutation, useQuery } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { Toast } from 'primereact/toast';
import RegisterForm, {
  RegisterFormFields,
} from '../../../features/user/RegisterForm';
import firebaseErrorMessages from '../../../components/errorMessages/firebase';
import { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import RemoveUserButton from '../../../features/user/RemoveUserButton';
import SuccessMessage from '../../../features/user/SuccessMessage';
import {
  deleteUser,
  getUser,
  putUsers,
  usersArgs,
} from '../../../services/UsersService';
import { User } from '../../../Interfaces/User.interfaces';
import LoadingButton from '../../../components/inputs/LoadingButton';
import { processAccessForm } from 'apps/tmr-frontend/src/utils/roleUtils';

const UserUpdate = () => {
  const navigate = useNavigate();
  const { userID } = useParams();
  const toast = useRef<Toast>(null);
  const formMethods = useForm<RegisterFormFields>();
  const { handleSubmit, setValue, watch } = formMethods;
  const [user, setUser] = useState<User>();
  const access = watch('access');

  const userResult = useQuery({
    queryKey: ['getUser', userID],
    queryFn: ({ signal }) => {
      return getUser(userID ?? '', signal);
    },
    onSuccess: (data) => {
      setUser(data);
      onGetUserSuccess(data);
    },
    refetchOnWindowFocus: false,
  });

  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: (data: usersArgs) => {
      return putUsers(userID ?? '', data);
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as { code: string };
      showToast(
        'error',
        toast,
        'Update User Error',
        firebaseErrorMessages[errorData?.code] ||
          'An error ocurred! please try again later',
        3000
      );
    },
  });

  const deleteUserResult = useMutation({
    mutationFn: () => {
      return deleteUser(userID ?? '');
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as { code: string };
      showToast(
        'error',
        toast,
        'Remove User Error',
        firebaseErrorMessages[errorData?.code] ||
          'An error ocurred! please try again later',
        3000
      );
    },
  });

  const onGetUserSuccess = (data: User) => {
    setValue('firstname', data.firstName);
    setValue('lastname', data.lastName);
    setValue('email', data.email);
    setValue('isAdmin', data.isAdmin ? 'yes' : 'no');
    setValue('company', data.companyID);
    setValue('userType', data.type);
    setValue('roles', data.roles);
    setValue('vpUser', data.vpUser);
  };

  const onSubmit = (data: RegisterFormFields) => {
    const { email, company, access, ...otherData } = data;
    const list = processAccessForm(access);

    const payload = {
      ...otherData,
      isAdmin: otherData.isAdmin === 'yes',
      roles: otherData.roles || [],
      company: company?.length ? company : undefined,
      access: list,
    };

    mutate(payload);
  };

  useEffect(() => {
    if (access && user?.access) {
      access.forEach((acc, index) => {
        const report = acc.report;
        const match = user.access?.find(
          (element) =>
            element.report === report.replace(/ /g, '_').toLocaleLowerCase()
        );
        if (match) {
          const { editable, shouldApprove, shouldNotify, approvalOrder } =
            match;
          setValue(`access.${index}.selected`, true);

          if (editable != null) {
            setValue(`access.${index}.editable`, editable || 'view-only');
          }

          setValue(`access.${index}.shouldNotify`, shouldNotify ?? undefined);
          setValue(
            `access.${index}.shouldApprove`,
            approvalOrder ?? (shouldApprove || undefined)
          );
        }
      });
    }
  }, [access, user]);

  if (userResult.isFetching) {
    return (
      <div className="flex mx-auto">
        <ProgressSpinner />
      </div>
    );
  }

  if (userResult.error) {
    const error = userResult.error as AxiosError;
    const errorData = error?.response?.data as { message: string };
    return (
      <div className="pt-8">
        <ErrorMessage
          content={
            errorData.message ||
            'Failed to obtain user data! Please try again later.'
          }
        />
      </div>
    );
  }

  return (
    <div className="flex flex-column justify-content-center gap-5 pt-8">
      <ErrorToast toastRef={toast} />
      <div className="text-35px font-bold text-center mb-5 relative">
        <div>Update User</div>
        {!isSuccess && !deleteUserResult.isSuccess && (
          <div className="flex justify-content-center lg:absolute top-0 right-0 mt-4 lg:mt-0 lg:mr-8">
            <RemoveUserButton
              onAccept={deleteUserResult.mutate}
              isLoading={deleteUserResult.isLoading}
            />
          </div>
        )}
      </div>
      {!isSuccess && !deleteUserResult.isSuccess && (
        <FormProvider {...formMethods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="max-w-600px mx-auto flex flex-column gap-5"
          >
            <RegisterForm emailDisabled={true} />
            <div className="flex flex-wrap gap-2 mb-3">
              <Button
                onClick={() => {
                  navigate('/admin/users');
                }}
                className="w-15rem bluwaiRed border-0 flex m-auto py-3"
              >
                <span className="mx-auto text-22px text-white">Cancel</span>
              </Button>
              <LoadingButton
                isLoading={isLoading}
                label="Update"
                className="w-15rem bluwaiBlue border-0 flex m-auto py-3"
                fontSize="text-22px"
              />
            </div>
          </form>
        </FormProvider>
      )}
      {isSuccess && <SuccessMessage message="The user has been updated!" />}
      {deleteUserResult.isSuccess && (
        <SuccessMessage message="The user has been removed!" />
      )}
    </div>
  );
};

export default UserUpdate;
