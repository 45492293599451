import BluwaiFavicon from '../../../../assets/layout/images/bluwai_Favicon_blue_red.png';
import K2 from '../../../../assets/layout/images/CompanyLogos/2K-LOGO.jpg';
import CUA from '../../../../assets/layout/images/CompanyLogos/CUA-LOGO.jpg';
import ECM from '../../../../assets/layout/images/CompanyLogos/ECM New Logo.jpg';
import GLM from '../../../../assets/layout/images/CompanyLogos/GLMLogo2.jpg';
import LPT from '../../../../assets/layout/images/CompanyLogos/LPTlogo.jpg';
import PDT from '../../../../assets/layout/images/CompanyLogos/PDT.png';
import RD from '../../../../assets/layout/images/CompanyLogos/RD.png';
import WH from '../../../../assets/layout/images/CompanyLogos/WHLogo.jpg';
import { useCompanyContext } from '../../../context/CompanyContext';

const CompanyLogo = {
  '1': BluwaiFavicon,
  '10': K2,
  '30': CUA,
  '40': ECM,
  '60': GLM,
  '70': LPT,
  '71': PDT,
  '80': RD,
  '90': WH,
};

const ARInvoiceCompanyLogo = () => {
  const { selectedCompany } = useCompanyContext();
  const logo = CompanyLogo[selectedCompany!.id as keyof typeof CompanyLogo];

  return logo ? (
    <img src={logo} alt="Company Logo" className="w-full h-auto" />
  ) : null;
};

export default ARInvoiceCompanyLogo;
