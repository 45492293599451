import React from 'react';
import { Sidebar } from 'primereact/sidebar';
import { FormProvider, useForm } from 'react-hook-form';
import { fetchAccountsPayablesFilters } from '../../accountsPayables/APFilters';
import JCPayablePerJobDrilldown from './JCPayablePerJobDrilldown';

type JCPayablePerJobDrilldownContainerProps = {
  visible: boolean;
  hideDrilldown: () => void;
  contract: string;
  contractName: string;
  showRetainage: boolean;
  showOpenAmount: boolean;
};

const JCPayablePerJobDrilldownContainer = ({
  visible,
  hideDrilldown,
  contract,
  contractName,
  showRetainage,
  showOpenAmount,
}: JCPayablePerJobDrilldownContainerProps) => {
  const methods = useForm<fetchAccountsPayablesFilters>({
    defaultValues: { reportType: 'openPayablesPerJob', jobs: [contract] },
  });

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={hideDrilldown}
      className="w-full max-w-1150px"
    >
      <FormProvider {...methods}>
        <JCPayablePerJobDrilldown
          contract={contract}
          contractName={contractName}
          showRetainage={showRetainage}
          showOpenAmount={showOpenAmount}
        />
      </FormProvider>
    </Sidebar>
  );
};

export default JCPayablePerJobDrilldownContainer;
