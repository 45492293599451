import React, { useCallback, useState } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { formatCurrency } from '../../../../utils/formatUtils';
import {
  DataTableExpandedRows,
  DataTableRowClickEventParams,
  DataTableRowMouseEventParams,
  DataTableRowToggleParams,
} from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import SCItemStatusContainer from './SCItemStatusContainer';
import { SCPaymentStatus } from '../../../Interfaces/Accounting/Subcontracts.interfaces';
import Attachment from '../../../components/attachment/Attachment';
import TooltipContent from '../../../components/messages/TooltipContent';
import SCPayableAttachments from './SCPayableAttachments';

type SCStatusTableProps = {
  subcontracts: SCPaymentStatus[];
};

const SCStatusTable = React.forwardRef<HTMLDivElement, SCStatusTableProps>(
  ({ subcontracts }, ref) => {
    const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows>({});

    const calcHeight = (rows: number) => {
      const headerHeight = 49;
      const footerHeight = 54;
      const rowHeight = 42;
      return headerHeight + footerHeight + rows * rowHeight + 3;
    };

    const rowClassName = (data: SCPaymentStatus) => {
      const ref = data.SL;
      const isExpanded = expandedRows[ref];
      return isExpanded ? 'cursor-pointer expandedRow' : 'cursor-pointer';
    };

    const rowExpansionTemplate = (data: SCPaymentStatus) => {
      return <SCItemStatusContainer sl={data.SL} />;
    };

    const onRowHover = (e: DataTableRowMouseEventParams) => {
      const cell = e.originalEvent.target as HTMLElement;
      const row = cell.closest('tr') as HTMLElement;
      animateCellText(row);
    };

    const onRowClick = (e: DataTableRowClickEventParams) => {
      const expanded = { ...expandedRows };
      const ref = e.data.SL;
      if (expanded[ref]) {
        delete expanded[ref];
        setExpandedRows(expanded);
      } else {
        expanded[ref] = true;
        setExpandedRows(expanded);
      }
    };

    const onRowToogle = (e: DataTableRowToggleParams) => {
      setExpandedRows(e.data as DataTableExpandedRows);
    };

    let curr = 0;
    let inv = 0;
    let paid = 0;
    let unpaid = 0;
    let retainage = 0;
    let balance = 0;
    subcontracts.forEach((item: SCPaymentStatus) => {
      curr += item.CurCost;
      inv += item.InvCost;
      paid += item.PayablePaid;
      unpaid += item.PayableUnpaid;
      retainage += item.RetainageUnpaid;
      balance += item.CommittedBalance;
    });

    return (
      <Table
        id="sc-status"
        ref={ref}
        data={subcontracts}
        className="mx-3 dashboardOptionShadow with-expanded dobleHeader"
        calcHeight={calcHeight}
        dataKey="SL"
        expandedRows={expandedRows}
        rowExpansionTemplate={rowExpansionTemplate}
        onRowClick={onRowClick}
        onRowToggle={onRowToogle}
        rowClassName={rowClassName}
        onRowMouseEnter={useCallback(onRowHover, [])}
        hideColumns={true}
      >
        <Column
          style={{ minWidth: '2rem', maxWidth: '2rem' }}
          headerClassName={`tableHeader`}
          className="tableCell p-0 printHide"
          footerClassName="tableFooter"
        />
        <Column
          expander={true}
          style={{ minWidth: '50px', maxWidth: '50px' }}
          headerClassName={`tableHeader font-normal`}
          className={`text-standard blackText printHide tableCell justify-content-center`}
          footerClassName="tableFooter"
        />
        <Column
          field="SL"
          header="SL"
          colSpan={2}
          body={(sc) => {
            return <div className="scroll-text">{sc.SL}</div>;
          }}
          headerClassName={`tableHeader font-normal justify-content-center`}
          style={{ minWidth: '200px', maxWidth: '200px' }}
          className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="VendorName"
          header="Vendor Name"
          body={(sc) => {
            return <div className="scroll-text">{sc.VendorName}</div>;
          }}
          headerClassName={`tableHeader font-normal justify-content-center text-center`}
          style={{ minWidth: '300px', maxWidth: '300px' }}
          className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="OrigCost"
          header="Original Contract"
          body={(sc) => formatCurrency(sc.OrigCost)}
          headerClassName={`tableHeader font-normal justify-content-center text-center`}
          style={{ minWidth: '140px' }}
          className={`text-standard blackText tableCell justify-content-end`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="ChangeOrder"
          header="Change Orders"
          body={(sc) => formatCurrency(sc.ChangeOrder)}
          headerClassName={`tableHeader font-normal justify-content-center text-center`}
          style={{ minWidth: '140px' }}
          className={`justify-content-end text-standard blackText tableCell`}
          footerClassName="tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
          footer="Total"
          sortable
        />
        <Column
          field="CurCost"
          header="Current Contract"
          body={(sc) => formatCurrency(sc.CurCost)}
          headerClassName={`tableHeader font-normal justify-content-center text-center`}
          style={{ minWidth: '140px' }}
          className={`justify-content-end text-standard blackText tableCell`}
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(curr)}
          sortable
        />
        <Column
          field="InvCost"
          header="Amount Invoiced"
          body={(sc) => formatCurrency(sc.InvCost)}
          headerClassName={`tableHeader font-normal justify-content-center text-center`}
          style={{ minWidth: '140px' }}
          className={`justify-content-end text-standard blackText tableCell`}
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(inv)}
          sortable
        />
        <Column
          field="PayablePaid"
          header="Amount Paid"
          body={(sc) => formatCurrency(sc.PayablePaid)}
          headerClassName={`tableHeader font-normal justify-content-center text-center`}
          style={{ minWidth: '140px' }}
          className={`justify-content-end text-standard blackText tableCell`}
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(paid)}
          sortable
        />
        <Column
          field="PayableUnpaid"
          header="Payable (Unpaid)"
          body={(sc) => (
            <div className="text-right w-full">
              <SCPayableAttachments
                label={formatCurrency(sc.PayableUnpaid) ?? ''}
                sl={sc.SL}
              />
            </div>
          )}
          headerClassName={`tableHeader font-normal justify-content-center text-center`}
          style={{ minWidth: '140px' }}
          className={`justify-content-end text-standard blackText tableCell`}
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(unpaid)}
          sortable
        />
        <Column
          field="RetainageUnpaid"
          header="Retainage (unpaid)"
          body={(sc) => formatCurrency(sc.RetainageUnpaid)}
          headerClassName={`tableHeader font-normal justify-content-center text-center`}
          style={{ minWidth: '140px' }}
          className={`justify-content-end text-standard blackText tableCell`}
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(retainage)}
          sortable
        />
        <Column
          field="CommittedBalance"
          header="Committed Balance"
          body={(sc) => formatCurrency(sc.CommittedBalance)}
          headerClassName={`tableHeader font-normal justify-content-center text-center`}
          style={{ minWidth: '140px' }}
          className={`justify-content-end text-standard blackText tableCell`}
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(balance)}
          sortable
        />
        <Column
          style={{ minWidth: '30px', maxWidth: '30px' }}
          body={(sc) =>
            sc.UniqueAttchID ? (
              <Attachment
                uniqueAttchID={sc.UniqueAttchID}
                zipFilename={sc.SL}
              />
            ) : (
              <div className="text-red-600 font-bold">
                <TooltipContent
                  label="!"
                  content={`No subcontract file was found!`}
                  id={sc.SL.replace(/-/g, '')
                    .replace(/&/g, '')
                    .replace(/ /g, '')}
                />
              </div>
            )
          }
          headerClassName="tableHeader font-normal"
          className="justify-content-center text-standard blackText tableCell printHide"
          footerClassName="tableFooter"
        />
        <Column
          style={{ minWidth: '2rem', maxWidth: '2rem' }}
          headerClassName={`tableHeader`}
          className="tableCell p-0 printHide"
          footerClassName="tableFooter"
        />
      </Table>
    );
  }
);

const transactionsAreEqual = (
  prevTransactions: Readonly<SCStatusTableProps>,
  nextTransactions: Readonly<SCStatusTableProps>
) => {
  return prevTransactions.subcontracts === nextTransactions.subcontracts;
};

export default React.memo(SCStatusTable, transactionsAreEqual);
