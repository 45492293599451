import { BASE_PDF_STYLES } from '../../../../utils/reportUtils';
import React from 'react';
import ReportButton from '../../../components/report/ReportButton';
import { excelCleanTable } from '../../../../utils/excelUtils';
import * as XLSX from 'xlsx';
import { capitalizeString } from '../../../../utils/formatUtils';
import { useCompanyContext } from '../../../context/CompanyContext';

const pdfStyles = `
${BASE_PDF_STYLES}

body.pdfBody .drilldownHeader {
    margin-left: auto!important;
  }

`;

type CBDrilldownReportProps = {
  elementRef: React.MutableRefObject<HTMLElement | null>;
  state?: string;
  period: string;
};

const CBDrilldownReport = ({
  elementRef,
  state,
  period,
}: CBDrilldownReportProps) => {
  const { selectedCompany } = useCompanyContext();

  const excelElementPreProcess = (element: HTMLElement) => {
    excelCleanTable(element);

    return element;
  };

  const excelBeforeDownload = (workBook: XLSX.WorkBook) => {
    const sheet = workBook.Sheets['Sheet1'];
    const periodLabel = state ? `${capitalizeString(state)} ` : '';

    sheet['C2'] = { t: 's', v: selectedCompany!.name };
    sheet['C3'] = {
      t: 's',
      v: `${periodLabel}Contracts`,
    };
    sheet['C4'] = { t: 's', v: `Billing Cycle: ${period}` };
  };

  return (
    <ReportButton
      elementToPrintRef={elementRef}
      fileName="CB_Contracts_Report"
      pdfStyles={pdfStyles}
      noButton={true}
      isPdfPortrait={true}
      widthElementID="cb-drilldown"
      tableOriginCell="A6"
      excelPreprocess={excelElementPreProcess}
      excelBeforeDownload={excelBeforeDownload}
      minWidth={1000}
    />
  );
};

export default CBDrilldownReport;
