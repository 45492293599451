import { ARCustomer } from '../../../Interfaces/Accounting/AccountsReceivables.interfaces';
import { PERIOD_OPTIONS, RECEIVABLES_OPTIONS } from '../ARFilter/ARFilters';

export const getReceivableTypeName = (id: string) => {
  const payable = RECEIVABLES_OPTIONS.find((payable) => payable.value === id);

  return payable?.label;
};

export const getPeriodLabel = (selectedPeriod: string) => {
  const period = PERIOD_OPTIONS.find(
    (period) => period.value === selectedPeriod
  );

  return period?.label;
};

export const getCustomerNames = (
  customersIDS: number[],
  customersList: ARCustomer[]
) => {
  const filteredCustomers = customersList?.filter((customer: ARCustomer) =>
    customersIDS.includes(customer.accountNumber)
  );

  const customerNames: string[] = filteredCustomers?.map(
    (customer: ARCustomer) => customer.name
  );

  return customerNames?.toString();
};
