import { useQuery } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import DeleteUsersButton from '../../../features/user/DeleteUsersButton';
import UserParams from '../../../features/user/UserParams';
import UsersFilter, {
  UsersFiltersArgs,
} from '../../../features/user/UsersFilter';
import UsersTable from '../../../features/user/UsersTable';
import { getUsers } from '../../../services/UsersService';

const Users = () => {
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [backupData, setBackupData] = useState({ users: [], totalUsers: 0 });
  const [backupPage, setBackupPage] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsAmount, setRowsAmount] = useState(15);
  const methods = useForm<UsersFiltersArgs>();
  const { watch } = methods;
  const { userType, isAdmin, roles, info } = watch();

  const { data, isFetching, isError, refetch } = useQuery({
    queryKey: ['getUsers', page, rowsAmount, userType, isAdmin, roles, info],
    queryFn: ({ signal }) => {
      return getUsers(
        { page, amountPerPage: rowsAmount, userType, isAdmin, roles, info },
        signal
      );
    },
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setBackupData(data);
      setBackupPage(page);
    },
    onError: (error) => {
      showToast(
        'error',
        toast,
        'Search User Error',
        'An error ocurred! please try again later',
        3000
      );
    },
  });

  const removeUser = (user: string) => {
    const newList = selectedUsers.filter((id) => id !== user);
    setSelectedUsers(newList);
  };

  const addUser = (user: string) => {
    setSelectedUsers((list) => [...list, user]);
  };

  useEffect(() => {
    setPage(0);
  }, [rowsAmount, userType, isAdmin, roles, info]);

  return (
    <div className="pt-5 mx-3">
      <FormProvider {...methods}>
        <ErrorToast toastRef={toast} />
        <div className="mb-8 flex justify-content-between">
          <div className="font-bold text-35px">Users</div>
          <Button
            onClick={() => {
              navigate('register');
            }}
            className="ml-5 h-fit buttonBackground text-white border-0 text-xl w-fit py-3 px-3 radius-10px"
          >
            New User
          </Button>
        </div>
        <div className="flex flex-wrap gap-3 mb-3">
          <UsersFilter />
          <UserParams />
          <div className="ml-auto flex align-items-center">
            <DeleteUsersButton
              selectedUsers={selectedUsers}
              afterDeletion={refetch}
            />
          </div>
        </div>
        <UsersTable
          isFetching={isFetching}
          isError={isError}
          setPage={setPage}
          setRowsAmmount={setRowsAmount}
          usersData={data}
          rowsAmount={rowsAmount}
          page={page}
          backupData={backupData}
          backupPage={backupPage}
          removeUser={removeUser}
          addUser={addUser}
          selectedUsers={selectedUsers}
        />
      </FormProvider>
    </div>
  );
};

export default Users;
