import React, { useRef } from 'react';
import APopenPerJobTableContainer from '../../accountsPayables/APOpenPerJob/APopenPerJobTableContainer';
import JCDrilldownReport from '../JCReport/JCDrilldownReport';

type JCPayablePerJobDrilldownProps = {
  contract: string;
  contractName: string;
  showRetainage?: boolean;
  showOpenAmount?: boolean;
};

const JCPayablePerJobDrilldown = ({
  contract,
  contractName,
  showRetainage,
  showOpenAmount,
}: JCPayablePerJobDrilldownProps) => {
  const drilldownRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      id="jc-payables-drilldown"
      ref={drilldownRef}
      className="blackText text-standard printBackground w-full max-w-1150px"
    >
      <div className="mt-3 px-4 flex justify-content-center sm:justify-content-between gap-3 flex-wrap capitalize">
        <div className="text-28px font-bold flex-grow-1 text-center sm:text-left">
          <div className="mx-auto w-fit sm:ml-0 drilldownHeader">
            JC Open Payables per Job Drilldown
          </div>
          <div className="text-23px mt-3 mr-auto w-fit">
            Contract {contract} {contractName}
          </div>
        </div>
        <div className="text-23px mx-auto md:ml-4 md:mr-2 mt-2 sm:mt-0 cursor-pointer printHide flex align-items-center">
          <JCDrilldownReport
            title="Contract Open Payables"
            elementRef={drilldownRef}
            fileName="JC_Payables_Drilldown_Report"
            elementID="jc-payables-drilldown"
            costDetail={{ contract, contractName }}
          />
        </div>
      </div>
      <div className="my-4" />
      <APopenPerJobTableContainer
        isDrilldown={true}
        showPerJobRetainage={showRetainage}
        showPerJobAmount={showOpenAmount}
      />
    </div>
  );
};

export default JCPayablePerJobDrilldown;
