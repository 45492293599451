import FormControlledInput from '../../../components/inputs/FormInputs/FormControlledInput';
import FormControlledNumberInput from '../../../components/inputs/FormInputs/FormControlledNumberInput';

type PhaseEstimateRowPops = {
  phaseIndex: number;
  fieldIndex: number;
};

const PhaseEstimateRow = ({ phaseIndex, fieldIndex }: PhaseEstimateRowPops) => {
  return (
    <div className={`flex-wrap grid col-12`}>
      <div className="col-12 sm:col-4">
        <label
          htmlFor={`phases.${phaseIndex}.details.${fieldIndex}.ctLabel`}
          className="text-standard block mb-2"
        >
          Cost Type
        </label>
        <FormControlledInput
          defaultValue=""
          formID={`phases.${phaseIndex}.details.${fieldIndex}.ctLabel`}
          disabled={true}
          autocomplete="off"
          isFieldArray={true}
        />
      </div>
      <div className="col-12 sm:col-4">
        <label
          htmlFor={`phases.${phaseIndex}.details.${fieldIndex}.umLabel`}
          className="text-standard block mb-2"
        >
          U/M
        </label>
        <FormControlledInput
          defaultValue=""
          formID={`phases.${phaseIndex}.details.${fieldIndex}.umLabel`}
          disabled={true}
          autocomplete="off"
          isFieldArray={true}
        />
      </div>
      <div className="col-12 sm:col-4">
        <label
          htmlFor={`phases.${phaseIndex}.details.${fieldIndex}.cost`}
          className="text-standard block mb-2"
        >
          Estimate Cost
        </label>
        <FormControlledNumberInput
          formID={`phases.${phaseIndex}.details.${fieldIndex}.cost`}
          min={0}
          mode="currency"
          currency="USD"
          locale="en-US"
          isFieldArray={true}
        />
      </div>
    </div>
  );
};

export default PhaseEstimateRow;
