import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { useEffect, useRef } from 'react';
import ErrorToast, { showToast } from '../messages/ErrorAlert';
import useFetchAttachments from '../../hooks/useFetchAttachments';
import WrapperButton from '../buttons/WrapperButton';

type AttachmentProps = {
  uniqueAttchID: string;
  zipFilename: string;
};

const Attachment = ({ uniqueAttchID, zipFilename }: AttachmentProps) => {
  const toastBackError = useRef<Toast>(null);
  const { fetchAttachments, loading, error } = useFetchAttachments({
    uniqueAttchID,
    zipFilename,
  });

  const enableQuery = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    fetchAttachments();
  };

  useEffect(() => {
    if (error) {
      showToast('error', toastBackError, 'Download Error', error, 3000);
    }
  }, [error]);

  return (
    <div>
      <ErrorToast toastRef={toastBackError} />
      {loading && <ProgressSpinner className="w-1rem h-auto" />}
      {!loading && (
        <WrapperButton onClick={(e) => enableQuery(e)}>
          <i className="pi pi-paperclip totalColor" />
        </WrapperButton>
      )}
    </div>
  );
};

export default Attachment;
