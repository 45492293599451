import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Column,
  ColumnEditorOptions,
  ColumnEventParams,
} from 'primereact/column';
import Table from '../../../components/Table/Table';
import { formatCurrency } from '../../../../utils/formatUtils';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import {
  JCProjectedCost,
  CostDetailArgs,
  DraftCosts,
  JCProjectedCostDraft,
} from '../../../Interfaces/Accounting/JobsContracts.interface';
import { InputText } from 'primereact/inputtext';
import JCCostDrilldownContainer from '../JCCostDrilldown/JCCostDrilldownContainer';
import JCCommittedCostContainer from '../JCCommittedCostDrilldown/JCCommittedCostContainer';
import JCProjectedCostProcess from './JCProjectedCostProcess'; 
import WrapperButton from '../../../components/buttons/WrapperButton';
import TooltipContent from '../../../components/messages/TooltipContent';
import JCNoteButton from './JCNoteButton';
import { Access } from '../../../Interfaces/Role.interfaces';

type JCProjectedCostTableProps = {
  costs: JCProjectedCost[];
  draftDefault?: JCProjectedCostDraft;
  approval?: boolean;
  access: Access;
  months?: string[];
  hidePhaseDetails?: string[];
};

const JCProjectedCostTable = React.forwardRef<
  HTMLDivElement,
  JCProjectedCostTableProps
>(
  (
    { costs, draftDefault, approval, access, months, hidePhaseDetails },
    ref
  ) => {
    const canEdit = !!access.editable || (access.shouldApprove && approval);
    const tableClass = 'mx-3 dashboardOptionShadow hoverEffect';
    const columnHeaderClass = 'tableHeader';
    const [isEditing, setIsEditing] = useState(false);
    const [selectedCost, setSelectedCost] = useState<CostDetailArgs>();
    const [showDrilldown, setShowDrilldown] = useState(false);
    const [drilldownType, setDrilldownType] = useState('');
    const [costsDraft, setCostsDraft] = useState<DraftCosts>(
      draftDefault ? { ...draftDefault.costs } : {}
    );

    const calcHeight = (rows: number) => {
      const headerHeight = 32;
      const footerHeight = 54;
      const rowHeight = 31;
      return headerHeight + footerHeight + rows * rowHeight + 3;
    };

    const onRowHover = (e: DataTableRowMouseEventParams) => {
      const cell = e.originalEvent.target as HTMLElement;
      const row = cell.closest('tr') as HTMLElement;
      animateCellText(row);
    };

    const extraColumns = useMemo(() => {
      return months?.map((month) => {
        return (
          <Column
            key={month}
            field={`${month}.amount`}
            header={month}
            body={(jc) =>
              jc[month]?.amount ? (
                jc[month]?.note ? (
                  <TooltipContent
                    id={`${month.replace(/\//g, '_')}-${jc.Phase.replace(
                      / /g,
                      '_'
                    )}`}
                    label={formatCurrency(jc[month].amount) || ''}
                    content={jc[month].note}
                    bold={jc[month].note}
                  />
                ) : (
                  formatCurrency(jc[month]?.amount)
                )
              ) : (
                ''
              )
            }
            headerClassName={`font-normal ${columnHeaderClass} justify-content-center bg-teal-300`}
            style={{ minWidth: '130px' }}
            className={`text-standard blackText tableCell justify-content-end`}
            footer={(e) => {
              const records = e.props.value;
              const total = useMemo(() => {
                let totalValue = 0;
                records.forEach(
                  (
                    record: Record<string, { amount: number; note?: string }>
                  ) => {
                    totalValue += record[month]?.amount || 0;
                  }
                );
                return formatCurrency(totalValue);
              }, [records, month]);

              return total;
            }}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
        );
      });
    }, [months]);

    const textEditor = (options: ColumnEditorOptions) => {
      const editorCallback = options.editorCallback;

      return (
        <InputText
          type="text"
          keyfilter="num"
          className="w-full"
          value={options.value || ''}
          onChange={(e) => {
            try {
              if (editorCallback) {
                editorCallback(e.target.value);
              }
            } catch (e) {
              console.log(e);
            }
          }}
          onFocus={() => setIsEditing(true)}
        />
      );
    };

    const onCellEditComplete = (e: ColumnEventParams) => {
      const { rowData, newValue, field } = e;
      const number = parseFloat(newValue || '');

      rowData[field] = isNaN(number) ? null : number;
      rowData.OverUnder = !isNaN(number) ? rowData.ProjCost - number : null;

      setCostsDraft((values) => {
        const draft = values[rowData.Phase] ?? {};

        if (!isNaN(number)) {
          values[rowData.Phase] = { ...draft, amount: number };
          return { ...values };
        } else if (draft.amount) {
          values[rowData.Phase].amount = undefined;
          if (!draft.note) {
            delete values[rowData.Phase];
          }
          return { ...values };
        }

        return values;
      });

      setIsEditing(false);
    };

    const onNoteEditComplete = (phase: string, newValue?: string) => {
      setCostsDraft((values) => {
        const draft = values[phase] ?? {};

        if (newValue) {
          values[phase] = { ...draft, note: newValue };
          return { ...values };
        } else if (draft.note) {
          values[phase].note = undefined;
          if (!draft.amount) {
            delete values[phase];
          }
          return { ...values };
        }

        return values;
      });
    };

    const onCellClick = (cost: JCProjectedCost, drilldownType: string) => {
      if (hidePhaseDetails?.includes(cost.Phase)) {
        return;
      }

      setSelectedCost({
        job: cost.Job,
        jobName: cost.JobName,
        phase: cost.Phase,
        phaseDescription: cost.Description,
      });
      setShowDrilldown(true);
      setDrilldownType(drilldownType);
    };

    const hideDrilldown = () => {
      setSelectedCost(undefined);
      setShowDrilldown(false);
    };

    let totOrig = 0;
    let totEst = 0;
    let totActual = 0;
    let totCommitt = 0;
    let totRemCommitt = 0;
    let totProjCost = 0;
    let overUnder = 0;

    costs.forEach((cost) => {
      totOrig += cost.OriginalEst;
      totEst += cost.CurrentEst;
      totActual += cost.ActualCost;
      totCommitt += cost.CommitCost;
      totRemCommitt += cost.RemCommit;
      totProjCost += cost.ProjCost;
      overUnder += cost.OverUnder || 0;
    });

    return (
      <div className="pdfDivFitContent">
        {selectedCost && drilldownType === 'actual' && (
          <JCCostDrilldownContainer
            visible={showDrilldown}
            hideDrilldown={hideDrilldown}
            costDetail={{ ...selectedCost }}
          />
        )}
        {selectedCost && drilldownType === 'committed' && (
          <JCCommittedCostContainer
            visible={showDrilldown}
            hideDrilldown={hideDrilldown}
            costDetail={{ ...selectedCost }}
          />
        )}
        <Table
          id="jc-projected-cost"
          data={costs}
          className={tableClass}
          calcHeight={useCallback(calcHeight, [])}
          onRowMouseEnter={useCallback(onRowHover, [])}
        >
          <Column
            field="Phase"
            header="Phase"
            style={{ minWidth: '120px', maxWidth: '120px' }}
            headerClassName="tableHeader font-normal text-center"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="Description"
            header="Description"
            body={(jc) => {
              return <div className="scroll-text">{jc.Description}</div>;
            }}
            style={{ minWidth: '250px', maxWidth: '250px' }}
            headerClassName="tableHeader font-normal justify-content-center"
            className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
            footerClassName="overflow-x-visible tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
            footer="Total"
            sortable
          />
          <Column
            field="OriginalEst"
            header="Original Estimate"
            body={(jc) => formatCurrency(jc.OriginalEst)}
            headerClassName={`font-normal ${columnHeaderClass} justify-content-center`}
            style={{ minWidth: '160px' }}
            className={`text-standard blackText tableCell justify-content-end`}
            footer={formatCurrency(totOrig)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            field="CurrentEst"
            header="Current Estimate"
            body={(jc) => formatCurrency(jc.CurrentEst)}
            headerClassName={`font-normal ${columnHeaderClass} justify-content-center`}
            style={{ minWidth: '160px' }}
            className={`text-standard blackText tableCell justify-content-end`}
            footer={formatCurrency(totEst)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            field="ActualCost"
            header="Actual Cost"
            body={(jc) => (
              <WrapperButton
                disabled={hidePhaseDetails?.includes(jc.Phase)}
                className={`w-full text-right printColor ${
                  !hidePhaseDetails?.includes(jc.Phase)
                    ? 'totalColor'
                    : 'blackText'
                }`}
                onClick={() => onCellClick(jc, 'actual')}
              >
                {formatCurrency(jc.ActualCost)}
              </WrapperButton>
            )}
            headerClassName={`font-normal ${columnHeaderClass} justify-content-center`}
            style={{ minWidth: '150px' }}
            className={`text-standard tableCell justify-content-end`}
            footer={formatCurrency(totActual)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            field="CommitCost"
            header="Committed Costs"
            body={(jc) => (
              <WrapperButton
                disabled={hidePhaseDetails?.includes(jc.Phase)}
                className={`w-full text-right printColor ${
                  !hidePhaseDetails?.includes(jc.Phase)
                    ? 'totalColor'
                    : 'blackText'
                }`}
                onClick={() => onCellClick(jc, 'committed')}
              >
                {formatCurrency(jc.CommitCost)}
              </WrapperButton>
            )}
            headerClassName={`font-normal ${columnHeaderClass} justify-content-center`}
            style={{ minWidth: '160px' }}
            className={`text-standard tableCell justify-content-end`}
            footer={formatCurrency(totCommitt)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            field="RemCommit"
            header="Remaining to Commit"
            body={(jc) => formatCurrency(jc.RemCommit)}
            headerClassName={`font-normal ${columnHeaderClass} justify-content-center`}
            style={{ minWidth: '190px' }}
            className={`text-standard blackText tableCell justify-content-end`}
            footer={formatCurrency(totRemCommitt)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          {extraColumns}
          <Column
            field="ProjCost"
            header={
              (extraColumns?.length || 0) > 0
                ? 'Total Projected Costs'
                : 'Projected Costs'
            }
            body={(jc) => (
              <span
                className={`${
                  (jc.ActualCost > jc.ProjCost ||
                    jc.CommitCost > jc.ProjCost) &&
                  'errorText'
                } `}
              >
                <TooltipContent
                  id={`PC${jc.Phase.replace(/ /g, '_')}`}
                  label={formatCurrency(jc.ProjCost) || ''}
                  content={jc.Note}
                  bold={!!jc.Note}
                />
              </span>
            )}
            headerClassName={`font-normal ${columnHeaderClass} justify-content-center ${
              (extraColumns?.length || 0) > 0 && 'bg-teal-300'
            }`}
            style={{
              minWidth: (extraColumns?.length || 0) > 0 ? '190px' : '150px',
            }}
            className={`text-standard blackText tableCell justify-content-end`}
            footer={formatCurrency(totProjCost)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            field="NewProjCost"
            header="New Project Costs"
            body={(jc) =>
              jc.NewProjCost !== null && jc.NewProjCost !== undefined ? (
                <div
                  className={`w-full text-right ${
                    jc.ActualCost > jc.NewProjCost ||
                    jc.CommitCost > jc.NewProjCost
                      ? 'errorText'
                      : canEdit && 'totalColor'
                  } printColor`}
                >
                  {formatCurrency(jc.NewProjCost)}
                </div>
              ) : (
                <React.Fragment>
                  {canEdit && (
                    <i className={`pi pi-pencil totalColor printHide`} />
                  )}
                </React.Fragment>
              )
            }
            style={{ minWidth: '160px' }}
            headerClassName="tableHeader font-normal text-center"
            className={`justify-content-center text-standard blackText tableCell ${
              canEdit && 'pointer'
            }`}
            editor={
              canEdit
                ? (options) => {
                    return textEditor(options);
                  }
                : null
            }
            onCellEditComplete={onCellEditComplete}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={(e) => {
              const records = e.props.value;
              let total = 0;
              records.forEach((record: JCProjectedCost) => {
                total += record.NewProjCost ?? 0;
              });
              return total ? formatCurrency(total) : '';
            }}
          />
          <Column
            field="OverUnder"
            header="Over/Under"
            body={(jc) => formatCurrency(jc.OverUnder)}
            style={{ minWidth: '160px' }}
            headerClassName="tableHeader font-normal text-center"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={(e) => {
              const records = e.props.value;
              let total = 0;
              records.forEach((record: JCProjectedCost) => {
                total += record.OverUnder ?? 0;
              });
              return total ? formatCurrency(total) : '';
            }}
          />
          <Column
            field="NewNote"
            header="Note"
            body={(jc) => {
              return (
                <JCNoteButton
                  cost={jc}
                  onBlur={onNoteEditComplete}
                  defaultValue={costsDraft[jc.Phase]?.note}
                />
              );
            }}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-center text-standard blackText tableCell min-w-50px max-w-50px pmin-w-250px pmax-w-250px"
            footerClassName="tableFooter"
          />
        </Table>
        <div className="ml-auto w-full px-3 sm:px-0 sm:w-24rem mr-3 my-4 text-standard flex justify-content-between border-bottom-2">
          <span className="font-bold">New Projected Cost</span>
          <span id="newProjectedCost">
            {formatCurrency(totProjCost - (overUnder || 0))}
          </span>
        </div>
        <div className="mt-3 flex gap-4 w-fit ml-auto mr-3 printHide">
          <JCProjectedCostProcess
            draft={{ ...draftDefault, costs: costsDraft }}
            approvalMode={approval}
            access={access}
            blockAll={isEditing}
          />
        </div>
      </div>
    );
  }
);

const transactionsAreEqual = (
  prevTransactions: Readonly<JCProjectedCostTableProps>,
  nextTransactions: Readonly<JCProjectedCostTableProps>
) => {
  return prevTransactions.costs === nextTransactions.costs;
};

export default React.memo(JCProjectedCostTable, transactionsAreEqual);
