import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { cleanJsonEmptyValues } from '../../utils/formatUtils';
import { jsonsComparison } from '../../utils/utils';

type useFiltersCacheArgs = {
  tab: string;
  filters: Record<string, unknown>;
  cookieName?: string;
  path?: string;
};

const useFiltersCache = ({
  tab,
  filters,
  cookieName,
  path,
}: useFiltersCacheArgs) => {
  const cName = cookieName ?? 'filters';
  const cPath = path ?? '/accounting-dashboard';
  const [cookies, setCookie, removeCookie] = useCookies([cName]);

  useEffect(() => {
    const expirationTime = new Date(Date.now() + 60 * 60 * 1000);
    const cleanedFilters = cleanJsonEmptyValues(filters);
    const keys = Object.keys(cleanedFilters);
    const cookieFilters = cookies[cName]?.filters;
    const cookieKeys = Object.keys(cookieFilters || {});

    if (!keys.length && cookieKeys.length) {
      removeCookie(cName, { path: cPath });
    } else if (keys.length) {
      
      if (!jsonsComparison(cleanedFilters, cookieFilters || {})) {
        setCookie(
          cName,
          { tab, filters: cleanedFilters },
          { expires: expirationTime, path: cPath }
        );
      }
    }
  }, [tab, filters, cookies, cName, cPath, setCookie, removeCookie]);
};

export default useFiltersCache;
