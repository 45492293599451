import { useMutation } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import WrapperButton from '../../components/buttons/WrapperButton';
import ErrorToast, { showToast } from '../../components/messages/ErrorAlert';
import { useCompanyContext } from '../../context/CompanyContext';
import { fetchARInvoiceType } from '../../services/AccountsReceivablesService';
import ARAttachment from './ARAIAForm/ARAttachment';
import ARInvoice from './ARInvoice/ARInvoice';

type ARAttachmentSelectorProps = {
  invoice: string;
  contract: string;
};

const ARAttachmentSelector = ({
  invoice,
  contract,
}: ARAttachmentSelectorProps) => {
  const { selectedCompany } = useCompanyContext();
  const toastBackError = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const [invoiceType, setInvoiceType] = useState('');

  const { mutate } = useMutation({
    mutationFn: (contract: string) => {
      return fetchARInvoiceType(selectedCompany!, contract);
    },
    onSuccess: (data) => {
      setInvoiceType(data);
    },
    onError: () => {
      setLoading(false);
      showToast(
        'error',
        toastBackError,
        'AR Attachment Error',
        'An error ocurred, please try again later',
        3000
      );
    },
  });

  const enableQuery = () => {
    setLoading(true);
    mutate(contract);
  };

  useEffect(() => {
    if (!loading) {
      setTimeout(() => setInvoiceType(''), 3000);
    }
  }, [loading]);

  return (
    <div>
      <ErrorToast toastRef={toastBackError} />
      {loading && <ProgressSpinner className="w-1rem h-auto" />}
      {!loading && (
        <WrapperButton onClick={enableQuery}>
          <i className="pi pi-paperclip totalColor" />
        </WrapperButton>
      )}
      {invoiceType === 'AIA' && (
        <ARAttachment
          contract={contract}
          invoice={invoice}
          setLoading={setLoading}
        />
      )}
      {invoiceType === 'INV' && (
        <ARInvoice
          contract={contract}
          invoice={invoice}
          setLoading={setLoading}
        />
      )}
    </div>
  );
};

export default ARAttachmentSelector;
