import React, { useCallback } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { formatUTCDate, formatCurrency } from '../../../../utils/formatUtils';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import { SCItemPayment } from '../../../Interfaces/Accounting/Subcontracts.interfaces';

type SCItemPaymentsTableProps = {
  payments: SCItemPayment[];
};

const SCItemPaymentsTable = React.forwardRef<
  HTMLDivElement,
  SCItemPaymentsTableProps
>(({ payments }, ref) => {
  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  const rowClassName = () => {
    return 'nestedExpandedTableCell';
  };

  return (
    <Table
      ref={ref}
      data={payments}
      className="nestedExpandedTable tableFirstLineStyled noFooter w-full"
      onRowMouseEnter={useCallback(onRowHover, [])}
      rowClassName={rowClassName}
    >
      <Column
        style={{ minWidth: '50px', maxWidth: '50px' }}
        headerClassName={`expandedTableHeader `}
        className={`text-standard blackText printHide tableCell justify-content-center `}
        footerClassName="tableFooter"
      />
      <Column
        headerClassName={`font-normal expandedTableHeader `}
        style={{ minWidth: '200px', maxWidth: '200px' }}
        className={`text-standard blackText tableCell justify-content-center doubleColumn`}
        footerClassName="tableFooter"
      />
      <Column
        field="CheckDate"
        header="Chk Date"
        body={(sc) => formatUTCDate(sc.CheckDate)}
        headerClassName={`font-normal expandedTableHeader `}
        style={{ minWidth: '300px', maxWidth: '300px' }}
        className={`text-standard blackText tableCell  text-center justify-content-center`}
        footerClassName="tableFooter"
      />
      <Column
        field="CMRef"
        header="CM Ref#"
        headerClassName={`font-normal expandedTableHeader `}
        style={{ minWidth: '140px' }}
        className={`justify-content-center text-standard blackText tableCell `}
        footerClassName="tableFooter"
      />
      <Column
        field="InvDate"
        header="Inv. Date"
        body={(sc) => formatUTCDate(sc.InvDate)}
        headerClassName={`font-normal expandedTableHeader  text-center justify-content-center`}
        style={{ minWidth: '140px' }}
        className={`justify-content-center text-standard blackText tableCell `}
      />
      <Column
        field="APRef"
        header="Inv. Number"
        headerClassName={`font-normal expandedTableHeader  text-center justify-content-center`}
        style={{ minWidth: '140px' }}
        className={`justify-content-center text-standard blackText tableCell `}
      />
      <Column
        field="AmountInv"
        body={(payment) => formatCurrency(payment.AmountInv)}
        headerClassName={`font-normal expandedTableHeader  text-center justify-content-center`}
        style={{ minWidth: '140px' }}
        className={`justify-content-end text-standard blackText tableCell `}
      />
      <Column
        field="PayablePaid"
        body={(payment) => formatCurrency(payment.PayablePaid)}
        headerClassName={`font-normal expandedTableHeader  text-center justify-content-center`}
        style={{ minWidth: '140px' }}
        className={`justify-content-end text-standard blackText tableCell `}
      />
      <Column
        field="PayableUnpaid"
        body={(payment) => formatCurrency(payment.PayableUnpaid)}
        headerClassName={`font-normal expandedTableHeader  text-center justify-content-center`}
        style={{ minWidth: '140px' }}
        className={`justify-content-end text-standard blackText tableCell `}
      />
      <Column
        field="RetainageUnpaid"
        body={(payment) => formatCurrency(payment.RetainageUnpaid)}
        headerClassName={`font-normal expandedTableHeader  text-center justify-content-center`}
        style={{ minWidth: '140px' }}
        className={`justify-content-end text-standard blackText tableCell `}
      />
      <Column
        headerClassName={`font-normal expandedTableHeader  text-center justify-content-center`}
        style={{ minWidth: '140px' }}
        className={`justify-content-end text-standard blackText tableCell `}
      />
      <Column
        style={{ minWidth: '30px', maxWidth: '30px' }}
        headerClassName={`expandedTableHeader`}
        className="tableCell p-0 printHide"
      />
    </Table>
  );
});

const transactionsAreEqual = (
  prevTransactions: Readonly<SCItemPaymentsTableProps>,
  nextTransactions: Readonly<SCItemPaymentsTableProps>
) => {
  return prevTransactions.payments === nextTransactions.payments;
};

export default React.memo(SCItemPaymentsTable, transactionsAreEqual);
