import { Toast } from 'primereact/toast';
import React from 'react';
import { useReactToPrint } from 'react-to-print';
import { showToast } from '../messages/ErrorAlert';

type PrintItemProps = {
  setLoading: (state: boolean) => void;
  fileName: string;
  elementToPrintRef: React.MutableRefObject<HTMLElement | null>;
  toast: React.RefObject<Toast>;
};

const PrintItem = ({
  setLoading,
  fileName,
  elementToPrintRef,
  toast,
}: PrintItemProps) => {
  const handlePrint = useReactToPrint({
    removeAfterPrint: true,
    content: () => {
      if (!elementToPrintRef.current) {
        setLoading(false);
        showToast(
          'error',
          toast,
          'Print Error',
          'An error ocurred while printing! Try again later',
          3000
        );
      }
      return elementToPrintRef.current;
    },
    suppressErrors: false,
    onPrintError: () => {
      setLoading(false);
      showToast(
        'error',
        toast,
        'Print Error',
        'An error ocurred while printing! Try again later',
        3000
      );
    },
    onAfterPrint: () => {
      setLoading(false);
    },
    documentTitle: fileName,
  });

  return {
    label: 'Print',
    icon: 'pi pi-fw pi-print',
    command: () => {
      setLoading(true);
      handlePrint();
    },
  };
};

export default PrintItem;
