import { Link } from 'react-router-dom';

type SucessFormMessageProps = {
  message: React.ReactNode;
  redirectUrl?: string;
  redirectMessage?: string;
};

const SuccessFormMessage = ({
  message,
  redirectUrl,
  redirectMessage,
}: SucessFormMessageProps) => {
  return (
    <div className="w-fit mx-auto flex flex-column">
      <div className="text-center text-40px font-bold text-green-500 mb-3">
        Success!
      </div>
      <i className="pi pi-check-circle mx-auto text-40px text-green-500 mb-3" />
      <div className="text-standard mt-3 mx-auto">{message}</div>
      {redirectUrl && (
        <Link
          to={redirectUrl}
          className="mx-auto cursor-pointer totalColor hover:underline font-bold mt-3"
        >
          {redirectMessage}
        </Link>
      )}
    </div>
  );
};

export default SuccessFormMessage;
