import { useQuery } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import LoadingButton from 'apps/tmr-frontend/src/app/components/inputs/LoadingButton';
import {
  SLForm,
  SLItem,
  SLItems,
} from '../../../Interfaces/Accounting/ACForms.interfaces';
import { useCompanyContext } from '../../../context/CompanyContext';
import { fetchUnitsOfMeasure } from '../../../services/ACFormsService';
import ErrorToast from '../../../components/messages/ErrorAlert';
import { fetchJCJobPhases } from '../../../services/JobsContractsService';
import SLItemRow from './SLItemRow';
import { formatCurrency } from 'apps/tmr-frontend/src/utils/formatUtils';
import ConfirmationDialog from '../../../components/messages/ConfirmationDialog';

type SLItemsProps = {
  returnStep: () => void;
  isLoading: boolean;
  onSubmit: () => void;
};

const SLItemsForm = ({ returnStep, isLoading, onSubmit }: SLItemsProps) => {
  const { selectedCompany } = useCompanyContext();
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const toastError = useRef<Toast>(null);
  const { control, getValues } = useFormContext<SLItems>();
  const job = useWatch<SLForm>({ name: 'job' }) as string;
  const slItems = useWatch({
    name: `slItems`,
  }) as SLItem[];
  const { fields, append, replace } = useFieldArray<SLItems>({
    control,
    name: 'slItems',
  });

  useQuery({
    queryKey: ['getUnitOfMeasure'],
    queryFn: ({ signal }) => {
      return fetchUnitsOfMeasure(signal);
    },
    refetchOnWindowFocus: false,
  });

  useQuery({
    queryKey: ['getJobPhases', selectedCompany, job],
    queryFn: ({ signal }) =>
      fetchJCJobPhases({ companyID: selectedCompany!.id, job }, signal),
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && job),
  });

  const addNewItem = (index: number) => {
    const slItem = getValues(`slItems.${index}`) ?? {};
    const newItem = { ...slItem };
    delete newItem.phase;

    append({
      ...newItem,
      line: slItems.length + 1,
    });
  };

  const removeItem = (index: number) => {
    let slItems = getValues(`slItems`);
    const newList: SLItem[] = [];

    slItems?.forEach((slItem, itemIndex) => {
      if (index !== itemIndex) {
        newList.push({
          ...slItem,
          line: itemIndex < index ? itemIndex + 1 : itemIndex,
        });
      }
    });

    replace(newList);
  };

  useEffect(() => {
    let total = 0;
    slItems.forEach((slItem) => {
      total += slItem.total ?? 0;
    });

    setTotal(total);
  }, [slItems]);

  return (
    <div className="mx-2">
      <ErrorToast toastRef={toastError} />
      <div className="flex">
        <Button
          className="ml-auto text-20px"
          onClick={() => {
            append({
              line: slItems.length + 1,
            });
          }}
          type="button"
        >
          Add new
        </Button>
      </div>
      <div className="hidden lg:flex">
        <div className="col-7 flex p-0">
          <div className="col-1 pb-0">
            <label className="text-standard block">Line</label>
          </div>
          <div className="col-4 xl:col-3 pb-0">
            <label className="text-standard block">Phase Code</label>
          </div>
          <div className="col-4 xl:col-5 pb-0">
            <label className="text-standard block">Description</label>
          </div>
          <div className="col-4 xl:col-3 pb-0">
            <label className="text-standard block">UoM</label>
          </div>
        </div>
        <div className="col-4 flex flex-wrap p-0 flex-grow-1">
          <div className="col-3 pb-0">
            <label className="text-standard block">Units</label>
          </div>
          <div className="col-3 pb-0">
            <label className="text-standard block">Price</label>
          </div>
          <div className="col-4 pb-0">
            <label className="text-standard block">Total</label>
          </div>
          <div className="col-2 pb-0">
            <label className="text-standard block">Retainage</label>
          </div>
        </div>
        <div className="col-1 max-w-5rem" />
      </div>
      {fields?.map((field, index) => {
        return (
          <SLItemRow
            key={field.id}
            index={index}
            addNewItem={addNewItem}
            removeItem={removeItem}
          />
        );
      })}
      {!!total && (
        <div className="flex text-standard">
          <div className="flex col-4 sm:col-offset-6 md:col-offset-7 flex-grow-1">
            <div className="col-3 col-offset-3 text-right font-bold">
              Total:
            </div>
            <div className="col-6 lg:col-4 text-right">
              {formatCurrency(total)}
            </div>
          </div>
          <div className="col-1 max-w-5rem hidden lg:flex" />
        </div>
      )}
      <div className="flex flex-wrap flex-row-reverse gap-2 mb-3 mt-5">
        <ConfirmationDialog
          Button={
            <LoadingButton
              isLoading={isLoading}
              label="Create SL"
              className="w-15rem bluwaiBlue border-0 flex m-auto py-3"
              fontSize="text-22px"
              type="button"
            />
          }
          onConfirm={onSubmit}
          message='Are you sure you want to submit this subcontract?'
        />
        <Button
          onClick={() => {
            returnStep();
          }}
          className="w-15rem buttonSecondary border-0 flex m-auto py-3"
          type="button"
        >
          <span className="mx-auto text-22px text-white">Previous</span>
        </Button>
        <Button
          onClick={() => {
            navigate('/accounting-dashboard/new');
          }}
          className="w-15rem bluwaiRed border-0 flex m-auto py-3"
          type="button"
        >
          <span className="mx-auto text-22px text-white">Cancel</span>
        </Button>
      </div>
    </div>
  );
};

export default SLItemsForm;
