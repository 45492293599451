import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useWatch } from 'react-hook-form';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import CMTransactionsTable from './CMTransactionsTable';
import { formatDate } from '../../../../utils/formatUtils';
import { useCompanyContext } from '../../../context/CompanyContext';
import { fetchCashManagement } from '../../../services/CashManagementService';
import { CashManagementFilterFields } from '../CashManagementFilters';

const CMTransactionsTableContainer = React.forwardRef<HTMLDivElement>(
  (props, ref) => {
    const { selectedCompany } = useCompanyContext();
    const filters = useWatch<CashManagementFilterFields>();

    const { data, isFetching, isError } = useQuery({
      queryKey: [
        'getCashManagementData',
        filters.account,
        filters.dates,
        filters.info,
        selectedCompany,
      ],
      queryFn: ({ signal }) => {
        return fetchCashManagement(
          {
            source: selectedCompany!.source,
            companyID: selectedCompany!.id,
            accountId: filters.account,
            dates: filters.dates,
            info: filters.info || undefined,
          },
          signal
        );
      },
      refetchOnWindowFocus: false,
      enabled: !!(selectedCompany && filters.account),
    });

    const getDescription = (filters: CashManagementFilterFields) => {
      const date = filters.dates?.at(0);
      if (date) {
        return `${formatDate(date)} Start Balance`;
      } else {
        return 'Month Start Balance';
      }
    };

    if (isFetching || !(selectedCompany && filters.account))
      return (
        <div className="text-center mx-auto mt-3">
          <ProgressSpinner />
        </div>
      );

    if (isError)
      return (
        <ErrorMessage
          content={'Failed to obtain data! Please try again later.'}
        />
      );

    return (
      <CMTransactionsTable
        ref={ref}
        cmTransactions={data?.transactions || []}
        balanceStart={data?.balance?.amount || 0}
        startDescription={getDescription(filters)}
      />
    );
  }
);

export default CMTransactionsTableContainer;
