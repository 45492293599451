import { InputText } from 'primereact/inputtext';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { useCompanyContext } from '../../context/CompanyContext';
import {
  fetchAccountsPayablesList,
  fetchAPOpenJobs,
} from '../../services/AccountsPayablesService';
import MultipleSelectStyled from '../../components/inputs/StyledInputs/MultipleSelectStyled';
import CalendarStyled from '../../components/inputs/StyledInputs/CalendarStyled';
import { getVendorFetch } from './apUtil';
import { CompanySource } from '../../Interfaces/User.interfaces';
import DropdownStyled from '../../components/inputs/StyledInputs/DropdownStyled';
import { fetchCashManagementAccounts } from '../../services/CashManagementService';
import { DropdownChangeParams } from 'primereact/dropdown';

export type fetchAccountsPayablesFilters = {
  reportType: string;
  dates?: Date[];
  vendors?: string[];
  info?: string;
  jobs?: string[];
  account?: string;
};

const APFilters = () => {
  const { control, watch } = useFormContext();
  const { selectedCompany } = useCompanyContext();
  const reportType = watch('reportType');

  const { data, isFetching, isError } = useQuery({
    queryKey: ['getAPVendors', selectedCompany!.id, reportType],
    queryFn: ({ signal }) => {
      return getVendorFetch(reportType, selectedCompany!, signal);
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
    staleTime: 1000 * 60 * 10,
  });

  const { data: accounts, isLoading } = useQuery({
    queryKey: ['getAPAccounts', selectedCompany],
    queryFn: ({ signal }) =>
      fetchAccountsPayablesList(selectedCompany!.id, signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  const jobs = useQuery({
    queryKey: ['getAPOpenJobs', selectedCompany],
    queryFn: ({ signal }) => {
      return fetchAPOpenJobs(selectedCompany!, signal);
    },
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && reportType === 'openPayablesPerJob'),
    staleTime: 1000 * 60 * 10,
  });

  return (
    <>
      {selectedCompany!.source == CompanySource.Yardi && (
        <div className="col">
          <Controller
            control={control}
            name="account"
            render={({ field }) => (
              <DropdownStyled
                isDisabled={isError}
                options={accounts}
                labelField="name"
                valueField="id"
                isLoading={isLoading}
                placeholder={
                  isError ? 'Failed to load accounts!' : 'Select account'
                }
                clearable={false}
                {...field}
                {...{
                  onChange: (e: DropdownChangeParams) => {
                    field.onChange(e.target.value);
                  },
                }}
              />
            )}
          />
        </div>
      )}
      {reportType === 'openPayablesPerJob' && (
        <div className="col">
          <Controller
            control={control}
            name="jobs"
            render={({ field: { value, ...otherFields } }) => (
              <MultipleSelectStyled
                isDisabled={jobs.isError}
                resetFilterOnHide={true}
                showSelectAll={false}
                filter={true}
                options={jobs.data}
                labelField="Job"
                valueField="Job"
                isLoading={jobs.isFetching}
                placeholder={
                  jobs.isError ? 'Failed to load jobs!' : 'Select Job'
                }
                value={jobs.isFetching ? null : value}
                {...otherFields}
              />
            )}
          />
        </div>
      )}
      {reportType === 'openPayablesPerJob' && (
        <div className="col">
          <Controller
            control={control}
            name="jobs"
            render={({ field: { value, ...otherFields } }) => (
              <MultipleSelectStyled
                isDisabled={isError}
                resetFilterOnHide={true}
                showSelectAll={false}
                filter={true}
                options={jobs.data}
                labelField="JobName"
                valueField="Job"
                isLoading={jobs.isFetching}
                placeholder={
                  jobs.isError ? 'Failed to load job names!' : 'Select Job Name'
                }
                value={jobs.isFetching ? null : value}
                {...otherFields}
              />
            )}
          />
        </div>
      )}
      {reportType !== 'vendorList' && (
        <div className="col">
          <Controller
            control={control}
            name="vendors"
            render={({ field: { value, ...otherFields } }) => (
              <MultipleSelectStyled
                isDisabled={isError}
                resetFilterOnHide={true}
                showSelectAll={false}
                filter={true}
                options={data}
                labelField="name"
                valueField={
                  selectedCompany?.source == CompanySource.ViewPoint
                    ? 'code'
                    : 'id'
                }
                isLoading={isFetching}
                placeholder={
                  isError ? 'Failed to load vendors!' : 'Select vendor'
                }
                clearable={true}
                value={isFetching ? null : value}
                {...otherFields}
              />
            )}
          />
        </div>
      )}
      {reportType !== 'vendorList' && (
        <div className="col">
          <Controller
            control={control}
            defaultValue=""
            name="dates"
            render={({ field }) => (
              <CalendarStyled
                selectionMode="range"
                placeholder="Select Date Range"
                readOnlyInput
                showButtonBar
                {...field}
              />
            )}
          />
        </div>
      )}

      <div className="col">
        <Controller
          control={control}
          defaultValue=""
          name="info"
          render={({ field }) => (
            <span className="p-input-icon-right w-full">
              <InputText
                type="text"
                placeholder="Search"
                className="fieldBorder w-full text-standard blackText"
                {...field}
              />
              <i className="pi pi-search" />
            </span>
          )}
        />
      </div>
    </>
  );
};

export default APFilters;
