import { BASE_PDF_STYLES } from '../../../../utils/reportUtils';
import React from 'react';
import ReportButton from '../../../components/report/ReportButton';
import { excelCleanTable } from '../../../../utils/excelUtils';
import * as XLSX from 'xlsx';
import { formatUTCDate } from '../../../../utils/formatUtils';
import { useCompanyContext } from '../../../context/CompanyContext';
import { SCPaymentDrilldownDetails } from '../../../Interfaces/Accounting/Subcontracts.interfaces';

const pdfStyles = `
${BASE_PDF_STYLES}

body.pdfBody .drilldownHeader {
    margin-left: auto!important;
  }

`;

type CBDrilldownReportProps = {
  elementRef: React.MutableRefObject<HTMLElement | null>;
  paymentDetails: SCPaymentDrilldownDetails;
};

const SCDrilldownReport = ({
  elementRef,
  paymentDetails,
}: CBDrilldownReportProps) => {
  const { selectedCompany } = useCompanyContext();

  const excelElementPreProcess = (element: HTMLElement) => {
    excelCleanTable(element);

    return element;
  };

  const excelBeforeDownload = (workBook: XLSX.WorkBook) => {
    const sheet = workBook.Sheets['Sheet1'];
    let row = 5;

    sheet['C2'] = { t: 's', v: selectedCompany!.name };
    sheet['C3'] = { t: 's', v: 'SC Payment Details' };

    if (paymentDetails.Job) {
      sheet[`B${row}`] = {
        t: 's',
        v: 'Job Number:',
      };
      sheet[`C${row}`] = {
        t: 's',
        v: `${paymentDetails.Job} ${paymentDetails.JobName}`,
      };

      row += 1;
    }

    if (paymentDetails.VendorName) {
      sheet[`B${row}`] = { t: 's', v: 'Sub. Name:' };
      sheet[`C${row}`] = { t: 's', v: paymentDetails.VendorName };
      row += 1;
    }

    if (paymentDetails.PmName) {
      sheet[`B${row}`] = { t: 's', v: 'Project Manager:' };
      sheet[`C${row}`] = { t: 's', v: paymentDetails.PmName };
      row += 1;
    }

    if (paymentDetails.InvDate) {
      sheet[`B${row}`] = { t: 's', v: 'Inv. Date:' };
      sheet[`C${row}`] = {
        t: 's',
        v: formatUTCDate(paymentDetails.InvDate ?? ''),
      };
      row += 1;
    }

    if (paymentDetails.APRef) {
      sheet[`B${row}`] = { t: 's', v: 'Inv. Number:' };
      sheet[`C${row}`] = { t: 's', v: paymentDetails.APRef };
      row += 1;
    }
  };

  return (
    <ReportButton
      elementToPrintRef={elementRef}
      fileName="SC_Payment_Report"
      pdfStyles={pdfStyles}
      noButton={true}
      isPdfPortrait={true}
      widthElementID="sc-payment-details"
      tableOriginCell="A10"
      excelPreprocess={excelElementPreProcess}
      excelBeforeDownload={excelBeforeDownload}
      minWidth={1000}
    />
  );
};

export default SCDrilldownReport;
