import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  RoleAcessContext,
  RolesAccessContextData,
} from '../Interfaces/Role.interfaces';
import { Access } from '../Interfaces/Role.interfaces';

const RolesAccessContext = React.createContext({} as RolesAccessContextData);

type RolesAccessContextProviderProps = {
  children: React.ReactNode;
};

export const RolesAccessContextProvider = ({
  children,
}: RolesAccessContextProviderProps) => {
  const [access, setAccess] = useState<RoleAcessContext>({});

  const setRolesAccess = useCallback((rolesAcess: Access[]) => {
    setAccess((access) => {
      return { ...access, rolesAcess };
    });
  }, []);

  const setIsAdmin = useCallback((isAdmin: boolean) => {
    setAccess((access) => {
      return { ...access, isAdmin };
    });
  }, []);

  const setUserType = useCallback((userType: string) => {
    setAccess((access) => {
      return { ...access, userType };
    });
  }, []);

  const setRolePhases = useCallback((rolePhases: string[]) => {
    setAccess((access) => {
      return { ...access, rolePhases };
    });
  }, []);

  const contextValue = useMemo(() => {
    return {
      ...access,
      setRolesAccess,
      setIsAdmin,
      setUserType,
      setRolePhases,
    };
  }, [access]);

  return (
    <RolesAccessContext.Provider value={contextValue}>
      {children}
    </RolesAccessContext.Provider>
  );
};

export const useRolesAccessContext = () => useContext(RolesAccessContext);
