import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import ResumeCard from '../../../components/cards/ResumeCard';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import { fetchAccountsReceivablesResume } from '../../../services/AccountsReceivablesService';
import ARResume from './ARResume';

const ARResumeContainer = () => {
  const { selectedCompany } = useCompanyContext();
  const { data, isLoading, isError, isSuccess } = useQuery({
    queryKey: ['getAccountsReceivablesResume', selectedCompany],
    queryFn: ({ signal }) =>
      fetchAccountsReceivablesResume(selectedCompany!, signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  return (
    <div className="w-full max-w-624px ml-auto">
      <ResumeCard>
        <div className="p-3 flex flex-wrap gap-5">
          {isLoading && <ProgressSpinner />}
          {isSuccess && <ARResume resume={data} />}
          {isError && (
            <ErrorMessage
              content={'Failed to obtain data! Please try again later.'}
            />
          )}
        </div>
      </ResumeCard>
    </div>
  );
};

export default ARResumeContainer;
