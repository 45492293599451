import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import { useFormContext } from 'react-hook-form';
import APVendorPaymentsTable from './APVendorPaymentsTable';
import { VendorPayments } from '../../../Interfaces/Accounting/AccountsPayables.interfaces';
import { fetchAccountsPayablesFilters } from '../APFilters';
import { fetchAccountsPayablesVendorPayments } from '../../../services/vendorPaymentService';

const APVendorPaymentsTableContainer = React.forwardRef<HTMLDivElement>(
  (props, ref) => {
    const { selectedCompany } = useCompanyContext();
    const { watch } = useFormContext<fetchAccountsPayablesFilters>();
    const filters = watch();
    const { data, isFetching, isError } = useQuery({
      queryKey: ['getAPVendorHistory', selectedCompany, filters],
      queryFn: ({ signal }) =>
        fetchAccountsPayablesVendorPayments(
          {
            company: selectedCompany!,
            dates: filters.dates,
            info: filters.info,
            vendors: filters.vendors,
          },
          signal
        ),
      refetchOnWindowFocus: false,
      enabled: !!selectedCompany,
    });

    const createIDForPayment = (payments: VendorPayments[]) => {
      payments.forEach((payment: VendorPayments) => {
        payment.uuid = `${payment.vendor.id}-${payment.CMRef}`;
      });

      return payments;
    };

    if (isFetching || !selectedCompany)
      return (
        <div className="text-center mx-auto mt-3">
          <ProgressSpinner />
        </div>
      );

    if (isError)
      return (
        <ErrorMessage
          content={'Failed to obtain data! Please try again later.'}
        />
      );

    return <APVendorPaymentsTable payments={createIDForPayment(data)} />;
  }
);

export default APVendorPaymentsTableContainer;
