import moment from 'moment';

export const formatDate = (date: Date) => {
  if (!date) {
    return null;
  }

  return moment(date).format('L');
};

export const formatUTCDate = (date: Date | string, format?: string) => {
  if (!date) {
    return null;
  }

  return moment(new Date(date))
    .utc()
    .format(format ?? 'L');
};

export const formatCurrency = (data: number) => {
  if (data === null || data === undefined) {
    return null;
  }
  const roundedData = Math.round(data * 100) / 100;

  return (Math.abs(roundedData) === 0 ? 0 : roundedData).toLocaleString(
    'en-US',
    {
      style: 'currency',
      currency: 'USD',
    }
  );
};

export const capitalizeString = (text: string) => {
  if (!text) {
    return text;
  }

  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const cleanJsonEmptyValues = (jsonObject: object) => {
  const entries = Object.entries(jsonObject);
  const nonEmptyOrNull = entries.filter(
    ([key, val]) => val !== '' && val !== null && val !== undefined
  );
  const output = Object.fromEntries(nonEmptyOrNull);

  return output;
};

export const formatNamesToInitials = (name: string) => {
  const splitedName = name.split(' ');
  const cleanedList = splitedName.filter((word) => word);
  const initialsList = cleanedList.map((word) => word.charAt(0));
  const initials = initialsList.join('').toUpperCase();

  return initials;
};

type formatCellTextToAmountArgs = {
  text: string;
  pTotal?: number;
  exceedErrorMessage?: string;
  limitAmount?: number;
};

export const formatCellTextToAmount = ({
  text,
  limitAmount,
  pTotal,
  exceedErrorMessage,
}: formatCellTextToAmountArgs) => {
  if (!text) {
    return null;
  }

  const value = text.replace(/\$/g, '').replace(/,/g, '').replace(/%/g, '');

  let number = parseFloat(value);
  if (text.includes('%')) {
    const pValue = number / 100;
    number = (pTotal ?? 0) * pValue;
  }

  if (!number || number === 0) {
    throw Error('The amount is not applicable.');
  }

  if (
    limitAmount !== undefined &&
    limitAmount !== null &&
    (limitAmount >= 0 ? number > limitAmount : number < limitAmount)
  ) {
    throw Error(exceedErrorMessage ?? 'The amount exceeds the limit.');
  }

  return number;
};

export const formatPercentage = (data: number) => {
  if (data === null || data === undefined) {
    return null;
  }
  const roundedData = Math.round(data * 100) / 100;

  return `${roundedData.toFixed(2)}%`;
};
