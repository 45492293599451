import React, { MouseEvent, useCallback, useState } from 'react';
import { classNames } from 'primereact/utils';
import { Route, Routes } from 'react-router-dom';
import AppTopbar from './features/appTopBar/AppTopbar';
import MainSideBar from './features/navbar/MainSideBar';
import { CommandArgs } from './components/navbar/AppMenuTypes';
import AccountingDashboardRoutes from './routes/AccountingDashboardRoutes';
import AdminRoutes from './routes/AdminRoutes';
import checkPermissions from './HOCs/CheckPermissions';
import { useScreenWidthDetector } from './hooks/useScreenWidthDetector';

export const RTLContext = React.createContext(false);

const App = () => {
  const { isDesktop } = useScreenWidthDetector();

  const [desktopMenuActive, setDesktopMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const Admin = checkPermissions({
    sectionsToAccess: [],
    Children: AdminRoutes,
  });

  const onMenuButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (isDesktop) {
      setDesktopMenuActive((prevState) => !prevState);
      setMobileMenuActive(false);
    } else {
      setDesktopMenuActive(false);
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onScreenClick = () => {
    if ((desktopMenuActive || mobileMenuActive) && !isDesktop) {
      hideOverlayMenu();
    }
  };

  const onMenuItemClick = (event: CommandArgs) => {
    if (!event.item.items && !isDesktop) {
      hideOverlayMenu();
    }
  };

  const hideOverlayMenu = () => {
    setMobileMenuActive(false);
    setDesktopMenuActive(false);
  };

  const layoutContainerClassName = classNames(
    'layout-wrapper flex flex-column',
    'layout-topbar-white layout-menu-static',
    {
      'layout-menu-active': desktopMenuActive,
      'layout-menu-mobile-active': mobileMenuActive,
    }
  );

  return (
    <div
      className={layoutContainerClassName}
      onClick={onScreenClick}
      role="button"
      tabIndex={0}
      onKeyDown={onScreenClick}
    >
      <div
        className="menu-wrapper navBackground top-0 h-full sidebar sm:text-xl w-14rem sm:w-21rem"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className="layout-menu-container">
          <MainSideBar onItemClick={onMenuItemClick} />
        </div>
      </div>
      <AppTopbar
        onMenuButtonClick={onMenuButtonClick}
        active={desktopMenuActive || mobileMenuActive}
        mode={isDesktop ? 'desktop' : 'mobile'}
      />
      <div className="layout-main content min-h-0">
        <div className="layout-content bg-white p-0">
          <Routes>
            <Route
              path="/accounting-dashboard/*"
              element={<AccountingDashboardRoutes />}
            />
            <Route path="/admin/*" element={<Admin />} />
          </Routes>
        </div>
      </div>
      {mobileMenuActive && <div className="layout-mask modal-in"></div>}
    </div>
  );
};

export default App;
