import { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import SCFilters, {
  SubcontractsFilters,
} from '../../features/subcontracts/SCFilters';
import SCLatestPaymentsContainer from '../../features/subcontracts/SCLatestPayments/SCLatestPaymentsContainer';
import SCPaymentWorksheetContainer from '../../features/subcontracts/SCPaymentWorksheet/SCPaymentWorksheetContainer';
import SCResumeContainer from '../../features/subcontracts/SCResume/SCResumeContainer';
import SCReport from '../../features/subcontracts/SCReport/SCReport';
import SCReportHeader from '../../features/subcontracts/SCReport/SCReportHeader';
import SCScheduleContainer from '../../features/subcontracts/SCSchedule/SCScheduleContainer';
import SCStatusContainer from '../../features/subcontracts/SCStatus/SCStatusContainer';
import checkPermissions from '../../HOCs/CheckPermissions';
import SCParams from '../../features/subcontracts/SCParams';
import ReportSelector from '../../components/report-selector/ReportSelector';
import useProtectedReportOptions from '../../hooks/useProtectedReportOptions';
import {
  MODULES,
  reportsPerSection,
} from 'apps/tmr-frontend/src/utils/rolesConst';
import FiltersMenu from '../../components/filters-menu/FiltersMenu';
import { useCompanyContext } from '../../context/CompanyContext';

const Subcontracts = () => {
  const { selectedCompany } = useCompanyContext();
  const prevSelectedCompanyRef = useRef<string | null>(null);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const methods = useForm<SubcontractsFilters>();
  const { watch, resetField } = methods;
  const reportType = watch('reportType');

  const [protectedReports] = useProtectedReportOptions({
    reports: MODULES['subcontracts'],
  });

  const SCSchedule = checkPermissions({
    sectionsToAccess: reportsPerSection['subcontracts'],
    Children: SCScheduleContainer,
  });

  const SCSummary = checkPermissions({
    sectionsToAccess: reportsPerSection['subcontracts'],
    Children: SCResumeContainer,
  });

  const Payments = checkPermissions({
    sectionsToAccess: ['last_payment_summary'],
    Children: SCLatestPaymentsContainer,
  });
  const Worksheet = checkPermissions({
    sectionsToAccess: ['subcontract_payment_worksheet'],
    Children: SCPaymentWorksheetContainer,
  });
  const Status = checkPermissions({
    sectionsToAccess: ['subcontract_status_report'],
    Children: SCStatusContainer,
  });

  const handleReportChange = () => {
    resetField('jobs', { defaultValue: null });
    resetField('job', { defaultValue: null });
    resetField('subcontractor');
    resetField('subcontract', { defaultValue: null });
    resetField('dates');
    resetField('info');
    resetField('itemsState', { defaultValue: 'show' });
  };

  useEffect(() => {
    if (prevSelectedCompanyRef.current === null) {
      prevSelectedCompanyRef.current = selectedCompany?.id || null;
    } else if (prevSelectedCompanyRef.current !== selectedCompany?.id) {
      methods.reset();
      prevSelectedCompanyRef.current = selectedCompany?.id || null;
    }
  }, [selectedCompany, methods]);

  return (
    <div className="h-full flex flex-column">
      <FormProvider {...methods}>
        <div className="mt-6 mx-5 flex gap-2 flex-wrap">
          <div className="flex flex-column row-gap-4">
            <SCSchedule />
          </div>
          <SCSummary />
        </div>
        <div className="flex flex-column  gap-2 mx-3  mt-3">
          <div className="flex flex-row gap-2 justify-content-between">
            <ReportSelector
              protectedReports={protectedReports}
              onChange={() => {
                handleReportChange();
              }}
            />
            <div className="flex w-full justify-content-between  gap-2">
              <FiltersMenu>
                <SCFilters />
              </FiltersMenu>
              <SCReport elementRef={tableRef} />
            </div>
          </div>
        </div>
        <div className="mb-3 printBackground pdfDivFitContent" ref={tableRef}>
          <SCReportHeader />
          {reportType === 'paymentSummary' && <Payments />}
          {reportType === 'paymentWorksheet' && <Worksheet />}
          {reportType === 'subcontractPayments' && <Status />}
        </div>
        <SCParams />
      </FormProvider>
    </div>
  );
};

export default Subcontracts;
