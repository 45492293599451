import { CostDetail } from '../../../Interfaces/Accounting/JobsContracts.interface';

export function processCostList(data: CostDetail[]) {
  const newData: CostDetail[] = [];
  let currentFirstCost = data[0];
  let currentCostType = currentFirstCost?.CostType;
  let acumulator = { units: 0, hours: 0, cost: 0 };
  let phaseAcumulator = { units: 0, hours: 0, cost: 0 };

  data.forEach((cost: CostDetail, idx: number) => {
    if (
      cost.CostType === currentCostType &&
      currentFirstCost &&
      currentFirstCost.Phase === cost.Phase
    ) {
      acumulator.units = acumulator.units + cost.ActualUnits;
      acumulator.hours = acumulator.hours + cost.ActualHours;
      acumulator.cost = acumulator.cost + cost.ActualCost;
    } else {
      newData.push({
        Phase: currentFirstCost.Phase,
        PhaseDescription: currentFirstCost.PhaseDescription,
        Description: 'Cost Type Total:',
        CostType: currentCostType,
        ActualUnits: acumulator.units,
        ActualHours: acumulator.hours,
        ActualCost: acumulator.cost,
      } as CostDetail);

      phaseAcumulator.units = phaseAcumulator.units + acumulator.units;
      phaseAcumulator.hours = phaseAcumulator.hours + acumulator.hours;
      phaseAcumulator.cost = phaseAcumulator.cost + acumulator.cost;

      if (currentFirstCost.Phase !== cost.Phase) {
        newData.push({
          Phase: currentFirstCost.Phase,
          PhaseDescription: currentFirstCost.PhaseDescription,
          Description: 'Phase Total:',
          ActualUnits: phaseAcumulator.units,
          ActualHours: phaseAcumulator.hours,
          ActualCost: phaseAcumulator.cost,
        } as CostDetail);

        phaseAcumulator = { units: 0, hours: 0, cost: 0 };
      }

      currentFirstCost = cost;
      currentCostType = cost.CostType;
      acumulator = {
        units: cost.ActualUnits,
        hours: cost.ActualHours,
        cost: cost.ActualCost,
      };
    }

    newData.push(cost);

    if (idx + 1 === data.length) {
      phaseAcumulator.units = phaseAcumulator.units + acumulator.units;
      phaseAcumulator.hours = phaseAcumulator.hours + acumulator.hours;
      phaseAcumulator.cost = phaseAcumulator.cost + acumulator.cost;
      newData.push({
        Phase: currentFirstCost.Phase,
        PhaseDescription: currentFirstCost.PhaseDescription,
        Description: 'Cost Type Total:',
        CostType: currentCostType,
        ActualUnits: acumulator.units,
        ActualHours: acumulator.hours,
        ActualCost: acumulator.cost,
      } as CostDetail);

      newData.push({
        Phase: currentFirstCost.Phase,
        PhaseDescription: currentFirstCost.PhaseDescription,
        Description: 'Phase Total:',
        ActualUnits: phaseAcumulator.units,
        ActualHours: phaseAcumulator.hours,
        ActualCost: phaseAcumulator.cost,
      } as CostDetail);
    }
  });

  return newData;
}
