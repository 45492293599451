import { formatCurrency, formatUTCDate } from '../../../../utils/formatUtils';
import CheckBox from '../../../components/boxes/checkbox';
import {
  AIAChangedOrders,
  AIADetails,
  AIAFormTotals,
} from '../../../Interfaces/Accounting/AccountsReceivables.interfaces';

type AIAFormProps = {
  totals: AIAFormTotals;
  changedOrders: AIAChangedOrders;
  contractInfo: AIADetails;
  invoice: string;
};

const AIAForm = ({
  totals,
  changedOrders,
  contractInfo,
  invoice,
}: AIAFormProps) => {
  const contract = contractInfo.contract;

  const retainage =
    totals.PrevRetg - totals.PrevSMRetg + totals.WCRetg - totals.RetgRel;
  const smRetainage = totals.PrevSMRetg + totals.SMRetg;
  const sm = totals.SM + totals.PrevSM;
  const billed = totals.AmtBilled + totals.PrevAmt - sm;

  return (
    <div className="w-1008px h-778px">
      <div className="flex align-items-end gap-8">
        <div className="font-bold text-titlePT">
          APPLICATION AND CERTIFICATE FOR PAYMENT
        </div>
        <div className="font-bold ml-5 text-9pt timesNewRoman">
          Invoice #: <span className="ml-4 pl-1">{invoice}</span>
        </div>
      </div>
      <div className="border-3 border-bottom-none my-1" />
      <div className="flex gap-8 mt-2">
        <div>
          <div className="flex gap-1">
            <div className="flex gap-1 min-w-280px">
              <div className="font-bold text-labelPT vpNarrow">To Owner:</div>
              <div className="text-9pt">
                <div>{contract?.Name}</div>
                <div>{contract?.Address}</div>
                <div>{contract?.Address2}</div>
                <div>
                  {`${contract?.City}, ${contract?.State} ${contract?.Zip}`}
                </div>
              </div>
            </div>
            <div className="flex gap-1">
              <div className="font-bold text-labelPT vpNarrow">Project:</div>
              <div className="text-labelPT">
                {`${contract?.Contract} ${contract?.ContractName}`}
              </div>
            </div>
          </div>
          <div className="flex gap-1">
            <div className="flex gap-1 mt-2 min-w-280px">
              <div className="font-bold text-labelPT vpNarrow">
                From Contractor:
              </div>
              <div className="text-9pt">
                <div>{contractInfo.company.name}</div>
                <div>{contractInfo.company.address}</div>
                <div className="mt-2">
                  {`${contractInfo.company.city}, ${contractInfo.company.state} ${contractInfo.company.zip}`}
                </div>
              </div>
            </div>
            <div className="flex gap-1 mt-2">
              <div className="font-bold text-labelPT vpNarrow">
                Via Architect:
              </div>
              <div className="text-labelPT"></div>
            </div>
          </div>
          <div className="flex gap-1 mt-2">
            <div className="font-bold text-labelPT vpNarrow">Contract For:</div>
          </div>
        </div>
        <div className="ml-2 flex gap-8">
          <div className="flex flex-column">
            <div className="flex gap-1">
              <div className="font-bold text-labelPT vpNarrow">
                Application No.:
              </div>
              <div className="ml-8 text-9pt">{contract?.AppNumber}</div>
            </div>
            <div className="mt-4 flex gap-1">
              <div className="font-bold text-labelPT vpNarrow">Period To:</div>
              <div className="ml-5 text-9pt">
                {formatUTCDate(contract?.ToDate)}
              </div>
            </div>
            <div className="mt-5 pt-2">
              <div className="font-bold text-labelPT vpNarrow">
                Project Nos:
              </div>
            </div>
            <div className="mt-auto">
              <div className="font-bold text-labelPT vpNarrow">
                Contract Date:
              </div>
            </div>
          </div>
          <div className="flex flex-column">
            <div className="font-bold text-labelPT vpNarrow">
              Distribution to:
            </div>
            <div className="font-bold flex gap-2 text-labelPT vpNarrow">
              <CheckBox />
              Owner
            </div>
            <div className="font-bold flex gap-2 text-labelPT vpNarrow">
              <CheckBox />
              Architect
            </div>
            <div className="font-bold flex gap-2 text-labelPT vpNarrow">
              <CheckBox />
              Contractor
            </div>
            <div className="font-bold flex gap-2 text-labelPT vpNarrow">
              <CheckBox />
            </div>
            <div className="font-bold flex gap-2 mt-1 text-labelPT vpNarrow">
              <CheckBox />
            </div>
          </div>
        </div>
      </div>
      <div className="border-3 border-bottom-none my-1" />
      <div className="flex gap-6 mt-2">
        <div>
          <div className="font-bold text-subTitlePT">
            CONTRACTOR'S APPLICATION FOR PAYMENT
          </div>
          <div className="mt-2 text-notePT">
            Application is made for payment, as shown below, in connection with
            the Contract.
          </div>
          <div className="text-notePT">Continuation Sheet is attached.</div>
          <div className="mt-6">
            <div className="flex justify-content-between text-9pt w-430px">
              <div>
                1. Original Contract Sum . . . . . . . . . . . . . . . . . . . .
                . . .
              </div>
              <div>
                {formatCurrency(
                  totals.CurrContract -
                    (changedOrders.CurrentAdditions +
                      changedOrders.PreviousAdditions -
                      changedOrders.CurrentDeductions -
                      changedOrders.PreviousDeductions) +
                    totals.ChangeAmount
                )}
              </div>
            </div>
            <div className="mt-2 flex justify-content-between text-9pt w-430px">
              <div>
                2. Net Change By Change Order . . . . . . . . . . . . . . . . .
                . . .
              </div>
              <div>
                {formatCurrency(
                  changedOrders.CurrentAdditions +
                    changedOrders.PreviousAdditions -
                    changedOrders.CurrentDeductions -
                    changedOrders.PreviousDeductions
                )}
              </div>
            </div>
            <div className="mt-2 flex justify-content-between text-9pt w-430px">
              <div>
                3. Contract Sum To Date . . . . . . . . . . . . . . . . . . . .
                . . . . . .
              </div>
              <div>
                {formatCurrency(totals.CurrContract + totals.ChangeAmount)}
              </div>
            </div>
            <div className="mt-2 flex justify-content-between text-9pt w-430px">
              <div>
                4. Total Completed and Stored To Date . . . . . . . . . . . . .
                . .
              </div>
              <div>{formatCurrency(totals.PrevAmt + totals.AmtBilled)}</div>
            </div>
            <div className="mt-2 text-9pt ">5. Retainage:</div>
            <div className="mt-1 ml-2 flex flex-column text-9pt w-305px">
              <div className="flex pr-2 justify-content-between text-9pt ">
                <div>
                  a. {Math.round((retainage / billed) * 10000) / 100}% of
                  Completed Work
                </div>
                <div>{formatCurrency(retainage)}</div>
              </div>
              <div className="flex pr-2 mt-2 justify-content-between text-9pt ">
                <div>
                  b. {Math.round((smRetainage / (sm || 1)) * 10000) / 100}% of
                  Stored Material
                </div>
                <div>{formatCurrency(smRetainage)}</div>
              </div>
              <div className="flex ml-3 pr-3 mt-3 justify-content-between text-9pt w-430px">
                <div>
                  Total Retainage . . . . . . . . . . . . . . . . . . . . . . .
                  . . . .
                </div>
                <div className="pr-2">
                  {formatCurrency(
                    totals.PrevRetg +
                      totals.WCRetg +
                      totals.SMRetg -
                      totals.RetgRel
                  )}
                </div>
              </div>
            </div>
            <div className="mt-2 flex justify-content-between text-9pt text-9pt w-430px">
              <div>
                6. Total Earned Less Retainage . . . . . . . . . . . . . . . . .
                . .
              </div>
              <div>
                {formatCurrency(
                  totals.PrevAmt +
                    totals.AmtBilled -
                    totals.PrevRetg -
                    totals.WCRetg -
                    totals.SMRetg +
                    totals.RetgRel
                )}
              </div>
            </div>
            <div className="text-9pt">. . .</div>
            <div className="mt-2 flex justify-content-between text-9pt w-430px">
              <div>
                7. Less Previous Certificates For Payments . . . . . . . . . .
              </div>
              <div>{formatCurrency(totals.PrevAmt - totals.PrevRetg)}</div>
            </div>
            <div className="mt-2 flex justify-content-between text-9pt w-430px">
              <div>
                8. Current Payment Due . . . . . . . . . . . . . . . . . . . . .
                . . . .
              </div>
              <div>
                {formatCurrency(
                  totals.AmtBilled -
                    totals.WCRetg -
                    totals.SMRetg +
                    totals.RetgRel
                )}
              </div>
            </div>
            <div className="text-9pt">. .</div>
            <div className="mt-2 flex justify-content-between text-9pt w-430px">
              <div>
                9. Balance To Finish, Plus Retainage . . . . . . . . . . . . . .
                . . .
              </div>
              <div>
                {formatCurrency(
                  totals.CurrContract -
                    totals.PrevAmt -
                    totals.AmtBilled +
                    totals.PrevRetg +
                    totals.WCRetg +
                    totals.SMRetg +
                    totals.ChangeAmount -
                    totals.RetgRel
                )}
              </div>
            </div>
            <div className="text-9pt">. .</div>
          </div>
          <table className="mt-5 table-collapse">
            <thead>
              <tr className="font-normal">
                <th className="font-normal border-solid border-1 text-left pl-1 text-9pt w-215px">
                  CHANGE ORDER SUMMARY
                </th>
                <th className="font-normal border-solid border-1 text-9pt w-113px">
                  Additions
                </th>
                <th className="font-normal border-solid border-1 text-9pt w-113px">
                  Deductions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-right">
                <td className="border-solid border-1 pl-3 text-left text-biggerNotePT">
                  Total changes approved
                  <div> in previous months by Owner</div>
                </td>
                <td className="border-solid border-1 py-2 pr-1 text-10pt">
                  {formatCurrency(changedOrders.PreviousAdditions)}
                </td>
                <td className="border-solid border-1 pr-1 text-10pt">
                  {formatCurrency(changedOrders.PreviousDeductions)}
                </td>
              </tr>
              <tr className="text-right">
                <td className="border-solid border-1 pl-3 text-left text-biggerNotePT">
                  Total Approved this Month
                </td>
                <td className="border-solid border-1 py-1 pr-1 text-10pt">
                  {formatCurrency(changedOrders.CurrentAdditions)}
                </td>
                <td className="border-solid border-1 pr-1 text-10pt">
                  {formatCurrency(changedOrders.CurrentDeductions)}
                </td>
              </tr>
              <tr className="text-right">
                <td className="border-solid border-1 pr-2 text-biggerNotePT">
                  TOTALS
                </td>
                <td className="border-solid border-1 py-2 pr-1 text-10pt">
                  {formatCurrency(
                    changedOrders.CurrentAdditions +
                      changedOrders.PreviousAdditions
                  )}
                </td>
                <td className="border-solid border-1 pr-1 text-10pt ">
                  {formatCurrency(
                    changedOrders.CurrentDeductions +
                      changedOrders.PreviousDeductions
                  )}
                </td>
              </tr>
              <tr>
                <td className="border-solid border-1 pl-3 text-9pt">
                  Net Changes By Change Order
                </td>
                <td
                  className="border-solid border-1 font-bold text-10pt h-33px"
                  colSpan={2}
                >
                  <div className="w-6 text-right border-right-1 border-transparent pr-1">
                    {formatCurrency(
                      changedOrders.CurrentAdditions +
                        changedOrders.PreviousAdditions -
                        changedOrders.CurrentDeductions -
                        changedOrders.PreviousDeductions
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <div className="mt-1 text-9pt w-450px">
            The undersigned Contractor certifies that to the best of the
            Contractor's knowledge, information, and belief, the work covered by
            this Application for Payment has been completed in accordance with
            the Contract Documents. That all amounts have been paid by the
            Contractor for Work for which previous Certificates for Payment were
            issued and payments received from the Owner, and that current
            payment shown herein is now due.
          </div>
          <div className="mt-3 text-9pt">
            <span>CONTRACTOR:</span>
            <span className="ml-4">{contractInfo.company.name}</span>
          </div>
          <div className="mt-5 pt-2 text-9pt">
            By:____________________________________Date:______________
          </div>
          <div className="mt-5 text-labelPT w-355px">
            <div className="flex justify-content-between">
              <span>State of:</span>
              <span>County of:</span>
            </div>
            <div className="flex justify-content-between">
              <span>Subscribed and sworn to before me this</span>
              <span className="mr-3 pr-1">day of</span>
            </div>
            <div className="flex justify-content-between">Notary Public:</div>
            <div className="flex justify-content-between">
              My Commission expires:
            </div>
          </div>
          <div className="border-2 border-bottom-none mt-4 mb-2" />
          <div className="font-bold text-9pt">
            ARCHITECT'S CERTIFICATE FOR PAYMENT
          </div>
          <div className="text-littleLabelPT w-430px">
            In accordance with the Contract Documents, based on on-site
            observations and the data comprising the above application, the
            Architect certifies to the Owner that to the best of the Architect's
            knowledge, information, and belief, the Work has progressed as
            indicated, the quality of the Work is in accordance with the
            Contract Documents, and the Contractor is entitled to payment of the
            AMOUNT CERTIFIED.
          </div>
          <div className="font-bold mt-4 flex align-items-start text-7pt">
            <span>AMOUNT CERTIFIED</span>
            <span className="font-normal ml-2 text-10pt">
              {formatCurrency(
                totals.AmtBilled -
                  totals.WCRetg -
                  totals.SMRetg +
                  totals.RetgRel
              )}
            </span>
          </div>
          <div className="font-italic mt-2 text-7pt w-500px">
            (Attach explanation if amount certified differs from the amount
            applied. Initial all figures on this Application and on the
            Continuation Sheet that are changed to conform with the amount
            certified.)
          </div>
          <div className="mt-4 font-bold text-9pt">ARCHITECT:</div>
          <div className="mt-2 text-9pt">
            By:____________________________________Date:______________
          </div>
          <div className="mt-3 text-9pt w-470px">
            This Certificate is not negotiable. The AMOUNT CERTIFIED is payable
            only to the Contractor named herein. Issuance, payment, and
            acceptance of payment are without prejudice to any rights of the
            Owner or Contractor under this Contract.
          </div>
        </div>
      </div>
      <div className="border-3 border-bottom-none my-2" />
    </div>
  );
};

export default AIAForm;
