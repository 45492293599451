import React, { useEffect } from 'react';
import DropdownStyled from '../../../components/inputs/StyledInputs/DropdownStyled';
import { Controller, useFormContext } from 'react-hook-form';
import { useCompanyContext } from '../../../context/CompanyContext';

const GROUP_TYPE_OPTIONS = [
  { label: 'Per Job', value: 'job' },
  { label: 'Per Vendor', value: 'vendor' },
  { label: 'Per PM', value: 'pm' },
];

export type SubcontractsResumeFilters = {
  groupType: string;
};

const SCResumeFilters = () => {
  const { control, reset } = useFormContext();
  const { selectedCompany } = useCompanyContext();

  useEffect(() => {
    reset();
  }, [selectedCompany, reset]);

  return (
    <div className="flex flex-wrap justify-content-center align-items-end sm:justify-content-start gap-4 form">
      <div className="w-10rem">
        <Controller
          control={control}
          name="groupType"
          render={({ field }) => (
            <DropdownStyled
              options={GROUP_TYPE_OPTIONS}
              clearable={false}
              labelField="label"
              valueField="value"
              {...field}
            />
          )}
        />
      </div>
    </div>
  );
};

export default SCResumeFilters;
