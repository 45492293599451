export const CONTRACT_STATES = [
  { label: 'Active Contracts', value: 'active' },
  { label: 'Closed Contracts', value: 'closed' },
];

export const JOB_STATES = [
  { label: 'All Jobs', value: 'all' },
  { label: 'Active Jobs', value: 'active' },
  { label: 'Closed Jobs', value: 'closed' },
];

export const STATES_TO_STATUS: Record<string, number[]> = {
  active: [1],
  closed: [2, 3],
};

export const startDateReport = ['costRevenue', 'costDetail'];
export const endDateReports = ['costRevenue', 'costDetail', 'costs'];
