import React from 'react';
import ReportButton from '../../../components/report/ReportButton';
import * as XLSX from 'xlsx';
import { useCompanyContext } from '../../../context/CompanyContext';
import { useWatch } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import {
  BASE_PDF_STYLES,
  getContractName,
  getContractNames,
  getJobDescription,
} from '../../../../utils/reportUtils';
import { fecthCBContracts } from '../../../services/ContractBillingsService';
import { excelCleanTable, getNextColumn } from '../../../../utils/excelUtils';
import { JobContractFilters } from '../JCFilters';
import {
  getContractStateLabel,
  getJCPhasesLabel,
  getJCPmNames,
  getJCReportTypeName,
  getJobStateLabel,
  getSubmittedDraft,
} from './JCReportUtils';
import { formatDate } from '../../../../utils/formatUtils';
import {
  fecthJobs,
  fetchJCJobPhases,
  fetchJCJobProjectManagers,
  fetchSubmittedProjectedCosts,
} from '../../../services/JobsContractsService';

export const idPerJCReportType = {
  contractAnalysis: { id: 'jc-contract-analysis', isPortrait: false },
  costRevenue: { id: 'jc-cost-revenue', isPortrait: false },
  costEstimate: { id: 'jc-cost-estimate', isPortrait: false },
  costDetail: { id: 'jc-cost-detail', isPortrait: false },
  costs: { id: 'jc-cost-dates', isPortrait: false },
  itemDetails: { id: 'jc-contract-items', isPortrait: false },
  projectedJC: { id: 'jc-projected-cost', isPortrait: false },
  phaseMaster: { id: 'jc-phase-master', isPortrait: true },
  jobPhases: { id: 'jc-job-phases', isPortrait: true },
};

type JCReportProps = {
  elementRef: React.MutableRefObject<HTMLElement | null>;
};

const JCReport = ({ elementRef }: JCReportProps) => {
  const { selectedCompany } = useCompanyContext();
  const filters = useWatch<JobContractFilters>();
  const reportDate = new Date();
  const reportInfo =
    idPerJCReportType[filters.reportType as keyof typeof idPerJCReportType];

  const { data } = useQuery({
    queryKey: ['getContracts', selectedCompany],
    queryFn: ({ signal }) =>
      fecthCBContracts({ companyID: selectedCompany!.id }, signal),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const jobs = useQuery({
    queryKey: ['getJCJobs', selectedCompany, filters.jobState],
    queryFn: ({ signal }) =>
      fecthJobs(
        {
          companyID: selectedCompany!.id,
          status: filters.jobState === 'active' ? [1] : [2, 3],
        },
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const phases = useQuery({
    queryKey: ['getJobCostPhases', selectedCompany, filters.job],
    queryFn: ({ signal }) =>
      fetchJCJobPhases(
        {
          companyID: selectedCompany!.id,
          job: filters.job ?? '',
          hasCost: true,
        },
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const analysisContracts = useQuery({
    queryKey: ['getAnalysisContracts', selectedCompany, filters.contractState],
    queryFn: ({ signal }) =>
      fecthCBContracts({ companyID: selectedCompany!.id }, signal),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const pms = useQuery({
    queryKey: ['getJobPMs', selectedCompany],
    queryFn: ({ signal }) =>
      fetchJCJobProjectManagers(selectedCompany!.id, signal),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const submittedCosts = useQuery({
    queryKey: ['getSubmittedProjectedCosts', selectedCompany, filters.job],
    queryFn: ({ signal }) =>
      fetchSubmittedProjectedCosts(
        selectedCompany!.id,
        filters.job ?? '',
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const projectedCostsBeforeDownload = (
    sheet: XLSX.WorkSheet,
    element?: HTMLElement
  ) => {
    const date = element?.querySelector('#jc-last-protection-date');

    sheet[`A13`] = { t: 's', v: 'Last Projection Cost Month:' };
    sheet[`B13`] = { t: 's', v: date?.textContent };

    const ref = sheet['!ref'];
    const boundary = (ref ?? '').split(':');
    const lastRow = parseInt(boundary[1].replace(/[A-Z]/g, ''));
    const lastCol = boundary[1].match(/[A-Za-z]+/)?.[0] || '';

    const totalPayables = element?.querySelector('#newProjectedCost');
    const nextToLastCol = getNextColumn(lastCol, -1);
    const lastColAdjusted = getNextColumn(lastCol, 0);

    sheet[`${nextToLastCol}${lastRow + 2}`] = {
      t: 's',
      v: 'New Projected Cost:',
    };
    sheet[`${lastColAdjusted}${lastRow + 2}`] = {
      t: 's',
      v: totalPayables?.textContent,
    };

    sheet['!fullref'] = `A${1}:${lastColAdjusted}${lastRow + 2}`;
    sheet['!ref'] = `A${1}:${lastColAdjusted}${lastRow + 2}`;
  };

  const excelElementPreProcess = (element: HTMLElement) => {
    excelCleanTable(element);

    return element;
  };

  const excelBeforeDownload = (
    workBook: XLSX.WorkBook,
    element?: HTMLElement
  ) => {
    const sheet = workBook.Sheets['Sheet1'];
    const companyName = selectedCompany!.name;

    sheet['D2'] = { t: 's', v: companyName };
    sheet['D3'] = { t: 's', v: 'Jobs & Contracts Report' };
    sheet['A4'] = { t: 's', v: 'Search criteria:' };
    sheet['B5'] = { t: 's', v: 'Report Type:' };
    sheet['C5'] = {
      t: 's',
      v: getJCReportTypeName(filters.reportType ?? ''),
    };

    let rowStart = 6;

    if (filters.contractState && filters.reportType === 'contractAnalysis') {
      sheet[`B${rowStart}`] = { t: 's', v: 'Contract State:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: getContractStateLabel(filters.contractState),
      };

      rowStart += 1;
    }

    if (filters.jobState && filters.reportType === 'costRevenue') {
      sheet[`B${rowStart}`] = { t: 's', v: 'Job State:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: getJobStateLabel(filters.jobState),
      };

      rowStart += 1;
    }

    if (filters.pms && filters.reportType === 'contractAnalysis') {
      sheet[`B${rowStart}`] = { t: 's', v: 'Project Managers:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: getJCPmNames(filters.pms, pms.data),
      };

      rowStart += 1;
    }

    if (filters.contracts) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Contracts:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: filters.contracts.join(', '),
      };
      sheet[`B${rowStart + 1}`] = { t: 's', v: 'Job Names:' };
      sheet[`C${rowStart + 1}`] = {
        t: 's',
        v: getContractNames(filters.contracts, analysisContracts.data),
      };
      rowStart += 2;
    }

    if (filters.contract) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Contract:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: filters.contract,
      };
      sheet[`B${rowStart + 1}`] = { t: 's', v: 'Job Name:' };
      sheet[`C${rowStart + 1}`] = {
        t: 's',
        v: getContractName(filters.contract, data),
      };
      rowStart += 2;
    }

    if (filters.job) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Job:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: filters.job,
      };
      sheet[`B${rowStart + 1}`] = { t: 's', v: 'Job Name:' };
      sheet[`C${rowStart + 1}`] = {
        t: 's',
        v: getJobDescription(filters.job, jobs.data),
      };
      rowStart += 2;
    }

    if (filters.submittedID) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Submitted Draft:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: getSubmittedDraft(filters.submittedID, submittedCosts.data),
      };

      rowStart += 1;
    }

    if (filters.phases && filters.phases.length > 0) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Phases:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: getJCPhasesLabel(filters.phases, phases.data),
      };

      rowStart += 1;
    }

    if (filters.startDate) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Start Date:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: formatDate(filters.startDate),
      };

      rowStart += 1;
    }

    if (filters.endDate) {
      sheet[`B${rowStart}`] = { t: 's', v: 'End Date:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: formatDate(filters.endDate),
      };

      rowStart += 1;
    }

    if (filters.info) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Search term:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: filters.info,
      };

      rowStart += 1;
    }

    sheet[`A${rowStart}`] = { t: 's', v: 'Created at:' };
    sheet[`B${rowStart}`] = { t: 's', v: reportDate.toLocaleString() };

    if (filters.reportType === 'projectedJC') {
      projectedCostsBeforeDownload(sheet, element);
    }
  };

  return (
    <ReportButton
      elementToPrintRef={elementRef}
      fileName="J&C_Report"
      pdfStyles={BASE_PDF_STYLES}
      excelPreprocess={excelElementPreProcess}
      excelBeforeDownload={excelBeforeDownload}
      tableOriginCell="A15"
      widthElementID={reportInfo?.id}
      isPdfPortrait={reportInfo?.isPortrait}
    />
  );
};

export default JCReport;
