import FolderListContainer from '../../../features/financialStatement/FolderList/FolderListContainer';

const FSFolders = () => {
  return (
    <div className="h-full flex flex-column">
      <div className="mx-5 mb-2 mt-4"></div>
      <div className="mb-3 mx-8">
        <FolderListContainer />
      </div>
    </div>
  );
};

export default FSFolders;
