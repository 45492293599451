import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCompanyContext } from '../../../context/CompanyContext';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { fetchSCPaymentStatus } from '../../../services/SubcontractsService';
import { useFormContext } from 'react-hook-form';
import { SubcontractsFilters } from '../SCFilters';
import SCStatusTable from './SCStatusTable';

const SCStatusContainer = React.forwardRef<HTMLDivElement>((props, ref) => {
  const { selectedCompany } = useCompanyContext();
  const { watch } = useFormContext<SubcontractsFilters>();
  const filters = watch();
  const isEnabled = selectedCompany && filters.job;

  const { data, isFetching, isError } = useQuery({
    queryKey: ['getSCSubcontractStatus', selectedCompany, filters],
    queryFn: ({ signal }) => {
      const { subcontract, info, job } = filters;

      return fetchSCPaymentStatus(
        {
          companyID: selectedCompany!.id,
          job: job ?? '',
          subcontract,
          info,
        },
        signal
      );
    },
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });

  if (!isEnabled) {
    return (
      <div className="mt-4">
        <ErrorMessage
          severity="info"
          content={'To start the search please select a Job.'}
        />
      </div>
    );
  }

  if (isFetching)
    return (
      <div className="text-center mx-auto mt-3">
        <ProgressSpinner />
      </div>
    );

  if (isError)
    return (
      <ErrorMessage
        content={'Failed to obtain data! Please try again later.'}
      />
    );

  return <SCStatusTable subcontracts={data} />;
});

export default SCStatusContainer;
