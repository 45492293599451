import React from 'react';
import { Link } from 'react-router-dom';

type SuccessMessageProps = {
  message: string;
};

const SuccessMessage = ({ message }: SuccessMessageProps) => {
  return (
    <div className="w-fit mx-auto flex flex-column">
      <div className="text-center text-40px font-bold text-green-500 mb-3">
        Success!
      </div>
      <i className="pi pi-check-circle mx-auto text-40px text-green-500 mb-3" />
      <div className="text-standard mt-3 mx-auto">{message}</div>
      <Link
        to="/admin/users"
        className="mx-auto totalColor hover:underline font-bold mt-3"
      >
        Click here to return to the users page
      </Link>
    </div>
  );
};

export default SuccessMessage;
