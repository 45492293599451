export function dateDiffInDays(start: Date, end: Date) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  const utcStart = Date.UTC(
    start.getUTCFullYear(),
    start.getUTCMonth(),
    start.getUTCDate()
  );
  const utcEnd = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate());

  return Math.floor((utcEnd - utcStart) / _MS_PER_DAY);
}

export const underXDays = (
  date: string | Date,
  daysDifference: number,
  plusLastDay?: boolean
) => {
  const dueDate = new Date(date);
  const diference = dateDiffInDays(dueDate, new Date()) + (plusLastDay ? 1 : 0);
  return diference <= daysDifference;
};

export const getDatePeriod = (
  date: string | Date,
  periods: {
    label: string;
    differenceInDays: number;
  }[],
  plusLastDay?: boolean
) => {
  for (const period of periods) {
    if (underXDays(date, period.differenceInDays, plusLastDay)) {
      return period.label;
    }
  }

  return periods[periods.length - 1].label;
};

export const isTodayOrEarlier = (date: Date) => {
  date.setHours(0, 0, 0, 0);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if (date <= today) {
    return true;
  }
  return false;
};

export const transformUTCtoLocale = (date: Date) => {
  const newDate = new Date(date);
  const year = newDate.getUTCFullYear();
  const month = newDate.getUTCMonth();
  const day = newDate.getUTCDate();

  return new Date(year, month, day);
};
