import React, { useMemo, useState } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { formatUTCDate, formatCurrency } from '../../../../utils/formatUtils';
import { VendorPayments } from '../../../Interfaces/Accounting/AccountsPayables.interfaces';
import {
  DataTableExpandedRows,
  DataTableRowClickEventParams,
  DataTableRowToggleParams,
} from 'primereact/datatable';
import APVendorDetailsTableContainer from './APVendorDetailsTableContainer';
import { PaymentMethod } from '../../../../utils/apConst';

type APVendorPaymentsTableProps = {
  payments: VendorPayments[];
};

const APVendorPaymentsTable = React.forwardRef<
  HTMLDivElement,
  APVendorPaymentsTableProps
>(({ payments }, ref) => {
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows>({});

  const getTotals = (payments: VendorPayments[]) => {
    let amountTotal = 0;
    let discTotal = 0;
    let deductTotal = 0;
    let netTotal = 0;

    payments.forEach((payment) => {
      amountTotal += payment.amount;
      discTotal += payment.discount || 0;
      deductTotal += payment.deduct || 0;
      netTotal += payment.netAmount;
    });

    return { amountTotal, discTotal, deductTotal, netTotal };
  };

  const calcHeight = (rows: number) => {
    const headerHeight = 32;
    const footerHeight = 54;
    const rowHeight = 42;
    return headerHeight + footerHeight + rows * rowHeight + 3;
  };

  const rowClassName = (data: VendorPayments) => {
    const ref = data.uuid;
    const isExpanded = expandedRows[ref];
    return isExpanded ? 'cursor-pointer expandedRow' : 'cursor-pointer';
  };

  const rowExpansionTemplate = (data: VendorPayments) => {
    return (
      <APVendorDetailsTableContainer
        cmRef={data.CMRef}
        cmAcct={data.CMAcct}
        vendor={data.vendor.id}
        vendorGroup={data.vendor.vendorGroup || ''}
      />
    );
  };

  const onRowClick = (e: DataTableRowClickEventParams) => {
    const expanded = { ...expandedRows };
    const ref = e.data.uuid;
    if (expanded[ref]) {
      delete expanded[ref];
      setExpandedRows(expanded);
    } else {
      expanded[ref] = true;
      setExpandedRows(expanded);
    }
  };

  const onRowToogle = (e: DataTableRowToggleParams) => {
    setExpandedRows(e.data as DataTableExpandedRows);
  };

  const totals = useMemo(() => getTotals(payments), [payments]);

  return (
    <Table
      id="payment-history-table"
      ref={ref}
      data={payments}
      className="mx-3 dashboardOptionShadow with-expanded"
      calcHeight={calcHeight}
      dataKey="uuid"
      expandedRows={expandedRows}
      rowExpansionTemplate={rowExpansionTemplate}
      onRowClick={onRowClick}
      onRowToggle={onRowToogle}
      rowClassName={rowClassName}
      hideColumns={true}
    >
      <Column
        style={{ flexBasis: '10%' }}
        headerClassName={`tableHeader`}
        className="tableCell p-0 printHide"
        footerClassName="tableFooter"
      />
      <Column
        expander={true}
        style={{ minWidth: '100px', flexBasis: '6%' }}
        headerClassName={`tableHeader font-normal`}
        className={`text-standard blackText printHide tableCell justify-content-center`}
        footerClassName="tableFooter"
      />
      <Column
        field="vendor.name"
        header="Vendor"
        headerClassName={`tableHeader font-normal justify-content-center`}
        style={{ minWidth: '370px', flexBasis: '15%' }}
        className={`text-standard blackText tableCell`}
        footerClassName="tableFooter"
        sortable
      />
      <Column
        field="CMAcct"
        header="CM Acc"
        headerClassName={`tableHeader font-normal`}
        style={{ minWidth: '150px', flexBasis: '7%' }}
        className={`text-standard blackText tableCell justify-content-center`}
        footerClassName="tableFooter"
        sortable
      />
      <Column
        field="payMethod"
        header="Pay Method"
        body={(ap) =>
          PaymentMethod[ap.payMethod as keyof typeof PaymentMethod] ||
          ap.payMethod
        }
        headerClassName={`tableHeader font-normal`}
        style={{ minWidth: '150px', flexBasis: '7%' }}
        className={`text-standard blackText tableCell justify-content-center`}
        footerClassName="tableFooter"
        sortable
      />
      <Column
        field="CMRef"
        header="CMRef"
        headerClassName={`tableHeader font-normal`}
        style={{ minWidth: '150px', flexBasis: '7%' }}
        className={`text-standard blackText tableCell justify-content-center`}
        footerClassName="tableFooter"
        sortable
      />
      <Column
        field="paidDate"
        header="Date"
        body={(payment) => formatUTCDate(payment.paidDate)}
        headerClassName={`tableHeader font-normal`}
        style={{ minWidth: '150px', flexBasis: '7%' }}
        className={`text-standard blackText tableCell justify-content-center`}
        footer="Total"
        footerClassName="tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
        sortable
      />
      <Column
        field="amount"
        header="Amount"
        body={(payment) => formatCurrency(payment.amount)}
        headerClassName={`tableHeader font-normal justify-content-center`}
        style={{ minWidth: '150px', flexBasis: '7%' }}
        className={`justify-content-end text-standard blackText tableCell`}
        footer={formatCurrency(totals.amountTotal)}
        footerClassName="tableFooter block text-right border-top-2 mt-4"
        sortable
      />
      <Column
        field="discount"
        header="Discount"
        body={(payment) => formatCurrency(payment.discount || 0)}
        headerClassName={`tableHeader font-normal justify-content-center`}
        style={{ minWidth: '150px', flexBasis: '7%' }}
        className={`justify-content-end text-standard blackText tableCell`}
        footer={formatCurrency(totals.discTotal)}
        footerClassName="tableFooter block text-right border-top-2 mt-4"
        sortable
      />
      <Column
        field="deduct"
        header="Deduct"
        body={(payment) => formatCurrency(payment.deduct || 0)}
        headerClassName={`tableHeader font-normal justify-content-center`}
        style={{ minWidth: '150px', flexBasis: '7%' }}
        className={`justify-content-end text-standard blackText tableCell`}
        footer={formatCurrency(totals.deductTotal)}
        footerClassName="tableFooter block text-right border-top-2 mt-4"
        sortable
      />
      <Column
        field="netAmount"
        header="Net Payment"
        body={(payment) => formatCurrency(payment.netAmount)}
        headerClassName={`tableHeader font-normal justify-content-center`}
        style={{ minWidth: '200px', flexBasis: '10%' }}
        className={`justify-content-end text-standard blackText tableCell`}
        footer={formatCurrency(totals.netTotal)}
        footerClassName="tableFooter block text-right border-top-2 mt-4"
        sortable
      />
      <Column
        style={{ flexBasis: '10%' }}
        headerClassName={`tableHeader`}
        className="tableCell p-0 printHide"
        footerClassName="tableFooter"
      />
    </Table>
  );
});

const transactionsAreEqual = (
  prevTransactions: Readonly<APVendorPaymentsTableProps>,
  nextTransactions: Readonly<APVendorPaymentsTableProps>
) => {
  return prevTransactions.payments === nextTransactions.payments;
};

export default React.memo(APVendorPaymentsTable, transactionsAreEqual);
