import axios from 'axios';
import { auth } from '../../firebase';
import { Company } from '../Interfaces/User.interfaces';

const apiUrl = process.env['NX_API_URL'];

export const fetchAccountingDashboardAccounts = async (
  company: Company,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/accountingDashboard/accountsData`,
    {
      params: {
        companyID: company.id,
        source: company.source,
      },
      signal,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const data = response.data;
  return data;
};

export const fetchAccountingDashboardContracts = async (
  company: Company,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/accountingDashboard/contractsData`,
    {
      params: {
        companyID: company.id,
        source: company.source,
      },
      signal,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const data = response.data;
  return data;
};

export const fetchAccountingDashboard = async () => {
  const response = await axios.get(`${apiUrl}/accounting-dashboard`);
  const dashboard = response.data;
  return dashboard;
};
