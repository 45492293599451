import { Accordion, AccordionTab } from 'primereact/accordion';
import { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import ModuleAccordionHeader from './ModuleAccordionHeader';
import ReportOption from './ReportOption';
import {
  AccessForm,
  EditValues,
  SystemAccess,
} from '../../Interfaces/Role.interfaces';

type ModulesAccordionProps = {
  sections: Record<string, SystemAccess[]>;
};

const ModulesAccordion = ({ sections }: ModulesAccordionProps) => {
  const [accordionTabs, setAccordionTabs] = useState<JSX.Element[]>([]);
  const [activeTab, setActiveTab] = useState<number[]>([]);
  const [checkedModules, setCheckedModules] = useState<Record<string, boolean>>(
    {}
  );
  const { fields, append, remove } = useFieldArray({
    name: 'access',
  });
  const results = useWatch({ name: 'access' });

  const updateCheckedModules = (module: string, checked: boolean) => {
    setCheckedModules((value) => {
      const tempChecked = { ...value };
      tempChecked[module] = checked;
      return tempChecked;
    });
  };

  const accordionTab = (
    module: string,
    startIndex: number,
    children: JSX.Element[]
  ) => {
    return (
      <AccordionTab
        key={module}
        header={
          <ModuleAccordionHeader
            module={module}
            checkedModules={checkedModules}
            updateModule={updateCheckedModules}
            startIndex={startIndex}
            count={children.length}
          />
        }
      >
        {children}
      </AccordionTab>
    );
  };

  const accordionTabCB = useCallback(accordionTab, [checkedModules]);

  const buildAccordeon = (fields: AccessForm[]) => {
    const result: JSX.Element[] = [];
    let optionsList: JSX.Element[] = [];
    let currentModule = fields[0]?.module;

    fields.forEach((item, index) => {
      if (item.module !== currentModule && optionsList.length) {
        result.push(
          accordionTabCB(currentModule, index - optionsList.length, optionsList)
        );
        optionsList = [];
      }
      currentModule = item.module;

      const option = (
        <ReportOption key={item.report} item={item} index={index} />
      );
      optionsList.push(option);
    });

    if (optionsList.length) {
      result.push(
        accordionTabCB(
          currentModule,
          fields.length - optionsList.length,
          optionsList
        )
      );
    }

    return result;
  };

  const buildAccordeonCB = useCallback(buildAccordeon, [accordionTabCB]);

  useEffect(() => {
    const reports: AccessForm[] = [];
    Object.keys(sections).forEach((key) => {
      const reportList = sections[key];
      reportList.forEach((option) => {
        const { value, edition, notification, approval } = option;
        const fields = {
          report: value,
          label: value.replace(/_/g, ' '),
          editOption: edition?.map((option) => {
            let label = `${option}`;
            let value: EditValues = option;

            switch (label) {
              case 'false':
                label = 'View Only';
                value = 'view-only';
                break;
              case 'true':
                label = 'Standard';
                break;
              case 'approval':
                label = 'With Approval';
                break;
              case 'no-approval':
                label = 'Without Approval';
                break;
              default:
                break;
            }

            return { label, value };
          }),
          notification,
          selected: false,
          module: key,
          approvalOption:
            typeof approval === 'number'
              ? [...Array(approval + 1).keys()].map((i) => i + 1)
              : approval,
        };
        reports.push(fields);
      });
    });

    remove();
    append(reports);
  }, [append, remove]);

  useEffect(() => {
    let length = 0;
    Object.keys(sections).forEach((key) => {
      const reportList = sections[key];
      length += reportList.length;
    });
    if (fields.length === length) {
      const list = buildAccordeonCB(fields as unknown as AccessForm[]);
      setAccordionTabs(list);
    }
  }, [fields, buildAccordeonCB]);

  useEffect(() => {
    let currentModule = results ? results[0]?.module : null;
    let counter = 0;
    let checkedCounter = 0;
    results?.forEach((access: AccessForm) => {
      if (access.module === currentModule) {
        counter += 1;
        checkedCounter += access.selected ? 1 : 0;
      } else {
        updateCheckedModules(
          currentModule,
          counter === checkedCounter && counter !== 0
        );

        currentModule = access.module;
        counter = 1;
        checkedCounter = access.selected ? 1 : 0;
      }
    });

    if (counter !== 0) {
      updateCheckedModules(
        currentModule,
        counter === checkedCounter && counter !== 0
      );
    }
  }, [results]);

  return (
    <Accordion
      className="w-full"
      multiple
      activeIndex={activeTab}
      onTabChange={(e) => {
        const element = e.originalEvent.target as HTMLElement;

        if (!element.className.includes('p-checkbox')) {
          setActiveTab(e.index as unknown as number[]);
        }
      }}
    >
      {accordionTabs}
    </Accordion>
  );
};

export default ModulesAccordion;
