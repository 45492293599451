import { Access, AccessForm } from '../app/Interfaces/Role.interfaces';

const editablePriority = (editable: string | boolean) => {
  switch (editable) {
    case 'no-approval':
      return 3;
    case 'approval':
      return 2;
    case true:
      return 1;
    default:
      return 0;
  }
};

export const removeDuplicates = (reportList: Access[]) => {
  const uniqueReportsMap = new Map<string, Access>();

  for (const reportObj of reportList) {
    const { report, editable, shouldApprove, shouldNotify, approvalOrder } =
      reportObj;

    if (uniqueReportsMap.has(report)) {
      const prevRep = uniqueReportsMap.get(report);

      const newObj = {
        report,
        shouldApprove: prevRep?.shouldApprove || shouldApprove,
        approvalOrder: !prevRep?.approvalOrder
          ? approvalOrder
          : (approvalOrder || 0) > prevRep.approvalOrder
          ? approvalOrder
          : prevRep.approvalOrder,
        shouldNotify: prevRep?.shouldNotify || shouldNotify,
        editable:
          editablePriority(editable || '') >
          editablePriority(prevRep?.editable || '')
            ? editable
            : prevRep?.editable,
      };

      uniqueReportsMap.set(report, newObj);
    } else {
      uniqueReportsMap.set(report, reportObj);
    }
  }

  return Array.from(uniqueReportsMap.values());
};

export const shouldBlockAdmin = (
  blockAdminType: boolean | 'external' | 'internal',
  userType: string
) => {
  if (typeof blockAdminType === 'string') {
    return blockAdminType === userType;
  }

  if (typeof blockAdminType === 'boolean') {
    return blockAdminType;
  }

  return false;
};

export const checkAccess = (
  rolesAcess: Access[],
  sectionsToAccess: string[]
) => {
  const match = rolesAcess.find((access) =>
    sectionsToAccess.includes(access.report)
  );

  return !!match;
};

export const processAccessForm = (acessForm: AccessForm[]) => {
  const selectedReports = acessForm.filter((data) => data.selected);

  const reports = selectedReports.map((data) => {
    const { report, editable, shouldNotify, shouldApprove } = data;

    const reportData = {
      report,
      editable: editable === 'view-only' ? false : editable,
      ...(shouldNotify !== undefined && { shouldNotify }),
      ...(shouldApprove !== undefined && { shouldApprove: !!shouldApprove }),
      ...(typeof shouldApprove === 'number' && {
        approvalOrder: shouldApprove,
      }),
    };

    if (editable === undefined) {
      delete reportData.editable;
    }

    return reportData;
  });

  return reports;
};
