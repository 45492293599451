import React from 'react';
import ReportButton from '../../../components/report/ReportButton';
import * as XLSX from 'xlsx';
import { useCompanyContext } from '../../../context/CompanyContext';
import { useWatch } from 'react-hook-form';
import {
  BASE_PDF_STYLES,
  getJobDescription,
  getJobDescriptions,
} from '../../../../utils/reportUtils';
import {
  excelCleanTable,
  excelFormatNestedRows,
} from '../../../../utils/excelUtils';
import { formatDate } from '../../../../utils/formatUtils';
import {
  getBillItemStatusLabel,
  getSCReportTypeName,
  getSubcontractorName,
  getSubmittedDraft,
} from './SCReportUtils';
import { SubcontractsFilters } from '../SCFilters';
import { useQuery } from '@tanstack/react-query';
import {
  fecthSCJobs,
  fecthSCVendors,
  fetchSCPaymentDrafts,
} from '../../../services/SubcontractsService';

const idPerSCReportType = {
  paymentSummary: 'sc-latest-payments',
  subcontractPayments: 'sc-status',
  paymentWorksheet: 'sc-payment-worksheet',
};

type SCReportProps = {
  elementRef: React.MutableRefObject<HTMLElement | null>;
};

const SCReport = ({ elementRef }: SCReportProps) => {
  const { selectedCompany } = useCompanyContext();
  const filters = useWatch<SubcontractsFilters>();
  const reportDate = new Date();

  const jobsRequest = useQuery({
    queryKey: ['getSubcontractJobs', selectedCompany],
    queryFn: ({ signal }) => fecthSCJobs(selectedCompany!.id, [1], signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  const vendorRequest = useQuery({
    queryKey: ['getSubcontractVendors', selectedCompany],
    queryFn: ({ signal }) =>
      fecthSCVendors({ companyID: selectedCompany!.id }, signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  const submittedSCBillings = useQuery({
    queryKey: [
      'getSubmittedSCBillings',
      selectedCompany,
      filters.job,
      filters.subcontract,
    ],
    queryFn: ({ signal }) =>
    fetchSCPaymentDrafts(
        {
          companyID: selectedCompany!.id,
          job: filters.job ?? '',
          subcontract: filters.subcontract ?? '',
          isSubmitted: true,
          isApproved: false,
        },
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const paymentWorksheetBeforeDownload = (sheet: XLSX.WorkSheet) => {
    const ref = sheet['!ref'];
    if (ref) {
      const boundary = ref.replace(/[A-Z]/g, '').split(':');
      const firstRow = 17;
      const lastRow = parseInt(boundary[1]);
      for (let i = firstRow; i <= lastRow; i++) {
        const cellK = sheet[`K${i}`];
        cellK.z = '0.00%';
        const cellL = sheet[`L${i}`];
        cellL.z = '0.00%';
      }
    }
  };

  const excelElementPreProcess = (element: HTMLElement) => {
    const tableBody = element.getElementsByClassName('p-datatable-tbody')[0];
    excelCleanTable(element);

    if (filters.reportType === 'subcontractPayments') {
      excelFormatNestedRows(tableBody as HTMLElement);
    }

    return element;
  };

  const subcontractStatusBeforeDownload = (sheet: XLSX.WorkSheet) => {
    const rows = sheet['!rows'];
    const ref = sheet['!ref'];
    const boundary = (ref ?? '').replace(/[A-Z]/g, '').split(':');
    const firstRow = parseInt(boundary[0]);
    const lastRow = parseInt(boundary[1]);

    for (let i = firstRow; i < lastRow; i++) {
      const cell = sheet[`A${i}`];

      if (cell && (typeof cell.v === 'number' || cell.v === 'Item')) {
        rows?.push({ level: 1 });
      } else if (cell && !cell.v) {
        rows?.push({ level: 2 });
      } else {
        rows?.push({ level: 0 });
      }
    }
  };

  const excelBeforeDownload = (
    workBook: XLSX.WorkBook,
    element?: HTMLElement
  ) => {
    const sheet = workBook.Sheets['Sheet1'];
    const companyName = selectedCompany!.name;

    sheet['C2'] = { t: 's', v: companyName };

    sheet['C3'] = { t: 's', v: 'Subcontract Report' };
    sheet['A4'] = { t: 's', v: 'Search criteria:' };

    sheet['B5'] = { t: 's', v: 'Report Type:' };
    sheet['C5'] = {
      t: 's',
      v: getSCReportTypeName(filters.reportType ?? ''),
    };

    let row = 6;

    if (filters.jobs && filters.jobs.length !== 0) {
      sheet[`B${row}`] = { t: 's', v: 'Jobs:' };
      sheet[`C${row}`] = {
        t: 's',
        v: filters.jobs.join(', '),
      };
      sheet[`B${row + 1}`] = { t: 's', v: 'Job Names:' };
      sheet[`C${row + 1}`] = {
        t: 's',
        v: getJobDescriptions(filters.jobs, jobsRequest.data),
      };
      row += 2;
    }

    if (filters.job) {
      sheet[`B${row}`] = { t: 's', v: 'Job:' };
      sheet[`C${row}`] = {
        t: 's',
        v: filters.job,
      };
      sheet[`B${row + 1}`] = { t: 's', v: 'Job Name:' };
      sheet[`C${row + 1}`] = {
        t: 's',
        v: getJobDescription(filters.job, jobsRequest.data),
      };
      row += 2;
    }

    if (filters.subcontractor && filters.reportType === 'paymentSummary') {
      sheet[`B${row}`] = { t: 's', v: 'Subcontractor:' };
      sheet[`C${row}`] = {
        t: 's',
        v: getSubcontractorName(filters.subcontractor, vendorRequest.data),
      };

      row += 1;
    }

    if (filters.subcontract && filters.reportType !== 'paymentSummary') {
      sheet[`B${row}`] = { t: 's', v: 'Subcontract:' };
      sheet[`C${row}`] = {
        t: 's',
        v: filters.subcontract,
      };

      row += 1;
    }

    if (filters.itemsState && filters.reportType === 'paymentWorksheet') {
      sheet[`B${row}`] = { t: 's', v: 'Items State:' };
      sheet[`C${row}`] = {
        t: 's',
        v: getBillItemStatusLabel(filters.itemsState),
      };

      row += 1;
    }

    if (filters.submittedID && filters.reportType === 'paymentWorksheet') {
      sheet[`B${row}`] = { t: 's', v: 'Submitted Draft:' };
      sheet[`C${row}`] = {
        t: 's',
        v: getSubmittedDraft(filters.submittedID, submittedSCBillings.data),
      };

      row += 1;
    }

    if (filters.dates && filters.reportType === 'paymentSummary') {
      sheet[`B${row}`] = { t: 's', v: 'Date range:' };
      sheet[`C${row}`] = {
        t: 's',
        v: `${formatDate(filters.dates[0])} ${
          filters.dates[1] ? ` - ${formatDate(filters.dates[1])}` : ''
        } `,
      };
      row += 1;
    }

    if (filters.info) {
      sheet[`B${row}`] = { t: 's', v: 'Search term:' };
      sheet[`C${row}`] = { t: 's', v: filters.info };
      row += 1;
    }

    sheet[`A${row}`] = { t: 's', v: 'Created at:' };
    sheet[`B${row}`] = { t: 's', v: reportDate.toLocaleString() };

    if (filters.reportType === 'paymentWorksheet') {
      const totalPayables = element?.querySelector('#sc-vendor-info');
      sheet[`A${row + 1}`] = { t: 's', v: 'Vendor info:' };
      sheet[`B${row + 1}`] = { t: 's', v: totalPayables?.textContent };

      const invDate = element?.querySelector(
        '#invDate'
      ) as HTMLInputElement | null;
      const invNumber = element?.querySelector(
        '#invNumber'
      ) as HTMLInputElement | null;

      if (invDate?.value) {
        sheet[`A${row + 2}`] = { t: 's', v: 'Invoice Date:' };
        sheet[`B${row + 2}`] = { t: 's', v: invDate?.value };
        sheet[`A${row + 3}`] = { t: 's', v: 'Next Invoice Number:' };
        sheet[`B${row + 3}`] = { t: 's', v: invNumber?.value };
      }

      paymentWorksheetBeforeDownload(sheet);
    } else if (filters.reportType === 'subcontractPayments') {
      subcontractStatusBeforeDownload(sheet);
    }
  };

  return (
    <ReportButton
      elementToPrintRef={elementRef}
      fileName="Subcontract_Report"
      pdfStyles={BASE_PDF_STYLES}
      excelPreprocess={excelElementPreProcess}
      excelBeforeDownload={excelBeforeDownload}
      tableOriginCell="A17"
      widthElementID={
        idPerSCReportType[filters.reportType as keyof typeof idPerSCReportType]
      }
    />
  );
};

export default SCReport;
