import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCompanyContext } from '../../../context/CompanyContext';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { fetchPREmployeeList } from '../../../services/PayrollService';
import EmployeeTable from './EmployeeTable';

const EmployeeTableContainer = React.forwardRef<HTMLDivElement>(
  (props, ref) => {
    const { selectedCompany } = useCompanyContext();

    const { data, isFetching, isError } = useQuery({
      queryKey: ['getPREmployees', selectedCompany],
      queryFn: ({ signal }) => {
        return fetchPREmployeeList(
          selectedCompany!.id,

          signal
        );
      },
      refetchOnWindowFocus: false,
      enabled: !!selectedCompany,
    });

    if (isFetching || !selectedCompany)
      return (
        <div className="text-center mx-auto mt-3">
          <ProgressSpinner />
        </div>
      );

    if (isError)
      return (
        <ErrorMessage
          content={'Failed to obtain data! Please try again later.'}
        />
      );

    return <EmployeeTable employees={data} />;
  }
);

export default EmployeeTableContainer;
