export const jsonsComparison = (
  json1: Record<string, unknown>,
  json2: Record<string, unknown>
) => {
  if (Object.keys(json1).length !== Object.keys(json2).length) {
    return false;
  }

  let result = true;
  for (const i in json1) {
    const isArray = Array.isArray(json1[i]) && Array.isArray(json2[i]);

    if (isArray) {
      result =
        result && arrayComparison(json1[i] as unknown[], json2[i] as unknown[]);
    } else if (json1[i] !== json2[i]) {
      result = false;
    }
  }

  return result;
};

export const arrayComparison = (array1: unknown[], array2: unknown[]) => {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }

  return true;
};
