import { useMutation } from '@tanstack/react-query';
import { downloadFile } from '../../../../utils/fileUtil';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCompanyContext } from '../../../context/CompanyContext';
import {
  fetchAttachmentFile,
  fetchAttachmentFiles,
} from '../../../services/AttachmentService';
import { fetchSCUnpaidInvoices } from '../../../services/SubcontractsService';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { useRef } from 'react';
import { Toast } from 'primereact/toast';
import WrapperButton from '../../../components/buttons/WrapperButton';

type SCPayableAttachmentsProps = {
  label: string;
  sl: string;
};

const SCPayableAttachments = ({ label, sl }: SCPayableAttachmentsProps) => {
  const { selectedCompany } = useCompanyContext();
  const toastError = useRef<Toast>(null);

  const activateError = (message: string) => {
    showToast('error', toastError, 'Download Error', message, 3000);
  };

  const files = useMutation({
    mutationFn: (attachmentIdList: number[]) => {
      return fetchAttachmentFiles(attachmentIdList, sl);
    },
    onSuccess: (data) => {
      downloadFile(data);
    },
    onError: () => {
      activateError(
        'An error occurred while downloading the files! Try again later'
      );
    },
  });

  const file = useMutation({
    mutationFn: fetchAttachmentFile,
    onSuccess: (response) => {
      downloadFile(response);
    },
    onError: (error) => {
      activateError(
        'An error occurred while downloading the file! Try again later'
      );
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: fetchSCUnpaidInvoices,
    onSuccess: (data) => {
      if (data.length > 1) {
        const attachmentIDList = data.map(
          (element: { AttachmentID: number }) => element.AttachmentID
        );
        files.mutate(attachmentIDList);
      } else {
        const id = data[0]?.AttachmentID;
        file.mutate(id);
      }
    },
    onError: () => {
      activateError(
        'An error occurred while fetching the files! Try again later'
      );
    },
  });

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    mutate({ companyID: selectedCompany!.id, subcontract: sl });
  };

  const loading = isLoading || file.isLoading || files.isLoading;

  return (
    <div className="w-full">
      <ErrorToast toastRef={toastError} />
      {loading && <ProgressSpinner className="w-1rem h-auto mx-auto flex" />}
      {!loading && (
        <WrapperButton className="totalColor printColor" onClick={onClick}>
          {label}
        </WrapperButton>
      )}
    </div>
  );
};

export default SCPayableAttachments;
