import { Tooltip } from 'primereact/tooltip';

type TooltipContentProps = {
  id: string;
  label: string;
  content: string;
  bold?: boolean;
};

const TooltipContent = ({ label, content, id, bold }: TooltipContentProps) => {
  return (
    <div>
      <div
        className={`text-center custom-target-icon ${bold && 'font-bold'}`}
        data-pr-position="top"
        data-pr-tooltip={content}
        id={`T${id}`}
      >
        {label}
        <Tooltip target={`#T${id}`} />
      </div>
    </div>
  );
};

export default TooltipContent;
