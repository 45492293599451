import axios from 'axios';
import { auth } from '../../firebase';
import { CompanySource } from '../Interfaces/User.interfaces';

const apiUrl = process.env['NX_API_URL'];

export type fetchCashManagementArgs = {
  source: CompanySource;
  accountId?: string;
  info?: string;
  dates?: Date[];
  companyID?: string;
};

export const fetchCashManagement = async (
  { source, accountId, info, dates, companyID }: fetchCashManagementArgs,
  signal?: AbortSignal
) => {
  let dateStart = null;
  let dateEnd = null;
  if (dates) {
    dateEnd = dates[1] || dates[0];
    dateStart = dates[0];
  }

  const response = await axios.get(`${apiUrl}/api/cashManagement`, {
    signal,
    params: {
      source,
      accountId: accountId,
      info: info ?? null,
      dateStart: dateStart,
      dateEnd: dateEnd,
      companyID,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  return response.data;
};

export const fetchCashManagementHistorcalData = async (
  { source, accountId, info, dates, companyID }: fetchCashManagementArgs,
  signal?: AbortSignal
) => {
  let dateStart = null;
  let dateEnd = null;
  if (dates) {
    dateEnd = dates[1] || dates[0];
    dateStart = dates[0];
  }

  const response = await axios.get(
    `${apiUrl}/api/cashManagement/transactions`,
    {
      signal,
      params: {
        source,
        accountId: accountId,
        info: info ?? null,
        dateStart: dateStart,
        dateEnd: dateEnd,
        companyID,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  return response.data;
};

export const fetchCashManagementAccounts = async (
  source: CompanySource,
  companyID: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/cashManagement/accounts`, {
    signal,
    params: { source, companyID },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  return response.data;
};

export const fetchCashManagementAccountsResume = async (
  source: CompanySource,
  companyID: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/cashManagement/accountsResume`,
    {
      signal,
      params: {
        source,
        companyID,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  return response.data;
};
