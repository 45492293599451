import React, { useEffect, useRef } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { Toast } from 'primereact/toast';
import useFetchAttachments from '../../../hooks/useFetchAttachments';
import WrapperButton from '../../../components/buttons/WrapperButton';

type APDrilldownPreviewAttachmentProps = {
  uniqueAttchID?: string;
  zipFilename: string;
};

const APDrilldownPreviewAttachment = ({
  uniqueAttchID,
  zipFilename,
}: APDrilldownPreviewAttachmentProps) => {
  const toastBackError = useRef<Toast>(null);
  const { fetchAttachments, loading, error } = useFetchAttachments({
    uniqueAttchID: uniqueAttchID ?? '',
    zipFilename,
  });

  const onLinkClick = () => {
    if (uniqueAttchID) {
      fetchAttachments();
    }
  };

  useEffect(() => {
    if (error) {
      showToast('error', toastBackError, 'Download Error', error, 3000);
    }
  }, [error]);

  if (!uniqueAttchID) {
    return <ErrorMessage severity="warn" content="No attachments available!" />;
  }

  return (
    <div>
      {!loading && (
        <WrapperButton onClick={onLinkClick} className="text-23px totalColor">
          View Invoice
        </WrapperButton>
      )}
      {loading && <ProgressSpinner className="h-2rem" />}
      <ErrorToast toastRef={toastBackError} />
    </div>
  );
};

export default APDrilldownPreviewAttachment;
