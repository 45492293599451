import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { useCompanyContext } from '../../../context/CompanyContext';
import GeneralInfoForm from '../../../features/acForms/PoEntry/FormElements/GeneralInfoForm';
import PieceWorkForm from '../../../features/acForms/PoEntry/FormElements/PieceWorkForm';
import POEntrySucess from '../../../features/acForms/PoEntry/SuccessElements/POEntrySucess';
import {
  POArgs,
  POForm,
  POItem,
  POPieceWork,
  SLArgs,
  SLForm,
} from '../../../Interfaces/Accounting/ACForms.interfaces';
import { postNewPO, postNewSL } from '../../../services/ACFormsService';
import { v4 as uuidv4 } from 'uuid';
import POFile from '../../../features/acForms/PoEntry/FormElements/POFile';
import GeneralInfo from '../../../features/acForms/SubcontractEntry/GeneralInfo';
import SLSteps, {
  checkBeforeStep,
} from '../../../features/acForms/SubcontractEntry/SLSteps';
import SLFile from '../../../features/acForms/SubcontractEntry/SLFile';
import SLItems from '../../../features/acForms/SubcontractEntry/SLItemsForm';
import SLEntrySuccess from '../../../features/acForms/SubcontractEntry/Success/SLEntrySuccess';

const SubcontractEntry = () => {
  const { selectedCompany } = useCompanyContext();
  const [activeIndex, setActiveIndex] = useState(0);
  const toast = useRef<Toast>(null);
  const formMethods = useForm<SLForm>({
    defaultValues: {
      upType: 'file',
      slItems: [{ line: 1 }],
    },
  });
  const { handleSubmit, trigger, watch, resetField } = formMethods;
  const upType = watch('upType');

  const changeIndex = (number: number) => {
    setActiveIndex(number);
  };

  const returnStep = () => {
    setActiveIndex((index) => index - 1);
  };

  const checkForm = async (index: number) => {
    const shouldChange = await checkBeforeStep(index, trigger);

    if (shouldChange) {
      setActiveIndex(index + 1);
    }
  };

  const { mutate, isLoading, isSuccess, reset, data } = useMutation({
    mutationFn: (data: SLArgs) => {
      return postNewSL(selectedCompany!.id, data);
    },
    onError: (error: AxiosError) => {
      const response = error.response;
      const data = response?.data as { message: string };

      showToast(
        'error',
        toast,
        'Create SL Error',
        data?.message || 'An error ocurred! please try again later',
        3000
      );
    },
  });

  const onSubmit = (data: SLForm) => {
    const { upType, ...otherData } = data;
    const { sl, job, vendor, description, attachment } = otherData;
    const payload = {
      companyID: selectedCompany!.id,
      job,
      vendor,
      description,
      sl,
      attachment: attachment ?? null,
    };

    if (upType === 'file') {
      mutate({
        ...payload,
        subFile: otherData.subFile,
      });
    } else {
      const { slItems } = otherData;

      const items = slItems?.map((item) => {
        return { ...item, retainage: item.retainage! / 100 };
      });

      mutate({ ...payload, slItems: items });
    }
  };

  useEffect(() => {
    resetField('subFile');
    resetField('slItems');
  }, [upType, resetField]);

  useEffect(() => {
    setActiveIndex(0);
    reset();
    formMethods.reset();
  }, [selectedCompany, reset, formMethods]);

  return (
    <div className="flex flex-column justify-content-center gap-5 pt-4">
      <div className="text-35px font-bold text-center mb-2 relative">
        New Subcontract Setup
      </div>
      <FormProvider {...formMethods}>
        {!isSuccess && (
          <React.Fragment>
            <div className="w-full max-w-900px mx-auto mb-2">
              <SLSteps index={activeIndex} changeIndex={changeIndex} />
            </div>
            <form
              className={`${
                upType === 'manual' && activeIndex === 1
                  ? 'max-w-1200px'
                  : 'max-w-700px'
              }  w-full mx-auto flex flex-column gap-5`}
            >
              <div className={`${activeIndex !== 0 && 'hidden'}`}>
                <GeneralInfo checkForm={checkForm} />
              </div>
              {upType === 'manual' && (
                <div className={`${activeIndex !== 1 && 'hidden'}`}>
                  <SLItems
                    returnStep={returnStep}
                    isLoading={isLoading}
                    onSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              )}
              {upType === 'file' && (
                <div className={`${activeIndex !== 1 && 'hidden'}`}>
                  <SLFile
                    returnStep={returnStep}
                    isLoading={isLoading}
                    onSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              )}
            </form>
          </React.Fragment>
        )}
        {isSuccess && <SLEntrySuccess subcontract={data} />}
      </FormProvider>
      <ErrorToast toastRef={toast} />
    </div>
  );
};

export default SubcontractEntry;
