type ExpandIconProps = {
  expanded: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const ExpandIcon = ({ expanded, onOpen, onClose }: ExpandIconProps) => {
  if (expanded) {
    return (
      <i className="pi pi-minus text-17px cursor-pointer" onClick={onClose} />
    );
  }

  return <i className="pi pi-plus text-17px cursor-pointer" onClick={onOpen} />;
};

export default ExpandIcon;
