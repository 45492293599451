import { ColumnEditorOptions } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import React, { useState } from 'react';

type ColumnTextEditorProps = ColumnEditorOptions & {
  id: string;
  onFocus?: () => void;
};

const ColumnTextEditor = ({
  id,
  editorCallback,
  value,
  onFocus,
}: ColumnTextEditorProps) => {
  const [prevValue, setPrevValue] = useState('');

  return (
    <React.Fragment>
      <InputText
        type="text"
        id={id}
        keyfilter={/^(-?(\d+(\.\d{0,2})?|\.\d{0,2})?%?|-%|%)$/}
        validateOnly={true}
        onFocus={onFocus}
        onInput={(event, validatePattern) => {
          const target = event.currentTarget;
          const hasLength = target.value.length > 0;

          if (validatePattern && hasLength) {
            setPrevValue(target.value);
            return;
          }

          if (hasLength) {
            target.value = prevValue;
          }
        }}
        className="w-full"
        value={value ?? ''}
        onChange={(e) => {
          try {
            if (editorCallback) {
              editorCallback(e.target.value);
            }
          } catch (e) {
            console.log(e);
          }
        }}
      />
      <Tooltip
        target={`#${id}`}
        content={`Enter amount or percentage by using '%' at the end`}
        position="top"
        event="focus"
      />
    </React.Fragment>
  );
};

export default ColumnTextEditor;
