import React, { useEffect, useState } from 'react';
import DropdownStyled from '../../components/inputs/StyledInputs/DropdownStyled';
import { Controller, useFormContext } from 'react-hook-form';
import { useCompanyContext } from '../../context/CompanyContext';
import { useQuery } from '@tanstack/react-query';
import {
  fecthSCJobs,
  fecthSCVendors,
  fecthSubcontracts,
  fetchSCPaymentDrafts,
} from '../../services/SubcontractsService';
import { APVendor } from '../../Interfaces/Accounting/AccountsPayables.interfaces';
import CalendarStyled from '../../components/inputs/StyledInputs/CalendarStyled';
import { InputText } from 'primereact/inputtext';
import MultipleSelectStyled from '../../components/inputs/StyledInputs/MultipleSelectStyled';
import { MODULES } from '../../../utils/rolesConst';
import useProtectedReportOptions from '../../hooks/useProtectedReportOptions';
import { JCJobSummary } from '../../Interfaces/Accounting/JobsContracts.interface';
import FormControlledDropdown from '../../components/inputs/FormInputs/FormControlledDropdown';
import { formatUTCDate } from 'apps/tmr-frontend/src/utils/formatUtils';
import { SCBillingDraft } from '../../Interfaces/Accounting/Subcontracts.interfaces';
import { useRolesAccessContext } from '../../context/RolesAccessContext';

export const ITEMS_STATES = [
  { label: 'Show 100% Billed', value: 'show' },
  { label: 'Hide 100% Billed', value: 'hide' },
];

export type SubcontractsFilters = {
  reportType: string;
  jobs?: string[];
  job?: string;
  subcontractor?: string;
  subcontract?: string;
  dates?: Date[];
  info?: string;
  submittedID?: string;
  itemsState?: string;
};

const SCFilters = () => {
  const { rolesAcess } = useRolesAccessContext();
  const canApprove = rolesAcess?.find(
    (access) =>
      access.report === 'subcontract_payment_worksheet' && access.shouldApprove
  );
  const { control, resetField, watch } = useFormContext();
  const { selectedCompany } = useCompanyContext();
  const reportType = watch('reportType');
  const selectedJob = watch('job');
  const selectedSubcontract = watch('subcontract');

  const { data, isFetching, isError } = useQuery({
    queryKey: ['getSubcontractJobs', selectedCompany],
    queryFn: ({ signal }) => fecthSCJobs(selectedCompany!.id, [1], signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
    staleTime: 1000 * 60 * 10,
  });

  const vendorRequest = useQuery({
    queryKey: ['getSubcontractVendors', selectedCompany],
    queryFn: ({ signal }) =>
      fecthSCVendors({ companyID: selectedCompany!.id }, signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
    staleTime: 1000 * 60 * 10,
  });

  const subcontractsRequest = useQuery({
    queryKey: ['getSubcontracts', selectedCompany, selectedJob],
    queryFn: ({ signal }) =>
      fecthSubcontracts(selectedCompany!.id, selectedJob, signal),
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && selectedJob),
    staleTime: 1000 * 60 * 10,
  });

  const submittedSCBillings = useQuery({
    queryKey: [
      'getSubmittedSCBillings',
      selectedCompany,
      selectedJob,
      selectedSubcontract,
    ],
    queryFn: ({ signal }) =>
      fetchSCPaymentDrafts(
        {
          companyID: selectedCompany!.id,
          job: selectedJob,
          subcontract: selectedSubcontract,
          isSubmitted: true,
          isApproved: false,
        },
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: !!(
      selectedCompany &&
      selectedSubcontract &&
      canApprove &&
      reportType === 'paymentWorksheet'
    ),
    staleTime: 1000 * 60 * 10,
  });

  const onJobChange = () => {
    resetField('subcontract', { defaultValue: null });
    resetField('itemsState', { defaultValue: 'show' });
  };

  const onSubcontractChange = () => {
    resetField('itemsState', { defaultValue: 'show' });
    resetField('submittedID', { defaultValue: null });
  };

  return (
    <>
      {reportType === 'paymentSummary' && (
        <React.Fragment>
          <div className="col">
            <Controller
              control={control}
              name="jobs"
              render={({ field: { value, ...otherFields } }) => (
                <MultipleSelectStyled
                  isDisabled={isError}
                  resetFilterOnHide={true}
                  showSelectAll={false}
                  filter={true}
                  options={data}
                  labelField="Job"
                  valueField="Job"
                  isLoading={isFetching}
                  placeholder={isError ? 'Failed to load jobs!' : 'Select Job'}
                  value={isFetching || typeof value === 'string' ? null : value}
                  clearable={true}
                  {...otherFields}
                />
              )}
            />
          </div>
          <div className="col">
            <Controller
              control={control}
              name="jobs"
              render={({ field: { value, ...otherFields } }) => (
                <MultipleSelectStyled
                  isDisabled={isError}
                  resetFilterOnHide={true}
                  showSelectAll={false}
                  filter={true}
                  options={data}
                  labelField="Description"
                  valueField="Job"
                  isLoading={isFetching}
                  placeholder={
                    isError ? 'Failed to load job names!' : 'Select Job Name'
                  }
                  value={isFetching || typeof value === 'string' ? null : value}
                  clearable={true}
                  {...otherFields}
                />
              )}
            />
          </div>
        </React.Fragment>
      )}
      {(reportType === 'paymentWorksheet' ||
        reportType === 'subcontractPayments') && (
        <React.Fragment>
          <div className="col">
            <FormControlledDropdown
              formID="job"
              options={data}
              labelField="Job"
              valueField="Job"
              placeholder={isError ? 'Failed to load jobs!' : 'Select Job'}
              clearable={true}
              isDisabled={isError}
              isLoading={isFetching}
              filter={true}
              resetFilterOnHide={true}
              additionalChange={onJobChange}
            />
          </div>
          <div className="col">
            <FormControlledDropdown
              formID="job"
              options={data}
              labelField="Description"
              valueField="Job"
              placeholder={isError ? 'Failed to job names!' : 'Select Job Name'}
              clearable={true}
              isDisabled={isError}
              isLoading={isFetching}
              filter={true}
              resetFilterOnHide={true}
              additionalChange={onJobChange}
            />
          </div>
        </React.Fragment>
      )}
      {reportType === 'paymentSummary' && (
        <div className="col">
          <Controller
            control={control}
            name="subcontractor"
            render={({ field }) => (
              <DropdownStyled
                isDisabled={vendorRequest.isError}
                options={vendorRequest.data}
                labelField="Name"
                valueField="Vendor"
                isLoading={vendorRequest.isFetching}
                placeholder={
                  isError
                    ? 'Failed to load subcontractors!'
                    : 'Select Subcontractor'
                }
                clearable={true}
                filter={true}
                resetFilterOnHide={true}
                {...field}
              />
            )}
          />
        </div>
      )}
      {reportType === 'paymentSummary' && (
        <div className="col">
          <Controller
            control={control}
            defaultValue=""
            name="dates"
            render={({ field }) => (
              <CalendarStyled
                selectionMode="range"
                placeholder="Select Date Range"
                readOnlyInput
                showButtonBar
                {...field}
              />
            )}
          />
        </div>
      )}
      {(reportType === 'paymentWorksheet' ||
        reportType === 'subcontractPayments') && (
        <div className="col">
          <FormControlledDropdown
            formID="subcontract"
            options={subcontractsRequest.data}
            labelField="SL"
            valueField="SL"
            placeholder={
              isError ? 'Failed to load subcontracts!' : 'Select Subcontract'
            }
            clearable={true}
            isDisabled={subcontractsRequest.isError || !selectedJob}
            isLoading={subcontractsRequest.isFetching}
            filter={true}
            resetFilterOnHide={true}
            additionalChange={onSubcontractChange}
          />
        </div>
      )}
      {reportType === 'paymentWorksheet' && (
        <div className="col">
          <FormControlledDropdown
            formID="itemsState"
            defaultValue={'show'}
            options={ITEMS_STATES}
            labelField="label"
            valueField="value"
            placeholder={'Select billed items state'}
            isDisabled={!selectedSubcontract}
            clearable={false}
          />
        </div>
      )}
      {reportType === 'paymentWorksheet' && canApprove && (
        <div className="col">
          <FormControlledDropdown
            formID="submittedID"
            options={
              submittedSCBillings.data?.map((draft: SCBillingDraft) => {
                return {
                  ...draft,
                  label: `${formatUTCDate(draft.updatedAt || '')} - ${
                    draft.userName
                  }`,
                };
              }) || []
            }
            labelField="label"
            valueField="id"
            placeholder={
              submittedSCBillings.isError
                ? 'Failed to load submitted drafts!'
                : 'Approve Submitted Drafts'
            }
            isDisabled={
              submittedSCBillings.isError || !submittedSCBillings.data?.length
            }
            isLoading={submittedSCBillings.isFetching}
            clearable={true}
            filter={true}
            resetFilterOnHide={true}
          />
        </div>
      )}
      <div className="col">
        <Controller
          control={control}
          defaultValue=""
          name="info"
          render={({ field }) => (
            <span className="p-input-icon-right">
              <InputText
                type="text"
                placeholder="Search"
                className="fieldBorder w-full text-standard blackText"
                {...field}
              />
              <i className="pi pi-search" />
            </span>
          )}
        />
      </div>
    </>
  );
};

export default SCFilters;
