import { formatCurrency } from '../../../../utils/formatUtils';
import FormInfo from '../../../components/messages/FormInfo';
import { COPhase } from '../../../Interfaces/Accounting/ACForms.interfaces';

type COPhaseSummaryProps = {
  phase: COPhase;
};

const COPhaseSummary = ({ phase }: COPhaseSummaryProps) => {
  const details = phase.details?.filter((detail) => !!detail.cost) ?? [];

  return (
    <div className="grid mx-0 mb-2 mt-1">
      <div className="col-12 sm:col-6 py-0">
        <FormInfo label="Phase Code" content={phase.code} />
      </div>
      {phase.description && (
        <div className="col-12 sm:col-6 py-0">
          <FormInfo label="Phase Description" content={phase.description} />
        </div>
      )}
      {details.length > 0 && (
        <div className="col-12 pt-1">
          <FormInfo label="Phase Details" content="" />
          <div className="flex flex-column gap-3">
            {details.map((detail) => {
              return (
                <div className="grid avoid-break mx-1" key={detail.ctLabel}>
                  <div className="col-6 pb-1">
                    <FormInfo label="Cost Type" content={detail.ctLabel} />
                  </div>
                  <div className="col-6 pb-1">
                    <FormInfo label="U/M" content={detail.umLabel} />
                  </div>
                  <div className="col-6 pb-1">
                    <FormInfo
                      label="Estimate Cost"
                      content={formatCurrency(detail.cost)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default COPhaseSummary;
