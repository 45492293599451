import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '../../../components/inputs/LoadingButton';
import ConfirmationDialog from '../../../components/messages/ConfirmationDialog';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { useCompanyContext } from '../../../context/CompanyContext';
import COInfo from '../../../features/acForms/ChangeOrders/COInfo';
import COSuccess from '../../../features/acForms/ChangeOrders/COSuccess';
import { COFormFields } from '../../../Interfaces/Accounting/ACForms.interfaces';
import { postChangeOrders } from '../../../services/ACFormsService';

const ChangeOrders = () => {
  const { selectedCompany } = useCompanyContext();
  const formMethods = useForm<COFormFields>();
  const { handleSubmit } = formMethods;
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);

  const formatPhases = (data: COFormFields) => {
    const { phases, ...otherData } = data;
    const newPhases =
      phases
        ?.filter((phase) => !!phase.code)
        ?.map((phase) => {
          const details = phase.details
            ?.filter((detail) => detail.cost)
            ?.map((detail) => {
              return { costType: detail.costType, estimate: detail.cost };
            });

          return {
            details,
            code: phase.code ?? '',
            description: phase.description ?? undefined,
          };
        }) ?? [];

    const payload = {
      ...otherData,
      phases: newPhases.length > 0 ? newPhases : undefined,
    };

    return payload;
  };

  const { mutate, isLoading, isSuccess, reset } = useMutation({
    mutationFn: (data: COFormFields) => {
      const { file, ...otherData } = formatPhases(data);

      const payload = {
        ...otherData,
        companyID: selectedCompany,
      };
      return postChangeOrders(selectedCompany!, payload, file);
    },
    onError: (error: AxiosError) => {
      const response = error.response;
      const data = response?.data as { message: string };
      showToast(
        'error',
        toast,
        'Change Orders Upload Error',
        data?.message || 'An error ocurred! please try again later',
        10000
      );
    },
  });

  const onSubmit = (data: COFormFields) => {
    mutate(data);
  };

  useEffect(() => {
    reset();
    formMethods.reset();
  }, [selectedCompany, reset]);

  return (
    <div className="flex flex-column justify-content-center gap-5 pt-4">
      <div className="text-35px font-bold text-center mb-2 relative">
        New Change Orders
      </div>
      <FormProvider {...formMethods}>
        {!isSuccess && (
          <form className="max-w-600px w-full mx-auto flex flex-column gap-5">
            <COInfo />
            <div className="flex flex-wrap flex-row-reverse gap-2 mb-3">
              <ConfirmationDialog
                Button={
                  <LoadingButton
                    isLoading={isLoading}
                    label="Send"
                    className="w-15rem bluwaiBlue border-0 flex m-auto py-3"
                    fontSize="text-22px"
                    type="button"
                  />
                }
                onConfirm={handleSubmit(onSubmit)}
                message="Are you sure you want to submit the change orders?"
              />
              <Button
                onClick={() => {
                  navigate('/accounting-dashboard/new');
                }}
                className="w-15rem bluwaiRed border-0 flex m-auto py-3"
                type="button"
              >
                <span className="mx-auto text-22px text-white">Cancel</span>
              </Button>
            </div>
          </form>
        )}
        {isSuccess && <COSuccess />}
      </FormProvider>
      <ErrorToast toastRef={toast} />
    </div>
  );
};

export default ChangeOrders;
