import React, { useCallback } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import {
  formatCurrency,
  formatPercentage,
} from '../../../../utils/formatUtils';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import { ContractMonthlyBilling } from '../../../Interfaces/Accounting/ContractBillings.interface';

type MonthlyBillingTableProps = {
  billings: ContractMonthlyBilling[];
  months: string[];
};

const MonthlyBillingTable = ({
  billings,
  months,
}: MonthlyBillingTableProps) => {
  const calcHeight = (rows: number) => {
    const headerHeight = 32;
    const footerHeight = 54;
    const rowHeight = 31;
    return headerHeight + footerHeight + rows * rowHeight + 3;
  };

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  const monthColumns = months.map((month) => (
    <Column
      key={month}
      field={month}
      header={month}
      body={(cb) => (cb[month] ? formatCurrency(cb[month]) : '')}
      style={{ minWidth: '140px' }}
      headerClassName="tableHeader font-normal justify-content-center text-center"
      className="justify-content-end text-standard blackText tableCell"
      footer={(e) => {
        const records = e.props.value;
        let total = 0;
        records.forEach((record: ContractMonthlyBilling) => {
          const value = record[month] as number;
          const amount = value ?? 0;
          total += amount;
        });

        return total ? formatCurrency(total) : '';
      }}
      footerClassName="tableFooter block text-right border-top-2 mt-4"
      sortable
    />
  ));

  let contractAmount = 0;
  let balance = 0;
  let billed = 0;
  let yearBilled = 0;
  billings.forEach((bill) => {
    contractAmount += bill.Amount ?? 0;
    balance += bill.BalanceToBill ?? 0;
    billed += bill.BilledCTD ?? 0;
    yearBilled += bill.BillingCurrentYear ?? 0;
  });

  return (
    <div className="pdfDivFitContent">
      <Table
        id="cb-monthly-billing"
        data={billings}
        className={`mx-3 dashboardOptionShadow`}
        calcHeight={useCallback(calcHeight, [])}
        onRowMouseEnter={useCallback(onRowHover, [])}
        hideColumns={true}
      >
        {[
          <Column
            key={'Contract'}
            field="Contract"
            header="Contract #"
            style={{ minWidth: '140px', maxWidth: '140px' }}
            headerClassName="tableHeader font-normal"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />,
          <Column
            key={'ContractName'}
            field="ContractName"
            header="Contract Name"
            body={(cb) => {
              return <div className="scroll-text">{cb.ContractName}</div>;
            }}
            style={{ minWidth: '210px', maxWidth: '210px' }}
            headerClassName="tableHeader font-normal justify-content-center"
            className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
            footerClassName="tableFooter"
            sortable
          />,
          <Column
            key="Customer"
            field="Customer"
            header="Customer"
            body={(cb) => {
              return <div className="scroll-text">{cb.Customer}</div>;
            }}
            style={{ minWidth: '210px', maxWidth: '210px' }}
            headerClassName="tableHeader font-normal justify-content-center"
            className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
            footerClassName="tableFooter"
            sortable
          />,
          <Column
            key="FieldPM"
            field="FieldPM"
            header="Field PM"
            body={(cb) => {
              return <div className="scroll-text">{cb.FieldPM}</div>;
            }}
            style={{ minWidth: '140px', maxWidth: '140px' }}
            headerClassName="tableHeader font-normal justify-content-center"
            className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
            footerClassName="overflow-x-visible tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
            footer="Total"
            sortable
          />,
          <Column
            key="Amount"
            field="Amount"
            header="Contract Amount"
            body={(cb) => (cb.Amount ? formatCurrency(cb.Amount) : '')}
            style={{ minWidth: '160px' }}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(contractAmount)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />,
          <Column
            key="BalanceToBill"
            field="BalanceToBill"
            header="Balance To Bill"
            body={(cb) =>
              cb.BalanceToBill ? formatCurrency(cb.BalanceToBill) : ''
            }
            style={{ minWidth: '150px' }}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(balance)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />,
          <Column
            key="BilledRatio"
            field="BilledRatio"
            header="Billed %"
            body={(cb) =>
              cb.BilledRatio ? formatPercentage(cb.BilledRatio * 100) : ''
            }
            style={{ minWidth: '100px' }}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatPercentage((billed / contractAmount) * 100)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />,
          <Column
            key="BilledCTD"
            field="BilledCTD"
            header="Billed CTD"
            body={(cb) => (cb.BilledCTD ? formatCurrency(cb.BilledCTD) : '')}
            style={{ minWidth: '160px' }}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(billed)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />,
          <Column
            key="BillingCurrentYear"
            field="BillingCurrentYear"
            header="Billing Current Year"
            body={(cb) =>
              cb.BillingCurrentYear ? formatCurrency(cb.BillingCurrentYear) : ''
            }
            style={{ minWidth: '180px' }}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(yearBilled)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />,
          ...monthColumns,
        ]}
      </Table>
    </div>
  );
};

const transactionsAreEqual = (
  prevTransactions: Readonly<MonthlyBillingTableProps>,
  nextTransactions: Readonly<MonthlyBillingTableProps>
) => {
  return (
    prevTransactions.billings === nextTransactions.billings &&
    prevTransactions.months === nextTransactions.months
  );
};

export default React.memo(MonthlyBillingTable, transactionsAreEqual);
