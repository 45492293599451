import { useQuery } from '@tanstack/react-query';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef } from 'react';
import WrapperButton from '../../../components/buttons/WrapperButton';
import ConfirmationDialog from '../../../components/messages/ConfirmationDialog';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { useCompanyContext } from '../../../context/CompanyContext';
import { TimeSheetArgs } from '../../../Interfaces/Accounting/ACForms.interfaces';
import { ApiError } from '../../../Interfaces/Generic.interfaces';
import { fetchPRTimesheetTemplate } from '../../../services/PayrollService';

type TimesheetTemplateProps = {
  storeTemplate?: (template?: TimeSheetArgs) => void;
  updateLoading?: (value: boolean) => void;
};

const TimesheetTemplate = ({
  storeTemplate,
  updateLoading,
}: TimesheetTemplateProps) => {
  const { selectedCompany } = useCompanyContext();
  const toast = useRef<Toast>(null);

  const { refetch } = useQuery({
    queryKey: ['getTimesheetTemplate', selectedCompany],
    queryFn: ({ signal }) => {
      if (storeTemplate) {
        storeTemplate(undefined);
      }

      if (updateLoading) {
        updateLoading(true);
      }

      return fetchPRTimesheetTemplate(selectedCompany!.id, signal);
    },
    onSuccess: (data) => {
      if (storeTemplate) {
        storeTemplate([...data.employees]);
      }

      if (updateLoading) {
        updateLoading(false);
      }
    },
    onError: (error: ApiError) => {
      if (updateLoading) {
        updateLoading(false);
      }

      showToast(
        'error',
        toast,
        `Last Timesheet as Template`,
        error?.response?.data?.message ||
          `A timesheet template couldn't be found!`,
        3000
      );
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      <ConfirmationDialog
        tagKey="template-conf"
        Button={
          <WrapperButton type="button">
            <div className="text-18px font-bold cursor-pointer totalColor hover:underline">
              Use Last Timesheet as Template
            </div>
          </WrapperButton>
        }
        onConfirm={() => refetch()}
        message={
          'Are you sure you want to overwrite the current content with the template data?'
        }
      />
    </React.Fragment>
  );
};

export default TimesheetTemplate;
