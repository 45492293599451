import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCompanyContext } from '../../../context/CompanyContext';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { fecthJCProjectedCost } from '../../../services/JobsContractsService';
import { useFormContext } from 'react-hook-form';
import { JobContractFilters } from '../JCFilters';
import JCProjectedCostTable from './JCProjectedCostTable';
import { useRolesAccessContext } from '../../../context/RolesAccessContext';
import JCProjectedCostDate from './JCProjectedCostDate';

const JCProjectedCostTableContainer = React.forwardRef<HTMLDivElement>(
  (props, ref) => {
    const { selectedCompany } = useCompanyContext();
    const { rolesAcess, rolePhases } = useRolesAccessContext();
    const { watch } = useFormContext<JobContractFilters>();
    const filters = watch();
    const isEnabled = selectedCompany && filters.job;
    const access = rolesAcess?.find(
      (acess) => acess.report === 'projected_jc_report'
    );

    const { data, isFetching, isError } = useQuery({
      queryKey: [
        'getJCProjectedCost',
        selectedCompany,
        filters.job,
        filters.submittedID,
        filters.extraDetails,
      ],
      queryFn: ({ signal }) => {
        return fecthJCProjectedCost(
          selectedCompany!.id,
          filters.job ?? '',
          !!access?.editable,
          filters.submittedID,
          filters.extraDetails === 'hide' ? false : true,
          signal
        );
      },
      refetchOnWindowFocus: false,
      enabled: !!isEnabled,
    });

    if (!isEnabled) {
      return (
        <div className="mt-4">
          <ErrorMessage
            severity="info"
            content={'To start the search please select a Job.'}
          />
        </div>
      );
    }

    if (isFetching)
      return (
        <div className="text-center mx-auto mt-3">
          <ProgressSpinner />
        </div>
      );

    if (isError)
      return (
        <ErrorMessage
          content={'Failed to obtain data! Please try again later.'}
        />
      );

    return (
      <div>
        {data.lastProjectedDate && (
          <JCProjectedCostDate date={new Date(data.lastProjectedDate)} />
        )}
        <JCProjectedCostTable
          costs={data.costs}
          months={data.months}
          draftDefault={data.draft}
          approval={!!filters.submittedID}
          access={access ?? { report: 'projected_jc_report' }}
          hidePhaseDetails={rolePhases}
        />
      </div>
    );
  }
);

export default JCProjectedCostTableContainer;
