import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCompanyContext } from '../../../context/CompanyContext';
import { fetchAccountsReceivablesReceipts } from '../../../services/AccountsReceivablesService';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import ARReceiptTable from './ARReceiptTable';
import { useFormContext } from 'react-hook-form';
import { ARFiltersArgs } from '../ARFilter/ARFilters';
import { checkObligatoryFilters } from '../ARFilter/ARFilterUtils';

const ARReceiptTableContainer = React.forwardRef<HTMLDivElement>(
  (props, ref) => {
    const { selectedCompany } = useCompanyContext();
    const { watch } = useFormContext<ARFiltersArgs>();
    const filters = watch();
    const isEnabled = selectedCompany && checkObligatoryFilters(filters);

    const { data, isFetching, isError } = useQuery({
      queryKey: ['getARReceipts', selectedCompany, filters],
      queryFn: ({ signal }) => {
        const { reportType, ...otherFilters } = filters;
        return fetchAccountsReceivablesReceipts(
          {
            company: selectedCompany!,
            ...otherFilters,
          },
          signal
        );
      },
      refetchOnWindowFocus: false,
      enabled: !!isEnabled,
    });

    if (!isEnabled) {
      return (
        <div className="mt-4">
          <ErrorMessage
            severity="info"
            content={
              'To start the search please select a: Customer, Contract, Job Name, or date.'
            }
          />
        </div>
      );
    }

    if (isFetching)
      return (
        <div className="text-center mx-auto mt-3">
          <ProgressSpinner />
        </div>
      );

    if (isError)
      return (
        <ErrorMessage
          content={'Failed to obtain data! Please try again later.'}
        />
      );

    return <ARReceiptTable arReceipts={data} />;
  }
);

export default ARReceiptTableContainer;
