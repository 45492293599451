import { BASE_PDF_STYLES } from '../../../../utils/reportUtils';
import { useRef } from 'react';
import ReportButton from '../../../components/report/ReportButton';
import { formatCurrency } from '../../../../utils/formatUtils';

type EstimateSuccessMessageProps = {
  phaseFile?: string;
  phases?: number;
  estimatesFile?: string;
  cost?: number;
};

const EstimateSuccessMessage = ({
  phaseFile,
  phases,
  estimatesFile,
  cost,
}: EstimateSuccessMessageProps) => {
  const summaryRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      id="estimates-success"
      ref={summaryRef}
      className="mt-3 mx-5 max-w-800px"
    >
      <div className="text-30px font-bold flex justify-content-between align-items-end mb-5 gap-2">
        Estimated Cost Summary:
        <div className="text-23px cursor-pointer printHide h-fit">
          <ReportButton
            elementToPrintRef={summaryRef}
            fileName="Estimated_Cost_Form"
            pdfStyles={BASE_PDF_STYLES}
            noButton={true}
            hideExcel={true}
            isPdfPortrait={true}
            widthElementID="estimates-success"
            minWidth={800}
          />
        </div>
      </div>
      <div>
        The following files were uploaded successfully:
        <ul>
          {phaseFile && (
            <li className="mb-2">
              Job Phases.- {phaseFile} with {phases} phases.
            </li>
          )}
          {estimatesFile && (
            <li className="mb-2">
              Estimated cost.- {estimatesFile} with a total cost of
              <span className="ml-1">{formatCurrency(cost!)}</span>.
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default EstimateSuccessMessage;
