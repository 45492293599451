import { checkAccess } from 'apps/tmr-frontend/src/utils/roleUtils';
import { useEffect, useState } from 'react';
import { useRolesAccessContext } from '../../../context/RolesAccessContext';
import { FORMS } from '../formConsts';
import Section from './Section';

const SectionList = () => {
  const [sections, setSections] = useState<JSX.Element[]>([]);
  const { rolesAcess } = useRolesAccessContext();

  useEffect(() => {
    const list = [];
    FORMS.forEach((section) => {
      const hasAccess = section.access
        ? checkAccess(rolesAcess ?? [], section.access)
        : true;

      if (hasAccess) {
        list.push(
          <div className="col h-250px" key={section.section}>
            <Section
              sectionName={section.section}
              formList={section.forms || []}
            />
          </div>
        );
      }
    });

    for (let i = 0; i < 5; i++) {
      list.push(
        <div className="col" key={i}>
          <div className="min-w-300px" />
        </div>
      );
    }

    setSections(list);
  }, []);

  return <div className="grid gap-3">{sections}</div>;
};

export default SectionList;
