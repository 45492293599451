import React, { useCallback, useState } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import {
  formatCurrency,
  formatPercentage,
} from '../../../../utils/formatUtils';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import { JCContractResume } from '../../../Interfaces/Accounting/JobsContracts.interface';
import JCBillReceiptsDrilldownContainer from '../JCBillReceiptsDrilldown/JCBillReceiptsDrilldownContainer';
import JCCostDrilldownContainer from '../JCCostDrilldown/JCCostDrilldownContainer';
import CIDrilldownContainer from '../ContractItems/CIDrilldownContainer';
import { FormProvider, useForm } from 'react-hook-form';
import { fetchContractBillingFilters } from '../../contractBillings/CBFilters';
import JCFlagsTooltip from './JCFlagsTooltip';
import { JobContractFilters } from '../JCFilters';
import WrapperButton from '../../../components/buttons/WrapperButton';

type JCResumeProps = {
  contracts: JCContractResume[];
  defaultSize?: number;
  defaultActiveJobs?: boolean;
};

const JCResume = React.forwardRef<HTMLDivElement, JCResumeProps>(
  ({ contracts, defaultSize, defaultActiveJobs }, ref) => {
    const methods = useForm<fetchContractBillingFilters>();
    const JCmethods = useForm<JobContractFilters>();
    const [selectedContract, setSelectedContract] = useState<{
      contract: string;
      contractName: string;
    }>();
    const [selectedDrilldown, setSelectedDrilldown] = useState('');
    const flaggedContracts = contracts.filter(
      (contract) => contract.PJTDProfit < 10 || contract.NetCashFlow < 0
    );

    const onRowHover = (e: DataTableRowMouseEventParams) => {
      const cell = e.originalEvent.target as HTMLElement;
      const row = cell.closest('tr') as HTMLElement;
      animateCellText(row);
    };

    const onDrilldownCellClick = (
      drilldownType: string,
      contract: string,
      contractName: string
    ) => {
      setSelectedDrilldown(drilldownType);

      setSelectedContract({ contract, contractName });
    };

    const hideSelectedDrilldown = () => {
      setSelectedContract(undefined);
      setSelectedDrilldown('');
    };

    return (
      <React.Fragment>
        {selectedDrilldown === 'details' && selectedContract && (
          <FormProvider {...methods}>
            <JCCostDrilldownContainer
              visible={true}
              hideDrilldown={hideSelectedDrilldown}
              costDetail={{
                job: selectedContract.contract,
                jobName: selectedContract.contractName,
              }}
            />
          </FormProvider>
        )}
        {selectedDrilldown === 'b&r' && selectedContract && (
          <FormProvider {...JCmethods}>
            <JCBillReceiptsDrilldownContainer
              visible={true}
              hideDrilldown={hideSelectedDrilldown}
              {...selectedContract}
            />
          </FormProvider>
        )}
        {selectedDrilldown === 'items' && selectedContract && (
          <CIDrilldownContainer
            visible={true}
            hideDrilldown={hideSelectedDrilldown}
            {...selectedContract}
          />
        )}
        <Table
          id="jc-contract-analysis"
          ref={ref}
          data={
            defaultActiveJobs && !flaggedContracts.length
              ? contracts
              : flaggedContracts
          }
          className={`dashboardOptionShadow noFooter w-full`}
          onRowMouseEnter={useCallback(onRowHover, [])}
          fixedDefaultHeight={defaultSize ?? 260}
        >
          {!!flaggedContracts.length && (
            <Column
              body={(jc) => <JCFlagsTooltip contract={jc} />}
              style={{ minWidth: '20px', maxWidth: '20px' }}
              headerClassName="tableHeader font-normal"
              className="justify-content-center text-standard blackText tableCell p-1"
              footerClassName="tableFooter"
            />
          )}
          <Column
            field="Contract"
            header="Contract#"
            style={{ minWidth: '110px', maxWidth: '110px' }}
            headerClassName="tableHeader font-normal"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="ContractName"
            header="Contract Name"
            body={(jb) => {
              return <div className="scroll-text">{jb.ContractName}</div>;
            }}
            style={{ minWidth: '150px', maxWidth: '150px' }}
            headerClassName="tableHeader font-normal justify-content-center"
            className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="ContractAmount"
            header="Contract $"
            body={(jc) => (
              <WrapperButton
                className="totalColor w-full text-right printColor"
                onClick={() =>
                  onDrilldownCellClick('items', jc.Contract, jc.ContractName)
                }
              >
                {formatCurrency(jc.ContractAmount)}
              </WrapperButton>
            )}
            style={{ minWidth: '125px' }}
            headerClassName="tableHeader font-normal text-center justify-content-center"
            className="justify-content-end text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="BilledAmount"
            header="Billed"
            body={(jc) => (
              <WrapperButton
                className="totalColor w-full text-right printColor"
                onClick={() =>
                  onDrilldownCellClick('b&r', jc.Contract, jc.ContractName)
                }
              >
                {formatCurrency(jc.BilledAmount)}
              </WrapperButton>
            )}
            style={{ minWidth: '125px', maxWidth: '125px' }}
            headerClassName="tableHeader font-normal text-center justify-content-center"
            className="justify-content-end text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="CostToDate"
            header="Cost"
            body={(jc) => (
              <WrapperButton
                className="totalColor w-full text-right printColor"
                onClick={() =>
                  onDrilldownCellClick('details', jc.Contract, jc.ContractName)
                }
              >
                {formatCurrency(jc.CostToDate)}
              </WrapperButton>
            )}
            style={{ minWidth: '125px', maxWidth: '125px' }}
            headerClassName="tableHeader font-normal text-center justify-content-center"
            className="justify-content-end text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="PBilled"
            header="%Complete"
            body={(jb) => formatPercentage(jb.PBilled)}
            style={{ minWidth: '120px', maxWidth: '120px' }}
            headerClassName="tableHeader font-normal text-center"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />
        </Table>
      </React.Fragment>
    );
  }
);

const transactionsAreEqual = (
  prevTransactions: Readonly<JCResumeProps>,
  nextTransactions: Readonly<JCResumeProps>
) => {
  return prevTransactions.contracts === nextTransactions.contracts;
};

export default React.memo(JCResume, transactionsAreEqual);
