import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import SuccessFormMessage from '../../../components/messages/SuccessFormMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import DownloadTemplate from '../../../features/acForms/ScheduleOfValues/DownloadTemplate';
import ScheduleForm, {
  ScheduleFormFields,
} from '../../../features/acForms/ScheduleOfValues/ScheduleForm';
import { postScheduleOfValues } from '../../../services/ACFormsService';
import SOVSuccessMessage from '../../../features/acForms/ScheduleOfValues/SOVSuccessMessage';
import LoadingButton from '../../../components/inputs/LoadingButton';
import ConfirmationDialog from '../../../components/messages/ConfirmationDialog';

const ScheduleOfValues = () => {
  const { selectedCompany } = useCompanyContext();
  const navigate = useNavigate();
  const formMethods = useForm<ScheduleFormFields>();
  const { handleSubmit } = formMethods;
  const toast = useRef<Toast>(null);

  const { mutate, isLoading, isSuccess, data, reset } = useMutation({
    mutationFn: (schedule: File[]) => {
      return postScheduleOfValues(selectedCompany!.id, schedule);
    },
    onError: (error: AxiosError) => {
      const response = error.response;
      const data = response?.data as { message: string };
      showToast(
        'error',
        toast,
        'Upload SOV Error',
        data?.message || 'An error ocurred! please try again later',
        10000
      );
    },
  });

  const onSubmit = (data: ScheduleFormFields) => {
    mutate(data.schedule);
  };

  useEffect(() => {
    formMethods.reset();
    reset();
  }, [selectedCompany, reset, formMethods]);

  return (
    <div className="flex flex-column justify-content-center gap-5 pt-4">
      <div className="text-35px font-bold text-center mb-2 relative">
        New Schedule of Values
        {!isSuccess && (
          <div className="flex justify-content-center lg:absolute top-0 left-0 mt-4 lg:mt-0 lg:ml-6">
            <DownloadTemplate />
          </div>
        )}
      </div>
      <ErrorToast toastRef={toast} />
      {!isSuccess && (
        <FormProvider {...formMethods}>
          <form className="max-w-600px mx-auto flex flex-column gap-5">
            <ScheduleForm />
            <div className="flex flex-wrap flex-row-reverse gap-2 mb-3">
              <ConfirmationDialog
                Button={
                  <LoadingButton
                    isLoading={isLoading}
                    label="Create Schedule"
                    className="w-15rem bluwaiBlue border-0 flex m-auto py-3"
                    fontSize="text-22px"
                    type="button"
                  />
                }
                onConfirm={handleSubmit(onSubmit)}
                message="Are you sure you want to submit the schedule of values?"
              />
              <Button
                onClick={() => {
                  navigate('/accounting-dashboard/new');
                }}
                className="w-15rem bluwaiRed border-0 flex m-auto py-3"
                type="button"
              >
                <span className="mx-auto text-22px text-white">Cancel</span>
              </Button>
            </div>
          </form>
        </FormProvider>
      )}
      {isSuccess && (
        <div>
          <SuccessFormMessage
            message={
              <SOVSuccessMessage
                successFiles={data.successFiles}
                errorFiles={data.errorFiles}
              />
            }
            redirectUrl="/accounting-dashboard/new"
            redirectMessage="Click here to return to the forms list"
          />
        </div>
      )}
    </div>
  );
};

export default ScheduleOfValues;
