import React, { useCallback, useState } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { formatCurrency } from '../../../../utils/formatUtils';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import {
  CostDetailArgs,
  CostToDate,
} from '../../../Interfaces/Accounting/JobsContracts.interface';
import JCCostDrilldownContainer from '../JCCostDrilldown/JCCostDrilldownContainer';
import WrapperButton from '../../../components/buttons/WrapperButton';
import { useRolesAccessContext } from '../../../context/RolesAccessContext';

type CostToDateTableProps = {
  costs: CostToDate[];
};

const CostToDateTable = React.forwardRef<HTMLDivElement, CostToDateTableProps>(
  ({ costs }, ref) => {
    const { rolePhases } = useRolesAccessContext();
    const [selectedCost, setSelectedCost] = useState<CostDetailArgs>();
    const [showDrilldown, setShowDrilldown] = useState(false);

    const calcHeight = (rows: number) => {
      const headerHeight = 32;
      const footerHeight = 54;
      const rowHeight = 31;
      return headerHeight + footerHeight + rows * rowHeight + 3;
    };

    const onRowHover = (e: DataTableRowMouseEventParams) => {
      const cell = e.originalEvent.target as HTMLElement;
      const row = cell.closest('tr') as HTMLElement;
      animateCellText(row);
    };

    const onCellClick = (cost: CostToDate, dateType: string) => {
      if (rolePhases?.includes(cost.Phase)) {
        return;
      }

      setSelectedCost({
        job: cost.Job,
        jobName: cost.JobName,
        phase: cost.Phase,
        phaseDescription: cost.Description,
        dateType,
      });
      setShowDrilldown(true);
    };

    const hideDrilldown = () => {
      setSelectedCost(undefined);
      setShowDrilldown(false);
    };

    let curr = 0;
    let mtd = 0;
    let ytd = 0;
    let jtd = 0;
    costs.forEach((cost) => {
      curr = curr + cost.CurrCost;
      mtd = mtd + cost.MTD;
      ytd = ytd + cost.YTD;
      jtd = jtd + cost.JTD;
    });

    return (
      <div className="pdfDivFitContent">
        {selectedCost && (
          <JCCostDrilldownContainer
            visible={showDrilldown}
            hideDrilldown={hideDrilldown}
            costDetail={{ ...selectedCost }}
          />
        )}
        <Table
          id="jc-cost-dates"
          ref={ref}
          data={costs}
          className={`mx-3 dashboardOptionShadow`}
          calcHeight={useCallback(calcHeight, [])}
          onRowMouseEnter={useCallback(onRowHover, [])}
        >
          <Column
            headerClassName={`tableHeader`}
            className="tableCell p-0 printHide"
            footerClassName="tableFooter "
          />
          <Column
            headerClassName={`tableHeader`}
            className="tableCell p-0 printHide"
            footerClassName="tableFooter "
          />
          <Column
            field="Phase"
            header="Phase Code"
            style={{ minWidth: '140px', maxWidth: '140px' }}
            headerClassName="tableHeader font-normal text-center"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="Description"
            header="Description"
            body={(jc) => {
              return <div className="scroll-text">{jc.Description}</div>;
            }}
            style={{ minWidth: '300px', maxWidth: '300px' }}
            headerClassName="tableHeader font-normal justify-content-center"
            className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
            footerClassName="overflow-x-visible tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
            footer="Total"
            sortable
          />
          <Column
            field="CurrCost"
            header="Curr Est Cost"
            body={(jc) => formatCurrency(jc.CurrCost)}
            style={{ minWidth: '180px' }}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={formatCurrency(curr)}
            sortable
          />
          <Column
            field="MTD"
            header="MTD Cost"
            body={(jc) => (
              <WrapperButton
                disabled={rolePhases?.includes(jc.Phase)}
                className={`w-full text-right printColor ${
                  !rolePhases?.includes(jc.Phase) ? 'totalColor' : 'blackText'
                }`}
                onClick={() => onCellClick(jc, 'MTD')}
              >
                {formatCurrency(jc.MTD)}
              </WrapperButton>
            )}
            style={{ minWidth: '180px' }}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={formatCurrency(mtd)}
            sortable
          />
          <Column
            field="YTD"
            header="YTD Cost"
            body={(jc) => (
              <WrapperButton
                disabled={rolePhases?.includes(jc.Phase)}
                className={`w-full text-right printColor ${
                  !rolePhases?.includes(jc.Phase) ? 'totalColor' : 'blackText'
                }`}
                onClick={() => onCellClick(jc, 'YTD')}
              >
                {formatCurrency(jc.YTD)}
              </WrapperButton>
            )}
            style={{ minWidth: '180px' }}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={formatCurrency(ytd)}
            sortable
          />
          <Column
            field="JTD"
            header="JTD"
            body={(jc) => (
              <WrapperButton
                disabled={rolePhases?.includes(jc.Phase)}
                className={`w-full text-right printColor ${
                  !rolePhases?.includes(jc.Phase) ? 'totalColor' : 'blackText'
                }`}
                onClick={() => onCellClick(jc, 'JTD')}
              >
                {formatCurrency(jc.JTD)}
              </WrapperButton>
            )}
            style={{ minWidth: '180px' }}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={formatCurrency(jtd)}
            sortable
          />
          <Column
            headerClassName={`tableHeader`}
            className="tableCell p-0 printHide"
            footerClassName="tableFooter "
          />
          <Column
            headerClassName={`tableHeader`}
            className="tableCell p-0 printHide"
            footerClassName="tableFooter "
          />
        </Table>
      </div>
    );
  }
);

const transactionsAreEqual = (
  prevTransactions: Readonly<CostToDateTableProps>,
  nextTransactions: Readonly<CostToDateTableProps>
) => {
  return prevTransactions.costs === nextTransactions.costs;
};

export default React.memo(CostToDateTable, transactionsAreEqual);
