import React from 'react';
import ReportButton from '../../../components/report/ReportButton';
import * as XLSX from 'xlsx';
import { useCompanyContext } from '../../../context/CompanyContext';
import { useWatch } from 'react-hook-form';
import { BASE_PDF_STYLES } from '../../../../utils/reportUtils';
import { excelCleanTable } from '../../../../utils/excelUtils';
import { PayrollFilters, PERIOD_OPTIONS } from '../PRFilters';
import { getPRReportTypeName } from './PRReportUtils';
import { capitalizeString } from '../../../../utils/formatUtils';

const pdfStyles = `
${BASE_PDF_STYLES}

body.pdfBody .tablePrint.pdfFitTableContent thead, body.pdfBody .tablePrint tbody {
    width: fit-content!important;
  }

`;

const idPerPRReportType = {
  employee: 'pr-employees',
  earnings: 'pr-earnings',
};

type JCReportProps = {
  elementRef: React.MutableRefObject<HTMLElement | null>;
};

const PRReport = ({ elementRef }: JCReportProps) => {
  const { selectedCompany } = useCompanyContext();
  const filters = useWatch<PayrollFilters>();
  const reportDate = new Date();

  const excelElementPreProcess = (element: HTMLElement) => {
    excelCleanTable(element);

    return element;
  };

  const employeeListBeforeDownload = (
    sheet: XLSX.WorkSheet,
    element?: HTMLElement
  ) => {
    const ref = sheet['!ref'];
    const boundary = (ref ?? '').replace(/[A-Z]/g, '').split(':');
    const lastRow = parseInt(boundary[1]);

    const active = element?.querySelector('#activeEmp');
    const inactive = element?.querySelector('#inactiveEmp');
    const total = element?.querySelector('#empTotal');

    sheet[`I${lastRow + 2}`] = { t: 's', v: 'Active Employees:' };
    sheet[`J${lastRow + 2}`] = { t: 's', v: active?.textContent };
    sheet[`I${lastRow + 3}`] = { t: 's', v: 'Inactive Employees:' };
    sheet[`J${lastRow + 3}`] = { t: 's', v: inactive?.textContent };
    sheet[`I${lastRow + 4}`] = { t: 's', v: 'Total:' };
    sheet[`J${lastRow + 4}`] = { t: 's', v: total?.textContent };

    sheet['!fullref'] = `A${1}:L${lastRow + 4}`;
    sheet['!ref'] = `A${1}:L${lastRow + 4}`;
  };

  const excelBeforeDownload = (
    workBook: XLSX.WorkBook,
    element?: HTMLElement
  ) => {
    const sheet = workBook.Sheets['Sheet1'];
    const companyName = selectedCompany!.name;

    sheet['C2'] = { t: 's', v: companyName };

    sheet['C3'] = { t: 's', v: 'Payroll Report' };
    sheet['A4'] = { t: 's', v: 'Search criteria:' };

    sheet['B5'] = { t: 's', v: 'Report Type:' };
    sheet['C5'] = {
      t: 's',
      v: getPRReportTypeName(filters.reportType ?? ''),
    };

    let row = 6;

    if (filters.reportType === 'earnings') {
      sheet[`B${row}`] = { t: 's', v: 'Employee Status:' };
      sheet[`C${row}`] = {
        t: 's',
        v: capitalizeString(filters.employeeStatus ?? ''),
      };

      const period = PERIOD_OPTIONS.find(
        (period) => period.value === filters.period
      );

      sheet[`B${row + 1}`] = { t: 's', v: 'Period Type:' };
      sheet[`C${row + 1}`] = {
        t: 's',
        v: period?.label,
      };

      row += 2;
    }

    sheet[`A${row}`] = { t: 's', v: 'Created at:' };
    sheet[`B${row}`] = { t: 's', v: reportDate.toLocaleString() };

    if (filters.reportType === 'employee') {
      employeeListBeforeDownload(sheet, element);
    }
  };

  return (
    <ReportButton
      elementToPrintRef={elementRef}
      fileName="Payroll_Report"
      pdfStyles={pdfStyles}
      excelPreprocess={excelElementPreProcess}
      excelBeforeDownload={excelBeforeDownload}
      tableOriginCell="A9"
      widthElementID={
        idPerPRReportType[filters.reportType as keyof typeof idPerPRReportType]
      }
    />
  );
};

export default PRReport;
