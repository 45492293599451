import { formatDate } from '../../../../utils/formatUtils';
import { Panel } from 'primereact/panel';
import { useFormContext } from 'react-hook-form';
import FormInfo from '../../../components/messages/FormInfo';
import {
  ContractInfo,
  CustomerSummary,
  DepartmentSummary,
  ProcessGroupSummary,
} from '../../../Interfaces/Accounting/ACForms.interfaces';
import { useQuery } from '@tanstack/react-query';
import { fetchContractLookUpInfo } from '../../../services/ACFormsService';
import { useCompanyContext } from '../../../context/CompanyContext';

const ContractSummary = () => {
  const { selectedCompany } = useCompanyContext();
  const { getValues } = useFormContext<ContractInfo>();
  const {
    contract,
    contractName,
    department,
    customer,
    retainage,
    processGroup,
    startDate,
  } = getValues();

  const { data } = useQuery({
    queryKey: ['getNewContractInfo', selectedCompany],
    queryFn: ({ signal }) =>
      fetchContractLookUpInfo(selectedCompany!.id, signal),
    refetchOnWindowFocus: false,
    enabled: false,
    keepPreviousData: true,
  });

  const departmentData = data?.departments.find(
    (dep: DepartmentSummary) => dep.Department === department
  );
  const customerData = data?.customers.find(
    (cust: CustomerSummary) => cust.accountNumber === customer
  );
  const processData = data?.processGroups.find(
    (proc: ProcessGroupSummary) => proc.ProcessGroup === processGroup
  );

  return (
    <Panel header="Contract Set-up" className="max-w-600px mx-3 text-standard">
      <div className="grid">
        <div className="col-12 sm:col-6">
          <FormInfo label="Next Available Contract" content={contract} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Contract Name" content={contractName} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Department" content={departmentData?.Description} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Customer" content={customerData?.name} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Retainage Default" content={`${retainage}%`} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo
            label="Processing Group"
            content={processData?.Description || processGroup}
          />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo
            label="Approximate Start Date"
            content={formatDate(startDate)}
          />
        </div>
      </div>
    </Panel>
  );
};

export default ContractSummary;
