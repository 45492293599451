import { formatUTCDate } from '../../../../utils/formatUtils';
import React, { useRef } from 'react';
import {
  SCPaymentDetails,
  SCPaymentDrilldownDetails,
} from '../../../Interfaces/Accounting/Subcontracts.interfaces';
import SCPaymentDetailsTable from './SCPaymentDetailsTable';
import { processDetailList } from './util';
import SCDrilldownReport from '../SCReport/SCDrilldownReport';

type SCPaymentDrilldownProps = {
  paymentDetails: SCPaymentDrilldownDetails;
  details: SCPaymentDetails[];
  showJob?: boolean;
  periodEnd?: Date;
  processByJob?: boolean;
  processBySubcontract?: boolean;
};

const SCPaymentDrilldown = ({
  paymentDetails,
  details,
  showJob,
  periodEnd,
  processByJob = false,
  processBySubcontract = false,
}: SCPaymentDrilldownProps) => {
  const drilldownRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      id="sc-payment-details"
      ref={drilldownRef}
      className="blackText text-standard printBackground w-full max-w-1000px"
    >
      <div className="mt-3 px-4 flex justify-content-center sm:justify-content-between gap-3 flex-wrap capitalize">
        <div className="text-28px font-bold flex-grow-1 text-center sm:text-left">
          <div className="mx-auto w-fit sm:ml-0 drilldownHeader mb-3">
            SC Payment Details
          </div>
          {paymentDetails.Job && (
            <div className="text-23px mr-auto w-fit">
              <span>Job Number:</span>
              <span className="font-normal ml-1">
                {paymentDetails.Job} {paymentDetails.JobName}
              </span>
            </div>
          )}
          {paymentDetails.VendorName && (
            <div className="text-23px mr-auto w-fit">
              <span>Sub. Name:</span>
              <span className="font-normal ml-1">
                {paymentDetails.VendorName}
              </span>
            </div>
          )}
          {paymentDetails.PmName && (
            <div className="text-23px mr-auto w-fit">
              <span>Project Manager:</span>
              <span className="font-normal ml-1">{paymentDetails.PmName}</span>
            </div>
          )}
          {periodEnd && (
            <div className="text-23px mr-auto w-fit">
              <span>Check Date:</span>
              <span className="font-normal ml-1">
                {formatUTCDate(periodEnd)}
              </span>
            </div>
          )}
          {paymentDetails.InvDate && (
            <div className="text-23px mr-auto w-fit">
              <span>Inv. Date:</span>
              <span className="font-normal ml-1">
                {formatUTCDate(paymentDetails.InvDate)}
              </span>
            </div>
          )}
          {paymentDetails.APRef && (
            <div className="text-23px mr-auto w-fit">
              <span>Inv. Number:</span>
              <span className="font-normal ml-1">{paymentDetails.APRef}</span>
            </div>
          )}
        </div>
        <div className="text-23px mx-auto md:ml-4 md:mr-2 mt-2 sm:mt-0 cursor-pointer printHide flex align-items-center h-fit">
          <SCDrilldownReport
            elementRef={drilldownRef}
            paymentDetails={paymentDetails}
          />
        </div>
      </div>
      <div className="my-4" />
      <SCPaymentDetailsTable
        details={processDetailList(details, processBySubcontract, processByJob)}
        showJob={showJob}
      />
    </div>
  );
};

export default SCPaymentDrilldown;
