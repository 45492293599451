import { formatCurrency } from '../../../../utils/formatUtils';
import React, { useCallback, useState } from 'react';
import Table from '../../../components/Table/Table';
import { Column } from 'primereact/column';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import SCPeriodPaymentDrilldownContainer from '../SCPaymentDrilldown/SCPeriodPaymentDrilldownContainer';
import { SCPaymentSummary } from '../../../Interfaces/Accounting/Subcontracts.interfaces';
import ColorIndicator from '../../../components/messages/ColorIndicator';
import WrapperButton from '../../../components/buttons/WrapperButton';

type SCResumeTableProps = {
  payments: SCPaymentSummary[];
  groupType: string;
  periodStart: Date;
  periodEnd: Date;
};

const SCResumeTable = ({
  payments,
  groupType,
  periodStart,
  periodEnd,
}: SCResumeTableProps) => {
  const [showDrilldown, setShowDrilldown] = useState(false);
  const [payment, setPayment] = useState<SCPaymentSummary>();

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  const onDrilldownCellClick = (scPayment: SCPaymentSummary) => {
    setPayment(scPayment);
    setShowDrilldown(true);
  };

  const hideDrilldown = () => {
    setPayment(undefined);
    setShowDrilldown(false);
  };

  let amount = 0;
  payments.forEach((payment) => {
    amount += payment.PaidAmount;
  });

  return (
    <React.Fragment>
      {payment && (
        <SCPeriodPaymentDrilldownContainer
          visible={showDrilldown}
          hideDrilldown={hideDrilldown}
          scpayment={payment}
          periodStart={periodStart}
          periodEnd={periodEnd}
          processByJob={groupType !== 'job'}
          processBySubcontract={groupType !== 'vendor'}
        />
      )}
      <Table
        data={payments}
        className={`dashboardOptionShadow w-full max-w-450px`}
        onRowMouseEnter={useCallback(onRowHover, [])}
        fixedDefaultHeight={305}
      >
        {groupType === 'job' && (
          <Column
            field="Job"
            header="Job"
            body={(sc, options) => {
              return (
                <div className="flex gap-1 align-items-center">
                  <ColorIndicator colorIndex={options.rowIndex} />
                  {sc.Job}
                </div>
              );
            }}
            style={{ minWidth: '110px', maxWidth: '110px' }}
            headerClassName={`tableHeader font-normal text-center`}
            className={`text-standard blackText tableCell justify-content-center`}
            footerClassName="tableFooter"
          />
        )}
        {groupType === 'job' && (
          <Column
            field="JobName"
            header="Job Name"
            body={(sc) => {
              return <div className="scroll-text">{sc.JobName}</div>;
            }}
            style={{ minWidth: '200px', maxWidth: '200px' }}
            headerClassName={`tableHeader font-normal text-center justify-content-center`}
            className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
            footer={'Total'}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
          />
        )}
        {groupType === 'vendor' && (
          <Column
            field="VendorName"
            header="Vendor"
            body={(sc, options) => {
              return (
                <div className="flex gap-1 align-items-center scroll-text">
                  <ColorIndicator colorIndex={options.rowIndex} />
                  {sc.VendorName}
                </div>
              );
            }}
            style={{ minWidth: '250px', maxWidth: '250px' }}
            headerClassName={`tableHeader font-normal text-center justify-content-center`}
            className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
            footer={'Total'}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
          />
        )}
        {groupType === 'pm' && (
          <Column
            field="Name"
            header="Name"
            body={(sc, options) => {
              return (
                <div className="flex gap-1 align-items-center scroll-text">
                  <ColorIndicator colorIndex={options.rowIndex} />
                  {sc.Name}
                </div>
              );
            }}
            style={{ minWidth: '250px', maxWidth: '250px' }}
            headerClassName={`tableHeader font-normal text-center justify-content-center`}
            className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
            footer={'Total'}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
          />
        )}
        <Column
          field="PaidAmount"
          header="Amount"
          body={(sc) => (
            <WrapperButton
              className="totalColor w-full text-right printColor"
              onClick={() => onDrilldownCellClick(sc)}
            >
              {formatCurrency(sc.PaidAmount)}
            </WrapperButton>
          )}
          style={{ minWidth: '130px' }}
          headerClassName={`tableHeader font-normal text-center justify-content-center`}
          className={`text-standard blackText tableCell justify-content-end`}
          footer={formatCurrency(amount)}
          footerClassName="tableFooter block text-right border-top-2 mt-4"
        />
      </Table>
    </React.Fragment>
  );
};

export default SCResumeTable;
