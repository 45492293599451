import React, { useCallback } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { formatUTCDate, formatCurrency } from '../../../../utils/formatUtils';
import { Payable } from '../../../Interfaces/Accounting/AccountsPayables.interfaces';
import TooltipResume from '../../../components/messages/TooltipResume';
import ResumeContent from '../../../components/messages/ResumeContent';
import {
  DataTableRowClickEventParams,
  DataTableRowMouseEventParams,
} from 'primereact/datatable';
import JCAttachment from '../../../components/attachment/Attachment';
import { animateCellText } from '../../../../utils/htmlUtils';

export type APUnapprovedTableProps = {
  accountsPayables: Payable[];
};

const APUnapprovedTable = React.forwardRef<
  HTMLDivElement,
  APUnapprovedTableProps
>(({ accountsPayables }, ref) => {
  const limit = 30;
  const calcHeight = (rows: number) => {
    const headerHeight = 32;
    const footerHeight = 54;
    const rowHeight = 31;
    return headerHeight + footerHeight + rows * rowHeight + 3;
  };

  let grossTotal = 0;
  let taxTotal = 0;
  accountsPayables.forEach((payable: Payable) => {
    grossTotal = payable.Gross + grossTotal;
    taxTotal = (payable.Tax || 0) + taxTotal;
  });

  const onRowClick = (e: DataTableRowClickEventParams) => {
    const data = e.data;
    const note = data.Notes;
    if (!note || note.length < limit) {
      return;
    }

    const element = e.originalEvent.target as HTMLElement;
    const row = element.closest('tr');
    const resumeElement = row?.getElementsByClassName(
      'resume-content'
    )[0] as HTMLElement;

    if (resumeElement) {
      const resume = resumeElement.firstElementChild as HTMLElement;
      resume?.click();
    }
  };

  const rowClassName = (data: Payable) => {
    const note = data.Notes;
    if (note && note.length >= limit) {
      return 'cursor-pointer';
    }
    return '';
  };

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  return (
    <Table
      id="unapproved-table"
      ref={ref}
      data={accountsPayables}
      className={`mx-3 dashboardOptionShadow tableFirstLineStyled`}
      calcHeight={useCallback(calcHeight, [])}
      onRowClick={useCallback(onRowClick, [])}
      rowClassName={useCallback(rowClassName, [])}
      onRowMouseEnter={useCallback(onRowHover, [])}
      hideColumns={true}
    >
      <Column
        field="Vendor"
        header="Vendor"
        body={(ap) => {
          return <div className="scroll-text">{ap.Vendor}</div>;
        }}
        headerClassName={`tableHeader font-normal justify-content-center`}
        style={{ minWidth: '200px' }}
        className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
        footerClassName="tableFooter"
        sortable
      />
      <Column
        field="Reference"
        header="Inv. Number"
        style={{ minWidth: '150px' }}
        headerClassName="tableHeader font-normal"
        className="justify-content-center text-standard blackText tableCell"
        footerClassName="tableFooter"
        sortable
      />
      <Column
        field="InvoiceDate"
        header="Inv. Date"
        style={{ minWidth: '150px' }}
        body={(ap) => formatUTCDate(ap.InvoiceDate)}
        headerClassName="tableHeader font-normal"
        className="justify-content-center text-standard blackText tableCell"
        footerClassName="tableFooter"
        sortable
      />
      <Column
        field="Description"
        header="Description"
        body={(ap) => {
          return <div className="scroll-text">{ap.Description}</div>;
        }}
        style={{ minWidth: '200px' }}
        headerClassName="tableHeader font-normal"
        className=" text-standard blackText tableCell title overflow-x-hidden white-space-nowrap checkOverflow"
        footer="Grand Total"
        footerClassName={`overflow-x-visible tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative`}
        sortable
      />
      <Column
        field="Gross"
        header={'Amount'}
        style={{ minWidth: '130px' }}
        body={(ap) => formatCurrency(ap.Gross)}
        headerClassName="tableHeader font-normal justify-content-center text-center"
        className="justify-content-end text-standard blackText tableCell"
        footer={grossTotal.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })}
        footerClassName="tableFooter block text-right border-top-2 mt-4"
        sortable
      />
      <Column
        field="Tax"
        header="Tax"
        style={{ minWidth: '120px' }}
        body={(ap) => formatCurrency(ap.Tax)}
        headerClassName="tableHeader font-normal justify-content-center text-center"
        className="justify-content-end text-standard blackText tableCell"
        footer={taxTotal.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })}
        footerClassName="tableFooter block text-right border-top-2 mt-4"
        sortable
      />
      <Column
        field="NetAmount"
        header={'Total Amount'}
        style={{ minWidth: '170px' }}
        body={(ap) => formatCurrency(ap.NetAmount)}
        headerClassName="tableHeader font-normal justify-content-center text-center"
        className="justify-content-end text-standard blackText tableCell"
        footer={(grossTotal + taxTotal).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })}
        footerClassName="tableFooter block text-right border-top-2 mt-4"
        sortable
      />
      <Column
        field="Reviewer"
        header="Reviewer"
        body={(ap) => (
          <React.Fragment>
            <div className="printHide">
              <TooltipResume
                text={ap.Reviewer}
                maximum={4}
                id={ap.Reference.replace(/\W/g, '')}
              />
            </div>
            <div className="hidden printShow word-break-word text-center">
              {ap.Reviewer}
            </div>
          </React.Fragment>
        )}
        style={{ minWidth: '100px' }}
        headerClassName="tableHeader font-normal"
        className="justify-content-center text-standard blackText tableCell"
        footerClassName="tableFooter"
        sortable
      />
      <Column
        field="Notes"
        header="Notes"
        body={(ap) => {
          return (
            <React.Fragment>
              <div className="printHide w-full resume-content">
                <ResumeContent text={ap.Notes || ''} limit={limit} />
              </div>
              <div className="hidden printShow justify-content-center">
                {ap.Notes}
              </div>
            </React.Fragment>
          );
        }}
        style={{ minWidth: '300px' }}
        headerClassName="tableHeader font-normal"
        className="justify-content-center text-standard blackText tableCell word-break-word"
        footerClassName="tableFooter"
        sortable
      />
      <Column
        field="Rejected"
        header="Status"
        body={(ap) =>
          ap.Rejected === 'Y' ? (
            <span className="text-red-600 font-bold text-standard">!</span>
          ) : null
        }
        style={{ minWidth: '50px' }}
        headerClassName="tableHeader font-normal"
        className="justify-content-center text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        style={{ minWidth: '80px', maxWidth: '80px' }}
        body={(ap) =>
          ap.UniqueAttchID && (
            <JCAttachment
              uniqueAttchID={ap.UniqueAttchID}
              zipFilename={ap.Reference}
            />
          )
        }
        headerClassName="tableHeader font-normal"
        className="justify-content-center text-standard blackText tableCell printHide"
        footerClassName="tableFooter"
      />
    </Table>
  );
});

const transactionsAreEqual = (
  prevTransactions: Readonly<APUnapprovedTableProps>,
  nextTransactions: Readonly<APUnapprovedTableProps>
) => {
  return (
    prevTransactions.accountsPayables === nextTransactions.accountsPayables
  );
};

export default React.memo(APUnapprovedTable, transactionsAreEqual);
