import SectionList from '../../features/acForms/formsList/SectionList';

const Form = () => {
  return (
    <div className="h-full flex flex-column">
      <div className="mt-6 mb-3 mx-8">
        <SectionList />
      </div>
    </div>
  );
};

export default Form;
