import { Toast } from 'primereact/toast';
import React from 'react';
import { useReactToPrint } from 'react-to-print';
import { showToast } from '../messages/ErrorAlert';
import {
  postGeneratePDF,
  postGeneratePDFArgs,
} from '../../services/AttachmentService';
import { useMutation } from '@tanstack/react-query';

type PrintItemProps = {
  setLoading: (state: boolean) => void;
  fileName: string;
  elementToPrintRef: React.MutableRefObject<HTMLElement | null>;
  toast: React.RefObject<Toast>;
  pdfStyles: string;
  widthElementID: string;
  isPdfPortrait?: boolean;
  minWidth?: number;
  format?: string;
};

const PdfItem = ({
  setLoading,
  fileName,
  elementToPrintRef,
  toast,
  pdfStyles,
  widthElementID,
  isPdfPortrait,
  minWidth,
  format,
}: PrintItemProps) => {
  const { mutate } = useMutation({
    mutationFn: (data: postGeneratePDFArgs) => {
      return postGeneratePDF(data);
    },
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      setLoading(false);
    },
    onError: (e: { response: { status: number } }) => {
      let message = '';
      if (e.response?.status === 413) {
        message =
          'An error occured because the report is too large. Please export less content!';
      }

      showErrorToast(message);
      setLoading(false);
    },
  });

  const showErrorToast = (message?: string) => {
    showToast(
      'error',
      toast,
      'PDF Error',
      message ?? 'An error ocurred while downloading PDF file! Try again later',
      3000
    );
  };

  const backendPDF = async (printIframe: HTMLIFrameElement) => {
    const printDoc = printIframe.contentDocument as Document;
    if (!printDoc) {
      return;
    }

    const printHtml = printDoc.getElementsByTagName('html')[0];
    const printHead = printHtml.getElementsByTagName('head')[0];
    const style = printHead.getElementsByTagName('style')[0].cloneNode(true);
    const cloneDocumentHead = document
      .getElementsByTagName('head')[0]
      .cloneNode(true) as HTMLElement;

    cloneDocumentHead.appendChild(style);
    printHead.replaceWith(cloneDocumentHead);

    let width = printDoc.getElementById(widthElementID)?.offsetWidth;
    if ((minWidth && width && minWidth > width) || !width) {
      width = minWidth;
    }

    mutate({
      html: printHtml.outerHTML,
      format: format ?? 'A4',
      isPdfPortrait: !!isPdfPortrait,
      width: width,
    });
  };

  const handlePDF = useReactToPrint({
    bodyClass: 'pdfBody',
    pageStyle: pdfStyles,
    copyStyles: false,
    content: () => {
      if (!elementToPrintRef.current) {
        showErrorToast();
      }
      return elementToPrintRef.current;
    },
    print: backendPDF,
    onPrintError: () => {
      setLoading(false);
      showErrorToast();
    },
  });

  return {
    label: 'Export Pdf',
    icon: 'pi pi-fw pi-file-pdf',
    command: () => {
      setLoading(true);
      handlePDF();
    },
  };
};

export default PdfItem;
