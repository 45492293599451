import { Document, Page, pdfjs } from 'react-pdf';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useState } from 'react';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

type PreviewPDFProps = {
  pdf: string | ArrayBuffer;
  show: boolean;
  hide: () => void;
};

const PreviewPDF = ({ pdf, show, hide }: PreviewPDFProps) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const buttonClass =
    'py-2 px-3 blackText bg-white hover:bg-gray-300 text-color border-white noShadow font-bold';

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <Dialog header="Pdf Preview" visible={show} onHide={hide} className="w-fit">
      <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
        <Page
          pageNumber={pageNumber}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
        <div className="flex justify-content-center align-items-center text-15px dashboardOptionShadow w-fit mx-auto font-bold">
          <Button
            disabled={pageNumber === 1}
            className={buttonClass}
            onClick={() => {
              setPageNumber(pageNumber - 1);
            }}
          >
            {'<'}
          </Button>
          <div className="w-fit px-1 flex blackText text-standard">
            Page {pageNumber} of {numPages}
          </div>
          <Button
            disabled={pageNumber === numPages}
            className={buttonClass}
            onClick={() => {
              setPageNumber(pageNumber + 1);
            }}
          >
            {'>'}
          </Button>
        </div>
      </Document>
    </Dialog>
  );
};

export default PreviewPDF;
