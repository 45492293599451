import React, { useCallback, useState } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { formatCurrency } from '../../../../utils/formatUtils';
import {
  DataTableExpandedRows,
  DataTableRowClickEventParams,
  DataTableRowMouseEventParams,
  DataTableRowToggleParams,
} from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import SCItemPaymentsContainer from './SCItemPaymentsContainer';
import { SCItemPaymentStatus } from '../../../Interfaces/Accounting/Subcontracts.interfaces';

type SCItemStatusTableProps = {
  items: SCItemPaymentStatus[];
};

const SCItemStatusTable = React.forwardRef<
  HTMLDivElement,
  SCItemStatusTableProps
>(({ items }, ref) => {
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows>({});

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  const rowClassName = (data: SCItemPaymentStatus) => {
    const ref = data.SLItem;
    const isExpanded = expandedRows[ref];
    return isExpanded
      ? 'cursor-pointer expandedRow'
      : 'cursor-pointer expandedTableCell';
  };

  const rowExpansionTemplate = (data: SCItemPaymentStatus) => {
    return <SCItemPaymentsContainer sl={data.SL} slItem={data.SLItem} />;
  };

  const onRowClick = (e: DataTableRowClickEventParams) => {
    const expanded = { ...expandedRows };
    const ref = e.data.SLItem;
    if (expanded[ref]) {
      delete expanded[ref];
      setExpandedRows(expanded);
    } else {
      expanded[ref] = true;
      setExpandedRows(expanded);
    }
  };

  const onRowToogle = (e: DataTableRowToggleParams) => {
    setExpandedRows(e.data as DataTableExpandedRows);
  };

  return (
    <Table
      ref={ref}
      data={items}
      className="expandedTable noFooter w-full dobleHeader"
      onRowMouseEnter={useCallback(onRowHover, [])}
      dataKey="SLItem"
      expandedRows={expandedRows}
      rowExpansionTemplate={rowExpansionTemplate}
      onRowClick={onRowClick}
      onRowToggle={onRowToogle}
      rowClassName={rowClassName}
    >
      <Column
        style={{ minWidth: '1rem', maxWidth: '1rem' }}
        headerClassName={`expandedTableHeader`}
        className="tableCell p-0 printHide"
        footerClassName="tableFooter"
      />
      <Column
        expander={true}
        style={{ minWidth: '50px', maxWidth: '50px' }}
        headerClassName={`expandedTableHeader`}
        className={`text-standard blackText printHide tableCell justify-content-center `}
        footerClassName="tableFooter"
      />
      <Column
        field="SLItem"
        header="Item"
        headerClassName={`font-normal expandedTableHeader`}
        style={{ minWidth: '80px', maxWidth: '80px' }}
        className={`text-standard blackText tableCell justify-content-center `}
        footerClassName="tableFooter"
      />
      <Column
        field="Description"
        header="Description"
        body={(sc) => {
          return <div className="scroll-text">{sc.Description}</div>;
        }}
        headerClassName={`font-normal expandedTableHeader`}
        style={{ minWidth: '120px', maxWidth: '120px' }}
        className={`text-standard blackText tableCell  overflow-x-hidden white-space-nowrap checkOverflow`}
        footerClassName="tableFooter"
      />
      <Column
        field="Phase"
        header="Phase"
        headerClassName={`font-normal expandedTableHeader`}
        style={{ minWidth: '300px', maxWidth: '300px' }}
        className={`justify-content-center text-standard blackText tableCell `}
        footerClassName="tableFooter"
      />
      <Column
        field="OrigCost"
        header="Original Contract"
        body={(payment) => formatCurrency(payment.OrigCost)}
        headerClassName={`font-normal expandedTableHeader text-center justify-content-center`}
        style={{ minWidth: '140px' }}
        className={`justify-content-end text-standard blackText tableCell `}
      />
      <Column
        field="ChangeOrder"
        header="Change Orders"
        body={(payment) => formatCurrency(payment.ChangeOrder)}
        headerClassName={`font-normal expandedTableHeader text-center justify-content-center`}
        style={{ minWidth: '140px' }}
        className={`justify-content-end text-standard blackText tableCell `}
      />
      <Column
        field="CurCost"
        header="Current Contract"
        body={(payment) => formatCurrency(payment.CurCost)}
        headerClassName={`font-normal expandedTableHeader text-center justify-content-center`}
        style={{ minWidth: '140px' }}
        className={`justify-content-end text-standard blackText tableCell `}
      />
      <Column
        field="InvCost"
        header="Amount Invoiced"
        body={(payment) => formatCurrency(payment.InvCost)}
        headerClassName={`font-normal expandedTableHeader text-center justify-content-center`}
        style={{ minWidth: '140px' }}
        className={`justify-content-end text-standard blackText tableCell `}
      />
      <Column
        field="PayablePaid"
        header="Amount Paid"
        body={(payment) => formatCurrency(payment.PayablePaid)}
        headerClassName={`font-normal expandedTableHeader text-center justify-content-center`}
        style={{ minWidth: '140px' }}
        className={`justify-content-end text-standard blackText tableCell `}
      />
      <Column
        field="PayableUnpaid"
        header="Payable (Unpaid)"
        body={(payment) => formatCurrency(payment.PayableUnpaid)}
        headerClassName={`font-normal expandedTableHeader text-center justify-content-center`}
        style={{ minWidth: '140px' }}
        className={`justify-content-end text-standard blackText tableCell`}
      />
      <Column
        field="RetainageUnpaid"
        header={() => (
          <div>
            <div>Retainage</div>
            <div>(unpaid)</div>
          </div>
        )}
        body={(payment) => formatCurrency(payment.RetainageUnpaid)}
        headerClassName={`font-normal expandedTableHeader text-center justify-content-center`}
        style={{ minWidth: '140px' }}
        className={`justify-content-end text-standard blackText tableCell`}
      />
      <Column
        field="CommittedBalance"
        header="Committed Balance"
        body={(payment) => formatCurrency(payment.CommittedBalance)}
        headerClassName={`font-normal expandedTableHeader text-center justify-content-center`}
        style={{ minWidth: '140px' }}
        className={`justify-content-end text-standard blackText tableCell`}
      />
      <Column
        style={{ minWidth: '30px', maxWidth: '30px' }}
        headerClassName={`expandedTableHeader`}
        className="tableCell p-0 printHide"
        footerClassName="tableFooter"
      />
      <Column
        style={{ minWidth: '1rem', maxWidth: '1rem' }}
        headerClassName={`expandedTableHeader`}
        className="tableCell p-0 printHide"
        footerClassName="tableFooter"
      />
    </Table>
  );
});

const transactionsAreEqual = (
  prevTransactions: Readonly<SCItemStatusTableProps>,
  nextTransactions: Readonly<SCItemStatusTableProps>
) => {
  return prevTransactions.items === nextTransactions.items;
};

export default React.memo(SCItemStatusTable, transactionsAreEqual);
