import React, { useRef } from 'react';
import { CostEstimates } from '../../../Interfaces/Accounting/JobsContracts.interface';
import JCDrilldownReport from '../JCReport/JCDrilldownReport';
import JCCostEstimateTable from './JCCostEstimateTable';

type JCCEDrilldownProps = {
  estimates: CostEstimates[];
  contract: string;
  contractName: string;
};

const JCCEDrilldown = ({
  estimates,
  contract,
  contractName,
}: JCCEDrilldownProps) => {
  const drilldownRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      id="jc-costs-estimates-drilldown"
      ref={drilldownRef}
      className="blackText text-standard printBackground w-full max-w-1500px pdfDivFitContent"
    >
      <div className="mt-3 px-4 flex justify-content-center sm:justify-content-between gap-3 flex-wrap capitalize">
        <div className="text-28px font-bold flex-grow-1 text-center sm:text-left">
          <div className="mx-auto w-fit sm:ml-0 drilldownHeader">
            JC Cost/Estimate Drilldown
          </div>
          <div className="text-23px mt-3 mr-auto w-fit">
            Contract {contract} {contractName}
          </div>
        </div>
        <div className="text-23px mx-auto md:ml-4 md:mr-2 mt-2 sm:mt-0 cursor-pointer printHide flex align-items-center">
          <JCDrilldownReport
            title="JC Costs/Estimates Drilldown"
            elementRef={drilldownRef}
            fileName="JC_Cost_Estimate_Drilldown_Report"
            elementID="jc-costs-estimates-drilldown"
            costDetail={{ contract, contractName }}
          />
        </div>
      </div>
      <div className="my-4" />
      <JCCostEstimateTable estimates={estimates} drilldownStyles={true} />
    </div>
  );
};

export default JCCEDrilldown;
