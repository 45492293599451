import React, { useCallback } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import { JCPhase } from '../../../Interfaces/Accounting/JobsContracts.interface';

type JCPhaseMasterTableProps = {
  phases: JCPhase[];
};

const JCPhaseMasterTable = React.forwardRef<
  HTMLDivElement,
  JCPhaseMasterTableProps
>(({ phases }, ref) => {
  const tableClass = 'mx-3 dashboardOptionShadow';
  const columnHeaderClass = 'tableHeader';

  const calcHeight = (rows: number) => {
    const headerHeight = 32;
    const footerHeight = 0;
    const rowHeight = 31;
    return headerHeight + footerHeight + rows * rowHeight + 3;
  };

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  return (
    <Table
      id="jc-phase-master"
      ref={ref}
      data={phases}
      className={`${tableClass} noFooter`}
      calcHeight={useCallback(calcHeight, [])}
      onRowMouseEnter={useCallback(onRowHover, [])}
    >
      <Column
        headerClassName={columnHeaderClass}
        className="tableCell p-0 printHide"
        footerClassName="tableFooter"
      />
      <Column
        headerClassName={columnHeaderClass}
        className="tableCell p-0 printHide"
        footerClassName="tableFooter"
      />
      <Column
        field="Phase"
        header="Phase"
        style={{ minWidth: '120px' }}
        headerClassName={`${columnHeaderClass} font-normal text-center`}
        className="justify-content-center text-standard blackText tableCell"
        footerClassName="tableFooter"
        sortable
      />
      <Column
        field="Description"
        header="Description"
        body={(jc) => {
          return <div className="scroll-text">{jc.Description}</div>;
        }}
        style={{ minWidth: '250px' }}
        headerClassName={`${columnHeaderClass} font-normal justify-content-center`}
        className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
        footerClassName="tableFooter"
        sortable
      />
      <Column
        headerClassName={columnHeaderClass}
        className="tableCell p-0 printHide"
        footerClassName="tableFooter"
      />
      <Column
        headerClassName={columnHeaderClass}
        className="tableCell p-0 printHide"
        footerClassName="tableFooter "
      />
    </Table>
  );
});

const transactionsAreEqual = (
  prevTransactions: Readonly<JCPhaseMasterTableProps>,
  nextTransactions: Readonly<JCPhaseMasterTableProps>
) => {
  return prevTransactions.phases === nextTransactions.phases;
};

export default React.memo(JCPhaseMasterTable, transactionsAreEqual);
