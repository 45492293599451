import { useQuery } from '@tanstack/react-query';
import { useCompanyContext } from 'apps/tmr-frontend/src/app/context/CompanyContext';
import {
  fetchJobCostCodes,
  fetchJobExtras,
  fetchUnitsOfMeasure,
} from 'apps/tmr-frontend/src/app/services/ACFormsService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { BASE_PDF_STYLES } from '../../../../../utils/reportUtils';
import SuccessFormMessage from '../../../../components/messages/SuccessFormMessage';
import ReportButton from '../../../../components/report/ReportButton';
import {
  JobCostCode,
  JobExtra,
  POExtra,
  POForm,
  POItem,
  POItemSummary,
  POPieceWork,
  UnitOfMeasure,
} from '../../../../Interfaces/Accounting/ACForms.interfaces';
import { PO_COST_TYPES } from '../../formConsts';
import GeneralInfoSummary from './GeneralInfoSummary';
import PieceWorkSummary from './PieceWorkSummary';

type POEntrySucessProps = {
  poItems: POItem[];
};

const formatData = (poItems: POItemSummary[]) => {
  const pieceWorks: Record<string, POPieceWork> = {};

  poItems.forEach((poItem) => {
    const extra: POExtra = {
      id: '',
      extra: poItem.extraNumber,
      costCode: poItem.costCode,
      um: poItem.unitOfMeasure,
      quantity: poItem.units,
      price: poItem.cost,
      total: poItem.total ?? poItem.units * poItem.cost,
    };
    const pw = pieceWorks[poItem.pieceWorkDesc];

    if (pw === undefined || pw === null) {
      const pieceWork: POPieceWork = {
        description: poItem.pieceWorkDesc,
        extras: [extra],
      };

      pieceWorks[poItem.pieceWorkDesc] = pieceWork;
    } else {
      pw.extras?.push(extra);
    }
  });

  return { pieceWorks: Object.values(pieceWorks) };
};

const POEntrySucess = ({ poItems }: POEntrySucessProps) => {
  const { selectedCompany } = useCompanyContext();
  const { getValues } = useFormContext<POForm>();
  const { job, poType } = getValues();
  const summaryRef = useRef<HTMLDivElement | null>(null);
  const { pieceWorks } = useMemo(() => formatData(poItems), [poItems]);

  const umRequest = useQuery({
    queryKey: ['getUnitOfMeasure'],
    queryFn: ({ signal }) => {
      return fetchUnitsOfMeasure(signal);
    },
    refetchOnWindowFocus: false,
  });

  const umJson = umRequest.data?.reduce(
    (acc: Record<string, string>, um: UnitOfMeasure) => {
      acc[um.UM] = um.Description;

      return acc;
    },
    {} as Record<string, string>
  );

  const costRequest = useQuery({
    queryKey: ['getJCCostCodes', selectedCompany, job],
    queryFn: ({ signal }) => {
      return fetchJobCostCodes(
        selectedCompany!.id,
        job,
        PO_COST_TYPES[poType] ?? null,
        signal
      );
    },
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && job),
  });

  const costJson = costRequest.data?.reduce(
    (acc: Record<string, string>, costCode: JobCostCode) => {
      acc[costCode.CostCode] = costCode.Description;

      return acc;
    },
    {} as Record<string, string>
  );

  const extraRequest = useQuery({
    queryKey: ['getJobExtras', selectedCompany, job],
    queryFn: ({ signal }) => {
      return fetchJobExtras(selectedCompany!.id, job, signal);
    },
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && job),
  });

  const extraJson = extraRequest.data?.reduce(
    (acc: Record<string, string>, extra: JobExtra) => {
      acc[extra.ExNumber] = extra.ExDesc;

      return acc;
    },
    {} as Record<string, string>
  );

  if (
    umRequest.isFetching ||
    costRequest.isFetching ||
    extraRequest.isFetching
  ) {
    return <ProgressSpinner />;
  }

  return (
    <div>
      <SuccessFormMessage
        message="The Purchase Order has been uploaded for creation!"
        redirectUrl="/accounting-dashboard/new"
        redirectMessage="Click here to return to the forms list"
      />
      <div
        id="po-success"
        className="w-fit mx-auto flex flex-column gap-3"
        ref={summaryRef}
      >
        <div className="text-30px font-bold mt-6 flex justify-content-between align-items-end">
          PO Summary:
          <div className="text-23px cursor-pointer printHide h-fit">
            <ReportButton
              elementToPrintRef={summaryRef}
              fileName="Purchase_Order"
              pdfStyles={BASE_PDF_STYLES}
              noButton={true}
              hideExcel={true}
              isPdfPortrait={true}
              widthElementID="po-success"
              minWidth={800}
            />
          </div>
        </div>
        <GeneralInfoSummary />
        <PieceWorkSummary
          pieceWorks={pieceWorks}
          ums={umJson}
          costCodes={costJson}
          extras={extraJson}
        />
      </div>
    </div>
  );
};

export default POEntrySucess;
