import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import ResumeCard from '../../../components/cards/ResumeCard';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import { fetchAccountsPayablesResume } from '../../../services/AccountsPayablesService';
import APResume from './APResume';

const APResumeContainer = () => {
  const { selectedCompany } = useCompanyContext();
  const { data, isLoading, isError, isSuccess } = useQuery({
    queryKey: ['getAccountsPayableResume', selectedCompany],
    queryFn: ({ signal }) =>
      fetchAccountsPayablesResume(selectedCompany!, signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  return (
    <div className="w-full max-w-624px md:ml-auto">
      <ResumeCard>
        <div className="p-3 flex flex-wrap gap-5">
          {isLoading && <ProgressSpinner />}
          {isSuccess && (
            <APResume
              opTotal={data.openPayables || 0}
              uapTotal={data.unapprovedPayables || 0}
            />
          )}
          {isError && (
            <ErrorMessage
              content={'Failed to obtain data! Please try again later.'}
            />
          )}
        </div>
      </ResumeCard>
    </div>
  );
};

export default APResumeContainer;
