import React, { useCallback, useRef } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useRolesAccessContext } from '../context/RolesAccessContext';
import { checkAccess, shouldBlockAdmin } from '../../utils/roleUtils';

type CheckPermissionsProps<T = JSX.Element> = {
  sectionsToAccess: string[];
  Children: React.FC<T>;
  blockAdminType?: boolean | 'external' | 'internal';
};

const checkPermissions = <T,>({
  sectionsToAccess,
  Children,
  blockAdminType,
}: CheckPermissionsProps<T>) => {
  return useCallback(
    (props: T & React.PropsWithChildren<T>) => {
      const { rolesAcess, isAdmin, userType } = useRolesAccessContext();
      const hasAccess = checkAccess(rolesAcess ?? [], sectionsToAccess);

      if (
        hasAccess ||
        (isAdmin && !shouldBlockAdmin(blockAdminType ?? false, userType ?? ''))
      ) {
        return <Children {...props} />;
      }

      return null;
    },
    [Children, blockAdminType]
  );
};

export default checkPermissions;
