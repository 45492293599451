import axios from 'axios';
import { auth } from '../../firebase';

const apiUrl = process.env['NX_API_URL'];

export const postFolder = async (companyID: string, folderName: string) => {
  const response = await axios.post(
    `${apiUrl}/api/financialStatements`,
    { companyID, folderName },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const getFoldersSummary = async (
  companyID: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/financialStatements`, {
    params: { companyID },
    signal,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const getFolder = async (
  companyID: string,
  folderPath: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/financialStatements/${folderPath}`,
    {
      params: { companyID },
      signal,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const putFolder = async (
  companyID: string,
  folderID: string,
  folderName: string
) => {
  const response = await axios.put(
    `${apiUrl}/api/financialStatements/${folderID}`,
    { companyID, folderName },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const deleteFolder = async (companyID: string, folderID: string) => {
  const response = await axios.delete(
    `${apiUrl}/api/financialStatements/${folderID}?companyID=${companyID}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const postFile = async (
  companyID: string,
  folderID: string,
  formData: FormData
) => {
  formData.append('companyID', companyID);
  const response = await axios.post(
    `${apiUrl}/api/financialStatements/${folderID}/files`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const getFolderFiles = async (
  companyID: string,
  folderID: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/financialStatements/${folderID}/files`,
    {
      params: { companyID },
      signal,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const deleteFile = async (
  companyID: string,
  fileID: string,
  folderID: string
) => {
  const response = await axios.delete(
    `${apiUrl}/api/financialStatements/${folderID}/files/${fileID}?companyID=${companyID}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const downloadFile = async (
  companyID: string,
  fileID: string,
  folderID: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/financialStatements/${folderID}/files/${fileID}/download`,
    {
      params: { companyID },
      signal,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  return response;
};
