import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCompanyContext } from '../../../context/CompanyContext';
import { fetchARContractBillsAndReceipts } from '../../../services/AccountsReceivablesService';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import CBRTable from './CBRTable';
import { useFormContext } from 'react-hook-form';
import { ARFiltersArgs } from '../ARFilter/ARFilters';
import { checkObligatoryFilters } from '../ARFilter/ARFilterUtils';

type CBRTableContainerProps = {
  isDrilldown?: boolean;
};

const CBRTableContainer = React.forwardRef<
  HTMLDivElement,
  CBRTableContainerProps
>(({ isDrilldown }, ref) => {
  const { selectedCompany } = useCompanyContext();
  const { watch } = useFormContext<ARFiltersArgs>();
  const filters = watch();
  const isEnabled = selectedCompany && checkObligatoryFilters(filters);

  const { data, isFetching, isError } = useQuery({
    queryKey: ['getARContractBAR', selectedCompany, filters],
    queryFn: ({ signal }) => {
      const { reportType, ...otherFilters } = filters;
      return fetchARContractBillsAndReceipts(
        {
          company: selectedCompany!,
          ...otherFilters,
        },
        signal
      );
    },
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });

  if (!isEnabled) {
    return (
      <div className="mt-4">
        <ErrorMessage
          severity="info"
          content={
            'To start the search please select a: Customer, Contract, Job Name, or date.'
          }
        />
      </div>
    );
  }

  if (isFetching)
    return (
      <div className="text-center mx-auto mt-3">
        <ProgressSpinner />
      </div>
    );

  if (isError)
    return (
      <div className="mt-4">
        <ErrorMessage
          content={'Failed to obtain data! Please try again later.'}
        />
      </div>
    );

  return <CBRTable cbrTransactions={data} drilldownStyles={isDrilldown} />;
});

export default CBRTableContainer;
