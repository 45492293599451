import { Fieldset } from 'primereact/fieldset';
import { useEffect, useState } from 'react';
import { ACForm } from '../../../Interfaces/Accounting/ACForms.interfaces';
import SectionLink from './SectionLink';

type SectionProps = {
  sectionName: string;
  formList: ACForm[];
};

const Section = ({ sectionName, formList }: SectionProps) => {
  const [reports, setReports] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const list = formList.map((form) => {
      return <SectionLink key={form.label} form={form} />;
    });

    setReports(list);
  }, [formList]);

  return (
    <Fieldset legend={sectionName} className="text-standard h-full min-w-300px">
      <ul>{reports}</ul>
    </Fieldset>
  );
};

export default Section;
