import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useState } from 'react';
import ResumeCard from '../../../components/cards/ResumeCard';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import { ApiError } from '../../../Interfaces/Generic.interfaces';
import { fetchPRPeriodDates } from '../../../services/PayrollService';
import PRPeriod from './PRPeriod';

const PRPeriodContainer = React.forwardRef<HTMLDivElement>((props, ref) => {
  const { selectedCompany } = useCompanyContext();
  const [errorMessage, setErrorMessage] = useState('');
  const { data, isLoading, isError, isSuccess } = useQuery({
    queryKey: ['getPayrollPeriodDates', selectedCompany],
    queryFn: ({ signal }) => fetchPRPeriodDates(selectedCompany!.id, signal),
    onError: (error: ApiError) => {
      setErrorMessage(error?.response?.data?.message);
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  return (
    <div className="w-fit md:ml-auto">
      <ResumeCard>
        <div className="p-3 flex justify-content-center">
          {isLoading && <ProgressSpinner />}
          {isSuccess && <PRPeriod periodDates={data} />}
          {isError && (
            <ErrorMessage
              content={
                errorMessage || 'Failed to obtain data! Please try again later.'
              }
            />
          )}
        </div>
      </ResumeCard>
    </div>
  );
});

export default PRPeriodContainer;
