import { formatDate } from '../../../../utils/formatUtils';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { useCompanyContext } from '../../../context/CompanyContext';
import { fetchCashManagementAccounts } from '../../../services/CashManagementService';
import { useQuery } from '@tanstack/react-query';
import { CMAccount } from '../../../Interfaces/Accounting/CashManagement.interfaces';
import { CashManagementFilterFields } from '../CashManagementFilters';

const CMReportHeader = () => {
  const { selectedCompany } = useCompanyContext();
  const filters = useWatch<CashManagementFilterFields>();

  const { data } = useQuery({
    queryKey: ['getCashManagementAccounts', selectedCompany],
    queryFn: ({ signal }) =>
      fetchCashManagementAccounts(
        selectedCompany!.source,
        selectedCompany!.id,
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const account = data?.find((acc: CMAccount) => acc.id === filters.account);

  return (
    <div className="blackText printMedia printShow">
      <div className="text-30px text-center mt-5">{selectedCompany!.name}</div>
      <div className="text-28px text-center">Cash Management Report</div>
      <div className="text-17px ml-3 mt-2">
        <span className="font-bold text-20px">Search criteria:</span>
        <div className="ml-3">
          <div>
            <span className="font-bold mr-1">Bank account:</span>
            {account?.name}
          </div>
          {filters.dates && (
            <div>
              <span className="font-bold mr-1">Date range:</span>
              {formatDate(filters.dates[0])}
              {filters.dates[1] && ` - ${formatDate(filters.dates[1])}`}
            </div>
          )}
          {!filters.dates && (
            <div>
              <span className="font-bold mr-1">Month:</span>
              {new Date().toLocaleString('en-US', { month: 'long' })}
            </div>
          )}
          {filters.info && (
            <div>
              <span className="font-bold">Search term:</span> {filters.info}
            </div>
          )}
        </div>
      </div>
      <div className="ml-3 text-17px mb-5">
        <span className="font-bold mr-1">Created at:</span>
        {new Date().toLocaleString()}
      </div>
    </div>
  );
};

export default CMReportHeader;
