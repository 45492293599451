import React, { useRef } from 'react';
import Table from '../../../components/Table/Table';
import { Column } from 'primereact/column';
import {
  Payable,
  PayableApproval,
  PayableDetail,
} from '../../../Interfaces/Accounting/AccountsPayables.interfaces';
import { formatUTCDate } from '../../../../utils/formatUtils';
import APDrilldownHeaderInfo from './APDrilldownHeaderInfo';
import APDrilldownReport from '../APReport/APDrilldownReport';
import APDrilldownLineContent from './APDrilldownLineContent';
import APDrilldownPreviewAttachment from './APDrilldownPreviewAttachment';

type APDrilldownContentProps = {
  payable: Payable;
  invoices: PayableDetail[];
  approvals: PayableApproval[];
  uniqueAttchID?: string;
  extendedInfo?: boolean;
};

const APDrilldownContent = ({
  payable,
  invoices,
  approvals,
  uniqueAttchID,
  extendedInfo,
}: APDrilldownContentProps) => {
  const drilldownRef = useRef<HTMLDivElement | null>(null);

  const getPayableStatus = (invoices: PayableDetail[]) => {
    let status = 3;
    invoices.forEach((invoice) => {
      status = invoice.Status < status ? invoice.Status : status;
    });

    return status;
  };

  const buildInvoiceDetails = (invoices: PayableDetail[]) => {
    let line = invoices[0] ? invoices[0].Line : null;
    let tempInvoiceList: PayableDetail[] = [];
    const invoicesDividesByLine: PayableDetail[][] = [];

    invoices.forEach((invoice, idx) => {
      if (line !== invoice.Line) {
        invoicesDividesByLine.push(tempInvoiceList);
        line = invoice.Line;
        tempInvoiceList = [];
      }

      tempInvoiceList.push(invoice);

      if (idx + 1 === invoices.length) {
        invoicesDividesByLine.push(tempInvoiceList);
      }
    });

    const details = invoicesDividesByLine.map((invoicesList, idx) => {
      return (
        <div className="mt-6" key={idx + 1}>
          <APDrilldownLineContent
            invoicesList={invoicesList}
            lineNumber={idx + 1}
            extendedInfo={extendedInfo}
          />
        </div>
      );
    });

    return details;
  };

  return (
    <div
      id="ap-drilldown"
      ref={drilldownRef}
      className="blackText text-standard printBackground max-w-800px"
    >
      <div className="flex flex-column sm:row-gap-2 sm:flex-row flex-wrap align-items-center justify-content-center sm:justify-content-end">
        <div className="text-28px font-bold mx-auto sm:ml-4 drilldownHeader">
          AP Drilldown
        </div>
        <div className="sm:ml-auto mt-3 sm:mt-0 printHide">
          <APDrilldownPreviewAttachment
            uniqueAttchID={uniqueAttchID}
            zipFilename={payable.Reference}
          />
        </div>
        <div className="text-23px sm:ml-4 sm:mr-6 mt-2 sm:mt-0 cursor-pointer printHide">
          <APDrilldownReport elementRef={drilldownRef} />
        </div>
      </div>
      <div className="mt-5 ml-4">
        <APDrilldownHeaderInfo
          payable={payable}
          extendedDetails={extendedInfo}
          status={getPayableStatus(invoices)}
        />
      </div>
      <Table
        data={approvals}
        className="mt-5 noFooter standardHeader"
        stripedRows={false}
      >
        <Column
          field="Reviewer"
          header="Reviewer"
          headerClassName={`font-normal bg-white pb-0`}
          style={{ minWidth: '150px' }}
          className={`text-standard blackText tableCell justify-content-center`}
        />
        <Column
          field="DateApproved"
          header="Date Approved"
          body={(ap) => formatUTCDate(ap.DateApproved)}
          headerClassName={`font-normal bg-white pb-0`}
          style={{ minWidth: '150px' }}
          className={`text-standard blackText tableCell justify-content-center`}
        />
        <Column
          field="Memo"
          header="Memo"
          headerClassName={`font-normal bg-white pb-0`}
          style={{ minWidth: '150px' }}
          className={`text-standard blackText tableCell justify-content-center`}
        />
      </Table>
      <div className="mt-7 mx-4">{buildInvoiceDetails(invoices)}</div>
    </div>
  );
};

export default APDrilldownContent;
