import React from 'react';
import { PayableDetail } from '../../../Interfaces/Accounting/AccountsPayables.interfaces';
import { formatCurrency } from '../../../../utils/formatUtils';
import APDrilldownInvoiceDetails from './APDrilldownInvoiceDetails';

type APDrilldownLineContentProps = {
  invoicesList: PayableDetail[];
  extendedInfo?: boolean;
  lineNumber: number;
};

const APDrilldownLineContent = ({
  invoicesList,
  extendedInfo,
  lineNumber,
}: APDrilldownLineContentProps) => {
  let lineAmount = 0;
  invoicesList.forEach((invoice) => {
    lineAmount = lineAmount + invoice.Amount;
  });
  const invoice = invoicesList[0];
  return (
    <div>
      <div className="text-17px font-bold">
        Line {lineNumber}
        {invoicesList.length > 1 && ` - ${formatCurrency(lineAmount)}`}
      </div>
      <div className="mx-2 mt-2">
        <div className="flex flex-wrap column-gap-2">
          <span className="font-bold">Job:</span>
          {invoice.JobCode && (
            <React.Fragment>
              <span>{invoice.JobCode}</span>
              <span>{invoice.JobName}</span>
            </React.Fragment>
          )}
        </div>
        <div className="flex flex-wrap column-gap-2">
          <span className="font-bold">Phase:</span>
          {invoice.PhaseCode && (
            <React.Fragment>
              <span>{invoice.PhaseCode}</span>
              <span>{invoice.PhaseDescription}</span>
            </React.Fragment>
          )}
        </div>
        <div className="flex flex-wrap column-gap-2">
          <span className="font-bold">Cost Type:</span>
          {!!invoice.CostType && (
            <React.Fragment>
              <span>{invoice.CostType}</span>
              <span>{invoice.CostName}</span>
            </React.Fragment>
          )}
        </div>
        <div className="flex flex-wrap column-gap-2">
          <span className="font-bold">GL ACC:</span>
          <span>{invoice.Account}</span>
          <span>{invoice.AccountName}</span>
        </div>
        <div className="font-bold">Sequences:</div>
      </div>
      <div className="mx-3 w-fit">
        {invoicesList.map((invoice, jdx) => {
          return (
            <div className=" mt-2" key={`${lineNumber}-${jdx + 1}`}>
              {jdx !== 0 && (
                <div className="w-full mb-2 border-solid border-1 border-gray-300" />
              )}
              <APDrilldownInvoiceDetails
                invoice={invoice}
                extendedDetails={extendedInfo}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default APDrilldownLineContent;
