import React from 'react';
import { Payable } from '../../../Interfaces/Accounting/AccountsPayables.interfaces';
import { formatUTCDate, formatCurrency } from '../../../../utils/formatUtils';

const InvoiceStatus = {
  3: 'Paid',
  2: 'Partially Paid',
  1: 'Approved/Open',
};

type APDrilldownHeaderInfoProps = {
  payable: Payable;
  status: number;
  extendedDetails?: boolean;
  total?: number;
};

const APDrilldownHeaderInfo = ({
  payable,
  status,
  extendedDetails,
  total,
}: APDrilldownHeaderInfoProps) => {
  return (
    <div>
      <div className="flex column-gap-2 flex-wrap">
        <span className="font-bold">Vendor:</span>
        <span>{payable.Vendor}</span>
      </div>
      <div className="flex column-gap-2">
        <span className="font-bold">Inv. Number:</span>
        <span>{payable.Reference}</span>
      </div>
      <div className="flex column-gap-2">
        <span className="font-bold">Inv. Date:</span>
        <span>{formatUTCDate(payable.InvoiceDate)}</span>
      </div>
      <div className="flex column-gap-2 flex-wrap">
        <span className="font-bold">Description:</span>
        <span>{payable.Description}</span>
      </div>
      <div className="flex column-gap-2">
        <span className="font-bold">Due Date:</span>
        <span>{formatUTCDate(payable.DueDate ?? '')}</span>
      </div>
      {total && (
        <div className="flex column-gap-2">
          <span className="font-bold">Total:</span>
          <span>{formatCurrency(total)}</span>
        </div>
      )}
      {extendedDetails && (
        <React.Fragment>
          <div className="flex column-gap-2">
            <span className="font-bold">Gross:</span>
            <span>{formatCurrency(payable.Gross)}</span>
          </div>
          <div className="flex column-gap-2">
            <span className="font-bold">Amount:</span>
            <span>{formatCurrency(payable.PayableAmount ?? 0)}</span>
          </div>
          <div className="flex column-gap-2">
            <span className="font-bold">Tax:</span>
            <span>{formatCurrency(payable.Tax)}</span>
          </div>
          <div className="flex column-gap-2">
            <span className="font-bold">Discount:</span>
            <span>{formatCurrency(payable.DiscountOffered ?? 0)}</span>
          </div>
          <div className="flex column-gap-2">
            <span className="font-bold">Net Due:</span>
            <span>{formatCurrency(payable.NetAmount)}</span>
          </div>
          <div className="flex column-gap-2">
            <span className="font-bold">Status:</span>
            <span>{InvoiceStatus[status as keyof typeof InvoiceStatus]}</span>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default APDrilldownHeaderInfo;
