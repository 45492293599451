import axios from 'axios';
import { auth } from '../../firebase';

const apiUrl = process.env['NX_API_URL'];

export const fetchAttachmentFile = async (
  attachmentID: number,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/attachments/${attachmentID}`,
    {
      signal,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  return response;
};

export const fetchAttachmentFiles = async (
  attachmentIdList: number[],
  zipFilename: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/attachments/idList`, {
    signal,
    params: { attachmentIdList, zipFilename },
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  return response;
};

export type postGeneratePDFArgs = {
  html: string;
  format: string;
  isPdfPortrait: boolean;
  width?: number;
};

export const postGeneratePDF = async (
  body: postGeneratePDFArgs,
  signal?: AbortSignal
) => {
  const response = await axios.post(`${apiUrl}/api/attachments/pdf`, body, {
    signal,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
    responseType: 'blob',
  });

  const result = response.data;
  return result;
};

export const fetchAttachmentsIDs = async (
  companyID: string,
  uniqueAttchID: string
) => {
  const response = await axios.get(`${apiUrl}/api/attachments`, {
    params: { companyID, uniqueAttchID },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};
