import { downloadFileUsingPath } from '../../../../../utils/fileUtil';
import { Button } from 'primereact/button';

const POFileTemplate = () => {
  const onClick = () => {
    downloadFileUsingPath('/assets/templates/ACForms/PO Template.xlsx');
  };

  return (
    <Button
      className="w-16rem buttonSecondary border-0 flex py-3"
      onClick={onClick}
      type="button"
    >
      <span className="mx-auto text-17px text-white">Download PO Template</span>
    </Button>
  );
};

export default POFileTemplate;
