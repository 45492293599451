import { useQuery } from '@tanstack/react-query';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import DropdownStyled from '../../components/inputs/StyledInputs/DropdownStyled';
import { Controller, useFormContext } from 'react-hook-form';
import { useCompanyContext } from '../../context/CompanyContext';
import { DropdownChangeParams } from 'primereact/dropdown';
import CalendarStyled from '../../components/inputs/StyledInputs/CalendarStyled';
import { CMAccount } from '../../Interfaces/Accounting/CashManagement.interfaces';
import { CompanySource } from '../../Interfaces/User.interfaces';
import CalendarMonthStyled from '../../components/inputs/StyledInputs/CalendarMonthStyled';

export type CashManagementFilterFields = {
  account?: string;
  dates?: Date[];
  info?: string;
};

export type CashManagementFilterProps = {
  isError: boolean;
  isLoading: boolean;
  accounts: CMAccount[];
};

const CashManagementFilters = ({
  accounts,
  isError,
  isLoading,
}: CashManagementFilterProps) => {
  const { control } = useFormContext();
  const { selectedCompany } = useCompanyContext();

  return (
    <div className="flex flex-wrap justify-content-center md:justify-content-start gap-4">
      <div className="w-16rem">
        <div className="text-standard mb-2">Account</div>
        <Controller
          control={control}
          name="account"
          render={({ field }) => (
            <DropdownStyled
              isDisabled={isError}
              options={accounts}
              labelField="name"
              valueField="id"
              isLoading={isLoading}
              placeholder={
                isError ? 'Failed to load accounts!' : 'Select account'
              }
              clearable={false}
              {...field}
              {...{
                onChange: (e: DropdownChangeParams) => {
                  field.onChange(e.target.value);
                },
              }}
            />
          )}
        />
      </div>

      <div className="w-16rem">
        <div className="text-standard mb-2">Activity</div>
        <Controller
          control={control}
          defaultValue=""
          name="dates"
          render={({ field }) =>
            selectedCompany?.source === CompanySource.ViewPoint ? (
              <CalendarStyled
                selectionMode="range"
                placeholder="Select Date Range"
                readOnlyInput
                showButtonBar
                {...field}
              />
            ) : (
              <CalendarMonthStyled
                selectionMode="range"
                placeholder="Select Month Range"
                readOnlyInput
                showButtonBar
                {...field}
              />
            )
          }
        />
      </div>
      {selectedCompany!.source != CompanySource.Yardi && (
        <div className="w-16rem align-self-end">
          <Controller
            control={control}
            defaultValue=""
            name="info"
            render={({ field }) => (
              <span className="p-input-icon-right">
                <InputText
                  type="text"
                  placeholder="Search"
                  className="fieldBorder w-full text-standard blackText"
                  {...field}
                />
                <i className="pi pi-search" />
              </span>
            )}
          />
        </div>
      )}
    </div>
  );
};

export default CashManagementFilters;
