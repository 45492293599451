import React, { Component, ErrorInfo, ReactNode } from 'react';
import ErrorMessage from '../components/messages/ErrorMessage';

interface Props {
  children?: ReactNode;
  fallBack?: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public override state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true };
  }

  public override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public override render() {
    if (this.state.hasError) {
      return (
        this.props.fallBack ?? (
          <ErrorMessage
            content={'Something went wrong! Please try again later.'}
          />
        )
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
