import React from 'react';
import ReportButton from '../../../components/report/ReportButton';
import * as XLSX from 'xlsx';
import { useCompanyContext } from '../../../context/CompanyContext';
import { useWatch } from 'react-hook-form';
import { BASE_PDF_STYLES } from '../../../../utils/reportUtils';
import { PRTimesheetFilters } from './TimesheetFilters';
import { getSubmittedDraft } from './util';
import { useQuery } from '@tanstack/react-query';
import { fetchPRTimesheets } from '../../../services/PayrollService';
import {
  excelCleanTable,
  excelFormatNestedRows,
} from 'apps/tmr-frontend/src/utils/excelUtils';
import { formatUTCDate } from 'apps/tmr-frontend/src/utils/formatUtils';

type SCReportProps = {
  elementRef: React.MutableRefObject<HTMLElement | null>;
  periodStart: Date;
  periodEnd: Date;
};

const TimesheetReport = ({
  elementRef,
  periodStart,
  periodEnd,
}: SCReportProps) => {
  const { selectedCompany } = useCompanyContext();
  const filters = useWatch<PRTimesheetFilters>();
  const reportDate = new Date();

  const submittedTimesheets = useQuery({
    queryKey: ['getSubmittedPRTimesheets', selectedCompany],
    queryFn: ({ signal }) =>
      fetchPRTimesheets(
        {
          companyID: selectedCompany!.id,
          isSubmitted: true,
          isApproved: false,
        },
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const excelElementPreProcess = (element: HTMLElement) => {
    const tableBody = element.getElementsByClassName('p-datatable-tbody')[0];
    excelCleanTable(element);
    excelFormatNestedRows(tableBody as HTMLElement);

    const rows = tableBody.getElementsByTagName('tr');
    Array.from(rows).forEach((row) => {
      const cells = row.getElementsByTagName('td');
      const isEmptyRow = Array.from(cells).every(
        (cell) =>
          !cell.textContent ||
          cell.textContent.trim() === '' ||
          cell.textContent.trim() === 'details' ||
          cell.textContent.trim() === 'dateDetails'
      );

      if (isEmptyRow) {
        row.remove();
      }
    });

    return element;
  };

  const nestedRowsBeforeDownload = (sheet: XLSX.WorkSheet) => {
    const rows = sheet['!rows'];
    const ref = sheet['!ref'];
    const boundary = (ref ?? '').replace(/[A-Z]/g, '').split(':');
    const firstRow = parseInt(boundary[0]);
    const lastRow = parseInt(boundary[1]);

    for (let i = firstRow; i < lastRow; i++) {
      const cellID = sheet[`A${i}`];
      const cellName = sheet[`B${i}`];

      if (cellID && !cellID?.v && cellName && cellName?.v === 'details') {
        sheet[`B${i}`] = { t: 's', v: '' };
        rows?.push({ level: 1 });
      } else if (
        cellID &&
        !cellID?.v &&
        cellName &&
        cellName?.v === 'dateDetails'
      ) {
        sheet[`B${i}`] = { t: 's', v: '' };
        rows?.push({ level: 2 });
      } else {
        rows?.push({ level: 0 });
      }
    }
  };

  const excelBeforeDownload = (workBook: XLSX.WorkBook) => {
    const sheet = workBook.Sheets['Sheet1'];
    const companyName = selectedCompany!.name;

    sheet['C2'] = { t: 's', v: companyName };
    sheet['C3'] = { t: 's', v: 'Payroll Timesheet' };

    sheet[`A6`] = { t: 's', v: 'Pay Period Start Date:' };
    sheet[`B6`] = {
      t: 's',
      v: formatUTCDate(periodStart),
    };

    sheet[`A7`] = { t: 's', v: 'Pay Period End Date:' };
    sheet[`B7`] = {
      t: 's',
      v: formatUTCDate(periodEnd),
    };

    let row = 8;

    if (filters.submittedID) {
      sheet[`A${row}`] = { t: 's', v: 'Submitted Draft:' };
      sheet[`B${row}`] = {
        t: 's',
        v: getSubmittedDraft(filters.submittedID, submittedTimesheets.data),
      };

      row += 1;
    }

    sheet[`A${row}`] = { t: 's', v: 'Created at:' };
    sheet[`B${row}`] = { t: 's', v: reportDate.toLocaleString() };

    nestedRowsBeforeDownload(sheet);
  };

  return (
    <ReportButton
      elementToPrintRef={elementRef}
      fileName="Payroll_Timesheet"
      pdfStyles={BASE_PDF_STYLES}
      excelPreprocess={excelElementPreProcess}
      excelBeforeDownload={excelBeforeDownload}
      tableOriginCell="A12"
      widthElementID="pr-timesheet"
    />
  );
};

export default TimesheetReport;
