import React, { useCallback } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { formatCurrency, formatUTCDate } from '../../../../utils/formatUtils';
import { ARReceipt } from '../../../Interfaces/Accounting/AccountsReceivables.interfaces';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';

type ARReceiptTableProps = {
  arReceipts: ARReceipt[];
};

const ARReceiptTable = React.forwardRef<HTMLDivElement, ARReceiptTableProps>(
  ({ arReceipts }, ref) => {
    const calcHeight = (rows: number) => {
      const headerHeight = 49;
      const footerHeight = 0;
      const rowHeight = 31;
      return headerHeight + footerHeight + rows * rowHeight + 3;
    };

    const onRowHover = (e: DataTableRowMouseEventParams) => {
      const cell = e.originalEvent.target as HTMLElement;
      const row = cell.closest('tr') as HTMLElement;
      animateCellText(row);
    };

    return (
      <Table
        id="ar-receipt-table"
        ref={ref}
        data={arReceipts}
        className={`mx-3 dashboardOptionShadow tableFirstLineStyled dobleHeader noFooter`}
        calcHeight={useCallback(calcHeight, [])}
        onRowMouseEnter={useCallback(onRowHover, [])}
        hideColumns={true}
      >
        <Column
          field="Customer"
          header="Customer"
          body={(ar) => {
            return <div className="scroll-text">{ar.Customer}</div>;
          }}
          headerClassName={`tableHeader font-normal justify-content-center`}
          style={{ minWidth: '250px', maxWidth: '250px' }}
          className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="Contract"
          header="Contract #"
          style={{ minWidth: '110px' }}
          headerClassName="tableHeader font-normal"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="ContractName"
          header="Contract Name"
          body={(ar) => {
            return <div className="scroll-text">{ar.ContractName}</div>;
          }}
          style={{ minWidth: '240px', maxWidth: '240px' }}
          headerClassName="tableHeader font-normal justify-content-center"
          className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="InvoiceNumber"
          header="Invoice"
          style={{ minWidth: '100px' }}
          headerClassName="tableHeader font-normal"
          className=" text-standard blackText tableCell title justify-content-center"
          footerClassName={`tableFooter`}
          sortable
        />
        <Column
          field="TransDate"
          header="Transaction Date"
          style={{ minWidth: '125px' }}
          body={(ar) => formatUTCDate(ar.TransDate)}
          headerClassName="tableHeader font-normal text-center"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="Deposit"
          header="Deposit #"
          style={{ minWidth: '125px' }}
          headerClassName="tableHeader font-normal"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="CheckNo"
          header="Check #"
          style={{ minWidth: '110px' }}
          headerClassName="tableHeader font-normal"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="CreditAmt"
          header="Check Amount"
          body={(ar) => formatCurrency(ar.CreditAmt)}
          style={{ minWidth: '150px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="justify-content-end text-standard blackText tableCell"
          sortable
        />
        <Column
          field="GLAccount"
          header="GL Account"
          style={{ minWidth: '120px', maxWidth: '120px' }}
          headerClassName="tableHeader font-normal text-center"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="OnAccount"
          header="On Account"
          body={(ar) => (ar.OnAccount ? formatCurrency(ar.OnAccount) : '')}
          style={{ minWidth: '130px' }}
          headerClassName="tableHeader font-normal text-center"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="Receipt"
          header="Receipt Amount"
          body={(ar) => formatCurrency(ar.Receipt)}
          style={{ minWidth: '150px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          sortable
        />
        <Column
          field="ClearDate"
          header="Date Cleared"
          style={{ minWidth: '110px' }}
          body={(ar) => formatUTCDate(ar.ClearDate)}
          headerClassName="tableHeader font-normal text-center"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
      </Table>
    );
  }
);

const transactionsAreEqual = (
  prevTransactions: Readonly<ARReceiptTableProps>,
  nextTransactions: Readonly<ARReceiptTableProps>
) => {
  return prevTransactions.arReceipts === nextTransactions.arReceipts;
};

export default React.memo(ARReceiptTable, transactionsAreEqual);
