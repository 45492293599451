import React, { useCallback, useState } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import { formatCurrency, formatUTCDate } from '../../../../utils/formatUtils';
import { SCPayment } from '../../../Interfaces/Accounting/Subcontracts.interfaces';
import SCPaymentDrilldownContainer from '../SCPaymentDrilldown/SCPaymentDrilldownContainer';
import WrapperButton from '../../../components/buttons/WrapperButton';

type SCLatestPaymentsTableProps = {
  payments: SCPayment[];
};

const SCLatestPaymentsTable = React.forwardRef<
  HTMLDivElement,
  SCLatestPaymentsTableProps
>(({ payments }, ref) => {
  const [showDrilldown, setShowDrilldown] = useState(false);
  const [payment, setPayment] = useState<SCPayment>();

  const calcHeight = (rows: number) => {
    const headerHeight = 32;
    const footerHeight = 0;
    const rowHeight = 31;
    return headerHeight + footerHeight + rows * rowHeight + 3;
  };

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  const onDrilldownCellClick = (scPayment: SCPayment) => {
    setPayment(scPayment);
    setShowDrilldown(true);
  };

  const hideDrilldown = () => {
    setPayment(undefined);
    setShowDrilldown(false);
  };

  return (
    <div className="pdfDivFitContent">
      {payment && (
        <SCPaymentDrilldownContainer
          visible={showDrilldown}
          hideDrilldown={hideDrilldown}
          scpayment={payment}
        />
      )}
      <Table
        id="sc-latest-payments"
        ref={ref}
        data={payments}
        className={`mx-3 dashboardOptionShadow noFooter`}
        calcHeight={useCallback(calcHeight, [])}
        onRowMouseEnter={useCallback(onRowHover, [])}
        hideColumns={true}
      >
        <Column
          headerClassName={`tableHeader`}
          className="tableCell p-0 printHide"
          footerClassName="tableFooter "
        />
        <Column
          field="SL"
          header="SL"
          body={(sc) => {
            return <div className="scroll-text">{sc.SL}</div>;
          }}
          style={{ minWidth: '200px', maxWidth: '200px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className=" text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="Job"
          header="Job"
          style={{ minWidth: '90px' }}
          headerClassName="tableHeader font-normal"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="Vendor"
          header="Vendor"
          style={{ minWidth: '90px' }}
          headerClassName="tableHeader font-normal"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="VendorName"
          header="Name"
          body={(sc) => {
            return <div className="scroll-text">{sc.VendorName}</div>;
          }}
          style={{ minWidth: '200px', maxWidth: '200px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className=" text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="VendorSortName"
          header="SortName"
          body={(sc) => {
            return <div className="scroll-text">{sc.VendorSortName}</div>;
          }}
          style={{ minWidth: '160px', maxWidth: '160px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="APRef"
          header="Inv. Number"
          style={{ minWidth: '150px' }}
          headerClassName="tableHeader font-normal"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="PaidDate"
          header="Paid Date"
          body={(sc) => formatUTCDate(sc.PaidDate)}
          style={{ minWidth: '110px' }}
          headerClassName="tableHeader font-normal"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="PaidAmount"
          header="Paid Amount"
          body={(sc) => (
            <WrapperButton
              className="totalColor w-full text-right printColor"
              onClick={() => onDrilldownCellClick(sc)}
            >
              {formatCurrency(sc.PaidAmount)}
            </WrapperButton>
          )}
          style={{ minWidth: '140px' }}
          headerClassName="tableHeader font-normal text-center"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          headerClassName={`tableHeader`}
          className="tableCell p-0 printHide"
          footerClassName="tableFooter "
        />
      </Table>
    </div>
  );
});

const transactionsAreEqual = (
  prevTransactions: Readonly<SCLatestPaymentsTableProps>,
  nextTransactions: Readonly<SCLatestPaymentsTableProps>
) => {
  return prevTransactions.payments === nextTransactions.payments;
};

export default React.memo(SCLatestPaymentsTable, transactionsAreEqual);
