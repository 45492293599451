import { BASE_PDF_STYLES } from '../../../../utils/reportUtils';
import { useRef } from 'react';
import ReportButton from '../../../components/report/ReportButton';
import { JobPhasesCostCode } from './JobPhasesForm';
import FormInfo from '../../../components/messages/FormInfo';

type JobPhasesMessageProps = {
  job: string;
  jobName: string;
  costCodes: JobPhasesCostCode[];
};

const JobPhasesMessage = ({
  job,
  jobName,
  costCodes,
}: JobPhasesMessageProps) => {
  const summaryRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      id="job-phases-success"
      ref={summaryRef}
      className="mt-3 mx-5 w-full max-w-800px"
    >
      <div className="text-30px font-bold flex justify-content-between align-items-end mb-5 gap-2">
        Job Phase Codes Summary:
        <div className="text-23px cursor-pointer printHide h-fit">
          <ReportButton
            elementToPrintRef={summaryRef}
            fileName="Job_Phases_Form"
            pdfStyles={BASE_PDF_STYLES}
            noButton={true}
            hideExcel={true}
            isPdfPortrait={true}
            widthElementID="job-phases-success"
            minWidth={800}
          />
        </div>
      </div>
      <div>The form was uploaded successfully with the following info:</div>
      <div className="grid mt-2">
        <div className="col-12 sm:col-6">
          <FormInfo label="Job #" content={job} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Job Name" content={jobName} />
        </div>
        <div className="col-12 mt-1">
          <div className="grid ml-2 font-bold pb-0 hidden sm:flex text-center">
            <div className="col-4 pt-0 word-break-word">Cost Code</div>
            <div className="col-4 pt-0">Description</div>
            <div className="col-4 pt-0 word-break-word">Extra/Location</div>
          </div>
          {costCodes.map((costCode) => {
            return (
              <div className="grid ml-2 mb-3">
                <div className="col-12 pb-0 sm:col-4 word-break-word sm:text-center">
                  <span className="font-bold mr-1 sm:hidden">Cost Code:</span>
                  {costCode.costCode}
                </div>
                <div className="col-12 pb-0 sm:col-4 word-break-word">
                  <span className="font-bold mr-1 sm:hidden ">
                    Description:
                  </span>
                  {costCode.description}
                </div>
                <div className="col-12 pb-0 sm:col-4 sm:text-center">
                  <span className="font-bold mr-1 sm:hidden">
                    Extra/Location:
                  </span>
                  {costCode.extra}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default JobPhasesMessage;
