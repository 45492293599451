type FileIconProps = {
  mimeType: string;
};

const FileIcon = ({ mimeType }: FileIconProps) => {
  let className = 'text-70px m-auto pi';

  if (mimeType.includes('image')) {
    className = `${className} pi-image text-cyan-600`;
  } else if (mimeType.includes('pdf')) {
    className = `${className} pi-file-pdf text-red-600`;
  } else if (
    mimeType.includes('excel') ||
    mimeType.includes('spreadsheetml.sheet')
  ) {
    className = `${className} pi-file-excel text-green-600`;
  } else if (
    mimeType.includes('msword') ||
    mimeType.includes('wordprocessingml')
  ) {
    className = `${className} pi-file-word text-blue-600`;
  } else {
    className = `${className} pi-file`;
  }

  return <i className={className} />;
};

export default FileIcon;
