import { formatCurrency } from 'apps/tmr-frontend/src/utils/formatUtils';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import Table from '../../../components/Table/Table';
import { PayrollTimesheetDetail } from '../../../Interfaces/Accounting/ACForms.interfaces';
import JobInput from './JobInput';
import PhaseCodeInput from './PhaseCodeInput';

type TimesheetDateDetailsTableProps = {
  dateDetails: PayrollTimesheetDetail[];
  empID: string;
  detailID: string;
  changeTimesheetValue: (
    empID: string,
    key: keyof PayrollTimesheetDetail,
    value?: number,
    detailID?: string,
    dateDetailID?: string
  ) => void;
  addDateDetailRow: (empID: string, detailID?: string) => void;
  removeDetailRow: (
    empID: string,
    detailID: string,
    dateDetailID?: string
  ) => void;
};

const TimesheetDateDetailsTable = ({
  dateDetails,
  empID,
  detailID,
  changeTimesheetValue,
  addDateDetailRow,
  removeDetailRow,
}: TimesheetDateDetailsTableProps) => {
  return (
    <Table
      data={dateDetails ?? []}
      dataKey="id"
      className={`nestedExpandedTable noFooter w-full`}
      rowClassName={() => 'nestedExpandedTableCell'}
      scrollable={false}
      scrollHeight={undefined}
    >
      <Column
        style={{ minWidth: '100px' }}
        headerClassName="hidden"
        className="justify-content-center text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        body={() => {
          return <span className="visibility-hidden">dateDetails</span>;
        }}
        style={{ minWidth: '180px' }}
        headerClassName="hidden"
        className=" text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        style={{ minWidth: '160px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        style={{ minWidth: '100px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        style={{ minWidth: '120px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        body={(record) => {
          return (
            <span className="text-center w-full">
              <InputNumber
                className="printHide"
                inputClassName="w-full blackText text-standard text-center"
                value={record.regularHrs}
                onBlur={(event) => {
                  changeTimesheetValue(
                    empID,
                    'regularHrs',
                    parseInt(event.target.value?.replace(/,/g, '')) ??
                      undefined,
                    detailID,
                    record.id
                  );
                }}
              />
              <span className="printMedia printShow">{record.regularHrs}</span>
            </span>
          );
        }}
        style={{ minWidth: '100px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        body={(record) => {
          return (
            <span className="text-center w-full">
              <InputNumber
                className="printHide"
                inputClassName="w-full blackText text-standard text-center"
                value={record.overTimeHrs}
                onBlur={(event) => {
                  changeTimesheetValue(
                    empID,
                    'overTimeHrs',
                    parseInt(event.target.value?.replace(/,/g, '')) ??
                      undefined,
                    detailID,
                    record.id
                  );
                }}
              />
              <span className="printMedia printShow">{record.overTimeHrs}</span>
            </span>
          );
        }}
        style={{ minWidth: '100px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        body={(record) => {
          return (
            <span className="text-center w-full">
              <InputNumber
                className="printHide"
                inputClassName="w-full blackText text-standard text-center"
                value={record.sickHrs}
                onBlur={(event) => {
                  changeTimesheetValue(
                    empID,
                    'sickHrs',
                    parseInt(event.target.value?.replace(/,/g, '')) ??
                      undefined,
                    detailID,
                    record.id
                  );
                }}
              />
              <span className="printMedia printShow">{record.sickHrs}</span>
            </span>
          );
        }}
        style={{ minWidth: '100px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        body={(record) => {
          return (
            <span className="text-center w-full">
              <InputNumber
                className="printHide"
                inputClassName="w-full blackText text-standard text-center"
                value={record.holidayHrs}
                onBlur={(event) => {
                  changeTimesheetValue(
                    empID,
                    'holidayHrs',
                    parseInt(event.target.value?.replace(/,/g, '')) ??
                      undefined,
                    detailID,
                    record.id
                  );
                }}
              />
              <span className="printMedia printShow">{record.holidayHrs}</span>
            </span>
          );
        }}
        style={{ minWidth: '100px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        body={(record) => {
          return (
            <span className="text-right w-full">
              <InputNumber
                className="printHide"
                inputClassName="w-full blackText text-standard text-right"
                min={0}
                mode="currency"
                currency="USD"
                locale="en-US"
                value={record.perDiem}
                onBlur={(event) => {
                  changeTimesheetValue(
                    empID,
                    'perDiem',
                    parseFloat(
                      event.target.value?.replace('$', '').replace(/,/g, '')
                    ) ?? undefined,
                    detailID,
                    record.id
                  );
                }}
              />
              <span className="printMedia printShow">
                {formatCurrency(record.perDiem)}
              </span>
            </span>
          );
        }}
        style={{ minWidth: '100px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        body={(record) => {
          return (
            <JobInput
              value={record.job}
              onChange={(event) => {
                changeTimesheetValue(
                  empID,
                  'job',
                  event.value,
                  detailID,
                  record.id
                );

                if (record.phaseCode) {
                  changeTimesheetValue(
                    empID,
                    'phaseCode',
                    undefined,
                    detailID,
                    record.id
                  );
                }
              }}
            />
          );
        }}
        style={{ minWidth: '200px', maxWidth: '200px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        body={(record) => {
          if (!record.job || record.job === 'N/A') {
            return;
          }

          return (
            <PhaseCodeInput
              value={record.phaseCode}
              onChange={(event) => {
                changeTimesheetValue(
                  empID,
                  'phaseCode',
                  event.value,
                  detailID,
                  record.id
                );
              }}
              job={record.job}
            />
          );
        }}
        style={{ minWidth: '200px', maxWidth: '200px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        body={(record, options) => {
          if (options.rowIndex + 1 === dateDetails?.length) {
            return (
              <i
                className="pi pi-plus text-17px cursor-pointer"
                onClick={() => {
                  addDateDetailRow(empID, detailID);
                }}
              />
            );
          }

          return;
        }}
        style={{ maxWidth: '30px' }}
        headerClassName={`hidden`}
        className={`text-standard blackText printHide tableCell justify-content-center`}
        footerClassName="tableFooter"
      />
      <Column
        body={(record) => {
          if (dateDetails?.length !== 1) {
            return (
              <i
                className="pi pi-trash text-17px cursor-pointer"
                onClick={() => {
                  removeDetailRow(empID, detailID, record.id);
                }}
              />
            );
          }

          return;
        }}
        style={{ maxWidth: '30px' }}
        headerClassName={`hidden`}
        className={`text-standard blackText printHide tableCell justify-content-center`}
        footerClassName="tableFooter"
      />
    </Table>
  );
};

export default TimesheetDateDetailsTable;
