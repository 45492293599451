import {
  ConfirmDialog,
  confirmDialog,
  ConfirmDialogOptions,
} from 'primereact/confirmdialog';
import React from 'react';

type ConfirmationDialogProps = {
  Button: React.ReactElement;
  message?:
    | React.ReactNode
    | ((options: ConfirmDialogOptions) => React.ReactNode);
  header?: string;
  icon?: string;
  onConfirm: () => void;
  rejectClassName?: string;
  acceptClassName?: string;
  tagKey?: string;
  contentClassName?: string;
  visibility?: boolean;
  onHide?: () => void;
};

const ConfirmationDialog = ({
  Button,
  message,
  header,
  icon,
  onConfirm,
  rejectClassName,
  acceptClassName,
  tagKey,
  contentClassName,
  visibility,
  onHide,
}: ConfirmationDialogProps) => {
  const options = {
    message: message ?? 'Are you sure you want to proceed?',
    header: header ?? 'Confirmation',
    icon: icon ?? 'pi pi-exclamation-triangle errorText',
    accept: onConfirm,
    className: 'whiteSpace-preLine text-standard',
    rejectClassName:
      rejectClassName ??
      'blackText transparent border-none hover:bg-gray-200 active:bg-gray-200 active:text-black-alpha-90 hover:text-black-alpha-90',
    acceptClassName: acceptClassName ?? 'bluwaiBlue border-none',
    contentClassName,
    tagKey,
  };

  const showConfirmDialog = () => {
    confirmDialog(options);
  };

  if (visibility !== undefined) {
    return (
      <React.Fragment>
        <ConfirmDialog visible={visibility} onHide={onHide} {...options} />
        {Button}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <ConfirmDialog tagKey={tagKey} />
      {React.cloneElement(Button, {
        onClick: showConfirmDialog,
      })}
    </React.Fragment>
  );
};

export default ConfirmationDialog;
