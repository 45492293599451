type JCProjectedCostDateProps = {
  date: Date;
};

const JCProjectedCostDate = ({ date }: JCProjectedCostDateProps) => {
  return (
    <div className="text-18px ml-3 flex mt-4">
      <div className="mr-1 font-bold">Last Projection Cost Month:</div>
      <div id="jc-last-protection-date" className="flex gap-1 ml-1">
        <div>
          <span className="mr-1">
            {date.toLocaleString('en-US', {
              month: 'long',
            })}
          </span>
          - {date.getUTCFullYear()}
        </div>
      </div>
    </div>
  );
};

export default JCProjectedCostDate;
