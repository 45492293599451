import { BACKGROUND_COLORS, BORDER_COLORS } from '../../../utils/graphConst';

type ColorIndicatorProps = {
  colorIndex: number;
};

const ColorIndicator = ({ colorIndex }: ColorIndicatorProps) => {
  return (
    <div
      style={{
        backgroundColor:
          BACKGROUND_COLORS[colorIndex % BACKGROUND_COLORS.length],
        borderColor: BORDER_COLORS[colorIndex % BORDER_COLORS.length],
      }}
      className="w-1rem h-1rem border-1 border-solid"
    />
  );
};

export default ColorIndicator;
