import axios from 'axios';
import { auth } from '../../firebase';
import { cleanJsonEmptyValues } from '../../utils/formatUtils';
import { DraftCosts } from '../Interfaces/Accounting/JobsContracts.interface';

const apiUrl = process.env['NX_API_URL'];

type JCFilters = {
  companyID: string;
  job?: string;
  info?: string;
  endDate?: Date;
  jobStatus?: string;
};

export const fecthJCCostRevenue = async (
  params: JCFilters,
  signal?: AbortSignal
) => {
  const cleanedParams = cleanJsonEmptyValues(params);

  const response = await axios.get(`${apiUrl}/api/jobsContracts/costRevenue`, {
    signal,
    params: cleanedParams,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

type fecthJCCostDetailArgs = {
  companyID: string;
  contract?: string;
  job?: string;
  costType?: number;
  startDate?: Date;
  endDate?: Date;
  info?: string;
  dateType?: string;
  phases?: string[];
};

export const fecthJCCostDetail = async (
  params: fecthJCCostDetailArgs,
  signal?: AbortSignal
) => {
  const cleanedParams = cleanJsonEmptyValues(params);

  const response = await axios.get(`${apiUrl}/api/jobsContracts/costDetail`, {
    signal,
    params: cleanedParams,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const fecthJCProjectedCost = async (
  companyID: string,
  job: string,
  edit?: boolean,
  draftID?: string,
  extraDetails?: boolean,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/jobsContracts/projectedJobCost`,
    {
      signal,
      params: { companyID, job, edit, draftID, extraDetails },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const fecthJCCommittedCost = async (
  companyID: string,
  job?: string,
  phase?: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/jobsContracts/committedCosts`,
    {
      signal,
      params: { companyID, job, phase },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const fecthJCCostType = async (
  companyID: string,
  costType: number,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/jobsContracts/costType`, {
    signal,
    params: { companyID, costType },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const fetchJCResume = async (
  companyID: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/jobsContracts`, {
    signal,
    params: { companyID },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const fetchJCContractAnalysis = async (
  companyID: string,
  contractState: string,
  contracts?: string[],
  pms?: number[],
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/jobsContracts/contractAnalysis`,
    {
      signal,
      params: { companyID, contractState, contracts, pms },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const fecthJCCostEstimate = async (
  params: JCFilters,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/jobsContracts/costEstimates`,
    {
      signal,
      params,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const fecthJCCostToDate = async (
  params: JCFilters,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/jobsContracts/costToDate`, {
    signal,
    params,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

type JCJobPhasesFilters = {
  companyID: string;
  job?: string;
  hasCost?: boolean;
  costTypes?: number[];
  info?: string;
};

export const fetchJCJobPhases = async (
  params: JCJobPhasesFilters,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/jobsContracts/jobPhases`, {
    signal,
    params: cleanJsonEmptyValues(params),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const fetchJCJobPhaseEstimates = async (
  companyID: string,
  job: string,
  phases?: string[],
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/jobsContracts/phaseEstimates`,
    {
      signal,
      params: cleanJsonEmptyValues({ companyID, job, phases }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const fetchJCJobProjectManagers = async (
  companyID: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/jobsContracts/projectManagers`,
    {
      signal,
      params: { companyID },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const fetchJCBacklog = async (
  companyID: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/jobsContracts/backlogTrend`, {
    signal,
    params: { companyID },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

type JCJobFilters = {
  companyID: string;
  contract?: string;
  status?: number[];
};

export const fecthJobs = async (params: JCJobFilters, signal?: AbortSignal) => {
  const response = await axios.get(`${apiUrl}/api/jobsContracts/jobs`, {
    signal,
    params,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

type ProjectedCostDraftArgs = {
  companyID: string;
  jobNumber: string;
  costs: DraftCosts;
};

export const postProjectedCostDraft = async (body: ProjectedCostDraftArgs) => {
  const response = await axios.post(
    `${apiUrl}/api/jobsContracts/projectedJobCost`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const putProjectedCostDraft = async (
  draftID: string,
  costs: DraftCosts,
  fromApproval: boolean
) => {
  const response = await axios.put(
    `${apiUrl}/api/jobsContracts/projectedJobCost/${draftID}`,
    { costs, fromApproval },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const putSubmitProjectedCostDraft = async (draftID: string) => {
  const response = await axios.put(
    `${apiUrl}/api/jobsContracts/projectedJobCost/submit/${draftID}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const fetchSubmittedProjectedCosts = async (
  companyID: string,
  job: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/jobsContracts/projectedJobCost/submit`,
    {
      signal,
      params: { companyID, job },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const putApproveProjectedCostDraft = async (draftID: string) => {
  const response = await axios.put(
    `${apiUrl}/api/jobsContracts/projectedJobCost/approve/${draftID}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  const result = response.data;
  return result;
};

export const putRejectProjectedCostDraft = async (
  draftID: string,
  reason: string
) => {
  const response = await axios.put(
    `${apiUrl}/api/jobsContracts/projectedJobCost/reject/${draftID}`,
    { reason },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  const result = response.data;
  return result;
};

export const fecthJCPhaseMaster = async (
  companyID: string,
  info?: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/jobsContracts/phaseMaster`, {
    signal,
    params: { companyID, info },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const fecthCostTypes = async (
  companyID: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/jobsContracts/costTypes`, {
    signal,
    params: { companyID },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};
