import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import WrapperButton from '../../../components/buttons/WrapperButton';
import FormControlledDropdown from '../../../components/inputs/FormInputs/FormControlledDropdown';
import FormControlledInput from '../../../components/inputs/FormInputs/FormControlledInput';
import FormControlledNumberInput from '../../../components/inputs/FormInputs/FormControlledNumberInput';
import { useCompanyContext } from '../../../context/CompanyContext';
import {
  SLForm,
  SLItem,
  SLItems,
  UnitOfMeasure,
} from '../../../Interfaces/Accounting/ACForms.interfaces';
import { JCPhase } from '../../../Interfaces/Accounting/JobsContracts.interface';
import { fetchUnitsOfMeasure } from '../../../services/ACFormsService';
import { fetchJCJobPhases } from '../../../services/JobsContractsService';
import { UM_ONLY_TOTAL } from '../formConsts';

type SLItemRowProps = {
  index: number;
  addNewItem: (index: number) => void;
  removeItem: (index: number) => void;
};

const SLItemRow = ({ index, addNewItem, removeItem }: SLItemRowProps) => {
  const { selectedCompany } = useCompanyContext();
  const { setValue, resetField } = useFormContext<SLItems>();
  const [job, units, price, uom, phase] = useWatch({
    name: [
      'job',
      `slItems.${index}.units`,
      `slItems.${index}.price`,
      `slItems.${index}.um`,
      `slItems.${index}.phase`,
    ],
  });
  const [onlyTotal, setOnlyTotal] = useState(false);
  const slItems = useWatch({
    name: `slItems`,
  }) as SLItem[];

  const um = useQuery({
    queryKey: ['getUnitOfMeasure'],
    queryFn: ({ signal }) => {
      return fetchUnitsOfMeasure(signal);
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const phases = useQuery({
    queryKey: ['getJobPhases', selectedCompany, job],
    queryFn: ({ signal }) =>
      fetchJCJobPhases({ companyID: selectedCompany!.id, job }, signal),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(() => {
    if (UM_ONLY_TOTAL.includes(uom)) {
      setValue(`slItems.${index}.units`, 0);
      setValue(`slItems.${index}.price`, 0);
      setOnlyTotal(true);
    } else {
      setOnlyTotal(false);
    }
  }, [uom, index]);

  useEffect(() => {
    if (!UM_ONLY_TOTAL.includes(uom)) {
      if (!units || !price) {
        resetField(`slItems.${index}.total`, { defaultValue: null });
      } else {
        const total = units * price;
        setValue(`slItems.${index}.total`, total);
      }
    }
  }, [units, price, index, uom]);

  useEffect(() => {
    if (phase && phases.data) {
      const data = phases.data.find(
        (phaseData: JCPhase) => phaseData.Phase === phase
      );

      setValue(`slItems.${index}.description`, data?.Description || '');
    }
  }, [phase, phases.data, setValue]);

  return (
    <div className="flex flex-wrap mb-2 sm:mb-4 lg:mb-0">
      <div className="col-12 lg:col-7 flex flex-wrap p-0">
        <div className="col-12 sm:col-1">
          <label
            htmlFor={`slItems.${index}.line`}
            className="text-standard block mb-2 lg:hidden"
          >
            Line
          </label>
          <FormControlledNumberInput
            formID={`slItems.${index}.line`}
            isFieldArray={true}
            disabled={true}
          />
        </div>
        <div className="col-12 sm:col-4 xl:col-3">
          <label
            htmlFor={`slItems.${index}.phase`}
            className="text-standard block mb-2 lg:hidden"
          >
            Phase Code
          </label>
          <FormControlledDropdown
            isFieldArray={true}
            formID={`slItems.${index}.phase`}
            options={phases.data}
            labelField="Label"
            valueField="Phase"
            placeholder={
              phases.isError ? 'Failed to load options' : 'Select Option'
            }
            isDisabled={phases.isError}
            isLoading={phases.isFetching}
            clearable={false}
            filter={true}
            resetFilterOnHide={true}
            rules={{
              required: {
                value: true,
                message: 'Phase is a required field',
              },
            }}
            ignoreOnBlur={true}
          />
        </div>
        <div className="col-12 sm:col-4 xl:col-5">
          <label
            htmlFor={`slItems.${index}.description`}
            className="text-standard block mb-2 lg:hidden"
          >
            Description
          </label>
          <FormControlledInput
            defaultValue=""
            formID={`slItems.${index}.description`}
            isFieldArray={true}
            rules={{
              required: {
                value: true,
                message: 'Description is a required field',
              },
            }}
            autocomplete="off"
          />
        </div>
        <div className="col-12 sm:col-3">
          <label
            htmlFor={`slItems.${index}.um`}
            className="text-standard block mb-2 lg:hidden"
          >
            UoM
          </label>
          <FormControlledDropdown
            isFieldArray={true}
            formID={`slItems.${index}.um`}
            options={um.data?.filter(
              (um: UnitOfMeasure) => um.UM !== 'MOS' && um.UM !== 'QTR'
            )}
            labelField="Description"
            valueField="UM"
            placeholder={
              um.isError ? 'Failed to load options' : 'Select Option'
            }
            isDisabled={um.isError}
            isLoading={um.isFetching}
            clearable={false}
            filter={true}
            resetFilterOnHide={true}
            rules={{
              required: {
                value: true,
                message: 'UoM is a required field',
              },
            }}
            ignoreOnBlur={true}
          />
        </div>
      </div>
      <div className="col-12 sm:col-10 md:col-11 lg:col-4 flex flex-wrap p-0 flex-grow-1">
        <div className="col-6 sm:col-3">
          <label
            htmlFor={`slItems.${index}.units`}
            className="text-standard block mb-2 lg:hidden"
          >
            Units
          </label>
          <FormControlledNumberInput
            formID={`slItems.${index}.units`}
            isFieldArray={true}
            disabled={onlyTotal}
            rules={{
              required: {
                value: true,
                message: 'Units is a required field',
              },
            }}
          />
        </div>
        <div className="col-6 sm:col-3">
          <label
            htmlFor={`slItems.${index}.price`}
            className="text-standard block mb-2 lg:hidden"
          >
            Price
          </label>
          <FormControlledNumberInput
            formID={`slItems.${index}.price`}
            min={0}
            mode="currency"
            currency="USD"
            locale="en-US"
            isFieldArray={true}
            disabled={onlyTotal}
            rules={{
              required: {
                value: true,
                message: 'Price is a required field',
              },
            }}
          />
        </div>
        <div className="col-6 sm:col-4">
          <label
            htmlFor={`slItems.${index}.total`}
            className="text-standard block mb-2 lg:hidden"
          >
            Total
          </label>
          <FormControlledNumberInput
            formID={`slItems.${index}.total`}
            min={0}
            mode="currency"
            currency="USD"
            locale="en-US"
            isFieldArray={true}
            disabled={!onlyTotal}
            rules={{
              required: {
                value: true,
                message: 'Total is a required field',
              },
            }}
          />
        </div>
        <div className="col-6 sm:col-2 pr-0">
          <label
            htmlFor={`slItems.${index}.retainage`}
            className="text-standard block mb-2 lg:hidden"
          >
            Retainage
          </label>
          <FormControlledNumberInput
            formID={`slItems.${index}.retainage`}
            isFieldArray={true}
            suffix="%"
            min={0}
            max={100}
            maxFractionDigits={2}
            rules={{
              required: {
                value: true,
                message: 'Retainage is a required field',
              },
            }}
          />
        </div>
      </div>
      <div className="col-12 sm:col-2 md:col-1 flex p-0 lg:max-w-5rem">
        <div className="col-6 flex mt-0 sm:mt-4 lg:mt-0">
          <WrapperButton
            className="h-fit mx-auto mt-0 sm:my-auto"
            type="button"
            onClick={() => addNewItem(index)}
          >
            <i className="pi pi-refresh text-17px p-1 border-circle hover:bg-gray-300" />
          </WrapperButton>
        </div>
        {slItems.length > 1 && (
          <div className="col-6 flex mt-0 sm:mt-4 lg:mt-0">
            <WrapperButton
              className="h-fit mx-auto mt-0 sm:my-auto"
              type="button"
              onClick={() => removeItem(index)}
            >
              <i className="pi pi-trash text-17px p-1 border-circle hover:bg-gray-300" />
            </WrapperButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default SLItemRow;
