import { FILE_SIZE_LIMIT } from 'apps/tmr-frontend/src/utils/fileConsts';
import {
  FILE_FORMATS,
  transformBToMB,
} from 'apps/tmr-frontend/src/utils/fileUtil';
import { Controller, useFormContext } from 'react-hook-form';
import MultipleFileInput from '../../../components/inputs/StyledInputs/MultipleFileInput';

const ContractAttachments = () => {
  const { control } = useFormContext();

  return (
    <div>
      <Controller
        name="attachments"
        aria-label="file-input"
        control={control}
        render={({ field: { onChange, value } }) => (
          <div>
            <MultipleFileInput
              value={value}
              onChange={onChange}
              acceptedFormats={FILE_FORMATS}
              totalLimit={FILE_SIZE_LIMIT}
              totalLimitLabel={`${transformBToMB(FILE_SIZE_LIMIT)} MB`}
              emptyMessage={`Optional: upload contract attachment files. Allowed extensions: ${FILE_FORMATS.join(
                ', '
              )}.`}
            />
          </div>
        )}
      />
    </div>
  );
};

export default ContractAttachments;
