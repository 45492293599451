import { useFieldArray, useFormContext } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { useCompanyContext } from '../../../context/CompanyContext';
import { fecthJobs } from '../../../services/JobsContractsService';
import FormControlledDropdown from '../../../components/inputs/FormInputs/FormControlledDropdown';
import {
  fetchJobCostCodes,
  fetchJobExtras,
} from '../../../services/ACFormsService';
import JobPhaseRow from './JobPhaseRow';
import { useEffect } from 'react';

export type JobPhasesCostCode = {
  costCode?: string;
  description?: string;
  extra?: string;
};

export type JobPhasesFormFields = {
  job: string;
  costCodes: JobPhasesCostCode[];
};

const JobPhasesForm = () => {
  const { selectedCompany } = useCompanyContext();
  const { control, watch } = useFormContext<JobPhasesFormFields>();
  const { fields, append, remove, replace } =
    useFieldArray<JobPhasesFormFields>({
      control,
      name: 'costCodes',
    });
  const job = watch('job');

  const newRow = (row?: JobPhasesCostCode) => {
    if (!row) {
      append({});
      return;
    }

    append({ costCode: row.costCode, description: row.description });
  };

  const removeRow = (index: number) => {
    remove(index);
  };

  const jobs = useQuery({
    queryKey: ['getOpenJobs', selectedCompany],
    queryFn: ({ signal }) =>
      fecthJobs({ companyID: selectedCompany!.id, status: [1] }, signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  useQuery({
    queryKey: ['getJCCostCodes', selectedCompany, job],
    queryFn: ({ signal }) => {
      return fetchJobCostCodes(selectedCompany!.id, job, undefined, signal);
    },
    refetchOnWindowFocus: false,
    enabled: !!job,
  });

  useQuery({
    queryKey: ['getJobExtras', selectedCompany, job],
    queryFn: ({ signal }) => {
      return fetchJobExtras(selectedCompany!.id, job, signal);
    },
    refetchOnWindowFocus: false,
    enabled: !!job,
  });

  useEffect(() => {
    replace([{}]);
  }, [selectedCompany, job]);

  return (
    <div className="flex flex-wrap w-full">
      <div className="col-12 sm:col-6">
        <label htmlFor="job" className="text-standard block mb-2">
          Job #
        </label>
        <FormControlledDropdown
          formID="job"
          options={jobs.data}
          labelField="Job"
          valueField="Job"
          placeholder={jobs.isError ? 'Failed to load jobs!' : 'Select Job'}
          isDisabled={jobs.isError}
          isLoading={jobs.isFetching}
          clearable={true}
          filter={true}
          resetFilterOnHide={true}
          rules={{
            required: {
              value: true,
              message: 'Job is a required field',
            },
          }}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="job" className="text-standard block mb-2">
          Job Name
        </label>
        <FormControlledDropdown
          formID="job"
          options={jobs.data}
          labelField="Description"
          valueField="Job"
          placeholder={
            jobs.isError ? 'Failed to load job names!' : 'Select Job Name'
          }
          isDisabled={jobs.isError}
          isLoading={jobs.isFetching}
          clearable={true}
          filter={true}
          resetFilterOnHide={true}
          rules={{
            required: {
              value: true,
              message: 'Job Name is a required field',
            },
          }}
        />
      </div>
      <div className="col-12">
        {fields.map((field, index) => {
          return (
            <JobPhaseRow
              index={index}
              key={field.id}
              addRow={newRow}
              removeRow={removeRow}
            />
          );
        })}
      </div>
    </div>
  );
};

export default JobPhasesForm;
