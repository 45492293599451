import { formatUTCDate } from '../../../../utils/formatUtils';
import { ProjectManagerSummary } from '../../../Interfaces/Accounting/ACForms.interfaces';
import {
  JCProjectedCostDraft,
  JCPhase,
} from '../../../Interfaces/Accounting/JobsContracts.interface';
import {  REPORT_OPTIONS } from '../JCFilters';
import { CONTRACT_STATES, JOB_STATES } from '../util/JCUtils';

export const getJCReportTypeName = (id: string) => {
  const report = REPORT_OPTIONS.find((report) => report.value === id);

  return report?.label;
};

export const getContractStateLabel = (stateValue: string) => {
  const contractState = CONTRACT_STATES.find(
    (state) => state.value === stateValue
  );

  return contractState?.label;
};

export const getJobStateLabel = (stateValue: string) => {
  const jobState = JOB_STATES.find((state) => state.value === stateValue);

  return jobState?.label;
};

export const getJCPhasesLabel = (
  selectedPhases: string[],
  phases: JCPhase[]
) => {
  const filteresPhases = phases?.filter((phase) =>
    selectedPhases.includes(phase.Phase)
  );
  const labels = filteresPhases.map((phase) => phase.Label);

  return labels.join(', ');
};

export const getJCPmNames = (
  selectedPms: number[],
  data: ProjectManagerSummary[]
) => {
  const filteredPms = data?.filter((pm) => selectedPms.includes(pm.ProjectMgr));
  const labels = filteredPms.map((pm) => pm.Name);

  return labels.join(', ');
};

export const getSubmittedDraft = (
  seledtedDraft: string,
  data: JCProjectedCostDraft[]
) => {
  const draft = data?.find((draft) => draft.id === seledtedDraft);

  return `${formatUTCDate(draft?.updatedAt ?? '')} - ${draft?.userName}`;
};
