import React from 'react';

type WrapperButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  className?: string;
};

const WrapperButton = ({
  children,
  disabled,
  className,
  ...otherProps
}: WrapperButtonProps) => {
  return (
    <button
      className={`bg-transparent p-0 border-none text-inherit ${
        !disabled && 'cursor-pointer'
      } ${className}`}
      disabled={disabled}
      {...otherProps}
    >
      {children}
    </button>
  );
};

export default WrapperButton;
