import { remoteConfig } from 'apps/tmr-frontend/src/firebase';
import { fetchAndActivate, getAll } from 'firebase/remote-config';
import React, { useContext, useEffect, useState } from 'react';
import { DEFAULT_FEATURE_FLAGS } from './defaultFlags';

remoteConfig.settings = {
  minimumFetchIntervalMillis: parseInt(
    process.env['NX_FEATURE_FLAG_CACHE'] || '3600000'
  ),
  fetchTimeoutMillis: 60000,
};
remoteConfig.defaultConfig = DEFAULT_FEATURE_FLAGS;

const FeatureFlagsContext = React.createContext<Record<string, boolean>>({});
type FeatureFlagsProviderProps = {
  children: React.ReactNode;
};

export const FeatureFlagsProvider = ({
  children,
}: FeatureFlagsProviderProps) => {
  const [flags, setFlags] = useState<Record<string, boolean>>({});

  useEffect(() => {
    fetchAndActivate(remoteConfig)
      .then(() => {
        return getAll(remoteConfig);
      })
      .then((remoteFlags) => {
        const newFlags = { ...flags };

        for (const [key, config] of Object.entries(remoteFlags)) {
          newFlags[key] = config.asBoolean();
        }

        setFlags(newFlags);
      })
      .catch((error) => console.error('Error fetching ffs: ', error));
  }, []);

  return (
    <FeatureFlagsContext.Provider value={flags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlagsContext = () => useContext(FeatureFlagsContext);
