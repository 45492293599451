import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';
import RejectionField from '../../../components/dialog/RejectionField';
import LoadingButton from '../../../components/inputs/LoadingButton';
import ConfirmationDialog from '../../../components/messages/ConfirmationDialog';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { useCompanyContext } from '../../../context/CompanyContext';
import { ApiError } from '../../../Interfaces/Generic.interfaces';
import { Access } from '../../../Interfaces/Role.interfaces';
import {
  putProgressBillingApproveDraft,
  postProgressBillingDraft,
  putProgressBillingSubmitDraft,
  putProgressBillingDraft,
  putProgressBillingRejectDraft,
} from '../../../services/ContractBillingsService';
import { fetchContractBillingFilters } from '../CBFilters';

type ProgressBillingProcessProps = {
  draftAmounts: Record<number, number>;
  retainage?: number;
  defaultDraftID?: string;
  isSubmitted?: boolean;
  approvalMode?: boolean;
  access: Access;
  blockAll?: boolean;
  defaultBlockSave?: boolean;
};

const ProgressBillingProcess = ({
  draftAmounts,
  retainage,
  defaultDraftID,
  isSubmitted,
  approvalMode,
  access,
  blockAll,
  defaultBlockSave = true,
}: ProgressBillingProcessProps) => {
  const { selectedCompany } = useCompanyContext();
  const [blockSave, setBlockSave] = useState(defaultBlockSave);
  const [blockSubmit, setBlockSubmit] = useState(
    !defaultBlockSave || (isSubmitted ?? true)
  );
  const [blockApproval, setBlockApproval] = useState(
    approvalMode ? !defaultBlockSave : !defaultDraftID || !defaultBlockSave
  );
  const [hasBeenApproved, setHasBeenApproved] = useState(false);
  const [draftID, setDraftID] = useState<string>(defaultDraftID ?? '');
  const [visibility, setVisibility] = useState(false);
  const contract = useWatch<fetchContractBillingFilters>({
    name: 'contract',
  }) as string;
  const toast = useRef<Toast>(null);
  const prevDraft = useRef(draftAmounts);
  const prevRetainage = useRef(retainage);

  const save = useMutation({
    mutationFn: (draftID: string) => {
      if (draftID) {
        return putProgressBillingDraft(
          draftID,
          !!approvalMode,
          draftAmounts,
          retainage ?? undefined
        );
      }

      return postProgressBillingDraft(
        selectedCompany!.id,
        contract,
        draftAmounts,
        retainage ?? undefined
      );
    },
    onSuccess: (data) => {
      showToast(
        'success',
        toast,
        'Save Progress Billing',
        'The data was saved successfully!',
        3000
      );

      setBlockSave(true);
      setBlockSubmit(false);
      setBlockApproval(false);

      if (!draftID) {
        setDraftID(data);
      }
    },
    onError: (error: ApiError) => {
      const errorMessage = error.response?.data?.message;

      showToast(
        'error',
        toast,
        'Save Progress Billing',
        errorMessage ?? "The data couldn't be saved",
        3000
      );
    },
  });

  const submit = useMutation({
    mutationFn: (draftID: string) => {
      return putProgressBillingSubmitDraft(draftID);
    },
    onSuccess: () => {
      setBlockSubmit(true);

      showToast(
        'success',
        toast,
        'Submit Progress Billing',
        'The data was submitted successfully!',
        3000
      );
    },
    onError: () => {
      showToast(
        'error',
        toast,
        'Submit Progress Billing',
        "The data couldn't be submitted",
        3000
      );
    },
  });

  const approveRequest = useMutation({
    mutationFn: (draftID: string) => {
      return putProgressBillingApproveDraft(draftID, selectedCompany!.id);
    },
    onSuccess: () => {
      const label = approvalMode ? 'Approve' : 'Upload';
      const mesLabel = approvalMode ? 'approved' : 'uploaded';
      showToast(
        'success',
        toast,
        `${label} Progress Billing`,
        `The data was ${mesLabel} successfully!`,
        3000
      );

      setBlockApproval(true);
      setHasBeenApproved(true);
    },
    onError: (error: AxiosError) => {
      const label = approvalMode ? 'Approve' : 'Upload';
      const mesLabel = approvalMode ? 'approved' : 'uploaded';

      const errorData = error.response?.data as {
        code: string;
        message: string;
      };
      showToast(
        'error',
        toast,
        `${label} Progress Billing`,
        errorData?.message || `The data couldn't be ${mesLabel}`,
        3000
      );
    },
  });

  const rejectRequest = useMutation({
    mutationFn: (props: { draftID: string; reason: string }) => {
      return putProgressBillingRejectDraft(props.draftID, props.reason);
    },
    onSuccess: () => {
      showToast(
        'success',
        toast,
        `Reject Progress Billing`,
        `The draft was rejected successfully!`,
        3000
      );

      setBlockApproval(true);
      setHasBeenApproved(true);
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as {
        code: string;
        message: string;
      };

      showToast(
        'error',
        toast,
        `Reject Progress Billing`,
        errorData?.message || `The draft couldn't be rejected`,
        3000
      );
    },
  });

  useEffect(() => {
    if (prevDraft.current !== draftAmounts && !hasBeenApproved) {
      setBlockSave(false);
      setBlockSubmit(true);
      setBlockApproval(true);
      prevDraft.current = draftAmounts;
    }
  }, [draftAmounts, hasBeenApproved]);

  useEffect(() => {
    if (prevRetainage.current !== retainage && !hasBeenApproved) {
      setBlockSave(false);
      setBlockSubmit(true);
      setBlockApproval(true);
      prevRetainage.current = retainage;
    }
  }, [retainage, hasBeenApproved]);

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      {((access.shouldApprove && approvalMode) || access?.editable) && (
        <LoadingButton
          label="Save"
          fontSize="text-xl"
          bgColor="buttonSecondary"
          isLoading={save.isLoading}
          onClick={() => save.mutate(draftID)}
          disabled={blockAll || blockSave || save.isLoading}
        />
      )}
      {!approvalMode && access?.editable === 'approval' && (
        <ConfirmationDialog
          Button={
            <LoadingButton
              label="Submit"
              fontSize="text-xl"
              isLoading={submit.isLoading}
              disabled={blockAll || blockSubmit || submit.isLoading}
              type="button"
            />
          }
          onConfirm={() => submit.mutate(draftID)}
          message="Are you sure you want to submit this draft?"
        />
      )}
      {approvalMode && access.shouldApprove && (
        <ConfirmationDialog
          tagKey="draft-rejection"
          visibility={visibility}
          Button={
            <LoadingButton
              label={'Reject'}
              fontSize="text-xl"
              bgColor="bluwaiRed"
              isLoading={rejectRequest.isLoading}
              disabled={blockAll || blockApproval || rejectRequest.isLoading}
              type="button"
              onClick={() => setVisibility(true)}
            />
          }
          contentClassName="pb-0"
          onConfirm={() => {}}
          acceptClassName="hidden"
          rejectClassName="hidden"
          onHide={() => setVisibility(false)}
          message={
            <RejectionField
              onReject={() => {
                setVisibility(false);
              }}
              onConfirm={(reason) => {
                rejectRequest.mutate({ draftID, reason });
                setVisibility(false);
              }}
            />
          }
        />
      )}
      {(approvalMode
        ? access?.shouldApprove
        : access?.editable === 'no-approval') && (
        <ConfirmationDialog
          tagKey="draft-approval"
          Button={
            <LoadingButton
              label={approvalMode ? 'Approve' : 'Upload'}
              fontSize="text-xl"
              bgColor="bg-green-500"
              isLoading={approveRequest.isLoading}
              disabled={blockAll || blockApproval || approveRequest.isLoading}
              type="button"
            />
          }
          onConfirm={() => approveRequest.mutate(draftID)}
          message={`Are you sure you want to ${
            approvalMode ? 'approve' : 'upload'
          } this draft?`}
        />
      )}
    </React.Fragment>
  );
};

export default ProgressBillingProcess;
