import React, { useCallback } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { formatCurrency, formatUTCDate } from '../../../../utils/formatUtils';
import { ContractBillsAndReceipts } from '../../../Interfaces/Accounting/AccountsReceivables.interfaces';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import ARAttachmentSelector from '../ARAttachmentSelector';

type CBRTableProps = {
  cbrTransactions: ContractBillsAndReceipts[];
  drilldownStyles?: boolean;
};

const CBRTable = React.forwardRef<HTMLDivElement, CBRTableProps>(
  ({ cbrTransactions, drilldownStyles }, ref) => {
    const tableClass = drilldownStyles ? 'mt-5' : 'mx-3 dashboardOptionShadow';
    const columnHeaderClass = drilldownStyles
      ? 'DrilldownHeader'
      : 'tableHeader';

    const calcHeight = (rows: number) => {
      const headerHeight = 49;
      const footerHeight = 54;
      const rowHeight = 31;
      return headerHeight + footerHeight + rows * rowHeight + 11;
    };

    let billed = 0;
    let retainage = 0;
    let current = 0;
    let receipt = 0;
    let remaining = 0;
    cbrTransactions.forEach((receivable: ContractBillsAndReceipts) => {
      billed = billed + receivable.ContractBilled;
      retainage = retainage + receivable.Retainage;
      current = current + receivable.CurrentDue;
      receipt = receipt + receivable.Receipt;
      remaining = remaining + receivable.AmountRemaining;
    });

    const onRowHover = (e: DataTableRowMouseEventParams) => {
      const cell = e.originalEvent.target as HTMLElement;
      const row = cell.closest('tr') as HTMLElement;
      animateCellText(row);
    };

    const rowClassName = (data: ContractBillsAndReceipts) => {
      if (!data.ContractBilled && !data.Retainage) {
        return 'hidden';
      }
      return '';
    };

    return (
      <div className="pdfDivFitContent">
        <Table
          id="ar-cbr-table"
          ref={ref}
          data={cbrTransactions}
          className={`${tableClass} dobleHeader`}
          calcHeight={useCallback(calcHeight, [])}
          onRowMouseEnter={useCallback(onRowHover, [])}
          rowClassName={useCallback(rowClassName, [])}
          stripedRows={!drilldownStyles}
          hideColumns={true}
        >
          {!drilldownStyles && (
            <Column
              field="Customer"
              header="Customer"
              body={(ar) => {
                return <div className="scroll-text">{ar.Customer}</div>;
              }}
              headerClassName={`${columnHeaderClass} font-normal justify-content-center`}
              style={{ minWidth: '225px', maxWidth: '225px' }}
              className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
              footerClassName="tableFooter"
              sortable
            />
          )}
          {!drilldownStyles && (
            <Column
              field="Contract"
              header="Contract #"
              style={{ minWidth: '110px' }}
              headerClassName={`${columnHeaderClass} font-normal`}
              className="justify-content-center text-standard blackText tableCell"
              footerClassName="tableFooter"
              sortable
            />
          )}
          {!drilldownStyles && (
            <Column
              field="ContractName"
              header="Contract Name"
              body={(ar) => {
                return <div className="scroll-text">{ar.ContractName}</div>;
              }}
              style={{ minWidth: '250px', maxWidth: '250px' }}
              headerClassName={`${columnHeaderClass} font-normal justify-content-center`}
              className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
              footerClassName="tableFooter"
              sortable
            />
          )}
          <Column
            field="InvoiceNumber"
            header="Invoice"
            style={{ minWidth: '80px' }}
            headerClassName={`${columnHeaderClass} font-normal`}
            className=" text-standard blackText tableCell title justify-content-center"
            footerClassName={`tableFooter`}
            sortable
          />
          <Column
            field="InvoiceDate"
            header="Inv. Date"
            style={{ minWidth: '100px' }}
            body={(ar) => formatUTCDate(ar.InvoiceDate)}
            headerClassName={`${columnHeaderClass} font-normal`}
            className="justify-content-center text-center text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="Description"
            header="Description"
            body={(ar) => {
              return <div className="scroll-text">{ar.Description}</div>;
            }}
            style={{ minWidth: '130px', maxWidth: '130px' }}
            headerClassName={`${columnHeaderClass} font-normal justify-content-center`}
            className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
            footer="Total"
            footerClassName="overflow-x-visible tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
            sortable
          />
          <Column
            field="ContractBilled"
            header="Contract Billed"
            style={{ minWidth: '140px' }}
            body={(ar) => formatCurrency(ar.ContractBilled)}
            headerClassName={`${columnHeaderClass} font-normal justify-content-center text-center`}
            className="justify-content-end text-standard blackText tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={formatCurrency(billed)}
            sortable
          />
          <Column
            field="Retainage"
            header="Retainage"
            style={{ minWidth: '130px' }}
            body={(ar) => formatCurrency(ar.Retainage)}
            headerClassName={`${columnHeaderClass} font-normal justify-content-center text-center`}
            className="justify-content-end text-standard blackText tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={formatCurrency(retainage)}
            sortable
          />
          <Column
            field="CurrentDue"
            header="Curent Due"
            style={{ minWidth: '150px' }}
            body={(ar) => formatCurrency(ar.CurrentDue)}
            headerClassName={`${columnHeaderClass} font-normal justify-content-center text-center`}
            className="justify-content-end text-standard blackText tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={formatCurrency(current)}
            sortable
          />
          <Column
            field="Receipt"
            header="Receipt"
            style={{ minWidth: '150px' }}
            body={(ar) => formatCurrency(ar.Receipt ? ar.Receipt : 0)}
            headerClassName={`${columnHeaderClass} font-normal justify-content-center text-center`}
            className="justify-content-end text-standard blackText tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={formatCurrency(receipt)}
            sortable
          />
          <Column
            field="CheckNo"
            header="Check"
            body={(ar) => {
              if (Array.isArray(ar.CheckNo)) {
                return (
                  <div className="checkMultiLine">
                    {ar.CheckNo.map((checkNo: string, index: number) => (
                      <div key={index}>{checkNo}</div>
                    ))}
                  </div>
                );
              }
              return ar.CheckNo;
            }}
            style={{ minWidth: '120px' }}
            headerClassName={`${columnHeaderClass} font-normal`}
            className="justify-content-center text-center text-standard blackText tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            field="Date"
            header="Date"
            body={(ar) => {
              if (Array.isArray(ar.Date)) {
                return (
                  <div className="dateMultiLine">
                    {ar.Date.map((date: Date, index: number) => (
                      <div key={index}>{formatUTCDate(date)}</div>
                    ))}
                  </div>
                );
              }
              return formatUTCDate(ar.Date);
            }}
            style={{ minWidth: '100px' }}
            headerClassName={`${columnHeaderClass} font-normal text-center`}
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            field="ClearDate"
            header="Date Cleared"
            body={(ar) => {
              if (Array.isArray(ar.ClearDate)) {
                return (
                  <div className="clearMultiLine">
                    {ar.ClearDate.map((date: Date, index: number) => (
                      <div key={index}>{formatUTCDate(date)}</div>
                    ))}
                  </div>
                );
              }
              return formatUTCDate(ar.ClearDate);
            }}
            style={{ minWidth: '100px' }}
            headerClassName={`${columnHeaderClass} font-normal text-center`}
            className="text-standard blackText tableCell justify-content-center"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            field="AmountRemaining"
            header="Current Balance Remaining"
            style={{ minWidth: '160px' }}
            body={(ar) => formatCurrency(ar.AmountRemaining)}
            headerClassName={`${columnHeaderClass} font-normal justify-content-center text-center`}
            className="justify-content-end text-standard blackText tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={formatCurrency(remaining)}
            sortable
          />
          <Column
            header=""
            style={{ minWidth: '50px', maxWidth: '50px' }}
            body={(ar) => {
              return (
                <ARAttachmentSelector
                  contract={ar.Contract}
                  invoice={ar.InvoiceNumber}
                />
              );
            }}
            headerClassName={`${columnHeaderClass} font-normal justify-content-center text-center`}
            className="justify-content-center text-standard blackText tableCell printHide"
            footerClassName="tableFooter"
          />
        </Table>
      </div>
    );
  }
);

const transactionsAreEqual = (
  prevTransactions: Readonly<CBRTableProps>,
  nextTransactions: Readonly<CBRTableProps>
) => {
  return prevTransactions.cbrTransactions === nextTransactions.cbrTransactions;
};

export default React.memo(CBRTable, transactionsAreEqual);
