import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import SuccessFormMessage from '../../../components/messages/SuccessFormMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import EstimatedCostForm, {
  EstimatedCostFormFields,
} from '../../../features/acForms/EstimatedCost/EstimatedCostForm';
import EstimateDownloadTemplate from '../../../features/acForms/EstimatedCost/EstimateDownloadTemplate';
import { postEstimatedCosts } from '../../../services/ACFormsService';
import EstimateSuccessMessage from '../../../features/acForms/EstimatedCost/EstimateSuccessMessage';
import LoadingButton from '../../../components/inputs/LoadingButton';
import ConfirmationDialog from '../../../components/messages/ConfirmationDialog';

const EstimatedCost = () => {
  const { selectedCompany } = useCompanyContext();
  const navigate = useNavigate();
  const formMethods = useForm<EstimatedCostFormFields>();
  const { handleSubmit, watch } = formMethods;
  const values = watch();
  const toast = useRef<Toast>(null);

  const { mutate, isLoading, isSuccess, reset, data } = useMutation({
    mutationFn: (data: EstimatedCostFormFields) => {
      return postEstimatedCosts(
        selectedCompany!.id,
        data.phases,
        data.estimates
      );
    },
    onError: (error: AxiosError) => {
      const response = error.response;
      const data = response?.data as { message: string };
      showToast(
        'error',
        toast,
        'Upload Estimated Cost Files Error',
        data?.message || 'An error ocurred! please try again later',
        10000
      );
    },
  });

  const onSubmit = (data: EstimatedCostFormFields) => {
    mutate(data);
  };

  useEffect(() => {
    formMethods.reset();
    reset();
  }, [selectedCompany, reset, formMethods]);

  return (
    <div className="flex flex-column justify-content-center gap-5 pt-4">
      <div className="text-35px font-bold text-center mb-2 relative">
        New Estimated Cost
        {!isSuccess && (
          <div className="flex justify-content-center lg:absolute top-0 left-0 mt-4 lg:mt-0 lg:ml-6">
            <EstimateDownloadTemplate />
          </div>
        )}
      </div>
      <ErrorToast toastRef={toast} />
      {!isSuccess && (
        <FormProvider {...formMethods}>
          <form className="max-w-600px mx-auto flex flex-column gap-5">
            <EstimatedCostForm />
            <div className="flex flex-wrap flex-row-reverse gap-2 mb-3">
              <ConfirmationDialog
                Button={
                  <LoadingButton
                    isLoading={isLoading}
                    label="Create Estimates"
                    className="w-15rem bluwaiBlue border-0 flex m-auto py-3"
                    fontSize="text-22px"
                    type="button"
                  />
                }
                onConfirm={handleSubmit(onSubmit)}
                message="Are you sure you want to submit the estimated cost?"
              />
              <Button
                onClick={() => {
                  navigate('/accounting-dashboard/new');
                }}
                className="w-15rem bluwaiRed border-0 flex m-auto py-3"
                type="button"
              >
                <span className="mx-auto text-22px text-white">Cancel</span>
              </Button>
            </div>
          </form>
        </FormProvider>
      )}
      {isSuccess && (
        <div>
          <SuccessFormMessage
            message={
              <EstimateSuccessMessage
                phaseFile={values.phases?.name}
                estimatesFile={values.estimates?.name}
                phases={data.phases}
                cost={data.cost}
              />
            }
            redirectUrl="/accounting-dashboard/new"
            redirectMessage="Click here to return to the forms list"
          />
        </div>
      )}
    </div>
  );
};

export default EstimatedCost;
