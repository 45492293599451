import { Steps, StepsSelectParams } from 'primereact/steps';
import { useFormContext, UseFormTrigger, useWatch } from 'react-hook-form';
import {
  POForm,
  POUploadTypes,
} from '../../../Interfaces/Accounting/ACForms.interfaces';
import { PO_FORM_FIELDS } from '../formConsts';

export const checkBeforeStep = async (
  index: number,
  checkErrors: UseFormTrigger<POForm>,
  endIndex?: number
) => {
  let result = await checkErrors(
    PO_FORM_FIELDS[index as keyof typeof PO_FORM_FIELDS]
  );

  for (let i = index + 1; i <= (endIndex || 0); i++) {
    result =
      result &&
      (await checkErrors(PO_FORM_FIELDS[i as keyof typeof PO_FORM_FIELDS]));
  }

  return result;
};

type POStepsProps = {
  index: number;
  changeIndex: (number: number) => void;
};

const POSteps = ({ index, changeIndex }: POStepsProps) => {
  const { trigger } = useFormContext<POForm>();
  const upType = useWatch<POForm>({ name: 'upType' }) as POUploadTypes;

  const checkIndexChange = async (newIndex: number, currentIndex: number) => {
    if (newIndex <= currentIndex) {
      changeIndex(newIndex);
      return;
    }

    const shouldChange = await checkBeforeStep(
      currentIndex,
      trigger,
      newIndex - 1
    );

    if (shouldChange) {
      changeIndex(newIndex);
    }
  };

  const flowItems = {
    manual: [
      {
        label: 'Piece Work',
        command: (event: StepsSelectParams) => {
          checkIndexChange(event.index, index);
        },
      },
    ],
    file: [
      {
        label: 'PO File',
        command: (event: StepsSelectParams) => {
          checkIndexChange(event.index, index);
        },
      },
    ],
  };

  const items = [
    {
      label: 'General Info',
      command: (event: StepsSelectParams) => {
        changeIndex(event.index);
      },
    },
    ...(flowItems[upType] || []),
  ];

  return (
    <Steps
      model={items}
      activeIndex={index}
      readOnly={false}
      className="steps-db-bg"
    />
  );
};

export default POSteps;
