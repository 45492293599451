import { BASE_PDF_STYLES } from '../../../../utils/reportUtils';
import { useRef } from 'react';
import ReportButton from '../../../components/report/ReportButton';
import { formatCurrency } from '../../../../utils/formatUtils';
import { SuccessFiles } from '../../../Interfaces/Accounting/ACForms.interfaces';

type SOVSuccessMessageProps = {
  successFiles: SuccessFiles[];
  errorFiles?: string[];
};

const SOVSuccessMessage = ({
  successFiles,
  errorFiles,
}: SOVSuccessMessageProps) => {
  const summaryRef = useRef<HTMLDivElement | null>(null);
  const sucessSubject = successFiles.length > 1 ? 'files were' : 'file was';
  const sucessMessage = `The following ${sucessSubject} uploaded successfully:`;
  const errorSubject = errorFiles && errorFiles?.length > 1 ? 'files' : 'file';
  const errorMessage = `The following ${errorSubject} couldn't be uploded:`;

  return (
    <div id="sov-success" ref={summaryRef} className="mt-3 mx-5 max-w-800px">
      <div className="text-30px font-bold flex justify-content-between align-items-end mb-5">
        Schedule of Values Summary:
        <div className="text-23px cursor-pointer printHide h-fit">
          <ReportButton
            elementToPrintRef={summaryRef}
            fileName="SOV_Form"
            pdfStyles={BASE_PDF_STYLES}
            noButton={true}
            hideExcel={true}
            isPdfPortrait={true}
            widthElementID="sov-success"
            minWidth={800}
          />
        </div>
      </div>
      <div>
        {sucessMessage}
        <ul>
          {successFiles.map((file: SuccessFiles) => {
            const sucessItem = `${file.fileName} with a ${formatCurrency(
              file.amount
            )} total.`;

            return (
              <li className="mb-2" key={file.fileName}>
                {sucessItem}
              </li>
            );
          })}
        </ul>
      </div>
      {errorFiles && errorFiles?.length > 0 && (
        <div className="max-w-600px">
          {errorMessage}
          <ul>
            {errorFiles.map((file: string) => {
              return (
                <li className="mb-2" key={file}>
                  {file}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SOVSuccessMessage;
