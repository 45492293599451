import { getFieldArrayError } from 'apps/tmr-frontend/src/utils/formUtils';
import { InputText, InputTextProps } from 'primereact/inputtext';
import React from 'react';
import {
  Controller,
  FieldError,
  FieldErrorsImpl,
  useFormContext,
} from 'react-hook-form';
import FormErrorMessage from '../../messages/FormErrorMessage';

type FormControlledInputProps = InputTextProps & {
  defaultValue?: string | number;
  formID: string;
  rules?: object;
  disabled?: boolean;
  placeholder?: string;
  autocomplete?: string;
  hideErrors?: boolean;
  isFieldArray?: boolean;
};

const FormControlledInput = ({
  defaultValue,
  formID,
  rules,
  disabled,
  placeholder,
  autocomplete,
  hideErrors,
  isFieldArray,
  ...otherProps
}: FormControlledInputProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  let fieldError = errors[formID];
  if (isFieldArray) {
    fieldError = getFieldArrayError(formID, errors);
  }

  return (
    <React.Fragment>
      <Controller
        defaultValue={defaultValue}
        control={control}
        name={formID}
        rules={rules}
        render={({ field }) => (
          <InputText
            id={formID}
            placeholder={placeholder}
            disabled={disabled}
            className={`w-full blackText text-standard ${
              fieldError && 'p-invalid'
            }`}
            autoComplete={autocomplete}
            {...otherProps}
            {...field}
          />
        )}
      />
      {fieldError && !hideErrors && (
        <FormErrorMessage errorMessage={fieldError.message as string} />
      )}
    </React.Fragment>
  );
};

export default FormControlledInput;
