import { SCPaymentDetails } from '../../../Interfaces/Accounting/Subcontracts.interfaces';

export function processDetailList(
  data: SCPaymentDetails[],
  processSub: boolean,
  processJob: boolean
) {
  if (!processSub && !processJob) {
    return data;
  }

  const newList: SCPaymentDetails[] = [];
  let jobAccumulator = 0;
  let slAccumulator = 0;
  let currentJob = data[0]?.Job;
  let currentJobName = data[0]?.JobName;
  let currentSL = data[0]?.SL;

  const pushAccumulator = (
    description: string,
    accumulator: number,
    key: string,
    value: any,
    JobName?: string
  ) => {
    if (accumulator > 0) {
      newList.push({
        [key]: value,
        Description: description,
        Amount: accumulator,
        Phase: JobName,
      } as SCPaymentDetails);
    }
  };

  data.forEach((detail) => {
    if (processSub && detail.SL !== currentSL) {
      pushAccumulator('Subcontract Total:', slAccumulator, 'SL', currentSL);
      slAccumulator = 0;
      currentSL = detail.SL;
    }

    if (processSub) {
      slAccumulator += detail.Amount;
    }

    if (processJob && detail.Job !== currentJob) {
      pushAccumulator(
        'Job Total:',
        jobAccumulator,
        'Job',
        currentJob,
        currentJobName
      );
      jobAccumulator = 0;
      currentJob = detail.Job;
      currentJobName = detail.JobName;
    }

    if (processJob) {
      jobAccumulator += detail.Amount;
    }

    newList.push(detail);
  });

  if (processSub) {
    pushAccumulator('Subcontract Total:', slAccumulator, 'SL', currentSL);
  }

  if (processJob) {
    pushAccumulator(
      'Job Total:',
      jobAccumulator,
      'Job',
      currentJob,
      currentJobName
    );
  }

  return newList;
}
