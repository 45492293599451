import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCompanyContext } from '../../../context/CompanyContext';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { getFoldersSummary } from '../../../services/FinancialStatementsService';
import FolderList from './FolderList';

const FolderListContainer = React.forwardRef<HTMLDivElement>((props, ref) => {
  const { selectedCompany } = useCompanyContext();

  const { data, isFetching, isError, refetch } = useQuery({
    queryKey: ['getFSFolderList', selectedCompany],
    queryFn: ({ signal }) => {
      return getFoldersSummary(selectedCompany!.id, signal);
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  if (isFetching || !selectedCompany)
    return (
      <div className="text-center mx-auto mt-3">
        <ProgressSpinner />
      </div>
    );

  if (isError)
    return (
      <ErrorMessage
        content={'Failed to obtain data! Please try again later.'}
      />
    );

  return <FolderList folders={data} fetchFolders={refetch} />;
});

export default FolderListContainer;
