import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { SCPaymentSummary } from '../../../Interfaces/Accounting/Subcontracts.interfaces';
import SCResumeFilters, { SubcontractsResumeFilters } from './SCResumeFilter';
import SCResumeGraph from './SCResumeGraph';
import SCResumeTable from './SCResumeTable';

type SCResumeProps = {
  payments: SCPaymentSummary[];
  periodStart: Date;
  periodEnd: Date;
  isLoading: boolean;
};

const SCResume = React.forwardRef<HTMLDivElement, SCResumeProps>(
  ({ payments, periodStart, periodEnd, isLoading }, ref) => {
    const { watch } = useFormContext<SubcontractsResumeFilters>();
    const groupType = watch('groupType');

    return (
      <div className="flex flex-wrap gap-3 justify-content-center sm:justify-content-start">
        <div className="flex flex-column row-gap-3">
          <div className="mr-auto">
            <SCResumeFilters />
          </div>
          {payments?.length > 0 && !isLoading && (
            <SCResumeGraph payments={payments} groupType={groupType} />
          )}
        </div>
        {isLoading && (
          <div className="w-full flex justify-content-center">
            <ProgressSpinner />
          </div>
        )}
        {!isLoading && payments && (
          <SCResumeTable
            payments={payments}
            groupType={groupType}
            periodStart={periodStart}
            periodEnd={periodEnd}
          />
        )}
      </div>
    );
  }
);

export default SCResume;
