import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useFormContext, useWatch } from 'react-hook-form';
import useFiltersCache from '../../hooks/useFiltersCache';
import { USFilterFields } from '../../Interfaces/User.interfaces';
import { UsersFiltersArgs } from './UsersFilter';

const UserParams = () => {
  const [cookies] = useCookies(['adminFilters']);
  const { setValue } = useFormContext<UsersFiltersArgs>();
  const filters = useWatch();
  useFiltersCache({
    tab: 'US',
    filters,
    cookieName: 'adminFilters',
    path: '/admin',
  });
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    if (!rendered && cookies.adminFilters?.tab === 'US') {
      setRendered(true);
      const cookieFilters = cookies.adminFilters?.filters;

      for (const i in cookieFilters) {
        setValue(i as USFilterFields, cookieFilters[i]);
      }
    }
  }, [setValue, rendered, setRendered, cookies.adminFilters]);

  return <span />;
};

export default UserParams;
