import axios from 'axios';
import { auth } from '../../firebase';
import { Access } from '../Interfaces/Role.interfaces';

const apiUrl = process.env['NX_API_URL'];

export type postRoleArgs = {
  name: string;
  type: string;
  access: Access[];
  hidePhaseCodes: boolean;
};

export const postRole = async (data: postRoleArgs) => {
  const response = await axios.post(`${apiUrl}/api/roles`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export type getRolesArgs = {
  page?: number;
  amountPerPage?: number;
  roleType?: string;
};

export const getRoles = async (params: getRolesArgs, signal?: AbortSignal) => {
  const response = await axios.get(`${apiUrl}/api/roles`, {
    signal,
    params: params,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const getRolesInfo = async (
  rolesIDList: string[],
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/roles/info`, {
    signal,
    params: { rolesIDList },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const getRole = async (roleID: string, signal?: AbortSignal) => {
  const response = await axios.get(`${apiUrl}/api/roles/${roleID}`, {
    signal,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const getSystemSections = async (signal?: AbortSignal) => {
  const response = await axios.get(`${apiUrl}/api/roles/sections`, {
    signal,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  return response.data;
};

export const putRole = async (roleID: string, data: postRoleArgs) => {
  const payload = {
    access: data.access,
    type: data.type,
    hidePhaseCodes: data.hidePhaseCodes,
  };

  const response = await axios.put(`${apiUrl}/api/roles/${roleID}`, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const deleteRole = async (roleID: string) => {
  const response = await axios.delete(`${apiUrl}/api/roles/${roleID}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const getRolePhaseCodes = async (signal?: AbortSignal) => {
  const response = await axios.get(
    `${apiUrl}/api/jobsContracts/rolePhaseCodes`,
    {
      signal,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  return response.data;
};
