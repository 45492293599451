import React from 'react';
import { Controller, FieldErrorsImpl, useFormContext } from 'react-hook-form';
import FormErrorMessage from '../../messages/FormErrorMessage';
import { InputNumber, InputNumberProps } from 'primereact/inputnumber';
import { getFieldArrayError } from 'apps/tmr-frontend/src/utils/formUtils';

type FormControlledNumberInputProps = InputNumberProps & {
  defaultValue?: number;
  formID: string;
  rules?: object;
  isFieldArray?: boolean;
};

const FormControlledNumberInput = ({
  defaultValue,
  formID,
  rules,
  isFieldArray,
  ...otherProps
}: FormControlledNumberInputProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  let fieldError = errors[formID];
  if (isFieldArray) {
    fieldError = getFieldArrayError(formID, errors);
  }

  return (
    <React.Fragment>
      <Controller
        defaultValue={defaultValue}
        control={control}
        name={formID}
        rules={rules}
        render={({ field: { ref, onChange, ...otherFields } }) => (
          <InputNumber
            id={formID}
            inputRef={ref}
            className={`w-full ${fieldError && 'p-invalid'}`}
            inputClassName="w-full blackText text-standard"
            onValueChange={(e) => onChange(e.value)}
            {...otherProps}
            {...otherFields}
          />
        )}
      />
      {fieldError && (
        <FormErrorMessage errorMessage={fieldError.message as string} />
      )}
    </React.Fragment>
  );
};

export default FormControlledNumberInput;
