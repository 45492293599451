import { formatUTCDate } from '../../../../utils/formatUtils';
import { ProgressBillingDraft } from '../../../Interfaces/Accounting/ContractBillings.interface';
import { ITEMS_STATES, REPORT_OPTIONS } from '../CBFilters';

export const getCBReportTypeName = (id: string) => {
  const report = REPORT_OPTIONS.find((report) => report.value === id);

  return report?.label;
};

export const getSubmittedDraft = (
  seledtedDraft: string,
  data: ProgressBillingDraft[]
) => {
  const draft = data?.find((draft) => draft.id === seledtedDraft);

  return `${formatUTCDate(draft?.updatedAt ?? '')} - ${draft?.userName}`;
};

export const getBillItemStatusLabel = (value: string) => {
  const itemState = ITEMS_STATES.find((state) => state.value === value);

  return itemState?.label;
};
