import React, { useState } from 'react';
import AppMenu from '../../components/navbar/AppMenu';
import { CommandArgs } from '../../components/navbar/AppMenuTypes';
import CashManagement from '../../../assets/layout/images/icons/Icon map-accounting.svg';
import ITSupoort from '../../../assets/layout/images/icons/Icon awesome-laptop.svg';
import Estimating from '../../../assets/layout/images/icons/Intersection 1.svg';
import Insurance from '../../../assets/layout/images/icons/Icon material-supervisor-account.svg';
import Investments from '../../../assets/layout/images/icons/Icon awesome-chart-line.svg';
import Development from '../../../assets/layout/images/icons/Icon ionic-ios-create.svg';
import Tax from '../../../assets/layout/images/icons/Icon awesome-money-check-alt.svg';
import Asset from '../../../assets/layout/images/icons/Icon awesome-money-bill-wave.svg';
import Budgeting from '../../../assets/layout/images/icons/Icon awesome-balance-scale-left.svg';

const menu = [
  {
    items: [
      {
        label: 'Accounting Dashboard',
        to: '/accounting-dashboard',
        iconIMG: CashManagement,
      },
      {
        label: 'IT Support',
        to: '/it-Support',
        iconIMG: ITSupoort,
        disabled: true,
      },
      {
        label: 'Estimating',
        to: '/estimating',
        iconIMG: Estimating,
        disabled: true,
      },
      {
        label: 'Insurance',
        to: '/insurance',
        iconIMG: Insurance,
        disabled: true,
      },
      {
        label: 'Investments',
        to: '/investments',
        iconIMG: Investments,
        disabled: true,
      },
      {
        label: 'Development',
        to: '/development',
        iconIMG: Development,
        disabled: true,
      },
      {
        label: 'Tax Services',
        to: '/tax-services',
        iconIMG: Tax,
        disabled: true,
      },
      {
        label: 'Asset Management',
        to: '/asset-management',
        iconIMG: Asset,
        disabled: true,
      },
      {
        label: 'Management and Budgeting',
        to: '/management-and-budgeting',
        iconIMG: Budgeting,
        disabled: true,
      },
    ],
  },
];

type MainSideBarProps = {
  onItemClick: (event: CommandArgs) => void;
};

const MainSideBar = ({ onItemClick }: MainSideBarProps) => {
  const [menuActive, setMenuActive] = useState(false);

  const onMenuItemClick = (event: CommandArgs) => {
    if (!event.item.items) {
      setMenuActive(false);
    }
    onItemClick(event);
  };

  const onRootMenuItemClick = () => {
    setMenuActive((prevState) => !prevState);
  };

  return (
    <AppMenu
      onMenuItemClick={onMenuItemClick}
      onRootMenuItemClick={onRootMenuItemClick}
      menuMode={'static'}
      active={menuActive}
      items={menu}
    />
  );
};

export default MainSideBar;
