export const animateCellText = (father?: HTMLElement) => {
  const children = father?.getElementsByClassName('checkOverflow');
  Array.from(children ?? []).forEach(function (child) {
    if (child.scrollWidth > child.clientWidth + 6) {
      child.classList.add('animatedCell');
    }
  });
  const removeClass = () => {
    Array.from(children ?? []).forEach(function (child) {
      if (child.scrollWidth > child.clientWidth) {
        child.classList.remove('animatedCell');
      }
    });
    father?.removeEventListener('mouseleave', removeClass);
  };
  father?.addEventListener('mouseleave', removeClass);
};
