import React from 'react';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ProgressSpinner } from 'primereact/progressspinner';

type RemoveUserButtonProps = {
  onAccept: () => void;
  isLoading?: boolean;
};

const RemoveUserButton = ({ onAccept, isLoading }: RemoveUserButtonProps) => {
  const onClickRemove = () => {
    confirmDialog({
      message: 'Do you want to remove this user?',
      header: 'Remove Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: onAccept,
    });
  };

  return (
    <div>
      <Button
        onClick={onClickRemove}
        className="w-15rem bluwaiRed border-0 flex py-3"
      >
        {isLoading && <ProgressSpinner className="w-3rem h-3rem" />}
        {!isLoading && (
          <span className="mx-auto text-22px text-white">Remove</span>
        )}
      </Button>
      <ConfirmDialog />
    </div>
  );
};

export default RemoveUserButton;
