import React from 'react';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
} from 'chart.js';
import { Link } from 'react-router-dom';
import { BACKGROUND_COLORS, BORDER_COLORS } from '../../../../utils/graphConst';
import ColorIndicator from '../../../components/messages/ColorIndicator';

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale);

type APResumeProps = {
  opTotal: number;
  uapTotal: number;
};

const APResume = ({ opTotal, uapTotal }: APResumeProps) => {
  const pieData = {
    labels: ['Open Payables', 'Unapproved Payables'],
    datasets: [
      {
        data: [opTotal, uapTotal],
        backgroundColor: BACKGROUND_COLORS,
        borderColor: BORDER_COLORS,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <React.Fragment>
      <Pie data={pieData} options={options} className="w-10rem h-10rem" />
      <div className="flex-grow-1 pr-4 flex flex-column justify-content-around text-standard">
        <div className="flex justify-content-between">
          <Link
            to={`/accounting-dashboard/account-payables?reportType=openPayables`}
            className="flex gap-1 align-items-center"
          >
            <ColorIndicator colorIndex={0} />
            <div className="totalColor cursor-pointer hover:underline">
              Open Payables
            </div>
          </Link>
          <div>
            {opTotal.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </div>
        </div>
        <div className="flex justify-content-between">
          <Link
            to={`/accounting-dashboard/account-payables?reportType=unapprovedInvoices`}
            className="flex gap-1 align-items-center"
          >
            <ColorIndicator colorIndex={1} />
            <div className="totalColor cursor-pointer hover:underline">
              Unapproved Payables
            </div>
          </Link>
          <div>
            {uapTotal.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </div>
        </div>
        <div className="flex justify-content-between border-top-2 border-top-solid totalBorder pt-1">
          <div>Total Due</div>
          <div>
            {(opTotal + uapTotal || 0).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default APResume;
