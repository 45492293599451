import { useQuery } from '@tanstack/react-query';
import { formatCurrency } from '../../../../utils/formatUtils';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import FormControlledDropdown from '../../../components/inputs/FormInputs/FormControlledDropdown';
import FormControlledInput from '../../../components/inputs/FormInputs/FormControlledInput';
import {
  fetchJCJobPhaseEstimates,
  fetchJCJobPhases,
} from '../../../services/JobsContractsService';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  COEstimate,
  COFormFields,
} from '../../../Interfaces/Accounting/ACForms.interfaces';
import PhaseEstimateRow from './PhaseEstimateRow';
import {
  JCPhase,
  PhaseEstimate,
} from '../../../Interfaces/Accounting/JobsContracts.interface';
import { useCompanyContext } from '../../../context/CompanyContext';
import WrapperButton from '../../../components/buttons/WrapperButton';

type PhaseRowProps = {
  fieldId: string;
  fieldIndex: number;
  job: string;
  remove: (index: number) => void;
  costTypes: Record<number, string>;
  ums: Record<string, string>;
};

const PhaseRow = ({
  fieldId,
  fieldIndex,
  job,
  remove,
  costTypes,
  ums,
}: PhaseRowProps) => {
  const { selectedCompany } = useCompanyContext();
  const { setValue, resetField } = useFormContext<COFormFields>();
  const [estimatesTotal, setEstimatesTotal] = useState<number>();
  const phaseCode = useWatch({ name: `phases.${fieldIndex}.code` });
  const detailRows = useWatch({
    name: `phases.${fieldIndex}.details`,
  }) as COEstimate[];

  const phases = useQuery({
    queryKey: ['getJCJobPhases', selectedCompany, job],
    queryFn: ({ signal }) => {
      return fetchJCJobPhases(
        {
          companyID: selectedCompany!.id,
          job,
          hasCost: false,
        },
        signal
      );
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const estimates = useQuery({
    queryKey: ['getCOPhaseEstimates', selectedCompany, job, phaseCode, fieldId],
    queryFn: ({ signal }) => {
      resetField(`phases.${fieldIndex}.details`, { defaultValue: null });
      return fetchJCJobPhaseEstimates(
        selectedCompany!.id,
        job,
        [phaseCode],
        signal
      );
    },
    onSuccess: (data) => {
      const estRows = data?.map((est: PhaseEstimate) => {
        return {
          costType: est.CostType,
          ctLabel: costTypes[est.CostType],
          um: est.UM,
          umLabel: est.UM ? ums[est.UM] : est.UM,
        };
      });

      setValue(`phases.${fieldIndex}.details`, estRows);
    },
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && job && phaseCode),
  });

  useEffect(() => {
    if (phaseCode && phases.data) {
      const phase = phases.data.find(
        (phase: JCPhase) => phase.Phase === phaseCode
      );

      setValue(`phases.${fieldIndex}.description`, phase?.Description || '');
    }
  }, [phases.data, phaseCode, setValue]);

  useEffect(() => {
    let total = 0;

    detailRows?.forEach((field) => {
      total += field.cost || 0;
    });

    setEstimatesTotal(total || undefined);
  }, [detailRows, fieldIndex]);

  return (
    <div className="col-12 grid">
      <div className="col-12 sm:col-6">
        <label
          htmlFor={`phases.${fieldIndex}.code`}
          className="text-standard block mb-2"
        >
          Phase Code
        </label>
        <FormControlledDropdown
          formID={`phases.${fieldIndex}.code`}
          options={phases.data?.map((phase: JCPhase) => ({
            label: `${phase.Phase} - ${phase.Description}`,
            ...phase,
          }))}
          labelField="label"
          valueField="Phase"
          placeholder={
            phases.isError ? 'Failed to load phase codes!' : 'Select Phase Code'
          }
          isDisabled={phases.isError}
          isLoading={phases.isFetching}
          clearable={true}
          filter={true}
          resetFilterOnHide={true}
        />
      </div>
      <div className="col-12 sm:col-5">
        <label
          htmlFor={`phases.${fieldIndex}.description`}
          className="text-standard block mb-2"
        >
          Phase Description
        </label>
        <FormControlledInput
          defaultValue=""
          formID={`phases.${fieldIndex}.description`}
          autocomplete="off"
        />
      </div>
      <div className="col-12 sm:col-1 flex mt-3">
        <WrapperButton
          onClick={() => remove(fieldIndex)}
          className="h-fit m-auto"
        >
          <i className="pi pi-trash text-17px p-1 border-circle hover:bg-gray-300" />
        </WrapperButton>
      </div>
      {phaseCode && (
        <React.Fragment>
          {estimates.isFetching ? (
            <ProgressSpinner />
          ) : (
            <React.Fragment>
              {detailRows?.map((field, index) => {
                return (
                  <PhaseEstimateRow
                    key={`${index}-${field.costType}-${field.um}`}
                    fieldIndex={index}
                    phaseIndex={fieldIndex}
                  />
                );
              })}
              {detailRows?.length > 0 && (
                <div className={`flex-wrap grid col-12`}>
                  <div className="col-6 sm:col-3 sm:col-offset-6 text-right">
                    Total:
                  </div>
                  <div className="col-6 sm:col-3 text-right">
                    {estimatesTotal && formatCurrency(estimatesTotal)}
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default PhaseRow;
