import { useMutation } from '@tanstack/react-query';
import { downloadFile as download } from '../../../../utils/fileUtil';
import { AxiosError } from 'axios';
import { Toast } from 'primereact/toast';
import React, { useRef } from 'react';
import firebaseErrorMessages from '../../../components/errorMessages/firebase';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { useCompanyContext } from '../../../context/CompanyContext';
import { FS_File } from '../../../Interfaces/Accounting/FinancialStatements.interface';
import { downloadFile } from '../../../services/FinancialStatementsService';
import { ProgressSpinner } from 'primereact/progressspinner';
import WrapperButton from '../../../components/buttons/WrapperButton';

type DownloadFileProps = {
  file: FS_File;
  folderID: string;
};

const DownloadFile = ({ file, folderID }: DownloadFileProps) => {
  const { selectedCompany } = useCompanyContext();
  const toast = useRef<Toast>(null);

  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      return downloadFile(selectedCompany!.id, file.id, folderID);
    },
    onSuccess: (response) => {
      download(response);
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as { code: string };
      showToast(
        'error',
        toast,
        'Download File Error',
        firebaseErrorMessages[errorData?.code] ||
          'An error ocurred! please try again later',
        3000
      );
    },
  });

  const onClick = () => {
    mutate();
  };

  return (
    <div className="absolute left-0 ml-3 p-1 border-circle">
      <ErrorToast toastRef={toast} />
      {!isLoading && (
        <WrapperButton onClick={onClick}>
          <i className="pi pi-download text-17px hover:bg-gray-300" />
        </WrapperButton>
      )}
      {isLoading && (
        <ProgressSpinner style={{ width: '17px', height: '17px' }} />
      )}
    </div>
  );
};

export default DownloadFile;
