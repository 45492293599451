import React, { useCallback, useRef, useState } from 'react';
import {
  Column,
  ColumnEditorOptions,
  ColumnEventParams,
} from 'primereact/column';
import Table from '../../../components/Table/Table';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import {
  formatCurrency,
  formatPercentage,
} from '../../../../utils/formatUtils';
import {
  SCItem,
  SCBillingDraft,
} from '../../../Interfaces/Accounting/Subcontracts.interfaces';
import { InputText } from 'primereact/inputtext';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import SCPaymentProcess from './SCPaymentProcess';
import { Access } from '../../../Interfaces/Role.interfaces';

type SCPaymentWorksheetTableProps = {
  paymentItems: SCItem[];
  vendor: number;
  isApproval: boolean;
  draft?: SCBillingDraft;
  access: Access;
};

const SCPaymentWorksheetTable = React.forwardRef<
  HTMLDivElement,
  SCPaymentWorksheetTableProps
>(({ paymentItems, vendor, isApproval, draft, access }, ref) => {
  const [isEditing, setIsEditing] = useState(false);
  const [billingDraft, setBillingDraft] = useState<Record<number, number>>(
    draft?.amounts ?? {}
  );
  const toast = useRef<Toast>(null);
  const canEdit = !!access.editable || (access.shouldApprove && isApproval);

  const calcHeight = (rows: number) => {
    const headerHeight = 49;
    const footerHeight = 54;
    const rowHeight = 31;
    return headerHeight + footerHeight + rows * rowHeight + 3;
  };

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  const amountsChange = (slItem: number, amount?: number) => {
    if (amount) {
      billingDraft[slItem] = amount;
      setBillingDraft({ ...billingDraft });
    } else if (billingDraft[slItem]) {
      delete billingDraft[slItem];
      setBillingDraft({ ...billingDraft });
    }
  };

  const percentageEditor = (options: ColumnEditorOptions) => {
    const editorCallback = options.editorCallback;

    return (
      <React.Fragment>
        <InputText
          type="text"
          id="PercentageField"
          keyfilter={/\d|\./}
          className="w-full"
          value={options.value}
          onChange={(e) => {
            try {
              if (editorCallback) {
                editorCallback(e.target.value);
              }
            } catch (e) {
              console.log(e);
            }
          }}
        />
        <Tooltip
          target={`#PercentageField`}
          content={`Enter percentage amount`}
          position="top"
          event="focus"
        />
      </React.Fragment>
    );
  };

  const amountEditor = (options: ColumnEditorOptions) => {
    const editorCallback = options.editorCallback;

    return (
      <React.Fragment>
        <InputText
          type="text"
          id="AmountField"
          keyfilter={/\d|\./}
          className="w-full"
          value={options.value}
          onChange={(e) => {
            try {
              if (editorCallback) {
                editorCallback(e.target.value);
              }
            } catch (e) {
              console.log(e);
            }
          }}
        />
        <Tooltip
          target={`#AmountField`}
          content={`Enter billed amount`}
          position="top"
          event="focus"
        />
      </React.Fragment>
    );
  };

  const onPercentageEditComplete = (e: ColumnEventParams) => {
    const { rowData, newValue, field } = e;

    let errorMessage;
    if (newValue > 100) {
      errorMessage = 'The amount exceeds the limit.';
    } else if (newValue < rowData.BilledToDatePercentage) {
      errorMessage = 'The amount is under the limit.';
    }

    if (errorMessage) {
      showToast('error', toast, 'Edit Percentage Error', errorMessage, 3000);
      setIsEditing(false);
      return;
    }

    rowData[field] =
      newValue > rowData.BilledToDatePercentage ? newValue : null;
    const newAmount = (newValue * rowData.CurCost) / 100 - rowData.InvCost;
    const roundedAmount = Math.round(newAmount * 100) / 100;
    rowData.CurrentBilledAmount = newAmount ? roundedAmount : null;

    amountsChange(rowData.SLItem, roundedAmount);
    setIsEditing(false);
  };

  const onAmountEditComplete = (e: ColumnEventParams) => {
    const { rowData, newValue, field } = e;
    const number = parseFloat(newValue);

    const limit = rowData.CurCost - rowData.InvCost;
    if (number > limit) {
      showToast(
        'error',
        toast,
        'Edit Amount Error',
        'The amount exceeds the limit.',
        3000
      );
      setIsEditing(false);
      return;
    }

    rowData[field] = number || null;
    const percentage = ((number + rowData.InvCost) / rowData.CurCost) * 100;
    rowData.CompletedTD =
      number && percentage ? Math.round(percentage * 100) / 100 : null;

    amountsChange(rowData.SLItem, number);
    setIsEditing(false);
  };

  let units = 0;
  let costs = 0;
  let invUnits = 0;
  let invCosts = 0;
  let remUnits = 0;
  let remCosts = 0;
  paymentItems.forEach((item: SCItem) => {
    units += item.CurUnits;
    costs += item.CurCost;
    invUnits += item.InvUnits;
    invCosts += item.InvCost;
    remUnits += item.RemainingUnits;
    remCosts += item.RemainingCost;
  });

  return (
    <div className="pdfDivFitContent">
      <ErrorToast toastRef={toast} />
      <SCPaymentProcess
        draftAmounts={billingDraft}
        vendor={vendor}
        draftDefaultDate={draft?.invoiceDate}
        defaultDraftID={draft?.id}
        defaultSubmitted={draft?.submitted}
        isApproval={isApproval}
        blockAll={isEditing}
        access={access}
      >
        <Table
          id="sc-payment-worksheet"
          ref={ref}
          data={paymentItems}
          className={`mx-3 dashboardOptionShadow dobleHeader`}
          calcHeight={useCallback(calcHeight, [])}
          onRowMouseEnter={useCallback(onRowHover, [])}
          hideColumns={true}
        >
          <Column
            headerClassName={`tableHeader`}
            className="tableCell p-0 printHide"
            footerClassName="tableFooter "
          />
          <Column
            field="SLItem"
            header="SLItem"
            style={{ minWidth: '90px' }}
            headerClassName="tableHeader font-normal"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="Phase"
            header="Phase"
            style={{ minWidth: '110px' }}
            headerClassName="tableHeader font-normal"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="Description"
            header="Description"
            body={(sc) => {
              return <div className="scroll-text">{sc.Description}</div>;
            }}
            style={{ minWidth: '200px', maxWidth: '200px' }}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className=" text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="UM"
            header="UM"
            style={{ minWidth: '60px' }}
            headerClassName="tableHeader font-normal"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
            footer="Total"
            sortable
          />
          <Column
            field="CurUnits"
            header="Units"
            style={{ minWidth: '70px' }}
            headerClassName="tableHeader font-normal justify-content-center"
            className="justify-content-end text-standard blackText tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={units}
            sortable
          />
          <Column
            field="CurCost"
            header="Costs"
            body={(sc) => formatCurrency(sc.CurCost)}
            style={{ minWidth: '120px' }}
            headerClassName="tableHeader font-normal text-center justify-content-center"
            className="justify-content-end text-standard blackText tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={formatCurrency(costs)}
            sortable
          />
          <Column
            field="InvUnits"
            header="Invoiced Units"
            style={{ minWidth: '90px' }}
            headerClassName="tableHeader font-normal text-center justify-content-center"
            className="justify-content-end text-standard blackText tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={invUnits}
            sortable
          />
          <Column
            field="InvCost"
            header="Invoiced Costs"
            body={(sc) => formatCurrency(sc.InvCost)}
            style={{ minWidth: '120px' }}
            headerClassName="tableHeader font-normal text-center justify-content-center"
            className="justify-content-end text-standard blackText tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={formatCurrency(invCosts)}
            sortable
          />
          <Column
            field="RemainingCost"
            header="Remaining Costs"
            body={(sc) => formatCurrency(sc.RemainingCost)}
            style={{ minWidth: '120px' }}
            headerClassName="tableHeader font-normal text-center justify-content-center"
            className="justify-content-end text-standard blackText tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={formatCurrency(remCosts)}
            sortable
          />
          <Column
            field="RemainingUnits"
            header="Remaining Units"
            style={{ minWidth: '120px' }}
            headerClassName="tableHeader font-normal text-center justify-content-center"
            className="justify-content-end text-standard blackText tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={remUnits}
            sortable
          />
          <Column
            field="BilledToDatePercentage"
            header="Prior % Billed"
            body={(sc) => formatPercentage(sc.BilledToDatePercentage)}
            style={{ minWidth: '140px' }}
            headerClassName="tableHeader font-normal text-center justify-content-center"
            className="justify-content-end text-standard blackText tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={formatPercentage((invCosts / costs || 0) * 100)}
            sortable
          />
          <Column
            field="CompletedTD"
            header="Total % completed to date"
            body={(cb) =>
              !canEdit ||
              cb.BilledToDatePercentage === 100 ? null : cb.CompletedTD ? (
                <div className="w-full text-right totalColor printColor cursor-pointer">
                  {formatPercentage(cb.CompletedTD)}
                </div>
              ) : (
                <div className="w-full cursor-pointer flex justify-content-center">
                  <i className={`pi pi-pencil totalColor printHide`} />
                </div>
              )
            }
            style={{ minWidth: '150px' }}
            headerClassName="tableHeader font-normal text-center"
            className="justify-content-center text-standard blackText tableCell"
            editor={(options) => {
              if (canEdit && options.rowData.BilledToDatePercentage !== 100) {
                setIsEditing(true);
                return percentageEditor(options);
              }

              return null;
            }}
            onCellEditComplete={(options) =>
              canEdit && options.rowData.BilledToDatePercentage !== 100
                ? onPercentageEditComplete(options)
                : null
            }
            footerClassName="tableFooter block text-right border-top-2 mt-4"
          />
          <Column
            field="CurrentBilledAmount"
            header="Current Billed Amount"
            body={(cb) =>
              !canEdit ||
              cb.BilledToDatePercentage ===
                100 ? null : cb.CurrentBilledAmount ? (
                <div className="w-full text-right totalColor printColor cursor-pointer">
                  {formatCurrency(cb.CurrentBilledAmount)}
                </div>
              ) : (
                <div className="w-full cursor-pointer flex justify-content-center">
                  <i className={`pi pi-pencil totalColor printHide`} />
                </div>
              )
            }
            style={{ minWidth: '160px' }}
            headerClassName="tableHeader font-normal text-center"
            className="justify-content-center text-standard blackText tableCell"
            editor={(options) => {
              setIsEditing(true);
              return canEdit && options.rowData.BilledToDatePercentage !== 100
                ? amountEditor(options)
                : null;
            }}
            onCellEditComplete={(options) =>
              canEdit && options.rowData.BilledToDatePercentage !== 100
                ? onAmountEditComplete(options)
                : null
            }
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={(e) => {
              const records = e.props.value;
              let total = 0;
              records.forEach((record: SCItem) => {
                total += record.CurrentBilledAmount ?? 0;
              });
              return total ? formatCurrency(total) : '';
            }}
          />
          <Column
            headerClassName={`tableHeader`}
            className="tableCell p-0 printHide"
            footerClassName="tableFooter "
          />
        </Table>
      </SCPaymentProcess>
    </div>
  );
});

const transactionsAreEqual = (
  prevTransactions: Readonly<SCPaymentWorksheetTableProps>,
  nextTransactions: Readonly<SCPaymentWorksheetTableProps>
) => {
  return prevTransactions.paymentItems === nextTransactions.paymentItems;
};

export default React.memo(SCPaymentWorksheetTable, transactionsAreEqual);
