import FormControlledInput from '../../../components/inputs/FormInputs/FormControlledInput';

export type FolderFormFields = {
  folderName: string;
};

const FolderForm = () => {
  return (
    <div className="grid">
      <div className="col-12">
        <label htmlFor="firstname" className="block mb-2 text-standard">
          Folder Name
        </label>
        <FormControlledInput
          defaultValue=""
          formID="folderName"
          rules={{
            required: {
              value: true,
              message: 'Folder Name is a required field',
            },
          }}
        />
      </div>
    </div>
  );
};

export default FolderForm;
