import { Route, Routes } from 'react-router-dom';
import SystemError from '../components/messages/SystemError';
import ErrorBoundary from '../HOCs/ErrorBoundary';
import Roles from '../pages/Admin/Roles/Roles';
import RolesRegister from '../pages/Admin/Roles/RolesRegister';
import RolesUpdate from '../pages/Admin/Roles/RolesUpdate';
import UserForm from '../pages/Admin/Users/UserRegister';
import Users from '../pages/Admin/Users/Users';
import UserUpdate from '../pages/Admin/Users/UserUpdate';

const AdminRoutes = () => {
  return (
    <div className="dashboardBG">
      <ErrorBoundary fallBack={<SystemError />}>
        <Routes>
          <Route path="/users" element={<Users />} />
          <Route path="/users/register" element={<UserForm />} />
          <Route path="/users/:userID" element={<UserUpdate />} />
          <Route path="/roles" element={<Roles />} />
          <Route path="/roles/register" element={<RolesRegister />} />
          <Route path="/roles/:roleID" element={<RolesUpdate />} />
        </Routes>
      </ErrorBoundary>
    </div>
  );
};

export default AdminRoutes;
