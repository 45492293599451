import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCookies } from 'react-cookie';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const cookiesObjectsList = useCookies(['company']);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [timeOut, setTimeOut] = useState(0);
  const location = useLocation();

  const logOut = () => {
    signOut(auth);
    const removeCookie = cookiesObjectsList[2];
    removeCookie('company');
    navigate('/login');
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log(user);
        const lastSignIn = new Date(user?.metadata.lastSignInTime || '');
        const currentTime = new Date();
        const logOutTime = parseInt(process.env['NX_LOG_OUT_TIME'] ?? '0');
        const timeDifference = currentTime.getTime() - lastSignIn.getTime();

        if (timeDifference >= logOutTime) {
          console.log('Logging out');
          logOut();
        } else {
          const timeOut = logOutTime - timeDifference;

          setTimeOut(timeOut);
          setAuthenticated(true);
        }
      } else {
        setAuthenticated(false);
      }
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (timeOut) {
      const autoLogoutTimer = setTimeout(() => {
        logOut();
      }, timeOut);

      return () => clearTimeout(autoLogoutTimer);
    }

    return;
  }, [timeOut]);

  if (loading) {
    return (
      <div className="h-screen flex align-items-center">
        <ProgressSpinner />
      </div>
    );
  }

  if (!authenticated) {
    console.log('Redirecting to login');
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;
