import { Controller, useFormContext } from 'react-hook-form';
import DropdownStyled from '../../components/inputs/StyledInputs/DropdownStyled';
import FormControlledInput from '../../components/inputs/FormInputs/FormControlledInput';
import FormErrorMessage from '../../components/messages/FormErrorMessage';
import { ROLE_TYPE_OPTIONS } from '../../../utils/rolesConst';
import ModulesAccordion from './ModulesAccordion';
import { AccessForm } from '../../Interfaces/Role.interfaces';
import { RadioButton } from 'primereact/radiobutton';
import { useQuery } from '@tanstack/react-query';
import { getSystemSections } from '../../services/RolesService';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessage from '../../components/messages/ErrorMessage';

export type RegisterFormFields = {
  name: string;
  type: string;
  access: AccessForm[];
  hidePhaseCodes: string;
};

type RegisterFormProps = {
  disableName?: boolean;
};

const RegisterForm = ({ disableName }: RegisterFormProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { data, isFetching, isError } = useQuery({
    queryKey: ['getSystemSections'],
    queryFn: ({ signal }) => {
      return getSystemSections(signal);
    },
    refetchOnWindowFocus: false,
  });

  if (isFetching) {
    return (
      <div className="flex mx-auto">
        <ProgressSpinner />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="pt-8">
        <ErrorMessage
          content={'Failed to obtain sections! Please try again later.'}
        />
      </div>
    );
  }

  return (
    <div className="grid mx-3 justify-content-center">
      <div className="col-12 sm:col-6">
        <label htmlFor="name" className="text-standard block mb-2">
          Role name
        </label>
        <FormControlledInput
          defaultValue=""
          formID="name"
          disabled={disableName}
          rules={{
            required: {
              value: true,
              message: 'Role name is a required field',
            },
          }}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="type" className="text-standard block mb-2">
          Role type
        </label>
        <Controller
          defaultValue=""
          control={control}
          name="type"
          rules={{
            required: {
              value: true,
              message: 'Role type is a required field',
            },
          }}
          render={({ field: { ref, ...otherFields } }) => (
            <DropdownStyled
              id="type"
              options={ROLE_TYPE_OPTIONS}
              labelField="label"
              valueField="value"
              placeholder={'Select role type'}
              clearable={false}
              inputRef={ref}
              error={!!errors['userType']}
              {...otherFields}
            />
          )}
        />
        {errors['type'] && (
          <FormErrorMessage errorMessage={errors['type'].message as string} />
        )}
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="hidePhaseCodes" className="text-standard block mb-2">
          Hide Phase Codes
        </label>
        <Controller
          defaultValue={'no'}
          control={control}
          name="hidePhaseCodes"
          render={({ field }) => (
            <div className="flex align-items-center justify-content-around text-standard h-3rem">
              <div className="flex align-items-center">
                <RadioButton
                  inputId="yes"
                  {...field}
                  value="yes"
                  checked={field.value === 'yes'}
                />
                <label htmlFor="yes" className="ml-1 mr-3">
                  Yes
                </label>
              </div>
              <div className="flex align-items-center">
                <RadioButton
                  inputId="no"
                  {...field}
                  inputRef={field.ref}
                  value="no"
                  checked={field.value === 'no'}
                />
                <label htmlFor="no" className="ml-1 mr-3">
                  No
                </label>
              </div>
            </div>
          )}
        />
      </div>
      <div className="col-12 sm:col-6" />
      <ModulesAccordion sections={data} />
    </div>
  );
};

export default RegisterForm;
