import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormErrorMessage from '../../messages/FormErrorMessage';
import { InputMask, InputMaskProps } from 'primereact/inputmask';

type FormControlledInputMaskProps = InputMaskProps & {
  defaultValue: string | number;
  formID: string;
  rules?: object;
  disabled?: boolean;
  placeholder?: string;
  autocomplete?: string;
};

const FormControlledInputMask = ({
  defaultValue,
  formID,
  rules,
  disabled,
  placeholder,
  autocomplete,
  ...otherProps
}: FormControlledInputMaskProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const fieldError = errors[formID];

  return (
    <React.Fragment>
      <Controller
        defaultValue={defaultValue}
        control={control}
        name={formID}
        rules={rules}
        render={({ field }) => (
          <InputMask
            id={formID}
            placeholder={placeholder}
            disabled={disabled}
            className={`w-full blackText text-standard ${fieldError && 'p-invalid'}`}
            autoComplete={autocomplete}
            {...otherProps}
            {...field}
          />
        )}
      />
      {fieldError && (
        <FormErrorMessage errorMessage={fieldError.message as string} />
      )}
    </React.Fragment>
  );
};

export default FormControlledInputMask;
