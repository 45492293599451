import { MouseEvent } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../../assets/layout/images/bluwai_Logo.png';
import BluwaiFavicon from '../../../assets/layout/images/bluwai_Favicon_blue_red.png';
import ProfileIcon from './ProfileIcon';
import CompanySelector from './CompanySelector';

type AppTopbarProps = {
  onMenuButtonClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  active: boolean;
  mode: string;
};

const AppTopbar = ({ onMenuButtonClick, active, mode }: AppTopbarProps) => {
  const location = useLocation();

  return (
    <div
      className={`bg-white relative layout-topbar navbar flex flex-column ${
        active && mode === 'desktop' && 'navbarDesktop'
      }`}
    >
      <div className="flex-wrap lg:flex-nowrap layout-topbar-left align-items-center h-full gap-2 w-full p-2">
        {onMenuButtonClick && (
          <button
            type="button"
            className="flex jsutify-content-center shadow-0 p-link h-2rem w-3rem lg:h-3rem lg:w-3rem border-noround "
            onClick={onMenuButtonClick}
          >
            <img
              src={BluwaiFavicon}
              alt="Bluwai favicon"
              className="h-full w-auto m-auto cursor-pointer"
            />
          </button>
        )}
        <Link to={'/'}>
          <img
            id="app-logo"
            src={Logo}
            alt="Bluwai"
            className="h-3rem lg:h-5rem w-auto cursor-pointer mr-auto sm:mr-0"
          />
        </Link>
        <div className="mx-auto flex-order-3 sm:flex-order-0 px-3 pb-2 lg:p-0 w-full sm:w-auto min-w-0">
          {!location.pathname.toLocaleLowerCase().includes('admin') && (
            <CompanySelector />
          )}
        </div>
        <div className="mx-auto sm:mr-3 lg:ml-0 flex flex-grow-1 sm:flex-grow-0 justify-content-end gap-4">
          <ProfileIcon />
        </div>
      </div>
      <div className="border-1 w-full dividerBackground dividerShadow" />
    </div>
  );
};

export default AppTopbar;
