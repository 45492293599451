import axios from 'axios';
import { auth } from '../../firebase';
import { cleanJsonEmptyValues } from '../../utils/formatUtils';

const apiUrl = process.env['NX_API_URL'];

type SCFilters = {
  companyID: string;
  jobs?: string[];
  subcontractor?: string;
  dates?: Date[];
  info?: string;
};

export const fetchSCLatestPayments = async (
  params: SCFilters,
  signal?: AbortSignal
) => {
  const { dates, ...otherParams } = params;
  let dateStart = null;
  let dateEnd = null;

  if (dates) {
    dateEnd = dates[1] || dates[0];
    dateStart = dates[0];
  }
  const cleanedParams = cleanJsonEmptyValues(otherParams);

  const response = await axios.get(
    `${apiUrl}/api/subcontracts/latestPayments`,
    {
      signal,
      params: { ...cleanedParams, dateEnd, dateStart },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

type SCWorkSheetFilters = {
  companyID: string;
  job: string;
  subcontract?: string;
  info?: string;
  edit?: boolean;
  draftID?: string;
  hideBilled?: boolean;
};

export const fetchSCPaymentWorksheet = async (
  params: SCWorkSheetFilters,
  signal?: AbortSignal
) => {
  const cleanedParams = cleanJsonEmptyValues(params);
  const response = await axios.get(`${apiUrl}/api/subcontracts/items`, {
    signal,
    params: cleanedParams,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const fetchSCPaymentStatus = async (
  params: SCWorkSheetFilters,
  signal?: AbortSignal
) => {
  const cleanedParams = cleanJsonEmptyValues(params);
  const response = await axios.get(
    `${apiUrl}/api/subcontracts/subcontractsPaymentStatus`,
    {
      signal,
      params: cleanedParams,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const fetchSCPaymentItemsStatus = async (
  companyID: string,
  subcontract: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/subcontracts/itemsPaymentStatus`,
    {
      signal,
      params: { companyID, subcontract },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const fetchSCItemPayments = async (
  companyID: string,
  subcontract: string,
  slItem: number,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/subcontracts/itemPayments`, {
    signal,
    params: { companyID, subcontract, slItem },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

type SCPaymentDetailsFilters = {
  companyID: string;
  apref: string;
  sl: string;
  paidDate?: Date;
};

export const fetchSCPaymentDetails = async (
  params: SCPaymentDetailsFilters,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/subcontracts/paymentDetails`,
    {
      signal,
      params: params,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const fetchSCPeriodPaymentDetails = async (
  companyID: string,
  periodStart: Date,
  periodEnd: Date,
  job?: string,
  vendor?: number,
  pm?: number,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/subcontracts/periodPaymentDetails`,
    {
      signal,
      params: { companyID, job, vendor, periodStart, periodEnd, pm },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const fecthSCJobs = async (
  companyID: string,
  status?: number[],
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/subcontracts/subcontractJobs`,
    {
      signal,
      params: { companyID, status },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

type SCVendorsFilters = {
  companyID: string;
  job?: string;
  subcontract?: string;
};

export const fecthSCVendors = async (
  params: SCVendorsFilters,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/subcontracts/subcontractVendors`,
    {
      signal,
      params,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const fecthSubcontracts = async (
  companyID: string,
  job?: string[],
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/subcontracts/subcontracts`, {
    signal,
    params: { companyID, job },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

type fetchSCUnpaidInvoicesArgs = {
  companyID: string;
  subcontract: string;
};

export const fetchSCUnpaidInvoices = async (
  params: fetchSCUnpaidInvoicesArgs,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/subcontracts/unpaidInvoices`,
    {
      signal,
      params,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const fetchSCScheduleDates = async (
  companyID: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/subcontracts/schedule`, {
    signal,
    params: { companyID },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const fetchSCPeriodPayments = async (
  companyID: string,
  groupType: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/subcontracts/periodPayments`,
    {
      signal,
      params: { companyID, groupType },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

type SCNextInvoiceNumberArgs = {
  companyID: string;
  invoiceDate: Date;
  vendor: number;
};

export const fetchSCNextInvoiceNumber = async (
  params: SCNextInvoiceNumberArgs,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/subcontracts/nextInvoiceNumber`,
    {
      signal,
      params,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  return response.data;
};

type postSCPaymentArgs = {
  companyID: string;
  job: string;
  subcontract: string;
  amounts: Record<number, number>;
  invoiceDate: Date;
};

export const postSCPayment = async (params: postSCPaymentArgs) => {
  const response = await axios.post(
    `${apiUrl}/api/subcontracts/payment`,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  return response.data;
};

export const putSCPayment = async (
  draftID: string,
  params: { amounts: Record<number, number>; invoiceDate: Date },
  fromApproval: boolean
) => {
  const response = await axios.put(
    `${apiUrl}/api/subcontracts/payment/${draftID}`,
    { ...params, fromApproval },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  return response.data;
};

export const putSCSubmitPayment = async (draftID: string) => {
  const response = await axios.put(
    `${apiUrl}/api/subcontracts/payment/submit/${draftID}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  return response.data;
};

type getSCPaymentFilters = {
  companyID: string;
  job: string;
  subcontract: string;
  userID?: string;
  isSubmitted?: boolean;
  isApproved?: boolean;
};

export const fetchSCPaymentDrafts = async (
  params: getSCPaymentFilters,
  signal?: AbortSignal
) => {
  const cleanedParams = cleanJsonEmptyValues(params);

  const response = await axios.get(`${apiUrl}/api/subcontracts/payment`, {
    signal,
    params: cleanedParams,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  return response.data;
};

export const putSCApprovePayment = async (draftID: string) => {
  const response = await axios.put(
    `${apiUrl}/api/subcontracts/payment/approve/${draftID}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  return response.data;
};

export const putSCRejectPayment = async (draftID: string, reason: string) => {
  const response = await axios.put(
    `${apiUrl}/api/subcontracts/payment/reject/${draftID}`,
    { reason },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  return response.data;
};
