import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCompanyContext } from '../../../context/CompanyContext';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import ContractListTable from './ContractListTable';
import { fecthCBContractList } from '../../../services/ContractBillingsService';

const ContractListTableContainer = React.forwardRef<HTMLDivElement>(
  (props, ref) => {
    const { selectedCompany } = useCompanyContext();

    const { data, isFetching, isError } = useQuery({
      queryKey: ['getCBContractList', selectedCompany],
      queryFn: ({ signal }) => {
        return fecthCBContractList(selectedCompany!.id, signal);
      },
      refetchOnWindowFocus: false,
      enabled: !!selectedCompany,
    });

    if (isFetching || !selectedCompany)
      return (
        <div className="text-center mx-auto mt-3">
          <ProgressSpinner />
        </div>
      );

    if (isError)
      return (
        <ErrorMessage
          content={'Failed to obtain data! Please try again later.'}
        />
      );

    return <ContractListTable contracts={data} />;
  }
);

export default ContractListTableContainer;
