import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { PRTimesheetFilters } from './TimesheetFilters';

const PRTimesheetParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setValue } = useFormContext<PRTimesheetFilters>();
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    let value = searchParams.get('submittedID');
    if (value) {
      setValue('submittedID', value);
      setSearchParams();
    }

    setRendered(true);
  }, [setValue, searchParams, setSearchParams, rendered, setRendered]);

  return <span />;
};

export default PRTimesheetParams;
