import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { downloadFile } from '../../utils/fileUtil';
import { useCompanyContext } from '../context/CompanyContext';
import {
  fetchAttachmentFile,
  fetchAttachmentFiles,
  fetchAttachmentsIDs,
} from '../services/AttachmentService';

type useFetchAttachmentsArgs = {
  uniqueAttchID: string;
  zipFilename: string;
};

const useFetchAttachments = ({
  uniqueAttchID,
  zipFilename,
}: useFetchAttachmentsArgs) => {
  const { selectedCompany } = useCompanyContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const attachID = useMutation({
    mutationFn: fetchAttachmentFile,
    onSuccess: (response) => {
      downloadFile(response);
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
      setError('An error ocurred while downloading the file! Try again later');
    },
  });

  const files = useMutation({
    mutationFn: (attachmentIdList: number[]) => {
      return fetchAttachmentFiles(attachmentIdList, zipFilename);
    },
    onSuccess: (data) => {
      downloadFile(data);
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
      setError('An error ocurred while downloading the files! Try again later');
    },
  });

  const { mutate } = useMutation({
    mutationFn: (uniqueAttchID: string) => {
      return fetchAttachmentsIDs(selectedCompany!.id, uniqueAttchID);
    },
    onSuccess: (data) => {
      if (data.length > 1) {
        const attachmentIDList = data.map(
          (element: { AttachmentID: number }) => element.AttachmentID
        );
        files.mutate(attachmentIDList);
      } else {
        attachID.mutate(data[0]?.AttachmentID);
      }
    },
    onError: () => {
      setLoading(false);
      setError(
        'An error ocurred while searching for the file! Try again later'
      );
    },
  });

  const fetchAttachments = () => {
    setLoading(true);
    mutate(uniqueAttchID);
  };

  return { fetchAttachments, loading, error };
};

export default useFetchAttachments;
