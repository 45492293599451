import axios from 'axios';
import { auth } from '../../firebase';
import { Company } from '../Interfaces/User.interfaces';

const apiUrl = process.env['NX_API_URL'] + '/api/vendors/invoices';

export type fetchAccountsPayablesVendorInvoiceArgs = {
  company: Company;
  cmRef: string;
  cmAcct: number;
  vendor: string;
  vendorGroup: string;
};

export const fetchAccountsPayablesVendorInvoices = async (
  params: fetchAccountsPayablesVendorInvoiceArgs,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/history`, {
    signal,
    params: {
      companyID: params.company.id,
      source: params.company.source,
      cmRef: params.cmRef,
      cmAcct: params.cmAcct,
      vendor: params.vendor,
      vendorGroup: params.vendorGroup,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const data = response.data;
  return data;
};
