import { useEffect, useRef } from 'react';
import CBResumeTableContainer from '../../features/contractBillings/CBResume/CBResumeTableContainer';
import { FormProvider, useForm } from 'react-hook-form';
import CBFilters, {
  REPORT_OPTIONS,
  fetchContractBillingFilters,
} from '../../features/contractBillings/CBFilters';
import ContractListTableContainer from '../../features/contractBillings/ContractList/ContractListTableContainer';
import ContractProgressTableContainer from '../../features/contractBillings/ContractProgress/ContractProgressTableContainer';
import CBReportHeader from '../../features/contractBillings/CBReport/CBReportHeader';
import CBReport from '../../features/contractBillings/CBReport/CBReport';
import checkPermissions from '../../HOCs/CheckPermissions';
import CBParams from '../../features/contractBillings/CBParams';
import useProtectedReportOptions from '../../hooks/useProtectedReportOptions';
import ReportSelector from '../../components/report-selector/ReportSelector';
import FiltersMenu from '../../components/filters-menu/FiltersMenu';
import { reportsPerSection } from 'apps/tmr-frontend/src/utils/rolesConst';
import { useCompanyContext } from '../../context/CompanyContext';
import MonthlyBillingContainer from '../../features/contractBillings/MonthlyBilling/MonthlyBillingContainer';

const ContractBillings = () => {
  const { selectedCompany } = useCompanyContext();
  const prevSelectedCompanyRef = useRef<string | null>(null);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const methods = useForm<fetchContractBillingFilters>({
    defaultValues: { year: new Date() },
  });
  const { watch, resetField } = methods;
  const reportType = watch('reportType');

  const [protectedReports] = useProtectedReportOptions({
    reports: REPORT_OPTIONS,
  });

  const CBSummary = checkPermissions({
    sectionsToAccess: reportsPerSection['contract billings'],
    Children: CBResumeTableContainer,
  });

  const ContractList = checkPermissions({
    sectionsToAccess: ['contract_list'],
    Children: ContractListTableContainer,
  });

  const Progress = checkPermissions({
    sectionsToAccess: ['contract_progress_worksheet'],
    Children: ContractProgressTableContainer,
  });

  const MonthlyBilling = checkPermissions({
    sectionsToAccess: ['monthly_billing'],
    Children: MonthlyBillingContainer,
  });

  const handleReportChange = () => {
    resetField('contract', { defaultValue: null });
    resetField('itemsState', { defaultValue: 'show' });
    resetField('submittedID', { defaultValue: null });
    resetField('contracts', { defaultValue: null });
    resetField('year');
  };

  useEffect(() => {
    if (prevSelectedCompanyRef.current === null) {
      prevSelectedCompanyRef.current = selectedCompany?.id || null;
    } else if (prevSelectedCompanyRef.current !== selectedCompany?.id) {
      methods.reset();
      prevSelectedCompanyRef.current = selectedCompany?.id || null;
    }
  }, [selectedCompany, methods]);

  return (
    <div className="h-full flex flex-column">
      <FormProvider {...methods}>
        <div className="mt-6 mx-5 flex gap-2 flex-wrap justify-content-start">
          <CBSummary />
        </div>
        <div className="flex flex-column  gap-2 mx-3  mt-3">
          <div className="flex flex-row gap-2 justify-content-between">
            <ReportSelector
              protectedReports={protectedReports}
              onChange={() => {
                handleReportChange();
              }}
            />
            <div className="flex w-full justify-content-between  gap-2">
              <FiltersMenu>
                <CBFilters />
              </FiltersMenu>
              <CBReport elementRef={tableRef} />
            </div>
          </div>
        </div>
        <div className="mb-3 printBackground pdfDivFitContent" ref={tableRef}>
          <CBReportHeader />
          {reportType === 'contractList' && <ContractList />}
          {reportType === 'contractProgress' && <Progress />}
          {reportType === 'monthlyBilling' && <MonthlyBilling />}
        </div>
        <CBParams />
      </FormProvider>
    </div>
  );
};

export default ContractBillings;
