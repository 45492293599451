import { Button, ButtonProps } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';

type LoadingButtonProps = ButtonProps & {
  isLoading: boolean;
  label: string;
  fontSize?: string;
  bgColor?: string;
};

const LoadingButton = ({
  isLoading,
  label,
  fontSize,
  bgColor,
  ...otherProps
}: LoadingButtonProps) => {
  return (
    <Button
      type="submit"
      className={`w-10rem ${
        bgColor ?? 'bluwaiBlue'
      } border-0 flex m-auto py-3 relative`}
      disabled={isLoading}
      {...otherProps}
    >
      {isLoading && (
        <ProgressSpinner className="w-2rem h-2rem absolute mx-auto top-50 left-50 transform-50" />
      )}
      <span
        className={`mx-auto ${fontSize ?? 'text-18px'} text-white ${
          isLoading && 'visibility-hidden'
        }`}
      >
        {label}
      </span>
    </Button>
  );
};

export default LoadingButton;
