import React, { useState } from 'react';
import WrapperButton from '../buttons/WrapperButton';

type ResumeContentProps = {
  text: string;
  limit: number;
};

const ResumeContent = ({ text, limit }: ResumeContentProps) => {
  const [expanded, setExpanded] = useState(false);

  if (text.length < limit) {
    return <div className="w-full text-center">{text}</div>;
  }

  if (expanded) {
    return (
      <WrapperButton
      className="text-standard text-left"
        onClick={(e) => {
          setExpanded(false);
          e.stopPropagation();
        }}
      >
        {text}
      </WrapperButton>
    );
  }

  return (
    <WrapperButton
      className="totalColor text-standard"
      onClick={(e) => {
        setExpanded(true);
        e.stopPropagation();
      }}
    >
      {text.substring(0, limit) + '...'}
    </WrapperButton>
  );
};

export default ResumeContent;
