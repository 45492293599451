import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCompanyContext } from '../../../context/CompanyContext';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { fecthJCPhaseMaster } from '../../../services/JobsContractsService';
import JCPhaseMasterTable from './JCPhaseMasterTable';
import { useWatch } from 'react-hook-form';
import { JobContractFilters } from '../JCFilters';

const JCPhaseMasterContainer = React.forwardRef<HTMLDivElement>(
  (props, ref) => {
    const { selectedCompany } = useCompanyContext();
    const info = useWatch<JobContractFilters>({ name: 'info' }) as string;

    const { data, isFetching, isError } = useQuery({
      queryKey: ['getJCPhaseMaster', selectedCompany, info],
      queryFn: ({ signal }) => {
        return fecthJCPhaseMaster(
          selectedCompany!.id,
          info || undefined,
          signal
        );
      },
      refetchOnWindowFocus: false,
      enabled: !!selectedCompany,
    });

    if (isFetching)
      return (
        <div className="text-center mx-auto mt-3">
          <ProgressSpinner />
        </div>
      );

    if (isError)
      return (
        <ErrorMessage
          content={'Failed to obtain data! Please try again later.'}
        />
      );

    return <JCPhaseMasterTable phases={data} />;
  }
);

export default JCPhaseMasterContainer;
