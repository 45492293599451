import { Tooltip } from 'primereact/tooltip';
import React from 'react';

type TooltipResume = {
  id: string;
  text: string;
  maximum: number;
};

const TooltipResume = ({ text, maximum, id }: TooltipResume) => {
  if (!text) {
    return null;
  }

  if (text.length <= maximum) {
    return <span>{text}</span>;
  }

  return (
    <div
      className="text-center custom-target-icon"
      data-pr-position="top"
      data-pr-tooltip={text}
      id={`T${id}`}
    >
      {text.substring(0, maximum) + '...'}
      <Tooltip target={`#T${id}`} />
    </div>
  );
};

export default TooltipResume;
