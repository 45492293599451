import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useCompanyContext } from '../../context/CompanyContext';
import { useQuery } from '@tanstack/react-query';
import {
  fecthCBContracts,
  getSubmitedProgressBilling,
} from '../../services/ContractBillingsService';
import { MODULES } from '../../../utils/rolesConst';
import FormControlledDropdown from '../../components/inputs/FormInputs/FormControlledDropdown';
import { formatUTCDate } from 'apps/tmr-frontend/src/utils/formatUtils';
import { ProgressBillingDraft } from '../../Interfaces/Accounting/ContractBillings.interface';
import { useRolesAccessContext } from '../../context/RolesAccessContext';
import MultipleSelectStyled from '../../components/inputs/StyledInputs/MultipleSelectStyled';
import FormControlledCalendar from '../../components/inputs/FormInputs/FormControlledCalendar';

export const REPORT_OPTIONS = MODULES['contract billings'];

export const ITEMS_STATES = [
  { label: 'Show 100% Billed', value: 'show' },
  { label: 'Hide 100% Billed', value: 'hide' },
];

export type fetchContractBillingFilters = {
  reportType?: string;
  contract?: string;
  contracts?: string[];
  submittedID?: string;
  itemsState?: string;
  year?: Date;
};

const CBFilters = () => {
  const { rolesAcess } = useRolesAccessContext();
  const canApprove = rolesAcess?.find(
    (access) =>
      access.report === 'contract_progress_worksheet' && access.shouldApprove
  );
  const { resetField, watch, control } =
    useFormContext<fetchContractBillingFilters>();
  const { selectedCompany } = useCompanyContext();
  const { reportType, contract } = watch();
  const isEnabled = selectedCompany && reportType === 'contractProgress';

  const { data, isFetching, isError } = useQuery({
    queryKey: ['getCBActiveContracts', selectedCompany],
    queryFn: ({ signal }) =>
      fecthCBContracts({ companyID: selectedCompany!.id, status: 1 }, signal),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
    staleTime: 1000 * 60 * 10,
  });

  const submittedPBs = useQuery({
    queryKey: ['getSubmittedProgressBillings', selectedCompany, contract],
    queryFn: ({ signal }) =>
      getSubmitedProgressBilling(selectedCompany!.id, contract ?? '', signal),
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && contract && canApprove),
    staleTime: 1000 * 60 * 10,
  });

  const onContractChange = () => {
    resetField('submittedID', { defaultValue: null });
    resetField('itemsState', { defaultValue: 'show' });
  };

  const monthlyContracts = useQuery({
    queryKey: ['getMonthlyContracts', selectedCompany],
    queryFn: ({ signal }) => {
      return fecthCBContracts(
        { companyID: selectedCompany!.id, department: 1 },
        signal
      );
    },
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && reportType === 'monthlyBilling'),
    staleTime: 1000 * 60 * 10,
  });

  return (
    <React.Fragment>
      {reportType === 'contractProgress' && (
        <React.Fragment>
          <div className="col">
            <FormControlledDropdown
              formID="contract"
              options={data}
              labelField="Contract"
              valueField="Contract"
              placeholder={
                isError ? 'Failed to load contracts!' : 'Select Contract'
              }
              isDisabled={isError}
              isLoading={isFetching}
              clearable={true}
              filter={true}
              resetFilterOnHide={true}
              additionalChange={onContractChange}
            />
          </div>
          <div className="col">
            <FormControlledDropdown
              formID="contract"
              options={data}
              labelField="Description"
              valueField="Contract"
              placeholder={
                isError
                  ? 'Failed to load contract names!'
                  : 'Select Contract Name'
              }
              isDisabled={isError}
              isLoading={isFetching}
              clearable={true}
              filter={true}
              resetFilterOnHide={true}
              additionalChange={onContractChange}
            />
          </div>
        </React.Fragment>
      )}
      {reportType === 'contractProgress' && (
        <div className="col">
          <FormControlledDropdown
            formID="itemsState"
            defaultValue={'show'}
            options={ITEMS_STATES}
            labelField="label"
            valueField="value"
            placeholder={'Select billed items state'}
            isDisabled={!contract}
            clearable={false}
          />
        </div>
      )}
      {reportType === 'contractProgress' && canApprove && (
        <div className="col">
          <FormControlledDropdown
            formID="submittedID"
            options={
              submittedPBs.data?.map((draft: ProgressBillingDraft) => {
                return {
                  ...draft,
                  label: `${formatUTCDate(draft.updatedAt || '')} - ${
                    draft.userName
                  }`,
                };
              }) || []
            }
            labelField="label"
            valueField="id"
            placeholder={
              submittedPBs.isError
                ? 'Failed to load submitted drafts!'
                : 'Approve Submitted Drafts'
            }
            isDisabled={submittedPBs.isError || !submittedPBs.data?.length}
            isLoading={submittedPBs.isFetching}
            clearable={true}
            filter={true}
            resetFilterOnHide={true}
          />
        </div>
      )}
      {reportType === 'monthlyBilling' && (
        <React.Fragment>
          <div className="col">
            <Controller
              control={control}
              name="contracts"
              render={({ field: { value, ...otherFields } }) => (
                <MultipleSelectStyled
                  isDisabled={monthlyContracts.isError}
                  resetFilterOnHide={true}
                  showSelectAll={false}
                  filter={true}
                  clearable={true}
                  options={monthlyContracts.data}
                  labelField="Contract"
                  valueField="Contract"
                  isLoading={monthlyContracts.isFetching}
                  placeholder={
                    monthlyContracts.isError
                      ? 'Failed to load contracts!'
                      : 'Select Contract'
                  }
                  value={monthlyContracts.isFetching ? null : value}
                  panelClassName="w-full md:w-auto"
                  {...otherFields}
                />
              )}
            />
          </div>
          <div className="col">
            <Controller
              control={control}
              name="contracts"
              render={({ field: { value, ...otherFields } }) => (
                <MultipleSelectStyled
                  isDisabled={monthlyContracts.isError}
                  resetFilterOnHide={true}
                  showSelectAll={false}
                  filter={true}
                  clearable={true}
                  options={monthlyContracts.data}
                  labelField="Description"
                  valueField="Contract"
                  isLoading={monthlyContracts.isFetching}
                  placeholder={
                    monthlyContracts.isError
                      ? 'Failed to load contract names!'
                      : 'Select Contract Name'
                  }
                  value={monthlyContracts.isFetching ? null : value}
                  panelClassName="w-full md:w-auto"
                  {...otherFields}
                />
              )}
            />
          </div>
        </React.Fragment>
      )}
      {reportType === 'monthlyBilling' && (
        <div className="col">
          <FormControlledCalendar
            formID="year"
            className="w-full"
            triggerOnChange={true}
            dateFormat="yy"
            view="year"
            inputClassName="text-center"
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default CBFilters;
