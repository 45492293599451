import { Button } from 'primereact/button';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ContractForm from '../../../features/acForms/NewContract/ContractForm';
import JobForm from '../../../features/acForms/NewContract/JobForm';
import React, { useEffect, useRef, useState } from 'react';
import { useCompanyContext } from '../../../context/CompanyContext';
import ContractFormSteps, {
  checkBeforeStep,
} from '../../../features/acForms/NewContract/ContractFormSteps';
import GeneralInfoForm from '../../../features/acForms/NewContract/GeneralInfoForm';
import { NewContractFields } from '../../../Interfaces/Accounting/ACForms.interfaces';
import { useMutation } from '@tanstack/react-query';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { Toast } from 'primereact/toast';
import { postNewContract } from '../../../services/ACFormsService';
import ContractAttachments from '../../../features/acForms/NewContract/ContractAttachments';
import ContractSuccessMessage from '../../../features/acForms/NewContract/ContractSuccessMessage';
import LoadingButton from '../../../components/inputs/LoadingButton';
import ConfirmationDialog from '../../../components/messages/ConfirmationDialog';
import { ApiError } from '../../../Interfaces/Generic.interfaces';

const NewContract = () => {
  const { selectedCompany } = useCompanyContext();
  const navigate = useNavigate();
  const formMethods = useForm<NewContractFields>({ mode: 'onBlur' });
  const { handleSubmit, trigger } = formMethods;
  const [activeIndex, setActiveIndex] = useState(0);
  const toast = useRef<Toast>(null);

  const { mutate, isLoading, isSuccess, data, reset } = useMutation({
    mutationFn: (data: NewContractFields) => {
      return postNewContract(selectedCompany!.id, data);
    },
    onError: (error: ApiError) => {
      showToast(
        'error',
        toast,
        'Create Contract Error',
        error.response?.data?.message ||
          'An error ocurred! please try again later',
        3000
      );
    },
  });

  const changeIndex = (number: number) => {
    setActiveIndex(number);
  };

  const checkForm = async (index: number) => {
    const shouldChange = await checkBeforeStep(index, trigger);

    if (shouldChange) {
      setActiveIndex(index + 1);
    }
  };

  const onSubmit = (data: NewContractFields) => {
    const { contractValue, targetCost, processGroup, reviewerGroup, fieldPS } =
      data;
    const estProfit = contractValue - targetCost;

    const profitPerCont = (estProfit || 0) / contractValue;
    const estProfitPercentage = Math.round(profitPerCont * 10000) / 100;

    const profitPerCost = (estProfit || 0) / targetCost;
    const estCostMarkUp = Math.round(profitPerCost * 10000) / 100;

    const retainage = data.retainage;
    const bondPercentage = data.bondPercentage;

    const payload: NewContractFields = {
      ...data,
      contractValue,
      targetCost,
      prState: data.state,
      estProfit,
      estProfitPercentage,
      estCostMarkUp,
      retainage,
      bondPercentage,
      processGroup: processGroup === 'TBD' ? undefined : processGroup,
      reviewerGroup: reviewerGroup === 'TBD' ? undefined : reviewerGroup,
      fieldPS: fieldPS === 'TBD' ? undefined : fieldPS,
    };

    mutate(payload);
  };

  useEffect(() => {
    reset();
    formMethods.reset();
    setActiveIndex(0);
  }, [selectedCompany, formMethods, reset]);

  return (
    <div className="flex flex-column justify-content-center gap-5 pt-4">
      <div className="text-35px font-bold text-center mb-2">
        New Contract Setup
      </div>
      <FormProvider {...formMethods}>
        {!isSuccess && (
          <React.Fragment>
            <div className="w-screen max-w-900px mx-auto mb-2">
              <ContractFormSteps
                index={activeIndex}
                changeIndex={changeIndex}
              />
            </div>
            <form className="max-w-600px mx-auto flex flex-column gap-5">
              <div className={`${activeIndex !== 0 && 'hidden'}`}>
                <ContractForm />
              </div>
              <div className={`${activeIndex !== 1 && 'hidden'}`}>
                <JobForm />
              </div>
              <div className={`${activeIndex !== 2 && 'hidden'}`}>
                <GeneralInfoForm />
              </div>
              <div className={`${activeIndex !== 3 && 'hidden'}`}>
                <ContractAttachments />
              </div>
              <div className="flex flex-wrap flex-row-reverse gap-2 mb-3">
                {activeIndex !== 3 && (
                  <Button
                    className="w-15rem bluwaiBlue border-0 flex m-auto py-3"
                    type="button"
                    onClick={() => checkForm(activeIndex)}
                  >
                    <span className="mx-auto text-22px text-white">Next</span>
                  </Button>
                )}
                {activeIndex === 3 && (
                  <ConfirmationDialog
                    Button={
                      <LoadingButton
                        isLoading={isLoading}
                        label="Create Contract"
                        className="w-15rem bluwaiBlue border-0 flex m-auto py-3"
                        fontSize="text-22px"
                        type="button"
                      />
                    }
                    onConfirm={handleSubmit(onSubmit)}
                    message="Are you sure you want to submit this contract?"
                  />
                )}
                {activeIndex > 0 && (
                  <Button
                    onClick={() => {
                      setActiveIndex((index) => index - 1);
                    }}
                    className="w-15rem buttonSecondary border-0 flex m-auto py-3"
                    type="button"
                  >
                    <span className="mx-auto text-22px text-white">
                      Previous
                    </span>
                  </Button>
                )}
                <Button
                  onClick={() => {
                    navigate('/accounting-dashboard/new');
                  }}
                  className="w-15rem bluwaiRed border-0 flex m-auto py-3"
                  type="button"
                >
                  <span className="mx-auto text-22px text-white">Cancel</span>
                </Button>
              </div>
            </form>
          </React.Fragment>
        )}
        {isSuccess && (
          <ContractSuccessMessage extraJobNumber={data?.extraJobNumber} />
        )}
      </FormProvider>
      <ErrorToast toastRef={toast} />
    </div>
  );
};

export default NewContract;
