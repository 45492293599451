import React, { useCallback, useRef } from 'react';
import Table from '../../../components/Table/Table';
import { Column } from 'primereact/column';
import {
  Payable,
  PayableApproval,
  PayableDetail,
} from '../../../Interfaces/Accounting/AccountsPayables.interfaces';
import APDrilldownHeaderInfo from './APDrilldownHeaderInfo';
import APDrilldownReport from '../APReport/APDrilldownReport';
import { nextSortState } from '../../../../utils/sortUtils';
import {
  DataTablePFSEvent,
  DataTableSortOrderType,
} from 'primereact/datatable';
import { formatCurrency } from '../../../../utils/formatUtils';

type APDrilldownContentProps = {
  payable: Payable;
  invoices: PayableDetail[];
  approvals: PayableApproval[];
  uniqueAttchID?: string;
  extendedInfo?: boolean;
};

const YardiAPDrilldownContent = ({
  payable,
  invoices,
  extendedInfo,
}: APDrilldownContentProps) => {
  const drilldownRef = useRef<HTMLDivElement | null>(null);

  const getPayableStatus = (invoices: PayableDetail[]) => {
    let status = 3;
    invoices.forEach((invoice) => {
      status = invoice.Status < status ? invoice.Status : status;
    });

    return status;
  };
  const [sortState, setSortState] = React.useState<{
    field: string;
    order: DataTableSortOrderType;
  }>({ field: '', order: null });

  const handleSort = (event: DataTablePFSEvent) => {
    const { field, order } = nextSortState(event.sortField, sortState.field);
    setSortState({ field, order });
  };

  const calcHeight = (rows: number) => {
    const headerHeight = 32;
    const footerHeight = 0;
    const rowHeight = 31;
    return headerHeight + footerHeight + rows * rowHeight + 3;
  };

  return (
    <div
      id="ap-drilldown"
      ref={drilldownRef}
      className="blackText text-standard printBackground"
    >
      <div className="flex flex-column sm:row-gap-2 sm:flex-row flex-wrap align-items-center justify-content-center sm:justify-content-end">
        <div className="text-28px font-bold mx-auto sm:ml-4 drilldownHeader">
          AP Drilldown
        </div>

        <div className="text-23px sm:ml-4 sm:mr-6 mt-2 sm:mt-0 cursor-pointer printHide">
          <APDrilldownReport elementRef={drilldownRef} />
        </div>
      </div>
      <div className="mt-5 ml-4">
        <APDrilldownHeaderInfo
          payable={payable}
          extendedDetails={extendedInfo}
          status={getPayableStatus(invoices)}
          total={payable.NetAmount}
        />
      </div>
      <Table
        data={invoices}
        stripedRows={true}
        className={`mx-3 dashboardOptionShadow tableFirstLineStyled noFooter dobleHeader`}
        sortField={sortState.field}
        sortOrder={sortState.order}
        calcHeight={useCallback(calcHeight, [])}
        onSort={useCallback(handleSort, [sortState.field, invoices[0].Details])}
      >
        <Column
          field="Account"
          header="Account"
          headerClassName="tableHeader font-normal"
          style={{ minWidth: '150px', width: '100px' }}
          className={`text-standard blackText tableCell justify-content-start`}
          sortable
        />
        <Column
          field="Description"
          header="Description"
          headerClassName="tableHeader font-normal"
          style={{ minWidth: '250px', width: '300px' }}
          className="text-standard blackText tableCell title overflow-x-hidden white-space-nowrap checkOverflow"
          sortable
        />
        <Column
          field="Amount"
          header="Amount"
          headerClassName="tableHeader font-normal"
          body={(details) => formatCurrency(details.Amount)}
          style={{ minWidth: '150px', width: '100px' }}
          className={`text-standard blackText tableCell justify-content-end`}
          sortable
        />
      </Table>
    </div>
  );
};

export default YardiAPDrilldownContent;
