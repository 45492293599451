import axios from 'axios';
import { auth } from '../../firebase';
import { cleanJsonEmptyValues } from '../../utils/formatUtils';
import { TimeSheetArgs } from '../Interfaces/Accounting/ACForms.interfaces';

const apiUrl = process.env['NX_API_URL'];

export const fetchPREmployeeList = async (
  companyID: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/payroll/employeeList`, {
    signal,
    params: { companyID },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const fetchPREmployeeEarnings = async (
  companyID: string,
  employeeStatus: string,
  period: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/payroll/earnings`, {
    signal,
    params: { companyID, employeeStatus, period },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const fetchPRPeriodDates = async (
  companyID: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/payroll/period`, {
    signal,
    params: { companyID },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const fetchPREmployeesTimesheet = async (
  companyID: string,
  submittedID?: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/payroll/employeesTimesheet`, {
    signal,
    params: { companyID, draftID: submittedID },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

type fetchPRTimesheetsArgs = {
  companyID: string;
  userID?: string;
  isSubmitted?: boolean;
  isApproved?: boolean;
  periodStart?: Date;
  periodEnd?: Date;
};

export const fetchPRTimesheets = async (
  payload: fetchPRTimesheetsArgs,
  signal?: AbortSignal
) => {
  const cleanPayload = cleanJsonEmptyValues(payload);

  const response = await axios.get(`${apiUrl}/api/payroll/timesheet`, {
    signal,
    params: cleanPayload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const fetchPRTimesheetTemplate = async (
  companyID: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/payroll/timesheetTemplate`, {
    signal,
    params: { companyID },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  return response.data;
};

export const postTimesheetDraft = async (
  companyID: string,
  draft: TimeSheetArgs,
  periodStart: Date,
  periodEnd: Date
) => {
  const response = await axios.post(
    `${apiUrl}/api/payroll/timesheet`,
    { companyID, employees: draft, periodStart, periodEnd },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const putTimesheetDraft = async (
  dratID: string,
  draft: TimeSheetArgs,
  periodStart: Date,
  periodEnd: Date,
  fromApproval?: boolean
) => {
  const response = await axios.put(
    `${apiUrl}/api/payroll/timesheet/${dratID}`,
    { employees: draft, periodStart, periodEnd, fromApproval: !!fromApproval },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const putTimesheetSubmitDraft = async (dratID: string) => {
  const response = await axios.put(
    `${apiUrl}/api/payroll/timesheet/submit/${dratID}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const putTimesheetApproveDraft = async (dratID: string) => {
  const response = await axios.put(
    `${apiUrl}/api/payroll/timesheet/approve/${dratID}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  return response.data;
};

export const putTimesheetRejectDraft = async (
  dratID: string,
  reason: string
) => {
  const response = await axios.put(
    `${apiUrl}/api/payroll/timesheet/reject/${dratID}`,
    { reason },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  return response.data;
};
