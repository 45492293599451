import React, { useRef } from 'react';
import CBRTableContainer from '../../accountsReceivables/ContractBillsAndReceipts/CBRTableContainer';
import JCDrilldownReport from '../JCReport/JCDrilldownReport';
import { formatDate } from '../../../../utils/formatUtils';

type JCBillReceiptsDrilldownProps = {
  contract: string;
  contractName: string;
  customerName: string;
  startDate?: Date;
  endDate?: Date;
};

const JCBillReceiptsDrilldown = ({
  contract,
  contractName,
  customerName,
  startDate,
  endDate,
}: JCBillReceiptsDrilldownProps) => {
  const drilldownRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      id="jc-bill-receipts-drilldown"
      ref={drilldownRef}
      className="blackText text-standard printBackground w-full max-w-1500px"
    >
      <div className="mt-3 px-4 flex justify-content-center sm:justify-content-between gap-3 flex-wrap capitalize">
        <div className="text-23px font-bold flex-grow-1 text-center sm:text-left">
          <div className="text-28px mx-auto w-fit sm:ml-0 drilldownHeader">
            JC Billings and Receipts Drilldown
          </div>
          <div className="mt-3 mr-auto w-fit">
            Contract {contract} {contractName}
          </div>
          <div className="mr-auto w-fit">Customer {customerName}</div>
          {startDate && <div>Start Date: {formatDate(startDate)}</div>}
          {endDate && <div>End Date: {formatDate(endDate)}</div>}
        </div>
        <div className="text-23px mx-auto md:ml-4 md:mr-2 mt-2 sm:mt-0 cursor-pointer printHide flex align-items-center">
          <JCDrilldownReport
            title="Contract Billings and Receipts"
            elementRef={drilldownRef}
            fileName="JC_B&R_Drilldown_Report"
            elementID="jc-bill-receipts-drilldown"
            costDetail={{ contract, contractName }}
            customer={customerName}
          />
        </div>
      </div>
      <div className="my-4" />
      <CBRTableContainer isDrilldown={true} />
    </div>
  );
};

export default JCBillReceiptsDrilldown;
