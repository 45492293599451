import React, { useCallback, useState } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { formatUTCDate, formatCurrency } from '../../../../utils/formatUtils';
import {
  APVendorPaymentDetails,
  Payable,
} from '../../../Interfaces/Accounting/AccountsPayables.interfaces';
import APDrilldown from '../APDrilldown/APDrilldown';
import { DataTableRowClickEventParams } from 'primereact/datatable';

type APVendorDetailsTableProps = {
  payments: APVendorPaymentDetails[];
};

const APVendorDetailsTable = React.forwardRef<
  HTMLDivElement,
  APVendorDetailsTableProps
>(({ payments }, ref) => {
  const [showDrilldown, setShowDrilldown] = useState(false);
  const [selectedPayable, setSelectedPayable] = useState<Payable>();

  const onRowClick = (e: DataTableRowClickEventParams) => {
    const detail = e.data;

    const payableData = {
      VendorCode: detail.vendor.id,
      TransCode: detail.APTrans,
      Reference: detail.reference,
      InvoiceDate: detail.invoiceDate,
      Vendor: detail.vendor.name,
      Description: detail.description,
      DueDate: detail.dueDate,
    } as Payable;

    setSelectedPayable(payableData);
    setShowDrilldown(true);
  };

  return (
    <React.Fragment>
      {selectedPayable && (
        <APDrilldown
          visible={showDrilldown}
          setVisible={setShowDrilldown}
          payable={selectedPayable}
        />
      )}
      <Table
        ref={ref}
        data={payments}
        className="expandedTable tableFirstLineStyled noFooter w-full"
        onRowClick={useCallback(onRowClick, [])}
        rowClassName={useCallback(() => 'cursor-pointer', [])}
        hideColumns={false}
      >
        <Column
          style={{ flexBasis: '10%' }}
          className="tableCell p-0 printHide expandedTableCell"
          headerClassName={`expandedTableHeader`}
          footerClassName="tableFooter"
        />
        <Column
          style={{ minWidth: '100px', flexBasis: '6%' }}
          headerClassName={`expandedTableHeader`}
          className={`tableCell printHide expandedTableCell`}
          footerClassName="tableFooter"
        />
        <Column
          headerClassName={`expandedTableHeader`}
          style={{ minWidth: '370px', flexBasis: '15%' }}
          className={`text-standard blackText tableCell justify-content-center expandedTableCell`}
        />
        <Column
          field="reference"
          header="Inv. Number"
          headerClassName={`font-normal expandedTableHeader`}
          style={{ minWidth: '150px', flexBasis: '7%' }}
          className={`text-standard blackText tableCell justify-content-center expandedTableCell`}
        />
        <Column
          field="invoiceDate"
          header="Inv. Date"
          body={(payment) => formatUTCDate(payment.invoiceDate)}
          headerClassName={`font-normal expandedTableHeader`}
          style={{ minWidth: '150px', flexBasis: '7%' }}
          className={`text-standard blackText tableCell justify-content-center expandedTableCell`}
        />
        <Column
          field="description"
          header="Inv. Description"
          headerClassName={`font-normal expandedTableHeader justify-content-center`}
          style={{ minWidth: '300px', flexBasis: '14%' }}
          className={`text-standard blackText tableCell expandedTableCell doubleColumn`}
        />
        <Column
          field="amount"
          body={(payment) => formatCurrency(payment.amount)}
          headerClassName={`expandedTableHeader`}
          style={{ minWidth: '150px', flexBasis: '7%' }}
          className={`justify-content-end text-standard blackText tableCell expandedTableCell`}
        />
        <Column
          field="discount"
          body={(payment) => formatCurrency(payment.discount || 0)}
          headerClassName={`expandedTableHeader`}
          style={{ minWidth: '150px', flexBasis: '7%' }}
          className={`justify-content-end text-standard blackText tableCell expandedTableCell`}
        />
        <Column
          field="deduct"
          body={(payment) => formatCurrency(payment.deduct || 0)}
          headerClassName={`expandedTableHeader`}
          style={{ minWidth: '150px', flexBasis: '7%' }}
          className={`justify-content-end text-standard blackText tableCell expandedTableCell`}
        />
        <Column
          field="netAmount"
          body={(payment) => formatCurrency(payment.netAmount)}
          headerClassName={`expandedTableHeader`}
          style={{ minWidth: '200px', flexBasis: '10%' }}
          className={`justify-content-end text-standard blackText tableCell expandedTableCell`}
        />
        <Column
          style={{ flexBasis: '10%' }}
          className="tableCell p-0 printHide expandedTableCell"
          headerClassName={`expandedTableHeader`}
          footerClassName="tableFooter"
        />
      </Table>
    </React.Fragment>
  );
});

const transactionsAreEqual = (
  prevTransactions: Readonly<APVendorDetailsTableProps>,
  nextTransactions: Readonly<APVendorDetailsTableProps>
) => {
  return prevTransactions.payments === nextTransactions.payments;
};

export default React.memo(APVendorDetailsTable, transactionsAreEqual);
