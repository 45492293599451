import React, { useContext, useMemo, useState } from 'react';
import { Company } from '../Interfaces/User.interfaces';

type CompanyContextData = {
  selectedCompany: Company | undefined;
  setSelectedCompany: (company: Company | undefined) => void;
};

const CompanyContext = React.createContext({} as CompanyContextData);

type CompanyContextProviderProps = {
  children: React.ReactNode;
};

export const CompanyContextProvider = ({
  children,
}: CompanyContextProviderProps) => {
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(
    undefined
  );

  const contextValue = useMemo(
    () => ({
      selectedCompany,
      setSelectedCompany,
    }),
    [selectedCompany]
  );

  return (
    <CompanyContext.Provider value={contextValue}>
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompanyContext = () => useContext(CompanyContext);
