import React, { useCallback } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { ARCustomer } from '../../../Interfaces/Accounting/AccountsReceivables.interfaces';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';

type ARCustomerTableProps = {
  arCustomers: ARCustomer[];
};

const ARCustomerTable = React.forwardRef<HTMLDivElement, ARCustomerTableProps>(
  ({ arCustomers }, ref) => {
    const calcHeight = (rows: number) => {
      const headerHeight = 49;
      const footerHeight = 8;
      const rowHeight = 31;
      return headerHeight + footerHeight + rows * rowHeight + 3;
    };

    const onRowHover = (e: DataTableRowMouseEventParams) => {
      const cell = e.originalEvent.target as HTMLElement;
      const row = cell.closest('tr') as HTMLElement;
      animateCellText(row);
    };

    return (
      <Table
        id="ar-customer-table"
        ref={ref}
        data={arCustomers}
        className={`mx-3 dashboardOptionShadow dobleHeader noFooter`}
        calcHeight={useCallback(calcHeight, [])}
        onRowMouseEnter={useCallback(onRowHover, [])}
        hideColumns={true}
      >
        <Column
          field="name"
          header="Name"
          body={(ar) => {
            return <div className="scroll-text">{ar.name}</div>;
          }}
          headerClassName={`tableHeader font-normal justify-content-center`}
          style={{ minWidth: '250px', maxWidth: '250px' }}
          className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="sortName"
          header="Sort Name"
          headerClassName={`tableHeader font-normal justify-content-center`}
          style={{ minWidth: '200px' }}
          className={`text-standard blackText tableCell`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="contact"
          header="Contact"
          headerClassName={`tableHeader font-normal justify-content-center`}
          style={{ minWidth: '200px' }}
          className={`text-standard blackText tableCell`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="phone"
          header="Phone"
          headerClassName={`tableHeader font-normal justify-content-center`}
          style={{ minWidth: '130px' }}
          className={`text-standard blackText tableCell`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="address"
          header="Address"
          body={(ar) => {
            return <div className="scroll-text">{ar.Address}</div>;
          }}
          headerClassName={`tableHeader font-normal justify-content-center`}
          style={{ minWidth: '250px', maxWidth: '250px' }}
          className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="city"
          header="City"
          headerClassName={`tableHeader font-normal justify-content-center`}
          style={{ minWidth: '120px' }}
          className={`text-standard blackText tableCell`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="state"
          header="State"
          headerClassName={`tableHeader font-normal`}
          style={{ minWidth: '70px' }}
          className={`justify-content-center text-standard blackText tableCell`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="zip"
          header="Zip Code"
          headerClassName={`tableHeader font-normal justify-content-center`}
          style={{ minWidth: '100px' }}
          className={`text-standard blackText tableCell`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="eMail"
          header="Email"
          body={(ar) => {
            return <div className="scroll-text">{ar.EMail}</div>;
          }}
          headerClassName={`tableHeader font-normal justify-content-center`}
          style={{ minWidth: '200px', maxWidth: '200px' }}
          className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="accountNumber"
          header="Account Number"
          headerClassName={`tableHeader font-normal text-center justify-content-center`}
          style={{ minWidth: '100px', maxWidth: '100px' }}
          className={`text-standard blackText tableCell`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="status"
          header="Status"
          headerClassName={`tableHeader font-normal justify-content-center`}
          style={{ minWidth: '70px' }}
          className={`text-standard blackText tableCell`}
          footerClassName="tableFooter"
          sortable
        />
      </Table>
    );
  }
);

const transactionsAreEqual = (
  prevTransactions: Readonly<ARCustomerTableProps>,
  nextTransactions: Readonly<ARCustomerTableProps>
) => {
  return prevTransactions.arCustomers === nextTransactions.arCustomers;
};

export default React.memo(ARCustomerTable, transactionsAreEqual);
