import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
} from 'chart.js';
import { AccountResume } from '../../Interfaces/Accounting/CashManagement.interfaces';
import { Link } from 'react-router-dom';
import ResumeCard from '../../components/cards/ResumeCard';
import ErrorMessage from '../../components/messages/ErrorMessage';
import { BACKGROUND_COLORS, BORDER_COLORS } from '../../../utils/graphConst';
import ColorIndicator from '../../components/messages/ColorIndicator';
import { Company, CompanySource } from '../../Interfaces/User.interfaces';
import { useCompanyContext } from '../../context/CompanyContext';
import { useQuery } from '@tanstack/react-query';
import { fetchPayableAccountsBalances } from '../../services/AccountsPayablesService';

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale);

type DashboardGeneralLedgerPayableProps = {
  hasError?: boolean;
};

const DashboardGeneralLedgerPayable = ({
  hasError,
}: DashboardGeneralLedgerPayableProps) => {
  const [labels, setLabels] = useState<string[]>([]);
  const [amounts, setAmounts] = useState<number[]>([]);
  const [accountsRows, setAccountRows] = useState<React.ReactNode>([]);
  const [hidden, setHidden] = useState(false);
  const [total, setTotal] = useState<number | null>(null);

  const { selectedCompany } = useCompanyContext();

  const doughnutData = {
    labels: labels,
    datasets: [
      {
        data: amounts,
        backgroundColor: BACKGROUND_COLORS,
        borderColor: BORDER_COLORS,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const {
    data: accounts,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ['getGeneralLedgerPayable', selectedCompany],
    queryFn: ({ signal }) =>
      fetchPayableAccountsBalances(selectedCompany!, signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  const buildAccountRow = (account: AccountResume, position: number) => {
    return (
      <div className="flex justify-content-between" key={account.name}>
        <Link
          to={`/accounting-dashboard/account-payables?account=${account.id}`}
          className="flex gap-1 align-items-center"
        >
          <ColorIndicator colorIndex={position} />
          <div className="text-15px totalColor cursor-pointer hover:underline">
            {account.name}
          </div>
        </Link>
        <div className="text-15px">
          {account.balance.amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const descList: string[] = [];
    const amountList: number[] = [];

    if (accounts === undefined) return;

    const accRows = accounts.map((account: any, index: any) => {
      if (!account.balance.amount) {
        return null;
      }
      descList.push(account.name);
      amountList.push(account.balance.amount);
      return buildAccountRow(account, index);
    });

    setLabels(descList);
    setAmounts(amountList);
    setTotal(amountList.reduce((acc, curr) => acc + curr, 0));
    setAccountRows(accRows);
  }, [accounts]);

  if (hasError) {
    return (
      <div className="xl:flex-grow-1 max-w-500px">
        <ResumeCard>
          <div className="relative p-3 px-4 flex flex-column h-full">
            <Link
              to={`/accounting-dashboard/cash-management`}
              className="w-fit"
            >
              <div className="text-24px font-bold cursor-pointer totalColor hover:underline">
                Accounts Payable
              </div>
            </Link>
            <div className="my-8 py-3">
              <ErrorMessage
                content={'Failed to obtain data! Please try again later.'}
              />
            </div>
          </div>
        </ResumeCard>
      </div>
    );
  }

  return (
    <div className="xl:flex-grow-1 max-w-500px">
      <ResumeCard>
        <div
          className="relative p-3 px-4 flex flex-column"
          onMouseEnter={() => {
            setHidden(true);
          }}
          onMouseLeave={() => {
            setHidden(false);
          }}
          role="button"
          tabIndex={0}
        >
          <Link to={`/accounting-dashboard/cash-management`} className="w-fit">
            <div className="text-24px font-bold cursor-pointer totalColor hover:underline">
              Accounts Payable
            </div>
          </Link>
          <div
            className={`flex flex-wrap justify-content-around gap-5 ${
              hidden && 'visibility-hidden'
            }`}
          >
            <div className="mt-auto mb-3">
              <div className="mb-2 totalColor text-25px word-break-all">
                {(total || 0).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
              </div>
              <div className="w-fit mt-1 text-18px text-white totalBG radius-5px p-2">
                Total Balance
              </div>
            </div>
            <div>
              <Doughnut
                data={doughnutData}
                options={options}
                className="w-8rem h-8rem mx-auto"
              />
            </div>
          </div>
          {hidden && (
            <div className="absolute scrollbarStyled overflow-y-auto w-full left-0 bottom-0 height-75 flex">
              <div className="w-full my-auto px-4 flex flex-column gap-2">
                {accountsRows}
                <div className="flex justify-content-between border-top-2 border-top-solid totalBorder pt-3 mb-2">
                  <div className="text-15px">Total</div>
                  <div className="text-15px">
                    {(total || 0).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </ResumeCard>
    </div>
  );
};

export default DashboardGeneralLedgerPayable;
