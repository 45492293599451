import { Checkbox } from 'primereact/checkbox';
import { useFormContext } from 'react-hook-form';
import { capitalizeString } from '../../../utils/formatUtils';

type AccordionHeaderProps = {
  module: string;
  checkedModules: Record<string, boolean>;
  updateModule: (module: string, checked: boolean) => void;
  startIndex: number;
  count: number;
};

const ModuleAccordionHeader = ({
  module,
  checkedModules,
  updateModule,
  startIndex,
  count,
}: AccordionHeaderProps) => {
  const { setValue } = useFormContext();

  return (
    <div className="flex align-items-center">
      <span className='text-standard'>{capitalizeString(module)}</span>
      <Checkbox
        className="ml-4"
        checked={checkedModules[module]}
        onChange={(e) => {
          updateModule(module, e.checked);
          for (let i = startIndex; i < startIndex + count; i++) {
            setValue(`access.${i}.selected`, e.checked);
          }
        }}
      />
    </div>
  );
};
export default ModuleAccordionHeader;
