import { useQuery } from '@tanstack/react-query';
import { Panel } from 'primereact/panel';
import { useFormContext } from 'react-hook-form';
import FormInfo from '../../../components/messages/FormInfo';
import { useCompanyContext } from '../../../context/CompanyContext';
import {
  ContractInfo,
  JobInfo,
  ProjectManagerSummary,
  ReviewerSummary,
  StateSummary,
  TaxCodeSummary,
} from '../../../Interfaces/Accounting/ACForms.interfaces';
import { fetchJobLookUpInfo } from '../../../services/ACFormsService';

type JobSummaryProps = {
  extraJobNumber?: string;
};

const JobSummary = ({ extraJobNumber }: JobSummaryProps) => {
  const { selectedCompany } = useCompanyContext();
  const methods = useFormContext<ContractInfo>();
  const { getValues } = useFormContext<JobInfo>();
  const {
    projectManager,
    taxCode,
    reviewerGroup,
    address1,
    address2,
    city,
    state,
    zip,
    county,
    physician,
    notice,
    extraJob,
  } = getValues();
  const { contractName, contract, department } = methods.getValues();

  const { data } = useQuery({
    queryKey: ['getNewJobInfo', selectedCompany, department],
    queryFn: ({ signal }) =>
      fetchJobLookUpInfo(selectedCompany!.id, department, signal),
    refetchOnWindowFocus: false,
    enabled: false,
    keepPreviousData: true,
  });

  const managerData = data?.projectManagers.find(
    (man: ProjectManagerSummary) => man.ProjectMgr === projectManager
  );
  const stateData = data?.states.find(
    (stat: StateSummary) => stat.State === state
  );
  const taxData = data?.taxCodes.find(
    (tax: TaxCodeSummary) => tax.TaxCode === taxCode
  );
  const revData = data?.reviewerGroups.find(
    (rev: ReviewerSummary) => rev.ReviewerGroup === reviewerGroup
  );

  return (
    <Panel header="Job Set-up" className="max-w-600px mx-3 text-standard">
      <div className="grid">
        <div className="col-12 sm:col-6">
          <FormInfo label="Job Number" content={contract} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Job Description" content={contractName} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Contract" content={contract} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Project Manager" content={managerData?.Name} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Address 1" content={address1} />
        </div>
        {address2 && (
          <div className="col-12 sm:col-6">
            <FormInfo label="Address 2" content={address2} />
          </div>
        )}
        <div className="col-12 sm:col-6">
          <FormInfo label="City" content={city} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="State" content={stateData?.Name} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="ZIP" content={zip} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="County" content={county} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Payroll State" content={stateData?.Name} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Sales Tax Code" content={taxData?.Description} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo
            label="AP Inv Rev Grp"
            content={revData?.Description ?? reviewerGroup}
          />
        </div>
        {data?.allowExtraJob && (
          <div className="col-12">
            <FormInfo
              label="Create service maintenance extra job?"
              content={extraJob ? 'Yes' : 'No'}
            />
          </div>
        )}
        {data?.allowExtraJob && (
          <div className="col-12">
            <FormInfo
              label="Maintenance Extra Job Number"
              content={extraJobNumber}
            />
          </div>
        )}
        <div className="col-12">
          <FormInfo
            label="Requested required panel of physicians from Elena for display at the job site?"
            content={physician}
          />
        </div>
        <div className="col-12">
          <FormInfo
            label="Notice to Owner sent via certified mail to the Owner and GC?"
            content={notice}
          />
        </div>
      </div>
    </Panel>
  );
};

export default JobSummary;
