import { Button } from 'primereact/button';
import { useState } from 'react';
import { useScreenWidthDetector } from '../../hooks/useScreenWidthDetector';
import { StyledDialog } from '../dialog/StyledDialog';

const FiltersMenu = ({ children }: { children: React.ReactNode }) => {
  const { isDesktop } = useScreenWidthDetector();
  const [showModal, setShowModal] = useState(false);

  if (isDesktop) {
    return (
      <div className="flex flex-column">
        <div className="grid ">{children}</div>
      </div>
    );
  }
  return (
    <div className="flex flex-row ">
      <Button
        icon="pi pi-filter"
        className="border-0"
        onClick={() => setShowModal(true)}
      />
      <StyledDialog
        header="Filters"
        visible={showModal}
        style={{ width: '50vw' }}
        modal
        onHide={() => setShowModal(false)}
      >
        <div className="flex flex-column justify-content-between gap-3">
          {children}
        </div>
      </StyledDialog>
    </div>
  );
};

export default FiltersMenu;
