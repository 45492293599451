import React from 'react';
import { Sidebar } from 'primereact/sidebar';
import { useQuery } from '@tanstack/react-query';
import { useCompanyContext } from '../../../context/CompanyContext';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { fecthJCCommittedCost } from '../../../services/JobsContractsService';
import { CostDetailArgs } from '../../../Interfaces/Accounting/JobsContracts.interface';
import JCCommittedCost from './JCCommittedCostDrilldown';

type JCCommittedCostContainerProps = {
  visible: boolean;
  hideDrilldown: () => void;
  costDetail: CostDetailArgs;
};

const JCCommittedCostContainer = ({
  visible,
  hideDrilldown,
  costDetail,
}: JCCommittedCostContainerProps) => {
  let job = '';
  let jobName = '';
  if ('job' in costDetail) {
    ({ job, jobName } = costDetail);
  }
  const { phase, phaseDescription } = costDetail;
  const { selectedCompany } = useCompanyContext();

  const { data, isFetching, isError, isSuccess } = useQuery({
    queryKey: ['getJCCommittedCosts', selectedCompany, job, phase],
    queryFn: ({ signal }) =>
      fecthJCCommittedCost(selectedCompany!.id, job, phase, signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  const error = isError && !isFetching;
  const success = isSuccess && !isFetching;

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={hideDrilldown}
      className="w-full max-w-1300px"
    >
      {isFetching && (
        <div className="mx-8 h-full flex align-items-center">
          <ProgressSpinner />
        </div>
      )}
      {error && (
        <div className="h-full flex align-items-center">
          <ErrorMessage
            content={'Failed to obtain data! Please try again later.'}
          />
        </div>
      )}
      {success && (
        <JCCommittedCost
          costs={data || []}
          job={job}
          jobName={jobName}
          phase={phase}
          phaseDescription={phaseDescription}
        />
      )}
    </Sidebar>
  );
};

export default JCCommittedCostContainer;
