import React, {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { NavLink } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import { CSSTransition } from 'react-transition-group';
import { Ripple } from 'primereact/ripple';
import { Tooltip } from 'primereact/tooltip';
import { Badge } from 'primereact/badge';
import { AppMenuProps, AppSubmenuProps, SideBarItem } from './AppMenuTypes';

const AppSubmenu = forwardRef<HTMLUListElement, AppSubmenuProps>(
  (props, ref) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const onMenuItemClick = (
      event: React.SyntheticEvent,
      item: SideBarItem,
      index: number
    ) => {
      setActiveIndex(index);
      if (item.disabled) {
        event.preventDefault();
        return true;
      }

      if (props.root && props.onRootItemClick) {
        props.onRootItemClick({
          originalEvent: event,
          item: item,
        });
      }

      //execute command
      if (item.command) {
        item.command({ originalEvent: event, item: item });
        event.preventDefault();
      }

      if (item.items) {
        setActiveIndex(activeIndex === index ? null : index);
      } else if (props.menuMode !== 'static') {
        const ink = getInk(event.currentTarget);
        if (ink) {
          removeClass(ink, 'p-ink-active');
        }
      }

      if (props.onMenuItemClick) {
        props.onMenuItemClick({
          originalEvent: event,
          item: item,
        });
      }
      return false;
    };

    const getInk = (el: EventTarget & Element) => {
      for (let i = 0; i < el.children.length; i++) {
        if (
          typeof el.children[i].className === 'string' &&
          el.children[i].className.indexOf('p-ink') !== -1
        ) {
          return el.children[i];
        }
      }
      return null;
    };

    const removeClass = (element: EventTarget & Element, className: string) => {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            '(^|\\b)' + className.split(' ').join('|') + '(\\b|$)',
            'gi'
          ),
          ' '
        );
    };

    const visible = (item: SideBarItem) => {
      return typeof item.visible === 'function'
        ? item.visible()
        : item.visible !== false;
    };

    const isHorizontalOrSlim = useCallback(() => {
      return props.menuMode === 'horizontal' || props.menuMode === 'slim';
    }, [props.menuMode]);

    const isMobile = useCallback(() => {
      return window.innerWidth < 992;
    }, []);

    const isHorizontal = () => {
      return props.menuMode === 'horizontal';
    };
    const isSlim = () => {
      return props.menuMode === 'slim';
    };

    useEffect(() => {
      if (!props.menuActive && isHorizontalOrSlim() && !isMobile()) {
        setActiveIndex(null);
      }
    }, [props.menuActive, isHorizontalOrSlim, isMobile]);

    const getLink = (item: SideBarItem, index: number) => {
      const menuitemIconClassName = classNames(
        'layout-menuitem-icon',
        item.icon
      );
      const content = (
        <>
          {item.icon && <i className={menuitemIconClassName}></i>}
          {item.iconIMG && (
            <div className="w-3rem flex justify-content-center">
              <img
                className={` ${!item.disabled && 'svgWhite'}`}
                src={item.iconIMG}
                alt={`${item.label} img`}
              />
            </div>
          )}
          <span
            className={`layout-menuitem-text ${
              item.disabled ? 'disabledText' : 'text-white'
            }`}
          >
            {item.label}
          </span>
          {item.badge && (
            <Badge
              value={item.badge}
              style={item.badgeStyle}
              className={classNames(item.badgeClassName, 'p-badge-no-gutter')}
            />
          )}
          {item.items && (
            <i className="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
          )}
          <Ripple />
        </>
      );
      const commonLinkProps = {
        style: item.style,
        className: classNames(item.className, 'p-ripple tooltip', {
          'p-disabled': item.disabled,
          'p-link': !item.to,
        }),
        onClick: (e: React.SyntheticEvent) => onMenuItemClick(e, item, index),
      };

      if (item.url) {
        return (
          <a
            data-pr-tooltip={props.root && item.label}
            href={item.url}
            rel="noopener noreferrer"
            {...commonLinkProps}
          >
            {content}
          </a>
        );
      } else if (!item.to) {
        return (
          <a
            data-pr-tooltip={props.root && item.label}
            type="button"
            {...commonLinkProps}
          >
            {content}
          </a>
        );
      }

      return (
        <NavLink
          data-pr-tooltip={props.root && item.label}
          to={item.to}
          {...commonLinkProps}
          className={({ isActive }) =>
            classNames(
              commonLinkProps.className,
              isActive ? 'router-link-active' : undefined
            )
          }
        >
          {content}
        </NavLink>
      );
    };

    const getItems = () => {
      const transitionTimeout = props.root ? 0 : { enter: 1000, exit: 450 };
      return props.items?.map((item: SideBarItem, i: number) => {
        if (visible(item)) {
          const submenuRef = createRef<HTMLUListElement>();
          const active = activeIndex === i;
          const styleClass = classNames(
            `${item.badgeStyleClass} sidebarItem mt-2 sm:mt-4`,
            { active: active },
            { 'layout-root-menuitem': props.root }
          );
          const link = getLink(item, i);
          const tooltip = props.root && (
            <div>
              <span
                className="layout-menuitem-text"
                style={{ textTransform: 'uppercase' }}
              >
                {item.label}
              </span>
            </div>
          );

          return (
            <li key={item.label ?? i} className={styleClass}>
              {link}
              {tooltip}
              <CSSTransition
                nodeRef={submenuRef}
                classNames="layout-submenu-container"
                timeout={transitionTimeout}
                in={
                  item.items &&
                  (props.root &&
                  !(
                    (isHorizontal() || isSlim()) &&
                    !isMobile() &&
                    (!isSlim() || (isSlim() && activeIndex !== null))
                  )
                    ? true
                    : active)
                }
                unmountOnExit
              >
                <AppSubmenu
                  ref={submenuRef}
                  items={visible(item) ? item.items : undefined}
                  onMenuItemClick={props.onMenuItemClick}
                  menuMode={props.menuMode}
                  menuActive={props.menuActive}
                  parentMenuItemActive={active}
                ></AppSubmenu>
              </CSSTransition>
            </li>
          );
        }

        return null;
      });
    };

    if (!props.items) {
      return null;
    }

    const items = getItems();

    return (
      <>
        <ul ref={ref} className={props.className}>
          {items}
        </ul>
        {isSlim() && props.root && (
          <Tooltip target="li:not(.active-menuitem)>.tooltip" />
        )}
      </>
    );
  }
);

const AppMenu = ({
  items,
  active,
  onRootMenuItemClick,
  onMenuItemClick,
  menuMode,
}: AppMenuProps) => {
  return (
    <AppSubmenu
      items={items}
      className="layout-menu"
      menuActive={active}
      onRootItemClick={onRootMenuItemClick}
      onMenuItemClick={onMenuItemClick}
      root={true}
      menuMode={menuMode}
      parentMenuItemActive={true}
    />
  );
};

export default AppMenu;
