import { EXCEL_FORMATS, transformBToMB } from '../../../../utils/fileUtil';
import { Controller, useFormContext } from 'react-hook-form';
import FormErrorMessage from '../../../components/messages/FormErrorMessage';
import MultipleFileInput from '../../../components/inputs/StyledInputs/MultipleFileInput';
import { FILE_SIZE_LIMIT } from 'apps/tmr-frontend/src/utils/fileConsts';

export type ScheduleFormFields = {
  schedule: File[];
};

const ScheduleForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<ScheduleFormFields>();

  return (
    <Controller
      name="schedule"
      aria-label="file-input"
      control={control}
      rules={{
        required: {
          value: true,
          message: 'Schedule is a required field',
        },
      }}
      render={({ field: { onChange, value } }) => (
        <div>
          <MultipleFileInput
            value={value}
            onChange={onChange}
            acceptedFormats={EXCEL_FORMATS}
            totalLimit={FILE_SIZE_LIMIT}
            totalLimitLabel={`${transformBToMB(FILE_SIZE_LIMIT)} MB`}
            emptyMessage={`Upload schedule of values file. Allowed extensions: ${EXCEL_FORMATS.join(
              ', '
            )}.`}
            contentClassName={errors['schedule'] && 'border-red-500'}
          />
          {errors['schedule'] && (
            <FormErrorMessage
              errorMessage={errors['schedule'].message as string}
            />
          )}
        </div>
      )}
    />
  );
};

export default ScheduleForm;
