import { Sidebar } from 'primereact/sidebar';
import { useQuery } from '@tanstack/react-query';
import { useCompanyContext } from '../../../context/CompanyContext';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import SCPaymentDrilldown from './SCPaymentDrilldown';
import { fetchSCPeriodPaymentDetails } from '../../../services/SubcontractsService';
import { SCPaymentSummary } from '../../../Interfaces/Accounting/Subcontracts.interfaces';

type SCPeriodPaymentDrilldownContainerProps = {
  visible: boolean;
  hideDrilldown: () => void;
  scpayment: SCPaymentSummary;
  periodStart: Date;
  periodEnd: Date;
  processByJob?: boolean;
  processBySubcontract?: boolean;
};

const SCPeriodPaymentDrilldownContainer = ({
  visible,
  hideDrilldown,
  scpayment,
  periodStart,
  periodEnd,
  processByJob,
  processBySubcontract,
}: SCPeriodPaymentDrilldownContainerProps) => {
  const { selectedCompany } = useCompanyContext();

  const { data, isFetching, isError, isSuccess } = useQuery({
    queryKey: ['getSCPeriodPaymentDetails', selectedCompany, scpayment],
    queryFn: ({ signal }) =>
      fetchSCPeriodPaymentDetails(
        selectedCompany!.id,
        periodStart,
        periodEnd,
        scpayment.Job,
        scpayment.Vendor,
        scpayment.PM,
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={hideDrilldown}
      className="w-full max-w-1000px"
    >
      {isFetching && (
        <div className="mx-8 h-full flex align-items-center">
          <ProgressSpinner />
        </div>
      )}
      {isError && (
        <div className="h-full flex align-items-center">
          <ErrorMessage
            content={'Failed to obtain data! Please try again later.'}
          />
        </div>
      )}
      {isSuccess && (
        <SCPaymentDrilldown
          paymentDetails={{ ...scpayment, PmName: scpayment.Name }}
          details={data}
          showJob={processByJob}
          periodEnd={periodEnd}
          processByJob={processByJob}
          processBySubcontract={processBySubcontract}
        />
      )}
    </Sidebar>
  );
};

export default SCPeriodPaymentDrilldownContainer;
