import { transformBToMB } from '../../../../utils/fileUtil';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { FileUpload, ItemTemplateOptions } from 'primereact/fileupload';

export type FileInputItemProps = {
  file: File;
  itemOptions: ItemTemplateOptions;
  inputRef: React.RefObject<FileUpload>;
};

const FileInputItem = ({ file, itemOptions, inputRef }: FileInputItemProps) => {
  let displayeValue;
  if (file.size >= 1000000) {
    const sizeMB = transformBToMB(file.size) * 1000000;
    displayeValue = inputRef.current?.formatSize(sizeMB);
  }

  return (
    <div className="flex align-items-center flex-wrap">
      <div className="flex align-items-center" style={{ width: '40%' }}>
        <span className="flex flex-column text-left ml-3">{file.name}</span>
      </div>
      <Tag
        value={displayeValue ? `${displayeValue}` : itemOptions.formatSize}
        severity="warning"
        className="px-3 py-2"
      />
      <Button
        type="button"
        icon="pi pi-times"
        className="p-button-outlined p-button-rounded p-button-danger ml-auto"
        onClick={itemOptions.onRemove}
      />
    </div>
  );
};

export default FileInputItem;
