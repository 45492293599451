import axios from 'axios';
import { auth } from '../../firebase';
import { Company } from '../Interfaces/User.interfaces';

const apiUrl = process.env['NX_API_URL'] + '/api/vendors';

export type fetchVendorReportArgs = {
  company: Company;
  info?: string;
};

type FetchVendorArgs = {
  company: Company;
  active?: boolean;
  types?: string[];
};

export const fetchVendors = async (
  params: FetchVendorArgs,
  signal?: AbortSignal
) => {
  const response = await axios.get(apiUrl, {
    signal,
    params: {
      source: params.company.source,
      companyID: params.company.id,
      active: params.active,
      types: params.types,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const fetchVendorReport = async (
  params: fetchVendorReportArgs,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/report`, {
    signal,
    params: {
      source: params.company.source,
      companyID: params.company.id,
      info: params.info || null,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const fetchOpenVendors = async (
  company: Company,
  signal?: AbortSignal,
  hasJob?: boolean
) => {
  const response = await axios.get(`${apiUrl}/open`, {
    signal,
    params: { source: company.source, companyID: company.id, hasJob },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const fetchUnapprovedVendors = async (
  company: Company,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/unapproved`, {
    signal,
    params: { companyID: company.id, source: company.source },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};
