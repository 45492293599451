import { useQuery } from '@tanstack/react-query';
import { useWatch } from 'react-hook-form';
import { useCompanyContext } from '../../../context/CompanyContext';
import { PRTimesheetFilters } from './TimesheetFilters';
import { fetchPRTimesheets } from '../../../services/PayrollService';
import { getSubmittedDraft } from './util';
import { formatUTCDate } from 'apps/tmr-frontend/src/utils/formatUtils';

type TimesheetReportHeaderProps = {
  periodStart: Date;
  periodEnd: Date;
};

const TimesheetReportHeader = ({
  periodStart,
  periodEnd,
}: TimesheetReportHeaderProps) => {
  const { selectedCompany } = useCompanyContext();
  const filters = useWatch<PRTimesheetFilters>();

  const submittedTimesheets = useQuery({
    queryKey: ['getSubmittedPRTimesheets', selectedCompany],
    queryFn: ({ signal }) =>
      fetchPRTimesheets(
        {
          companyID: selectedCompany!.id,
          isSubmitted: true,
          isApproved: false,
        },
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  return (
    <div className="blackText printMedia printShow">
      <div className="text-30px text-center mt-5">{selectedCompany!.name}</div>
      <div className="text-28px text-center">Payroll Timesheet</div>
      <div className="text-17px ml-3 mt-2">
        <div>
          <span className="font-bold mr-1">Pay Period Start Date:</span>
          {formatUTCDate(periodStart)}
        </div>
        <div>
          <span className="font-bold mr-1">Pay Period End Date:</span>
          {formatUTCDate(periodEnd)}
        </div>
        {filters.submittedID && (
          <div>
            <span className="font-bold mr-1">Submitted Draft:</span>
            {getSubmittedDraft(filters.submittedID, submittedTimesheets.data)}
          </div>
        )}
      </div>
      <div className="ml-3 text-17px mb-5">
        <span className="font-bold mr-1">Created at:</span>
        {new Date().toLocaleString()}
      </div>
    </div>
  );
};

export default TimesheetReportHeader;
