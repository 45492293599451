import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCompanyContext } from '../../../context/CompanyContext';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { fetchJCResume } from '../../../services/JobsContractsService';
import JCResume from './JCResume';
import ResumeCard from '../../../components/cards/ResumeCard';

const JCResumeTableContainer = React.forwardRef<HTMLDivElement>(
  (props, ref) => {
    const { selectedCompany } = useCompanyContext();

    const { data, isLoading, isSuccess, isError } = useQuery({
      queryKey: ['getJCContractAnalysisResume', selectedCompany],
      queryFn: ({ signal }) => {
        return fetchJCResume(selectedCompany!.id, signal);
      },
      refetchOnWindowFocus: false,
      enabled: !!selectedCompany,
    });

    return (
      <div
        className={`ml-auto w-full lg:w-fit ${
          (isLoading || !selectedCompany) && 'mr-8 pr-8'
        }`}
      >
        <ResumeCard>
          <div className="p-3 flex justify-content-center">
            {(isLoading || !selectedCompany) && <ProgressSpinner />}
            {isSuccess && <JCResume contracts={data} />}
            {isError && (
              <ErrorMessage
                content={'Failed to obtain data! Please try again later.'}
              />
            )}
          </div>
        </ResumeCard>
      </div>
    );
  }
);

export default JCResumeTableContainer;
