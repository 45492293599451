import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import { fetchSCPaymentItemsStatus } from '../../../services/SubcontractsService';
import SCItemStatusTable from './SCItemStatusTable';

type SCItemStatusContainerProps = {
  sl: string;
};

const SCItemStatusContainer = React.forwardRef<
  HTMLDivElement,
  SCItemStatusContainerProps
>(({ sl }, ref) => {
  const { selectedCompany } = useCompanyContext();
  const { data, isFetching, isError } = useQuery({
    queryKey: ['getSCSubcontractItemsStatus', selectedCompany, sl],
    queryFn: ({ signal }) =>
      fetchSCPaymentItemsStatus(selectedCompany!.id, sl, signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  if (isFetching || !selectedCompany)
    return (
      <div className="text-center mx-auto mt-3">
        <ProgressSpinner />
      </div>
    );

  if (isError)
    return (
      <ErrorMessage
        content={'Failed to obtain data! Please try again later.'}
      />
    );

  return <SCItemStatusTable items={data} />;
});

export default SCItemStatusContainer;
