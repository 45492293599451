import { useMutation, useQuery } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { Toast } from 'primereact/toast';
import firebaseErrorMessages from '../../../components/errorMessages/firebase';
import { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import SuccessFormMessage from '../../../components/messages/SuccessFormMessage';
import RegisterForm, {
  RegisterFormFields,
} from '../../../features/roles/RegisterForm';
import {
  deleteRole,
  getRole,
  postRoleArgs,
  putRole,
} from '../../../services/RolesService';
import { capitalizeString } from '../../../../utils/formatUtils';
import RemoveRoleButton from '../../../features/roles/RemoveRoleButton';
import LoadingButton from '../../../components/inputs/LoadingButton';
import { processAccessForm } from 'apps/tmr-frontend/src/utils/roleUtils';

const RolesUpdate = () => {
  const [roleData, setRoleData] = useState<postRoleArgs>();
  const navigate = useNavigate();
  const { roleID } = useParams();
  const toast = useRef<Toast>(null);
  const formMethods = useForm<RegisterFormFields>();
  const { handleSubmit, watch, setValue } = formMethods;
  const access = watch('access');

  const roleResult = useQuery({
    queryKey: ['getRole', roleID],
    queryFn: ({ signal }) => {
      return getRole(roleID ?? '', signal);
    },
    onSuccess: (data) => {
      setRoleData(data);
    },
    refetchOnWindowFocus: false,
  });

  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: (data: postRoleArgs) => {
      return putRole(roleID!, data);
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as {
        code: string;
        message: string;
      };
      showToast(
        'error',
        toast,
        'Update Role Error',
        firebaseErrorMessages[errorData?.code] ||
          errorData.message ||
          'An error ocurred! please try again later',
        3000
      );
    },
  });

  const deleteRoleResult = useMutation({
    mutationFn: () => {
      return deleteRole(roleID ?? '');
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as {
        code: string;
        message: string;
      };

      showToast(
        'error',
        toast,
        'Remove Role Error',
        firebaseErrorMessages[errorData?.code] ||
          errorData.message ||
          'An error ocurred! please try again later',
        3000
      );
    },
  });

  const onSubmit = (data: RegisterFormFields) => {
    const { access, hidePhaseCodes } = data;

    const list = processAccessForm(access);

    const payload = {
      ...data,
      access: list,
      hidePhaseCodes: hidePhaseCodes === 'yes' ? true : false,
      name: '',
    };

    mutate(payload);
  };

  useEffect(() => {
    if (access && roleData) {
      setValue('name', capitalizeString(roleData.name));
      setValue('type', roleData.type);
      setValue('hidePhaseCodes', roleData.hidePhaseCodes ? 'yes' : 'no');

      access.forEach((acc, index) => {
        const report = acc.report;
        const match = roleData.access.find(
          (element) =>
            element.report === report.replace(/ /g, '_').toLocaleLowerCase()
        );
        if (match) {
          const { editable, shouldApprove, shouldNotify, approvalOrder } =
            match;
          setValue(`access.${index}.selected`, true);

          if (editable != null) {
            setValue(`access.${index}.editable`, editable || 'view-only');
          }

          setValue(`access.${index}.shouldNotify`, shouldNotify ?? undefined);
          setValue(
            `access.${index}.shouldApprove`,
            approvalOrder ?? (shouldApprove || undefined)
          );
        }
      });
    }
  }, [access, roleData, setValue]);

  if (roleResult.isFetching) {
    return (
      <div className="flex mx-auto">
        <ProgressSpinner />
      </div>
    );
  }

  if (roleResult.error) {
    const error = roleResult.error as AxiosError;
    const errorData = error?.response?.data as { message: string };
    return (
      <div className="pt-8">
        <ErrorMessage
          content={
            errorData.message ||
            'Failed to obtain role data! Please try again later.'
          }
        />
      </div>
    );
  }

  return (
    <div className="flex flex-column justify-content-center gap-5 pt-8">
      <ErrorToast toastRef={toast} />
      <div className="text-35px font-bold text-center mb-5 relative">
        <div>Update Role</div>
        {!isSuccess && !deleteRoleResult.isSuccess && (
          <div className="flex justify-content-center lg:absolute top-0 right-0 mt-4 lg:mt-0 lg:mr-8">
            <RemoveRoleButton
              onAccept={deleteRoleResult.mutate}
              isLoading={deleteRoleResult.isLoading}
            />
          </div>
        )}
      </div>
      {!isSuccess && !deleteRoleResult.isSuccess && (
        <FormProvider {...formMethods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="max-w-600px mx-auto flex flex-column gap-5"
          >
            <RegisterForm disableName={true} />
            <div className="flex flex-wrap gap-2 mb-3">
              <Button
                onClick={() => {
                  navigate('/admin/roles');
                }}
                className="w-15rem bluwaiRed border-0 flex m-auto py-3"
              >
                <span className="mx-auto text-22px text-white">Cancel</span>
              </Button>
              <LoadingButton
                isLoading={isLoading}
                label="Update"
                className="w-15rem bluwaiBlue border-0 flex m-auto py-3"
                fontSize="text-22px"
              />
            </div>
          </form>
        </FormProvider>
      )}
      {isSuccess && (
        <SuccessFormMessage
          message="The role has been updated!"
          redirectUrl="/admin/roles"
          redirectMessage="Click here to return to the roles page"
        />
      )}
      {deleteRoleResult.isSuccess && (
        <SuccessFormMessage
          message="The role has been removed!"
          redirectUrl="/admin/roles"
          redirectMessage="Click here to return to the roles page"
        />
      )}
    </div>
  );
};

export default RolesUpdate;
