import { EXCEL_FORMATS, transformBToMB } from '../../../../utils/fileUtil';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import FormErrorMessage from '../../../components/messages/FormErrorMessage';
import SingleFileInput from '../../../components/inputs/StyledInputs/SingleFileInput';
import { useEffect } from 'react';
import { FILE_SIZE_LIMIT } from 'apps/tmr-frontend/src/utils/fileConsts';

export type EstimatedCostFormFields = {
  phases: File;
  estimates: File;
};

const EstimatedCostForm = () => {
  const {
    control,
    formState: { errors },
    getValues,
    clearErrors,
  } = useFormContext<EstimatedCostFormFields>();
  const [phases, estimates] = useWatch<EstimatedCostFormFields>({
    name: ['phases', 'estimates'],
  });

  const validateAtLeastOneField = () => {
    const phases = getValues('phases');
    const estimates = getValues('estimates');
    if (!phases && !estimates) {
      return 'At least one of the files is required';
    }

    return true;
  };

  useEffect(() => {
    if (phases || estimates) {
      clearErrors();
    }
  }, [phases, estimates]);

  return (
    <div className="flex flex-column gap-4">
      <div>
        <label htmlFor="phase" className="text-standard block mb-2">
          Job Phases
        </label>
        <Controller
          name="phases"
          aria-label="file-input"
          control={control}
          rules={{
            validate: validateAtLeastOneField,
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              <SingleFileInput
                value={value}
                onChange={onChange}
                acceptedFormats={EXCEL_FORMATS}
                totalLimit={FILE_SIZE_LIMIT / 2}
                totalLimitLabel={`${transformBToMB(FILE_SIZE_LIMIT / 2)} MB`}
                emptyMessage={`Upload job phases file. Allowed extensions: ${EXCEL_FORMATS.join(
                  ', '
                )}.`}
                contentClassName={errors['phases'] && 'border-red-500'}
              />
              {errors['phases'] && (
                <FormErrorMessage
                  errorMessage={errors['phases'].message as string}
                />
              )}
            </div>
          )}
        />
      </div>
      <div>
        <label htmlFor="estimate" className="text-standard block mb-2">
          Estimated Cost
        </label>
        <Controller
          name="estimates"
          aria-label="file-input"
          control={control}
          rules={{
            validate: validateAtLeastOneField,
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              <SingleFileInput
                value={value}
                onChange={onChange}
                acceptedFormats={EXCEL_FORMATS}
                totalLimit={FILE_SIZE_LIMIT / 2}
                totalLimitLabel={`${transformBToMB(FILE_SIZE_LIMIT / 2)} MB`}
                emptyMessage={`Upload Estimates file. Allowed extensions: ${EXCEL_FORMATS.join(
                  ', '
                )}.`}
                contentClassName={errors['estimates'] && 'border-red-500'}
              />
              {errors['estimates'] && (
                <FormErrorMessage
                  errorMessage={errors['estimates'].message as string}
                />
              )}
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default EstimatedCostForm;
