import { Link } from 'react-router-dom';

const SystemError = () => {
  return (
    <div className="errorText w-fit mx-auto flex flex-column pt-5">
      <i className="pi pi-exclamation-triangle text-70px mx-auto" />
      <p className="text-xl md:text-4xl w-fit mx-auto">
        Oops! Something went wrong.
      </p>
      <div className="text-base md:text-xl mx-2">
        <p>In the meantime, you might try:</p>
        <ul>
          <li>Refreshing the page, or</li>
          <li>
            <span className="mr-1">Returning to the</span>
            <Link to="/" className="totalColor hover:underline">
              home page
            </Link>
            .
          </li>
        </ul>
        <p>
          If the problem persists, please feel free to contact our support team.
        </p>
      </div>
    </div>
  );
};

export default SystemError;
