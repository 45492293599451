import { downloadFileUsingPath } from '../../../../utils/fileUtil';
import { Button } from 'primereact/button';

const DownloadTemplate = () => {
  const onClick = () => {
    downloadFileUsingPath('/assets/templates/ACForms/SOV Template.xlsx');
  };

  return (
    <Button
      className="w-17rem buttonSecondary border-0 flex py-3"
      onClick={onClick}
    >
      <span className="mx-auto text-22px text-white">Download Template</span>
    </Button>
  );
};

export default DownloadTemplate;
