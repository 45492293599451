export const VendorType = {
  R: 'Regular',
  M: 'Material',
  S: 'Subcontract',
  E: 'Equipment',
  C: 'Consulting',
};

export const PaymentMethod = {
  C: 'Check',
  E: 'EFT',
};
