import React from 'react';
import { Calendar, CalendarProps } from 'primereact/calendar';

type CalendarStyledProps = CalendarProps & {
  error?: boolean;
};

const CalendarStyled = React.forwardRef<Calendar, CalendarStyledProps>(
  (props: CalendarStyledProps, ref) => {
    const { error, ...otherProps } = props;

    return (
      <Calendar
        panelClassName="radius-33px"
        className='w-12'
        inputClassName={`text-standard blackText fieldBorder ${
          error && 'p-invalid'
        }`}
        ref={ref}
        {...otherProps}
      />
    );
  }
);

export default CalendarStyled;
