import { Link, LinkProps } from 'react-router-dom';
import checkPermissions from '../../HOCs/CheckPermissions';
import { navOption } from '../../Interfaces/NavBar.interfaces';

export type NavLinkProps = {
  option: navOption;
  isActive: boolean;
  onClick: (option: navOption) => void;
};

const NavLink = ({ option, isActive, onClick }: NavLinkProps) => {
  const LinkComponent = option.reports
    ? checkPermissions<LinkProps>({
        sectionsToAccess: option.reports,
        Children: Link,
      })
    : Link;

  return (
    <LinkComponent
      to={option.url}
      onClick={() => onClick(option)}
      className={`cursor-pointer navbarItem ${
        isActive && 'active'
      } blackText relative h-full flex align-items-center`}
    >
      <div className={option.style}>{option.label}</div>
    </LinkComponent>
  );
};

export default NavLink;
