import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { ROLE_TYPE_OPTIONS } from '../../../utils/rolesConst';
import DropdownStyled from '../../components/inputs/StyledInputs/DropdownStyled';
import MultipleSelectStyled from '../../components/inputs/StyledInputs/MultipleSelectStyled';
import { useQuery } from '@tanstack/react-query';
import { getRoles } from '../../services/RolesService';
import { RoleSummary } from '../../Interfaces/Role.interfaces';

const IS_ADMIN_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export type UsersFiltersArgs = {
  userType?: string;
  isAdmin?: boolean;
  roles?: string[];
  info?: string;
};

const UsersFilter = () => {
  const [roles, setRoles] = useState<RoleSummary[]>([]);
  const { control, watch, resetField } = useFormContext();
  const userType = watch('userType');

  const { isFetching, isError } = useQuery({
    queryKey: ['getSystemRoles', userType],
    queryFn: ({ signal }) => getRoles({ roleType: userType }, signal),
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setRoles(data.roles);
    },
  });

  const onUserTypeChange = () => {
    resetField('roles');
  };

  return (
    <div className="flex flex-wrap justify-content-center align-items-end md:justify-content-start gap-4 form">
      <div className="w-15rem">
        <div className="text-standard mb-2">User Type</div>
        <Controller
          control={control}
          name="userType"
          render={({ field: { onChange, ...otherFields } }) => (
            <DropdownStyled
              options={ROLE_TYPE_OPTIONS}
              clearable={true}
              labelField="label"
              valueField="value"
              placeholder="Select User Type"
              onChange={(e) => {
                onChange(e);
                onUserTypeChange();
              }}
              {...otherFields}
            />
          )}
        />
      </div>
      <div className="w-15rem">
        <Controller
          control={control}
          name="roles"
          render={({ field: { value, ...otherFields } }) => (
            <MultipleSelectStyled
              isDisabled={isError}
              resetFilterOnHide={true}
              showSelectAll={false}
              filter={true}
              options={roles}
              labelField="name"
              valueField="id"
              isLoading={isFetching}
              placeholder={isError ? 'Failed to load roles!' : 'Select Role'}
              value={isFetching ? null : value}
              clearable={true}
              {...otherFields}
            />
          )}
        />
      </div>
      <div className="w-15rem">
        <div className="text-standard mb-2">Is System Admin</div>
        <Controller
          control={control}
          name="isAdmin"
          render={({ field }) => (
            <DropdownStyled
              options={IS_ADMIN_OPTIONS}
              clearable={true}
              labelField="label"
              valueField="value"
              optionDisabled="disabled"
              placeholder="Select option"
              {...field}
            />
          )}
        />
      </div>
      <div className="w-15rem align-self-end">
        <Controller
          control={control}
          name="info"
          defaultValue=""
          render={({ field }) => (
            <span className="p-input-icon-right">
              <InputText
                type="text"
                placeholder="Search"
                className="fieldBorder w-full text-standard blackText"
                {...field}
              />
              <i className="pi pi-search" />
            </span>
          )}
        />
      </div>
    </div>
  );
};

export default UsersFilter;
