import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import {
  EmployeeTimesheet,
  PayrollTimesheetDetails,
  TimeSheetArgs,
} from '../../../Interfaces/Accounting/ACForms.interfaces';
import { fetchPREmployeesTimesheet } from '../../../services/PayrollService';
import TimesheetTable from './TimesheetTable';
import { useEffect, useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { PRTimesheetFilters } from './TimesheetFilters';
import { v4 as uuidv4 } from 'uuid';
import { useRolesAccessContext } from '../../../context/RolesAccessContext';
import { transformUTCtoLocale } from 'apps/tmr-frontend/src/utils/dateUtils';

type TimeSheetTableContainerProps = {
  periodStart: Date;
  periodEnd: Date;
  defaultTimesheet?: TimeSheetArgs;
  defaultLoading?: boolean;
};

const TimeSheetTableContainer = ({
  periodStart,
  periodEnd,
  defaultTimesheet,
  defaultLoading,
}: TimeSheetTableContainerProps) => {
  const { selectedCompany } = useCompanyContext();
  const { rolesAcess } = useRolesAccessContext();
  const submittedID = useWatch<PRTimesheetFilters>({ name: 'submittedID' });
  const [employees, setEmployees] = useState<EmployeeTimesheet[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const access = rolesAcess?.find(
    (access) => access.report === 'new_pr_timesheet'
  );
  const currentdefTimesheet = useRef(defaultTimesheet);

  const { data, isError } = useQuery({
    queryKey: ['getPREmployeesTimesheet', selectedCompany, submittedID],
    queryFn: ({ signal }) => {
      setIsLoading(true);
      return fetchPREmployeesTimesheet(
        selectedCompany!.id,
        access?.shouldApprove ? submittedID : undefined,
        signal
      );
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
    onSuccess: (data) => {
      setEmployees(formatData(data.employees));
      setIsLoading(false);
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  const formatData = (data: EmployeeTimesheet[]) => {
    return data.map((emp) => {
      const details = emp.details ?? [];

      details.forEach((detail) => {
        emp.useDetails = true;

        if (!detail.id) {
          detail.id = uuidv4();
        }

        if (!detail.dateDetails) {
          detail.dateDetails = [{ id: uuidv4() }];
        } else {
          detail.dateDetails.forEach((dateDet) => {
            if (!dateDet.id) {
              dateDet.id = uuidv4();
            }
          });

          detail.dateDetails.push({ id: uuidv4() });
          detail.useDateDetails = true;
        }

        if (detail.date) {
          detail.date = transformUTCtoLocale(detail.date);
        }
      });

      const sortedDetails = details.sort((a, b) => {
        if (!a.date && b.date) {
          return 1;
        }

        if (a.date && !b.date) {
          return -1;
        }

        if (a.date && b.date) {
          return a.date.getTime() - b.date.getTime();
        }

        return 0;
      });

      sortedDetails.push({
        id: uuidv4(),
        dateDetails: [{ id: uuidv4() }],
      });

      emp.details = sortedDetails;

      return emp;
    });
  };

  useEffect(() => {
    if (defaultTimesheet && defaultTimesheet !== currentdefTimesheet.current) {
      const newEmpData = employees.map((emp: EmployeeTimesheet) => {
        const defaultEmp = defaultTimesheet.find(
          (defaultEmp) => emp.empID === defaultEmp.empID
        );

        let newDetails: PayrollTimesheetDetails[] | null = null;
        if (defaultEmp?.details) {
          newDetails = defaultEmp.details.map((detail) => {
            return { ...detail, id: uuidv4() };
          });
        }

        return defaultEmp
          ? {
              ...defaultEmp,
              firstName: emp.firstName,
              lastName: emp.lastName,
              details: newDetails ?? undefined,
            }
          : emp;
      });

      setEmployees(formatData(newEmpData));
      currentdefTimesheet.current = defaultTimesheet;
    }
  }, [defaultTimesheet, employees]);

  if (
    isLoading ||
    defaultLoading ||
    defaultTimesheet !== currentdefTimesheet.current
  ) {
    return (
      <div className="text-center mx-auto mt-3">
        <ProgressSpinner />
      </div>
    );
  }

  if (isError) {
    return (
      <ErrorMessage
        content={'Failed to obtain data! Please try again later.'}
      />
    );
  }

  return (
    <TimesheetTable
      employees={employees}
      periodStart={periodStart}
      periodEnd={periodEnd}
      draftID={data?.draft?.id}
      draftIsSubmitted={data?.draft?.submitted}
      approvalMode={!!submittedID}
      access={access ?? { report: 'new_pr_timesheet' }}
    />
  );
};

export default TimeSheetTableContainer;
