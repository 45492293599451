import React from 'react';
import { Sidebar } from 'primereact/sidebar';
import { useQuery } from '@tanstack/react-query';
import { useCompanyContext } from '../../../context/CompanyContext';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import CIDrilldownContent from './CIDrilldownContent';
import { fecthContractItems } from '../../../services/ContractBillingsService';

type CIDrilldownContainerProps = {
  visible: boolean;
  hideDrilldown: () => void;
  contract: string;
  contractName: string;
};

const CIDrilldownContainer = ({
  visible,
  hideDrilldown,
  contract,
  contractName,
}: CIDrilldownContainerProps) => {
  const { selectedCompany } = useCompanyContext();

  const { data, isFetching, isError, isSuccess } = useQuery({
    queryKey: ['getDrilldownContractItems', selectedCompany, contract],
    queryFn: ({ signal }) =>
      fecthContractItems(
        {
          companyID: selectedCompany!.id,
          contract,
        },
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={hideDrilldown}
      className="w-full max-w-1100px"
    >
      {isFetching && (
        <div className="mx-8 h-full flex align-items-center">
          <ProgressSpinner />
        </div>
      )}
      {isError && (
        <div className="h-full flex align-items-center">
          <ErrorMessage
            content={'Failed to obtain data! Please try again later.'}
          />
        </div>
      )}
      {isSuccess && (
        <CIDrilldownContent
          items={data}
          contract={contract}
          contractName={contractName}
        />
      )}
    </Sidebar>
  );
};

export default CIDrilldownContainer;
