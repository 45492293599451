import DropdownStyled from '../../../components/inputs/StyledInputs/DropdownStyled';
import { Controller, useFormContext } from 'react-hook-form';
import { useCompanyContext } from '../../../context/CompanyContext';
import { InputText } from 'primereact/inputtext';
import CalendarStyled from '../../../components/inputs/StyledInputs/CalendarStyled';
import MultipleSelectStyled from '../../../components/inputs/StyledInputs/MultipleSelectStyled';
import { useQuery } from '@tanstack/react-query';
import {
  fetchARContracts,
  fetchARCustomers,
} from '../../../services/AccountsReceivablesService';
import { MODULES } from '../../../../utils/rolesConst';

export const RECEIVABLES_OPTIONS = MODULES['accounts receivables'];

export const PERIOD_OPTIONS = [
  { label: 'Future', value: 'future' },
  { label: 'Current', value: '30' },
  { label: '31 - 60', value: '60' },
  { label: '61 - 90', value: '90' },
  { label: 'Over 90', value: '91' },
  { label: 'Retainage', value: 'retainage' },
];

export type ARFiltersArgs = {
  reportType?: string;
  period?: string;
  info?: string;
  customers?: number[];
  dates?: Date[];
  contracts?: string[];
};

const ARFilters = () => {
  const { selectedCompany } = useCompanyContext();
  const { control, watch, setValue } = useFormContext();
  const reportType = watch('reportType');

  const { data, isFetching, isError } = useQuery({
    queryKey: ['getARCustomer', selectedCompany],
    queryFn: ({ signal }) => fetchARCustomers(selectedCompany!, signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
    staleTime: 1000 * 60 * 10,
  });

  const contractsRequest = useQuery({
    queryKey: ['getARContracts', selectedCompany, reportType],
    queryFn: ({ signal }) =>
      fetchARContracts(selectedCompany!, reportType, signal),
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && reportType && reportType !== 'customerList'),
    staleTime: 1000 * 60 * 10,
  });

  return (
    <>
      {reportType !== 'customerList' && (
        <div className="col">
          <Controller
            control={control}
            name="customers"
            render={({ field: { value, ...otherFields } }) => (
              <MultipleSelectStyled
                isDisabled={isError}
                resetFilterOnHide={true}
                showSelectAll={false}
                filter={true}
                options={data}
                labelField="name"
                valueField="accountNumber"
                isLoading={isFetching}
                placeholder={
                  isError ? 'Failed to load customers!' : 'Select Customer'
                }
                value={isFetching ? null : value}
                {...otherFields}
              />
            )}
          />
        </div>
      )}
      {reportType !== 'customerList' && (
        <div className="col">
          <Controller
            control={control}
            name="contracts"
            render={({ field: { value, ...otherFields } }) => (
              <MultipleSelectStyled
                isDisabled={contractsRequest.isError}
                resetFilterOnHide={true}
                showSelectAll={false}
                filter={true}
                options={contractsRequest.data}
                labelField="Contract"
                valueField="Contract"
                isLoading={contractsRequest.isFetching}
                placeholder={
                  contractsRequest.isError
                    ? 'Failed to load contracts!'
                    : 'Select Contract'
                }
                value={contractsRequest.isFetching ? null : value}
                {...otherFields}
              />
            )}
          />
        </div>
      )}
      {reportType !== 'customerList' && (
        <div className="col">
          <Controller
            control={control}
            name="contracts"
            render={({ field: { value, ...otherFields } }) => (
              <MultipleSelectStyled
                isDisabled={isError}
                resetFilterOnHide={true}
                showSelectAll={false}
                filter={true}
                options={contractsRequest.data}
                labelField="Description"
                valueField="Contract"
                isLoading={contractsRequest.isFetching}
                placeholder={
                  contractsRequest.isError
                    ? 'Failed to load contract names!'
                    : 'Select Contract Name'
                }
                value={contractsRequest.isFetching ? null : value}
                {...otherFields}
              />
            )}
          />
        </div>
      )}
      {reportType !== 'customerList' && (
        <div className="col">
          <Controller
            control={control}
            defaultValue=""
            name="dates"
            render={({ field }) => (
              <CalendarStyled
                selectionMode="range"
                placeholder="Select Date Range"
                readOnlyInput
                showButtonBar
                {...field}
              />
            )}
          />
        </div>
      )}
      <div className="col">
        <Controller
          control={control}
          defaultValue=""
          name="info"
          render={({ field }) => (
            <span className="p-input-icon-right w-full">
              <InputText
                type="text"
                placeholder="Search"
                className="fieldBorder w-full text-standard blackText"
                {...field}
              />
              <i className="pi pi-search" />
            </span>
          )}
        />
      </div>

      {reportType === 'agedAnalysis' && (
        <div className="col">
          <Controller
            defaultValue={undefined}
            control={control}
            name="period"
            render={({ field: { onChange, ...field } }) => (
              <DropdownStyled
                options={PERIOD_OPTIONS}
                clearable={true}
                labelField="label"
                valueField="value"
                placeholder="Select Aging Period"
                onChange={(e) => {
                  if (!e.value) {
                    setValue('period', null);
                  } else {
                    onChange(e.value);
                  }
                }}
                {...field}
              />
            )}
          />
        </div>
      )}
    </>
  );
};

export default ARFilters;
