import { FieldError, FieldErrorsImpl } from 'react-hook-form';

export const getFieldArrayError = (
  formID: string,
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >
) => {
  let fieldError: FieldError | undefined = undefined;
  const levels = formID.split('.');
  let errorJson = errors;

  for (let key of levels) {
    if (key === levels[levels.length - 1]) {
      fieldError = errorJson[key] as FieldError;
    } else {
      const ex = errorJson[key];
      errorJson = (errorJson[key] ?? {}) as Partial<
        FieldErrorsImpl<{ [x: string]: any }>
      >;
    }
  }
  return fieldError;
};
