import { formatCurrency } from 'apps/tmr-frontend/src/utils/formatUtils';
import { Panel } from 'primereact/panel';
import FormInfo from '../../../../components/messages/FormInfo';
import { POPieceWork } from '../../../../Interfaces/Accounting/ACForms.interfaces';

type PieceWorkSummaryProps = {
  pieceWorks: POPieceWork[];
  extras: Record<string, string>;
  ums: Record<string, string>;
  costCodes: Record<string, string>;
};

const PieceWorkSummary = ({
  pieceWorks,
  extras,
  ums,
  costCodes,
}: PieceWorkSummaryProps) => {
  let total = 0;
  pieceWorks.forEach((pw) => {
    pw.extras?.forEach((extra) => (total += extra.total ?? 0));
  });

  return (
    <Panel header="Piece Work" className="max-w-1200px mx-3 text-standard">
      <div className="flex flex-column row-gap-3">
        {pieceWorks.map((pW) => {
          let acc = 0;
          pW.extras?.forEach((extra) => (acc += extra.total ?? 0));
          return (
            <div className="grid" key={`${pW.description}`}>
              <div className="col-12 pb-1">
                <FormInfo label="Item Description" content={pW.description} />
              </div>
              <div className="col-12 grid ml-2 mt-2 font-bold pb-0 hidden lg:flex text-center">
                <div className="col-2 pt-0 word-break-word">Extra/Location</div>
                <div className="col-3 pt-0 word-break-word">Cost Code</div>
                <div className="col-2 pt-0">UoM</div>
                <div className="col-1 pt-0">Quantity</div>
                <div className="col-2 pt-0">Price</div>
                <div className="col-2 pt-0">Total</div>
              </div>
              {pW.extras?.map((extra, index) => {
                const { costCode, um } = extra;
                const phaseDescription = costCodes[costCode!];

                return (
                  <div className="col-12 grid ml-2 mt-2 pt-0 pb-3" key={index}>
                    <div className="col-12 sm:col-4 lg:col-2 pt-0 word-break-word lg:text-center">
                      <span className="font-bold mr-1 lg:hidden">
                        Extra/Location:
                      </span>
                      {extras[extra.extra!] ?? extra.extra}
                    </div>
                    <div className="col-12 sm:col-4 lg:col-3 pt-0 word-break-word">
                      <span className="font-bold mr-1 lg:hidden">
                        Cost Code:
                      </span>
                      {phaseDescription
                        ? `${costCode} - ${phaseDescription}`
                        : costCode}
                    </div>
                    <div className="col-12 sm:col-4 lg:col-2 pt-0 lg:text-center">
                      <span className="font-bold mr-1 lg:hidden">UoM:</span>
                      {ums[um!] ?? um}
                    </div>
                    <div className="col-12 sm:col-4 lg:col-1 pt-0 lg:text-right">
                      <div className="sm:ml-4 xl:ml-0">
                        <span className="font-bold mr-1 lg:hidden">
                          Quantity:
                        </span>
                        {extra.quantity}
                      </div>
                    </div>
                    <div className="col-12 sm:col-4 lg:col-2 pt-0 lg:text-right">
                      <span className="font-bold mr-1 lg:hidden">Price:</span>
                      {formatCurrency(extra.price ?? 0)}
                    </div>
                    <div className="col-12 sm:col-4 lg:col-2 pt-0 lg:text-right">
                      <span className="font-bold mr-1 lg:hidden">Total:</span>
                      {formatCurrency(extra.total ?? 0)}
                    </div>
                  </div>
                );
              })}
              <div className="col-12 grid ml-2 py-0 mt-2 lg:mt-0">
                <div className="pt-0 pl-2 lg:pl-0 sm:col-4 lg:col-2 sm:col-offset-8 lg:col-offset-10 lg:text-right font-bold">
                  Item Total: {formatCurrency(acc)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="font-bold sm:text-right mt-4 mb-1">
        PO Total: {formatCurrency(total)}
      </div>
    </Panel>
  );
};

export default PieceWorkSummary;
