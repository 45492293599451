import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import React, { useRef, useState } from 'react';
import firebaseErrorMessages from '../../../components/errorMessages/firebase';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import SuccessFormMessage from '../../../components/messages/SuccessFormMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import { FolderSummary } from '../../../Interfaces/Accounting/FinancialStatements.interface';
import { deleteFolder } from '../../../services/FinancialStatementsService';

type FolderRemovalProps = {
  folder: FolderSummary;
  visible: boolean;
  onHide: () => void;
  fetchFolders?: () => void;
};

const FolderRemoval = ({
  folder,
  visible,
  onHide,
  fetchFolders,
}: FolderRemovalProps) => {
  const { selectedCompany } = useCompanyContext();
  const [confirmVisible, setConfirmVisible] = useState(visible);
  const [resultVisible, setResultVisible] = useState(false);
  const toast = useRef<Toast>(null);

  const { mutate, isLoading, isSuccess, isError } = useMutation({
    mutationFn: () => {
      return deleteFolder(selectedCompany!.id, folder.id);
    },
    onError: (error: AxiosError) => {
      setConfirmVisible(true);
      setResultVisible(false);
      const errorData = error.response?.data as { code: string };
      showToast(
        'error',
        toast,
        'Remove Folder Error',
        firebaseErrorMessages[errorData?.code] ||
          'An error ocurred! please try again later',
        3000
      );
    },
  });

  const hideConfirm = (result: string) => {
    setConfirmVisible(false);
    if (result === 'cancel' && !resultVisible) {
      onHide();
    }
  };

  const hideDialog = () => {
    setResultVisible(false);
    onHide();
    if (isSuccess && fetchFolders) {
      fetchFolders();
    }
  };

  const accepted = () => {
    mutate();
    setResultVisible(true);
  };

  return (
    <React.Fragment>
      <ConfirmDialog
        visible={confirmVisible}
        onHide={hideConfirm}
        message={`Do you want to remove the folder ${folder.name} ?`}
        header="Folder Removal Confirmation"
        icon="pi pi-info-circle"
        acceptClassName="p-button-danger"
        accept={accepted}
      />
      <Dialog
        header="Folder Removal in progress"
        visible={resultVisible}
        onHide={() => hideDialog()}
        className="w-fit"
        contentClassName="flex justify-content-center"
      >
        {(isLoading || isError) && <ProgressSpinner className="mx-auto" />}
        {isSuccess && (
          <SuccessFormMessage
            message={`The folder ${folder.name} has been removed!`}
          />
        )}
      </Dialog>
      <ErrorToast toastRef={toast} />
    </React.Fragment>
  );
};

export default FolderRemoval;
