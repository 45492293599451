import { useMutation } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import React, { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { Toast } from 'primereact/toast';
import RegisterForm, {
  RegisterFormFields,
} from '../../../features/user/RegisterForm';
import { auth } from '../../../../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import firebaseErrorMessages from '../../../components/errorMessages/firebase';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import SuccessMessage from '../../../features/user/SuccessMessage';
import { postUsers, postUsersArgs } from '../../../services/UsersService';
import LoadingButton from '../../../components/inputs/LoadingButton';
import { processAccessForm } from 'apps/tmr-frontend/src/utils/roleUtils';

const UserForm = () => {
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  const formMethods = useForm<RegisterFormFields>();
  const { handleSubmit } = formMethods;

  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: (data: postUsersArgs) => {
      return postUsers(data);
    },
    onSuccess: async (data) => {
      await sendPasswordResetEmail(auth, data.email);
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as { code: string };
      showToast(
        'error',
        toast,
        'Register User Error',
        firebaseErrorMessages[errorData?.code] ||
          'An error ocurred! please try again later',
        3000
      );
    },
  });

  const onSubmit = (data: RegisterFormFields) => {
    const { company, access, ...otherData } = data;

    const list = processAccessForm(access);

    const payload = {
      ...otherData,
      isAdmin: otherData.isAdmin === 'yes',
      roles: otherData.roles || [],
      company: company?.length ? company : undefined,
      access: list,
    };

    mutate(payload);
  };

  return (
    <div className="flex flex-column justify-content-center gap-5 pt-8">
      <ErrorToast toastRef={toast} />
      <div className="text-35px font-bold text-center mb-5">Register User</div>
      {!isSuccess && (
        <FormProvider {...formMethods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="max-w-600px mx-auto flex flex-column gap-5"
          >
            <RegisterForm />
            <div className="flex flex-wrap gap-2 mb-3">
              <Button
                onClick={() => {
                  navigate('/admin/users');
                }}
                className="w-15rem bluwaiRed border-0 flex m-auto py-3"
              >
                <span className="mx-auto text-22px text-white">Cancel</span>
              </Button>
              <LoadingButton
                isLoading={isLoading}
                label="Register"
                className="w-15rem bluwaiBlue border-0 flex m-auto py-3"
                fontSize="text-22px"
              />
            </div>
          </form>
        </FormProvider>
      )}
      {isSuccess && (
        <SuccessMessage message="The user has been created and an email was sent to set the password!" />
      )}
    </div>
  );
};

export default UserForm;
