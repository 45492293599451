import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import useFiltersCache from '../../hooks/useFiltersCache';
import { JCFilterFields } from '../../Interfaces/Accounting/JobsContracts.interface';
import { JobContractFilters } from './JCFilters';
import CryptoJS from 'crypto-js';

const params: { label: JCFilterFields; isEncrypted?: boolean }[] = [
  { label: 'reportType' },
  { label: 'job', isEncrypted: true },
  { label: 'submittedID' },
];

const formatFilters = (filters: JobContractFilters) => {
  return {
    ...filters,
    startDate: filters.startDate ? filters.startDate.toString() : null,
    endDate: filters.endDate ? filters.endDate.toString() : null,
  };
};

const JCParams = () => {
  const [cookies] = useCookies(['filters']);
  const [searchParams, setSearchParams] = useSearchParams();
  const { setValue } = useFormContext<JobContractFilters>();
  const filters = useWatch() as JobContractFilters;
  useFiltersCache({ tab: 'JC', filters: formatFilters(filters) });
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    const secretKey = process.env['NX_CRYPTO_PUBLIC_KEY'] ?? '';

    let hasParam = false;
    params.forEach((param) => {
      let value = searchParams.get(param.label);
      if (value) {
        if (param.isEncrypted) {
          const bytes = CryptoJS.AES.decrypt(value, secretKey);
          value = bytes.toString(CryptoJS.enc.Utf8);
        }

        setValue(param.label, value);
        hasParam = true;
      }
    });

    if (hasParam) {
      setSearchParams();
      setRendered(true);
    } else if (!rendered && cookies.filters?.tab === 'JC') {
      setRendered(true);
      const cookieFilters = cookies.filters?.filters;
      if (cookieFilters.startDate) {
        cookieFilters.startDate = new Date(cookieFilters.startDate);
      }
      if (cookieFilters.endDate) {
        cookieFilters.endDate = new Date(cookieFilters.endDate);
      }

      for (const i in cookieFilters) {
        setValue(i as JCFilterFields, cookieFilters[i]);
      }
    }
  }, [
    setValue,
    searchParams,
    setSearchParams,
    rendered,
    setRendered,
    cookies.filters,
  ]);

  return <span />;
};

export default JCParams;
