import React from 'react';
import ReportButton from '../../../components/report/ReportButton';
import * as XLSX from 'xlsx';
import { useCompanyContext } from '../../../context/CompanyContext';
import { useWatch } from 'react-hook-form';
import { formatDate } from '../../../../utils/formatUtils';
import { fetchCashManagementAccounts } from '../../../services/CashManagementService';
import { BASE_PDF_STYLES } from '../../../../utils/reportUtils';
import { excelCleanTable } from '../../../../utils/excelUtils';
import { useQuery } from '@tanstack/react-query';
import { CashManagementFilterFields } from '../CashManagementFilters';
import { CMAccount } from '../../../Interfaces/Accounting/CashManagement.interfaces';

type CashManagementReportsProps = {
  elementRef: React.MutableRefObject<HTMLElement | null>;
};

const CashManagementReports = ({ elementRef }: CashManagementReportsProps) => {
  const { selectedCompany } = useCompanyContext();
  const filters = useWatch<CashManagementFilterFields>();
  const reportDate = new Date();

  const { data } = useQuery({
    queryKey: ['getCashManagementAccounts', selectedCompany],
    queryFn: ({ signal }) =>
      fetchCashManagementAccounts(
        selectedCompany!.source,
        selectedCompany!.id,
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const excelElementPreProcess = (element: HTMLElement) => {
    excelCleanTable(element);

    return element;
  };

  const excelBeforeDownload = (workBook: XLSX.WorkBook) => {
    const sheet = workBook.Sheets['Sheet1'];
    if (sheet['!cols']) {
      sheet['!cols'][1].width = 20;
      sheet['!cols'][2].width = 15;
    }
    const companyName = selectedCompany!.name;
    const searchTerm = filters.info;
    const dateSearch = filters.dates;

    const account = data?.find((acc: CMAccount) => acc.id === filters.account);

    sheet['D2'] = { t: 's', v: companyName };
    sheet['D3'] = { t: 's', v: 'Cash Management Report' };
    sheet['A4'] = { t: 's', v: 'Search criteria:' };
    sheet['B5'] = { t: 's', v: 'Bank account:' };
    sheet['C5'] = { t: 's', v: account?.name };
    if (dateSearch) {
      sheet['B6'] = { t: 's', v: 'Date range:' };
      sheet['C6'] = {
        t: 's',
        v: `${formatDate(dateSearch[0])} ${
          dateSearch[1] ? ` - ${formatDate(dateSearch[1])}` : ''
        } `,
      };
    } else {
      sheet['B6'] = { t: 's', v: 'Month:' };
      sheet['C6'] = {
        t: 's',
        v: new Date().toLocaleString('en-US', { month: 'long' }),
      };
    }

    if (searchTerm) {
      sheet['B7'] = { t: 's', v: 'Search term:' };
      sheet['C7'] = { t: 's', v: searchTerm };
    }

    sheet['A8'] = { t: 's', v: 'Created at:' };
    sheet['B8'] = { t: 's', v: reportDate.toLocaleString() };
  };

  return (
    <ReportButton
      elementToPrintRef={elementRef}
      fileName="CM_Report"
      pdfStyles={BASE_PDF_STYLES}
      excelPreprocess={excelElementPreProcess}
      excelBeforeDownload={excelBeforeDownload}
      widthElementID="cm-table"
    />
  );
};

export default CashManagementReports;
