import React, { useCallback } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import { Earnings } from '../../../Interfaces/Accounting/Payroll.interface';
import { formatCurrency } from '../../../../utils/formatUtils';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

type EarningsTableProps = {
  earnings: Earnings[];
};

const EarningsTable = React.forwardRef<HTMLDivElement, EarningsTableProps>(
  ({ earnings }, ref) => {
    const headerClassName =
      'tableHeader flex font-normal justify-content-center text-center text-standard blackText tableCell';

    const calcHeight = (rows: number) => {
      const headerHeight = 60.5;
      const footerHeight = 54;
      const rowHeight = 31;
      return headerHeight + footerHeight + rows * rowHeight + 3;
    };

    const onRowHover = (e: DataTableRowMouseEventParams) => {
      const cell = e.originalEvent.target as HTMLElement;
      const row = cell.closest('tr') as HTMLElement;
      animateCellText(row);
    };

    let salary = 0;
    let hourly = 0;
    let auto = 0;
    let phone = 0;
    let insurance = 0;
    let other = 0;
    earnings.forEach((earning) => {
      salary += earning.Salary;
      hourly += earning.Hourly;
      auto += earning.Auto;
      phone += earning.Phone;
      insurance += earning.Insurance;
      other += earning.Other;
    });

    const headerGroup = (
      <ColumnGroup>
        <Row className="rowCLassName">
          <Column headerClassName="tableCell flex p-0 printHide tableHeader" />
          <Column
            style={{ minWidth: '740px', maxWidth: '740px' }}
            headerClassName="tableHeader flex tableCell"
            colSpan={5}
          />
          <Column
            header="Allowances"
            style={{ minWidth: '480px', maxWidth: '480px' }}
            headerClassName={headerClassName}
            className="pb-1"
            colSpan={4}
          />
          <Column headerClassName="tableCell flex p-0 printHide tableHeader" />
        </Row>
        <Row className="flex">
          <Column headerClassName="tableCell flex p-0 printHide tableHeader" />
          <Column
            field="EmpID"
            header="Emp ID"
            style={{ minWidth: '140px', maxWidth: '140px' }}
            headerClassName={headerClassName}
            sortable
          />
          <Column
            field="LastName"
            header="Last Name"
            style={{ minWidth: '200px', maxWidth: '200px' }}
            headerClassName={headerClassName}
            sortable
          />
          <Column
            field="FirstName"
            header="First Name"
            style={{ minWidth: '160px', maxWidth: '160px' }}
            headerClassName={headerClassName}
            sortable
          />
          <Column
            field="Salary"
            header="Salary"
            style={{ minWidth: '120px', maxWidth: '120px' }}
            headerClassName={headerClassName}
            sortable
          />
          <Column
            field="Hourly"
            header="Hourly"
            style={{ minWidth: '120px', maxWidth: '120px' }}
            headerClassName={headerClassName}
            sortable
          />
          <Column
            field="Auto"
            header="Auto"
            style={{ minWidth: '120px', maxWidth: '120px' }}
            headerClassName={headerClassName}
            className="border-none border-left-1 border-top-1 border-solid border-900"
            sortable
          />
          <Column
            field="Phone"
            header="Phone"
            style={{ minWidth: '120px', maxWidth: '120px' }}
            headerClassName={headerClassName}
            className="border-none border-top-1 border-solid border-900"
            sortable
          />
          <Column
            field="Insurance"
            header="Insurance"
            style={{ minWidth: '120px', maxWidth: '120px' }}
            headerClassName={headerClassName}
            className="border-none border-top-1 border-solid border-900"
            sortable
          />
          <Column
            field="Other"
            header="Other"
            body={(pr) => formatCurrency(pr.Other)}
            style={{ minWidth: '120px', maxWidth: '120px' }}
            headerClassName={headerClassName}
            className="border-none border-right-1 border-top-1 border-solid border-900"
            sortable
          />
          <Column headerClassName="tableCell flex p-0 printHide tableHeader" />
        </Row>
      </ColumnGroup>
    );

    return (
      <div className="pdfDivFitContent">
        <Table
          id="pr-earnings"
          ref={ref}
          data={earnings}
          className={`mx-3 dashboardOptionShadow headerFlex standardExtraHeader pdfFitTableContent`}
          calcHeight={useCallback(calcHeight, [])}
          onRowMouseEnter={useCallback(onRowHover, [])}
          headerColumnGroup={headerGroup}
          hideColumns={true}
        >
          <Column
            className="tableCell p-0 printHide"
            footerClassName="tableFooter"
          />
          <Column
            field="EmpID"
            header="Emp ID"
            style={{ minWidth: '140px', maxWidth: '140px' }}
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter"
          />
          <Column
            field="LastName"
            header="Last Name"
            body={(pr) => {
              return <div className="scroll-text">{pr.LastName}</div>;
            }}
            style={{ minWidth: '200px', maxWidth: '200px' }}
            className=" text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
            footerClassName="tableFooter"
          />
          <Column
            field="FirstName"
            header="First Name"
            body={(pr) => {
              return <div className="scroll-text">{pr.FirstName}</div>;
            }}
            style={{ minWidth: '160px', maxWidth: '160px' }}
            className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
            footerClassName="overflow-x-visible tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
            footer="Total"
          />
          <Column
            field="Salary"
            header="Salary"
            body={(pr) => formatCurrency(pr.Salary)}
            style={{ minWidth: '120px', maxWidth: '120px' }}
            className="justify-content-end text-standard blackText tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={formatCurrency(salary)}
          />
          <Column
            field="Hourly"
            header="Hourly"
            style={{ minWidth: '120px', maxWidth: '120px' }}
            body={(pr) => formatCurrency(pr.Hourly)}
            className="justify-content-end text-standard blackText tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={formatCurrency(hourly)}
          />
          <Column
            field="Auto"
            header="Auto"
            body={(pr) => formatCurrency(pr.Auto)}
            style={{ minWidth: '120px', maxWidth: '120px' }}
            className="justify-content-end text-standard blackText tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={formatCurrency(auto)}
          />
          <Column
            field="Phone"
            header="Phone"
            body={(pr) => formatCurrency(pr.Phone)}
            style={{ minWidth: '120px', maxWidth: '120px' }}
            className="justify-content-end text-standard blackText tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={formatCurrency(phone)}
          />
          <Column
            field="Insurance"
            header="Insurance"
            body={(pr) => formatCurrency(pr.Insurance)}
            style={{ minWidth: '120px', maxWidth: '120px' }}
            className="justify-content-end text-standard blackText tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={formatCurrency(insurance)}
          />
          <Column
            field="Other"
            header="Other"
            body={(pr) => formatCurrency(pr.Other)}
            style={{ minWidth: '120px', maxWidth: '120px' }}
            className="justify-content-end text-standard blackText tableCell"
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            footer={formatCurrency(other)}
          />
          <Column
            className="tableCell p-0 printHide"
            footerClassName="tableFooter"
          />
        </Table>
      </div>
    );
  }
);

const transactionsAreEqual = (
  prevTransactions: Readonly<EarningsTableProps>,
  nextTransactions: Readonly<EarningsTableProps>
) => {
  return prevTransactions.earnings === nextTransactions.earnings;
};

export default React.memo(EarningsTable, transactionsAreEqual);
