import { reportsPerSection } from '../../../utils/rolesConst';
import { useCookies } from 'react-cookie';
import Navbar from '../../components/navbar/Navbar';

const options = [
  { label: 'Accounting Dashboard', url: '/accounting-dashboard' },
  {
    label: 'Cash Management',
    url: 'cash-management',
    reports: reportsPerSection['cash management'],
  },
  {
    label: 'Accounts Payables',
    url: 'account-payables',
    reports: reportsPerSection['accounts payables'],
  },
  {
    label: 'Accounts Receivables',
    url: 'accounts-receivables',
    reports: reportsPerSection['accounts receivables'],
  },
  {
    label: 'Contract Billings',
    url: 'contract-billings',
    reports: reportsPerSection['contract billings'],
  },
  {
    label: 'Jobs & Contracts',
    url: 'jobs-contracts',
    reports: reportsPerSection['jobs & contracts'],
  },
  {
    label: 'Payroll',
    url: 'payroll',
    reports: reportsPerSection['payroll'],
  },
  {
    label: 'Subcontracts',
    url: 'subcontracts',
    reports: reportsPerSection['subcontracts'],
  },
  {
    label: 'Financial Statements',
    url: 'financialStatement',
    reports: reportsPerSection['financial statements'],
  },
  {
    label: 'New',
    url: 'new',
    style: 'highlightOption p-2 text-white border-round-lg',
    reports: reportsPerSection['new'],
  },
];

const TabsNavBar = () => {
  const cookiesMethods = useCookies(['filters']);

  const onOptionClick = () => {
    const remove = cookiesMethods[2];
    remove('filters', { path: '/accounting-dashboard' });
  };

  return <Navbar options={options} onOptionClick={onOptionClick} />;
};

export default TabsNavBar;
