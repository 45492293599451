import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCompanyContext } from '../../../context/CompanyContext';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { fecthJCCostToDate } from '../../../services/JobsContractsService';
import { useFormContext } from 'react-hook-form';
import { JobContractFilters } from '../JCFilters';
import CostToDateTable from './CostToDateTable';

const CostToDateTableContainer = React.forwardRef<HTMLDivElement>(
  (props, ref) => {
    const { selectedCompany } = useCompanyContext();
    const { watch } = useFormContext<JobContractFilters>();
    const filters = watch();
    const isEnabled = selectedCompany && filters.job;

    const { data, isFetching, isError } = useQuery({
      queryKey: [
        'getJCCostToDate',
        selectedCompany,
        filters.job,
        filters.info,
        filters.endDate,
      ],
      queryFn: ({ signal }) => {
        return fecthJCCostToDate(
          {
            companyID: selectedCompany!.id,
            job: filters.job,
            info: filters.info || undefined,
            endDate: filters.endDate ?? undefined,
          },
          signal
        );
      },
      refetchOnWindowFocus: false,
      enabled: !!isEnabled,
    });

    if (!isEnabled) {
      return (
        <div className="mt-4">
          <ErrorMessage
            severity="info"
            content={'To start the search please select a Job.'}
          />
        </div>
      );
    }

    if (isFetching)
      return (
        <div className="text-center mx-auto mt-3">
          <ProgressSpinner />
        </div>
      );

    if (isError)
      return (
        <ErrorMessage
          content={'Failed to obtain data! Please try again later.'}
        />
      );

    return <CostToDateTable costs={data} />;
  }
);

export default CostToDateTableContainer;
