import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  Title,
  PointElement,
  LineElement,
} from 'chart.js';
import { BacklogMonths } from '../../../Interfaces/Accounting/JobsContracts.interface';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  LineElement
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};

type BacklogProps = {
  months: BacklogMonths;
};

const Backlog = ({ months }: BacklogProps) => {
  const getMonths = () => {
    const list = [];
    for (let i = 11; i >= 0; i--) {
      const date = new Date();
      date.setMonth(date.getMonth() - i);
      list.push(date.toLocaleString('en-US', { month: 'long' }));
    }

    return list;
  };

  const buildBackLogList = () => {
    const list = [];
    for (let i = 12; i >= 0; i--) {
      list.push(months[`Month${i}` as keyof BacklogMonths]);
    }

    return list;
  };

  const data = {
    labels: getMonths(),
    datasets: [
      {
        label: 'Backlog',
        data: buildBackLogList(),
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
    ],
  };

  return <Line options={options} data={data} />;
};

export default Backlog;
