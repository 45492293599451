import { useQuery } from '@tanstack/react-query';
import { formatDate } from '../../../../utils/formatUtils';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { useCompanyContext } from '../../../context/CompanyContext';
import {
  fecthSCJobs,
  fecthSCVendors,
  fetchSCPaymentDrafts,
} from '../../../services/SubcontractsService';
import { SubcontractsFilters } from '../SCFilters';
import {
  getBillItemStatusLabel,
  getSCReportTypeName,
  getSubcontractorName,
  getSubmittedDraft,
} from './SCReportUtils';
import {
  getJobDescription,
  getJobDescriptions,
} from '../../../../utils/reportUtils';

const SCReportHeader = () => {
  const { selectedCompany } = useCompanyContext();
  const filters = useWatch<SubcontractsFilters>();

  const jobsRequest = useQuery({
    queryKey: ['getSubcontractJobs', selectedCompany],
    queryFn: ({ signal }) => fecthSCJobs(selectedCompany!.id, [1], signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  const vendorRequest = useQuery({
    queryKey: ['getSubcontractVendors', selectedCompany],
    queryFn: ({ signal }) =>
      fecthSCVendors({ companyID: selectedCompany!.id }, signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  const submittedSCBillings = useQuery({
    queryKey: [
      'getSubmittedSCBillings',
      selectedCompany,
      filters.job,
      filters.subcontract,
    ],
    queryFn: ({ signal }) =>
      fetchSCPaymentDrafts(
        {
          companyID: selectedCompany!.id,
          job: filters.job ?? '',
          subcontract: filters.subcontract ?? '',
          isSubmitted: true,
          isApproved: false,
        },
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  return (
    <div className="blackText printMedia printShow">
      <div className="text-30px text-center mt-5">{selectedCompany!.name}</div>
      <div className="text-28px text-center">Subcontract Report</div>
      <div className="text-17px ml-3 mt-2">
        <span className="font-bold text-20px">Search criteria:</span>
        <div className="ml-3">
          <div>
            <span className="font-bold mr-1">Report Type:</span>
            {getSCReportTypeName(filters.reportType ?? '')}
          </div>
          {filters.jobs && filters.jobs.length !== 0 && (
            <React.Fragment>
              <div>
                <span className="font-bold mr-1">Jobs:</span>
                {filters.jobs.join(', ')}
              </div>
              <div>
                <span className="font-bold mr-1">Job Names:</span>
                {getJobDescriptions(filters.jobs, jobsRequest.data)}
              </div>
            </React.Fragment>
          )}
          {filters.job && (
            <React.Fragment>
              <div>
                <span className="font-bold mr-1">Job:</span>
                {filters.job}
              </div>
              <div>
                <span className="font-bold mr-1">Job Name:</span>
                {getJobDescription(filters.job, jobsRequest.data)}
              </div>
            </React.Fragment>
          )}
          {filters.subcontractor && filters.reportType === 'paymentSummary' && (
            <div>
              <span className="font-bold mr-1">Subcontractor:</span>
              {getSubcontractorName(filters.subcontractor, vendorRequest.data)}
            </div>
          )}
          {filters.subcontract && filters.reportType !== 'paymentSummary' && (
            <div>
              <span className="font-bold mr-1">Subcontract:</span>
              {filters.subcontract}
            </div>
          )}
          {filters.itemsState && filters.reportType === 'paymentWorksheet' && (
            <div>
              <span className="font-bold mr-1">Items State:</span>
              {getBillItemStatusLabel(filters.itemsState)}
            </div>
          )}
          {filters.submittedID && filters.reportType === 'paymentWorksheet' && (
            <div>
              <span className="font-bold mr-1">Submitted Draft:</span>
              {getSubmittedDraft(filters.submittedID, submittedSCBillings.data)}
            </div>
          )}
          {filters.dates && filters.reportType === 'paymentSummary' && (
            <div>
              <span className="font-bold mr-1">Date range:</span>
              {formatDate(filters.dates[0])}
              {filters.dates[1] && ` - ${formatDate(filters.dates[1])}`}
            </div>
          )}
          {filters.info && (
            <div>
              <span className="font-bold">Search term:</span> {filters.info}
            </div>
          )}
        </div>
      </div>
      <div className="ml-3 text-17px mb-5">
        <span className="font-bold mr-1">Created at:</span>
        {new Date().toLocaleString()}
      </div>
    </div>
  );
};

export default SCReportHeader;
