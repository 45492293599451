type OptionCardProps = {
  children: React.ReactNode;
};

const OptionCard = ({ children }: OptionCardProps) => {
  return (
    <div className="bg-white p-3 border-round-xl border-solid border-2 border-gray-400 w-full h-full">
      {children}
    </div>
  );
};

export default OptionCard;
