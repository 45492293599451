import { DataTableSortOrderType } from 'primereact/datatable';

export const sortByField = (
  aValue: unknown,
  bValue: unknown,
  order: number
) => {
  if (typeof aValue === 'number' && typeof bValue === 'number') {
    return order * (aValue - bValue);
  } else if (typeof aValue === 'string' && typeof bValue === 'string') {
    return order * aValue.localeCompare(bValue);
  } else if (aValue instanceof Date && bValue instanceof Date) {
    return order * (aValue.getTime() - bValue.getTime());
  } else {
    return 0;
  }
};

export const nextSortState = (
  newSortField: string,
  previousSortField: string
) => {
  if (!newSortField) {
    return { field: '', order: 0 as DataTableSortOrderType, reset: true };
  }

  const order: DataTableSortOrderType =
    previousSortField === newSortField ? -1 : 1;

  return { field: newSortField, order, reset: false };
};

export const sortIndexForNumber = (
  valueA: number | null,
  valueB: number | null,
  order: DataTableSortOrderType
) => {
  if (valueA === null && valueB === null) {
    return 0;
  } else if (valueA === null) {
    return 1;
  } else if (valueB === null) {
    return -1;
  } else {
    return (order ?? 0) * (valueA - valueB);
  }
};
