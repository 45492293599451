import axios from 'axios';
import { auth } from '../../firebase';
import { cleanJsonEmptyValues } from '../../utils/formatUtils';
import { Company } from '../Interfaces/User.interfaces';

const apiUrl = process.env['NX_API_URL'];

export type fetchAccountsReceivablesArgs = {
  company: Company;
  period?: string;
  dates?: Date[];
  customers?: number[];
  info?: string;
  contracts?: string[];
};

export const fetchAccountsReceivables = async (
  params: fetchAccountsReceivablesArgs,
  signal?: AbortSignal
) => {
  const { dates, company, ...otherParams } = params;
  let dateStart = null;
  let dateEnd = null;

  if (dates) {
    dateEnd = dates[1] || dates[0];
    dateStart = dates[0];
  }
  const cleanedParams = cleanJsonEmptyValues(otherParams);

  const response = await axios.get(`${apiUrl}/api/accountsReceivables`, {
    signal,
    params: {
      ...cleanedParams,
      companyID: company.id,
      source: company.source,
      dateEnd,
      dateStart,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

export const fetchARContractBillsAndReceipts = async (
  params: fetchAccountsReceivablesArgs,
  signal?: AbortSignal
) => {
  const { dates, company, ...otherParams } = params;
  let dateStart = null;
  let dateEnd = null;

  if (dates) {
    dateEnd = dates[1] ?? dates[0];
    dateStart = dates[0];
  }
  const cleanedParams = cleanJsonEmptyValues(otherParams);

  const response = await axios.get(
    `${apiUrl}/api/accountsReceivables/billsAndReceipts`,
    {
      signal,
      params: {
        ...cleanedParams,
        companyID: company.id,
        source: company.source,
        dateEnd,
        dateStart,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const fetchAccountsReceivablesResume = async (
  company: Company,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/accountsReceivables/agedResume`,
    {
      signal,
      params: { companyID: company.id, source: company.source },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const data = response.data;
  return data;
};

export const fetchAccountsReceivablesReceipts = async (
  params: fetchAccountsReceivablesArgs,
  signal?: AbortSignal
) => {
  const { dates, company, ...otherParams } = params;
  let dateStart = null;
  let dateEnd = null;

  if (dates) {
    dateEnd = dates[1] || dates[0];
    dateStart = dates[0];
  }
  const cleanedParams = cleanJsonEmptyValues(otherParams);

  const response = await axios.get(
    `${apiUrl}/api/accountsReceivables/receipts`,
    {
      signal,
      params: {
        ...cleanedParams,
        companyID: company.id,
        source: company.source,
        dateEnd,
        dateStart,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  const result = response.data;
  return result;
};

export const fetchARCustomerReport = async (
  company: Company,
  info?: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/accountsReceivables/customerReport`,
    {
      signal,
      params: {
        companyID: company.id,
        source: company.source,
        info: info ?? null,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const data = response.data;
  return data;
};

export const fetchARCustomers = async (
  company: Company,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/accountsReceivables/customers`,
    {
      signal,
      params: { companyID: company.id, source: company.source },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const data = response.data;
  return data;
};

export const fetchARContracts = async (
  company: Company,
  reportType?: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/accountsReceivables/contracts`,
    {
      signal,
      params: {
        companyID: company.id,
        source: company.source,
        reportType: reportType ?? null,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const data = response.data;
  return data;
};

export const fetchAIAFormData = async (
  company: Company,
  contract: string,
  invoice: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/accountsReceivables/aiaform`,
    {
      signal,
      params: {
        companyID: company.id,
        invoice,
        contract,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const data = response.data;
  return data;
};

export const fetchInvoiceData = async (
  company: Company,
  contract: string,
  invoice: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/accountsReceivables/invoice`,
    {
      signal,
      params: {
        companyID: company.id,
        invoice,
        contract,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const data = response.data;
  return data;
};

export const fetchARInvoiceType = async (
  company: Company,
  contract: string
) => {
  const response = await axios.get(
    `${apiUrl}/api/accountsReceivables/invoiceType`,
    {
      params: { companyID: company.id, contract },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const data = response.data;
  return data;
};

export const fetchAccountsReceivableTotalBalcnce = async (
  company: Company,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/accountsReceivables/total-balance`,
    {
      signal,
      params: { source: company.source, companyID: company.id },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};
