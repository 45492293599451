import {
  downloadFile,
  downloadFileUsingPath,
} from '../../../../utils/fileUtil';
import { Menu } from 'primereact/menu';
import React, { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { fetchPhaseMasterFile } from '../../../services/ACFormsService';
import { useCompanyContext } from '../../../context/CompanyContext';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { AxiosError } from 'axios';
import { Toast } from 'primereact/toast';
import LoadingButton from '../../../components/inputs/LoadingButton';

const EstimateDownloadTemplate = () => {
  const { selectedCompany } = useCompanyContext();
  const menuRef = useRef<Menu>(null);
  const toast = useRef<Toast>(null);

  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      return fetchPhaseMasterFile(selectedCompany!.id);
    },
    onSuccess: (response) => {
      downloadFile(response);
    },
    onError: (error: AxiosError) => {
      const response = error.response;
      const data = response?.data as { message: string };
      showToast(
        'error',
        toast,
        'Download Phase Master File Error',
        data?.message || 'An error ocurred! please try again later',
        10000
      );
    },
  });

  const items = [
    {
      label: 'Phase Master',
      command: () => {
        mutate();
      },
    },
    {
      label: 'Job Phases',
      command: () => {
        downloadFileUsingPath(
          '/assets/templates/ACForms/Job Phases Template.xlsx'
        );
      },
    },
    {
      label: 'Estimates',
      command: () => {
        downloadFileUsingPath(
          '/assets/templates/ACForms/Estimates Template.xlsx'
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      <LoadingButton
        className="w-18rem buttonSecondary border-0 flex py-3"
        fontSize="text-22px"
        isLoading={isLoading}
        label="Download Templates"
        onClick={(e) => menuRef.current?.toggle(e)}
      />
      <Menu model={items} popup ref={menuRef} />
    </React.Fragment>
  );
};

export default EstimateDownloadTemplate;
