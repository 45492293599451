import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCompanyContext } from '../../../context/CompanyContext';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { fecthJCCostRevenue } from '../../../services/JobsContractsService';
import CostRevenueTable from './CostRevenueTable';
import { useFormContext } from 'react-hook-form';
import { JobContractFilters } from '../JCFilters';

const CostRevenueTableContainer = React.forwardRef<HTMLDivElement>(
  (props, ref) => {
    const { selectedCompany } = useCompanyContext();
    const { watch } = useFormContext<JobContractFilters>();
    const filters = watch();
    const isEnabled = selectedCompany && (filters.startDate || filters.job);

    const { data, isFetching, isError } = useQuery({
      queryKey: ['getJCCostRevenue', selectedCompany, filters],
      queryFn: ({ signal }) => {
        const {
          reportType,
          contractState,
          extraDetails,
          jobState,
          ...otherFilters
        } = filters;

        return fecthJCCostRevenue(
          {
            companyID: selectedCompany!.id,
            jobStatus: jobState !== 'all' ? jobState : undefined,
            ...otherFilters,
          },
          signal
        );
      },
      refetchOnWindowFocus: false,
      enabled: !!isEnabled,
    });

    if (isFetching || !isEnabled)
      return (
        <div className="text-center mx-auto mt-3">
          <ProgressSpinner />
        </div>
      );

    if (isError)
      return (
        <ErrorMessage
          content={'Failed to obtain data! Please try again later.'}
        />
      );

    return <CostRevenueTable jobs={data.Jobs} costTypes={data.CostTypes} />;
  }
);

export default CostRevenueTableContainer;
