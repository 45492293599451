import { useQuery } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import FormControlledCalendar from '../../../../components/inputs/FormInputs/FormControlledCalendar';
import FormControlledDropdown from '../../../../components/inputs/FormInputs/FormControlledDropdown';
import FormControlledInput from '../../../../components/inputs/FormInputs/FormControlledInput';
import FormErrorMessage from '../../../../components/messages/FormErrorMessage';
import { useCompanyContext } from '../../../../context/CompanyContext';
import { APVendor } from '../../../../Interfaces/Accounting/AccountsPayables.interfaces';
import { POPieceWork } from '../../../../Interfaces/Accounting/ACForms.interfaces';
import { fetchPOCheck } from '../../../../services/ACFormsService';
import { fecthJobs } from '../../../../services/JobsContractsService';
import { PO_FORM_FIELDS } from '../../formConsts';
import { fetchVendors } from '../../../../services/vendorsService';

export const PO_TYPES = [
  { label: 'Subcontract', value: 'Subcontractor' },
  { label: 'Material', value: 'Mat Vendor' },
];

export const UPLOAD_TYPES = [
  { label: 'File', value: 'file' },
  { label: 'Manual', value: 'manual' },
];

type GeneralInfoFormProps = {
  checkForm: (index: number) => void;
};

const GeneralInfoForm = ({ checkForm }: GeneralInfoFormProps) => {
  const { selectedCompany } = useCompanyContext();
  const navigate = useNavigate();
  const {
    setValue,
    register,
    unregister,
    setError,
    clearErrors,
    formState: { errors },
    getValues,
  } = useFormContext();
  const [availablePO, setAvailablePO] = useState<boolean | null>(null);
  const multipleYN = useWatch({ name: 'multipleYN' });
  const job = useWatch({ name: 'job' });
  const vendor = useWatch({ name: 'vendor' });
  const description = useWatch({ name: 'description' });
  const identifier = useWatch({ name: 'identifier' });
  const po = useWatch({ name: 'poID' });
  const poDate = useWatch({ name: 'poDate' });
  const poType = useWatch({ name: 'poType' });

  const jobs = useQuery({
    queryKey: ['getPoJobs', selectedCompany],
    queryFn: ({ signal }) =>
      fecthJobs({ companyID: selectedCompany!.id, status: [1] }, signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  const vendors = useQuery({
    queryKey: ['getPoVendors', selectedCompany, poType],
    queryFn: ({ signal }) => {
      return fetchVendors(
        {
          company: selectedCompany!,
          active: true,
          types: [poType],
        },
        signal
      );
    },
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && poType),
  });

  const poCheck = useQuery({
    queryKey: ['getPOCheck', selectedCompany, po],
    queryFn: ({ signal }) => {
      clearErrors(['poID']);
      setAvailablePO(null);
      return fetchPOCheck(selectedCompany!.id, po, signal);
    },
    onSuccess: (data) => {
      setAvailablePO(data);
      if (!data) {
        setError('poID', { type: 'availability', message: 'DUPLICATE PO' });
      }
    },
    onError: () => {
      setAvailablePO(false);
      setError('poID', {
        type: 'availability',
        message: `COULDN'T CHECK AVAILABILITY`,
      });
    },
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && po),
  });

  useEffect(() => {
    let poID = '';
    const vendorData: APVendor = vendors.data?.find((vend: APVendor) => {
      return vend.id === vendor;
    });

    if (job && vendorData && description) {
      poID = `${job.slice(-4)}-${vendorData.sortName.slice(0, 6)}-${
        identifier ? `${identifier}-${description}` : description
      }`;
    }

    setValue('poID', poID);
  }, [
    description,
    identifier,
    multipleYN,
    job,
    vendors.data,
    vendor,
    setValue,
  ]);

  useEffect(() => {
    if (multipleYN === 'Yes') {
      register('identifier', {
        required: {
          value: true,
          message: 'Identifier is a required field',
        },
      });
    } else {
      unregister('identifier');
    }
  }, [multipleYN, unregister, register]);

  useEffect(() => {
    if (poDate) {
      const date = new Date(poDate);
      date.setDate(1);
      setValue('month', date);
    } else {
      setValue('month', null);
    }
  }, [poDate, setValue]);

  useEffect(() => {
    if (!po) {
      clearErrors(['poID']);
      setAvailablePO(null);
    }
  }, [po, clearErrors]);

  useEffect(() => {
    setValue('vendor', null);
  }, [poType, setValue]);

  useEffect(() => {
    const pieceWork = getValues('pieceWork');

    pieceWork?.forEach((piece: POPieceWork, index: number) => {
      setValue(`pieceWork.${index}.costCode`, undefined);
    });
  }, [job, getValues, setValue]);

  useEffect(() => {
    const fields = PO_FORM_FIELDS[0];
    fields.forEach((field) => {
      setValue(field, null);
    });
    setValue('description', '');
    setValue('poID', '');
  }, [selectedCompany, setValue]);

  return (
    <div>
      <div className="grid mx-3 justify-content-center">
        <div className="col-12 sm:col-6">
          <label htmlFor="job" className="text-standard block mb-2">
            Job #
          </label>
          <FormControlledDropdown
            formID="job"
            options={jobs.data}
            labelField="Job"
            valueField="Job"
            placeholder={jobs.isError ? 'Failed to load jobs!' : 'Select Job'}
            isDisabled={jobs.isError}
            isLoading={jobs.isFetching}
            clearable={true}
            filter={true}
            resetFilterOnHide={true}
            rules={{
              required: {
                value: true,
                message: 'Job is a required field',
              },
            }}
          />
        </div>
        <div className="col-12 sm:col-6">
          <label htmlFor="job" className="text-standard block mb-2">
            Project Name
          </label>
          <FormControlledDropdown
            formID="job"
            options={jobs.data}
            labelField="Description"
            valueField="Job"
            placeholder={
              jobs.isError
                ? 'Failed to load project names!'
                : 'Select Project Name'
            }
            isDisabled={jobs.isError}
            isLoading={jobs.isFetching}
            clearable={true}
            filter={true}
            resetFilterOnHide={true}
            rules={{
              required: {
                value: true,
                message: 'Project Name is a required field',
              },
            }}
          />
        </div>
        <div className="col-12 sm:col-6">
          <label htmlFor="poType" className="text-standard block mb-2">
            PO Type
          </label>
          <FormControlledDropdown
            formID="poType"
            labelField="label"
            valueField="value"
            options={PO_TYPES}
            placeholder={'Select type'}
            clearable={true}
            rules={{
              required: {
                value: true,
                message: 'PO Type is a required field',
              },
            }}
          />
        </div>
        <div className="col-12 sm:col-6" />
        <div className="col-12 sm:col-6">
          <label htmlFor="vendor" className="text-standard block mb-2">
            Vendor
          </label>
          <FormControlledDropdown
            formID="vendor"
            options={vendors.data}
            labelField="name"
            valueField="id"
            placeholder={
              vendors.isError ? 'Failed to load vendors!' : 'Select vendor'
            }
            isDisabled={vendors.isError}
            isLoading={vendors.isFetching}
            clearable={true}
            filter={true}
            resetFilterOnHide={true}
            rules={{
              required: {
                value: true,
                message: 'Vendor is a required field',
              },
            }}
          />
        </div>
        <div className="col-12 sm:col-6">
          <label htmlFor="vendor" className="text-standard block mb-2">
            Vendor Short Name
          </label>
          <FormControlledDropdown
            formID="vendor"
            options={vendors.data}
            placeholder={'Vendor short name'}
            labelField="sortName"
            valueField="id"
            clearable={false}
            disabled={true}
          />
        </div>
        <div className="col-12 sm:col-6">
          <label htmlFor="poDate" className="text-standard block mb-2">
            PO Date
          </label>
          <FormControlledCalendar
            formID="poDate"
            rules={{
              required: {
                value: true,
                message: 'PO Date is a required field',
              },
            }}
            readOnlyInput
            showButtonBar
            className="w-full"
            triggerOnChange={true}
          />
        </div>
        <div className="col-12 sm:col-6">
          <label htmlFor="month" className="text-standard block mb-2">
            Batch Month
          </label>
          <FormControlledCalendar
            formID="month"
            readOnlyInput
            showButtonBar
            className="w-full"
            triggerOnChange={true}
            view="month"
            todayButtonClassName="visibility-hidden"
            disabled={true}
          />
        </div>
        <div className="col-12 sm:col-6">
          <label htmlFor="multipleYN" className="text-standard block mb-2">
            Multiple Bldg/Loc
          </label>
          <FormControlledDropdown
            formID="multipleYN"
            options={['Yes', 'No']}
            placeholder={'Select option'}
            clearable={true}
            rules={{
              required: {
                value: true,
                message: 'Multiple Bldg/Loc is a required field',
              },
            }}
          />
        </div>
        <div className="col-12 sm:col-6">
          {multipleYN === 'Yes' && (
            <React.Fragment>
              <label htmlFor="identifier" className="text-standard block mb-2">
                Add'l Identifier
              </label>
              <FormControlledInput
                defaultValue=""
                formID="identifier"
                rules={{
                  required: {
                    value: true,
                    message: 'Identifier is a required field',
                  },
                }}
                autocomplete="off"
              />
            </React.Fragment>
          )}
        </div>
        <div className="col-12 sm:col-6">
          <label htmlFor="description" className="text-standard block mb-2">
            PO Desc
          </label>
          <FormControlledInput
            defaultValue=""
            formID="description"
            rules={{
              required: {
                value: true,
                message: 'PO Desc is a required field',
              },
            }}
            autocomplete="off"
          />
        </div>
        <div className="col-12 sm:col-6">
          <label htmlFor="poID" className="text-standard block mb-2">
            PO#
          </label>
          <div className="p-inputgroup flex-1">
            <span className="p-inputgroup-addon">
              {poCheck.isFetching && (
                <ProgressSpinner className="w-1rem h-auto" />
              )}
              {availablePO && <i className="pi pi-check text-green-500" />}
              {!availablePO && availablePO !== null && (
                <i className="pi pi-times text-red-500" />
              )}
            </span>
            <FormControlledInput
              defaultValue=""
              formID="poID"
              rules={{
                required: {
                  value: true,
                  message: 'PO# is a required field',
                },
                validate: () => {
                  return availablePO ?? 'DUPLICATE PO';
                },
              }}
              autocomplete="off"
              disabled={true}
              hideErrors={true}
            />
          </div>
          {errors['poID'] && (
            <FormErrorMessage errorMessage={errors['poID'].message as string} />
          )}
        </div>
        <div className="col-12 sm:col-6">
          <label htmlFor="upType" className="text-standard block mb-2">
            Upload Type
          </label>
          <FormControlledDropdown
            formID="upType"
            labelField="label"
            valueField="value"
            options={UPLOAD_TYPES}
            defaultValue="file"
            placeholder={'Select type'}
            clearable={false}
          />
        </div>
        <div className="col-12 sm:col-6" />
      </div>
      <div className="flex flex-wrap flex-row-reverse gap-2 mb-3 mt-5">
        <Button
          className="w-15rem bluwaiBlue border-0 flex m-auto py-3"
          type="button"
          onClick={() => checkForm(0)}
          disabled={poCheck.isFetching}
        >
          <span className="mx-auto text-22px text-white">Next</span>
        </Button>
        <Button
          onClick={() => {
            navigate('/accounting-dashboard/new');
          }}
          className="w-15rem bluwaiRed border-0 flex m-auto py-3"
          type="button"
        >
          <span className="mx-auto text-22px text-white">Cancel</span>
        </Button>
      </div>
    </div>
  );
};

export default GeneralInfoForm;
