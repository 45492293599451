import axios from 'axios';
import { auth } from '../../firebase';
import { Company } from '../Interfaces/User.interfaces';
import { consumers } from 'stream';
import { info } from 'console';

const apiUrl = process.env['NX_API_URL'] + '/api';

export type fetchResidentStatusArgs = {
  company: Company;
  customers: string[] | undefined;
  contracts: string[] | undefined;
  period: string | undefined;
  info: string | undefined;
};

export const fetchResidentStatus = async (
  params: fetchResidentStatusArgs,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/resident-status`, {
    signal,
    params: {
      companyID: params.company.id,
      source: params.company.source,
      customers: params.customers,
      constracts: params.contracts,
      period: params.period,
      info: params.info,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const data = response.data;
  return data;
};
