import { useQuery } from '@tanstack/react-query';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import PdfItem from '../../../components/report/PdfItem';
import { useCompanyContext } from '../../../context/CompanyContext';
import { AIAFormItem } from '../../../Interfaces/Accounting/AccountsReceivables.interfaces';
import { fetchInvoiceData } from '../../../services/AccountsReceivablesService';
import ARInvoicePage from './ARInvoicePage';

type ARInvoiceProps = {
  invoice: string;
  contract: string;
  setLoading: (loading: boolean) => void;
};

const ARInvoice = ({ invoice, contract, setLoading }: ARInvoiceProps) => {
  const { selectedCompany } = useCompanyContext();
  const pdfRef = useRef<HTMLDivElement | null>(null);
  const toast = useRef<Toast>(null);
  const [showPdf, setShowPdf] = useState(false);
  const toastBackError = useRef<Toast>(null);
  const { data } = useQuery({
    queryKey: ['getARInvoiceData', selectedCompany, invoice, contract],
    queryFn: ({ signal }) =>
      fetchInvoiceData(selectedCompany!, contract, invoice, signal),
    refetchOnWindowFocus: false,
    onSuccess: () => {
      setShowPdf(true);
    },
    onError: () => {
      showToast(
        'error',
        toastBackError,
        'AR Invoice Error',
        'An error ocurred, please try again later',
        3000
      );
    },
  });

  const item = PdfItem({
    setLoading,
    fileName: 'ARInvoice',
    toast,
    elementToPrintRef: pdfRef,
    pdfStyles: ` body.pdfBody .pdfShow {
        display: block !important;
      }
    
      body.pdfBody .printBackground {
        background-color: white;
      }`,
    widthElementID: 'pdfTest',
    isPdfPortrait: true,
    format: 'Letter',
  });

  const buildInvoice = () => {
    if (!data) {
      return null;
    }

    const company = data?.contractInfo?.company;
    const contract = data?.contractInfo?.contract;
    const items = data?.items;

    let total = 0;
    items?.forEach((item: AIAFormItem) => {
      total = total + item.WC;
    });

    const chunkedItems = [];

    for (let i = 0; i < items.length; i += 24) {
      chunkedItems.push(items.slice(i, i + 24));
    }

    if (!chunkedItems.length) {
      return [
        <ARInvoicePage
          key={contract}
          contract={contract}
          company={company}
          items={[]}
          page={1}
          totalPages={1}
          total={total}
          invoice={invoice}
        />,
      ];
    }

    const pages = chunkedItems.map((itemsList, idx) => (
      <ARInvoicePage
        key={idx}
        contract={contract}
        company={company}
        items={itemsList}
        page={idx + 1}
        totalPages={chunkedItems.length}
        total={total}
        invoice={invoice}
      />
    ));

    return pages;
  };

  useEffect(() => {
    if (showPdf) {
      const command = item.command;
      command();
      setShowPdf(false);
    }
  }, [showPdf, item.command]);

  return (
    <div>
      <ErrorToast toastRef={toastBackError} />
      <div>
        {showPdf && (
          <div
            ref={pdfRef}
            id="pdfTest"
            className="w-fit hidden printBackground pdfShow"
          >
            {buildInvoice()}
          </div>
        )}
      </div>
    </div>
  );
};

export default ARInvoice;
