import { formatCurrency, formatDate } from '../../../../utils/formatUtils';
import { Panel } from 'primereact/panel';
import { useFormContext } from 'react-hook-form';
import FormInfo from '../../../components/messages/FormInfo';
import {
  EmployeeSummary,
  GeneralInfo,
  GeneralInfoValue,
} from '../../../Interfaces/Accounting/ACForms.interfaces';
import { useQuery } from '@tanstack/react-query';
import { fetchContractGeneralInfo } from '../../../services/ACFormsService';
import { useCompanyContext } from '../../../context/CompanyContext';

const GeneralInfoSummary = () => {
  const { selectedCompany } = useCompanyContext();
  const { getValues } = useFormContext<GeneralInfo>();
  const {
    bondCost,
    bondPercentage,
    bondReqd,
    dateOrdered,
    insurance,
    insuranceDateOrdered,
    contractValue,
    supervision,
    targetCost,
    estProfit,
    estProfitPercentage,
    estCostMarkUp,
    jobType,
    certified,
    ccip,
    dwellingUnits,
    metric1,
    metric1Amt,
    metric2,
    metric2Amt,
    heated,
    parking,
    fieldPS,
  } = getValues();

  const { data } = useQuery({
    queryKey: ['getContractGeneralInfo', selectedCompany],
    queryFn: ({ signal }) =>
      fetchContractGeneralInfo(selectedCompany!.id, signal),
    refetchOnWindowFocus: false,
    enabled: false,
    keepPreviousData: true,
  });

  const empData = data?.employees.find(
    (emp: EmployeeSummary) => emp.SortName === fieldPS
  );
  const met1 = data?.metric1.find(
    (met: GeneralInfoValue) => met.DBValue === metric1
  );
  const met2 = data?.metric2.find(
    (met: GeneralInfoValue) => met.DBValue === metric2
  );
  const job = data?.jobs.find(
    (job: GeneralInfoValue) => job.DBValue === jobType
  );

  return (
    <Panel
      header="General Information"
      className="max-w-600px mx-3 text-standard"
    >
      <div className="grid">
        <div className="col-12 sm:col-6">
          <FormInfo
            label="Estimated Bond Cost"
            content={formatCurrency(bondCost)}
          />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Bond Percentage" content={`${bondPercentage}%`} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Bond Reqd" content={bondReqd} />
        </div>
        {dateOrdered && (
          <div className="col-12 sm:col-6">
            <FormInfo label="Date Ordered" content={formatDate(dateOrdered)} />
          </div>
        )}
        <div className="col-12 sm:col-6">
          <FormInfo label="Insurance Cert Reqd" content={insurance} />
        </div>
        {insuranceDateOrdered && (
          <div className="col-12 sm:col-6">
            <FormInfo
              label="Date Ordered"
              content={formatDate(insuranceDateOrdered)}
            />
          </div>
        )}
        <div className="col-12 sm:col-6">
          <FormInfo
            label="Contract Value"
            content={formatCurrency(contractValue)}
          />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo
            label="Supervision & OH (included in job cost)"
            content={formatCurrency(supervision)}
          />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo
            label="Target Job Cost"
            content={formatCurrency(targetCost)}
          />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo
            label="Estimated Profit"
            content={estProfit ? formatCurrency(estProfit) : ''}
          />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo
            label="Estimated Profit %"
            content={`${estProfitPercentage}%`}
          />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo
            label="Estimated Cost Mark Up %"
            content={`${estCostMarkUp}%`}
          />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Job Type" content={job?.Display} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Certified" content={certified} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="CCIP" content={ccip} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Dwelling Units" content={dwellingUnits} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Metric1" content={met1?.Display} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Metric1 Amt" content={metric1Amt} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Metric2" content={met2?.Display} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Metric2 Amt" content={metric2Amt} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Heated Sq Ft Amt" content={heated} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Parking Deck" content={parking} />
        </div>
        {fieldPS && (
          <div className="col-12 sm:col-6">
            <FormInfo label="Field PM/Super" content={empData?.Name ?? fieldPS} />
          </div>
        )}
      </div>
    </Panel>
  );
};

export default GeneralInfoSummary;
