import { formatUTCDate } from '../../../../utils/formatUtils';
import React, { useState } from 'react';
import { ContractBillingResume } from '../../../Interfaces/Accounting/ContractBillings.interface';
import Table from '../../../components/Table/Table';
import { Column } from 'primereact/column';
import CBDrilldownContainer from '../CBDrilldown/CBDrilldownContainer';
import WrapperButton from '../../../components/buttons/WrapperButton';

type CBResumeTableProps = {
  resume: ContractBillingResume[];
  defaultSize?: number;
};

const CBResumeTable = ({ resume, defaultSize }: CBResumeTableProps) => {
  const [showDrilldown, setShowDrilldown] = useState(false);
  const [state, setState] = useState<string>();
  const [period, setPeriod] = useState<string>();

  let active = 0;
  let billed = 0;
  let pending = 0;
  let overdue = 0;
  resume.forEach((resume: ContractBillingResume) => {
    active = active + resume.ActiveJobs;
    billed = billed + resume.Billed;
    pending = pending + resume.Pending;
    overdue = overdue + resume.Overdue;
  });

  const onCellClick = (period: string, status?: string) => {
    setPeriod(period || 'empty');
    setState(status);
    setShowDrilldown(true);
  };

  const hideDrilldown = () => {
    setShowDrilldown(false);
    setPeriod(undefined);
    setState(undefined);
  };

  return (
    <React.Fragment>
      {(period || state) && (
        <CBDrilldownContainer
          visible={showDrilldown}
          hideDrilldown={hideDrilldown}
          period={period ?? ''}
          state={state}
        />
      )}
      <Table
        data={resume}
        className={`mx-3 dashboardOptionShadow dobleHeader w-full`}
        fixedDefaultHeight={defaultSize}
      >
        <Column
          field="ProcessGroup"
          header="Billing Cycle"
          style={{ minWidth: '70px', maxWidth: '70px' }}
          body={(cb) =>
            cb.ProcessGroup ? (
              <WrapperButton
                className="totalColor hover:underline"
                onClick={() => onCellClick(cb.ProcessGroup)}
              >
                {cb.ProcessGroup}
              </WrapperButton>
            ) : (
              cb.ProcessGroup
            )
          }
          headerClassName={`tableHeader font-normal text-center`}
          className={`text-15px blackText tableCell justify-content-center`}
          footerClassName="tableFooter"
        />
        <Column
          field="DueDate"
          header="Due Date"
          style={{ minWidth: '80px' }}
          body={(period) => formatUTCDate(period.DueDate)}
          headerClassName={`tableHeader font-normal text-center`}
          className={`text-15px blackText tableCell justify-content-center`}
          footer={'Total Jobs'}
          footerClassName="tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
        />
        <Column
          field="ActiveJobs"
          header="Active Jobs"
          style={{ minWidth: '70px', maxWidth: '70px' }}
          body={(cb) => (
            <WrapperButton
              className="totalColor hover:underline"
              onClick={() => onCellClick(cb.ProcessGroup)}
            >
              {cb.ActiveJobs}
            </WrapperButton>
          )}
          headerClassName={`tableHeader font-normal text-center`}
          className={`justify-content-center text-15px blackText tableCell `}
          footer={active}
          footerClassName="tableFooter block text-center border-top-2 mt-4"
        />
        <Column
          field="Billed"
          header="Billed"
          style={{ minWidth: '50px', maxWidth: '50px' }}
          headerClassName={`tableHeader font-normal`}
          body={(cb) =>
            cb.Billed ? (
              <WrapperButton
                className="totalColor hover:underline"
                onClick={() => onCellClick(cb.ProcessGroup, 'billed')}
              >
                {cb.Billed}
              </WrapperButton>
            ) : (
              cb.Billed
            )
          }
          className={`justify-content-center text-15px blackText tableCell `}
          footer={billed}
          footerClassName="tableFooter block text-center border-top-2 mt-4"
        />
        <Column
          field="Pending"
          header="Pending"
          style={{ minWidth: '70px', maxWidth: '70px' }}
          body={(cb) =>
            cb.Pending ? (
              <WrapperButton
                className="totalColor hover:underline"
                onClick={() => onCellClick(cb.ProcessGroup, 'pending')}
              >
                {cb.Pending}
              </WrapperButton>
            ) : (
              cb.Pending
            )
          }
          headerClassName={`tableHeader font-normal`}
          className={`justify-content-center text-15px blackText tableCell `}
          footer={pending}
          footerClassName="tableFooter block text-center border-top-2 mt-4"
        />
        <Column
          field="Overdue"
          header="Overdue"
          style={{ minWidth: '70px', maxWidth: '70px' }}
          body={(cb) =>
            cb.Overdue ? (
              <WrapperButton
                className="totalColor hover:underline"
                onClick={() => onCellClick(cb.ProcessGroup, 'overdue')}
              >
                {cb.Overdue}
              </WrapperButton>
            ) : (
              cb.Overdue
            )
          }
          headerClassName={`tableHeader font-normal`}
          className={`justify-content-center text-15px blackText tableCell `}
          footer={overdue}
          footerClassName="tableFooter block text-center border-top-2 mt-4"
        />
      </Table>
    </React.Fragment>
  );
};

export default CBResumeTable;
