import React, { useCallback, useState } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { formatUTCDate, formatCurrency } from '../../../../utils/formatUtils';
import { Payable } from '../../../Interfaces/Accounting/AccountsPayables.interfaces';
import APDrilldown from '../APDrilldown/APDrilldown';
import {
  DataTableRowClickEventParams,
  DataTableRowMouseEventParams,
} from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';

export type APOpenPerJobTableProps = {
  accountsPayables: Payable[];
  drilldownStyles?: boolean;
  showRetainage?: boolean;
  showOpenAmount?: boolean;
};

const APOpenPerJobTable = React.forwardRef<
  HTMLDivElement,
  APOpenPerJobTableProps
>(
  (
    { accountsPayables, drilldownStyles, showRetainage, showOpenAmount },
    ref
  ) => {
    const tableClass = drilldownStyles ? 'mt-5' : 'mx-3 dashboardOptionShadow';
    const columnHeaderClass = drilldownStyles
      ? 'DrilldownHeader'
      : 'tableHeader';
    const [showDrilldown, setShowDrilldown] = useState(false);
    const [selectedPayable, setSelectedPayable] = useState<Payable>();

    const calcHeight = (rows: number) => {
      const headerHeight = 32;
      const footerHeight = 54;
      const rowHeight = 31;
      return headerHeight + footerHeight + rows * rowHeight + 3;
    };

    const onRowHover = (e: DataTableRowMouseEventParams) => {
      const cell = e.originalEvent.target as HTMLElement;
      const row = cell.closest('tr') as HTMLElement;
      animateCellText(row);
    };

    const onRowClick = (e: DataTableRowClickEventParams) => {
      if (e.data.Reference) {
        setSelectedPayable(e.data);
        setShowDrilldown(true);
      }
    };

    const rowClassName = (data: Payable) => {
      let className = data.Reference ? 'cursor-pointer' : '';
      if (!data.Reference) {
        className = `${className} font-bold surface-300`;
      }

      return className;
    };

    let amountTotal = 0;
    let originalTotal = 0;
    let discTotal = 0;
    let retainage = 0;
    accountsPayables.forEach((payable: Payable) => {
      if (payable.Reference) {
        amountTotal = amountTotal + payable.NetAmount;
        originalTotal = originalTotal + payable.Gross;
        discTotal = (payable.DiscountOffered ?? 0) + discTotal;
        retainage += payable.Retainage ?? 0;
      }
    });

    return (
      <div>
        {selectedPayable && (
          <APDrilldown
            visible={showDrilldown}
            setVisible={setShowDrilldown}
            payable={selectedPayable}
          />
        )}
        <Table
          id="open-per-job-table"
          ref={ref}
          data={accountsPayables}
          className={tableClass}
          calcHeight={useCallback(calcHeight, [])}
          onRowClick={useCallback(onRowClick, [])}
          rowClassName={useCallback(rowClassName, [])}
          onRowMouseEnter={useCallback(onRowHover, [])}
          stripedRows={!drilldownStyles}
          hideColumns={true}
        >
          <Column
            headerClassName={columnHeaderClass}
            className="tableCell p-0 printHide"
            footerClassName="tableFooter"
          />
          {!drilldownStyles && (
            <Column
              field="Job"
              header="Job #"
              style={{ minWidth: '150px' }}
              headerClassName={`${columnHeaderClass} font-normal`}
              className="justify-content-center text-standard blackText tableCell"
              footerClassName="tableFooter"
              sortable
            />
          )}
          {!drilldownStyles && (
            <Column
              field="Description"
              header="Job Name"
              body={(ap) => <div className="scroll-text">{ap.Description}</div>}
              style={{ minWidth: '240px' }}
              headerClassName={`${columnHeaderClass} font-normal`}
              className=" text-standard blackText tableCell title overflow-x-hidden white-space-nowrap checkOverflow"
              footerClassName="tableFooter"
            />
          )}
          <Column
            field="Vendor"
            header="Vendor"
            body={(ap) => <div className="scroll-text">{ap.Vendor}</div>}
            headerClassName={`${columnHeaderClass} font-normal justify-content-center`}
            style={{ minWidth: '280px', maxWidth: '280px' }}
            className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="InvoiceDate"
            header="Inv. Date"
            style={{ minWidth: '150px' }}
            body={(ap) => formatUTCDate(ap.InvoiceDate)}
            headerClassName={`${columnHeaderClass} font-normal`}
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="Reference"
            header="Inv. Number"
            style={{ minWidth: '180px' }}
            headerClassName={`${columnHeaderClass} font-normal`}
            className="justify-content-center text-standard blackText tableCell"
            footer="Total"
            footerClassName="tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
            sortable
          />
          <Column
            field="Gross"
            header={'Original Amount'}
            style={{ minWidth: '150px' }}
            body={(ap) => formatCurrency(ap.Gross)}
            headerClassName={`${columnHeaderClass} font-normal justify-content-center text-center white-space-nowrap`}
            className="justify-content-end text-standard blackText tableCell"
            footer={originalTotal.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          {showOpenAmount && (
            <Column
              field="DiscountOffered"
              header="Discount"
              style={{ minWidth: '120px' }}
              body={(ap) => formatCurrency(ap.DiscountOffered)}
              headerClassName={`${columnHeaderClass} font-normal justify-content-center text-center`}
              className="justify-content-end text-standard blackText tableCell"
              footer={discTotal.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
              footerClassName="tableFooter block text-right border-top-2 mt-4"
              sortable
            />
          )}
          {showOpenAmount && (
            <Column
              field="NetAmount"
              header={'Open Amount'}
              style={{ minWidth: '170px' }}
              body={(ap) => formatCurrency(ap.NetAmount)}
              headerClassName={`${columnHeaderClass} font-normal justify-content-center text-center`}
              className="justify-content-end text-standard blackText tableCell"
              footer={amountTotal.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
              footerClassName="tableFooter block text-right border-top-2 mt-4"
              sortable
            />
          )}
          {showRetainage && (
            <Column
              field="Retainage"
              header={'Open Retainage'}
              style={{ minWidth: '180px' }}
              body={(ap) => formatCurrency(ap.Retainage)}
              headerClassName={`${columnHeaderClass} font-normal justify-content-center text-center`}
              className="justify-content-end text-standard blackText tableCell"
              footer={formatCurrency(retainage)}
              footerClassName="tableFooter block text-right border-top-2 mt-4"
              sortable
            />
          )}
          <Column
            headerClassName={columnHeaderClass}
            className="tableCell p-0 printHide"
            footerClassName="tableFooter"
          />
        </Table>
      </div>
    );
  }
);

const transactionsAreEqual = (
  prevTransactions: Readonly<APOpenPerJobTableProps>,
  nextTransactions: Readonly<APOpenPerJobTableProps>
) => {
  return (
    prevTransactions.accountsPayables === nextTransactions.accountsPayables
  );
};

export default React.memo(APOpenPerJobTable, transactionsAreEqual);
