import { formatCurrency } from '../../../../utils/formatUtils';
import { Panel } from 'primereact/panel';
import { useFormContext } from 'react-hook-form';
import FormInfo from '../../../components/messages/FormInfo';
import { COFormFields } from '../../../Interfaces/Accounting/ACForms.interfaces';
import { useQuery } from '@tanstack/react-query';
import { useCompanyContext } from '../../../context/CompanyContext';
import { fetchARCustomers } from '../../../services/AccountsReceivablesService';
import { fecthJobs } from '../../../services/JobsContractsService';
import { ARCustomer } from '../../../Interfaces/Accounting/AccountsReceivables.interfaces';
import { JCJobSummary } from '../../../Interfaces/Accounting/JobsContracts.interface';
import COPhaseSummary from './COPhaseSummary';

const COSummary = () => {
  const { selectedCompany } = useCompanyContext();
  const { getValues } = useFormContext<COFormFields>();
  const { customer, job, changeOrder, description, amount, phases, file } =
    getValues();
  const filteredPhases = phases?.filter((phase) => !!phase.code);

  const customers = useQuery({
    queryKey: ['getCOCustomer', selectedCompany],
    queryFn: ({ signal }) => fetchARCustomers(selectedCompany!, signal),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const jobs = useQuery({
    queryKey: ['getCOJobs', selectedCompany],
    queryFn: ({ signal }) =>
      fecthJobs({ companyID: selectedCompany!.id }, signal),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const custData = customers.data?.find(
    (cust: ARCustomer) => cust.accountNumber === customer
  );
  const jobData = jobs.data?.find(
    (jobData: JCJobSummary) => jobData.Job === job
  );

  return (
    <Panel header="Info" className="max-w-600px mx-3 text-standard">
      <div className="grid">
        <div className="col-12">
          <FormInfo label="Customer" content={custData?.name} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Job #" content={job} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Job Name" content={jobData?.Description} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Approved CO #" content={changeOrder} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="CO Description" content={description} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="CO Amount" content={formatCurrency(amount)} />
        </div>
        <div className="col-12 sm:col-6 printHide" />
        {filteredPhases && filteredPhases?.length > 0 && (
          <div className="col-12">
            <FormInfo label="Phases" content="" />
            {filteredPhases.map((phase) => {
              return <COPhaseSummary phase={phase} key={phase.code} />;
            })}
          </div>
        )}
        <div className="col-12 sm:col-6">
          <FormInfo label="Attach Executed CO" content={file.name} />
        </div>
      </div>
    </Panel>
  );
};

export default COSummary;
