import React from 'react';
import { Calendar, CalendarProps } from 'primereact/calendar';

type CalendarMonthStyledProps = CalendarProps & {
  error?: boolean;
};

const CalendarMonthStyled = React.forwardRef<
  Calendar,
  CalendarMonthStyledProps
>((props: CalendarMonthStyledProps, ref) => {
  const { error, ...otherProps } = props;

  return (
    <Calendar
      panelClassName="radius-33px"
      className="w-12"
      inputClassName={`text-standard blackText fieldBorder ${
        error && 'p-invalid'
      }`}
      ref={ref}
      {...otherProps}
      view="month"
    />
  );
});

export default CalendarMonthStyled;
