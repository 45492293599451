import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import { useFormContext } from 'react-hook-form';
import { fetchAPOpenPerJob } from '../../../services/AccountsPayablesService';
import APOpenPerJobTable from './APOpenPerJobTable';
import { fetchAccountsPayablesFilters } from '../APFilters';
import { processPayableListPerJob } from '../apUtil';

type APopenPerJobTableContainerProps = {
  isDrilldown?: boolean;
  showPerJobRetainage?: boolean;
  showPerJobAmount?: boolean;
};

const APopenPerJobTableContainer = React.forwardRef<
  HTMLDivElement,
  APopenPerJobTableContainerProps
>(({ isDrilldown, showPerJobRetainage, showPerJobAmount }, ref) => {
  const { selectedCompany } = useCompanyContext();
  const { watch } = useFormContext<fetchAccountsPayablesFilters>();
  const filters = watch();
  const enabled = !!selectedCompany;

  const { data, isFetching, isError } = useQuery({
    queryKey: ['getAPOpenPerJob', selectedCompany, filters],
    queryFn: ({ signal }) => {
      const { reportType, ...otherFilters } = filters;

      return fetchAPOpenPerJob(
        { ...otherFilters, company: selectedCompany! },
        signal
      );
    },
    refetchOnWindowFocus: false,
    enabled: enabled,
  });

  if (isFetching || !enabled)
    return (
      <div className="text-center mx-auto mt-3">
        <ProgressSpinner />
      </div>
    );

  if (isError)
    return (
      <ErrorMessage
        content={'Failed to obtain data! Please try again later.'}
      />
    );

  return (
    <APOpenPerJobTable
      accountsPayables={isDrilldown ? data : processPayableListPerJob(data)}
      drilldownStyles={isDrilldown}
      showRetainage={showPerJobRetainage}
      showOpenAmount={showPerJobAmount}
    />
  );
});

export default APopenPerJobTableContainer;
