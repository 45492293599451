import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
} from 'chart.js';
import { AccountResume } from '../../../Interfaces/Accounting/CashManagement.interfaces';
import { Link } from 'react-router-dom';
import { BACKGROUND_COLORS, BORDER_COLORS } from '../../../../utils/graphConst';
import ColorIndicator from '../../../components/messages/ColorIndicator';

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale);

type CashManagementBanksDataProps = {
  resume: AccountResume[];
  total: number;
};

const CashManagementBanksData = ({
  resume,
  total,
}: CashManagementBanksDataProps) => {
  const [labels, setLabels] = useState<string[]>([]);
  const [amounts, setAmounts] = useState<number[]>([]);
  const [accounts, setAccounts] = useState<React.ReactNode>([]);

  const pieData = {
    labels: labels,
    datasets: [
      {
        data: amounts,
        backgroundColor: BACKGROUND_COLORS,
        borderColor: BORDER_COLORS,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const buildAccountRow = (account: AccountResume, position: number) => {
    return (
      <div key={account.name} className="flex justify-content-between">
        <Link
          to={`/accounting-dashboard/cash-management?account=${account.id}`}
          className="flex gap-1 align-items-center"
        >
          <ColorIndicator colorIndex={position} />
          <div className="totalColor cursor-pointer hover:underline">
            {account.name}
          </div>
        </Link>
        <div>
          {account.balance.amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const descList: string[] = [];
    const amountList: number[] = [];

    if (resume) {
      const filteredRows = resume.filter((account) => {
        return account.balance.amount;
      });
      const accountRows = filteredRows.map((account, index) => {
        if (!account.balance.amount) {
          return null;
        }
        descList.push(account.name);
        amountList.push(account.balance.amount);
        return buildAccountRow(account, index);
      });

      setAccounts(accountRows);
    } else {
      setAccounts(undefined);
    }

    setLabels(descList);
    setAmounts(amountList);
  }, [resume]);

  return (
    <React.Fragment>
      <Pie data={pieData} options={options} className="w-9rem h-9rem" />
      <div className="flex-grow-1 pr-4 flex flex-column justify-content-around text-standard">
        {accounts}
        <div className="flex justify-content-between border-top-2 border-top-solid totalBorder pt-1">
          <div>Total Available</div>
          <div>
            {(total || 0).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CashManagementBanksData;
