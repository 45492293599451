import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useFormContext, useWatch } from 'react-hook-form';
import useFiltersCache from '../../hooks/useFiltersCache';
import { PRFilterFields } from '../../Interfaces/Accounting/Payroll.interface';
import { PayrollFilters } from './PRFilters';

const PRParams = () => {
  const [cookies] = useCookies(['filters']);
  const { setValue } = useFormContext<PayrollFilters>();
  const filters = useWatch();
  useFiltersCache({ tab: 'PR', filters });
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    if (!rendered && cookies.filters?.tab === 'PR') {
      setRendered(true);
      const cookieFilters = cookies.filters?.filters;

      for (const i in cookieFilters) {
        setValue(i as PRFilterFields, cookieFilters[i]);
      }
    }
  }, [setValue, rendered, setRendered, cookies.filters]);

  return <span />;
};

export default PRParams;
