import React, { useCallback } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import {
  formatCurrency,
  formatPercentage,
  formatUTCDate,
} from '../../../../utils/formatUtils';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import { ContractBill } from '../../../Interfaces/Accounting/ContractBillings.interface';

type ContractListTableProps = {
  contracts: ContractBill[];
};

const ContractListTable = React.forwardRef<
  HTMLDivElement,
  ContractListTableProps
>(({ contracts }, ref) => {
  const calcHeight = (rows: number) => {
    const headerHeight = 32;
    const footerHeight = 0;
    const rowHeight = 31;
    return headerHeight + footerHeight + rows * rowHeight + 3;
  };

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  return (
    <div className="pdfDivFitContent">
      <Table
        id="cb-contract-list"
        ref={ref}
        data={contracts}
        className={`mx-3 dashboardOptionShadow noFooter`}
        calcHeight={useCallback(calcHeight, [])}
        onRowMouseEnter={useCallback(onRowHover, [])}
        hideColumns={true}
      >
        <Column
          headerClassName={`tableHeader`}
          className="tableCell p-0 printHide"
          footerClassName="tableFooter "
        />
        <Column
          headerClassName={`tableHeader`}
          className="tableCell p-0 printHide"
          footerClassName="tableFooter "
        />
        <Column
          field="ProcessGroup"
          header="Bill Date"
          headerClassName={`tableHeader font-normal`}
          style={{ minWidth: '120px', maxWidth: '120px' }}
          className={`justify-content-center text-standard blackText tableCell`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="Contract"
          header="Contract #"
          style={{ minWidth: '140px', maxWidth: '140px' }}
          headerClassName="tableHeader font-normal"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="ContractName"
          header="Contract Name"
          body={(cb) => {
            return <div className="scroll-text">{cb.ContractName}</div>;
          }}
          style={{ minWidth: '210px', maxWidth: '210px' }}
          headerClassName="tableHeader font-normal justify-content-center"
          className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="CurrContract"
          header="Contract Amount"
          body={(cb) => formatCurrency(cb.CurrContract)}
          style={{ minWidth: '180px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="PrevAmt"
          header="Total Billed"
          body={(cb) => (cb.PrevAmt ? formatCurrency(cb.PrevAmt) : '')}
          style={{ minWidth: '180px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="BilledRatio"
          header="Total Billed %"
          body={(cb) =>
            cb.BilledRatio ? formatPercentage(cb.BilledRatio * 100) : ''
          }
          style={{ minWidth: '180px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="InvTotal"
          header="Current Billing"
          body={(cb) => (cb.InvTotal ? formatCurrency(cb.InvTotal) : '')}
          style={{ minWidth: '180px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="LastBilled"
          header="Last Billed Date"
          body={(cb) => (cb.LastBilled ? formatUTCDate(cb.LastBilled) : null)}
          style={{ minWidth: '180px' }}
          headerClassName="tableHeader font-normal text-center"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          headerClassName={`tableHeader`}
          className="tableCell p-0 printHide"
          footerClassName="tableFooter "
        />
        <Column
          headerClassName={`tableHeader`}
          className="tableCell p-0 printHide"
          footerClassName="tableFooter "
        />
      </Table>
    </div>
  );
});

const transactionsAreEqual = (
  prevTransactions: Readonly<ContractListTableProps>,
  nextTransactions: Readonly<ContractListTableProps>
) => {
  return prevTransactions.contracts === nextTransactions.contracts;
};

export default React.memo(ContractListTable, transactionsAreEqual);
