import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import useFiltersCache from '../../hooks/useFiltersCache';
import { CMFilterFields } from '../../Interfaces/Accounting/CashManagement.interfaces';
import { CashManagementFilterFields } from './CashManagementFilters';

const formatFilters = (filters: CashManagementFilterFields) => {
  return {
    ...filters,
    dates: filters.dates
      ? filters.dates.map((date) => date?.toString() || null)
      : null,
  };
};

const CMParams = () => {
  const [cookies] = useCookies(['filters']);
  const [searchParams, setSearchParams] = useSearchParams();
  const { setValue } = useFormContext<CashManagementFilterFields>();
  const filters = useWatch<CashManagementFilterFields>();
  useFiltersCache({ tab: 'CM', filters: formatFilters(filters) });
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    const account = searchParams.get('account') ?? '';

    if (account) {
      setValue('account', account);
      setSearchParams();
      setRendered(true);
    } else if (!rendered && cookies.filters?.tab === 'CM') {
      setRendered(true);
      const cookieFilters = cookies.filters?.filters;
      if (cookieFilters.dates) {
        const dates = cookieFilters.dates;
        const start = new Date(dates[0]);
        const end = dates[1] ? new Date(dates[1]) : null;
        cookieFilters.dates = [start, end];
      }

      for (const i in cookieFilters) {
        setValue(i as CMFilterFields, cookieFilters[i]);
      }
    }
  }, [
    setValue,
    searchParams,
    setSearchParams,
    rendered,
    setRendered,
    cookies.filters,
  ]);

  return <span />;
};

export default CMParams;
