import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import useFiltersCache from '../../hooks/useFiltersCache';
import { SCFilterFields } from '../../Interfaces/Accounting/Subcontracts.interfaces';
import { SubcontractsFilters } from './SCFilters';
import CryptoJS from 'crypto-js';

const params: { label: SCFilterFields; isEncrypted?: boolean }[] = [
  { label: 'reportType' },
  { label: 'job', isEncrypted: true },
  { label: 'subcontract', isEncrypted: true },
  { label: 'submittedID' },
];

const formatFilters = (filters: SubcontractsFilters) => {
  return {
    ...filters,
    dates: filters.dates
      ? filters.dates.map((date) => date?.toString() || null)
      : null,
  };
};

const SCParams = () => {
  const [cookies] = useCookies(['filters']);
  const [searchParams, setSearchParams] = useSearchParams();
  const { setValue } = useFormContext<SubcontractsFilters>();
  const filters = useWatch() as SubcontractsFilters;
  useFiltersCache({ tab: 'SC', filters: formatFilters(filters) });
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    const secretKey = process.env['NX_CRYPTO_PUBLIC_KEY'] ?? '';
    let hasParam = false;
    params.forEach((param) => {
      let value = searchParams.get(param.label);
      if (value) {
        if (param.isEncrypted) {
          const bytes = CryptoJS.AES.decrypt(value, secretKey);
          value = bytes.toString(CryptoJS.enc.Utf8);
        }

        setValue(param.label, value);
        hasParam = true;
      }
    });

    if (hasParam) {
      setSearchParams();
      setRendered(true);
    } else if (!rendered && cookies.filters?.tab === 'SC') {
      setRendered(true);
      const cookieFilters = cookies.filters?.filters;
      if (cookieFilters.dates) {
        const dates = cookieFilters.dates;
        const start = new Date(dates[0]);
        const end = dates[1] ? new Date(dates[1]) : null;
        cookieFilters.dates = [start, end];
      }

      for (const i in cookieFilters) {
        setValue(i as SCFilterFields, cookieFilters[i]);
      }
    }
  }, [
    setValue,
    searchParams,
    setSearchParams,
    rendered,
    setRendered,
    cookies.filters,
  ]);

  return <span />;
};

export default SCParams;
