import { Toast } from 'primereact/toast';
import React from 'react';
import { showToast } from '../messages/ErrorAlert';
import { autoFitColumns } from '../../../utils/excelUtils';
import * as XLSX from 'xlsx';

type ExcelItemProps = {
  fileName: string;
  elementToPrintRef: React.MutableRefObject<HTMLElement | null>;
  toast: React.RefObject<Toast>;
  tableOriginCell?: string;
  excelPreprocess?: (element: HTMLElement) => HTMLElement;
  excelBeforeDownload?: (
    workBook: XLSX.WorkBook,
    element?: HTMLElement
  ) => void;
};

const ExcelItem = ({
  fileName,
  elementToPrintRef,
  toast,
  excelPreprocess,
  excelBeforeDownload,
  tableOriginCell,
}: ExcelItemProps) => {
  const generateExcel = () => {
    let table = elementToPrintRef.current?.cloneNode(true) as HTMLElement;
    if (excelPreprocess && table) {
      table = excelPreprocess(table);
    }

    const workbook = XLSX.utils.table_to_book(table, {
      origin: tableOriginCell ?? 'A12',
    });
    Object.keys(workbook.Sheets).forEach(function (key) {
      autoFitColumns(workbook.Sheets[key]);
    });

    if (excelBeforeDownload) {
      excelBeforeDownload(workbook, table);
    }

    XLSX.writeFile(workbook, `${fileName}.xlsb`);
  };

  const handleExcel = () => {
    try {
      generateExcel();
    } catch (e) {
      showToast(
        'error',
        toast,
        'Excel Error',
        'An error ocurred while downloading Excel file! Try again later',
        3000
      );
    }
  };

  return {
    label: 'Export Excel',
    icon: 'pi pi-fw pi-file-excel',
    command: () => {
      handleExcel();
    },
  };
};

export default ExcelItem;
