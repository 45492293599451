import React, { useEffect, useState } from 'react';
import { useRolesAccessContext } from '../../../context/RolesAccessContext';
import { FS_File } from '../../../Interfaces/Accounting/FinancialStatements.interface';
import NewFileOption from '../NewFile/NewFileOption';
import File from './File';

type FileListProps = {
  files: FS_File[];
  folderID: string;
  refetchFiles: () => void;
};

const FileList = ({ files, folderID, refetchFiles }: FileListProps) => {
  const { rolesAcess } = useRolesAccessContext();
  const canEdit = rolesAcess?.find(
    (acess) => acess.report === 'financial_statements' && acess.editable
  );
  const [folderList, setFolderList] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const list = files.map((file) => (
      <div key={file.id} className="col flex justify-content-center">
        <File file={file} folderID={folderID} refetchFiles={refetchFiles} />
      </div>
    ));

    for (let i = 0; i < 7; i++) {
      list.push(
        <div key={i} className="col">
          <div className="w-15rem" />
        </div>
      );
    }

    setFolderList(list);
  }, [files, refetchFiles]);

  return (
    <div className="grid">
      {canEdit && (
        <div className="col flex justify-content-center">
          <NewFileOption afterUpload={refetchFiles} folderID={folderID} />
        </div>
      )}
      {folderList}
    </div>
  );
};

export default FileList;
