import { formatUTCDate } from '../../../../utils/formatUtils';
import React from 'react';
import { PayrollPeriodDates } from '../../../Interfaces/Accounting/Payroll.interface';

type PRPeriodProps = {
  periodDates: PayrollPeriodDates;
};

const PRPeriod = React.forwardRef<HTMLDivElement, PRPeriodProps>(
  ({ periodDates }, ref) => {
    const { nextCheck, periodEnd, nextTimesheet } = periodDates;

    return (
      <div className="w-fit md:ml-auto">
        <div className="p-3 text-18px">
          <div className="flex justify-content-between sm:gap-8">
            <div>Time Sheet Due</div>
            <div>{formatUTCDate(nextTimesheet)}</div>
          </div>
          <div className="flex justify-content-between sm:gap-8 mt-4">
            <div>Next Check Date</div>
            <div>{formatUTCDate(nextCheck)}</div>
          </div>
          <div className="flex justify-content-between sm:gap-8 mt-4">
            <div>Period End Date</div>
            <div>{formatUTCDate(periodEnd)}</div>
          </div>
        </div>
      </div>
    );
  }
);

export default PRPeriod;
