import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useCompanyContext } from '../../context/CompanyContext';
import { ARContractResume } from '../../Interfaces/Accounting/AccountsReceivables.interfaces';
import { useQuery } from '@tanstack/react-query';
import { fecthCBContracts } from '../../services/ContractBillingsService';
import { MODULES } from '../../../utils/rolesConst';
import {
  fecthJobs,
  fetchJCJobPhases,
  fetchJCJobProjectManagers,
  fetchSubmittedProjectedCosts,
} from '../../services/JobsContractsService';
import MultipleSelectStyled from '../../components/inputs/StyledInputs/MultipleSelectStyled';
import { formatUTCDate } from '../../../utils/formatUtils';
import { useRolesAccessContext } from '../../context/RolesAccessContext';
import FormControlledInput from '../../components/inputs/FormInputs/FormControlledInput';
import FormControlledDropdown from '../../components/inputs/FormInputs/FormControlledDropdown';
import FormControlledCalendar from '../../components/inputs/FormInputs/FormControlledCalendar';
import { JCProjectedCostDraft } from '../../Interfaces/Accounting/JobsContracts.interface';
import {
  CONTRACT_STATES,
  endDateReports,
  JOB_STATES,
  startDateReport,
  STATES_TO_STATUS,
} from './util/JCUtils';

export const REPORT_OPTIONS = MODULES['jobs & contracts'];

export type JobContractFilters = {
  reportType: string;
  job?: string;
  contract?: string;
  info?: string;
  startDate?: Date;
  endDate?: Date;
  contractState: string;
  jobState: string;
  phases?: string[];
  submittedID?: string;
  pms?: number[];
  contracts?: string[];
  extraDetails?: string;
};

const JCFilters = () => {
  const { rolesAcess } = useRolesAccessContext();
  const canApprove = rolesAcess?.find(
    (acess) => acess.report === 'projected_jc_report' && acess.shouldApprove
  );
  const { control, resetField, watch, setValue } = useFormContext();
  const { selectedCompany } = useCompanyContext();
  const reportType = watch('reportType');
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const job = watch('job');
  const contractState = watch('contractState');
  const jobState = watch('jobState');

  const { data, isFetching, isError } = useQuery({
    queryKey: ['getContracts', selectedCompany],
    queryFn: ({ signal }) => {
      return fecthCBContracts({ companyID: selectedCompany!.id }, signal);
    },
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && reportType === 'itemDetails'),
    staleTime: 1000 * 60 * 10,
  });

  const analysisContracts = useQuery({
    queryKey: ['getAnalysisContracts', selectedCompany, contractState],
    queryFn: ({ signal }) => {
      if (contractState === 'active') {
        return fecthCBContracts(
          { companyID: selectedCompany!.id, status: 1, department: 1 },
          signal
        );
      }
      const promise = Promise.all([
        fecthCBContracts(
          { companyID: selectedCompany!.id, status: 2, department: 1 },
          signal
        ),
        fecthCBContracts(
          { companyID: selectedCompany!.id, status: 3, department: 1 },
          signal
        ),
      ]).then((values) => {
        const result = [...values[0], ...values[1]];
        result.sort((a, b) => {
          const contractA = parseInt(a.Contract.split('-')[1]);
          const contractB = parseInt(b.Contract.split('-')[1]);
          return contractA - contractB;
        });
        return result;
      });

      return promise;
    },
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && reportType === 'contractAnalysis'),
    staleTime: 1000 * 60 * 10,
  });

  const jobs = useQuery({
    queryKey: ['getJCJobs', selectedCompany, jobState],
    queryFn: ({ signal }) =>
      fecthJobs(
        {
          companyID: selectedCompany!.id,
          status: STATES_TO_STATUS[jobState],
        },
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: !!(
      selectedCompany &&
      reportType !== 'contractAnalysis' &&
      reportType !== 'itemDetails' &&
      reportType !== 'phaseMaster'
    ),
    staleTime: 1000 * 60 * 10,
  });

  const phases = useQuery({
    queryKey: ['getJobCostPhases', selectedCompany, job],
    queryFn: ({ signal }) =>
      fetchJCJobPhases(
        { companyID: selectedCompany!.id, job, hasCost: true },
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && job && reportType === 'costDetail'),
    staleTime: 1000 * 60 * 10,
  });

  const submittedCosts = useQuery({
    queryKey: ['getSubmittedProjectedCosts', selectedCompany, job],
    queryFn: ({ signal }) =>
      fetchSubmittedProjectedCosts(selectedCompany!.id, job, signal),
    refetchOnWindowFocus: false,
    enabled: !!(
      selectedCompany &&
      job &&
      canApprove &&
      reportType === 'projectedJC'
    ),
    staleTime: 1000 * 60 * 10,
  });

  const pms = useQuery({
    queryKey: ['getJobPMs', selectedCompany],
    queryFn: ({ signal }) =>
      fetchJCJobProjectManagers(selectedCompany!.id, signal),
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && reportType === 'contractAnalysis'),
    staleTime: 1000 * 60 * 10,
  });

  const onJobChange = () => {
    resetField('submittedID');
    resetField('extraDetails', { defaultValue: 'hide' });
    resetField('phases', { defaultValue: null });
  };

  const onContractStateChange = () => {
    resetField('contracts');
  };

  const onJobStateChange = () => {
    resetField('job');
  };

  useEffect(() => {
    if (!startDate && reportType === 'costRevenue' && !job) {
      const firstDay = new Date(new Date().getFullYear(), 0, 1);
      setValue('startDate', firstDay);
    }
  }, [reportType, job, startDate, setValue]);

  return (
    <>
      {reportType === 'contractAnalysis' && (
        <div className="col">
          <FormControlledDropdown
            formID="contractState"
            defaultValue="active"
            options={CONTRACT_STATES}
            labelField="label"
            valueField="value"
            placeholder="Select contract state"
            clearable={false}
            additionalChange={onContractStateChange}
          />
        </div>
      )}
      {reportType === 'contractAnalysis' && (
        <div className="col">
          <Controller
            control={control}
            name="pms"
            render={({ field: { value, ...otherFields } }) => (
              <MultipleSelectStyled
                isDisabled={pms.isError}
                resetFilterOnHide={true}
                showSelectAll={false}
                filter={true}
                clearable={true}
                options={pms.data}
                labelField="Name"
                valueField="ProjectMgr"
                isLoading={pms.isFetching}
                placeholder={
                  pms.isError
                    ? 'Failed to load project managers!'
                    : 'Select Project Manager'
                }
                value={pms.isFetching ? null : value}
                panelClassName="w-full md:w-auto"
                {...otherFields}
              />
            )}
          />
        </div>
      )}
      {reportType === 'contractAnalysis' && (
        <React.Fragment>
          <div className="col">
            <Controller
              control={control}
              name="contracts"
              render={({ field: { value, ...otherFields } }) => (
                <MultipleSelectStyled
                  isDisabled={analysisContracts.isError}
                  resetFilterOnHide={true}
                  showSelectAll={false}
                  filter={true}
                  clearable={true}
                  options={analysisContracts.data}
                  labelField="Contract"
                  valueField="Contract"
                  isLoading={analysisContracts.isFetching}
                  placeholder={
                    analysisContracts.isError
                      ? 'Failed to load contracts!'
                      : 'Select Contract'
                  }
                  value={analysisContracts.isFetching ? null : value}
                  panelClassName="w-full md:w-auto"
                  {...otherFields}
                />
              )}
            />
          </div>
          <div className="col">
            <Controller
              control={control}
              name="contracts"
              render={({ field: { value, ...otherFields } }) => (
                <MultipleSelectStyled
                  isDisabled={analysisContracts.isError}
                  resetFilterOnHide={true}
                  showSelectAll={false}
                  filter={true}
                  clearable={true}
                  options={analysisContracts.data}
                  labelField="Description"
                  valueField="Contract"
                  isLoading={analysisContracts.isFetching}
                  placeholder={
                    analysisContracts.isError
                      ? 'Failed to load contract names!'
                      : 'Select Contract Name'
                  }
                  value={analysisContracts.isFetching ? null : value}
                  panelClassName="w-full md:w-auto"
                  {...otherFields}
                />
              )}
            />
          </div>
        </React.Fragment>
      )}
      {reportType === 'itemDetails' && (
        <React.Fragment>
          <div className="col">
            <FormControlledDropdown
              formID="contract"
              options={data}
              labelField="Contract"
              valueField="Contract"
              placeholder={
                isError ? 'Failed to load contracts!' : 'Select Contract'
              }
              clearable={true}
              isDisabled={isError}
              isLoading={isFetching}
              filter={true}
              resetFilterOnHide={true}
            />
          </div>
          <div className="col">
            <FormControlledDropdown
              formID="contract"
              options={data}
              labelField="Description"
              valueField="Contract"
              placeholder={
                isError
                  ? 'Failed to load contract names!'
                  : 'Select Contract Name'
              }
              clearable={true}
              isDisabled={isError}
              isLoading={isFetching}
              filter={true}
              resetFilterOnHide={true}
            />
          </div>
        </React.Fragment>
      )}
      {reportType !== 'contractAnalysis' &&
        reportType !== 'itemDetails' &&
        reportType !== 'phaseMaster' && (
          <div className="col">
            <FormControlledDropdown
              formID="jobState"
              defaultValue={'active'}
              options={
                reportType === 'costRevenue' ? JOB_STATES : JOB_STATES.slice(1)
              }
              labelField="label"
              valueField="value"
              placeholder="Select job state"
              clearable={false}
              additionalChange={onJobStateChange}
            />
          </div>
        )}
      {reportType !== 'contractAnalysis' &&
        reportType !== 'itemDetails' &&
        reportType !== 'phaseMaster' && (
          <React.Fragment>
            <div className="col">
              <FormControlledDropdown
                formID="job"
                options={jobs.data}
                labelField="Job"
                valueField="Job"
                placeholder={
                  jobs.isError ? 'Failed to load jobs!' : 'Select Job'
                }
                clearable={true}
                isDisabled={jobs.isError}
                isLoading={jobs.isFetching}
                filter={true}
                resetFilterOnHide={true}
                additionalChange={onJobChange}
              />
            </div>
            <div className="col">
              <FormControlledDropdown
                formID="job"
                options={jobs.data}
                labelField="Description"
                valueField="Job"
                placeholder={
                  jobs.isError ? 'Failed to load job names!' : 'Select Job Name'
                }
                clearable={true}
                isDisabled={jobs.isError}
                isLoading={jobs.isFetching}
                filter={true}
                resetFilterOnHide={true}
                additionalChange={onJobChange}
              />
            </div>
          </React.Fragment>
        )}
      {reportType === 'costDetail' && (
        <div className="col">
          <Controller
            control={control}
            name="phases"
            render={({ field: { value, ...otherFields } }) => (
              <MultipleSelectStyled
                isDisabled={phases.isError || !job}
                resetFilterOnHide={true}
                showSelectAll={false}
                filter={true}
                options={phases.data}
                labelField="Label"
                valueField="Phase"
                isLoading={phases.isFetching}
                placeholder={
                  phases.isError ? 'Failed to load phases!' : 'Select Phase'
                }
                value={phases.isFetching ? null : value}
                panelClassName="w-full md:w-auto"
                {...otherFields}
              />
            )}
          />
        </div>
      )}
      {startDateReport.includes(reportType) && (
        <div className="col">
          <FormControlledCalendar
            formID="startDate"
            readOnlyInput
            showButtonBar
            maxDate={endDate}
            placeholder="Select Start Date"
          />
        </div>
      )}
      {endDateReports.includes(reportType) && (
        <div className="col">
          <FormControlledCalendar
            formID="endDate"
            readOnlyInput
            showButtonBar
            minDate={startDate}
            placeholder="Select End Date"
          />
        </div>
      )}
      {reportType !== 'contractAnalysis' && reportType !== 'projectedJC' && (
        <div className="col">
          <span className="p-input-icon-right">
            <FormControlledInput
              defaultValue=""
              formID="info"
              placeholder="Search"
              className="fieldBorder w-full text-standard blackText"
            />
            <i className="pi pi-search" />
          </span>
        </div>
      )}
      {reportType === 'projectedJC' && (
        <div className="col">
          <FormControlledDropdown
            formID="extraDetails"
            defaultValue="hide"
            options={[
              { label: 'Hide Previous Projections', value: 'hide' },
              { label: 'Show Previous Projections', value: 'show' },
            ]}
            labelField="label"
            valueField="value"
            clearable={false}
            disabled={!job}
          />
        </div>
      )}
      {reportType === 'projectedJC' && canApprove && (
        <div className="col">
          <FormControlledDropdown
            formID="submittedID"
            options={
              submittedCosts.data?.map((draft: JCProjectedCostDraft) => {
                return {
                  ...draft,
                  label: `${formatUTCDate(draft.updatedAt ?? '')} - ${
                    draft.userName
                  }`,
                };
              }) || []
            }
            labelField="label"
            valueField="id"
            placeholder={
              submittedCosts.isError
                ? 'Failed to load drafts!'
                : 'Select Cost Draft'
            }
            isDisabled={submittedCosts.isError || !submittedCosts.data?.length}
            isLoading={submittedCosts.isFetching}
            clearable={true}
            filter={true}
            resetFilterOnHide={true}
          />
        </div>
      )}
    </>
  );
};

export default JCFilters;
