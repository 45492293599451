import { useEffect, useState } from 'react';
import { useRolesAccessContext } from '../context/RolesAccessContext';
import { Access, ReportItem } from '../Interfaces/Role.interfaces'; 

type useProtectedReportOptionsArgs = {
  reports: ReportItem[];
};

const useProtectedReportOptions = ({
  reports,
}: useProtectedReportOptionsArgs) => {
  const { rolesAcess, isAdmin } = useRolesAccessContext();
  const [protectedReports, setProtectedReports] = useState<ReportItem[]>([]);

  const checkReportPermissions = (
    reports: ReportItem[],
    rolesAcess: Access[],
    isAdmin?: boolean
  ) => {
    const result = reports.map((report) => {
      const reportName = report.label.replace(/ /g, '_').toLocaleLowerCase();
      const match = rolesAcess?.find((access) => reportName === access.report);
      const hasAdminAccess = isAdmin && !report.blockAdmin;

      return { ...report, disabled: !(match ?? hasAdminAccess) };
    });
    return result;
  };

  useEffect(() => {
    const list = checkReportPermissions(reports, rolesAcess ?? [], isAdmin);
    setProtectedReports(list);
  }, [rolesAcess, isAdmin, reports]);

  return [protectedReports];
};

export default useProtectedReportOptions;
