import React, { useRef } from 'react';
import { Menu } from 'primereact/menu';
import UserIcon from '../../../assets/layout/images/icons/Icon awesome-user-alt.svg';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../../firebase';
import { useRolesAccessContext } from '../../context/RolesAccessContext';
import { useCookies } from 'react-cookie';
import WrapperButton from '../../components/buttons/WrapperButton';

const ProfileIcon = () => {
  const cookiesObjectsList = useCookies(['company']);
  const { isAdmin } = useRolesAccessContext();
  const navigate = useNavigate();
  const menuRef = useRef<Menu>(null);

  const logOut = () => {
    signOut(auth);
    const removeCookie = cookiesObjectsList[2];
    removeCookie('company');
    navigate('/login');
  };

  const admin = () => {
    navigate('/admin');
  };

  const items = [
    {
      label: 'Log out',
      command: () => {
        logOut();
      },
    },
  ];

  if (isAdmin) {
    items.unshift({
      label: 'Admin menu',
      command: () => {
        admin();
      },
    });
  }

  return (
    <React.Fragment>
      <WrapperButton onClick={(e) => menuRef.current?.toggle(e)}>
        <img
          src={UserIcon}
          alt="user icon"
          className="m-2 h-2rem w-3rem lg:h-3rem w-fit"
        />
      </WrapperButton>
      <Menu ref={menuRef} model={items} popup />
    </React.Fragment>
  );
};

export default ProfileIcon;
