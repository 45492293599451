import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import RejectionField from '../../../components/dialog/RejectionField';
import LoadingButton from '../../../components/inputs/LoadingButton';
import ConfirmationDialog from '../../../components/messages/ConfirmationDialog';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { useCompanyContext } from '../../../context/CompanyContext';
import {
  EmployeeTimesheet,
  PayrollTimesheetDetail,
  PayrollTimesheetDetailArgs,
  TimeSheetArgs,
  TimeSheetDetailsArgs,
} from '../../../Interfaces/Accounting/ACForms.interfaces';
import { Access } from '../../../Interfaces/Role.interfaces';
import {
  postTimesheetDraft,
  putTimesheetApproveDraft,
  putTimesheetDraft,
  putTimesheetRejectDraft,
  putTimesheetSubmitDraft,
} from '../../../services/PayrollService';

type TimesheetProcessProps = {
  approvalMode: boolean;
  draft: EmployeeTimesheet[];
  periodStart: Date;
  periodEnd: Date;
  defaultDraftID?: string;
  isSubmitted?: boolean;
  blockAll?: boolean;
  access: Access;
};

const TimesheetProcess = ({
  defaultDraftID,
  approvalMode,
  isSubmitted,
  draft,
  periodStart,
  periodEnd,
  blockAll,
  access,
}: TimesheetProcessProps) => {
  const { selectedCompany } = useCompanyContext();
  const toast = useRef<Toast>(null);
  const [draftID, setDraftID] = useState<string>(defaultDraftID ?? '');
  const [blockSave, setBlockSave] = useState(true);
  const [blockSubmit, setBlockSubmit] = useState(
    isSubmitted ?? !defaultDraftID
  );
  const [blockApproval, setBlockApproval] = useState(
    approvalMode ? false : !defaultDraftID
  );
  const [hasBeenApproved, setHasBeenApproved] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const prevDraft = useRef(draft);

  const save = useMutation({
    mutationFn: ({
      draftID,
      draft,
    }: {
      draftID: string;
      draft: TimeSheetArgs;
    }) => {
      if (draftID) {
        return putTimesheetDraft(
          draftID,
          draft,
          periodStart,
          periodEnd,
          !!approvalMode
        );
      }

      return postTimesheetDraft(
        selectedCompany!.id,
        draft,
        periodStart,
        periodEnd
      );
    },
    onSuccess: (data) => {
      showToast(
        'success',
        toast,
        'Save Payroll Timesheet',
        'The data was saved successfully!',
        3000
      );

      setBlockSave(true);
      setBlockSubmit(false);
      setBlockApproval(false);

      if (!draftID) {
        setDraftID(data);
      }
    },
    onError: () => {
      showToast(
        'error',
        toast,
        'Save Payroll Timesheet',
        "The data couldn't be saved",
        3000
      );
    },
  });

  const saveDraft = (draftID: string, draft: EmployeeTimesheet[]) => {
    const processedDraft = draft.map((emp) => {
      const {
        firstName,
        lastName,
        useDetails,
        details,
        totalHrs,
        empID,
        ...otherFields
      } = emp;

      if (useDetails) {
        const empNewDetails: TimeSheetDetailsArgs[] = [];
        details?.forEach((det) => {
          const { useDateDetails, dateDetails, id, totalHrs, ...otherFields } =
            det;

          if (useDateDetails) {
            const empNewDateDetails: PayrollTimesheetDetailArgs[] = [];
            dateDetails?.forEach((dateDet) => {
              const { totalHrs, id, ...otherFields } = dateDet;
              const keysAmount = Object.keys(otherFields);

              if (keysAmount.length > 0) {
                empNewDateDetails.push(otherFields);
              }
            });

            empNewDetails.push({
              date: otherFields.date,
              dateDetails: empNewDateDetails,
            });
          } else {
            const keysAmount = Object.keys(otherFields);

            if (keysAmount.length > 0) {
              empNewDetails.push({
                ...otherFields,
              });
            }
          }
        });

        return { empID: `${empID}`, details: empNewDetails };
      } else {
        return { empID: `${empID}`, ...otherFields };
      }
    });

    save.mutate({ draftID, draft: processedDraft });
  };

  const checkDraft = (draft: EmployeeTimesheet[]) => {
    let missingHours = false;

    const checkMissingHours = (detail: PayrollTimesheetDetail) => {
      const { totalHrs } = detail;
      missingHours = missingHours || totalHrs === undefined;
    };

    draft.forEach((emp) => {
      const { useDetails, details } = emp;

      if (useDetails) {
        details?.forEach((det) => {
          const detKeys = Object.keys(det);
          const { useDateDetails, dateDetails } = det;

          if (useDateDetails) {
            dateDetails?.forEach((dateDet) => {
              const dateDetkeys = Object.keys(dateDet);

              if (dateDetkeys.length > 1) {
                checkMissingHours(dateDet);
              }
            });
          } else if (detKeys.length > 2) {
            checkMissingHours(det);
          }
        });
      } else {
        checkMissingHours(emp);
      }
    });

    return missingHours;
  };

  const generateConfirmationMessage = (
    defaultText: string,
    draft: EmployeeTimesheet[]
  ) => {
    let initial = defaultText;
    const missingHours = checkDraft(draft);

    if (!missingHours) {
      return initial;
    }

    initial = `${initial}\n\n The timesheet is missing hours for active employees.`;

    return initial;
  };

  const submit = useMutation({
    mutationFn: (draftID: string) => {
      return putTimesheetSubmitDraft(draftID);
    },
    onSuccess: () => {
      setBlockSubmit(true);

      showToast(
        'success',
        toast,
        'Submit Payroll Timesheet',
        'The data was submitted successfully!',
        3000
      );
    },
    onError: () => {
      showToast(
        'error',
        toast,
        'Submit Payroll Timesheet',
        "The data couldn't be submitted",
        3000
      );
    },
  });

  const approveRequest = useMutation({
    mutationFn: (draftID: string) => {
      return putTimesheetApproveDraft(draftID);
    },
    onSuccess: () => {
      const label = approvalMode ? 'Approve' : 'Upload';
      const mesLabel = approvalMode ? 'approved' : 'uploaded';

      showToast(
        'success',
        toast,
        `${label} Payroll Timesheet`,
        `The draft was ${mesLabel} successfully!`,
        3000
      );

      setBlockApproval(true);
      setHasBeenApproved(true);
    },
    onError: (error: AxiosError) => {
      const label = approvalMode ? 'Approve' : 'Upload';
      const mesLabel = approvalMode ? 'approved' : 'uploaded';

      const errorData = error.response?.data as {
        code: string;
        message: string;
      };
      showToast(
        'error',
        toast,
        `${label} Payroll Timesheet`,
        errorData?.message || `The draft couldn't be ${mesLabel}`,
        3000
      );
    },
  });

  const rejectRequest = useMutation({
    mutationFn: (props: { draftID: string; reason: string }) => {
      return putTimesheetRejectDraft(props.draftID, props.reason);
    },
    onSuccess: () => {
      showToast(
        'success',
        toast,
        `Reject Payroll Timesheet`,
        `The draft was rejected successfully!`,
        3000
      );

      setBlockApproval(true);
      setHasBeenApproved(true);
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as {
        code: string;
        message: string;
      };

      showToast(
        'error',
        toast,
        `Reject Payroll Timesheet`,
        errorData?.message || `The draft couldn't be rejected`,
        3000
      );
    },
  });

  useEffect(() => {
    if (prevDraft.current !== draft && !hasBeenApproved) {
      setBlockSave(false);
      setBlockSubmit(true);
      setBlockApproval(true);
      prevDraft.current = draft;
    }
  }, [draft, hasBeenApproved]);

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      <LoadingButton
        label="Save"
        fontSize="text-xl"
        bgColor="buttonSecondary"
        isLoading={save.isLoading}
        onClick={() => saveDraft(draftID, draft)}
        disabled={blockAll || blockSave || save.isLoading}
      />
      {!approvalMode && access.editable === 'approval' && (
        <ConfirmationDialog
          Button={
            <LoadingButton
              label="Submit"
              fontSize="text-xl"
              isLoading={submit.isLoading}
              disabled={blockAll || blockSubmit || submit.isLoading}
              type="button"
            />
          }
          onConfirm={() => submit.mutate(draftID)}
          message={generateConfirmationMessage(
            `Are you sure you want to submit this draft?`,
            draft
          )}
        />
      )}
      {approvalMode && access.shouldApprove && (
        <ConfirmationDialog
          tagKey="draft-rejection"
          visibility={visibility}
          Button={
            <LoadingButton
              label={'Reject'}
              fontSize="text-xl"
              bgColor="bluwaiRed"
              isLoading={rejectRequest.isLoading}
              disabled={blockAll || blockApproval || rejectRequest.isLoading}
              type="button"
              onClick={() => setVisibility(true)}
            />
          }
          contentClassName="pb-0"
          onConfirm={() => {}}
          acceptClassName="hidden"
          rejectClassName="hidden"
          onHide={() => setVisibility(false)}
          message={
            <RejectionField
              onReject={() => {
                setVisibility(false);
              }}
              onConfirm={(reason) => {
                rejectRequest.mutate({ draftID, reason });
                setVisibility(false);
              }}
            />
          }
        />
      )}
      {(approvalMode
        ? access.shouldApprove
        : access.editable === 'no-approval') && (
        <ConfirmationDialog
          tagKey="draft-approval"
          Button={
            <LoadingButton
              label={approvalMode ? 'Approve' : 'Upload'}
              fontSize="text-xl"
              bgColor="bg-green-500"
              isLoading={approveRequest.isLoading}
              disabled={blockAll || blockApproval || approveRequest.isLoading}
              type="button"
            />
          }
          onConfirm={() => approveRequest.mutate(draftID)}
          message={generateConfirmationMessage(
            `Are you sure you want to ${
              approvalMode ? 'approve' : 'upload'
            } this draft?`,
            draft
          )}
        />
      )}
    </React.Fragment>
  );
};

export default TimesheetProcess;
