import axios from 'axios';
import { cleanJsonEmptyValues } from '../../utils/formatUtils';
import { auth } from '../../firebase';
import { Company } from '../Interfaces/User.interfaces';

const apiUrl = process.env['NX_API_URL'] + '/api/vendors/payments';

export type fetchAccountsPayablesVendorPaymentsArgs = {
  company: Company;
  info?: string;
  dates?: Date[];
  vendors?: string[];
};

export const fetchAccountsPayablesVendorPayments = async (
  params: fetchAccountsPayablesVendorPaymentsArgs,
  signal?: AbortSignal
) => {
  const { dates, company, ...otherParams } = params;
  let dateStart = null;
  let dateEnd = null;

  if (dates) {
    dateEnd = dates[1] || dates[0];
    dateStart = dates[0];
  }
  const cleanedParams = cleanJsonEmptyValues(otherParams);

  const response = await axios.get(`${apiUrl}/history`, {
    signal,
    params: {
      ...cleanedParams,
      companyID: company.id,
      source: company.source,
      dateEnd,
      dateStart,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const data = response.data;
  return data;
};
