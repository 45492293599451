import { Controller, useFormContext } from 'react-hook-form';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { SubFileForm } from '../../../Interfaces/Accounting/ACForms.interfaces';
import LoadingButton from '../../../components/inputs/LoadingButton';
import FormErrorMessage from '../../../components/messages/FormErrorMessage';
import {
  EXCEL_FORMATS,
  transformBToMB,
} from 'apps/tmr-frontend/src/utils/fileUtil';
import SingleFileInput from '../../../components/inputs/StyledInputs/SingleFileInput';
import SLFileTemplate from './SLFileTemplate';
import ConfirmationDialog from '../../../components/messages/ConfirmationDialog';
import { FILE_SIZE_LIMIT } from 'apps/tmr-frontend/src/utils/fileConsts';

type SLFileProps = {
  returnStep: () => void;
  isLoading: boolean;
  onSubmit: () => void;
};

const SLFile = ({ returnStep, isLoading, onSubmit }: SLFileProps) => {
  const navigate = useNavigate();
  const {
    control,
    formState: { errors },
  } = useFormContext<SubFileForm>();

  return (
    <div>
      <div className="mb-3">
        <SLFileTemplate />
      </div>
      <Controller
        name="subFile"
        aria-label="file-input"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Items file is a required field',
          },
        }}
        render={({ field: { onChange, value } }) => (
          <div>
            <SingleFileInput
              value={value}
              onChange={onChange}
              acceptedFormats={EXCEL_FORMATS}
              totalLimit={FILE_SIZE_LIMIT / 2}
              totalLimitLabel={`${transformBToMB(FILE_SIZE_LIMIT / 2)} MB`}
              emptyMessage={`Upload Items file. Allowed extensions: ${EXCEL_FORMATS.join(
                ', '
              )}.`}
              contentClassName={errors['subFile'] && 'border-red-500'}
            />
            {errors['subFile'] && (
              <FormErrorMessage
                errorMessage={errors['subFile'].message as string}
              />
            )}
          </div>
        )}
      />
      <div className="flex flex-wrap flex-row-reverse gap-2 mb-3 mt-5">
        <ConfirmationDialog
          Button={
            <LoadingButton
              isLoading={isLoading}
              label="Create SL"
              className="w-15rem bluwaiBlue border-0 flex m-auto py-3"
              fontSize="text-22px"
              type="button"
            />
          }
          onConfirm={onSubmit}
          message="Are you sure you want to submit this subcontract?"
        />
        <Button
          onClick={() => {
            returnStep();
          }}
          className="w-15rem buttonSecondary border-0 flex m-auto py-3"
          type="button"
        >
          <span className="mx-auto text-22px text-white">Previous</span>
        </Button>
        <Button
          onClick={() => {
            navigate('/accounting-dashboard/new');
          }}
          className="w-15rem bluwaiRed border-0 flex m-auto py-3"
          type="button"
        >
          <span className="mx-auto text-22px text-white">Cancel</span>
        </Button>
      </div>
    </div>
  );
};

export default SLFile;
