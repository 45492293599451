import React from 'react';
import { formatUTCDate } from '../../../../utils/formatUtils';
import { AIAContractInfo } from '../../../Interfaces/Accounting/AccountsReceivables.interfaces';
import { Company } from '../../../Interfaces/User.interfaces';
import ARInvoiceCompanyLogo from './ARInvoiceCompanyLogo';

type ARInvoiceHeaderProp = {
  contract: AIAContractInfo;
  company: Company;
  invoice: string;
};

const ARInvoiceHeader = ({
  contract,
  company,
  invoice,
}: ARInvoiceHeaderProp) => {
  return (
    <React.Fragment>
      <div className="grid grid-nogutter pt-8 ml-2 mr-7 pr-2">
        <div className="flex-grow-1">
          <div className="flex font-bold">
            <div>
              <div className="w-13rem h-auto ml-6">
                <ARInvoiceCompanyLogo />
              </div>
              <div
                style={{ width: '350px' }}
                className="flex gap-3 text-20px mt-7 pl-2"
              >
                <div className="w-3rem">To :</div>
                <div>
                  <div>{contract?.Name}</div>
                  <div>{contract?.Address}</div>
                  <div>
                    {contract?.City}, {contract?.State} {contract?.Zip}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center text-25px ml-2 pl-2">
              <div>{company.name}</div>
              <div>{company.address}</div>
              <div>{company.address2}</div>
              <div>
                {company.city}, {company.state} {company.zip}
              </div>
              <div>{company.phone}</div>
            </div>
            <div className="text-20px mt-auto mb-4 flex gap-1 -ml-5">
              <div className="text-right mt-auto">
                <div>Invoice #:</div>
                <div>Date:</div>
              </div>
              <div className="text-right pr-3">
                <div>{invoice}</div>
                <div>{formatUTCDate(contract?.AppDate, 'MM/DD/YY')}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-2 w-12-half">
          <div className="font-bold text-right text-25px">INVOICE</div>
        </div>
      </div>
      <div className="grid grid-nogutter ml-2 mr-7 pr-2 mt-6">
        <div className="flex-grow-1 pl-3">
          <div className="flex justify-content-between font-bold">
            <div className="flex gap-3 text-20px align-items-end">
              <div>Contract :</div>
              <div className="font-normal">
                {contract?.Contract} {contract?.ContractName}
              </div>
            </div>
            <div className="text-20px flex align-items-end gap-2 mr-2 pr-6">
              <div className="text-right">
                <div>Invoice Due Date:</div>
                <div>Payment Terms:</div>
              </div>
              <div>
                <div className={`${!contract.DueDate && 'visibility-hidden'}`}>
                  {formatUTCDate(contract?.DueDate, 'MM/DD/YY') ??
                    'No due date'}
                </div>
                <div className={`${!contract.PayTerms && 'visibility-hidden'}`}>
                  {contract?.PayTerms ?? 'No payterm'}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-2 w-12-half"></div>
      </div>
    </React.Fragment>
  );
};

export default ARInvoiceHeader;
