import React, { useEffect } from 'react';
import DropdownStyled from '../../components/inputs/StyledInputs/DropdownStyled';
import { Controller, useFormContext } from 'react-hook-form';
import { useCompanyContext } from '../../context/CompanyContext';
import { MODULES } from '../../../utils/rolesConst';

export const REPORT_OPTIONS = MODULES['payroll'];

export const EMPLOYEE_STATUS = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

export const PERIOD_OPTIONS = [
  { label: 'Pay Period', value: 'pay' },
  { label: 'Annual', value: 'annual' },
];

export type PayrollFilters = {
  reportType: string;
  employeeStatus: string;
  period: string;
};

const PRFilters = () => {
  const { selectedCompany } = useCompanyContext();
  const { control, watch } = useFormContext();
  const reportType = watch('reportType');

  return (
    <>
      {reportType === 'earnings' && (
        <div className="col">
          <Controller
            control={control}
            name="employeeStatus"
            render={({ field }) => (
              <DropdownStyled
                options={EMPLOYEE_STATUS}
                labelField="label"
                valueField="value"
                placeholder="Select employee status"
                clearable={false}
                {...field}
              />
            )}
          />
        </div>
      )}
      {reportType === 'earnings' && (
        <div className="col">
          <Controller
            control={control}
            name="period"
            render={({ field }) => (
              <DropdownStyled
                options={PERIOD_OPTIONS}
                clearable={false}
                labelField="label"
                valueField="value"
                {...field}
              />
            )}
          />
        </div>
      )}
    </>
  );
};

export default PRFilters;
