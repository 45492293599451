import { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ContractItemsTableContainer from '../../features/jobsContracts/ContractItems/ContractItemsTableContainer';
import CostRevenueTableContainer from '../../features/jobsContracts/CostRevenue/CostRevenueTableContainer';
import CostToDateTableContainer from '../../features/jobsContracts/CostToDate/CostToDateTableContainer';
import JCCostEstimateTableContainer from '../../features/jobsContracts/JCCostEstimate/JCCostEstimateTableContainer';
import JCDetailTableContainer from '../../features/jobsContracts/JCDetail/JCDetailTableContainer';
import JCFilters, {
  JobContractFilters,
} from '../../features/jobsContracts/JCFilters';
import JCReport, {
  idPerJCReportType,
} from '../../features/jobsContracts/JCReport/JCReport';
import JCReportHeader from '../../features/jobsContracts/JCReport/JCReportHeader';
import JCContractAnalysis from '../../features/jobsContracts/JCContractAnalysis/JCContractAnalysisContainer';
import JCResumeTableContainer from '../../features/jobsContracts/JCResume/JCResumeTableContainer';
import checkPermissions from '../../HOCs/CheckPermissions';
import BacklogContainer from '../../features/jobsContracts/Backlog/BacklogContainer';
import JCProjectedCostTableContainer from '../../features/jobsContracts/JCProjectedCost/JCProjectedCostTableContainer';
import JCParams from '../../features/jobsContracts/JCParams';
import JCPhaseMasterContainer from '../../features/jobsContracts/PhaseMaster/JCPhaseMasterContainer';
import JCJobPhasesContainer from '../../features/jobsContracts/JCJobPhases/JCJobPhasesContainer';
import {
  MODULES,
  reportsPerSection,
} from 'apps/tmr-frontend/src/utils/rolesConst';
import useProtectedReportOptions from '../../hooks/useProtectedReportOptions';
import ReportSelector from '../../components/report-selector/ReportSelector';
import FiltersMenu from '../../components/filters-menu/FiltersMenu';
import { useCompanyContext } from '../../context/CompanyContext';

const JobsContracts = () => {
  const { selectedCompany } = useCompanyContext();
  const prevSelectedCompanyRef = useRef<string | null>(null);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const methods = useForm<JobContractFilters>({
    defaultValues: {
      contractState: 'active',
      extraDetails: 'hide',
    },
  });
  const { watch, resetField, getValues } = methods;
  const reportType = watch('reportType');
  const reportInfo =
    idPerJCReportType[reportType as keyof typeof idPerJCReportType];
  const [protectedReports] = useProtectedReportOptions({
    reports: MODULES['jobs & contracts'],
  });

  const JCBacklog = checkPermissions({
    sectionsToAccess: reportsPerSection['jobs & contracts'],
    Children: BacklogContainer,
  });

  const JCSummary = checkPermissions({
    sectionsToAccess: reportsPerSection['jobs & contracts'],
    Children: JCResumeTableContainer,
  });

  const Analysis = checkPermissions({
    sectionsToAccess: ['contract_analysis'],
    Children: JCContractAnalysis,
  });
  const Revenue = checkPermissions({
    sectionsToAccess: ['cost_and_revenue'],
    Children: CostRevenueTableContainer,
  });
  const Estimate = checkPermissions({
    sectionsToAccess: ['cost/estimate'],
    Children: JCCostEstimateTableContainer,
  });
  const Detail = checkPermissions({
    sectionsToAccess: ['cost_detail'],
    Children: JCDetailTableContainer,
  });
  const Cost = checkPermissions({
    sectionsToAccess: ['mtd/ytd/jtd_costs'],
    Children: CostToDateTableContainer,
  });
  const Items = checkPermissions({
    sectionsToAccess: ['contract_items_with_co_detail'],
    Children: ContractItemsTableContainer,
  });
  const ProjectedCost = checkPermissions({
    sectionsToAccess: ['projected_jc_report'],
    Children: JCProjectedCostTableContainer,
  });
  const PhaseMaster = checkPermissions({
    sectionsToAccess: ['phase_master'],
    Children: JCPhaseMasterContainer,
  });
  const JobPhases = checkPermissions({
    sectionsToAccess: ['job_phases'],
    Children: JCJobPhasesContainer,
  });

  const handleReportChange = () => {
    const report = getValues('reportType');
    resetField('contract');
    resetField('contractState', { defaultValue: 'active' });
    resetField('jobState', {
      defaultValue: report === 'costRevenue' ? 'all' : 'active',
    });
    resetField('endDate');
    resetField('info');
    resetField('startDate', {
      defaultValue:
        report === 'costRevenue'
          ? new Date(new Date().getFullYear(), 0, 1)
          : null,
    });
    resetField('phases');
    resetField('job');
    resetField('submittedID');
    resetField('pms');
    resetField('contracts');
    resetField('extraDetails', { defaultValue: 'hide' });
  };

  useEffect(() => {
    if (prevSelectedCompanyRef.current === null) {
      prevSelectedCompanyRef.current = selectedCompany?.id || null;
    } else if (prevSelectedCompanyRef.current !== selectedCompany?.id) {
      methods.reset();
      prevSelectedCompanyRef.current = selectedCompany?.id || null;
    }
  }, [selectedCompany, methods]);

  return (
    <div className="h-full flex flex-column">
      <FormProvider {...methods}>
        <div className="mt-6 mx-3 flex gap-4 flex-wrap justify-content-start">
          <div className="mr-auto lg:mr-0  w-full max-w-500px max-h-280px">
            <JCBacklog />
          </div>
          <div>
            <JCSummary />
          </div>
        </div>
        <div className="flex flex-column  gap-2 mx-3 mt-4">
          <div className="flex flex-row gap-2 justify-content-between">
            <ReportSelector
              protectedReports={protectedReports}
              onChange={() => {
                handleReportChange();
              }}
            />
            <div className="flex w-full justify-content-between  gap-2">
              <FiltersMenu>
                <JCFilters />
              </FiltersMenu>
              <JCReport elementRef={tableRef} />
            </div>
          </div>
        </div>
        <div
          className={`mb-3 printBackground ${
            !reportInfo?.isPortrait && 'pdfDivFitContent'
          } w-full`}
          ref={tableRef}
        >
          <JCReportHeader />
          {reportType === 'contractAnalysis' && <Analysis />}
          {reportType === 'costRevenue' && <Revenue />}
          {reportType === 'costEstimate' && <Estimate />}
          {reportType === 'costDetail' && <Detail />}
          {reportType === 'costs' && <Cost />}
          {reportType === 'itemDetails' && <Items />}
          {reportType === 'projectedJC' && <ProjectedCost />}
          {reportType === 'phaseMaster' && <PhaseMaster />}
          {reportType === 'jobPhases' && <JobPhases />}
        </div>
        <JCParams />
      </FormProvider>
    </div>
  );
};

export default JobsContracts;
