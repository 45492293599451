import React from 'react';
import { formatDate } from '../../../../utils/formatUtils';
import { useWatch } from 'react-hook-form';
import { useCompanyContext } from '../../../context/CompanyContext';
import { useQuery } from '@tanstack/react-query';
import { ARFiltersArgs } from '../ARFilter/ARFilters';
import {
  fetchARContracts,
  fetchARCustomers,
} from '../../../services/AccountsReceivablesService';
import {
  getCustomerNames,
  getPeriodLabel,
  getReceivableTypeName,
} from './ARReportUtils';
import { getContractNames } from '../../../../utils/reportUtils';

const ARReportHeader = () => {
  const { selectedCompany } = useCompanyContext();
  const filters = useWatch<ARFiltersArgs>();
  const { data } = useQuery({
    queryKey: ['getARCustomer', selectedCompany],
    queryFn: ({ signal }) => fetchARCustomers(selectedCompany!, signal),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const contractsRequest = useQuery({
    queryKey: ['getARContracts', selectedCompany, filters.reportType],
    queryFn: ({ signal }) =>
      fetchARContracts(selectedCompany!, filters.reportType, signal),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  return (
    <div className="blackText printMedia printShow">
      <div className="text-30px text-center mt-5">{selectedCompany!.name}</div>
      <div className="text-28px text-center">Accounts Receivables Report</div>
      <div className="text-17px ml-3 mt-2">
        <span className="font-bold text-20px">Search criteria:</span>
        <div className="ml-3">
          <div>
            <span className="font-bold mr-1">Report Type:</span>
            {getReceivableTypeName(filters.reportType ?? '')}
          </div>
          {filters.reportType === 'agedAnalysis' && filters.period && (
            <div>
              <span className="font-bold mr-1">Period:</span>
              {getPeriodLabel(filters.period)}
            </div>
          )}
          {filters.customers && filters.customers.length !== 0 && (
            <div>
              <span className="font-bold mr-1">Customers:</span>
              {getCustomerNames(filters.customers, data)}
            </div>
          )}
          {filters.contracts && filters.contracts.length !== 0 && (
            <React.Fragment>
              <div>
                <span className="font-bold mr-1">Contracts:</span>
                {filters.contracts.join(', ')}
              </div>
              <div>
                <span className="font-bold mr-1">Contract Names:</span>
                {getContractNames(filters.contracts, contractsRequest.data)}
              </div>
            </React.Fragment>
          )}
          {filters.dates && (
            <div>
              <span className="font-bold mr-1">Date range:</span>
              {formatDate(filters.dates[0])}
              {filters.dates[1] && ` - ${formatDate(filters.dates[1])}`}
            </div>
          )}
          {filters.info && (
            <div>
              <span className="font-bold">Search term:</span> {filters.info}
            </div>
          )}
        </div>
      </div>
      <div className="ml-3 text-17px mb-5">
        <span className="font-bold mr-1">Created at:</span>
        {new Date().toLocaleString()}
      </div>
    </div>
  );
};

export default ARReportHeader;
