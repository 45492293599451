import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import SystemError from './components/messages/SystemError';
import ErrorBoundary from './HOCs/ErrorBoundary';
import RequireAuth from './HOCs/ProtectedRoute';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import MainRoutes from './routes/MainRoutes';

const AppWrapper = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <ErrorBoundary fallBack={<SystemError />}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route
          path="/*"
          element={
            <RequireAuth>
              <MainRoutes />
            </RequireAuth>
          }
        />
      </Routes>
    </ErrorBoundary>
  );
};

export default AppWrapper;
