import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import { fetchAPOpenAged } from '../../../services/AccountsPayablesService';
import APAgedPayablesTable from './APAgedPayablesTable';
import { useFormContext } from 'react-hook-form';
import { fetchAccountsPayablesFilters } from '../APFilters';
import { processAgedPayablePerVendor } from '../apUtil';

const APAgedPayablesTableContainer = React.forwardRef<HTMLDivElement>(() => {
  const { selectedCompany } = useCompanyContext();
  const { watch } = useFormContext<fetchAccountsPayablesFilters>();
  const filters = watch();

  const { data, isFetching, isError } = useQuery({
    queryKey: ['getAgedAccountsPayables', selectedCompany, filters],
    queryFn: ({ signal }) => {
      const { reportType, ...otherFilters } = filters;

      return fetchAPOpenAged(
        { ...otherFilters, company: selectedCompany! },
        signal
      );
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  if (isFetching || !selectedCompany)
    return (
      <div className="text-center mx-auto mt-3">
        <ProgressSpinner />
      </div>
    );

  if (isError)
    return (
      <ErrorMessage
        content={'Failed to obtain data! Please try again later.'}
      />
    );

  return (
    <APAgedPayablesTable accountsPayables={processAgedPayablePerVendor(data)} />
  );
});

export default APAgedPayablesTableContainer;
