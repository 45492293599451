import React from 'react';
import { Sidebar } from 'primereact/sidebar';
import { useQuery } from '@tanstack/react-query';
import { useCompanyContext } from '../../../context/CompanyContext';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { fetchSCPaymentDetails } from '../../../services/SubcontractsService';
import SCPaymentDrilldown from './SCPaymentDrilldown';
import { SCPayment } from '../../../Interfaces/Accounting/Subcontracts.interfaces';

type SCPaymentDrilldownContainerProps = {
  visible: boolean;
  hideDrilldown: () => void;
  scpayment: SCPayment;
};

const SCPaymentDrilldownContainer = ({
  visible,
  hideDrilldown,
  scpayment,
}: SCPaymentDrilldownContainerProps) => {
  const { selectedCompany } = useCompanyContext();

  const { data, isFetching, isError, isSuccess } = useQuery({
    queryKey: ['getSCPaymentDetails', selectedCompany, scpayment],
    queryFn: ({ signal }) =>
      fetchSCPaymentDetails(
        {
          companyID: selectedCompany!.id,
          apref: scpayment.APRef ?? '',
          sl: scpayment.SL ?? '',
          paidDate: scpayment.PaidDate,
        },
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={hideDrilldown}
      className="w-full max-w-1000px"
    >
      {isFetching && (
        <div className="mx-8 h-full flex align-items-center">
          <ProgressSpinner />
        </div>
      )}
      {isError && (
        <div className="h-full flex align-items-center">
          <ErrorMessage
            content={'Failed to obtain data! Please try again later.'}
          />
        </div>
      )}
      {isSuccess && <SCPaymentDrilldown paymentDetails={scpayment} details={data} />}
    </Sidebar>
  );
};

export default SCPaymentDrilldownContainer;
