import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import { getFolder } from '../../../services/FinancialStatementsService';
import { BreadCrumb } from 'primereact/breadcrumb';
import FileListContainer from '../../../features/financialStatement/FileList/FileListContainer';
import { useEffect, useRef, useState } from 'react';

const FSFolderFiles = () => {
  const { folderPath } = useParams();
  const navigate = useNavigate();
  const { selectedCompany } = useCompanyContext();
  const prevSelectedCompanyRef = useRef<string | null>(null);
  const [rendered, setRendered] = useState(false);

  const { data, isFetching, isError } = useQuery({
    queryKey: ['getFSFolderData', selectedCompany, folderPath],
    queryFn: ({ signal }) =>
      getFolder(selectedCompany!.id, folderPath ?? '', signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  useEffect(() => {
    if (rendered) {
      if (
        prevSelectedCompanyRef.current &&
        prevSelectedCompanyRef.current !== selectedCompany!.id
      ) {
        navigate('/accounting-dashboard/financialStatement');
      } else {
        prevSelectedCompanyRef.current = selectedCompany!.id;
      }
    }
  }, [rendered, selectedCompany, navigate]);

  useEffect(() => {
    setRendered(true);
  }, []);

  if (isFetching || !selectedCompany) {
    return (
      <div className="h-full flex pt-8">
        <ProgressSpinner />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="h-full flex pt-8">
        <ErrorMessage
          content={'Failed to obtain folder data! Please try again later.'}
        />
      </div>
    );
  }

  const items = [{ label: data?.folderName }];
  const home = {
    icon: 'pi pi-folder',
    command: () => navigate('/accounting-dashboard/financialStatement'),
  };

  return (
    <div className="h-full flex flex-column">
      <div className="mx-5 mb-2 mt-4">
        <BreadCrumb className="w-fit" model={items} home={home} />
      </div>
      <div className="mb-3 mx-8">
        <FileListContainer folderID={data?.id} />
      </div>
    </div>
  );
};

export default FSFolderFiles;
