import { Route, Routes } from 'react-router-dom';
import App from '../App';
import { CompanyContextProvider } from '../context/CompanyContext';
import { RolesAccessContextProvider } from '../context/RolesAccessContext';
import GetRoles from '../features/roles/GetRoles';
import checkPermissions from '../HOCs/CheckPermissions';
import AdminDashboard from '../pages/Admin/AdminDashboard';
import Dashboard from '../pages/Dashboard';

const MainRoutes = () => {
  const Admin = checkPermissions({
    sectionsToAccess: [],
    Children: AdminDashboard,
  });

  return (
    <CompanyContextProvider>
      <RolesAccessContextProvider>
        <GetRoles>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/*" element={<App />} />
          </Routes>
        </GetRoles>
      </RolesAccessContextProvider>
    </CompanyContextProvider>
  );
};

export default MainRoutes;
