import { transformBToMB } from '../../../../utils/fileUtil';
import {
  FileUpload,
  FileUploadHeaderTemplateOptions,
} from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';

type FileInputHeaderProps = {
  headerOptions: FileUploadHeaderTemplateOptions;
  currentSize: number;
  sizeLimitLabel: string;
  sizelimit: number;
  inputRef: React.RefObject<FileUpload>;
};

const FileInputHeader = ({
  headerOptions,
  currentSize,
  sizeLimitLabel,
  sizelimit,
  inputRef,
}: FileInputHeaderProps) => {
  const { className, chooseButton, uploadButton, cancelButton } = headerOptions;
  const value = (currentSize * 100) / sizelimit;

  let displayeValue = currentSize;
  if (currentSize >= 1000000) {
    displayeValue = transformBToMB(currentSize) * 1000000;
  }

  const formatedValue = inputRef?.current
    ? inputRef.current.formatSize(displayeValue)
    : '0 B';

  return (
    <div
      className={`${className} flex flex-wrap row-gap-3 column-gap-1`}
      style={{
        backgroundColor: 'transparent',
        alignItems: 'center',
      }}
    >
      {chooseButton}
      {uploadButton}
      {cancelButton}
      <div className="flex align-items-center gap-3 ml-auto mr-auto sm:mr-0">
        <span>
          {formatedValue} / {sizeLimitLabel}
        </span>
        <ProgressBar
          value={value}
          showValue={false}
          style={{ width: '10rem', height: '12px' }}
        />
      </div>
    </div>
  );
};

export default FileInputHeader;
