import React from 'react';
import ReportButton from '../../../components/report/ReportButton';
import * as XLSX from 'xlsx';
import { useCompanyContext } from '../../../context/CompanyContext';
import { useWatch } from 'react-hook-form';
import { formatDate } from '../../../../utils/formatUtils';
import { useQuery } from '@tanstack/react-query';
import {
  BASE_PDF_STYLES,
  getContractNames,
} from '../../../../utils/reportUtils';
import {
  getCustomerNames,
  getPeriodLabel,
  getReceivableTypeName,
} from './ARReportUtils';
import { ARFiltersArgs } from '../ARFilter/ARFilters';
import {
  fetchARContracts,
  fetchARCustomers,
} from '../../../services/AccountsReceivablesService';
import { excelCleanTable } from '../../../../utils/excelUtils';

const idPerReceivableType = {
  agedAnalysis: 'ar-aged-table',
  contractBAR: 'ar-cbr-table',
  receiptsHistory: 'ar-receipt-table',
  customerList: 'ar-customer-table',
};

type ARReportProps = {
  elementRef: React.MutableRefObject<HTMLElement | null>;
};

const ARReport = ({ elementRef }: ARReportProps) => {
  const { selectedCompany } = useCompanyContext();
  const filters = useWatch<ARFiltersArgs>();
  const reportDate = new Date();

  const { data } = useQuery({
    queryKey: ['getARCustomer', selectedCompany],
    queryFn: ({ signal }) => fetchARCustomers(selectedCompany!, signal),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const contractsRequest = useQuery({
    queryKey: ['getARContracts', selectedCompany, filters.reportType],
    queryFn: ({ signal }) =>
      fetchARContracts(selectedCompany!, filters.reportType, signal),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const copyIntoCleanContainer = (
    parent: HTMLElement,
    newElement: HTMLElement,
    className: string
  ) => {
    const elementCopy = newElement?.cloneNode(true);
    const container = parent?.getElementsByClassName(
      className
    )[0] as HTMLElement;

    if (elementCopy && container) {
      container.innerHTML = '';
      container.appendChild(elementCopy);
      return container.parentElement?.cloneNode(true);
    }

    return null;
  };

  const contractBillsReceiptsPreprocess = (tableBody: Element) => {
    const multiLines = tableBody.querySelectorAll('tr .dateMultiLine');

    if (multiLines.length === 0) {
      return;
    }

    Array.from(multiLines).forEach(function (multiLine) {
      const parentRow = multiLine.closest('tr');
      const tableBody = parentRow?.parentElement;
      const dateLines = multiLine.getElementsByTagName('div');
      const checkLines = parentRow?.getElementsByClassName('checkMultiLine')[0];
      const checks = checkLines?.getElementsByTagName('div');
      const clearLines = parentRow?.getElementsByClassName('clearMultiLine')[0];
      const clearDates = clearLines?.getElementsByTagName('div');

      Array.from(dateLines).forEach(function (dateLine, idx) {
        const rowCopy = parentRow?.cloneNode(true) as HTMLElement;
        let dateCell = null;
        let clearCell = null;
        let checkCell = null;

        dateCell = copyIntoCleanContainer(rowCopy, dateLine, 'dateMultiLine');

        const clearDate = clearDates ? clearDates[idx] : null;
        if (clearDate) {
          clearCell = copyIntoCleanContainer(
            rowCopy,
            clearDate,
            'clearMultiLine'
          );
        }

        const check = checks ? checks[idx] : null;
        if (check) {
          checkCell = copyIntoCleanContainer(rowCopy, check, 'checkMultiLine');
        }

        const cells = rowCopy.getElementsByTagName('td');
        if (idx === 0) {
          Array.from(cells).forEach(function (cell) {
            const multiLine = cell.querySelectorAll('[class*="MultiLine"]');
            if (multiLine.length === 0) {
              cell.setAttribute('rowSpan', dateLines.length.toString());
            }
          });
        } else {
          Array.from(cells).forEach(function (cell) {
            cell.remove();
          });
          rowCopy.append(checkCell ?? '');
          rowCopy.append(dateCell ?? '');
          rowCopy.append(clearCell ?? '');
        }

        tableBody?.insertBefore(rowCopy, parentRow);
      });
      parentRow?.remove();
    });
  };

  const excelElementPreProcess = (element: HTMLElement) => {
    const tableBody = element.getElementsByClassName('p-datatable-tbody')[0];

    excelCleanTable(element);

    if (filters.reportType === 'contractBAR') {
      contractBillsReceiptsPreprocess(tableBody);
    }

    return element;
  };

  const agedReceivablesBeforeDownload = (
    sheet: XLSX.WorkSheet,
    element?: HTMLElement
  ) => {
    const ref = sheet['!ref'];
    const boundary = (ref ?? '').replace(/[A-Z]/g, '').split(':');
    const lastRow = parseInt(boundary[1]);

    const totalPayables = element?.querySelector('#totalReceivables');
    const totalRetainage = element?.querySelector('#totalRetainage');
    const grandTotal = element?.querySelector('#grandTotal');

    sheet[`K${lastRow + 2}`] = { t: 's', v: 'Receivables:' };
    sheet[`L${lastRow + 2}`] = { t: 's', v: totalPayables?.textContent };
    sheet[`K${lastRow + 3}`] = { t: 's', v: 'A/R - Retainage:' };
    sheet[`L${lastRow + 3}`] = { t: 's', v: totalRetainage?.textContent };
    sheet[`K${lastRow + 4}`] = { t: 's', v: 'Grand Total:' };
    sheet[`L${lastRow + 4}`] = { t: 's', v: grandTotal?.textContent };

    sheet['!fullref'] = `A${1}:L${lastRow + 4}`;
    sheet['!ref'] = `A${1}:L${lastRow + 4}`;
    if (sheet['!cols']) {
      sheet['!cols'][10].width = 14;
    }
  };

  const excelBeforeDownload = (
    workBook: XLSX.WorkBook,
    element?: HTMLElement
  ) => {
    const sheet = workBook.Sheets['Sheet1'];
    const companyName = selectedCompany!.name;
    const searchTerm = filters.info;
    const dateSearch = filters.dates;

    sheet['D2'] = { t: 's', v: companyName };
    sheet['D3'] = { t: 's', v: 'Accounts Receivables Report' };
    sheet['A4'] = { t: 's', v: 'Search criteria:' };
    sheet['B5'] = { t: 's', v: 'Report Type:' };
    sheet['C5'] = {
      t: 's',
      v: getReceivableTypeName(filters.reportType ?? ''),
    };

    let rowStart = 6;

    if (filters.period) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Period:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: getPeriodLabel(filters.period),
      };
      rowStart += 1;
    }

    if (filters.customers && filters.customers.length !== 0) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Customers:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: getCustomerNames(filters.customers, data),
      };
      rowStart += 1;
    }

    if (filters.contracts && filters.contracts.length !== 0) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Contracts:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: filters.contracts.join(', '),
      };
      sheet[`B${rowStart + 1}`] = { t: 's', v: 'Contract Names:' };
      sheet[`C${rowStart + 1}`] = {
        t: 's',
        v: getContractNames(filters.contracts, contractsRequest.data),
      };
      rowStart += 2;
    }

    if (dateSearch) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Date range:' };
      sheet[`C${rowStart}`] = {
        t: 's',
        v: `${formatDate(dateSearch[0])} ${
          dateSearch[1] ? ` - ${formatDate(dateSearch[1])}` : ''
        } `,
      };
      rowStart += 1;
    }

    if (searchTerm) {
      sheet[`B${rowStart}`] = { t: 's', v: 'Search term:' };
      sheet[`C${rowStart}`] = { t: 's', v: searchTerm };
      rowStart += 1;
    }

    sheet[`A${rowStart}`] = { t: 's', v: 'Created at:' };
    sheet[`B${rowStart}`] = { t: 's', v: reportDate.toLocaleString() };

    if (filters.reportType === 'agedAnalysis') {
      agedReceivablesBeforeDownload(sheet, element);
    }
  };

  return (
    <ReportButton
      elementToPrintRef={elementRef}
      fileName="AR_Report"
      pdfStyles={BASE_PDF_STYLES}
      excelPreprocess={excelElementPreProcess}
      excelBeforeDownload={excelBeforeDownload}
      tableOriginCell="A14"
      widthElementID={
        idPerReceivableType[
          filters.reportType as keyof typeof idPerReceivableType
        ]
      }
    />
  );
};

export default ARReport;
