import React, { useCallback } from 'react';
import { formatCurrency, formatUTCDate } from '../../../../utils/formatUtils';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import {
  AccountBalance,
  CMAccount,
} from '../../../Interfaces/Accounting/CashManagement.interfaces';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';

type CMTransactionBluwaiTableProps = {
  startBalance: AccountBalance;
  transactions: any[];
};

const CMBalanceTable = React.forwardRef<
  HTMLDivElement,
  CMTransactionBluwaiTableProps
>(({ startBalance, transactions }, ref) => {
  const calcHeight = (rows: number) => {
    const headerHeight = 32;
    const footerHeight = 54;
    const rowHeight = 31;
    return headerHeight + footerHeight + rows * rowHeight + 3;
  };

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  const rowClass = (data: unknown) => {
    const account = data as CMAccount;
    return {
      'balance-row': account.name === 'Start Balance',
    };
  };

  const getTotal = () => {
    if (transactions.length === 0) return 0;
    return transactions[transactions.length - 1].amount;
  };

  return (
    <Table
      id="cm-balance-table"
      data={[
        {
          amount: startBalance.amount,
          description: 'Start Balance',
          Void: 'Y',
        },
        ...transactions,
      ]}
      className="mx-3 dashboardOptionShadow tableFirstLineStyled"
      calcHeight={calcHeight}
      rowClass={rowClass}
      onRowMouseEnter={useCallback(onRowHover, [])}
      hideColumns={true}
    >
      <Column
        headerClassName={`tableHeader`}
        className="tableCell p-0 printHide"
        footerClassName="tableFooter "
      />
      <Column
        headerClassName={`tableHeader`}
        className="tableCell p-0 printHide"
        footerClassName="tableFooter"
      />
      <Column
        field="date"
        header="Date"
        body={(item) => {
          if (item.Void === 'Y') return null;

          return item.date ? formatUTCDate(item.date) : null;
        }}
        style={{ minWidth: '120px' }}
        headerClassName="tableHeader font-normal"
        className="justify-content-center text-standard blackText tableCell"
        footerClassName="tableFooter"
        sortable
      />
      <Column
        field="date"
        header="control"
        body={(item) => {
          return <div className="scroll-text">{item.control}</div>;
        }}
        style={{ minWidth: '120px' }}
        headerClassName="tableHeader font-normal"
        className="justify-content-center text-standard blackText tableCell"
        footerClassName="tableFooter"
        sortable
      />

      <Column
        field="Description"
        header="description"
        body={(item) => {
          return <div className="scroll-text">{item.description}</div>;
        }}
        style={{ minWidth: '400px', maxWidth: '400px' }}
        headerClassName="tableHeader font-normal justify-content-center"
        className=" text-standard blackText tableCell title overflow-x-hidden white-space-nowrap checkOverflow"
        footerClassName="tableFooter"
        sortable
      />
      <Column
        field="Remarks"
        header="remarks"
        body={(item) => {
          return <div className="scroll-text">{item.remarks}</div>;
        }}
        style={{ minWidth: '400px', maxWidth: '400px' }}
        headerClassName="tableHeader font-normal justify-content-center"
        className=" text-standard blackText tableCell title overflow-x-hidden white-space-nowrap checkOverflow"
        footerClassName="tableFooter"
        sortable
      />
      <Column
        field="debit"
        header="Debit(-)"
        body={(item) =>
          item.Void === 'Y' ? '' : formatCurrency(item.debit * -1)
        }
        style={{ minWidth: '150px' }}
        headerClassName="tableHeader font-normal justify-content-center"
        className="justify-content-end text-standard blackText tableCell"
        footer="Total"
        footerClassName="tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
        sortable
      />
      <Column
        field="credit"
        header="Credit(+)"
        body={(item) => (item.Void === 'Y' ? '' : formatCurrency(item.credit))}
        style={{ minWidth: '150px' }}
        headerClassName="tableHeader font-normal justify-content-center"
        className="justify-content-end text-standard blackText tableCell"
        footerClassName="tableFooter border-top-2 mt-4"
        sortable
      />
      <Column
        field="amount"
        header="Balance"
        body={(item) => {
          return formatCurrency(item.amount);
        }}
        style={{ minWidth: '150px' }}
        headerClassName="tableHeader font-normal justify-content-center"
        className="justify-content-end text-standard blackText tableCell result"
        footer={formatCurrency(getTotal())}
        footerClassName="tableFooter block text-right border-top-2 mt-4"
        sortable
      />

      <Column
        headerClassName={`tableHeader`}
        className="tableCell p-0 printHide"
        footerClassName="tableFooter"
      />
      <Column
        headerClassName={`tableHeader`}
        className="tableCell p-0 printHide"
        footerClassName="tableFooter"
      />
    </Table>
  );
});

const transactionsAreEqual = (
  prevTransactions: Readonly<CMTransactionBluwaiTableProps>,
  nextTransactions: Readonly<CMTransactionBluwaiTableProps>
) => {
  return (
    prevTransactions.startBalance === nextTransactions.startBalance &&
    prevTransactions.transactions === nextTransactions.transactions
  );
};

export default React.memo(CMBalanceTable, transactionsAreEqual);
