import { useQuery } from '@tanstack/react-query';
import { RadioButton } from 'primereact/radiobutton';
import React, { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import FormControlledDropdown from '../../../components/inputs/FormInputs/FormControlledDropdown';
import FormControlledInput from '../../../components/inputs/FormInputs/FormControlledInput';
import { useCompanyContext } from '../../../context/CompanyContext';
import { fetchJobLookUpInfo } from '../../../services/ACFormsService';
import { JOB_NOTICE_OPTIONS, JOB_PHYSICIANS_OPTIONS } from '../formConsts';

const JobForm = () => {
  const { selectedCompany } = useCompanyContext();
  const { setValue, control } = useFormContext();
  const state = useWatch({ name: 'state' });
  const department = useWatch({ name: 'department' });

  const { data, isLoading, isError } = useQuery({
    queryKey: ['getNewJobInfo', selectedCompany, department],
    queryFn: ({ signal }) =>
      fetchJobLookUpInfo(selectedCompany!.id, department, signal),
    onSuccess: (data) => {
      setValue('extraJob', data.allowExtraJob);
    },
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && department),
  });

  useEffect(() => {
    setValue('prState', state);
  }, [state, setValue]);

  return (
    <div className="grid mx-3 justify-content-center">
      <div className="col-12 sm:col-6">
        <label htmlFor="contract" className="text-standard block mb-2">
          Job Number
        </label>
        <FormControlledInput
          defaultValue=""
          disabled={true}
          formID="contract"
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="contractName" className="text-standard block mb-2">
          Job Description
        </label>
        <FormControlledInput
          defaultValue=""
          formID="contractName"
          disabled={true}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="contract" className="text-standard block mb-2">
          Contract
        </label>
        <FormControlledInput
          defaultValue=""
          disabled={true}
          formID="contract"
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="projectManager" className="text-standard block mb-2">
          Project Manager
        </label>
        <FormControlledDropdown
          formID="projectManager"
          rules={{
            required: {
              value: true,
              message: 'Project Manager is a required field',
            },
          }}
          options={data?.projectManagers}
          labelField="Name"
          valueField="ProjectMgr"
          placeholder={
            isError
              ? 'Failed to load project managers!'
              : 'Select project manager'
          }
          isDisabled={isError}
          isLoading={isLoading}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="address1" className="text-standard block mb-2">
          Address 1
        </label>
        <FormControlledInput
          defaultValue=""
          formID="address1"
          rules={{
            required: {
              value: true,
              message: 'Address 1 is a required field',
            },
          }}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="address2" className="text-standard block mb-2">
          Address 2
        </label>
        <FormControlledInput defaultValue="" formID="address2" />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="city" className="text-standard block mb-2">
          City
        </label>
        <FormControlledInput
          defaultValue=""
          formID="city"
          rules={{
            required: {
              value: true,
              message: 'City is a required field',
            },
          }}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="state" className="text-standard block mb-2">
          State
        </label>
        <FormControlledDropdown
          formID="state"
          rules={{
            required: {
              value: true,
              message: 'State is a required field',
            },
          }}
          options={data?.states}
          labelField="Name"
          valueField="State"
          placeholder={isError ? 'Failed to load states!' : 'Select state'}
          isDisabled={isError}
          isLoading={isLoading}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="zip" className="text-standard block mb-2">
          ZIP
        </label>
        <FormControlledInput
          defaultValue=""
          formID="zip"
          keyfilter="int"
          rules={{
            required: {
              value: true,
              message: 'ZIP is a required field',
            },
            maxLength: { value: 5, message: 'ZIP max length is 5' },
            minLength: { value: 5, message: 'ZIP min length is 5' },
          }}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="county" className="text-standard block mb-2">
          County
        </label>
        <FormControlledInput
          defaultValue=""
          formID="county"
          rules={{
            required: {
              value: true,
              message: 'County is a required field',
            },
          }}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="prState" className="text-standard block mb-2">
          Payroll State
        </label>
        <FormControlledDropdown
          formID="prState"
          disabled={true}
          rules={{
            required: {
              value: true,
              message: 'Payroll State is a required field',
            },
          }}
          options={data?.states}
          labelField="Name"
          valueField="State"
          placeholder={
            isError ? 'Failed to load payroll states!' : 'Select payroll state'
          }
          isDisabled={isError}
          isLoading={isLoading}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="taxCode" className="text-standard block mb-2">
          Sales Tax Code
        </label>
        <FormControlledDropdown
          formID="taxCode"
          rules={{
            required: {
              value: true,
              message: 'Tax Code is a required field',
            },
          }}
          options={data?.taxCodes}
          labelField="Description"
          valueField="TaxCode"
          placeholder={
            isError ? 'Failed to load tax codes!' : 'Select tax code'
          }
          isDisabled={isError}
          isLoading={isLoading}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="reviewerGroup" className="text-standard block mb-2">
          AP Inv Rev Grp
        </label>
        <FormControlledDropdown
          formID="reviewerGroup"
          rules={{
            required: {
              value: true,
              message: 'AP Inv Rev Grp is a required field',
            },
          }}
          options={[
            ...(data?.reviewerGroups || []),
            { Description: 'TBD', ReviewerGroup: 'TBD' },
          ]}
          labelField="Description"
          valueField="ReviewerGroup"
          placeholder={
            isError ? 'Failed to load ap inv rev grp!' : 'Select ap inv rev grp'
          }
          isDisabled={isError}
          isLoading={isLoading}
        />
      </div>
      <div className="col-12 sm:col-6">
        {data?.allowExtraJob && (
          <React.Fragment>
            <label htmlFor="extraJob" className="text-standard block mb-2">
              Create service maintenance extra job?
            </label>
            <Controller
              defaultValue={false}
              control={control}
              name="extraJob"
              render={({ field }) => (
                <div className="flex align-items-center justify-content-around text-standard h-3rem">
                  <div>
                    <RadioButton
                      inputId="yes"
                      {...field}
                      value={true}
                      checked={field.value}
                    />
                    <label htmlFor="yes" className="ml-1 mr-3">
                      Yes
                    </label>
                  </div>
                  <div>
                    <RadioButton
                      inputId="no"
                      {...field}
                      inputRef={field.ref}
                      value={false}
                      checked={!field.value}
                    />
                    <label htmlFor="no" className="ml-1 mr-3">
                      No
                    </label>
                  </div>
                </div>
              )}
            />
          </React.Fragment>
        )}
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="physician" className="text-standard block mb-2">
          <span>
            Requested required panel of physicians from Elena for display at the
            job site?
          </span>
          <span className="visibility-hidden">Padding text</span>
        </label>
        <FormControlledDropdown
          formID="physician"
          rules={{
            required: {
              value: true,
              message: 'Panel of physicians is a required field',
            },
          }}
          options={JOB_PHYSICIANS_OPTIONS}
          placeholder="Select option"
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="notice" className="text-standard block mb-2">
          <span>
            Notice to Owner sent via certified mail to the Owner and GC?
          </span>
          <span className="ml-1 text-red-500">
            Must be done within 45 days of work commencement.
          </span>
        </label>
        <FormControlledDropdown
          formID="notice"
          rules={{
            required: {
              value: true,
              message: 'Notice to Owner is a required field',
            },
          }}
          options={JOB_NOTICE_OPTIONS}
          labelField="label"
          valueField="value"
          placeholder="Select option"
        />
      </div>
    </div>
  );
};

export default JobForm;
