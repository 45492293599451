import React, { useEffect, useState } from 'react';
import { AccountReceivableResume } from '../../Interfaces/Accounting/AccountsReceivables.interfaces';
import ResumeCard from '../../components/cards/ResumeCard';
import { Link } from 'react-router-dom';
import ErrorMessage from '../../components/messages/ErrorMessage';
import { formatCurrency } from '../../../utils/formatUtils';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { BACKGROUND_COLORS, BORDER_COLORS } from '../../../utils/graphConst';
import ColorIndicator from '../../components/messages/ColorIndicator';
import { CompanySource } from '../../Interfaces/User.interfaces';
import { useCompanyContext } from '../../context/CompanyContext';
import { useQuery } from '@tanstack/react-query';
import { fetchAccountingDashboardAccounts } from '../../services/DataService';

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale);

const DashboardAccountsReceivables = () => {
  const { selectedCompany } = useCompanyContext();
  const [hidden, setHidden] = useState(false);
  const [resume, setResume] = useState<AccountReceivableResume>({
    retainage: 0,
    over90: 0,
    over60: 0,
    over30: 0,
    current: 0,
    future: 0,
  });

  const labels = [
    resume.future === 0 ? null : 'Future',
    'Current',
    '30+ days',
    '60+ days',
    '90+ days',
    'Retainage',
  ];

  let amounts = [
    resume.future === 0 ? null : resume.future,
    resume.current,
    resume.over30,
    resume.over60,
    resume.over90,
    resume.retainage,
  ];
  amounts = amounts.filter((amount) => amount);

  const graphData = {
    labels: labels.filter((label) => label),
    datasets: [
      {
        data: amounts,
        backgroundColor: BACKGROUND_COLORS,
        borderColor: BORDER_COLORS,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const handleMouseEnter = () => {
    if (selectedCompany?.source === CompanySource.ViewPoint) setHidden(true);
  };

  const handleMouseLeave = () => {
    if (selectedCompany?.source === CompanySource.ViewPoint) setHidden(false);
  };

  const { data, isError } = useQuery({
    queryKey: ['getAccountingDashboardAccountsData', selectedCompany],
    queryFn: ({ signal }) =>
      fetchAccountingDashboardAccounts(selectedCompany!, signal),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(() => {
    const ar = data?.accountsReceivablesResume;
    if (ar) {
      setResume(ar);
    }
  }, [data]);

  if (isError) {
    return (
      <div className="w-fit">
        <ResumeCard>
          <div className="relative p-3 px-4 flex flex-column h-full">
            <Link
              to={`/accounting-dashboard/accounts-receivables`}
              className="w-fit"
            >
              <div className="text-24px font-bold cursor-pointer totalColor hover:underline w-fit">
                Accounts Receivables
              </div>
            </Link>
            <div className="my-8 py-3">
              <ErrorMessage
                content={'Failed to obtain data! Please try again later.'}
              />
            </div>
          </div>
        </ResumeCard>
      </div>
    );
  }

  return (
    <ResumeCard>
      <div
        className="relative p-3 px-4 flex flex-column"
        onMouseEnter={() => {
          handleMouseEnter();
        }}
        onMouseLeave={() => {
          handleMouseLeave();
        }}
        role="button"
        tabIndex={0}
      >
        <Link
          to={`/accounting-dashboard/accounts-receivables`}
          className="w-fit"
        >
          <div className="text-24px font-bold cursor-pointer totalColor hover:underline">
            Accounts Receivables
          </div>
        </Link>
        <div
          className={`flex flex-wrap justify-content-around gap-5 ${
            hidden && 'visibility-hidden'
          }`}
        >
          <div className="mt-auto mb-3">
            <div className="mb-2 totalColor text-25px word-break-all">
              {formatCurrency(
                resume.retainage +
                  resume.over90 +
                  resume.over60 +
                  resume.over30 +
                  resume.current +
                  (resume.future || 0)
              )}
            </div>
            <div className="w-fit mt-1 text-18px text-white totalBG radius-5px p-2">
              Total Due
            </div>
          </div>
          <div>
            <Pie
              data={graphData}
              options={options}
              className="w-8rem h-8rem mx-auto"
            />
          </div>
        </div>
        {hidden && (
          <div className="absolute scrollbarStyled overflow-y-auto w-full my-3 px-4 left-0 bottom-0 height-65 flex flex-column gap-2 text-15px">
            <div className="flex justify-content-between">
              <Link
                className="totalColor cursor-pointer hover:underline flex gap-1 align-items-center"
                to={`/accounting-dashboard/accounts-receivables?period=retainage&reportType=agedAnalysis`}
              >
                <ColorIndicator colorIndex={amounts.length - 1} />
                Retainage
              </Link>
              <div className="text-15px">
                {formatCurrency(resume.retainage)}
              </div>
            </div>
            <div className="flex justify-content-between">
              <Link
                className="totalColor cursor-pointer hover:underline flex gap-1 align-items-center"
                to={`/accounting-dashboard/accounts-receivables?period=91&reportType=agedAnalysis`}
              >
                <ColorIndicator colorIndex={amounts.length - 2} />
                90+ days
              </Link>
              <div className="text-15px">{formatCurrency(resume.over90)}</div>
            </div>
            <div className="flex justify-content-between">
              <Link
                className="totalColor cursor-pointer hover:underline flex gap-1 align-items-center"
                to={`/accounting-dashboard/accounts-receivables?period=90&reportType=agedAnalysis`}
              >
                <ColorIndicator colorIndex={amounts.length - 3} />
                60+ days
              </Link>
              <div className="text-15px">{formatCurrency(resume.over60)}</div>
            </div>
            <div className="flex justify-content-between">
              <Link
                className="totalColor cursor-pointer hover:underline flex gap-1 align-items-center"
                to={`/accounting-dashboard/accounts-receivables?period=60&reportType=agedAnalysis`}
              >
                <ColorIndicator colorIndex={amounts.length - 4} />
                30+ days
              </Link>
              <div className="text-15px">{formatCurrency(resume.over30)}</div>
            </div>
            <div className="flex justify-content-between">
              <Link
                className="totalColor cursor-pointer hover:underline flex gap-1 align-items-center"
                to={`/accounting-dashboard/accounts-receivables?period=30&reportType=agedAnalysis`}
              >
                <ColorIndicator colorIndex={amounts.length - 5} />
                Current
              </Link>
              <div className="text-15px">{formatCurrency(resume.current)}</div>
            </div>
            {!!resume.future && (
              <div className="flex justify-content-between">
                <Link
                  className="totalColor cursor-pointer hover:underline flex gap-1 align-items-center"
                  to={`/accounting-dashboard/accounts-receivables?period=future&reportType=agedAnalysis`}
                >
                  <ColorIndicator colorIndex={amounts.length - 6} />
                  Future
                </Link>
                <div className="text-15px">
                  {formatCurrency(resume.future || 0)}
                </div>
              </div>
            )}
            <div className="flex justify-content-between border-top-2 border-top-solid totalBorder pt-1 mt-auto">
              <Link
                className="totalColor cursor-pointer hover:underline"
                to={`/accounting-dashboard/accounts-receivables?reportType=agedAnalysis`}
              >
                Total Due
              </Link>
              <div className="text-15px">
                {formatCurrency(
                  resume.retainage +
                    resume.over90 +
                    resume.over60 +
                    resume.over30 +
                    resume.current +
                    (resume.future || 0)
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </ResumeCard>
  );
};

export default DashboardAccountsReceivables;
