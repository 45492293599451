import React, { useCallback } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { formatCurrency } from '../../../../utils/formatUtils';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import { JCCommittedCost } from '../../../Interfaces/Accounting/JobsContracts.interface';
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { useCompanyContext } from '../../../context/CompanyContext';

type JCCommittedCostTableProps = {
  costs: JCCommittedCost[];
};

const JCCommittedCostTable = React.forwardRef<
  HTMLDivElement,
  JCCommittedCostTableProps
>(({ costs }, ref) => {
  const { selectedCompany } = useCompanyContext();
  const tableClass = 'mt-5';
  const columnHeaderClass = 'DrilldownHeader';

  const calcHeight = (rows: number) => {
    const headerHeight = 32;
    const footerHeight = 54;
    const rowHeight = 31;
    return headerHeight + footerHeight + rows * rowHeight + 3;
  };

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  const buildLink = (cost: JCCommittedCost) => {
    const secretKey = process.env['NX_CRYPTO_PUBLIC_KEY'] ?? '';

    const cipherCompany = CryptoJS.AES.encrypt(
      selectedCompany!.id,
      secretKey
    ).toString();
    const cipherJob = CryptoJS.AES.encrypt(cost.Job, secretKey).toString();
    const cipherSubcontract = CryptoJS.AES.encrypt(
      cost.SL,
      secretKey
    ).toString();

    const encodJob = encodeURIComponent(cipherJob);
    const encodSL = encodeURIComponent(cipherSubcontract);
    const encodComp = encodeURIComponent(cipherCompany);

    const url = `company=${encodComp}&reportType=subcontractPayments&job=${encodJob}&subcontract=${encodSL}`;
    return url;
  };

  let totOrig = 0;
  let totRem = 0;
  let totInv = 0;

  costs.forEach((cost) => {
    totOrig += cost.OrigCost;
    totRem += cost.RemCost;
    totInv += cost.Invoiced;
  });

  return (
    <div className="pdfDivFitContent">
      <Table
        id="jc-committed-costs"
        data={costs}
        className={tableClass}
        calcHeight={useCallback(calcHeight, [])}
        onRowMouseEnter={useCallback(onRowHover, [])}
        stripedRows={false}
      >
        <Column
          field="VendorName"
          header="Vendor"
          body={(jc) => {
            return <div className="scroll-text">{jc.VendorName}</div>;
          }}
          style={{ minWidth: '225px', maxWidth: '225px' }}
          headerClassName={`${columnHeaderClass} font-normal text-center justify-content-center`}
          className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="PO"
          header="PO"
          body={(jc) => {
            return <div className="scroll-text">{jc.PO}</div>;
          }}
          style={{ minWidth: '225px', maxWidth: '225px' }}
          headerClassName={`${columnHeaderClass} font-normal text-center justify-content-center`}
          className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="POItem"
          header="PO Item"
          style={{ minWidth: '110px', maxWidth: '110px' }}
          headerClassName={`${columnHeaderClass} font-normal text-center`}
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="SL"
          header="SL"
          body={(jc) => {
            return (
              <Link
                to={`/accounting-dashboard/subcontracts?${buildLink(jc)}`}
                className="flex gap-1 align-items-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="scroll-text totalColor cursor-pointer hover:underline">
                  {jc.SL}
                </div>
              </Link>
            );
          }}
          style={{ minWidth: '125px', maxWidth: '125px' }}
          headerClassName={`${columnHeaderClass} font-normal text-center justify-content-center`}
          className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="SLItem"
          header="SL Item"
          style={{ minWidth: '100px', maxWidth: '100px' }}
          headerClassName={`${columnHeaderClass} font-normal`}
          className="justify-content-center text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName="tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
          footer="Total"
          sortable
        />
        <Column
          field="OrigCost"
          header="Original Cost"
          body={(jc) => formatCurrency(jc.OrigCost)}
          headerClassName={`font-normal ${columnHeaderClass} justify-content-center`}
          style={{ minWidth: '140px' }}
          className={`text-standard blackText tableCell justify-content-end`}
          footer={formatCurrency(totOrig)}
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          sortable
        />
        <Column
          field="RemCost"
          header="Remaining Cost"
          body={(jc) => formatCurrency(jc.RemCost)}
          headerClassName={`font-normal ${columnHeaderClass} justify-content-center`}
          style={{ minWidth: '160px' }}
          className={`text-standard blackText tableCell justify-content-end`}
          footer={formatCurrency(totRem)}
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          sortable
        />
        <Column
          field="Invoiced"
          header="Invoiced"
          body={(jc) => formatCurrency(jc.Invoiced)}
          headerClassName={`font-normal ${columnHeaderClass} justify-content-center`}
          style={{ minWidth: '120px' }}
          className={`text-standard blackText tableCell justify-content-end`}
          footer={formatCurrency(totInv)}
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          sortable
        />
      </Table>
    </div>
  );
});

const transactionsAreEqual = (
  prevTransactions: Readonly<JCCommittedCostTableProps>,
  nextTransactions: Readonly<JCCommittedCostTableProps>
) => {
  return prevTransactions.costs === nextTransactions.costs;
};

export default React.memo(JCCommittedCostTable, transactionsAreEqual);
