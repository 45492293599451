import { ARContractResume } from '../app/Interfaces/Accounting/AccountsReceivables.interfaces';
import { JCJobSummary } from '../app/Interfaces/Accounting/JobsContracts.interface';

export const BASE_PDF_STYLES = `
  body.pdfBody .tablePrint {
    box-shadow: none !important;
    margin: 0 !important;
  }
  
  body.pdfBody .tablePrint.p-datatable > .p-datatable-wrapper {
    height: auto !important;
    max-height: none !important;
  }
  
  body.pdfBody .tablePrint.p-datatable-scrollable .p-datatable-tfoot {
    position: initial;
  }
  
  body.pdfBody .tablePrint.p-datatable-scrollable .p-datatable-thead {
    position: initial;
  }
  
  body.pdfBody .tablePrint .p-sortable-column-icon {
    display: none;
  }

  body.pdfBody .printHide {
    display: none !important;
  }

  body.pdfBody .printShow {
    display: inherit !important;
  }

  body.pdfBody .printBackground {
    background-color: white;
  }
  
  body.pdfBody .pdfDivFitContent {
    width: fit-content!important;
  }

  body.pdfBody .tablePrint tbody tr td {
    overflow-x: none !important;
    white-space: inherit !important;
    word-break: break-word !important;
  }
`;

export const getContractName = (
  selectedContract: string,
  contracts: ARContractResume[]
) => {
  const filteredContracts = contracts?.filter(
    (contract) => selectedContract === contract.Contract
  );

  const contract = filteredContracts.pop();

  return contract?.Description;
};

export const getJobDescription = (
  selectedJob: string,
  jobs: JCJobSummary[]
) => {
  const filteredJobs = jobs?.filter((job) => selectedJob === job.Job);

  const job = filteredJobs?.pop();

  return job?.Description;
};

export const getJobDescriptions = (
  selectedJobs: string[],
  jobs: JCJobSummary[]
) => {
  const filteredJobs = jobs?.filter((job) => selectedJobs.includes(job.Job));

  const jobNames: string[] = filteredJobs?.map((job) => job.Description);

  return jobNames?.join(', ');
};

export const getContractNames = (
  selectedContracts: string[],
  contracts: ARContractResume[]
) => {
  const filteredContracts = contracts?.filter((contract) =>
    selectedContracts.includes(contract.Contract)
  );

  const contractNames: string[] = filteredContracts?.map(
    (contract: ARContractResume) => contract.Description
  );

  return contractNames?.join(', ');
};
