import React, { useCallback } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import { Employee } from '../../../Interfaces/Accounting/Payroll.interface';
import { formatUTCDate } from '../../../../utils/formatUtils';

type EmployeeTableProps = {
  employees: Employee[];
};

const EmployeeTable = React.forwardRef<HTMLDivElement, EmployeeTableProps>(
  ({ employees }, ref) => {
    const calcHeight = (rows: number) => {
      const headerHeight = 49;
      const footerHeight = 0;
      const rowHeight = 31;
      return headerHeight + footerHeight + rows * rowHeight + 3;
    };

    const onRowHover = (e: DataTableRowMouseEventParams) => {
      const cell = e.originalEvent.target as HTMLElement;
      const row = cell.closest('tr') as HTMLElement;
      animateCellText(row);
    };

    let active = 0;
    let inactive = 0;
    employees.forEach((employee: Employee) => {
      if (employee.Status === 'Active') {
        active += 1;
      } else {
        inactive += 1;
      }
    });

    return (
      <div className="pdfDivFitContent">
        <Table
          id="pr-employees"
          ref={ref}
          data={employees}
          className={`mx-3 dashboardOptionShadow noFooter dobleHeader`}
          calcHeight={useCallback(calcHeight, [])}
          onRowMouseEnter={useCallback(onRowHover, [])}
          hideColumns={true}
        >
          <Column
            headerClassName={`tableHeader`}
            className="tableCell p-0 printHide"
            footerClassName="tableFooter "
          />
          <Column
            field="EmpID"
            header="Emp ID"
            style={{ minWidth: '100px' }}
            headerClassName="tableHeader font-normal text-center"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="LastName"
            header="Last Name"
            body={(pr) => {
              return <div className="scroll-text">{pr.LastName}</div>;
            }}
            style={{ minWidth: '180px' }}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className=" text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="FirstName"
            header="First Name"
            body={(pr) => {
              return <div className="scroll-text">{pr.FirstName}</div>;
            }}
            style={{ minWidth: '160px' }}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="Address"
            header="Address"
            body={(pr) => {
              return <div className="scroll-text">{pr.Address}</div>;
            }}
            style={{ minWidth: '300px', maxWidth: '300px' }}
            headerClassName="tableHeader font-normal justify-content-center"
            className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="HireDate"
            header="Hire Date"
            body={(pr) => formatUTCDate(pr.HireDate)}
            style={{ minWidth: '110px' }}
            headerClassName="tableHeader font-normal text-center"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="TermDate"
            header="Termination Date"
            body={(pr) => formatUTCDate(pr.TermDate)}
            style={{ minWidth: '110px' }}
            headerClassName="tableHeader font-normal text-center"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="Status"
            header="Status"
            style={{ minWidth: '100px' }}
            headerClassName="tableHeader font-normal text-center"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="Supervisor"
            header="Supervisor"
            style={{ minWidth: '140px', maxWidth: '140px' }}
            headerClassName="tableHeader font-normal text-center"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="Department"
            header="Department"
            style={{ minWidth: '180px', maxWidth: '180px' }}
            headerClassName="tableHeader font-normal text-center"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="Position"
            header="Position"
            style={{ minWidth: '160px', maxWidth: '160px' }}
            headerClassName="tableHeader font-normal text-center"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            headerClassName={`tableHeader`}
            className="tableCell p-0 printHide"
            footerClassName="tableFooter "
          />
        </Table>
        <div className="ml-auto w-full px-3 sm:px-0 sm:w-15rem mr-5 my-5 text-standard">
          <div className="flex justify-content-between">
            <span>Active Employees</span>
            <span id="activeEmp">{active}</span>
          </div>
          <div className="flex justify-content-between mt-2">
            <span>Inactive Employees</span>
            <span id="inactiveEmp">{inactive}</span>
          </div>
          <div className="flex justify-content-between mt-1 border-top-1 border-bottom-2">
            <span className="font-bold">Total</span>
            <span id="empTotal">{employees.length}</span>
          </div>
        </div>
      </div>
    );
  }
);

const transactionsAreEqual = (
  prevTransactions: Readonly<EmployeeTableProps>,
  nextTransactions: Readonly<EmployeeTableProps>
) => {
  return prevTransactions.employees === nextTransactions.employees;
};

export default React.memo(EmployeeTable, transactionsAreEqual);
