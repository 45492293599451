import * as XLSX from 'xlsx';

export const autoFitColumns = (worksheet: XLSX.WorkSheet) => {
  const ref = worksheet['!ref'];
  if (!ref) {
    return;
  }
  const [startLetter, endLetter] = ref.replace(/\d/, '').split(':');
  const numRegexp = new RegExp(/\d+$/g);
  const start = startLetter.charCodeAt(0);
  const end = endLetter.charCodeAt(0) + 1;
  const regexResult = numRegexp.exec(endLetter);
  const rows = regexResult ? +regexResult[0] : 0;
  const ranges: number[] = [];

  for (let i = start; i < end; i++) {
    ranges.push(i);
  }
  const objectMaxLength: XLSX.ColInfo[] = [];
  ranges.forEach((c) => {
    const cell = String.fromCharCode(c);
    let maxCellLength = 0;
    for (let y = 1; y <= rows; y++) {
      const wsCell = worksheet[`${cell}${y}`];
      const cellLength = String(wsCell ? wsCell.v : '').length + 1;
      if (cellLength > maxCellLength) {
        maxCellLength = cellLength;
      }
    }
    objectMaxLength.push({ width: maxCellLength });
  });
  worksheet['!cols'] = objectMaxLength;
};

export const excelCleanTable = (element: HTMLElement) => {
  const removableElements = element.getElementsByClassName('printHide');
  const tableBody = element.getElementsByClassName('p-datatable-tbody')[0];

  const titles = tableBody.querySelectorAll('td > .p-column-title');
  Array.from(titles).forEach(function (title) {
    title.remove();
  });

  Array.from(removableElements).forEach(function (removeableElement) {
    removeableElement.remove();
  });

  return element;
};

export const excelFormatNestedRows = (element: HTMLElement) => {
  const headers = element.querySelectorAll('.p-datatable-row-expansion th');

  if (headers.length > 0) {
    Array.from(headers).forEach(function (header) {
      const td = document.createElement('td');
      const children = header.childNodes;

      Array.from(children).forEach(function (child) {
        td.append(child.textContent ?? '');
      });

      td.setAttribute('class', header.className);
      header.replaceWith(td);
    });
  }

  const expandedRows = element.getElementsByClassName(
    'p-datatable-row-expansion'
  );
  Array.from(expandedRows).forEach(function (expandedRow) {
    const parent = expandedRow.parentElement;
    const rows = expandedRow.getElementsByTagName('tr');

    Array.from(rows).forEach(function (row) {
      const description = row.getElementsByClassName('doubleColumn')[0];
      description?.setAttribute('colspan', '2');
      parent?.insertBefore(row, expandedRow);
    });

    expandedRow.remove();
  });
};

export const getNextColumn = (column: string, step: number) => {
  let columnArray = column.split('').map((char) => char.charCodeAt(0) - 64);
  let value = 0;
  for (let i = 0; i < columnArray.length; i++) {
    value = value * 26 + columnArray[i];
  }
  value += step;

  let nextColumn = '';
  while (value > 0) {
    let remainder = value % 26;
    if (remainder === 0) {
      remainder = 26;
      value -= 26;
    }
    nextColumn = String.fromCharCode(remainder + 64) + nextColumn;
    value = Math.floor(value / 26);
  }

  return nextColumn;
};
