import { formatCurrency } from 'apps/tmr-frontend/src/utils/formatUtils';
import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import WrapperButton from '../../../../components/buttons/WrapperButton';
import FormControlledInput from '../../../../components/inputs/FormInputs/FormControlledInput';
import { POExtra } from '../../../../Interfaces/Accounting/ACForms.interfaces';
import ExtraRow from './ExtraRow';

type PieceWorkRowProps = {
  fieldIndex: number;
  remove: (index: number) => void;
};

const PieceWorkRow = ({ fieldIndex, remove }: PieceWorkRowProps) => {
  const [total, setTotal] = useState(0);
  const desc = useWatch({
    name: `pieceWork.${fieldIndex}.description`,
  }) as string;

  const extraRows = useWatch({
    name: `pieceWork.${fieldIndex}.extras`,
  }) as POExtra[];

  useEffect(() => {
    let acc = 0;
    extraRows.forEach((extra) => (acc += extra.total ?? 0));

    setTotal(acc);
  }, [extraRows]);

  return (
    <div>
      <div className="flex flex-wrap">
        <div className="col-9 sm:col-6 md:col-5 lg:col-3 pb-0">
          <label
            htmlFor={`pieceWork.${fieldIndex}.description`}
            className="text-standard block mb-2"
          >
            Item Description
          </label>
          <FormControlledInput
            formID={`pieceWork.${fieldIndex}.description`}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: 'Description is a required field',
              },
            }}
            isFieldArray={true}
          />
        </div>
        <div className="col-1 flex mt-1 pb-0">
          <WrapperButton
            onClick={() => remove(fieldIndex)}
            className="h-fit mx-auto mt-5"
            type="button"
          >
            <i className="pi pi-trash text-17px p-1 border-circle hover:bg-gray-300" />
          </WrapperButton>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="col-12 pt-0">
          {extraRows?.map((extra, index) => {
            return (
              !!extra.id && (
                <ExtraRow
                  key={extra.id}
                  pieceWorkIndex={fieldIndex}
                  fieldIndex={index}
                />
              )
            );
          })}
        </div>
        <div className="col-12 sm:col-11 pt-0 text-right">
          <div className="mr-3 sm:mr-2">
            <span className="font-bold mr-3">{desc} total:</span>
            {!!total && formatCurrency(total)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PieceWorkRow;
