import { Steps, StepsSelectParams } from 'primereact/steps';
import { useFormContext, UseFormTrigger } from 'react-hook-form';
import { NewContractFields } from '../../../Interfaces/Accounting/ACForms.interfaces';
import { CONTRACT_FORM_FIELDS } from '../formConsts';

export const checkBeforeStep = async (
  index: number,
  checkErrors: UseFormTrigger<NewContractFields>,
  endIndex?: number
) => {
  let result = await checkErrors(
    CONTRACT_FORM_FIELDS[index as keyof typeof CONTRACT_FORM_FIELDS]
  );

  for (let i = index + 1; i <= (endIndex || 0); i++) {
    result =
      result &&
      (await checkErrors(
        CONTRACT_FORM_FIELDS[i as keyof typeof CONTRACT_FORM_FIELDS]
      ));
  }

  return result;
};

type ContractFormSteps = {
  index: number;
  changeIndex: (number: number) => void;
};

const ContractFormSteps = ({ index, changeIndex }: ContractFormSteps) => {
  const { trigger } = useFormContext<NewContractFields>();

  const checkIndexChange = async (newIndex: number, currentIndex: number) => {
    if (newIndex <= currentIndex) {
      changeIndex(newIndex);
      return;
    }

    const shouldChange = await checkBeforeStep(
      currentIndex,
      trigger,
      newIndex - 1
    );
    if (shouldChange) {
      changeIndex(newIndex);
    }
  };

  const items = [
    {
      label: 'Contract Set-up',
      command: (event: StepsSelectParams) => {
        changeIndex(event.index);
      },
    },
    {
      label: 'Job Set-up',
      command: (event: StepsSelectParams) => {
        checkIndexChange(event.index, index);
      },
    },
    {
      label: 'General Information',
      command: (event: StepsSelectParams) => {
        checkIndexChange(event.index, index);
      },
    },
    {
      label: 'Contract Attachments',
      command: (event: StepsSelectParams) => {
        checkIndexChange(event.index, index);
      },
    },
  ];

  return (
    <Steps
      model={items}
      activeIndex={index}
      readOnly={false}
      className="steps-db-bg"
    />
  );
};

export default ContractFormSteps;
