import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import { fetchSCItemPayments } from '../../../services/SubcontractsService';
import SCItemPaymentsTable from './SCItemPaymentsTable';

type SCItemPaymentsContainerProps = {
  sl: string;
  slItem: number;
};

const SCItemPaymentsContainer = React.forwardRef<
  HTMLDivElement,
  SCItemPaymentsContainerProps
>(({ sl, slItem }, ref) => {
  const { selectedCompany } = useCompanyContext();
  const { data, isFetching, isError } = useQuery({
    queryKey: ['getSCSubcontractItemPayments', selectedCompany, sl, slItem],
    queryFn: ({ signal }) =>
      fetchSCItemPayments(selectedCompany!.id, sl, slItem, signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  if (isFetching || !selectedCompany)
    return (
      <div className="text-center mx-auto mt-3">
        <ProgressSpinner />
      </div>
    );

  if (isError)
    return (
      <ErrorMessage
        content={'Failed to obtain data! Please try again later.'}
      />
    );

  return <SCItemPaymentsTable payments={data} />;
});

export default SCItemPaymentsContainer;
