import { BASE_PDF_STYLES } from '../../../../utils/reportUtils';
import React from 'react';
import ReportButton from '../../../components/report/ReportButton';

const pdfStyles = `
${BASE_PDF_STYLES}

body.pdfBody .drilldownHeader {
    margin-left: auto!important;
  }

`;

type APDrilldownReportProps = {
  elementRef: React.MutableRefObject<HTMLElement | null>;
};

const APDrilldownReport = ({ elementRef }: APDrilldownReportProps) => {
  return (
    <ReportButton
      elementToPrintRef={elementRef}
      fileName="AP_Invoice_Report"
      pdfStyles={pdfStyles}
      noButton={true}
      hideExcel={true}
      isPdfPortrait={true}
      widthElementID="ap-drilldown"
      minWidth={800}
    />
  );
};

export default APDrilldownReport;
