import TooltipContent from '../../../components/messages/TooltipContent';
import { JCContractResume } from '../../../Interfaces/Accounting/JobsContracts.interface';

type JCFlagsTooltipProps = {
  contract: JCContractResume;
};

const JCFlagsTooltip = ({
  contract: { NetCashFlow, PJTDProfit, Contract },
}: JCFlagsTooltipProps) => {
  const flags = [
    NetCashFlow < 0 && 'net cash flow negative',
    PJTDProfit < 10 && 'jtd gross profit % under 10%',
  ].filter(Boolean);

  return (
    <div className="text-red-600 font-bold cursor-pointer">
      <TooltipContent
        label="!"
        content={`flags: ${flags.join(', ')}`}
        id={Contract.replace('-', '')}
      />
    </div>
  );
};

export default JCFlagsTooltip;
