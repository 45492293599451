import { formatCurrency } from '../../../../utils/formatUtils';
import { InvoiceFormItem } from '../../../Interfaces/Accounting/AccountsReceivables.interfaces';

type ARInvoiceTable = {
  items: InvoiceFormItem[];
  total: number;
  lastPage?: boolean;
};

const ARInvoiceTable = ({ items, total, lastPage }: ARInvoiceTable) => {
  return (
    <div className="ml-2 mr-7 pr-2">
      <div className="mt-4 flex flex-column">
        <div className="grid grid-nogutter border-top-2 border-bottom-1 border-x-2 font-bold">
          <div className="flex-grow-1"></div>
          <div className="col-6 w-61 pl-3 py-1 text-20px flex align-items-end">
            Service
          </div>
          <div className="text-center py-1 col-2 w-12-half border-left-1 text-20px px-6">
            Total Due
          </div>
        </div>
        {items.map((item: InvoiceFormItem) => (
          <div className="grid grid-nogutter border-x-2" key={item.Description}>
            <div className="flex-grow-1"></div>
            <div className="col-6 w-61 py-2 text-20px">{item.Description}</div>
            <div className="text-20px text-right py-2 col-3 w-12-half border-left-1 pr-1">
              {formatCurrency(item.WC)}
            </div>
          </div>
        ))}
        {!lastPage && <div className="border-1" />}
        {lastPage && (
          <div
            style={{ minHeight: '168px' }}
            className="grid grid-nogutter border-x-2 border-bottom-2 flex-grow-1"
          >
            <div className="flex-grow-1"></div>
            <div className="text-20px text-right pr-2 pt-2 col-4 w-29 border-left-1 border-top-1">
              Total Due This Invoice:
            </div>
            <div className="text-20px text-right pt-2 pr-1 col-3 w-12-half border-left-1 border-top-1 font-bold">
              {formatCurrency(total)}
              <div className="border-1 mr-1 ml-4" />
            </div>
          </div>
        )}
      </div>
      {lastPage && (
        <div className="grid grid-nogutter flex-grow-1">
          <div className="flex-grow-1"></div>
          <div className="text-20px font-bold text-right pr-2 pt-2 col-4 w-29">
            Total Amount Due:
          </div>
          <div className="text-20px text-right pt-2 col-3 w-12-half font-bold">
            <div className="pr-3"> {formatCurrency(total)}</div>
            <div className="border-1 mr-1 ml-5" />
            <div className="border-1 mr-1 ml-5 mt-1" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ARInvoiceTable;
