import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useState } from 'react';
import ResumeCard from '../../../components/cards/ResumeCard';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import { ApiError } from '../../../Interfaces/Generic.interfaces';
import { fetchSCScheduleDates } from '../../../services/SubcontractsService';
import SCSchedule from './SCSchedule';

const SCScheduleContainer = React.forwardRef<HTMLDivElement>((props, ref) => {
  const { selectedCompany } = useCompanyContext();
  const [errorMessage, setErrorMessage] = useState('');
  const { data, isLoading, isError, isSuccess } = useQuery({
    queryKey: ['getSCScheduleDates', selectedCompany],
    queryFn: ({ signal }) => fetchSCScheduleDates(selectedCompany!.id, signal),
    onError: (error: ApiError) => {
      setErrorMessage(error?.response?.data?.message);
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  return (
    <div className="w-fit">
      <ResumeCard>
        <div className="p-3 flex justify-content-center">
          {isLoading && <ProgressSpinner />}
          {isSuccess && <SCSchedule scheduleDates={data} />}
          {isError && (
            <ErrorMessage
              content={
                errorMessage || 'Failed to obtain data! Please try again later.'
              }
            />
          )}
        </div>
      </ResumeCard>
    </div>
  );
});

export default SCScheduleContainer;
