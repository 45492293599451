import React, { useState } from 'react';
import WrapperButton from '../../../components/buttons/WrapperButton';
import { Dialog } from 'primereact/dialog';
import { JCProjectedCost } from '../../../Interfaces/Accounting/JobsContracts.interface';
import { InputTextarea } from 'primereact/inputtextarea';

type JCNoteButtonProps = {
  cost: JCProjectedCost;
  onBlur: (phase: string, newValue?: string) => void;
  defaultValue?: string;
};

const JCNoteButton = ({ cost, onBlur, defaultValue }: JCNoteButtonProps) => {
  const [content, setContent] = useState(defaultValue);
  const [showNoteDialog, setShowNoteDialog] = useState(false);

  return (
    <React.Fragment>
      <span className="printMedia printShow">{content}</span>
      <WrapperButton
        onClick={() => {
          setShowNoteDialog(true);
        }}
        className="printHide"
      >
        <i
          className={`pi ${
            !!content ? 'pi-file-edit' : 'pi pi-pencil'
          } totalColor`}
        />
      </WrapperButton>
      <Dialog
        header="New Project Costs Note"
        visible={showNoteDialog}
        onHide={() => {
          setShowNoteDialog(false);
        }}
      >
        <div>
          <div className="mb-3 text-17px">
            <span className="font-bold">Phase: </span>
            {`${cost.Phase} - ${cost.Description}`}
          </div>
          <InputTextarea
            value={content}
            onChange={(e) => {
              setContent(e.target.value);
            }}
            onBlur={(e) => {
              setContent(e.target.value);
              onBlur(cost.Phase || '', e.target.value);
            }}
            rows={5}
            cols={60}
          />
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default JCNoteButton;
