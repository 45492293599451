import React from 'react';

type ResumeCardProps = {
  children: React.ReactNode;
};

const ResumeCard = ({ children }: ResumeCardProps) => {
  return (
    <div className="w-full h-full dashboardOptionShadow bg-white radius-15px">
      {children}
    </div>
  );
};

export default ResumeCard;
