import React from 'react';
import AppTopbar from '../features/appTopBar/AppTopbar';
import CashManagement from '../../assets/layout/images/icons/Icon map-accounting.svg';
import ITSupoort from '../../assets/layout/images/icons/Icon awesome-laptop.svg';
import Estimating from '../../assets/layout/images/icons/Intersection 1.svg';
import Insurance from '../../assets/layout/images/icons/Icon material-supervisor-account.svg';
import Investments from '../../assets/layout/images/icons/Icon awesome-chart-line.svg';
import Development from '../../assets/layout/images/icons/Icon ionic-ios-create.svg';
import Tax from '../../assets/layout/images/icons/Icon awesome-money-check-alt.svg';
import Asset from '../../assets/layout/images/icons/Icon awesome-money-bill-wave.svg';
import Budgeting from '../../assets/layout/images/icons/Icon awesome-balance-scale-left.svg';
import MenuOptionCard from '../components/cards/MenuOptionCard';

const menu = [
  { label: 'Accounting Dashboard', icon: CashManagement },
  { label: 'IT Support', icon: ITSupoort, disabled: true },
  { label: 'Estimating', icon: Estimating, disabled: true },
  { label: 'Insurance', icon: Insurance, disabled: true },
  { label: 'Investments', icon: Investments, disabled: true },
  { label: 'Development', icon: Development, disabled: true },
  { label: 'Tax Services', icon: Tax, disabled: true },
  { label: 'Asset Management', icon: Asset, disabled: true },
  { label: 'Management And Budgeting', icon: Budgeting, disabled: true },
];

const Dashboard = () => {
  return (
    <div className="layout-menu-static">
      <AppTopbar active={false} mode={'desktop'} />
      <div className="max-w-933px mx-auto content grid dashboard">
        <div className="grid row-gap-5 mt-8 pt-5">
          {menu.map((x) => (
            <div
              className="col-12 md:col-6 lg:col-4 flex justify-content-center"
              key={x.label}
            >
              <MenuOptionCard
                label={x.label}
                disabled={x.disabled}
                icon={x.icon}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
