import React, { useCallback, useState } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { formatCurrency, formatUTCDate } from '../../../../utils/formatUtils';
import {
  DataTableRowClickEventParams,
  DataTableRowMouseEventParams,
} from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import { CostDetail } from '../../../Interfaces/Accounting/JobsContracts.interface';
import JCAttachment from '../../../components/attachment/Attachment';
import APDrilldown from '../../accountsPayables/APDrilldown/APDrilldown';
import { Payable } from '../../../Interfaces/Accounting/AccountsPayables.interfaces';

type JCDetailTableProps = {
  costs: CostDetail[];
  drilldownStyles?: boolean;
};

const JCDetailTable = React.forwardRef<HTMLDivElement, JCDetailTableProps>(
  ({ costs, drilldownStyles }, ref) => {
    const [showDrilldown, setShowDrilldown] = useState(false);
    const [selectedPayable, setSelectedPayable] = useState<Payable>();
    const tableClass = drilldownStyles ? 'mt-5' : 'mx-3 dashboardOptionShadow';
    const columnHeaderClass = drilldownStyles
      ? 'DrilldownHeader'
      : 'tableHeader';

    const calcHeight = (rows: number) => {
      const headerHeight = 32;
      const footerHeight = 54;
      const rowHeight = 31;
      return headerHeight + footerHeight + rows * rowHeight + 3;
    };

    const onRowHover = (e: DataTableRowMouseEventParams) => {
      const cell = e.originalEvent.target as HTMLElement;
      const row = cell.closest('tr') as HTMLElement;
      animateCellText(row);
    };

    const rowClassName = (data: CostDetail) => {
      let className = data.APReference ? 'cursor-pointer' : '';
      if (!data.Mth) {
        className = `${className} font-bold ${
          data.CostType ? 'surface-300' : 'surface-400'
        }`;
      }

      return className;
    };

    const onRowClick = (e: DataTableRowClickEventParams) => {
      const cost: CostDetail = e.data;
      if (
        cost.APVendorCode &&
        cost.APTransCode &&
        cost.APReference &&
        cost.APInvoiceDate
      ) {
        const payableData = {
          VendorCode: cost.APVendorCode,
          TransCode: cost.APTransCode,
          Reference: cost.APReference,
          InvoiceDate: cost.APInvoiceDate,
          Vendor: cost.APVendor,
          Description: cost.APDescription,
          DueDate: cost.APDueDate,
        } as Payable;
        setSelectedPayable(payableData);
        setShowDrilldown(true);
      }
    };

    let totalHours = 0;
    let totalUnits = 0;
    let totalCosts = 0;

    costs.forEach((cost: CostDetail) => {
      if (cost.Mth) {
        totalHours = totalHours + cost.ActualHours;
        totalUnits = totalUnits + cost.ActualUnits;
        totalCosts = totalCosts + cost.ActualCost;
      }
    });

    return (
      <div className="pdfDivFitContent">
        {selectedPayable && (
          <APDrilldown
            visible={showDrilldown}
            setVisible={setShowDrilldown}
            payable={selectedPayable}
          />
        )}
        <Table
          id="jc-cost-detail"
          data={costs}
          className={tableClass}
          stripedRows={!drilldownStyles}
          calcHeight={useCallback(calcHeight, [])}
          onRowMouseEnter={useCallback(onRowHover, [])}
          rowClassName={useCallback(rowClassName, [])}
          onRowClick={useCallback(onRowClick, [])}
        >
          {!drilldownStyles && (
            <Column
              field="Phase"
              header="Phase Code"
              style={{ minWidth: '140px', maxWidth: '140px' }}
              headerClassName="tableHeader font-normal text-center"
              className="justify-content-center text-standard blackText tableCell"
              footerClassName="tableFooter"
              sortable
            />
          )}
          {!drilldownStyles && (
            <Column
              field="PhaseDescription"
              header="Phase Description"
              body={(jc) => {
                return <div className="scroll-text">{jc.PhaseDescription}</div>;
              }}
              style={{ minWidth: '250px', maxWidth: '250px' }}
              headerClassName="tableHeader font-normal justify-content-center"
              className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
              footerClassName="tableFooter"
              sortable
            />
          )}
          {!drilldownStyles && (
            <Column
              field="CostType"
              header="Cost Type"
              body={(jc) => {
                return <div className="scroll-text">{jc.CostType}</div>;
              }}
              style={{ minWidth: '150px', maxWidth: '150px' }}
              headerClassName="tableHeader font-normal justify-content-center"
              className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
              footerClassName="tableFooter"
              sortable
            />
          )}
          <Column
            field="Mth"
            header="Mth"
            body={(jc) => formatUTCDate(jc.Mth)}
            headerClassName={`font-normal ${columnHeaderClass}`}
            style={{ minWidth: '110px', maxWidth: '110px' }}
            className={`text-standard blackText tableCell justify-content-center`}
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="CostTrans"
            header="Trans"
            headerClassName={`font-normal ${columnHeaderClass}`}
            style={{ minWidth: '80px' }}
            className={`justify-content-center text-standard blackText tableCell`}
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="PostedDate"
            header="Posted"
            body={(jc) => formatUTCDate(jc.PostedDate)}
            headerClassName={`font-normal ${columnHeaderClass}`}
            style={{ minWidth: '120px' }}
            className={`justify-content-center text-standard blackText tableCell`}
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="ActualDate"
            header="Actual"
            body={(jc) => formatUTCDate(jc.ActualDate)}
            headerClassName={`font-normal ${columnHeaderClass}`}
            style={{ minWidth: '120px' }}
            className={`justify-content-center text-standard blackText tableCell`}
            footerClassName="tableFooter"
            sortable
          />
          {!drilldownStyles && (
            <Column
              field="Type"
              header="Type"
              headerClassName={`font-normal ${columnHeaderClass}`}
              style={{ minWidth: '80px', maxWidth: '80px' }}
              className={`text-standard blackText tableCell justify-content-center`}
              footerClassName="tableFooter"
              sortable
            />
          )}
          <Column
            field="Source"
            header="Source"
            body={(jc) => {
              return <div className="scroll-text">{jc.Source}</div>;
            }}
            headerClassName={`font-normal ${columnHeaderClass} justify-content-center`}
            style={{ minWidth: '120px' }}
            className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="Description"
            header="Description"
            body={(jc) => {
              return <div className="scroll-text">{jc.Description}</div>;
            }}
            headerClassName={`font-normal ${columnHeaderClass} justify-content-center`}
            style={{ minWidth: '200px', maxWidth: '200px' }}
            className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
            footer="Total"
            footerClassName="overflow-x-visible tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
            sortable
          />
          <Column
            field="ActualHours"
            header="Hours"
            body={(jc) => parseInt(jc.ActualHours)}
            headerClassName={`font-normal ${columnHeaderClass} justify-content-center`}
            style={{ minWidth: '100px', maxWidth: '100px' }}
            className={`text-standard blackText tableCell justify-content-end`}
            footer={totalHours}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            field="ActualUnits"
            header="Units"
            body={(jc) => parseInt(jc.ActualUnits)}
            headerClassName={`font-normal ${columnHeaderClass} justify-content-center`}
            style={{ minWidth: '100px', maxWidth: '100px' }}
            className={`text-standard blackText tableCell justify-content-end`}
            footer={totalUnits}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            field="ActualCost"
            header="Cost"
            body={(jc) => formatCurrency(jc.ActualCost)}
            headerClassName={`font-normal ${columnHeaderClass} justify-content-center`}
            style={{ minWidth: '150px' }}
            className={`text-standard blackText tableCell justify-content-end`}
            footer={formatCurrency(totalCosts)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            style={{ minWidth: '50px', maxWidth: '50px' }}
            body={(jc) =>
              jc.UniqueAttchID && (
                <JCAttachment
                  uniqueAttchID={jc.UniqueAttchID}
                  zipFilename={jc.APReference}
                />
              )
            }
            headerClassName={`font-normal ${columnHeaderClass} justify-content-center`}
            className="justify-content-center text-standard blackText tableCell printHide"
            footerClassName="tableFooter"
          />
        </Table>
      </div>
    );
  }
);

const transactionsAreEqual = (
  prevTransactions: Readonly<JCDetailTableProps>,
  nextTransactions: Readonly<JCDetailTableProps>
) => {
  return prevTransactions.costs === nextTransactions.costs;
};

export default React.memo(JCDetailTable, transactionsAreEqual);
