import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { useState } from 'react';
import FormErrorMessage from '../messages/FormErrorMessage';

type RejectionFieldProps = {
  onReject: () => void;
  onConfirm: (reason: string) => void;
};

const RejectionField = ({ onReject, onConfirm }: RejectionFieldProps) => {
  const [reason, setReason] = useState('');
  const [showError, setShowError] = useState(false);

  return (
    <div>
      <div className="mb-1">Are you sure you want to reject this draft?</div>
      <div>
        <label htmlFor="contractName" className="text-standard block mb-2">
          Please write the reason:
        </label>
        <InputTextarea
          value={reason}
          className={`text-standard ${showError && 'p-invalid'}`}
          onChange={(e) => {
            if (e.target.value) {
              setShowError(false);
            }

            setReason(e.target.value);
          }}
          rows={5}
          cols={45}
        />
        {showError && <FormErrorMessage errorMessage="Reason is required" />}
      </div>
      <div className="flex justify-content-end gap-2 mt-5 pb-2">
        <Button
          onClick={onReject}
          className="blackText font-bold transparent border-none hover:bg-gray-200 active:bg-gray-200 active:text-black-alpha-90 hover:text-black-alpha-90"
        >
          No
        </Button>
        <Button
          className="bluwaiBlue font-bold border-none mr-2"
          onClick={() => {
            if (!reason) {
              setShowError(true);
            } else {
              onConfirm(reason);
            }
          }}
        >
          Yes
        </Button>
      </div>
    </div>
  );
};

export default RejectionField;
