export const BACKGROUND_COLORS = [
  'rgba(255, 99, 132, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(255, 206, 86, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(255, 159, 64, 0.2)',
  'rgba(244, 67, 54, 0.2)',
  'rgba(63, 81, 181, 0.2)',
  'rgba(76, 175, 80, 0.2)',
  'rgba(255, 87, 34, 0.2)',
  'rgba(33, 150, 243, 0.2)',
  'rgba(255, 235, 59, 0.2)',
  'rgba(103, 58, 183, 0.2)',
  'rgba(255, 152, 0, 0.2)',
  'rgba(0, 188, 212, 0.2)',
  'rgba(139, 195, 74, 0.2)',
  'rgba(121, 85, 72, 0.2)',
  'rgba(233, 30, 99, 0.2)',
  'rgba(0, 150, 136, 0.2)',
  'rgba(255, 193, 7, 0.2)',
];

export const BORDER_COLORS = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(244, 67, 54, 1)',
  'rgba(63, 81, 181, 1)',
  'rgba(76, 175, 80, 1)',
  'rgba(255, 87, 34, 1)',
  'rgba(33, 150, 243, 1)',
  'rgba(255, 235, 59, 1)',
  'rgba(103, 58, 183, 1)',
  'rgba(255, 152, 0, 1)',
  'rgba(0, 188, 212, 1)',
  'rgba(139, 195, 74, 1)',
  'rgba(121, 85, 72, 1)',
  'rgba(233, 30, 99, 1)',
  'rgba(0, 150, 136, 1)',
  'rgba(255, 193, 7, 1)',
];
