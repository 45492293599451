export const getNewHeaderGroup = (
  headerJSX: JSX.Element,
  completeOptionList: string[],
  comparisonFunction: (headerTitle: string) => boolean
) => {
  const rows = headerJSX.props?.children;

  const newRows = rows.map((row: JSX.Element) => {
    const columns = row.props.children;

    const newCols = columns.filter(
      (col: JSX.Element) =>
        !col.props?.header ||
        !completeOptionList.includes(col.props?.header) ||
        comparisonFunction(col.props?.header)
    );

    const newRow = {
      ...row,
      props: { ...row.props, children: newCols },
    };

    return newRow;
  });

  const newHeaderColumns = {
    ...headerJSX,
    props: { ...headerJSX.props, children: newRows },
  };

  return newHeaderColumns;
};
