import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import ResumeCard from '../../../components/cards/ResumeCard';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import { fecthCBResume } from '../../../services/ContractBillingsService';
import CBResumeTable from './CBResumeTable';

const CBResumeTableContainer = () => {
  const { selectedCompany } = useCompanyContext();
  const { data, isLoading, isError, isSuccess } = useQuery({
    queryKey: ['getContractBillingsResume', selectedCompany],

    queryFn: ({ signal }) => {
      return fecthCBResume(selectedCompany!.id, signal);
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  return (
    <div className="w-full max-w-500px ml-auto">
      <ResumeCard>
        <div className="p-3 flex justify-content-center">
          {isLoading && <ProgressSpinner />}
          {isSuccess && <CBResumeTable resume={data} />}
          {isError && (
            <ErrorMessage
              content={'Failed to obtain data! Please try again later.'}
            />
          )}
        </div>
      </ResumeCard>
    </div>
  );
};

export default CBResumeTableContainer;
