import React, { useRef } from 'react';
import { JCCommittedCost } from '../../../Interfaces/Accounting/JobsContracts.interface';
import JCDrilldownReport from '../JCReport/JCDrilldownReport';
import JCCommittedCostTable from './JCCommittedCostTable';

type JCCommittedCostDrilldownProps = {
  costs: JCCommittedCost[];
  job: string;
  jobName: string;
  phase?: string;
  phaseDescription?: string;
};

const JCCommittedCostDrilldown = ({
  costs,
  job,
  jobName,
  phase,
  phaseDescription,
}: JCCommittedCostDrilldownProps) => {
  const drilldownRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      id="jc-committed-costs-drilldown"
      ref={drilldownRef}
      className="blackText text-standard printBackground w-full max-w-1500px pdfDivFitContent"
    >
      <div className="mt-3 px-4 flex justify-content-center sm:justify-content-between gap-3 flex-wrap capitalize">
        <div className="text-28px font-bold flex-grow-1 text-center sm:text-left">
          <div className="mx-auto w-fit sm:ml-0 drilldownHeader">
            JC Committed Costs Drilldown
          </div>
          <div className="text-23px mt-3 mr-auto w-fit">
            Job {job} {jobName}
          </div>
          {phase && phaseDescription && (
            <div className="text-23px mr-auto w-fit">
              Phase Code {phase} {phaseDescription}
            </div>
          )}
        </div>
        <div className="text-23px mx-auto md:ml-4 md:mr-2 mt-2 sm:mt-0 cursor-pointer printHide flex align-items-center">
          <JCDrilldownReport
            elementRef={drilldownRef}
            isPortrait={true}
            fileName="JC_Committed_Costs_Report"
            elementID="jc-committed-costs-drilldown"
            title="JC Committed Costs Drilldown"
            costDetail={{
              job,
              jobName,
              phase,
              phaseDescription,
            }}
          />
        </div>
      </div>
      <div className="my-4" />
      <JCCommittedCostTable costs={costs} />
    </div>
  );
};

export default JCCommittedCostDrilldown;
