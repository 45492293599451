import { useQuery } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ErrorToast, {
  showToast,
} from '../../../../components/messages/ErrorAlert';
import { useCompanyContext } from '../../../../context/CompanyContext';
import {
  POForm,
  POPieceWork,
  POPieceWorkForm,
} from '../../../../Interfaces/Accounting/ACForms.interfaces';
import {
  fetchJobCostCodes,
  fetchJobExtras,
  fetchUnitsOfMeasure,
} from '../../../../services/ACFormsService';
import PieceWorkRow from './PieceWorkRow';
import { v4 as uuidv4 } from 'uuid';
import LoadingButton from 'apps/tmr-frontend/src/app/components/inputs/LoadingButton';
import { formatCurrency } from 'apps/tmr-frontend/src/utils/formatUtils';
import { PO_COST_TYPES } from '../../formConsts';
import ConfirmationDialog from 'apps/tmr-frontend/src/app/components/messages/ConfirmationDialog';

type PieceWorkFormProps = {
  returnStep: () => void;
  isLoading: boolean;
  onSubmit: () => void;
};

const PieceWorkForm = ({
  returnStep,
  isLoading,
  onSubmit,
}: PieceWorkFormProps) => {
  const { selectedCompany } = useCompanyContext();
  const navigate = useNavigate();
  const [totalPO, setTotalPO] = useState<number>();
  const toastError = useRef<Toast>(null);
  const { control } = useFormContext<POPieceWorkForm>();
  const job = useWatch<POForm>({ name: 'job' }) as string;
  const poType = useWatch<POForm>({ name: 'poType' }) as string;
  const pieceWorks = useWatch({
    name: `pieceWork`,
  }) as POPieceWork[];
  const { fields, append, remove } = useFieldArray<POPieceWorkForm>({
    control,
    name: 'pieceWork',
    rules: {
      validate: (fieldArrayValues) => {
        const isEmpty = fieldArrayValues ? fieldArrayValues.length === 0 : true;
        if (isEmpty) {
          showToast(
            'error',
            toastError,
            'Piece Work Error',
            'Please submit at least one piece work',
            3000
          );
        }

        return !isEmpty;
      },
    },
  });

  useQuery({
    queryKey: ['getUnitOfMeasure'],
    queryFn: ({ signal }) => {
      return fetchUnitsOfMeasure(signal);
    },
    refetchOnWindowFocus: false,
  });

  useQuery({
    queryKey: ['getJCCostCodes', selectedCompany, job],
    queryFn: ({ signal }) => {
      return fetchJobCostCodes(
        selectedCompany!.id,
        job,
        PO_COST_TYPES[poType] ?? null,
        signal
      );
    },
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && job),
  });

  useQuery({
    queryKey: ['getJobExtras', selectedCompany, job],
    queryFn: ({ signal }) => {
      return fetchJobExtras(selectedCompany!.id, job, signal);
    },
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && job),
  });

  useEffect(() => {
    let acc = 0;
    pieceWorks.forEach((field) => {
      field.extras?.forEach((extra) => {
        acc += extra.total ?? 0;
      });
    });

    setTotalPO(acc);
  }, [pieceWorks]);

  return (
    <div className="mx-2">
      <ErrorToast toastRef={toastError} />
      <div className="flex">
        <Button
          className="ml-auto text-20px"
          onClick={() => {
            const id = uuidv4();
            append({
              description: '',
              extras: [{ id }],
            });
          }}
          type="button"
        >
          Add new
        </Button>
      </div>
      {fields?.map((field, index) => {
        return (
          <PieceWorkRow key={field.id} fieldIndex={index} remove={remove} />
        );
      })}
      <div className="col-12 sm:col-11 pt-0 text-right pr-4 sm:pr-3 mt-3">
        <span className="mr-1 font-bold">Total PO:</span>
        {!!totalPO && formatCurrency(totalPO)}
      </div>
      <div className="flex flex-wrap flex-row-reverse gap-2 mb-3 mt-5">
        <ConfirmationDialog
          Button={
            <LoadingButton
              isLoading={isLoading}
              label="Create PO"
              className="w-15rem bluwaiBlue border-0 flex m-auto py-3"
              fontSize="text-22px"
              type="button"
            />
          }
          onConfirm={onSubmit}
          message="Are you sure you want to submit the purchase order?"
        />
        <Button
          onClick={() => {
            returnStep();
          }}
          className="w-15rem buttonSecondary border-0 flex m-auto py-3"
          type="button"
        >
          <span className="mx-auto text-22px text-white">Previous</span>
        </Button>
        <Button
          onClick={() => {
            navigate('/accounting-dashboard/new');
          }}
          className="w-15rem bluwaiRed border-0 flex m-auto py-3"
          type="button"
        >
          <span className="mx-auto text-22px text-white">Cancel</span>
        </Button>
      </div>
    </div>
  );
};

export default PieceWorkForm;
