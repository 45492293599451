import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import APVendorDetailsTable from './APVendorDetailsTable';
import { fetchAccountsPayablesVendorInvoices } from '../../../services/vendorInvoiceService';

type APVendorDetailsTableContainerProps = {
  cmRef: string;
  cmAcct: number;
  vendor: string;
  vendorGroup: string;
};

const APVendorDetailsTableContainer = React.forwardRef<
  HTMLDivElement,
  APVendorDetailsTableContainerProps
>(({ cmRef, cmAcct, vendor, vendorGroup }, ref) => {
  const { selectedCompany } = useCompanyContext();
  const { data, isFetching, isError } = useQuery({
    queryKey: [
      'getAPVendorPaymentDetails',
      selectedCompany,
      cmRef,
      cmAcct,
      vendor,
      vendorGroup,
    ],
    queryFn: ({ signal }) =>
      fetchAccountsPayablesVendorInvoices(
        { company: selectedCompany!, cmRef, cmAcct, vendor, vendorGroup },
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  if (isFetching || !selectedCompany)
    return (
      <div className="text-center mx-auto mt-3">
        <ProgressSpinner className="h-auto w-6rem" />
      </div>
    );

  if (isError)
    return (
      <ErrorMessage
        content={'Failed to obtain data! Please try again later.'}
      />
    );

  return <APVendorDetailsTable payments={data} />;
});

export default APVendorDetailsTableContainer;
