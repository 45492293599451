import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ResumeCard from '../../../components/cards/ResumeCard';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import { ApiError } from '../../../Interfaces/Generic.interfaces';
import { fetchSCPeriodPayments } from '../../../services/SubcontractsService';
import SCResume from './SCResume';
import { SubcontractsResumeFilters } from './SCResumeFilter';

const SCResumeContainer = React.forwardRef<HTMLDivElement>((props, ref) => {
  const [errorMessage, setErrorMessage] = useState('');
  const methods = useForm<SubcontractsResumeFilters>({
    defaultValues: { groupType: 'vendor' },
  });
  const { watch } = methods;
  const groupType = watch('groupType');

  const { selectedCompany } = useCompanyContext();
  const { data, isFetching, isError } = useQuery({
    queryKey: ['getSCPeriodPayments', selectedCompany, groupType],
    queryFn: ({ signal }) =>
      fetchSCPeriodPayments(selectedCompany!.id, groupType, signal),
    onError: (error: ApiError) => {
      setErrorMessage(error?.response?.data?.message);
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  return (
    <div className="max-w-750px  w-full md:ml-auto">
      <FormProvider {...methods}>
        <ResumeCard>
          <div className="p-3">
            <SCResume
              payments={data?.payments}
              periodStart={data?.periodStart}
              periodEnd={data?.periodEnd}
              isLoading={isFetching}
            />
            {isError && (
              <ErrorMessage
                content={
                  errorMessage ||
                  'Failed to obtain data! Please try again later.'
                }
              />
            )}
          </div>
        </ResumeCard>
      </FormProvider>
    </div>
  );
});

export default SCResumeContainer;
