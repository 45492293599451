import { animateCellText } from '../../../../utils/htmlUtils';
import { MouseEvent } from 'react';
import { getFileCompleteName } from '../../../../utils/fileUtil';
import { formatDate } from '../../../../utils/formatUtils';
import OptionCard from '../../../components/cards/OptionCard';
import { FS_File } from '../../../Interfaces/Accounting/FinancialStatements.interface';
import RemoveFile from '../FileActions/RemoveFile';
import FileIcon from './FileIcon';
import DownloadFile from '../FileActions/DownloadFile';
import { useRolesAccessContext } from '../../../context/RolesAccessContext';
import PreviewFile from '../FileActions/PreviewFile';

type FileProps = {
  file: FS_File;
  folderID: string;
  refetchFiles?: () => void;
};

const File = ({ file, folderID, refetchFiles }: FileProps) => {
  const { rolesAcess } = useRolesAccessContext();
  const canEdit = rolesAcess?.find(
    (acess) => acess.report === 'financial_statements' && acess.editable
  );

  const onHover = (e: MouseEvent) => {
    const cell = e.target as HTMLElement;
    const divContainer = cell.closest('.fileBox') as HTMLElement;

    animateCellText(divContainer || cell);
  };

  return (
    <div
      className="w-15rem h-13rem text-center relative text-standard fileBox"
      onMouseEnter={onHover}
      role="button"
      tabIndex={0}
    >
      <OptionCard>
        <DownloadFile file={file} folderID={folderID} />
        {canEdit && (
          <RemoveFile
            folderID={folderID}
            file={file}
            afterRemoval={refetchFiles}
          />
        )}
        <div className="h-8rem flex">
          {file.extension?.toLocaleLowerCase() === 'pdf' ? (
            <PreviewFile folderID={folderID} file={file} />
          ) : (
            <FileIcon mimeType={file.mimeType} />
          )}
        </div>
        <div className="capitalize overflow-x-hidden white-space-nowrap checkOverflow">
          <div className="scroll-text">{getFileCompleteName(file)}</div>
        </div>
        <div className="mt-2">Upload: {formatDate(file.uploadedAt)}</div>
      </OptionCard>
    </div>
  );
};

export default File;
