import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import APOpenTable from './APOpenTable';
import { useFormContext } from 'react-hook-form';
import { fetchAccountsPayablesData } from '../../../services/AccountsPayablesService';
import { fetchAccountsPayablesFilters } from '../APFilters';

const APInvoiceArchiveContainer = React.forwardRef<HTMLDivElement>((ref) => {
  const { selectedCompany } = useCompanyContext();
  const { watch } = useFormContext<fetchAccountsPayablesFilters>();
  const filters = watch();
  const enabled =
    selectedCompany &&
    ((filters.vendors?.length ?? 0) > 0 || (filters.dates?.length ?? 0) > 0);

  const { data, isFetching, isError } = useQuery({
    queryKey: ['getAPPayables', selectedCompany, filters],
    queryFn: ({ signal }) =>
      fetchAccountsPayablesData(
        { ...filters, company: selectedCompany! },
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: !!enabled,
  });

  if (!enabled) {
    return (
      <div className="mt-4">
        <ErrorMessage
          severity="info"
          content={'To start the search please select a: Vendor or Date.'}
        />
      </div>
    );
  }

  if (isFetching)
    return (
      <div className="text-center mx-auto mt-3">
        <ProgressSpinner />
      </div>
    );

  if (isError)
    return (
      <ErrorMessage
        content={'Failed to obtain data! Please try again later.'}
      />
    );

  return <APOpenTable accountsPayables={data} isArchive={true} />;
});

export default APInvoiceArchiveContainer;
