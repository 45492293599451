import { SubcontractEntry } from 'apps/tmr-frontend/src/app/Interfaces/Accounting/ACForms.interfaces';
import { Panel } from 'primereact/panel';
import FormInfo from '../../../../components/messages/FormInfo';

type GeneralInfoSummaryProps = {
  subcontract: SubcontractEntry;
};

const GeneralInfoSummary = ({ subcontract }: GeneralInfoSummaryProps) => {
  const { job, vendor, description, sl, attachment } = subcontract;

  return (
    <Panel header="General Info" className="max-w-1200px mx-3 text-standard">
      <div className="grid">
        <div className="col-12 sm:col-6">
          <FormInfo label="Job" content={job.Job} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Job Name" content={job.JobName} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Vendor" content={vendor.name} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Vendor Short Name" content={vendor.sortName} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Descripcion" content={description} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Subcontract Name" content={sl} />
        </div>
        {attachment && (
          <div className="col-12 sm:col-6">
            <FormInfo label="Attachment" content={attachment} />
          </div>
        )}
      </div>
    </Panel>
  );
};

export default GeneralInfoSummary;
