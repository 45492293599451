import React, { useCallback } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { formatCurrency } from '../../../../utils/formatUtils';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import { ContractItems } from '../../../Interfaces/Accounting/JobsContracts.interface';

type ContractItemsTableProps = {
  items: ContractItems[];
  drilldownStyles?: boolean;
};

const ContractItemsTable = React.forwardRef<
  HTMLDivElement,
  ContractItemsTableProps
>(({ items, drilldownStyles }, ref) => {
  const tableClass = drilldownStyles ? 'mt-5' : 'mx-3 dashboardOptionShadow';
  const columnHeaderClass = drilldownStyles ? 'DrilldownHeader' : 'tableHeader';

  const calcHeight = (rows: number) => {
    const headerHeight = 32;
    const footerHeight = 54;
    const rowHeight = 31;
    return headerHeight + footerHeight + rows * rowHeight + 3;
  };

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  let contractAmnt = 0;
  let changed = 0;
  let current = 0;
  items.forEach((item) => {
    contractAmnt = contractAmnt + item.OrigContractAmt;
    changed = changed + item.ChangeOrder;
    current = current + item.ContractAmt;
  });

  return (
    <div className="pdfDivFitContent">
      <Table
        id="jc-contract-items"
        ref={ref}
        data={items}
        className={`${tableClass}`}
        calcHeight={useCallback(calcHeight, [])}
        onRowMouseEnter={useCallback(onRowHover, [])}
        stripedRows={!drilldownStyles}
      >
        <Column
          headerClassName={columnHeaderClass}
          className="tableCell p-0 printHide"
          footerClassName="tableFooter"
        />
        <Column
          field="Line"
          header="Line"
          style={{ minWidth: '100px', maxWidth: '100px' }}
          headerClassName={`${columnHeaderClass} font-normal text-center`}
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="Item"
          header="Item"
          body={(jc) => {
            return <div className="scroll-text">{jc.Item}</div>;
          }}
          style={{ minWidth: '300px', maxWidth: '300px' }}
          headerClassName={`${columnHeaderClass} font-normal justify-content-center`}
          className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName="overflow-x-visible tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
          footer="Total"
          sortable
        />
        <Column
          field="OrigContractAmt"
          header="Original Contract Amnt"
          body={(jc) => formatCurrency(jc.OrigContractAmt)}
          style={{ minWidth: '230px', maxWidth: '230px' }}
          headerClassName={`${columnHeaderClass} font-normal justify-content-center text-center`}
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(contractAmnt)}
          sortable
        />
        <Column
          field="ChangeOrder"
          header="Change Order"
          body={(jc) => formatCurrency(jc.ChangeOrder)}
          style={{ minWidth: '170px', maxWidth: '170px' }}
          headerClassName={`${columnHeaderClass} font-normal justify-content-center text-center`}
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(changed)}
          sortable
        />
        <Column
          field="ContractAmt"
          header="Current Contract Amnt"
          body={(jc) => formatCurrency(jc.ContractAmt)}
          style={{ minWidth: '230px', maxWidth: '230px' }}
          headerClassName={`${columnHeaderClass} font-normal justify-content-center text-center`}
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(current)}
          sortable
        />
        <Column
          headerClassName={columnHeaderClass}
          className="tableCell p-0 printHide"
          footerClassName="tableFooter "
        />
      </Table>
    </div>
  );
});

const transactionsAreEqual = (
  prevTransactions: Readonly<ContractItemsTableProps>,
  nextTransactions: Readonly<ContractItemsTableProps>
) => {
  return prevTransactions.items === nextTransactions.items;
};

export default React.memo(ContractItemsTable, transactionsAreEqual);
