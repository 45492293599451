import { CalendarProps } from 'primereact/calendar';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormErrorMessage from '../../messages/FormErrorMessage';
import CalendarStyled from './../StyledInputs/CalendarStyled';

type FormControlledCalendarProps = CalendarProps & {
  defaultValue?: string | Date;
  formID: string;
  rules?: object;
  triggerOnChange?: boolean;
};

const FormControlledCalendar = ({
  defaultValue,
  formID,
  rules,
  triggerOnChange,
  ...otherProps
}: FormControlledCalendarProps) => {
  const {
    control,
    formState: { errors },
    trigger,
  } = useFormContext();
  const fieldError = errors[formID];

  return (
    <React.Fragment>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={formID}
        rules={rules}
        render={({ field: { onChange, ...otherFields } }) => (
          <CalendarStyled
            error={!!fieldError}
            onChange={(e) => {
              onChange(e);
              if (triggerOnChange) {
                trigger(formID);
              }
            }}
            {...otherProps}
            {...otherFields}
          />
        )}
      />
      {fieldError && (
        <FormErrorMessage errorMessage={fieldError.message as string} />
      )}
    </React.Fragment>
  );
};

export default FormControlledCalendar;
