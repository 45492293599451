import { EXCEL_FORMATS, transformBToMB } from '../../../../../utils/fileUtil';
import { Controller, useFormContext } from 'react-hook-form';
import FormErrorMessage from '../../../../components/messages/FormErrorMessage';
import { POFileForm } from '../../../../Interfaces/Accounting/ACForms.interfaces';
import LoadingButton from '../../../../components/inputs/LoadingButton';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import POFileTemplate from './POFileTemplate';
import SingleFileInput from '../../../../components/inputs/StyledInputs/SingleFileInput';
import ConfirmationDialog from 'apps/tmr-frontend/src/app/components/messages/ConfirmationDialog';
import { FILE_SIZE_LIMIT } from 'apps/tmr-frontend/src/utils/fileConsts';

type POFileProps = {
  returnStep: () => void;
  isLoading: boolean;
  onSubmit: () => void;
};

const POFile = ({ returnStep, isLoading, onSubmit }: POFileProps) => {
  const navigate = useNavigate();
  const {
    control,
    formState: { errors },
  } = useFormContext<POFileForm>();

  return (
    <div>
      <div className="mb-3">
        <POFileTemplate />
      </div>
      <Controller
        name="poFile"
        aria-label="file-input"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'PO file is a required field',
          },
        }}
        render={({ field: { onChange, value } }) => (
          <div>
            <SingleFileInput
              value={value}
              onChange={onChange}
              acceptedFormats={EXCEL_FORMATS}
              totalLimit={FILE_SIZE_LIMIT}
              totalLimitLabel={`${transformBToMB(FILE_SIZE_LIMIT)} MB`}
              emptyMessage={`Upload PO file. Allowed extensions: ${EXCEL_FORMATS.join(
                ', '
              )}.`}
              contentClassName={errors['poFile'] && 'border-red-500'}
            />
            {errors['poFile'] && (
              <FormErrorMessage
                errorMessage={errors['poFile'].message as string}
              />
            )}
          </div>
        )}
      />
      <div className="flex flex-wrap flex-row-reverse gap-2 mb-3 mt-5">
        <ConfirmationDialog
          Button={
            <LoadingButton
              isLoading={isLoading}
              label="Create PO"
              className="w-15rem bluwaiBlue border-0 flex m-auto py-3"
              fontSize="text-22px"
              type="button"
            />
          }
          onConfirm={onSubmit}
          message="Are you sure you want to submit the purchase order?"
        />
        <Button
          onClick={() => {
            returnStep();
          }}
          className="w-15rem buttonSecondary border-0 flex m-auto py-3"
          type="button"
        >
          <span className="mx-auto text-22px text-white">Previous</span>
        </Button>
        <Button
          onClick={() => {
            navigate('/accounting-dashboard/new');
          }}
          className="w-15rem bluwaiRed border-0 flex m-auto py-3"
          type="button"
        >
          <span className="mx-auto text-22px text-white">Cancel</span>
        </Button>
      </div>
    </div>
  );
};

export default POFile;
