import { formatUTCDate } from '../../../../utils/formatUtils';
import React, { useRef } from 'react';
import {
  CostType,
  CostDetail,
} from '../../../Interfaces/Accounting/JobsContracts.interface';
import JCDetailTable from '../JCDetail/JCDetailTable';
import JCDrilldownReport from '../JCReport/JCDrilldownReport';

type jcDrilldownContentProps = {
  costs: CostDetail[];
  job: string;
  jobName: string;
  costType: CostType;
  phase?: string;
  phaseDescription?: string;
  startDate?: Date;
  endDate?: Date;
  dateType?: string;
};

const JCCostDrilldown = ({
  costs,
  job,
  jobName,
  costType,
  phase,
  phaseDescription,
  startDate,
  endDate,
  dateType,
}: jcDrilldownContentProps) => {
  const drilldownRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      id="jc-cost-drilldown"
      ref={drilldownRef}
      className="blackText text-standard printBackground w-full max-w-1500px pdfDivFitContent"
    >
      <div className="mt-3 px-4 flex justify-content-center sm:justify-content-between gap-3 flex-wrap capitalize">
        <div className="text-28px font-bold flex-grow-1 text-center sm:text-left">
          <div className="mx-auto w-fit sm:ml-0 drilldownHeader">
            JC Cost Detail Drilldown
          </div>
          <div className="text-23px mt-3 mr-auto w-fit">
            Job {job} {jobName}
          </div>
          {phase && phaseDescription && (
            <div className="text-23px mr-auto w-fit">
              Phase Code {phase} {phaseDescription}
            </div>
          )}
          {!!costType.CostType && (
            <div className="text-23px mr-auto w-fit">
              Cost Type {costType.CostType} - {costType.Description}
            </div>
          )}
          {startDate && (
            <div className="text-23px mr-auto w-fit">
              Start Date: {formatUTCDate(startDate)}
            </div>
          )}
          {endDate && (
            <div className="text-23px mr-auto w-fit">
              End Date: {formatUTCDate(endDate)}
            </div>
          )}
          {dateType && (
            <div className="text-23px mr-auto w-fit">{dateType} Cost</div>
          )}
        </div>
        <div className="text-23px mx-auto md:ml-4 md:mr-2 mt-2 sm:mt-0 cursor-pointer printHide flex align-items-center">
          <JCDrilldownReport
            elementRef={drilldownRef}
            isPortrait={true}
            fileName="JC_Cost_Drilldown_Report"
            elementID="jc-cost-drilldown"
            title="JC Drilldown"
            costType={costType}
            startDate={startDate}
            endDate={endDate}
            costDetail={{
              job,
              jobName,
              phase,
              phaseDescription,
              dateType,
            }}
          />
        </div>
      </div>
      <div className="my-4" />
      <JCDetailTable costs={costs} drilldownStyles={true} />
    </div>
  );
};

export default JCCostDrilldown;
