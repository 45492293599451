import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { useCompanyContext } from '../../../context/CompanyContext';
import GeneralInfoForm from '../../../features/acForms/PoEntry/FormElements/GeneralInfoForm';
import PieceWorkForm from '../../../features/acForms/PoEntry/FormElements/PieceWorkForm';
import POEntrySucess from '../../../features/acForms/PoEntry/SuccessElements/POEntrySucess';
import POSteps, {
  checkBeforeStep,
} from '../../../features/acForms/PoEntry/POSteps';
import {
  POArgs,
  POForm,
  POItem,
  POPieceWork,
} from '../../../Interfaces/Accounting/ACForms.interfaces';
import { postNewPO } from '../../../services/ACFormsService';
import { v4 as uuidv4 } from 'uuid';
import POFile from '../../../features/acForms/PoEntry/FormElements/POFile';

const PoEntry = () => {
  const { selectedCompany } = useCompanyContext();
  const [activeIndex, setActiveIndex] = useState(0);
  const toast = useRef<Toast>(null);
  const id = uuidv4();
  const formMethods = useForm<POForm>({
    defaultValues: {
      pieceWork: [{ description: '', extras: [{ id }] }],
      upType: 'file',
    },
  });
  const { handleSubmit, trigger, watch, resetField } = formMethods;
  const upType = watch('upType');

  const { mutate, isLoading, isSuccess, reset, data } = useMutation({
    mutationFn: (data: POArgs) => {
      return postNewPO(data);
    },
    onError: (error: AxiosError) => {
      const response = error.response;
      const data = response?.data as { message: string };

      showToast(
        'error',
        toast,
        'Create PO Error',
        data?.message || 'An error ocurred! please try again later',
        3000
      );
    },
  });

  const onSubmit = (data: POForm) => {
    const { upType, ...otherData } = data;
    const { poDate, poID, job, vendor, description } = otherData;
    const payload = {
      companyID: selectedCompany!.id,
      job,
      vendor,
      poDate,
      poDesc: description,
      po: poID,
    };

    if (upType === 'file') {
      mutate({
        ...payload,
        poFile: otherData.poFile,
      });
    } else {
      const { pieceWork } = otherData;

      const poItems = pieceWork.reduce((acc, pw) => {
        const items = pw
          .extras!.filter((extra) => !!extra.id)
          .map((extra) => {
            return {
              costCode: extra.costCode!,
              extraNumber: extra.extra!,
              pieceWorkDesc: pw.description!,
              unitOfMeasure: extra.um!,
              units: extra.quantity!,
              cost: extra.price!,
            };
          });

        return [...acc, ...items];
      }, [] as POItem[]);

      mutate({ ...payload, poItems });
    }
  };

  const changeIndex = (number: number) => {
    setActiveIndex(number);
  };

  const returnStep = () => {
    setActiveIndex((index) => index - 1);
  };

  const checkForm = async (index: number) => {
    const shouldChange = await checkBeforeStep(index, trigger);

    if (shouldChange) {
      setActiveIndex(index + 1);
    }
  };

  useEffect(() => {
    resetField('poFile');
    resetField('pieceWork');
  }, [upType, resetField]);

  useEffect(() => {
    setActiveIndex(0);
    reset();
    resetField('poFile');
    resetField('pieceWork');
  }, [selectedCompany, reset, resetField]);

  return (
    <div className="flex flex-column justify-content-center gap-5 pt-4">
      <div className="text-35px font-bold text-center mb-2 relative">
        New Purchase Order Entry
      </div>
      <FormProvider {...formMethods}>
        {!isSuccess && (
          <React.Fragment>
            <div className="w-screen max-w-900px mx-auto mb-2">
              <POSteps index={activeIndex} changeIndex={changeIndex} />
            </div>
            <form
              className={`${
                upType === 'manual' && activeIndex === 1
                  ? 'max-w-1200px'
                  : 'max-w-700px'
              }  w-full mx-auto flex flex-column gap-5`}
            >
              <div className={`${activeIndex !== 0 && 'hidden'}`}>
                <GeneralInfoForm checkForm={checkForm} />
              </div>
              {activeIndex === 1 && upType === 'manual' && (
                <div>
                  <PieceWorkForm
                    returnStep={returnStep}
                    isLoading={isLoading}
                    onSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              )}
              {upType === 'file' && (
                <div className={`${activeIndex !== 1 && 'hidden'}`}>
                  <POFile
                    returnStep={returnStep}
                    isLoading={isLoading}
                    onSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              )}
            </form>
          </React.Fragment>
        )}
        {isSuccess && <POEntrySucess poItems={data.poItems} />}
      </FormProvider>
      <ErrorToast toastRef={toast} />
    </div>
  );
};

export default PoEntry;
