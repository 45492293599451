import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCompanyContext } from '../../../context/CompanyContext';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { fetchJCBacklog } from '../../../services/JobsContractsService';
import Backlog from './Backlog';
import ResumeCard from '../../../components/cards/ResumeCard';

const BacklogContainer = React.forwardRef<HTMLDivElement>(() => {
  const { selectedCompany } = useCompanyContext();

  const { data, isLoading, isError, isSuccess } = useQuery({
    queryKey: ['getJCBacklog', selectedCompany],
    queryFn: ({ signal }) => {
      return fetchJCBacklog(selectedCompany!.id, signal);
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  return (
    <ResumeCard>
      <div className="p-2 flex justify-content-center">
        {(isLoading || !selectedCompany) && <ProgressSpinner />}
        {isSuccess && !isLoading && <Backlog months={data} />}
        {isError && (
          <ErrorMessage
            content={'Failed to obtain data! Please try again later.'}
          />
        )}
      </div>
    </ResumeCard>
  );
});

export default BacklogContainer;
