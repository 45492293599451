import {
  Calendar,
  CalendarChangeParams,
  CalendarValueType,
} from 'primereact/calendar';
import { useRef, useState } from 'react';

type CalendarIconProps = {
  showIcon: boolean;
  maxDate: Date;
  minDate: Date;
  onChange: (e: CalendarChangeParams) => void;
};

const CalendarIcon = ({
  showIcon,
  maxDate,
  minDate,
  onChange,
}: CalendarIconProps) => {
  const [dates, setDates] = useState<CalendarValueType>(null);
  const ref = useRef<HTMLElement>(null);
  const calendarRef = useRef<Calendar>(null);

  return (
    <div className={`mx-auto ${!showIcon && 'visibility-hidden'}`}>
      <i
        ref={ref}
        className="pi pi-calendar text-17px cursor-pointer relative"
        onClick={() => {
          if (showIcon) {
            calendarRef.current?.show();
          }
        }}
      />
      <Calendar
        value={dates}
        onChange={(e) => {
          setDates(e.value);
          const datesList = e.value as Date[];

          if (datesList.length === 2 && datesList[1] !== null) {
            calendarRef.current?.hide();
          }
          onChange(e);
        }}
        onHide={() => {
          setDates(null);
        }}
        panelClassName="left-auto top-100 right-0 w-16rem md:w-25rem text-center"
        inputClassName="hidden"
        ref={calendarRef}
        appendTo={ref.current}
        selectionMode="range"
        maxDate={maxDate}
        minDate={minDate}
      />
    </div>
  );
};

export default CalendarIcon;
