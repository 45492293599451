import { Toast, ToastProps, ToastSeverityType } from 'primereact/toast';
import { RefObject } from 'react';

type ErrorToastProps = ToastProps & {
  toastRef: RefObject<Toast>;
};

export const showToast = (
  type: ToastSeverityType,
  toastRef: RefObject<Toast>,
  title: string,
  content: string,
  time: number,
  closable?: boolean
) => {
  if (toastRef.current) {
    toastRef.current.show({
      severity: type,
      summary: title,
      detail: content,
      life: time,
      closable,
    });
  }
};

const ErrorToast = ({ toastRef, ...otherProps }: ErrorToastProps) => {
  return <Toast ref={toastRef} {...otherProps} />;
};

export default ErrorToast;
