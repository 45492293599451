import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCompanyContext } from '../../../context/CompanyContext';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { fetchJCContractAnalysis } from '../../../services/JobsContractsService';
import JCResume from './JCContractAnalysis';
import { useFormContext } from 'react-hook-form';
import { JobContractFilters } from '../JCFilters';

const JCContractAnalysisContainer = React.forwardRef<HTMLDivElement>(
  (props, ref) => {
    const { selectedCompany } = useCompanyContext();
    const { watch } = useFormContext<JobContractFilters>();
    const filters = watch();

    const { data, isFetching, isError } = useQuery({
      queryKey: [
        'getJCContractAnalysis',
        selectedCompany,
        filters.contractState,
        filters.contracts,
        filters.pms,
      ],
      queryFn: ({ signal }) => {
        return fetchJCContractAnalysis(
          selectedCompany!.id,
          filters.contractState,
          filters.contracts,
          filters.pms,
          signal
        );
      },
      refetchOnWindowFocus: false,
      enabled: !!selectedCompany,
    });

    if (isFetching || !selectedCompany)
      return (
        <div className="text-center mx-auto mt-3">
          <ProgressSpinner />
        </div>
      );

    if (isError)
      return (
        <ErrorMessage
          content={'Failed to obtain data! Please try again later.'}
        />
      );

    return <JCResume contracts={data} />;
  }
);

export default JCContractAnalysisContainer;
