import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCompanyContext } from '../../../context/CompanyContext';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { fetchJCJobPhases } from '../../../services/JobsContractsService';
import { useFormContext } from 'react-hook-form';
import { JobContractFilters } from '../JCFilters';
import JCJobPhasesTable from './JCJobPhasesTable';

const JCJobPhasesContainer = React.forwardRef<HTMLDivElement>((props, ref) => {
  const { selectedCompany } = useCompanyContext();
  const { watch } = useFormContext<JobContractFilters>();
  const filters = watch();
  const isEnabled = selectedCompany && filters.job;

  const { data, isFetching, isError } = useQuery({
    queryKey: ['getJCJobPhases', selectedCompany, filters.job, filters.info],
    queryFn: ({ signal }) => {
      return fetchJCJobPhases(
        {
          companyID: selectedCompany!.id,
          job: filters.job,
          hasCost: false,
          info: filters.info,
        },
        signal
      );
    },
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });

  if (!isEnabled) {
    return (
      <div className="mt-4">
        <ErrorMessage
          severity="info"
          content={'To start the search please select a Job.'}
        />
      </div>
    );
  }

  if (isFetching)
    return (
      <div className="text-center mx-auto mt-3">
        <ProgressSpinner />
      </div>
    );

  if (isError)
    return (
      <ErrorMessage
        content={'Failed to obtain data! Please try again later.'}
      />
    );

  return <JCJobPhasesTable phases={data} />;
});

export default JCJobPhasesContainer;
