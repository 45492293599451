import React, { useCallback } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import { formatCurrency } from '../../../../utils/formatUtils';
import { SCPaymentDetails } from '../../../Interfaces/Accounting/Subcontracts.interfaces';

type SCPaymentDetailsTableProps = {
  details: SCPaymentDetails[];
  showJob?: boolean;
};

const SCPaymentDetailsTable = React.forwardRef<
  HTMLDivElement,
  SCPaymentDetailsTableProps
>(({ details, showJob }, ref) => {
  const calcHeight = (rows: number) => {
    const headerHeight = 32;
    const footerHeight = 54;
    const rowHeight = 31;
    return headerHeight + footerHeight + rows * rowHeight + 3;
  };

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  const rowClassName = (data: SCPaymentDetails) => {
    if (!data.SLItem) {
      return `font-bold ${data.SL ? 'surface-300' :'surface-400'}`;
    }

    return '';
  };

  let total = 0;
  details.forEach((detail: SCPaymentDetails) => {
    if (detail.SLItem) {
      total += detail.Amount;
    }
  });

  return (
    <Table
      ref={ref}
      data={details}
      className={`standardHeader w-full`}
      calcHeight={useCallback(calcHeight, [])}
      onRowMouseEnter={useCallback(onRowHover, [])}
      rowClassName={useCallback(rowClassName, [])}
      stripedRows={false}
      hideColumns={true}
    >
      <Column
        field="SL"
        header="Row Labels"
        body={(sc) => {
          return <div className="scroll-text">{sc.SL}</div>;
        }}
        style={
          showJob
            ? { minWidth: '200px', maxWidth: '200px' }
            : { minWidth: '300px', maxWidth: '300px' }
        }
        headerClassName="DrilldownHeader font-normal justify-content-center text-center"
        className=" text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
        footerClassName="overflow-x-visible tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
        footer="Grand Total"
        sortable
      />
      <Column
        field="SLItem"
        header="SLItem"
        style={{ minWidth: '80px', maxWidth: '80px' }}
        headerClassName="DrilldownHeader font-normal"
        className="justify-content-center text-standard blackText tableCell"
        footerClassName="tableFooter block text-right border-top-2 mt-4"
        sortable
      />
      {showJob && (
        <Column
          field="Job"
          header="Job"
          style={{ minWidth: '100px' }}
          headerClassName="DrilldownHeader font-normal"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          sortable
        />
      )}
      <Column
        field="Phase"
        header="Phase"
        body={(sc) => {
          return <div className={`scroll-text mx-auto`}>{sc.Phase}</div>;
        }}
        style={{ minWidth: '160px', maxWidth: '160px' }}
        headerClassName="DrilldownHeader font-normal justify-content-center text-center"
        className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
        footerClassName="tableFooter block text-right border-top-2 mt-4"
        sortable
      />
      <Column
        field="Description"
        header="Description"
        body={(sc) => {
          return <div className="scroll-text">{sc.Description}</div>;
        }}
        style={{ minWidth: '220px', maxWidth: '220px' }}
        headerClassName="DrilldownHeader font-normal justify-content-center text-center"
        className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
        footerClassName="tableFooter block text-right border-top-2 mt-4"
        sortable
      />
      <Column
        field="Amount"
        header="Sum of Amount"
        body={(sc) => formatCurrency(sc.Amount)}
        style={{ minWidth: '180px', maxWidth: '180px' }}
        headerClassName="DrilldownHeader font-normal justify-content-center  text-center"
        className="justify-content-end text-standard blackText tableCell"
        footerClassName="tableFooter block text-right border-top-2 mt-4"
        footer={formatCurrency(total)}
        sortable
      />
    </Table>
  );
});

const transactionsAreEqual = (
  prevTransactions: Readonly<SCPaymentDetailsTableProps>,
  nextTransactions: Readonly<SCPaymentDetailsTableProps>
) => {
  return prevTransactions.details === nextTransactions.details;
};

export default React.memo(SCPaymentDetailsTable, transactionsAreEqual);
