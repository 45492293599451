import React from 'react';
import AppWrapper from './appWrapper';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CookiesProvider } from 'react-cookie';
import { FeatureFlagsProvider } from './context/featureFlags/FeatureFlagsContext';

const Index = () => {
  const queryClient = new QueryClient();
  return (
    <FeatureFlagsProvider>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <CookiesProvider>
            <AppWrapper />
          </CookiesProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </FeatureFlagsProvider>
  );
};

export default Index;
