import { useQuery } from '@tanstack/react-query';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import PdfItem from '../../../components/report/PdfItem';
import { useCompanyContext } from '../../../context/CompanyContext';
import { AIAFormItem } from '../../../Interfaces/Accounting/AccountsReceivables.interfaces';
import { fetchAIAFormData } from '../../../services/AccountsReceivablesService';
import AIAForm from './AIAForm';
import { MAXIMUM_CHARACTERS_PER_LINE, MAXIMUM_LINES } from './AIAFormConst';
import AIAFormTable from './AIAFormTable';

type ARAIAttachmentProps = {
  invoice: string;
  contract: string;
  setLoading: (loading: boolean) => void;
};

const ARAIAttachment = ({
  invoice,
  contract,
  setLoading,
}: ARAIAttachmentProps) => {
  const { selectedCompany } = useCompanyContext();
  const [showPdf, setShowPdf] = useState(false);
  const toast = useRef<Toast>(null);
  const toastBackError = useRef<Toast>(null);
  const pdfRef = useRef<HTMLDivElement | null>(null);

  const { data } = useQuery({
    queryKey: ['getARAIAFormData', selectedCompany, invoice, contract],
    queryFn: ({ signal }) => {
      return fetchAIAFormData(selectedCompany!, contract, invoice, signal);
    },
    refetchOnWindowFocus: false,
    onSuccess: () => {
      setShowPdf(true);
    },
    onError: () => {
      showToast(
        'error',
        toastBackError,
        'AIA Form Error',
        'An error ocurred, please try again later',
        3000
      );
      setLoading(false);
    },
  });

  const item = PdfItem({
    setLoading: setLoading,
    fileName: 'AIAForm',
    elementToPrintRef: pdfRef,
    toast: toast,
    pdfStyles: ` body.pdfBody .pdfShow {
        display: block !important;
      }
    
      body.pdfBody .printBackground {
        background-color: white;
      }`,
    widthElementID: 'pdfTest',
    format: 'Letter',
  });

  const buildItemsTable = () => {
    const items = data.items;

    const totals = {
      scheduledValue: 0,
      previous: 0,
      current: 0,
      materials: 0,
      total: 0,
      balance: 0,
      retainage: 0,
    };
    items.forEach((item: AIAFormItem) => {
      totals.scheduledValue =
        totals.scheduledValue + item.CurrContract + item.ChangeAmount;
      totals.previous = totals.previous + item.PrevAmt - item.PrevSM;
      totals.current = totals.current + item.AmtBilled - item.SM;
      totals.materials = totals.materials + item.SM + item.PrevSM;
      totals.total = totals.total + item.PrevAmt + item.AmtBilled;
      totals.balance =
        totals.balance +
        item.CurrContract +
        item.ChangeAmount -
        item.PrevAmt -
        item.AmtBilled;
      totals.retainage =
        totals.retainage +
        item.PrevRetg +
        item.WCRetg +
        item.SMRetg -
        item.RetgRel;
    });

    const chunkedItems: AIAFormItem[][] = [];

    let tempList: AIAFormItem[] = [];
    let totalLines = 0;
    items.forEach((item: AIAFormItem) => {
      const characters = item.Description.length;
      const lines = Math.ceil(characters / MAXIMUM_CHARACTERS_PER_LINE);
      totalLines += lines;

      if (totalLines > MAXIMUM_LINES) {
        chunkedItems.push([...tempList]);
        tempList = [];
        totalLines = lines;
      }
      tempList.push(item);
    });
    chunkedItems.push([...tempList]);

    const pages = chunkedItems.map((itemsList, idx) => (
      <AIAFormTable
        key={idx}
        items={itemsList}
        page={idx + 2}
        totalPages={chunkedItems.length + 1}
        invoice={invoice}
        contractInfo={data.contractInfo}
        totals={idx + 1 === chunkedItems.length ? totals : undefined}
      />
    ));

    return pages;
  };

  useEffect(() => {
    if (showPdf) {
      const command = item.command;
      command();
      setShowPdf(false);
    }
  }, [showPdf, item.command]);

  return (
    <div>
      <ErrorToast toastRef={toastBackError} />
      <div>
        {showPdf && (
          <div
            ref={pdfRef}
            style={{
              paddingLeft: '24px',
              paddingRight: '24px',
              marginTop: '30px',
            }}
            id="pdfTest"
            className="w-fit hidden printBackground pdfShow"
          >
            <AIAForm
              totals={data.totals}
              changedOrders={data.changedOrders}
              contractInfo={data.contractInfo}
              invoice={invoice}
            />
            {buildItemsTable()}
          </div>
        )}
      </div>
    </div>
  );
};

export default ARAIAttachment;
