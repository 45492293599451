import { getFieldArrayError } from 'apps/tmr-frontend/src/utils/formUtils';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormErrorMessage from '../../messages/FormErrorMessage';
import DropdownStyled, {
  DropdownStyledProps,
} from './../StyledInputs/DropdownStyled';

type FormControlledDropdownProps = DropdownStyledProps & {
  defaultValue?: string | number | null;
  formID: string;
  rules?: object;
  additionalChange?: () => void;
  isFieldArray?: boolean;
  ignoreOnBlur?: boolean;
};

const FormControlledDropdown = ({
  defaultValue,
  formID,
  rules,
  additionalChange,
  isFieldArray,
  ignoreOnBlur,
  ...otherProps
}: FormControlledDropdownProps) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  let fieldError = errors[formID];
  if (isFieldArray) {
    fieldError = getFieldArrayError(formID, errors);
  }

  return (
    <React.Fragment>
      <Controller
        defaultValue={defaultValue}
        control={control}
        name={formID}
        rules={rules}
        render={({ field: { ref, onChange, onBlur, ...otherFields } }) => (
          <DropdownStyled
            id={formID}
            inputRef={ref}
            error={!!fieldError}
            onChange={(e) => {
              const value = e.value;
              if (value) {
                onChange(value);
              } else {
                setValue(formID, null);
              }

              if (additionalChange) {
                additionalChange();
              }
            }}
            onBlur={ignoreOnBlur ? undefined : onBlur}
            {...otherProps}
            {...otherFields}
          />
        )}
      />
      {fieldError && (
        <FormErrorMessage errorMessage={fieldError.message as string} />
      )}
    </React.Fragment>
  );
};

export default FormControlledDropdown;
