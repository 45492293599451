import { downloadFileUsingPath } from 'apps/tmr-frontend/src/utils/fileUtil';
import { Button } from 'primereact/button';

const SLFileTemplate = () => {
  const onClick = () => {
    downloadFileUsingPath('/assets/templates/ACForms/Subcontract Items Template.xlsx');
  };

  return (
    <Button
      className="w-16rem buttonSecondary border-0 flex py-3"
      onClick={onClick}
      type="button"
    >
      <span className="mx-auto text-17px text-white">Download Template</span>
    </Button>
  );
};

export default SLFileTemplate;
