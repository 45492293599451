import { useQuery } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import {
  DataTablePFSEvent,
  DataTableRowClickEventParams,
} from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import Table from '../../../components/Table/Table';
import { getRoles } from '../../../services/RolesService';

const Roles = () => {
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const [backupData, setBackupData] = useState({ roles: [], totalRoles: 0 });
  const [backupPage, setBackupPage] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsAmount, setRowsAmount] = useState(15);
  const { data, isFetching, isError } = useQuery({
    queryKey: ['getRoles', page, rowsAmount],
    queryFn: ({ signal }) => {
      return getRoles({ page, amountPerPage: rowsAmount }, signal);
    },
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setBackupData(data);
      setBackupPage(page);
    },
    onError: (error) => {
      showToast(
        'error',
        toast,
        'Search User Error',
        'An error ocurred! please try again later',
        3000
      );
    },
  });

  const onPage = (event: DataTablePFSEvent) => {
    setPage(event.page ?? 0);
  };

  const calcHeight = (rows: number) => {
    const headerHeight = 49;
    const footerHeight = 0;
    const rowHeight = 41;
    return headerHeight + footerHeight + rows * rowHeight + 2;
  };

  const onRowClick = (event: DataTableRowClickEventParams) => {
    navigate(event.data.id);
  };

  useEffect(() => {
    setPage(0);
  }, [rowsAmount]);

  return (
    <div className="pt-5 mx-3">
      <ErrorToast toastRef={toast} />
      <div className="mb-8 flex justify-content-between">
        <div className="font-bold text-35px">Roles</div>
        <Button
          onClick={() => {
            navigate('register');
          }}
          className="ml-5 h-fit buttonBackground text-white border-0 text-xl w-fit py-3 px-3 radius-10px"
        >
          New Role
        </Button>
      </div>
      <Table
        data={isError ? backupData.roles : data?.roles}
        first={(isError ? backupPage : page) * rowsAmount}
        className={`tableStyled tablePrint`}
        rowClass={() => 'cursor-pointer'}
        lazy
        paginator
        loading={isFetching}
        totalRecords={isError ? backupData.totalRoles : data?.totalRoles}
        onPage={onPage}
        calcHeight={calcHeight}
        defaultRowAmount={rowsAmount}
        onRowsAmountChange={setRowsAmount}
        onRowClick={onRowClick}
      >
        <Column
          field="name"
          header="Role Name"
          body={(role) => {
            return <div className="capitalize">{role.name}</div>;
          }}
          style={{ minWidth: '150px' }}
          headerClassName={`tableHeader font-normal`}
          className={`justify-content-center text-standard blackText tableCell`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="type"
          header="Role Type"
          style={{ minWidth: '150px' }}
          headerClassName={`tableHeader font-normal`}
          className={`justify-content-center text-standard blackText tableCell`}
          footerClassName="tableFooter"
          sortable
        />
      </Table>
    </div>
  );
};

export default Roles;
