import React, { useCallback, useRef } from 'react';
import { Column, ColumnEventParams } from 'primereact/column';
import Table from '../../../components/Table/Table';
import {
  formatCellTextToAmount,
  formatCurrency,
  formatPercentage,
} from '../../../../utils/formatUtils';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import { ContractProgressItem } from '../../../Interfaces/Accounting/ContractBillings.interface';
import CurrencyLabelInput from '../../../components/inputs/StyledInputs/CurrencyLabelInput';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { Toast } from 'primereact/toast';
import ColumnTextEditor from '../../../components/Table/ColumnTextEditor';
import { Access } from '../../../Interfaces/Role.interfaces';

type ContractProgressTableProps = {
  items: ContractProgressItem[];
  releasedRetainage?: number;
  isApproval?: boolean;
  access: Access;
  updateIsEditing?: (value: boolean) => void;
  updateBillingDraft?: (item: number, value?: number) => void;
  updateReleasedRetainage?: (retainage?: number) => void;
};

const ContractProgressTable = ({
  items,
  releasedRetainage,
  isApproval,
  access,
  updateIsEditing = (value: boolean) => {},
  ...updates
}: ContractProgressTableProps) => {
  const canEdit = !!access.editable || (access.shouldApprove && isApproval);
  const toast = useRef<Toast>(null);

  const calcHeight = (rows: number) => {
    const headerHeight = 32;
    const footerHeight = 54;
    const rowHeight = 31;
    return headerHeight + footerHeight + rows * rowHeight + 3;
  };

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  const onCellEditComplete = (e: ColumnEventParams) => {
    const { rowData, newValue, field } = e;

    try {
      const valueText: string = newValue?.toString();

      let isRemaining = false;
      if (valueText?.includes('%')) {
        const cleanedValue = parseFloat(valueText.replace('%', ''));
        const remainderPercentage =
          100 - Math.round(rowData.CompletedRatio * 10000) / 100;

        isRemaining = cleanedValue === remainderPercentage;
      }

      const amount = isRemaining
        ? rowData.BalanceRemaining
        : formatCellTextToAmount({
            text: valueText,
            limitAmount: rowData.BalanceRemaining,
            pTotal: rowData.ContractAmount,
            exceedErrorMessage:
              'The amount entered exceeds the Contract Amount',
          });

      const roundedAmount = Math.round(amount * 100) / 100;

      if (
        rowData.ContractAmount > 0 &&
        rowData.BilledAmount + roundedAmount < 0
      ) {
        throw Error('The total billed amount cannot be lower than 0.');
      } else if (
        rowData.ContractAmount < 0 &&
        rowData.BilledAmount + roundedAmount > 0
      ) {
        throw Error('The total billed amount cannot be higher than 0.');
      }

      rowData[field] = roundedAmount || null;

      const updateBillingDraft = updates.updateBillingDraft;
      if (!updateBillingDraft) {
        return;
      }

      const item = rowData.Item.trim();
      if (roundedAmount) {
        updateBillingDraft(item, roundedAmount);
      } else {
        updateBillingDraft(item);
      }
    } catch (e) {
      const error = e as { message: string };
      showToast('error', toast, 'Edit Amount Error', error.message, 3000);
    }

    updateIsEditing(false);
  };

  let contractAmount = 0;
  let billed = 0;
  let balance = 0;
  let retainage = 0;
  items.forEach((item: ContractProgressItem) => {
    contractAmount = contractAmount + item.ContractAmount;
    billed = billed + item.BilledAmount;
    balance = balance + item.BalanceRemaining;
    retainage = retainage + item.Retainage;
  });

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      <Table
        id="cb-contract-progress"
        data={items}
        className={`mx-3 dashboardOptionShadow`}
        calcHeight={useCallback(calcHeight, [])}
        onRowMouseEnter={useCallback(onRowHover, [])}
        hideColumns={true}
      >
        <Column
          headerClassName={`tableHeader`}
          className="tableCell p-0 printHide"
          footerClassName="tableFooter "
        />
        <Column
          field="Contract"
          header="Contract #"
          style={{ minWidth: '140px', maxWidth: '140px' }}
          headerClassName="tableHeader font-normal"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
        />
        <Column
          field="ContractName"
          header="Contract Name"
          body={(cb) => {
            return <div className="scroll-text">{cb.ContractName}</div>;
          }}
          style={{ minWidth: '250px', maxWidth: '250px' }}
          headerClassName="tableHeader font-normal justify-content-center"
          className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName="tableFooter"
        />
        <Column
          field="Item"
          header="Item"
          headerClassName={`tableHeader font-normal`}
          style={{ minWidth: '100px', maxWidth: '100px' }}
          className={`justify-content-center text-standard blackText tableCell`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="Description"
          header="Description"
          body={(cb) => {
            return <div className="scroll-text">{cb.Description}</div>;
          }}
          style={{ minWidth: '300px', maxWidth: '300px' }}
          headerClassName="tableHeader font-normal justify-content-center"
          className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName="overflow-x-visible tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
          footer="Total"
          sortable
        />
        <Column
          field="ContractAmount"
          header="Contract Amount"
          body={(cb) => formatCurrency(cb.ContractAmount)}
          style={{ minWidth: '160px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="justify-content-end text-standard blackText tableCell"
          footer={formatCurrency(contractAmount)}
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          sortable
        />
        <Column
          field="BilledAmount"
          header="Billed"
          body={(cb) => formatCurrency(cb.BilledAmount)}
          style={{ minWidth: '150px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="justify-content-end text-standard blackText tableCell"
          footer={formatCurrency(billed)}
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          sortable
        />
        <Column
          field="CompletedRatio"
          header="% Complet"
          body={(cb) => formatPercentage(cb.CompletedRatio * 100)}
          style={{ minWidth: '140px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatPercentage((billed / contractAmount || 0) * 100)}
          sortable
        />
        <Column
          field="BalanceRemaining"
          header="Balance to Bill"
          body={(cb) =>
            cb.BalanceRemaining ? formatCurrency(cb.BalanceRemaining) : '-'
          }
          style={{ minWidth: '160px' }}
          headerClassName="tableHeader font-normal text-center justify-content-center"
          className="justify-content-end text-standard blackText tableCell"
          footer={formatCurrency(balance)}
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          sortable
        />
        <Column
          field="NewBilling"
          header="Billing this Period"
          body={(cb) =>
            !canEdit || !cb.BalanceRemaining ? null : cb.NewBilling ? (
              <div
                className={`w-full text-right totalColor cursor-pointer printColor`}
              >
                {formatCurrency(cb.NewBilling)}
              </div>
            ) : (
              <div className="w-full cursor-pointer flex justify-content-center">
                <i className="pi pi-pencil totalColor printHide" />
              </div>
            )
          }
          style={{ minWidth: '160px' }}
          headerClassName="tableHeader font-normal text-center"
          className={`justify-content-center text-standard blackText tableCell`}
          editor={(options) => {
            if (canEdit && options.rowData.BalanceRemaining) {
              return (
                <ColumnTextEditor
                  id="TBillField"
                  {...options}
                  onFocus={() => {
                    updateIsEditing(true);
                  }}
                />
              );
            }

            return null;
          }}
          onCellEditComplete={(options) =>
            canEdit && options.rowData.BalanceRemaining
              ? onCellEditComplete(options)
              : null
          }
          footer={(e) => {
            const records = e.props.value;
            let total = 0;
            records.forEach((record: ContractProgressItem) => {
              const amount = record.NewBilling ?? 0;
              total = total + amount;
            });

            return total ? formatCurrency(total) : '';
          }}
          footerClassName="tableFooter block text-right border-top-2 mt-4"
        />
        <Column
          headerClassName={`tableHeader`}
          className="tableCell p-0 printHide"
          footerClassName="tableFooter "
        />
      </Table>
      <div className="ml-auto w-fit mr-5 my-5 flex flex-wrap justify-content-center align-items-center gap-3 text-standard">
        <div>Unreleased Retainage</div>
        <div id="unreleasedRetainage" className="w-11rem text-right">
          {formatCurrency(retainage)}
        </div>
        <div id="retainagePeriod" className="w-11rem h-2rem">
          <CurrencyLabelInput
            pTotal={retainage}
            limit={retainage}
            exceedErrorMessage="The amount entered exceeds the Unreleased Retainage"
            blockEdit={!canEdit}
            defaultValue={releasedRetainage}
            changeValue={updates.updateReleasedRetainage}
            onInputStart={() => {
              updateIsEditing(true);
            }}
            onInputEnd={() => {
              updateIsEditing(false);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const transactionsAreEqual = (
  prevTransactions: Readonly<ContractProgressTableProps>,
  nextTransactions: Readonly<ContractProgressTableProps>
) => {
  return prevTransactions.items === nextTransactions.items;
};

export default React.memo(ContractProgressTable, transactionsAreEqual);
