import React, { useCallback, useState } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import {
  formatCurrency,
  formatPercentage,
} from '../../../../utils/formatUtils';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import {
  CostDetailArgs,
  CostEstimates,
} from '../../../Interfaces/Accounting/JobsContracts.interface';
import JCCostDrilldownContainer from '../JCCostDrilldown/JCCostDrilldownContainer';
import WrapperButton from '../../../components/buttons/WrapperButton';
import { useRolesAccessContext } from '../../../context/RolesAccessContext';

type JCCostEstimateTableProps = {
  estimates: CostEstimates[];
  drilldownStyles?: boolean;
};

const JCCostEstimateTable = React.forwardRef<
  HTMLDivElement,
  JCCostEstimateTableProps
>(({ estimates, drilldownStyles }, ref) => {
  const { rolePhases } = useRolesAccessContext();
  const tableClass = drilldownStyles ? 'mt-5' : 'mx-3 dashboardOptionShadow';
  const columnHeaderClass = drilldownStyles ? 'DrilldownHeader' : 'tableHeader';
  const [selectedCost, setSelectedCost] = useState<CostDetailArgs>();
  const [showDrilldown, setShowDrilldown] = useState(false);

  const calcHeight = (rows: number) => {
    const headerHeight = 49;
    const footerHeight = 54;
    const rowHeight = 31;
    return headerHeight + footerHeight + rows * rowHeight + 3;
  };

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  const onCellClick = (cost: CostEstimates) => {
    if (rolePhases?.includes(cost.Phase)) {
      return;
    }

    setSelectedCost({
      job: cost.Job,
      jobName: cost.JobName,
      costType: cost.CostType,
      phase: cost.Phase,
      phaseDescription: cost.Description,
    });
    setShowDrilldown(true);
  };

  const hideDrilldown = () => {
    setSelectedCost(undefined);
    setShowDrilldown(false);
  };

  let estHours = 0;
  let estCost = 0;
  let actHours = 0;
  let actCost = 0;
  let balHours = 0;
  let balCost = 0;
  estimates.forEach((estimate) => {
    estHours = estHours + estimate.EstimateHours;
    estCost = estCost + estimate.EstimateCost;
    actHours = actHours + estimate.ActualHours;
    actCost = actCost + estimate.ActualCost;
    balHours = balHours + estimate.BalanceHours;
    balCost = balCost + estimate.BalanceCost;
  });

  return (
    <div className="pdfDivFitContent">
      {selectedCost && (
        <JCCostDrilldownContainer
          visible={showDrilldown}
          hideDrilldown={hideDrilldown}
          costDetail={{ ...selectedCost }}
        />
      )}
      <Table
        id="jc-cost-estimate"
        ref={ref}
        data={estimates}
        className={`${tableClass} dobleHeader hoverEffect`}
        calcHeight={useCallback(calcHeight, [])}
        onRowMouseEnter={useCallback(onRowHover, [])}
        stripedRows={!drilldownStyles}
      >
        <Column
          headerClassName={columnHeaderClass}
          className="tableCell p-0 printHide"
          footerClassName="tableFooter"
        />
        <Column
          field="Phase"
          header="Phase Code"
          style={{ minWidth: '120px' }}
          headerClassName={`${columnHeaderClass} font-normal text-center`}
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="Description"
          header="Phase Description"
          body={(jc) => {
            return <div className="scroll-text">{jc.Description}</div>;
          }}
          style={{ minWidth: '250px', maxWidth: '250px' }}
          headerClassName={`${columnHeaderClass} font-normal justify-content-center`}
          className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="CostTypeDescription"
          header="Cost Type"
          body={(jc) => {
            return <div className="scroll-text">{jc.CostTypeDescription}</div>;
          }}
          style={{ minWidth: '150px', maxWidth: '150px' }}
          headerClassName={`${columnHeaderClass} font-normal justify-content-center`}
          className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName="overflow-x-visible tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
          footer="Total"
          sortable
        />
        <Column
          field="EstimateHours"
          header="Estimate Hours"
          style={{ minWidth: '100px', maxWidth: '100px' }}
          headerClassName={`${columnHeaderClass} font-normal justify-content-center text-center bg-blue-300`}
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={estHours}
          sortable
        />
        <Column
          field="EstimateCost"
          header="Estimate Dollars"
          body={(jc) => formatCurrency(jc.EstimateCost)}
          style={{ minWidth: '160px' }}
          headerClassName={`${columnHeaderClass} font-normal justify-content-center text-center bg-blue-300`}
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(estCost)}
          sortable
        />
        <Column
          field="ActualHours"
          header="Actual Hours"
          body={(jc) => (
            <WrapperButton
              disabled={rolePhases?.includes(jc.Phase)}
              className={`w-full text-right printColor ${
                !rolePhases?.includes(jc.Phase) ? 'totalColor' : 'blackText'
              }`}
              onClick={() => onCellClick(jc)}
            >
              {jc.ActualHours}
            </WrapperButton>
          )}
          style={{ minWidth: '100px' }}
          headerClassName={`${columnHeaderClass} font-normal justify-content-center text-center bg-teal-300`}
          className="justify-content-end text-standard tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={actHours}
          sortable
        />
        <Column
          field="ActualCost"
          header="Actual Dollars"
          body={(jc) => (
            <WrapperButton
              disabled={rolePhases?.includes(jc.Phase)}
              className={`w-full text-right printColor ${
                !rolePhases?.includes(jc.Phase) ? 'totalColor' : 'blackText'
              }`}
              onClick={() => onCellClick(jc)}
            >
              {formatCurrency(jc.ActualCost)}
            </WrapperButton>
          )}
          style={{ minWidth: '160px' }}
          headerClassName={`${columnHeaderClass} font-normal justify-content-center text-center bg-teal-300`}
          className="justify-content-end text-standard tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(actCost)}
          sortable
        />
        <Column
          field="ActualRatio"
          header="Actual %"
          body={(jc) =>
            isNaN(jc.ActualRatio ?? NaN) ? (
              ''
            ) : (
              <span className={`${jc.ActualRatio > 1 && 'errorText'}`}>
                {formatPercentage(jc.ActualRatio * 100)}
              </span>
            )
          }
          style={{ minWidth: '100px' }}
          headerClassName={`${columnHeaderClass} font-normal justify-content-center text-center bg-teal-300`}
          className="justify-content-end text-standard tableCell blackText"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={
            estCost > 0 ? formatPercentage((actCost / estCost) * 100) : ''
          }
          sortable
        />
        <Column
          field="BalanceHours"
          header="Balance Hours"
          style={{ minWidth: '100px' }}
          headerClassName={`${columnHeaderClass} font-normal justify-content-center text-center bg-orange-300`}
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={balHours}
          sortable
        />
        <Column
          field="BalanceCost"
          header="Balance Dollars"
          body={(jc) => formatCurrency(jc.BalanceCost)}
          style={{ minWidth: '160px' }}
          headerClassName={`${columnHeaderClass} font-normal justify-content-center text-center bg-orange-300`}
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(balCost)}
          sortable
        />
        <Column
          headerClassName={columnHeaderClass}
          className="tableCell p-0 printHide"
          footerClassName="tableFooter "
        />
      </Table>
    </div>
  );
});

const transactionsAreEqual = (
  prevTransactions: Readonly<JCCostEstimateTableProps>,
  nextTransactions: Readonly<JCCostEstimateTableProps>
) => {
  return prevTransactions.estimates === nextTransactions.estimates;
};

export default React.memo(JCCostEstimateTable, transactionsAreEqual);
