import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FormControlledCalendar from '../../../components/inputs/FormInputs/FormControlledCalendar';
import FormControlledDropdown from '../../../components/inputs/FormInputs/FormControlledDropdown';
import FormControlledNumberInput from '../../../components/inputs/FormInputs/FormControlledNumberInput';
import { useCompanyContext } from '../../../context/CompanyContext';
import { GeneralInfo } from '../../../Interfaces/Accounting/ACForms.interfaces';
import { fetchContractGeneralInfo } from '../../../services/ACFormsService';

const GeneralInfoForm = () => {
  const { selectedCompany } = useCompanyContext();
  const { setValue } = useFormContext<GeneralInfo>();
  const contractValue = useWatch({ name: 'contractValue' });
  const targetCost = useWatch({ name: 'targetCost' });
  const estProfit = useWatch({ name: 'estProfit' });

  const { data, isLoading, isError } = useQuery({
    queryKey: ['getContractGeneralInfo', selectedCompany],
    queryFn: ({ signal }) =>
      fetchContractGeneralInfo(selectedCompany!.id, signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  useEffect(() => {
    const profit = contractValue - targetCost;

    if (profit) {
      setValue('estProfit', profit);
    } else {
      setValue('estProfit', undefined);
    }
  }, [setValue, contractValue, targetCost]);

  useEffect(() => {
    if (contractValue) {
      const profitPer = (estProfit || 0) / contractValue;
      const percentage = Math.round(profitPer * 10000) / 100;
      setValue('estProfitPercentage', percentage);
    } else {
      setValue('estProfitPercentage', undefined);
    }
  }, [setValue, estProfit, contractValue]);

  useEffect(() => {
    if (targetCost) {
      const profitPer = (estProfit || 0) / targetCost;
      const percentage = Math.round(profitPer * 10000) / 100;
      setValue('estCostMarkUp', percentage);
    } else {
      setValue('estCostMarkUp', undefined);
    }
  }, [setValue, estProfit, targetCost]);

  return (
    <div className="grid mx-3 justify-content-center">
      <div className="col-12 sm:col-6">
        <label htmlFor="bondCost" className="text-standard block mb-2">
          Estimated Bond Cost
        </label>
        <FormControlledNumberInput
          formID="bondCost"
          rules={{
            required: {
              value: true,
              message: 'Estimated Bond Cost is a required field',
            },
          }}
          min={0}
          mode="currency"
          currency="USD"
          locale="en-US"
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="bondPercentage" className="text-standard block mb-2">
          Bond Percentage
        </label>
        <FormControlledNumberInput
          defaultValue={2}
          formID="bondPercentage"
          rules={{
            required: {
              value: true,
              message: 'Bond Percentage is a required field',
            },
          }}
          suffix="%"
          min={0}
          max={100}
          maxFractionDigits={2}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="bondReqd" className="text-standard block mb-2">
          Bond Reqd
        </label>
        <FormControlledDropdown
          formID="bondReqd"
          rules={{
            required: {
              value: true,
              message: 'Bond Reqd is a required field',
            },
          }}
          options={['Yes', 'No']}
          placeholder="Select option"
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="dateOrdered" className="text-standard block mb-2">
          Date Ordered
        </label>
        <FormControlledCalendar
          formID="dateOrdered"
          readOnlyInput
          showButtonBar
          className="w-full"
          triggerOnChange={true}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="insurance" className="text-standard block mb-2">
          Insurance Cert Reqd
        </label>
        <FormControlledDropdown
          formID="insurance"
          rules={{
            required: {
              value: true,
              message: 'Insurance Cert Reqd is a required field',
            },
          }}
          options={['Yes', 'No']}
          placeholder="Select option"
        />
      </div>
      <div className="col-12 sm:col-6">
        <label
          htmlFor="insuranceDateOrdered"
          className="text-standard block mb-2"
        >
          Date Ordered
        </label>
        <FormControlledCalendar
          formID="insuranceDateOrdered"
          readOnlyInput
          showButtonBar
          className="w-full"
          triggerOnChange={true}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="contractValue" className="text-standard block mb-2">
          Contract Value
        </label>
        <FormControlledNumberInput
          formID="contractValue"
          rules={{
            required: {
              value: true,
              message: 'Contract Value is a required field',
            },
          }}
          min={0}
          mode="currency"
          currency="USD"
          locale="en-US"
        />
      </div>
      <div className="col-12 sm:col-6" />
      <div className="col-12 sm:col-6">
        <label htmlFor="supervision" className="text-standard block mb-2">
          Supervision & OH (included in job cost)
        </label>
        <FormControlledNumberInput
          formID="supervision"
          rules={{
            required: {
              value: true,
              message:
                'Supervision & OH (included in job cost) is a required field',
            },
          }}
          min={0}
          mode="currency"
          currency="USD"
          locale="en-US"
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="targetCost" className="text-standard block mb-2">
          Target Job Cost
        </label>
        <FormControlledNumberInput
          formID="targetCost"
          rules={{
            required: {
              value: true,
              message: 'Target Job Cost is a required field',
            },
          }}
          min={0}
          mode="currency"
          currency="USD"
          locale="en-US"
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="estProfit" className="text-standard block mb-2">
          Estimated Profit
        </label>
        <FormControlledNumberInput
          formID="estProfit"
          disabled={true}
          rules={{
            required: {
              value: true,
              message: 'Estimated Profit is a required field',
            },
          }}
          min={0}
          mode="currency"
          currency="USD"
          locale="en-US"
        />
      </div>
      <div className="col-12 sm:col-6">
        <label
          htmlFor="estProfitPercentage"
          className="text-standard block mb-2"
        >
          Estimated Profit %
        </label>
        <FormControlledNumberInput
          disabled={true}
          formID="estProfitPercentage"
          rules={{
            required: {
              value: true,
              message: 'Estimated Profit % is a required field',
            },
          }}
          suffix="%"
          min={0}
          max={100}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="estCostMarkUp" className="text-standard block mb-2">
          Estimated Cost Mark Up %
        </label>
        <FormControlledNumberInput
          disabled={true}
          formID="estCostMarkUp"
          rules={{
            required: {
              value: true,
              message: 'Estimated Cost Mark Up % is a required field',
            },
          }}
          suffix="%"
          min={0}
        />
      </div>
      <div className="col-12 sm:col-6" />
      <div className="col-12 sm:col-6">
        <label htmlFor="jobType" className="text-standard block mb-2">
          Job Type
        </label>
        <FormControlledDropdown
          formID="jobType"
          rules={{
            required: {
              value: true,
              message: 'Job Type is a required field',
            },
          }}
          options={data?.jobs}
          labelField="Display"
          valueField="DBValue"
          placeholder={
            isError ? 'Failed to load job types!' : 'Select job type'
          }
          isDisabled={isError}
          isLoading={isLoading}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="certified" className="text-standard block mb-2">
          Certified
        </label>
        <FormControlledDropdown
          formID="certified"
          rules={{
            required: {
              value: true,
              message: 'Certified is a required field',
            },
          }}
          options={data?.certified}
          labelField="Display"
          valueField="DBValue"
          placeholder={isError ? 'Failed to load options!' : 'Select option'}
          isDisabled={isError}
          isLoading={isLoading}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="ccip" className="text-standard block mb-2">
          CCIP
        </label>
        <FormControlledDropdown
          formID="ccip"
          rules={{
            required: {
              value: true,
              message: 'CCIP is a required field',
            },
          }}
          options={data?.ccip}
          labelField="Display"
          valueField="DBValue"
          placeholder={isError ? 'Failed to load options!' : 'Select option'}
          isDisabled={isError}
          isLoading={isLoading}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="dwellingUnits" className="text-standard block mb-2">
          Dwelling Units
        </label>
        <FormControlledNumberInput
          formID="dwellingUnits"
          rules={{
            required: {
              value: true,
              message: 'Dwelling Units is a required field',
            },
          }}
          min={0}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="metric1" className="text-standard block mb-2">
          Metric1
        </label>
        <FormControlledDropdown
          formID="metric1"
          rules={{
            required: {
              value: true,
              message: 'Metric1 is a required field',
            },
          }}
          options={data?.metric1}
          labelField="Display"
          valueField="DBValue"
          placeholder={isError ? 'Failed to load options!' : 'Select option'}
          isDisabled={isError}
          isLoading={isLoading}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="metric1Amt" className="text-standard block mb-2">
          Metric1 Amt
        </label>
        <FormControlledNumberInput
          formID="metric1Amt"
          rules={{
            required: {
              value: true,
              message: 'Metric1 Amt is a required field',
            },
          }}
          min={0}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="metric2" className="text-standard block mb-2">
          Metric2
        </label>
        <FormControlledDropdown
          formID="metric2"
          rules={{
            required: {
              value: true,
              message: 'Metric2 is a required field',
            },
          }}
          options={data?.metric2}
          labelField="Display"
          valueField="DBValue"
          placeholder={isError ? 'Failed to load options!' : 'Select option'}
          isDisabled={isError}
          isLoading={isLoading}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="metric2Amt" className="text-standard block mb-2">
          Metric2 Amt
        </label>
        <FormControlledNumberInput
          formID="metric2Amt"
          rules={{
            required: {
              value: true,
              message: 'Metric2 Amt is a required field',
            },
          }}
          min={0}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="heated" className="text-standard block mb-2">
          Heated Sq Ft Amt
        </label>
        <FormControlledNumberInput
          formID="heated"
          rules={{
            required: {
              value: true,
              message: 'Heated Sq Ft Amt is a required field',
            },
          }}
          min={0}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="parking" className="text-standard block mb-2">
          Parking Deck
        </label>
        <FormControlledDropdown
          formID="parking"
          rules={{
            required: {
              value: true,
              message: 'Parking Deck is a required field',
            },
          }}
          options={data?.parking}
          labelField="Display"
          valueField="DBValue"
          placeholder={isError ? 'Failed to load options!' : 'Select option'}
          isDisabled={isError}
          isLoading={isLoading}
        />
      </div>
      <div className="col-12 sm:col-6">
        <label htmlFor="fieldPS" className="text-standard block mb-2">
          Field PM/Super
        </label>
        <FormControlledDropdown
          formID="fieldPS"
          options={[
            ...(data?.employees || []),
            { Name: 'TBD', SortName: 'TBD' },
          ]}
          labelField="Name"
          valueField="SortName"
          placeholder={
            isError ? 'Failed to load employees!' : 'Select employee'
          }
          isDisabled={isError}
          isLoading={isLoading}
        />
      </div>
      <div className="col-12 sm:col-6" />
    </div>
  );
};

export default GeneralInfoForm;
