import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  Title,
} from 'chart.js';
import { AccountReceivableResume } from '../../../Interfaces/Accounting/AccountsReceivables.interfaces';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../../../../utils/formatUtils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};

type ARResumeProps = {
  resume: AccountReceivableResume;
};

const ARResume = ({ resume }: ARResumeProps) => {
  const labels = [
    resume.future === 0 ? null : 'Future',
    'Current',
    '30+ days',
    '60+ days',
    '90+ days',
    'Retainage',
  ];

  const amounts = [
    resume.future === 0 ? null : resume.future,
    resume.current,
    resume.over30,
    resume.over60,
    resume.over90,
    resume.retainage,
  ];

  const data = {
    labels: labels.filter((label) => label),
    datasets: [
      {
        data: amounts.filter((amount) => amount),
        backgroundColor: 'rgba(76,170,190,255)',
      },
    ],
  };

  return (
    <React.Fragment>
      <Bar
        options={options}
        data={data}
        className={`max-w-300px mx-auto sm:mx-0`}
      />
      <div
        className={`gap-3 flex-grow-1 sm:pr-4 flex flex-column text-standard`}
      >
        <div className="flex justify-content-between">
          <Link
            className="totalColor cursor-pointer hover:underline"
            to={`/accounting-dashboard/accounts-receivables?period=retainage&reportType=agedAnalysis`}
          >
            Retainage
          </Link>
          <div>{formatCurrency(resume.retainage)}</div>
        </div>
        <div className="flex justify-content-between">
          <Link
            className="totalColor cursor-pointer hover:underline"
            to={`/accounting-dashboard/accounts-receivables?period=91&reportType=agedAnalysis`}
          >
            90+ days
          </Link>
          <div>{formatCurrency(resume.over90)}</div>
        </div>
        <div className="flex justify-content-between">
          <Link
            className="totalColor cursor-pointer hover:underline"
            to={`/accounting-dashboard/accounts-receivables?period=90&reportType=agedAnalysis`}
          >
            60+ days
          </Link>
          <div>{formatCurrency(resume.over60)}</div>
        </div>
        <div className="flex justify-content-between">
          <Link
            className="totalColor cursor-pointer hover:underline"
            to={`/accounting-dashboard/accounts-receivables?period=60&reportType=agedAnalysis`}
          >
            30+ days
          </Link>
          <div>{formatCurrency(resume.over30)}</div>
        </div>
        <div className="flex justify-content-between">
          <Link
            className="totalColor cursor-pointer hover:underline"
            to={`/accounting-dashboard/accounts-receivables?period=30&reportType=agedAnalysis`}
          >
            Current
          </Link>
          <div>{formatCurrency(resume.current)}</div>
        </div>
        {!!resume.future && (
          <div className="flex justify-content-between">
            <Link
              className="totalColor cursor-pointer hover:underline"
              to={`/accounting-dashboard/accounts-receivables?period=future&reportType=agedAnalysis`}
            >
              Future
            </Link>
            <div>{formatCurrency(resume.future || 0)}</div>
          </div>
        )}
        <div className="flex justify-content-between border-top-2 border-top-solid totalBorder pt-1 mt-auto">
          <Link
            className="totalColor cursor-pointer hover:underline"
            to={`/accounting-dashboard/accounts-receivables?reportType=agedAnalysis`}
          >
            Total Due
          </Link>
          <div>
            {formatCurrency(
              resume.retainage +
                resume.over90 +
                resume.over60 +
                resume.over30 +
                resume.current +
                (resume.future || 0)
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ARResume;
