import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Button } from 'primereact/button';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import React, { useRef, useState } from 'react';
import firebaseErrorMessages from '../../components/errorMessages/firebase';
import ErrorToast, { showToast } from '../../components/messages/ErrorAlert';
import SuccessFormMessage from '../../components/messages/SuccessFormMessage';
import { postDeleteUsers } from '../../services/UsersService';

type DeleteUsersButtonProps = {
  selectedUsers: string[];
  afterDeletion: () => void;
};

const DeleteUsersButton = ({
  selectedUsers,
  afterDeletion,
}: DeleteUsersButtonProps) => {
  const [visible, setVisible] = useState(false);
  const toast = useRef<Toast>(null);

  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: (data: string[]) => {
      return postDeleteUsers(data);
    },
    onSuccess: () => {
      afterDeletion();
    },

    onError: (error: AxiosError) => {
      setVisible(false);
      const errorData = error.response?.data as { code: string };
      showToast(
        'error',
        toast,
        'Delete Users Error',
        firebaseErrorMessages[errorData?.code] ||
          'An error ocurred! please try again later',
        3000
      );
    },
  });

  const requestDeleteUsers = (users: string[]) => {
    const length = users.length;
    confirmDialog({
      message: `Do you want to remove ${length} ${
        length > 1 ? 'users' : 'user'
      }?`,
      header: 'Removal Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => {
        mutate(users);
        setVisible(true);
      },
    });
  };

  return (
    <React.Fragment>
      <Button
        className="h-fit w-fit py-3 px-3 bluwaiRed border-0 text-xl radius-10px"
        disabled={selectedUsers.length === 0}
        onClick={() => requestDeleteUsers(selectedUsers)}
      >
        Remove Users
      </Button>
      <ConfirmDialog />
      <Dialog
        header="Removal in progress"
        visible={visible}
        onHide={() => setVisible(false)}
        className="w-fit"
        contentClassName="flex justify-content-center"
      >
        {isLoading && <ProgressSpinner className="mx-auto" />}
        {isSuccess && (
          <SuccessFormMessage
            message={`The ${selectedUsers.length} ${
              selectedUsers.length > 1 ? 'users have' : 'user has'
            } been removed!`}
          />
        )}
      </Dialog>
      <ErrorToast toastRef={toast} />
    </React.Fragment>
  );
};

export default DeleteUsersButton;
