import { useQuery } from '@tanstack/react-query';
import { useFormContext, useWatch } from 'react-hook-form';
import WrapperButton from '../../../../components/buttons/WrapperButton';
import FormControlledDropdown from '../../../../components/inputs/FormInputs/FormControlledDropdown';
import FormControlledNumberInput from '../../../../components/inputs/FormInputs/FormControlledNumberInput';
import { useCompanyContext } from '../../../../context/CompanyContext';
import {
  JobCostCode,
  POExtra,
  POForm,
  UnitOfMeasure,
} from '../../../../Interfaces/Accounting/ACForms.interfaces';
import {
  fetchJobCostCodes,
  fetchJobExtras,
  fetchUnitsOfMeasure,
} from '../../../../services/ACFormsService';
import { v4 as uuidv4 } from 'uuid';
import { useEffect } from 'react';
import { PO_COST_TYPES } from '../../formConsts';
import FormControlledInput from 'apps/tmr-frontend/src/app/components/inputs/FormInputs/FormControlledInput';

type ExtraRowProps = {
  pieceWorkIndex: number;
  fieldIndex: number;
};

const ExtraRow = ({ pieceWorkIndex, fieldIndex }: ExtraRowProps) => {
  const { selectedCompany } = useCompanyContext();
  const { setValue, getValues, resetField, unregister, register } =
    useFormContext<POForm>();
  const [job, poType, extras, extra] = useWatch({
    name: [
      'job',
      'poType',
      `pieceWork.${pieceWorkIndex}.extras`,
      `pieceWork.${pieceWorkIndex}.extras.${fieldIndex}`,
    ],
  });

  const addNewExtra = (index: number) => {
    const extras = getValues(`pieceWork.${pieceWorkIndex}.extras`) ?? [];
    const { extra, ...extraFields } = extras[index];
    const id = uuidv4();
    extras.push({ ...extraFields, id });
    setValue(`pieceWork.${pieceWorkIndex}.extras`, extras);
  };

  const removeExtra = (index: number) => {
    resetField(`pieceWork.${pieceWorkIndex}.extras.${index}`, {
      defaultValue: {},
    });
  };

  const { data, isFetching, isError } = useQuery({
    queryKey: ['getJobExtras', selectedCompany, job],
    queryFn: ({ signal }) => {
      return fetchJobExtras(selectedCompany!.id, job, signal);
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const umRequest = useQuery({
    queryKey: ['getUnitOfMeasure'],
    queryFn: ({ signal }) => {
      return fetchUnitsOfMeasure(signal);
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const costCodesRequest = useQuery({
    queryKey: ['getJCCostCodes', selectedCompany, job],
    queryFn: ({ signal }) => {
      return fetchJobCostCodes(
        selectedCompany!.id,
        job,
        PO_COST_TYPES[poType] ?? null,
        signal
      );
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(() => {
    const total = (extra.quantity ?? 0) * (extra.price ?? 0);
    if (total) {
      setValue(`pieceWork.${pieceWorkIndex}.extras.${fieldIndex}.total`, total);
    } else {
      resetField(`pieceWork.${pieceWorkIndex}.extras.${fieldIndex}.total`, {
        defaultValue: null,
      });
    }
  }, [setValue, extra.quantity, extra.price, fieldIndex, pieceWorkIndex]);

  useEffect(() => {
    const validate = (data: string | undefined) => {
      const extras = getValues(`pieceWork.${pieceWorkIndex}.extras`);
      const index = extras?.findIndex((extra) => extra?.extra === data);
      return index === fieldIndex ? true : 'Extra has repeated value';
    };

    unregister(`pieceWork.${pieceWorkIndex}.extras.${fieldIndex}.extra`, {
      keepValue: true,
    });
    if (data?.length > 0 || isFetching) {
      register(`pieceWork.${pieceWorkIndex}.extras.${fieldIndex}.extra`, {
        required: {
          value: true,
          message: 'Extra is a required field',
        },
        maxLength: undefined,
        minLength: undefined,
        validate,
      });
    } else {
      register(`pieceWork.${pieceWorkIndex}.extras.${fieldIndex}.extra`, {
        required: {
          value: true,
          message: 'Extra is a required field',
        },
        maxLength: { value: 2, message: 'Extra max length is 2' },
        minLength: { value: 2, message: 'Extra min length is 2' },
        value: '00',
        validate,
      });

      const extras = getValues(`pieceWork.${pieceWorkIndex}.extras`);
      if (extras?.length === 1) {
        setValue(
          `pieceWork.${pieceWorkIndex}.extras.${fieldIndex}.extra`,
          '00'
        );
      }
    }
  }, [data, fieldIndex, pieceWorkIndex, isFetching]);

  return (
    <div className="flex flex-wrap">
      <div className="col-12 sm:col-11 flex flex-wrap pb-0 sm:pr-0 sm:pb-2">
        <div className="col-12 lg:col-7 flex flex-wrap p-0">
          <div className="col-12 sm:col-4 md:col-3 lg:col-4 xl:col-3">
            <label className="text-standard block mb-2" htmlFor={`extra`}>
              Extra/Location
            </label>
            {data?.length > 0 || isFetching ? (
              <FormControlledDropdown
                isFieldArray={true}
                formID={`pieceWork.${pieceWorkIndex}.extras.${fieldIndex}.extra`}
                options={data}
                labelField="ExDesc"
                valueField="ExNumber"
                isLoading={isFetching}
                placeholder={isError ? 'Failed to load extras!' : 'Select'}
                isDisabled={isError}
                clearable={false}
                filter={true}
                resetFilterOnHide={true}
                ignoreOnBlur={true}
              />
            ) : (
              <FormControlledInput
                isFieldArray={true}
                formID={`pieceWork.${pieceWorkIndex}.extras.${fieldIndex}.extra`}
                keyfilter="pnum"
                autocomplete="off"
              />
            )}
          </div>
          <div className="col-12 sm:col-4 md:col-5 lg:col-4 xl:col-5">
            <label
              htmlFor={`pieceWork.${pieceWorkIndex}.extras.${fieldIndex}.costCode`}
              className="text-standard block mb-2"
            >
              Cost Code
            </label>
            <FormControlledDropdown
              isFieldArray={true}
              formID={`pieceWork.${pieceWorkIndex}.extras.${fieldIndex}.costCode`}
              options={costCodesRequest.data?.map((code: JobCostCode) => ({
                ...code,
                label: `${code.CostCode} - ${code.Description ?? ''}`,
              }))}
              labelField="label"
              valueField="CostCode"
              placeholder={
                costCodesRequest.isError ? 'Failed to load options' : 'Select'
              }
              isDisabled={costCodesRequest.isError}
              isLoading={costCodesRequest.isFetching}
              clearable={false}
              filter={true}
              resetFilterOnHide={true}
              rules={{
                required: {
                  value: true,
                  message: 'Cost Code is a required field',
                },
              }}
              ignoreOnBlur={true}
            />
          </div>
          <div className="col-12 sm:col-4">
            <label
              htmlFor={`pieceWork.${pieceWorkIndex}.extras.${fieldIndex}.um`}
              className="text-standard block mb-2"
            >
              UoM
            </label>
            <FormControlledDropdown
              isFieldArray={true}
              formID={`pieceWork.${pieceWorkIndex}.extras.${fieldIndex}.um`}
              options={umRequest.data?.filter(
                (um: UnitOfMeasure) => um.UM !== 'MOS' && um.UM !== 'QTR'
              )}
              labelField="Description"
              valueField="UM"
              placeholder={
                umRequest.isError ? 'Failed to load options' : 'Select Option'
              }
              isDisabled={umRequest.isError}
              isLoading={umRequest.isFetching}
              clearable={false}
              filter={true}
              resetFilterOnHide={true}
              rules={{
                required: {
                  value: true,
                  message: 'UoM is a required field',
                },
              }}
              ignoreOnBlur={true}
            />
          </div>
        </div>
        <div className="col-12 md:col-12 lg:col-5 flex flex-wrap p-0">
          <div className="col-12 sm:col-4">
            <label
              htmlFor={`pieceWork.${pieceWorkIndex}.extras.${fieldIndex}.quantity`}
              className="text-standard block mb-2"
            >
              Quantity
            </label>
            <FormControlledNumberInput
              formID={`pieceWork.${pieceWorkIndex}.extras.${fieldIndex}.quantity`}
              isFieldArray={true}
              rules={{
                required: {
                  value: true,
                  message: 'Quantity is a required field',
                },
              }}
            />
          </div>
          <div className="col-12 sm:col-4">
            <label
              htmlFor={`pieceWork.${pieceWorkIndex}.extras.${fieldIndex}.price`}
              className="text-standard block mb-2"
            >
              Price/ EA
            </label>
            <FormControlledNumberInput
              formID={`pieceWork.${pieceWorkIndex}.extras.${fieldIndex}.price`}
              min={0}
              mode="currency"
              currency="USD"
              locale="en-US"
              isFieldArray={true}
              rules={{
                required: {
                  value: true,
                  message: 'Price/ EA is a required field',
                },
              }}
            />
          </div>
          <div className="col-12 sm:col-4">
            <label
              htmlFor={`pieceWork.${pieceWorkIndex}.extras.${fieldIndex}.total`}
              className="text-standard block mb-2"
            >
              Total
            </label>
            <FormControlledNumberInput
              formID={`pieceWork.${pieceWorkIndex}.extras.${fieldIndex}.total`}
              min={0}
              mode="currency"
              currency="USD"
              locale="en-US"
              disabled={true}
              isFieldArray={true}
              rules={{
                required: {
                  value: true,
                  message: 'Total is a required field',
                },
              }}
            />
          </div>
        </div>
      </div>
      <div className="col-12 sm:col-1 flex flex-wrap pt-0 sm:pl-0 sm:pt-2">
        <div className="col-6 flex sm:mt-1">
          <WrapperButton
            onClick={() => addNewExtra(fieldIndex)}
            className="h-fit mx-auto sm:mt-5"
            type="button"
          >
            <i className="pi pi-refresh text-17px p-1 border-circle hover:bg-gray-300" />
          </WrapperButton>
        </div>
        {extras?.filter((extra: POExtra) => extra.id).length > 1 && (
          <div className="col-6 flex sm:mt-1">
            <WrapperButton
              onClick={() => removeExtra(fieldIndex)}
              className="h-fit mx-auto sm:mt-5"
              type="button"
            >
              <i className="pi pi-trash text-17px p-1 border-circle hover:bg-gray-300" />
            </WrapperButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExtraRow;
