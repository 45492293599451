import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCompanyContext } from '../../../context/CompanyContext';
import { fetchARCustomerReport } from '../../../services/AccountsReceivablesService';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import ARCustomerTable from './ARCustomerTable';
import { useFormContext } from 'react-hook-form';
import { ARFiltersArgs } from '../ARFilter/ARFilters';

const ARCustomerTableContainer = React.forwardRef<HTMLDivElement>(
  (props, ref) => {
    const { selectedCompany } = useCompanyContext();
    const { watch } = useFormContext<ARFiltersArgs>();
    const infoFilter = watch('info');
    const { data, isFetching, isError } = useQuery({
      queryKey: ['getARCustomers', selectedCompany, infoFilter],
      queryFn: ({ signal }) =>
        fetchARCustomerReport(
          selectedCompany!,
          infoFilter || undefined,
          signal
        ),
      refetchOnWindowFocus: false,
      enabled: !!selectedCompany,
    });

    if (isFetching || !selectedCompany)
      return (
        <div className="text-center mx-auto mt-3">
          <ProgressSpinner />
        </div>
      );

    if (isError)
      return (
        <ErrorMessage
          content={'Failed to obtain data! Please try again later.'}
        />
      );

    return <ARCustomerTable arCustomers={data} />;
  }
);

export default ARCustomerTableContainer;
