import { formatCurrency, formatUTCDate } from '../../../../utils/formatUtils';
import {
  AIADetails,
  AIAFormItem,
  AIATableTotals,
} from '../../../Interfaces/Accounting/AccountsReceivables.interfaces';
import { MAXIMUM_CHARACTERS_PER_LINE, MAXIMUM_LINES } from './AIAFormConst';

type AIAFormTableProps = {
  items: AIAFormItem[];
  page: number;
  totalPages: number;
  invoice: string;
  contractInfo: AIADetails;
  totals?: AIATableTotals;
};

const AIAFormTable = ({
  items,
  page,
  totalPages,
  invoice,
  contractInfo,
  totals,
}: AIAFormTableProps) => {
  const row = (item: AIAFormItem) => (
    <tr className="row-fixed-height">
      <td className="pr-1">{item.Item}</td>
      <td className="text-left pl-1">{item.Description}</td>
      <td className="pr-1">
        {formatCurrency(item.CurrContract + item.ChangeAmount)}
      </td>
      <td className="pr-1">{formatCurrency(item.PrevAmt - item.PrevSM)}</td>
      <td className="pr-1">{formatCurrency(item.AmtBilled - item.SM)}</td>
      <td className="pr-1">{formatCurrency(item.SM + item.PrevSM)}</td>
      <td className="pr-1">{formatCurrency(item.PrevAmt + item.AmtBilled)}</td>
      <td className="pr-1">
        {(
          ((item.PrevAmt + item.AmtBilled) /
            ((item.CurrContract || 1) + item.ChangeAmount)) *
          100
        ).toFixed(2)}
        %
      </td>
      <td className="pr-1">
        {formatCurrency(
          item.CurrContract + item.ChangeAmount - item.PrevAmt - item.AmtBilled
        )}
      </td>
      <td className="pr-1">
        {formatCurrency(
          item.PrevRetg + item.WCRetg + item.SMRetg - item.RetgRel
        )}
      </td>
    </tr>
  );

  const emptyRow = () => (
    <tr className="h-15px">
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
    </tr>
  );

  const buildRows = () => {
    const rows = items.map((item) => row(item));

    if (page === totalPages) {
      let totalLines = 0;
      items.forEach((item: AIAFormItem) => {
        const characters = item.Description.length;
        const lines = Math.ceil(characters / MAXIMUM_CHARACTERS_PER_LINE);
        totalLines += lines;
      });

      for (let i = totalLines; i < MAXIMUM_LINES; i++) {
        rows.push(emptyRow());
      }
    }

    return rows;
  };

  return (
    <div className="w-fit w-1008px h-778px mt-35px">
      <div className="flex align-items-end gap-8">
        <div className="font-bold text-titlePT w-725px">CONTINUATION SHEET</div>
        <div className="ml-5 timesNewRoman text-10pt">
          Page {page} of {totalPages}
        </div>
      </div>
      <div className="border-3 border-bottom-none my-1" />
      <div>
        <div className="flex gap-8">
          <div className="w-605px">
            <div className="flex">
              <div className="font-bold text-9pt">
                Application and Certification for Payment
              </div>
              <div className="text-9pt">, containing</div>
            </div>
            <div className="text-9pt">
              Contractor's signed certification is attached.
            </div>
            <div className="text-9pt">
              In tabulations below, amounts are stated to the nearest dollar.
            </div>
            <div className="text-9pt">
              Use Column I on Contracts where variable retainage for line items
              may apply.
            </div>
          </div>
          <div className="font-bold flex text-9pt">
            <div className="text-right flex flex-column gap-1">
              <div>Application No. :</div>
              <div>Application Date :</div>
              <div>To:</div>
              <div>Architect's Project No.:</div>
            </div>
            <div className="text-right flex flex-column gap-1 text-9pt w-75px">
              <div>{contractInfo.contract.AppNumber}</div>
              <div className="text-center">
                {formatUTCDate(contractInfo.contract.AppDate, 'MM/DD/YY')}
              </div>
              <div className="text-center">
                {formatUTCDate(contractInfo.contract.ToDate, 'MM/DD/YY')}
              </div>
            </div>
          </div>
        </div>
        <div className="flex font-bold text-9pt">
          <div className="flex w-220px">
            <div className="ml-2 w-85px">Invoice # :</div>
            <div>{invoice}</div>
          </div>
          <div>Contract :</div>
          <div className="ml-2">
            {contractInfo.contract.Contract}
            <span className="ml-1">{contractInfo.contract.ContractName}</span>
          </div>
        </div>
      </div>
      <table
        className={`pdfTable table-collapse h-480px ${
          page !== totalPages && 'noFooter'
        } mt-2`}
      >
        <thead>
          <tr>
            <th className="text-labelPT">A</th>
            <th className="text-labelPT">B</th>
            <th className="text-labelPT">C</th>
            <th className="border-right-none text-labelPT">D</th>
            <th className="border-left-none text-labelPT">E</th>
            <th className="text-labelPT">F</th>
            <th className="text-labelPT">G</th>
            <th></th>
            <th className="text-labelPT">H</th>
            <th className="text-labelPT">I</th>
          </tr>
          <tr>
            <th className="px-3 text-labelPT max-w-65px" rowSpan={2}>
              Item No.
            </th>
            <th className="text-labelPT w-210px" rowSpan={2}>
              Description of Work
            </th>
            <th className="text-labelPT max-w-85px" rowSpan={2}>
              Scheduled Value
            </th>
            <th className="text-labelPT max-w-193px max-h-13px" colSpan={2}>
              Work Completed
            </th>
            <th className="relative text-littleLabelPT w-95px" rowSpan={2}>
              <div className="h-full absolute flex flex-column justify-content-between px-2">
                Materials Presently Stored
                <div className="mb-2px">(Not in D or E)</div>
              </div>
            </th>
            <th className="px-3 text-littleLabelPT max-w-90px" rowSpan={2}>
              Total Completed and Stored To Date
              <div className="mt-3">(D+E+F)</div>
            </th>
            <th className="px-2 text-labelPT min-w-70px" rowSpan={2}>
              % <div>(G / C)</div>
            </th>
            <th className="px-3 text-labelPT max-w-85px" rowSpan={2}>
              Balance To Finish (C-G)
            </th>
            <th className="px-3 text-labelPT" rowSpan={2}>
              Retainage
            </th>
          </tr>
          <tr>
            <th className="px-2 text-labelPT max-w-96px">
              From Previous Application (D+E)
            </th>
            <th className="px-3 text-labelPT max-w-96px">
              This Period In Place
            </th>
          </tr>
        </thead>
        <tbody className="text-right">{buildRows()}</tbody>
        {page === totalPages && totals && (
          <tfoot>
            <tr className="font-bold text-right">
              <td colSpan={2} className="text-left">
                <div className="ml-auto pl-1 border-left-1 border-transparent w-210px">
                  Grand Totals
                </div>
              </td>
              <td className="pr-1">{formatCurrency(totals.scheduledValue)}</td>
              <td className="pr-1">{formatCurrency(totals.previous)}</td>
              <td className="pr-1">{formatCurrency(totals.current)}</td>
              <td className="pr-1">{formatCurrency(totals.materials)}</td>
              <td className="pr-1">{formatCurrency(totals.total)}</td>
              <td className="pr-1">
                {((totals.total / totals.scheduledValue) * 100).toFixed(2)}%
              </td>
              <td className="pr-1">{formatCurrency(totals.balance)}</td>
              <td className="pr-1">{formatCurrency(totals.retainage)}</td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

export default AIAFormTable;
