import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useRef, useState } from 'react';
import OptionCard from '../../../components/cards/OptionCard';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { postFile } from '../../../services/FinancialStatementsService';
import { useCompanyContext } from '../../../context/CompanyContext';
import { AxiosError } from 'axios';
import FileForm, { FileFormFields } from './FileForm';
import SuccessFormMessage from '../../../components/messages/SuccessFormMessage';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import firebaseErrorMessages from '../../../components/errorMessages/firebase';
import { Toast } from 'primereact/toast';
import LoadingButton from '../../../components/inputs/LoadingButton';
import WrapperButton from '../../../components/buttons/WrapperButton';

type NewFileOptionProps = {
  folderID: string;
  afterUpload?: () => void;
};

const NewFileOption = ({ folderID, afterUpload }: NewFileOptionProps) => {
  const { selectedCompany } = useCompanyContext();
  const toast = useRef<Toast>(null);
  const [visible, setVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const formMethods = useForm<FileFormFields>();
  const { handleSubmit } = formMethods;

  const { mutate, isLoading, isSuccess, reset } = useMutation({
    mutationFn: (data: FormData) => {
      return postFile(selectedCompany!.id, folderID, data);
    },
    onSuccess: (data) => {
      if (data.errorFiles?.length > 0) {
        setSuccessMessage(`${data.message}:\n ${data.errorFiles.join(',\n ')}`);
      }

      setSuccessMessage(data.message);
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as {
        code: string;
        message: string;
      };
      showToast(
        'error',
        toast,
        'Add File Error',
        firebaseErrorMessages[errorData?.code] ||
          errorData?.message ||
          'An error ocurred! please try again later',
        5000
      );
    },
  });

  const onHide = () => {
    setVisible(false);
    formMethods.reset();
    if (afterUpload && isSuccess) {
      afterUpload();
    }
  };

  const onClick = () => {
    setVisible(true);
  };

  const onSubmit = (data: FileFormFields) => {
    const formData = new FormData();
    data.files.forEach((file) => {
      formData.append(`files`, file);
    });

    mutate(formData);
  };

  useEffect(() => {
    if (visible) {
      reset();
    }
  }, [visible, reset]);

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      <WrapperButton
        className="w-15rem h-13rem text-center text-standard"
        onClick={onClick}
      >
        <OptionCard>
          <div className="h-8rem flex">
            <i className="pi pi-plus-circle text-70px m-auto text-gray-700" />
          </div>
          <div>Upload new file</div>
        </OptionCard>
      </WrapperButton>
      <Dialog
        header="Upload new file"
        visible={visible}
        onHide={onHide}
        className="w-screen sm:w-auto"
      >
        {!isSuccess && (
          <FormProvider {...formMethods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="max-w-624px md:w-screen mx-auto flex flex-column gap-5"
            >
              <FileForm />
              <div className="flex flex-wrap gap-2 mb-3">
                <Button
                  type="button"
                  onClick={onHide}
                  className="w-10rem bluwaiRed border-0 flex m-auto py-3"
                >
                  <span className="mx-auto text-18px text-white">Cancel</span>
                </Button>
                <LoadingButton isLoading={isLoading} label="Upload" />
              </div>
            </form>
          </FormProvider>
        )}
        {isSuccess && (
          <div className="max-w-30rem">
            <SuccessFormMessage message={successMessage} />
          </div>
        )}
      </Dialog>
    </React.Fragment>
  );
};

export default NewFileOption;
