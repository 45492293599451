import { Link } from 'react-router-dom';
import checkPermissions from '../../../HOCs/CheckPermissions';
import { ACForm } from '../../../Interfaces/Accounting/ACForms.interfaces';

type SectionLinkProps = {
  form: ACForm;
};

const SectionLink = ({ form }: SectionLinkProps) => {
  const LinkComponent = form.access
    ? checkPermissions({
        sectionsToAccess: [form.access],
        Children: Link,
        blockAdminType: true,
      })
    : Link;

  if (!form.url) {
    return <li>{form.label}</li>;
  }

  return (
    <LinkComponent to={form.url} className="hover:underline">
      <li>
        <span className="totalColor">{form.label}</span>
      </li>
    </LinkComponent>
  );
};

export default SectionLink;
