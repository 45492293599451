import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import APVendorTable from './APVendorTable';
import { useFormContext } from 'react-hook-form';
import { fetchAccountsPayablesFilters } from '../APFilters';
import { fetchVendorReport } from '../../../services/vendorsService';

const APVendorTableContainer = React.forwardRef<HTMLDivElement>(
  (props, ref) => {
    const { selectedCompany } = useCompanyContext();
    const { watch } = useFormContext<fetchAccountsPayablesFilters>();
    const infoFilter = watch('info');
    const { data, isFetching, isError } = useQuery({
      queryKey: ['getAPVendorsTable', selectedCompany, infoFilter],
      queryFn: ({ signal }) =>
        fetchVendorReport(
          { company: selectedCompany!, info: infoFilter },
          signal
        ),
      refetchOnWindowFocus: false,
      enabled: !!selectedCompany,
    });

    if (isFetching || !selectedCompany)
      return (
        <div className="text-center mx-auto mt-3">
          <ProgressSpinner />
        </div>
      );

    if (isError)
      return (
        <ErrorMessage
          content={'Failed to obtain data! Please try again later.'}
        />
      );

    return <APVendorTable vendors={data} />;
  }
);

export default APVendorTableContainer;
