import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCompanyContext } from '../../../context/CompanyContext';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import {
  fecthSCVendors,
  fetchSCPaymentWorksheet,
} from '../../../services/SubcontractsService';
import { useFormContext } from 'react-hook-form';
import { SubcontractsFilters } from '../SCFilters';
import SCPaymentWorksheetTable from './SCPaymentWorksheetTable';
import { useRolesAccessContext } from '../../../context/RolesAccessContext';

const SCPaymentWorksheetContainer = React.forwardRef<HTMLDivElement>(
  (props, ref) => {
    const { selectedCompany } = useCompanyContext();
    const { rolesAcess } = useRolesAccessContext();
    const { watch } = useFormContext<SubcontractsFilters>();
    const filters = watch();
    const isEnabled = selectedCompany && filters.job && filters.subcontract;
    const access = rolesAcess?.find(
      (access) => access.report === 'subcontract_payment_worksheet'
    );

    const { data, isFetching, isError } = useQuery({
      queryKey: ['getSCPaymentWorksheet', selectedCompany, filters],
      queryFn: ({ signal }) => {
        const { subcontract, info, job, submittedID, itemsState } = filters;

        return fetchSCPaymentWorksheet(
          {
            companyID: selectedCompany!.id,
            job: job ?? '',
            subcontract,
            info,
            edit: submittedID ? undefined : !!access?.editable,
            draftID: submittedID,
            hideBilled: itemsState === 'hide' ? true : false,
          },
          signal
        );
      },
      refetchOnWindowFocus: false,
      enabled: !!isEnabled,
    });

    const vendorRequest = useQuery({
      queryKey: ['getSubcontractVendor', selectedCompany, filters],
      queryFn: ({ signal }) => {
        const { job, subcontract } = filters;

        return fecthSCVendors(
          {
            companyID: selectedCompany!.id,
            job: job ?? '',
            subcontract,
          },
          signal
        );
      },
      refetchOnWindowFocus: false,
      enabled: !!isEnabled,
    });

    if (!isEnabled) {
      return (
        <div className="mt-4">
          <ErrorMessage
            severity="info"
            content={
              'To start the search please select a Job and a Subcontract.'
            }
          />
        </div>
      );
    }

    if (isFetching || vendorRequest.isFetching)
      return (
        <div className="text-center mx-auto mt-3">
          <ProgressSpinner />
        </div>
      );

    if (isError || vendorRequest.isError)
      return (
        <ErrorMessage
          content={'Failed to obtain data! Please try again later.'}
        />
      );

    const vendor = (vendorRequest.data || [])[0];
    return (
      <div>
        {vendor && (
          <div className="text-18px ml-3 flex mt-4">
            <div className="mr-1 font-bold">Vendor info:</div>
            <div id="sc-vendor-info" className="flex flex-wrap gap-1 ml-1">
              <div>{vendor.Vendor}</div>
              <div>{vendor.Name}</div>
              <div>{vendor.SortName}</div>
            </div>
          </div>
        )}
        <SCPaymentWorksheetTable
          paymentItems={data.items}
          vendor={vendor.Vendor}
          isApproval={!!filters.submittedID}
          draft={
            data.draft
              ? { ...data.draft, invoiceDate: new Date(data.draft.invoiceDate) }
              : undefined
          }
          access={access ?? { report: 'subcontract_payment_worksheet' }}
        />
      </div>
    );
  }
);

export default SCPaymentWorksheetContainer;
