import React from 'react';
import { PayableDetail } from '../../../Interfaces/Accounting/AccountsPayables.interfaces';
import { formatUTCDate, formatCurrency } from '../../../../utils/formatUtils';

type APDrilldownInvoiceDetailsProps = {
  invoice: PayableDetail;
  extendedDetails?: boolean;
};

const APDrilldownInvoiceDetails = ({
  invoice,
  extendedDetails,
}: APDrilldownInvoiceDetailsProps) => {
  return (
    <div>
      <div className="flex flex-wrap column-gap-2">
        <span className="font-bold">Seq {invoice.Sequence}:</span>
        <span>{formatCurrency(invoice.Amount)}</span>
      </div>
      {extendedDetails && (
        <React.Fragment>
          <div className="flex flex-wrap column-gap-2">
            <span className="font-bold">Paid Date:</span>
            <span>{formatUTCDate(invoice.PaidDate)}</span>
          </div>
          <div className="flex flex-wrap column-gap-2">
            <span className="font-bold">Bank/Check Number:</span>
            {invoice.CMRef && (
              <span>
                {invoice.CMAcct}/{invoice.CMRef.trim()}
              </span>
            )}
          </div>
          <div className="flex flex-wrap column-gap-2">
            <span className="font-bold">Clear Date:</span>
            <span>{formatUTCDate(invoice.ClearDate)}</span>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default APDrilldownInvoiceDetails;
