import { SubcontractEntry } from 'apps/tmr-frontend/src/app/Interfaces/Accounting/ACForms.interfaces';
import { useRef } from 'react';
import { BASE_PDF_STYLES } from '../../../../../utils/reportUtils';
import SuccessFormMessage from '../../../../components/messages/SuccessFormMessage';
import ReportButton from '../../../../components/report/ReportButton';
import GeneralInfoSummary from './GeneralInfoSummary';
import SLItemsSummary from './SLItemsSummary';

type SLEntrySuccessProps = {
  subcontract: SubcontractEntry;
};

const SLEntrySuccess = ({ subcontract }: SLEntrySuccessProps) => {
  const summaryRef = useRef<HTMLDivElement | null>(null);

  return (
    <div>
      <SuccessFormMessage
        message="The Subcontract has been uploaded for creation!"
        redirectUrl="/accounting-dashboard/new"
        redirectMessage="Click here to return to the forms list"
      />
      <div
        id="sl-entry-success"
        className="w-fit mx-auto flex flex-column gap-3"
        ref={summaryRef}
      >
        <div className="text-30px font-bold mt-6 flex justify-content-between align-items-end">
          Subcontract Summary:
          <div className="text-23px cursor-pointer printHide h-fit">
            <ReportButton
              elementToPrintRef={summaryRef}
              fileName="Subcontract_Entry"
              pdfStyles={BASE_PDF_STYLES}
              noButton={true}
              hideExcel={true}
              isPdfPortrait={true}
              widthElementID="sl-entry-success"
              minWidth={800}
            />
          </div>
        </div>
        <GeneralInfoSummary subcontract={subcontract} />
        <SLItemsSummary slItems={subcontract.slItems} />
      </div>
    </div>
  );
};

export default SLEntrySuccess;
