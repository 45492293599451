import { FormProvider, useForm } from 'react-hook-form';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase';
import { Link } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorToast, { showToast } from '../components/messages/ErrorAlert';
import { useRef } from 'react';
import { Toast } from 'primereact/toast';
import firebaseErrorMessages from '../components/errorMessages/firebase';
import ResetPasswordForm, {
  ResetPasswordFormData,
} from '../features/login/ResetPasswordForm';

const ResetPassword = () => {
  const toast = useRef<Toast>(null);
  const formMethods = useForm<ResetPasswordFormData>();
  const { handleSubmit } = formMethods;
  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: (data: ResetPasswordFormData) => {
      return sendPasswordResetEmail(auth, data.email);
    },
    onError: (error: { message: string; code: string }) => {
      showToast(
        'error',
        toast,
        'Reset Password Error',
        firebaseErrorMessages[error.code] ||
          'An error ocurred, please try again later',
        3000
      );
    },
  });

  const onSubmit = (data: ResetPasswordFormData) => {
    mutate(data);
  };

  return (
    <div
      className="login-page h-screen flex"
      style={{
        backgroundImage: 'url(assets/layout/images/login-background.svg)',
      }}
    >
      <div className="flex align-items-center m-auto justify-content-center">
        <div className="p-4">
          <div className="text-center mb-8">
            <img
              src="./assets/layout/images/bluwai_Logo.png"
              alt="Bluwai"
              className="w-full"
            />
          </div>
          <ErrorToast toastRef={toast} />
          {!isSuccess && (
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <ResetPasswordForm />
                <Button className="mt-5 w-15rem buttonSecondary border-0 flex m-auto py-3">
                  {isLoading && <ProgressSpinner className="w-3rem h-3rem" />}
                  {!isLoading && (
                    <span className="mx-auto text-22px text-white">
                      Reset Password
                    </span>
                  )}
                </Button>
              </form>
            </FormProvider>
          )}
          {isSuccess && (
            <div className="w-fit mx-auto flex flex-column text-center">
              <div className="text-40px font-bold text-green-500 mb-3">
                Success!
              </div>
              <i className="pi pi-check-circle mx-auto text-40px text-green-500 mb-3" />
              <div className="text-standard mt-3 mx-auto">
                A reset password link was sent to your email!
              </div>
              <Link
                to="/login"
                className="mx-auto totalColor hover:underline font-bold mt-3"
              >
                Click here to return to the login page
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
