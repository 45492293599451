import { useEffect } from 'react';
import FormControlledDropdown from '../inputs/FormInputs/FormControlledDropdown';
import { useFormContext } from 'react-hook-form';
import { ReportItem } from '../../Interfaces/Role.interfaces';

export type ReportSelectorProps = {
  protectedReports: ReportItem[];
  onChange: () => void;
};

const ReportSelector = ({
  onChange,
  protectedReports,
}: ReportSelectorProps) => {
  const { watch, setValue } = useFormContext();
  const reportType = watch('reportType');

  useEffect(() => {
    const firstReport = protectedReports.find((report) => !report.disabled);
    const currentReport = protectedReports.find(
      (report) => !report.disabled && report.value === reportType
    );

    setValue('reportType', currentReport?.value ?? firstReport?.value);
  }, [reportType, protectedReports, setValue]);

  return (
    <div className="w-20rem">
      <FormControlledDropdown
        formID="reportType"
        options={protectedReports}
        labelField="label"
        valueField="value"
        placeholder="Select Report"
        optionDisabled="disabled"
        clearable={false}
        additionalChange={() => {
          onChange();
        }}
      />
    </div>
  );
};

export default ReportSelector;
