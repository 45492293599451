import React from 'react';
import { Sidebar } from 'primereact/sidebar';
import { useQuery } from '@tanstack/react-query';
import { useCompanyContext } from '../../../context/CompanyContext';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import {
  fecthJCCostDetail,
  fecthJCCostType,
} from '../../../services/JobsContractsService';
import JCCostDrilldown from './JCCostDrilldown';
import { useFormContext } from 'react-hook-form';
import { JobContractFilters } from '../JCFilters';
import { CostDetailArgs } from '../../../Interfaces/Accounting/JobsContracts.interface';

type JCCostDrilldownContainerProps = {
  visible: boolean;
  hideDrilldown: () => void;
  costDetail: CostDetailArgs;
};

const JCCostDrilldownContainer = ({
  visible,
  hideDrilldown,
  costDetail,
}: JCCostDrilldownContainerProps) => {
  let job = '';
  let jobName = '';
  if ('job' in costDetail) {
    ({ job, jobName } = costDetail);
  }
  const { costType, phase, dateType, phaseDescription } = costDetail;
  const { selectedCompany } = useCompanyContext();
  const { watch } = useFormContext<JobContractFilters>();
  const filters = watch();

  const { data, isFetching, isError, isSuccess } = useQuery({
    queryKey: [
      'getJCCostDetail',
      selectedCompany,
      job,
      costType,
      phase,
      filters,
      dateType,
    ],
    queryFn: ({ signal }) =>
      fecthJCCostDetail(
        {
          companyID: selectedCompany!.id,
          job,
          costType,
          phases: phase ? [phase] : undefined,
          startDate: filters.startDate,
          endDate: filters.endDate,
          dateType,
        },
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  const costTypeRequest = useQuery({
    queryKey: ['getJCCostType', selectedCompany, costType],
    queryFn: ({ signal }) =>
      fecthJCCostType(selectedCompany!.id, costType ?? 0, signal),
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && costType),
  });

  const fetching = isFetching || costTypeRequest.isFetching;
  const error = (isError || costTypeRequest.isError) && !fetching;
  const success = (isSuccess || costTypeRequest.isSuccess) && !fetching;

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={hideDrilldown}
      className="w-full max-w-1500px"
    >
      {fetching && (
        <div className="mx-8 h-full flex align-items-center">
          <ProgressSpinner />
        </div>
      )}
      {error && (
        <div className="h-full flex align-items-center">
          <ErrorMessage
            content={'Failed to obtain data! Please try again later.'}
          />
        </div>
      )}
      {success && (
        <JCCostDrilldown
          costs={data || []}
          job={job}
          jobName={jobName}
          costType={{ ...costTypeRequest.data }}
          phase={phase}
          phaseDescription={phaseDescription}
          startDate={filters.startDate}
          endDate={filters.endDate}
          dateType={dateType}
        />
      )}
    </Sidebar>
  );
};

export default JCCostDrilldownContainer;
