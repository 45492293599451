import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCompanyContext } from '../../../context/CompanyContext';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { fecthJCCostDetail } from '../../../services/JobsContractsService';
import { useFormContext } from 'react-hook-form';
import { JobContractFilters } from '../JCFilters';
import JCDetailTable from './JCDetailTable';
import { processCostList } from './util';

const JCDetailTableContainer = React.forwardRef<HTMLDivElement>(
  (props, ref) => {
    const { selectedCompany } = useCompanyContext();
    const { watch } = useFormContext<JobContractFilters>();
    const filters = watch();
    const isEnabled = selectedCompany && filters.job;

    const { data, isFetching, isError } = useQuery({
      queryKey: ['getJCCostDetail', selectedCompany, filters],
      queryFn: ({ signal }) => {
        const {
          reportType,
          contractState,
          extraDetails,
          jobState,
          ...otherFilters
        } = filters;
        return fecthJCCostDetail(
          { companyID: selectedCompany!.id, ...otherFilters },
          signal
        );
      },
      refetchOnWindowFocus: false,
      enabled: !!isEnabled,
    });

    if (!isEnabled) {
      return (
        <div className="mt-4">
          <ErrorMessage
            severity="info"
            content={'To start the search please select a Job.'}
          />
        </div>
      );
    }

    if (isFetching)
      return (
        <div className="text-center mx-auto mt-3">
          <ProgressSpinner />
        </div>
      );

    if (isError)
      return (
        <ErrorMessage
          content={'Failed to obtain data! Please try again later.'}
        />
      );

    return <JCDetailTable costs={processCostList(data)} />;
  }
);

export default JCDetailTableContainer;
