import React, { useCallback } from 'react';
import { Column } from 'primereact/column';
import { getDatePeriod } from '../../../../utils/dateUtils';
import Table from '../../../components/Table/Table';
import { formatCurrency, formatUTCDate } from '../../../../utils/formatUtils';
import {
  nextSortState,
  sortByField,
  sortIndexForNumber,
} from '../../../../utils/sortUtils';
import { AccountReceivable } from '../../../Interfaces/Accounting/AccountsReceivables.interfaces';
import {
  DataTablePFSEvent,
  DataTableRowMouseEventParams,
  DataTableSortOrderType,
} from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import ARAttachmentSelector from '../ARAttachmentSelector';

const receivablePeriods = [
  { label: 'future', differenceInDays: -1 },
  { label: '30', differenceInDays: 30 },
  { label: '60', differenceInDays: 60 },
  { label: '90', differenceInDays: 90 },
  { label: '91', differenceInDays: Infinity },
];

type ARAgedTableProps = {
  accountsReceivables: AccountReceivable[];
};

const ARAgedTable = React.forwardRef<HTMLDivElement, ARAgedTableProps>(
  ({ accountsReceivables }, ref) => {
    const [sortState, setSortState] = React.useState<{
      field: string;
      order: DataTableSortOrderType;
    }>({ field: '', order: null });
    const [sortedTransactions, setSortedTransactions] = React.useState([
      ...accountsReceivables,
    ]);

    const calcHeight = (rows: number) => {
      const headerHeight = 32;
      const footerHeight = 54;
      const rowHeight = 31;
      return headerHeight + footerHeight + rows * rowHeight + 3;
    };

    const onRowHover = (e: DataTableRowMouseEventParams) => {
      const cell = e.originalEvent.target as HTMLElement;
      const row = cell.closest('tr') as HTMLElement;
      animateCellText(row);
    };

    let future = 0;
    let current = 0;
    let under60 = 0;
    let under90 = 0;
    let over91 = 0;
    let retainage = 0;
    accountsReceivables.forEach((receivable: AccountReceivable) => {
      retainage = receivable.Retainage + retainage;

      const period = getDatePeriod(receivable.InvoiceDate, receivablePeriods);
      switch (period) {
        case 'future':
          future = future + receivable.AmountDue;
          break;
        case '30':
          current = current + receivable.AmountDue;
          break;
        case '60':
          under60 = under60 + receivable.AmountDue;
          break;
        case '90':
          under90 = under90 + receivable.AmountDue;
          break;
        default:
          over91 = over91 + receivable.AmountDue;
          break;
      }
    });

    const getSortValue = (receivable: AccountReceivable, period: string) => {
      const resultPeriod = getDatePeriod(
        receivable.InvoiceDate,
        receivablePeriods
      );
      if (resultPeriod === period) {
        const value = receivable.AmountDue;
        return value || null;
      }

      return null;
    };

    const getSortValueCB = useCallback(getSortValue, []);

    const handleSort = (event: DataTablePFSEvent) => {
      const { reset, field, order } = nextSortState(
        event.sortField,
        sortState.field
      );
      setSortState({ field, order });

      if (reset) {
        setSortedTransactions([...accountsReceivables]);
        return;
      }

      const sortedData = [...accountsReceivables].sort(
        (receivableA, receivableB) => {
          let valueA, valueB;

          if (['future', '30', '60', '90', '91'].includes(field)) {
            valueA = getSortValueCB(receivableA, field);
            valueB = getSortValueCB(receivableB, field);
          } else if (field === 'retainage') {
            valueA = receivableA.Retainage || null;
            valueB = receivableB.Retainage || null;
          } else {
            const key = field as keyof AccountReceivable;
            valueA = receivableA[key];
            valueB = receivableB[key];
            return sortByField(valueA, valueB, order ?? 0);
          }

          return sortIndexForNumber(valueA, valueB, order);
        }
      );

      setSortedTransactions(sortedData);
    };

    return (
      <div className="pdfDivFitContent">
        <Table
          id="ar-aged-table"
          ref={ref}
          data={sortedTransactions}
          className={`mx-3 dashboardOptionShadow tableFirstLineStyled`}
          calcHeight={useCallback(calcHeight, [])}
          onRowMouseEnter={useCallback(onRowHover, [])}
          sortField={sortState.field}
          sortOrder={sortState.order}
          onSort={useCallback(handleSort, [
            sortState.field,
            accountsReceivables,
            getSortValueCB,
          ])}
          hideColumns={true}
        >
          <Column
            field="Customer"
            header="Customer"
            body={(ar) => {
              return <div className="scroll-text">{ar.Customer}</div>;
            }}
            headerClassName={`tableHeader font-normal justify-content-center`}
            style={{ minWidth: '250px', maxWidth: '250px' }}
            className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="Contract"
            header="Contract #"
            style={{ minWidth: '110px' }}
            headerClassName="tableHeader font-normal"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="ContractName"
            header="Contract Name"
            body={(ar) => {
              return <div className="scroll-text">{ar.ContractName}</div>;
            }}
            style={{ minWidth: '210px', maxWidth: '210px' }}
            headerClassName="tableHeader font-normal justify-content-center"
            className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="InvoiceNumber"
            header="Invoice"
            style={{ minWidth: '90px' }}
            headerClassName="tableHeader font-normal"
            className=" text-standard blackText tableCell title justify-content-center"
            footerClassName={`tableFooter`}
            sortable
          />
          <Column
            field="InvoiceDate"
            header="Inv. Date"
            style={{ minWidth: '100px' }}
            body={(ar) => formatUTCDate(ar.InvoiceDate)}
            headerClassName="tableHeader font-normal"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="Description"
            header="Description"
            body={(ar) => {
              return <div className="scroll-text">{ar.Description}</div>;
            }}
            style={{ minWidth: '130px', maxWidth: '130px' }}
            headerClassName="tableHeader font-normal justify-content-center"
            className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
            footer="Total"
            footerClassName="overflow-x-visible tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
            sortable
          />
          <Column
            sortField="future"
            header="Future"
            style={{ minWidth: '120px' }}
            body={(ar) => {
              return getDatePeriod(ar.InvoiceDate, receivablePeriods) ===
                'future' && ar.AmountDue
                ? formatCurrency(ar.AmountDue)
                : '-';
            }}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(future)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            sortField="30"
            header="Current"
            style={{ minWidth: '130px' }}
            body={(ar) => {
              return getDatePeriod(ar.InvoiceDate, receivablePeriods) ===
                '30' && ar.AmountDue
                ? formatCurrency(ar.AmountDue)
                : '-';
            }}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(current)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            sortField="60"
            header="31 - 60"
            style={{ minWidth: '130px' }}
            body={(ar) => {
              return getDatePeriod(ar.InvoiceDate, receivablePeriods) ===
                '60' && ar.AmountDue
                ? formatCurrency(ar.AmountDue)
                : '-';
            }}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(under60)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            sortField="90"
            header="61 - 90"
            style={{ minWidth: '130px' }}
            body={(ar) => {
              return getDatePeriod(ar.InvoiceDate, receivablePeriods) ===
                '90' && ar.AmountDue
                ? formatCurrency(ar.AmountDue)
                : '-';
            }}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(under90)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            sortField="91"
            header="Over 90"
            style={{ minWidth: '130px' }}
            body={(ar) => {
              return getDatePeriod(ar.InvoiceDate, receivablePeriods) ===
                '91' && ar.AmountDue
                ? formatCurrency(ar.AmountDue)
                : '-';
            }}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(over91)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            sortField="retainage"
            header="Retainage"
            style={{ minWidth: '130px' }}
            body={(ar) => {
              return ar.Retainage ? formatCurrency(ar.Retainage) : '-';
            }}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(retainage)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            header=""
            style={{ minWidth: '50px', maxWidth: '50px' }}
            body={(ar) => {
              return (
                <ARAttachmentSelector
                  contract={ar.Contract}
                  invoice={ar.InvoiceNumber}
                />
              );
            }}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-center text-standard blackText tableCell printHide"
            footerClassName="tableFooter"
          />
        </Table>
        <div className="ml-auto w-full px-3 sm:px-0 sm:w-24rem mr-5 my-5 text-standard">
          <div className="flex justify-content-between">
            <span>Receivables</span>
            <span id="totalReceivables">
              {formatCurrency(future + current + under60 + under90 + over91)}
            </span>
          </div>
          <div className="flex justify-content-between mt-2">
            <span>A/R - Retainage</span>
            <span id="totalRetainage">{formatCurrency(retainage)}</span>
          </div>
          <div className="flex justify-content-between mt-1 border-top-1 border-bottom-2">
            <span className="font-bold">Grand Total</span>
            <span id="grandTotal">
              {formatCurrency(
                future + current + under60 + under90 + over91 + retainage
              )}
            </span>
          </div>
        </div>
      </div>
    );
  }
);

const transactionsAreEqual = (
  prevTransactions: Readonly<ARAgedTableProps>,
  nextTransactions: Readonly<ARAgedTableProps>
) => {
  return (
    prevTransactions.accountsReceivables ===
    nextTransactions.accountsReceivables
  );
};

export default React.memo(ARAgedTable, transactionsAreEqual);
