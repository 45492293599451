import React, { memo, useCallback } from 'react';
import { Route, Routes } from 'react-router-dom';
import checkPermissions from '../HOCs/CheckPermissions';
import AccountingDashboard from '../pages/Accounting/AccountingDashboard';
import AccountPayables from '../pages/Accounting/AccountPayables';
import AccountsReceivables from '../pages/Accounting/AccountsReceivables';
import CashManagement from '../pages/Accounting/CashManagement';
import ContractBillings from '../pages/Accounting/ContractBillings';
import Form from '../pages/Accounting/Forms';
import FSFolderFiles from '../pages/Accounting/FinancialStatements/FSFolderFiles';
import FinancialStatements from '../pages/Accounting/FinancialStatements/FSFolders';
import JobsContracts from '../pages/Accounting/JobsContracts';
import Payroll from '../pages/Accounting/Payroll';
import Subcontracts from '../pages/Accounting/SubContracts';
import FormRoutes from './FormRoutes';
import { useCompanyContext } from '../context/CompanyContext';
import TabsNavBar from '../features/navbar/TabsNavBar';
import ErrorBoundary from '../HOCs/ErrorBoundary';
import SystemError from '../components/messages/SystemError';
import { reportsPerSection } from '../../utils/rolesConst';

const AccountingDashboardRoutes = () => {
  const { selectedCompany } = useCompanyContext();

  const CBRoute = checkPermissions({
    sectionsToAccess: reportsPerSection['contract billings'],
    Children: ContractBillings,
  });

  const ARRoute = checkPermissions({
    sectionsToAccess: reportsPerSection['accounts receivables'],
    Children: AccountsReceivables,
  });

  const APRoute = checkPermissions({
    sectionsToAccess: reportsPerSection['accounts payables'],
    Children: AccountPayables,
  });

  const CMRoute = checkPermissions({
    sectionsToAccess: reportsPerSection['cash management'],
    Children: CashManagement,
  });

  const JCRoute = checkPermissions({
    sectionsToAccess: reportsPerSection['jobs & contracts'],
    Children: JobsContracts,
  });

  const PayrollRoute = checkPermissions({
    sectionsToAccess: reportsPerSection['payroll'],
    Children: Payroll,
  });

  const SCRoute = checkPermissions({
    sectionsToAccess: reportsPerSection['subcontracts'],
    Children: Subcontracts,
  });

  const NewRoute = checkPermissions({
    sectionsToAccess: reportsPerSection['new'],
    Children: Form,
  });

  const FSProtected = checkPermissions({
    sectionsToAccess: reportsPerSection['financial statements'],
    Children: FinancialStatements,
  });

  const FSFilesProtected = checkPermissions({
    sectionsToAccess: reportsPerSection['financial statements'],
    Children: FSFolderFiles,
  });

  return (
    <div>
      <div className="mx-2 overflow-x-auto scrollbarStyled">
        <TabsNavBar />
      </div>
      {!!selectedCompany && (
        <div className="dashboardBG">
          <ErrorBoundary fallBack={<SystemError />}>
            <Routes>
              <Route path="/contract-billings" element={<CBRoute />} />
              <Route path="/accounts-receivables" element={<ARRoute />} />
              <Route path="/account-payables" element={<APRoute />} />
              <Route path="/cash-management" element={<CMRoute />} />
              <Route path="/jobs-contracts" element={<JCRoute />} />
              <Route path="/payroll" element={<PayrollRoute />} />
              <Route path="/subcontracts" element={<SCRoute />} />
              <Route path="/financialStatement" element={<FSProtected />} />
              <Route
                path="/financialStatement/:folderPath"
                element={<FSFilesProtected />}
              />
              <Route path="/new" element={<NewRoute />} />
              <Route path="/new/*" element={<FormRoutes />} />
              <Route path="/" element={<AccountingDashboard />} />
            </Routes>
          </ErrorBoundary>
        </div>
      )}
    </div>
  );
};

export default AccountingDashboardRoutes;
