import { Message, MessageSeverityType } from 'primereact/message';
import React from 'react';

type ErrorProps = {
  content: React.ReactNode;
  severity?: MessageSeverityType;
};

const ErrorMessage = ({ content, severity = 'error' }: ErrorProps) => {
  return (
    <Message
      className="flex w-fit mx-auto"
      severity={severity}
      text={content}
    />
  );
};

export default ErrorMessage;
