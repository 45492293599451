import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import * as XLSX from 'xlsx';
import ErrorToast from '../messages/ErrorAlert';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';

import PrintItem from './PrintItem';
import PdfItem from './PdfItem';
import ExcelItem from './ExcelItem';
import WrapperButton from '../buttons/WrapperButton';

type ReportButtonProps = {
  elementToPrintRef: React.MutableRefObject<HTMLElement | null>;
  pdfStyles: string;
  fileName: string;
  excelPreprocess?: (element: HTMLElement) => HTMLElement;
  excelBeforeDownload?: (
    workBook: XLSX.WorkBook,
    element?: HTMLElement
  ) => void;
  noButton?: boolean;
  hideExcel?: boolean;
  isPdfPortrait?: boolean;
  widthElementID: string;
  minWidth?: number;
  tableOriginCell?: string;
};

const ReportButton = ({
  elementToPrintRef,
  pdfStyles,
  fileName,
  excelPreprocess,
  excelBeforeDownload,
  noButton,
  hideExcel,
  isPdfPortrait,
  widthElementID,
  minWidth,
  tableOriginCell,
}: ReportButtonProps) => {
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);
  const menuRef = useRef<Menu>(null);

  const items = [
    PrintItem({ setLoading, fileName, toast, elementToPrintRef }),
    PdfItem({
      setLoading,
      fileName,
      toast,
      elementToPrintRef,
      pdfStyles,
      widthElementID,
      isPdfPortrait,
      minWidth,
    }),
  ];

  if (!hideExcel) {
    items.push(
      ExcelItem({
        fileName,
        toast,
        tableOriginCell,
        elementToPrintRef,
        excelPreprocess,
        excelBeforeDownload,
      })
    );
  }

  return (
    <>
      <div className="relative">
        <ErrorToast toastRef={toast} />
        <Menu ref={menuRef} model={items} popup />
      </div>
      {!noButton && (
        <Button
          icon={!loading && 'pi pi-print'}
          onClick={(e) => menuRef.current?.toggle(e)}
          className="buttonBackground text-white border-0 h-fit"
        >
          {loading && <ProgressSpinner className="h-2rem" />}
        </Button>
      )}
      {noButton && (
        <WrapperButton
          onClick={(e) => menuRef.current?.toggle(e)}
          className="totalColor"
        >
          {loading && <ProgressSpinner className="h-2rem" />}
          {!loading && 'Print Details'}
        </WrapperButton>
      )}
    </>
  );
};

export default ReportButton;
