import { InputText } from 'primereact/inputtext';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormErrorMessage from '../../components/messages/FormErrorMessage';

export type ResetPasswordFormData = {
  email: string;
};

const ResetPasswordForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <label htmlFor="email" className="text-standard block mb-2">
        Email
      </label>
      <Controller
        defaultValue=""
        control={control}
        name="email"
        rules={{
          required: {
            value: true,
            message: 'Email is a required field',
          },
        }}
        render={({ field }) => (
          <span className="p-input-icon-left w-full">
            <i className="pi pi-user top-0 mt-2 pt-1 text-20px" />
            <InputText
              id="email"
              type="email"
              className={`w-full blackText text-standard ${
                errors['email'] && 'p-invalid'
              }`}
              {...field}
            />
          </span>
        )}
      />
      {errors['email'] && (
        <FormErrorMessage errorMessage={errors['email'].message as string} />
      )}
    </div>
  );
};

export default ResetPasswordForm;
