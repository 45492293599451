import React from 'react';
import { useWatch } from 'react-hook-form';
import { useCompanyContext } from '../../../context/CompanyContext';
import { PayrollFilters, PERIOD_OPTIONS } from '../PRFilters';
import { getPRReportTypeName } from './PRReportUtils';

const PRReportHeader = () => {
  const { selectedCompany } = useCompanyContext();
  const filters = useWatch<PayrollFilters>();
  const period = PERIOD_OPTIONS.find(
    (period) => period.value === filters.period
  );

  return (
    <div className="blackText printMedia printShow">
      <div className="text-30px text-center mt-5">{selectedCompany!.name}</div>
      <div className="text-28px text-center">Payroll Report</div>
      <div className="text-17px ml-3 mt-2">
        <span className="font-bold text-20px">Search criteria:</span>
        <div className="ml-3">
          <div>
            <span className="font-bold mr-1">Report Type:</span>
            {getPRReportTypeName(filters.reportType ?? '')}
          </div>
          {filters.reportType === 'earnings' && (
            <div>
              <span className="font-bold mr-1">Employee Status:</span>
              <span className="capitalize">{filters.employeeStatus}</span>
            </div>
          )}
          {filters.reportType === 'earnings' && (
            <div>
              <span className="font-bold mr-1">Period Type:</span>
              <span className="capitalize">{period?.label}</span>
            </div>
          )}
        </div>
      </div>
      <div className="ml-3 text-17px mb-5">
        <span className="font-bold mr-1">Created at:</span>
        {new Date().toLocaleString()}
      </div>
    </div>
  );
};

export default PRReportHeader;
